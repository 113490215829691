<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 text-start">
            <div class="mb-2">Tipo de permiso</div>
            <div  >
                <div class="form-check" >
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"  :value="1" />
                    <label class="form-check-label" for="flexRadioDefault1"> Devolución </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"  :value="0" />
                    <label class="form-check-label" for="flexRadioDefault2"> Boletear </label>
                </div>
            </div>
        </div>

        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <label for="">Número de documento</label>
            <div class="input-group input-group-sm">
                <input type="text" placeholder="Serie" aria-label="Serie" class="form-control" disabled />
                <input type="text" placeholder="Número"  aria-label="Número" class="form-control" disabled />
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2 text-start">
            <label for="">Motivo:</label>
            <textarea class="form-control form-control-sm"  placeholder="Motivo" rows="10"></textarea>
        </div>
    </div>

    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm">
            <i class='fas fa-save' />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-oftalmologia-modal-anulacion",
    components: {
        CModal,
    },
    data() {
        return {

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
    },
    computed: {},
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.$emit("closeModalAnulacion");
        },
    },
};
</script>

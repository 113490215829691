<template lang="es">
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">

    <div>
        <div class=" px-3 d-sm-flex">
            <div class="form-check me-sm-2">
                <input v-model="seleccione" @click="mtdselecione" value="1" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                <label class="form-check-label" for="flexRadioDefault1">
                    Otros Examenes
                </label>
            </div>
            <div class="form-check">
                <input v-model="seleccione" @click="mtdselecione" value="2" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                <label class="form-check-label" for="flexRadioDefault2">
                    Laboratorios
                </label>
            </div>

        </div>
        <div class="row mx-0  px-2">
            <div v-if="seleccione == 1">
                <h5 class="mt-3">Otros Examenes</h5>
                <div class="mb-3 text-start">
                    <label for="exampleFormControlInput1" class="form-label ">Archivo :</label>
                    <input type="file" @change="getFile" class="form-control" ref="fileInput" id="file" lang="es" name="file">
                </div>
                <div class="col-md-12 table-responsive" id="div-tabla-anular">
                    <table id="tb-anular" class="table table-bordered">
                        <caption class="mx-0 px-0"></caption>
                        <thead class="bg-main" style="font-size: 12px">
                            <tr class="text-white">
                                <th scope="col" class="align-middle">N°</th>
                                <th scope="col" class="align-middle">FECHA</th>
                                <th scope="col" class="align-middle">NOMBRE</th>
                                <th scope="col" class="align-middle">USUARIO</th>
                                <th scope="col" class="align-middle">ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody class="border-main" style="font-size: 11px">
                            <tr class="tbody-table" v-for="(item, index) in other_exams" :key="index">
                                <td class="align-middle">
                                    {{ index + 1 }}
                                </td>
                                <td class="align-middle">
                                    {{ item.date }}
                                </td>
                                <td class="align-middle">
                                    {{ item.name }}
                                </td>
                                <td class="align-middle">
                                    {{ item.user.name }}
                                </td>
                                <td class="text-center  align-middle">
                                    <div class="d-flex justify-content-center">
                                        <button class="btn btn-sm btn-danger text-white" @click="openPdf(item.url)">
                                            <i class="fa fa-file-pdf"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!other_exams.length > 0" class="text-center">
                                <th scope="col" colspan="5">NO HAY EXAMENES SUBIDOS </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div v-else>
                <h5 class="mt-3 mb-3 ">Laboratorios</h5>
                <div class="col-md-12 table-responsive" id="div-tabla-anular">
                    <table id="tb-anular" class="table table-bordered">
                        <caption class="mx-0 px-0"></caption>
                        <thead class="bg-main" style="font-size: 12px">
                            <tr class="text-white">
                                <th scope="col" class="align-middle">N°</th>
                                <th scope="col" class="align-middle">CONCEPTO</th>
                                <th scope="col" class="align-middle">F.SOLICITUD</th>
                                <!-- <th scope="col" class="align-middle">F.RESULTADO</th> -->
                                <th scope="col" class="align-middle">MÉDICO SOLICITA</th>
                                <th scope="col" class="align-middle">ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody class="border-main" style="font-size: 11px">
                            <tr class="tbody-table" v-for="(laboratorio, index) in dataLaboratorios" :key="index">
                                <td class="align-middle">
                                    {{ index + 1 }}
                                </td>
                                <td class="align-middle">
                                    {{ laboratorio.product_name }}
                                </td>
                                <td class="align-middle">
                                    {{ laboratorio.date_solicitud }}
                                </td>
                                <!-- <td class="align-middle">
                                    {{ laboratorio.date}}
                                </td> -->
                                <td class="align-middle">
                                    {{ laboratorio.doctor_name }}
                                </td>
                                <td v-if="laboratorio.state ==1" class="text-center  align-middle">
                                    <div class="d-flex justify-content-center">
                                        <button @click="triggerFileInput(index)" class="btn btn-sm btn-warning text-white">
                                            <i class="fas fa-upload"></i>
                                        </button>
                                        <input type="file" @change="handleFileUpload($event, index,laboratorio)" :ref="'fileInput' + index" style="display : none">
                                    </div>
                                </td>
                                <td v-else class="text-center  align-middle">
                                    <div class="d-flex justify-content-center">
                                        <button class="btn btn-sm btn-danger text-white"  @click="openPdf(laboratorio.file)">
                                            <i class="fa fa-file-pdf"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!dataLaboratorios.length > 0" class="text-center">
                                <th scope="col" colspan="6">NO HAY EXAMENES SUBIDOS </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    </div>

    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button v-if="seleccione==1" class="btn btn-success btn-sm" :disabled="!fileLoaded" @click="mtdaddpdf">
            Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    data
} from "jquery";
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            exam_unit: {
                name: '',
                date: '',
                user_id: '',
                user_name: ''
            },

            swal: null,
            file: "",
            filelaboratorio: '',
            fileLoaded: false,
            fileExam_unit: {
                name: '',
                date: '',
                user_name: '',
                user_id: '',
                pdf_id: ''
            },
            seleccione: 1,
            dataLaboratorios: []
            // other_exams: [],
        }
    },
    components: {
        CModal,
        SweetAlert
    },

    computed: {

    },
    props: {
        title: String,
        boo_modal: Boolean,
        patient_id: Number,
        exam_id: Number,
        other_exams: {
            type: Array,
            default: []
        },
        files: ''
    },
    watch: {
        //    patient_id: {
        //      handler (){
        //         this.mtdgetdata()
        //      }
        //    }  
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            if (this.seleccione == 1) {
                const fileInput = this.$refs.fileInput;
                if (fileInput.files.length > 0) {
                    fileInput.value = null;
                }
            }

            this.fileLoaded = false;
            this.$emit('mtdclosemodalexam')
            this.seleccione = 1

        },
        getFile(e) {
            let file = e.target.files[0];
            if (!/\.pdf$/i.test(file.name)) {
                Swal.fire({
                    text: "Seleccione un archivo formato PDF",
                    icon: "info",
                    confirmButtonColor: "#900052",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const input = this.$refs.fileInput;
                        input.type = "text";
                        input.type = "file";
                        this.fileLoaded = false;
                    }
                });
            } else {
                this.file = file;
                this.fileLoaded = true;
            }
        },
        mtdaddpdf: function () {

            let formData = new FormData();
            formData.append("name", this.file.name);
            formData.append("file", this.file);
            formData.append("patient_id", this.patient_id);
            formData.append('status', 1);
            formData.append('view', 1)
            this.post({
                    url: this.$store.getters.get__url + "/Doctor/exam/store",
                    token: this.$store.getters.get__token,
                    params: formData
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        const fileInput = this.$refs.fileInput;
                        if (fileInput.files.length > 0) {
                            fileInput.value = null;
                        }
                        this.$emit('openmodalexam', this.patient_id)
                        Swal.fire({
                            icon: "success",
                            title: "Datos Guardados",
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    }
                })
                .catch((errors) => {});
        },
        openPdf: function (url) {
            console.log(url);
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },

        mtdselecione: function () {
            this.seleccione = (this.seleccione == 1) ? 2 : 1;
            if (this.seleccione == 2) {
                this.get({
                        url: this.$store.getters.get__url + "/Doctor/getPlanDetailPendients/" + this.patient_id +"/1",
                        token: this.$store.getters.get__token,

                    })
                    .then((response) => {
                        this.dataLaboratorios = response.data;

                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }
        },
        triggerFileInput(index) {
            this.$refs['fileInput' + index][0].click();
        },
        handleFileUpload(event, index, laboratorio) {
            let file = event.target.files[0];
            if (!/\.pdf$/i.test(file.name)) {
                Swal.fire({
                    text: "Seleccione un archivo formato PDF",
                    icon: "info",
                    confirmButtonColor: "#900052",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const input = this.$refs['fileInput' + index][0];
                        input.type = "text";
                        input.type = "file";

                    }
                });
            } else {
                this.filelaboratorio = file;
                let formData = new FormData();
                formData.append("id", laboratorio.id);
                formData.append("file", this.filelaboratorio);
                formData.append("document", laboratorio.documento);
                formData.append('case', 1);
                
                this.post({
                        url: this.$store.getters.get__url + "/Doctor/editHistoryPlanDetail",
                        token: this.$store.getters.get__token,
                        params: formData
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            Swal.fire({
                                icon: "success",
                                title: "Datos Guardados",
                                showConfirmButton: false,
                                timer: 1500,
                            });

                        }
                        this.seleccione = 1
                        this.mtdselecione()
                    })
                    .catch((errors) => {});
           

            }

        }


        // mtdgetdata ( ){
        //     this.get({
        //             url: this.$store.getters.get__url + "/Doctor/indexexamenes",
        //             token: this.$store.getters.get__token,
        //         })
        //         .then((response) => {
        //             if (response.statusCode == 200) {

        //                 console.log(response.data);
        //                  this.other_exams=response.data
        //                 // this.fileExam_unit = {
        //                 //     name: response.data.name,
        //                 //     date: response.data.date,
        //                 //     user_name: this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name,
        //                 //     user_id: response.data.user_id,
        //                 //     pdf_id: response.data.id
        //                 // }
        //                 // this.other_exams.push(this.fileExam_unit);
        //             }
        //         })
        //         .catch((errors) => {});
        // }

    },
}
</script>

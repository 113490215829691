<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0 ">
            <div class="mb-2 col-xl-4  col-12 px-1">
                <label for="name" class="form-label">Nombre</label>
                <input type="text" class="form-control form-control-sm" placeholder="Nombre" v-model="data_detail.name">
            </div>
            
            <div class="mb-2 col-xl-4 px-1">
                <label for="name" class="form-label">Marca</label>
                <select v-model="data_detail.brand_id" class="form-control form-control-sm" aria-label=".form-select-sm example">
                    <option value="" selected>Selecione...</option>
                    <option v-for="(brand,index) in dataBrands" :key="index" :value="brand.id">{{ brand.name }}</option>
                </select>
            </div>
            <div class="mb-2 col-xl-4  col-12 px-1">
                <label for="name" class="form-label">Modelo</label>
                <input type="text" class="form-control form-control-sm" placeholder="Modelo" v-model="data_detail.modelo">
            </div>
        </div>
        <div class="row mx-0 px-0 ">
            
            <div class="mb-2 col-xl-4  col-12 px-1">
                <label for="name" class="form-label">Serie</label>
                <input type="text" class="form-control form-control-sm" placeholder="Serie" v-model="data_detail.serie">
            </div>
            <div class=" mb-2 col-xl-4  col-12 px-1">
                <label for="price_cost" class="form-label">S/. Costo</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_cost" @keyup="validateAmount" placeholder="0.00">
            </div>
            <div class=" mb-2 col-xl-4   col-12 px-1">
                <label for="price_sale" class="form-label">S/.Venta</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_sale" @input="validatePriceSale" placeholder="0.00">
            </div>
        </div>

    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="mtdDisabled">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-optica",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: '',
                modelo: '',
                serie: '',
                price_cost: '',
                price_sale: '',
                brand_id: '',
            },
           
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataBrands: {
            type: Array,
            default: null
        }
    },
    computed: {
        mtdDisabled: function () {
            return !(  this.data_detail.name !== '' && this.data_detail.brand_id !== '' &&
                this.data_detail.price_cost !== '' && this.data_detail.price_sale !== '' &&
                this.data_detail.modelo !== '' && this.data_detail.serie !== '');
        }
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    if (this.num == 1) {
                        this.data_detail = {
                            ...newVal
                        };

                    }

                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdHidden: function () {
            this.data_detail = {
                name: '',
                modelo: '',
                serie: '',
                price_cost: '',
                price_sale: '',
                brand_id: '',
            }
        },
        mtdClose() {
            this.mtdHidden()
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdHidden()
        },
        
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_cost = sanitizedValue;
        },
        validatePriceSale(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_sale = sanitizedValue;
        }

    },

};
</script>

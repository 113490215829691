<template>
    <div>
        <div class="container">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>REPORTE DE CAJA</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div v-if="cpData.length > 0" class="d-flex justify-content-end px-3 mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
    
                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-3">Desde</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init"  @change="mtdGetData"  />
                                </div>
                            </div>
                            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 my-sm-2 my-xl-0">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-3">Hasta</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end"  @change="mtdGetData"  />
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 text-center">
                               
                            </div>
                            <div v-if="cpData.length > 0" class="col-xl-2 col-lg-12 col-md-12 col-sm-12 text-center">
                                <div class="input-group input-group-sm text-center w-100 btn-home">
                                    <button class="btn btn-sm w-100 text-white " @click="mtdViewPdfCaja()">
                                        Ver PDF <i class="fa fa-file-pdf"></i>
                                    </button>
                                </div>
                            </div>
                            <div v-if="cpData.length > 0" class="col-md-12 mt-3 mb-4" id="div-tabla-anular">
                                <div id="div-modal-caja" class="d-flex justify-content-center" v-for="(item, index) in cpData" :key="index">
                                    <div id="div-caja" class="card w-100">
                                        <div class=" fw-bold text-xss card-header text-white h4  bg-main">
                                            {{ item.date_end | formatDate("%d/%m/%y") }}
                                            {{ item.date_end.split(" ")[1] }} -
                                            {{ item.user.name }}
                                            {{ item.user.last_name }}
                                        </div>
                                        <div class="card-body">
                                            <div class="row d-flex">
                                                <div class="col-md-12 col-xl-6 col-sm-12 col-lg-12 mt-0 text-center">
                                                    <label class="form-label text-center"><strong>Billetes</strong></label>
                                                    <div v-for="(billete, index) in billetes(item)" :key="index" class="">
                                                        <div class="input-group input-group-sm mb-2">
                                                            <span class="input-group-text col-4">{{ billete.label }}</span>
                                                            <input disabled type="text" class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="billete.cantidad" width="10px" />
                                                            <span v-if="billete.total == 0" class="input-group-text col-4">= S/. 0.00</span>
                                                            <span v-else class="input-group-text col-4">= S/{{ billete.total }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-xl-6 col-sm-12 col-lg-12 text-center">
                                                    <label class="form-label text-center"><strong>Monedas</strong></label>
                                                    <div v-for="(moneda, index) in monedas(item)" :key="index" class="">
                                                        <div class="input-group input-group-sm mb-2">
                                                            <span class="input-group-text col-4">{{ moneda.label }}</span>
                                                            <input disabled type="text" class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="moneda.cantidad" />
                                                            <span class="input-group-text col-4" v-if="moneda.total == 0">= S/. 0.00</span>
                                                            <span v-else class="input-group-text col-4">= S/ {{ moneda.total }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 col-xl-4 col-lg-12 col-sm-12 text-center  btn-home ">
                                                    <strong> TOTAL EFECTIVO </strong>
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong>APERTURA: S/.{{ item.initial }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong>INGRESOS: S/.{{ item.income }}</strong>
                                                    </button>
    
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong>EGRESOS: S/.{{ item.expense }} </strong>
                                                    </button>
    
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong>TOTAL:S/.{{ item.final }} </strong>
                                                    </button>
                                                </div>
                                                <div class="col-md-12 col-xl-4 col-lg-12 col-sm-12 text-center  btn-home">
                                                    <strong> OTROS INGRESOS </strong>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1">
                                                            <strong> Transferencia: S/. {{ ttransferencia(item) }}</strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1">
                                                            <strong> Tarjeta: S/. {{ ttarjeta(item)}}</strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1">
                                                            <strong> Deposito: S/. {{ tdeposito(item) }}</strong>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button class="btn btn-sm w-100 mt-1">
                                                            <strong> Planilla: S/. {{ tplanilla(item)}}</strong>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-xl-4 col-lg-12 col-sm-12 text-center btn-home">
                                                    <strong> SALDOS </strong>
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong>Saldo efectivo S/{{ item.final}}</strong>
                                                    </button>
    
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong>Saldo General S/{{ general(item) }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong>Vueltos S/{{ vueltos(item) }}</strong>
                                                    </button>
                                                    <button class="btn btn-sm w-100 mt-1">
                                                        <strong v-if="sobrafalta(item) < 0"> Faltante :S/{{ parseFloat(sobrafalta(item)) * -1 }}</strong>
                                                        <strong v-else> Sobrante: S/{{ sobrafalta(item) }}</strong>
                                                    </button>
                                                </div>
    
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12 col-xl-3 col-lg-12 col-sm-12"></div>
                                                <div class="col-md-12 col-xl-3 col-lg-12 col-sm-12">
                                                    <label class="btn btn-sm w-100 mt-1 bg-main text-white">
                                                        <strong>Depósitos:S/.{{ depositos(item) }}</strong>
                                                    </label>
                                                </div>
                                                <div class="col-md-12 col-xl-3 col-lg-12 col-sm-12">
                                                    <label class="btn btn-sm w-100 mt-1 bg-main text-white">
                                                        <strong>Retiros:S/.{{ retiros(item) }}</strong>
                                                    </label>
                                                </div>
                                                <div class="col-md-12 col-xl-3 col-lg-12 col-sm-12"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-md-12 mt-3 mb-4 text-center fw-bold" id="div-tabla-anular">
                                       No se encontraron resultados 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import moment from "moment";
    export default {
        name: "c-gerencia-reportecaja",
        data() {
            return {
                filter: {
                    init: null,
                    end: null,
                },
                totalPagesToShow: 2,
                page: 0,
                selectPage: 1,
                stepPagination: [],
                dataShow: [],
                positem: null,
            };
        },
        created() {
            moment.locale("es");
            this.filter.end =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-" +
                moment().format("L").substr(0, 2);
            this.filter.init = this.filter.end;
            this.mtdGetData()
        },
        watch: {
            selectPage() {
                this.calculateData(1);
            },
        },
        computed: {
            cpData() {
                return this.dataShow;
            },
            limitedStepPagination() {
                const totalPages = this.stepPagination.length;
                const currentPage = this.selectPage;
    
                let startPage = 1;
                let endPage = totalPages;
                if (totalPages > this.totalPagesToShow) {
                    const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);
    
                    if (currentPage > halfPagesToShow) {
                        startPage = currentPage - halfPagesToShow;
                        endPage = currentPage + halfPagesToShow - 1;
    
                        if (endPage > totalPages) {
                            endPage = totalPages;
                            startPage = totalPages - this.totalPagesToShow + 1;
                        }
                    } else {
                        endPage = this.totalPagesToShow;
                    }
                }
    
                return Array.from({
                        length: endPage - startPage + 1,
                    },
                    (_, i) => startPage + i
                );
            },
        },
        methods: {
            ...mapActions(["get", "post"]),
            returnHome: function () {
                this.$emit("returnHome");
            },
            mtdGetData: function () {
                this.post({
                        url: this.$store.getters.get__url + "/Gerencia/reportecaja",
                        token: this.$store.getters.get__token,
                        params: {
                            init: this.filter.init,
                            end: this.filter.end,
                        },
                    })
                    .then((response) => {
                        this.dataCaja = response.data;
                        if (this.dataCaja.length > 0) {
                            this.calculateData(1)
                        }
    
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            },
            calculateData(items) {
                let filteredData = this.dataCaja;
                let totalPages = Math.ceil(filteredData.length / items);
                this.stepPagination = Array.from({
                        length: totalPages,
                    },
                    (_, index) => index + 1
                );
                let startIndex = (this.selectPage - 1) * items;
                let endIndex = startIndex + (items - 1);
                this.dataShow = filteredData.slice(startIndex, endIndex + 1);
                this.positem = this.dataShow[0].id;
            },
            billetes: function (item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.billetes;
            },
            monedas: function (item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.monedas;
            },
            ttransferencia(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.totTransferencia;
            },
            ttarjeta(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.totTarjeta;
            },
            tdeposito(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.totDeposito;
            },
            tplanilla(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.totPlanilla;
            },
            general(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
    
                let tot =
                    parseFloat(cierre.totTarjeta) +
                    parseFloat(cierre.totDeposito) +
                    parseFloat(cierre.totTransferencia) +
                    parseFloat(cierre.totPlanilla);
                return parseFloat(tot).toFixed(2);
            },
            vueltos(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.totTurned !== null && cierre.totTurned !== undefined ? cierre.totTurned : '0.00';
            },
            sobrafalta(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                let tot = 0;
                cierre.billetes.forEach(element => {
                    tot += parseFloat(element.total);
                });
                cierre.monedas.forEach(element => {
                    tot += parseFloat(element.total);
                });
                let tot1 =
                    parseFloat(tot) -
                    parseFloat(cierre.totEfectivo) -
                    parseFloat(cierre.totTurned !== null && cierre.totTurned !== undefined ? cierre.totTurned : '0.00')
                return parseFloat(tot1).toFixed(2);
            },
            depositos(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.deposit;
            },
            retiros(item) {
                let cierre = JSON.parse(item.transaction[0].json_cierre);
                return cierre.retirement;
            },
            backPage() {
                this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
            },
            nextPage() {
                this.selectPage = this.selectPage < this.stepPagination.length ? this.selectPage + 1 : this.stepPagination.length;
            },
            selectedPage(page) {
                this.selectPage = page;
            },
            mtdViewPdfCaja: function () {
                window.open(
                    this.$store.getters.get__url + "/Admision/viewpdf/cierre/" + this.positem,
                    "_blank"
                );
            },
        },
        filters: {
            formatDate(date) {
                return date != null ? moment(date).format("DD/MM/YYYY") : "-";
            },
        },
    }
    </script>
    
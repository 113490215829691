<template lang="es">
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row mx-0  px-2">
            <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <table id="tb-anular" class="table table-bordered">
              <caption class="mx-0 px-0"></caption>
                <thead class="bg-main" style="font-size: 12px">
                    <tr class="text-white">
                        <th scope="col" class="align-middle">N°</th>
                        <th scope="col" class="align-middle">DOSIS</th>
                        <th scope="col" class="align-middle">VIA</th>
                        <th scope="col" class="align-middle">FRECUENCIA</th>
                        <th scope="col" class="align-middle">DURACION</th>
                        <th scope="col" class="align-middle">OJO</th>
                        <th scope="col" class="align-middle" v-if="edit == true">ACCION</th>
                    </tr>
                </thead>
                <tbody class="border-main" style="font-size: 11px">
                    <tr class="tbody-table" v-for="(item, index) in data" :key="index">
                        <td class="align-middle">
                            {{ index + 1 }}
                        </td>
                        <td class="align-middle">
                            {{ item.dosis }}
                        </td>
                        <td class="align-middle">
                            {{ item.via}}
                        </td>
                        <td class="align-middle">
                            {{ item.frequency }}
                        </td>
                        <td class="align-middle">
                            {{ item.duration }}
                        </td>
                        <td class="align-middle">
                            {{ item.ojo }}
                        </td>
                        <td class="align-middle" v-if="edit == true">
                            <button type="button" @click="mtddelete(index, 'mtddeletedosis')"
                            class=" btn btn-danger btn-sm px-1 py-0 "> <i class="fas fa-trash-alt text-white"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
        <template #footer>
            <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
                <i class="fa fa-times" />&nbsp;Cerrar
            </button>
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
    </template>
    
    <script>
    import CModal from "@/components/shared/modals/CModal.vue";
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    import {
        mapActions
    } from "vuex";
    
    export default {
        name: "c-medico-historia-modal-dosis",
    
        data() {
            return {
                swal: null,
    
            }
        },
        components: {
            CModal,
            SweetAlert
        },
    
        computed: {
    
        },
        props: {
            title: String,
            boo_modal: Boolean,
            data: Array,
            product_id: Number,
            edit: Boolean,
        },
        methods: {
            ...mapActions(["get", "post"]),
            mtdclosemodal() {
                this.$emit('mtdclosemodal')
    
            },
            mtddelete(index){
                this.$emit('mtddeletedosis',index,this.product_id);
            }

    
        },
    }
    </script>
    
<template>
    <div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
      
        <div class="dropdown-header" @click="toggleDropdown()">
            {{ title }}
            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
        </div>
        <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
            <div class="dropdown-wrapper" v-if="dropdown">
                <div id="myTabContentHijo" v-if="component=='Hcantigua'">
                    <Hcantigua @changePage="changePage"></Hcantigua>
                </div>
                <div id="myTabContentHijo" v-if="component=='Hcactual'">
                    <Hcactuales @changePage="changePage"></Hcactuales>
                </div>
                
            </div>
        </transition>
    
    </div>
    </template>
    <script>
import {
    mapActions
} from 'vuex'
import Hcantigua from '@/components/Medico/Hcs/Hcantiguas.vue';
import Hcactuales from '@/components/Medico/Hcs/Hcactuales.vue';
export default {
    name: 'dropdown-component-antecedente',
    props: {
        title: String,
        component: String,
        
    },
    components: {
        Hcantigua,
        Hcactuales,
    },
    data: () => ({
        dropdown: false,
        dataPlanDetailType: [],
    }),
    methods: {
        ...mapActions(['get', 'post']),
        toggleDropdown() {
            this.dropdown = !this.dropdown;
           
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },
        changePage(){
            this.$emit('changePage','cola')
        },
        
    }
}
</script>
<template>
    <div>
        <div class="px-3 py-2">
            <div id="btn-home" class="col-md-12 mt-3 mb-3 d-flex justify-content-end btn-home">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="card-header text-white h4 text-center" style="background: #900052">
                        <strong>KARDEX INTERNO</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <!--Filtro-->
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                  <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                      <div class="input-group input-group-sm">
                                          <span class="input-group-text col-md-3">SELECCIÓN ALMACÉN</span>
                                          <v-select
                                          class="text-dark form-control"
                                          label="name"
                                          :options="dataAlmacen"
                                          placeholder="- Seleccionar Almacen -"
                                          v-model="selectedAlma"
                                          :reduce="(name) => name.id"
                                          >
                                          <template slot="no-options">
                                            No hay Almacen para la busqueda.
                                          </template>
                                          </v-select>
                                      </div>
                                  </div>
                                  <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-1">
                                          <button
                                          class="btn btn-sm w-100 text-white w-100 py-2"
                                          style="background: #900052"
                                          @click="mtdGetData"
                                          :disabled="mtddisable"
                                          >
                                          <i class="fas fa-search"></i>&nbsp;Buscar
                                          </button>
                                  </div>
                                  <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <button
                                          class="btn btn-sm w-100 text-white w-100 py-2"
                                          style="background: #900052"
                                          @click="viewpdf()"
                                          :disabled="mtddisable"
                                          >
                                          <i class="fal fa-file-pdf"  style="font-size: 18px;"></i>
                                          </button>
                                  </div>
                                </div>
                            </div>
                             <!--Fin Filtro-->
  
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-4 table-container" id=""  v-if="showTable">
                              <table  id="tb-anular" class="table tablaRe table-bordered align-middle">
                                <thead>
                                      <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                          <th scope="col" colspan="1" rowspan="2">FECHA</th>
                                          <th scope="col" colspan="1" rowspan="2">LOTE</th>
                                          <th scope="col" colspan="1" rowspan="2">TIPO OPE.</th>
                                          <th scope="col" colspan="2" >ALMACEN</th>
                                          <th scope="col" colspan="1" rowspan="2">INGRESOS</th>
                                          <th scope="col" colspan="1" rowspan="2">SALIDAS</th>
                                          <th scope="col" colspan="1" rowspan="2">SALDO</th>
                                      </tr>
                                 
                                      <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">Origen</th>
                                        <th scope="col">Destino</th>
                                     </tr>

                                  </thead>
  
                                <tbody class="custom-table">
  
                                  <tr class="text-center" style="vertical-align: middle; color: #900052; background: #f3e2ec">
                                      <th scope="col" colspan="5" style="font-style: italic;">*** TOTALES</th>
                                      <th scope="col">{{ totals.totalSumaCantidadEn }}</th>
                                      <th scope="col">{{  totals.totalSumaCantidadSa}}</th>
                                      <th scope="col" style="font-style: italic;"></th>
                                  </tr>
  
                                  <template v-for="(product, productId) in dataCxcs">
                                    <tr class="text-center" :key="'product_' + productId">
                                        <th scope="col" colspan="8">{{  product[0].name }}</th>
                                    </tr>
  
                                    <tr v-for="(item, index) in product" :key="'item_' + productId + '_' + index">
                                          <template v-if="item.type == 1">
                                              <td>{{ item.date | formatDate }}</td>
                                              <td>{{ item.lote ?  item.lote : '-'}}</td>
                                              <td>
                                                  <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                  <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                  <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                  <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                              </td>
                                              <td>
                                                    <span class="badge text-bg-secondary large-text">{{ item.almacen }}</span>
                                              </td>
                                              <td>
                                                    <span class="badge text-bg-secondary large-text">{{item.almacenDes }}</span>
                                              </td>
                                              <td>{{ parseInt(item.quantity) }}</td>
                                              <td>-</td>
                                              <td>{{ item.saldoCantidad }}</td>
                                          </template>
  
                                          <template v-if="item.type == 2">
                                              <td>{{ item.date | formatDate }}</td>
                                              <td>{{ item.lote ?  item.lote : '-'}}</td>
                                              <td>
                                                  <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                  <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                  <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                  <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                              </td>
                                              <td>
                                              <span class="badge text-bg-secondary large-text">{{ item.almacenDes }}</span>
                                            </td>
                                            <td>
                                              <span class="badge text-bg-secondary large-text">{{ item.almacen }}</span>
                                            </td>
                                              <td>-</td>
                                              <td>{{parseInt(item.quantity) }}</td>
                                              <td>{{ item.saldoCantidad }}</td>
                                          </template>
                                    </tr>
                                    <tr  class="text-center" style="vertical-align: middle; color: #900052; background: #fdfae3" :key="productId">
                                          <th scope="col" colspan="5" style="font-style: italic;">*** TOTAL</th>
                                          <th scope="col">{{ product.totales.sumaCantidadEn }}</th>
                                          <th scope="col">{{ product.totales.sumaCantidadSa }}</th>
                                          <th scope="col" style="font-style: italic;"></th>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
  
                            </div>
  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";

export default {
    name: "kardex",
    components: {},
    data() {
        return {
            search: "",
            dataShow: [],
            dataCxcs: [],
            selectPage: 1,
            noRecordsMessage: "",
            filter: {
                init: "",
                end: "",
                monthRange: null
            },
            stepPagination: [],
            servicesProd: [],
            selectedProd: "",
            totals: [],

            selectedYear: null,
            nextThreeYears: [],
            months: [
            { id: 1, name: 'Enero' },
            { id: 2, name: 'Febrero' },
            { id: 3, name: 'Marzo' },
            { id: 4, name: 'Abril' },
            { id: 5, name: 'Mayo' },
            { id: 6, name: 'Junio' },
            { id: 7, name: 'Julio' },
            { id: 8, name: 'Agosto' },
            { id: 9, name: 'Septiembre' },
            { id: 10, name: 'Octubre' },
            { id: 11, name: 'Noviembre' },
            { id: 12, name: 'Diciembre' }
            ],
            showTable: false,

            //Interno
            dataAlmacen: [],
            selectedAlma: null,
        };
    },
    mounted() {
    this.populateNextThreeYears();
    },
    computed: {
        mtddisable() {
            return !(this.selectedAlma !== null);
        },
        cpData() {
        }
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;

        this.vercampus();
    },
    methods: {
        ...mapActions(["get", "post"]),

        //metodo obtener data

        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Logistica/kardexxInter",
                token: this.$store.getters.get__token,
                params: {
                    alma: this.selectedAlma,
                },
            })
            .then((response) => {
                this.dataCxcs = response.dataCxcs;
                this.totals = response.totals;
                if (this.dataCxcs.length === 0) {
                    this.showTable = false;
                    this.noRecordsMessage = "No se encontraron resultados.";
                    Swal.fire({
                    text: " Sin Datos",
                    icon: "warning",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#900052",
                    });
                } else {
                    this.showTable = true;
                    this.noRecordsMessage = "";
                }
            })
            .catch((errors) => {});
        },

        changePage(page) {
            this.$emit("returnHome", page);
        },
        //Fecha
        formatDate(date) {
            const formattedDate = new Date(date);
            const day = String(formattedDate.getDate()).padStart(2, "0");
            const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
            const year = formattedDate.getFullYear();
            return `${day}/${month}/${year}`;
        },
        searchProd: function (search, loading) {
            if (search.length > 3) {
                this.servicesProd = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/logistics/searchKardex/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.servicesProd = response.data;
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        viewpdf: function () {
            window.open(
                this.$store.getters.get__url +
                "/Logistica/viewpdf/KardexxInt/" +
                this.selectedAlma,
                "_blank"
            );
        },

        populateNextThreeYears() {
        const currentYear = new Date().getFullYear() - 2;
        for (let i = 0; i < 6; i++) {
            this.nextThreeYears.push(currentYear + i);
        }
        },

        vercampus: function () {
            this.filter.campus_id = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Logistica/oc/filterAlmacen/" +this.filter.campus_id,
                    token: this.$store.getters.get__token,
                   
                })
                .then((response) => {
                    this.dataAlmacen = response.data;
                    console.log(this.dataAlmacen);
                })
                .catch((errors) => {});
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>

<style scoped>
#dtb-cxc {
    max-height: 400px;
    overflow-y: auto;
}

#dtb-cxc {
    max-width: 1400px;
    overflow-x: auto;
}

#btn-edit {
    color: #39b54a;
    font-size: 25px;
    margin: 0px 2px;
}

#btn-edit:hover {
    color: #217b2d;
}

#btn-remove {
    color: #ff0000;
    font-size: 25px;
    margin: 0px 2px;
}

#btn-remove:hover {
    color: #a30404;
}

.btn-bm-noradius {
    margin-left: 10px;
    width: 150px;
    height: 38px;
}

.btn-bm-noradius:hover {
    background: rgb(170, 2, 95);
    background-color: rgb(170, 2, 95);
    color: white;
}

.btn-register {
    background: rgb(144, 0, 82);
}

.btn-register:hover {
    background: rgb(170, 2, 95);
}

.modal-header {
    background: rgb(144, 0, 82);
}

.close {
    cursor: pointer;
}

.text-start {
    text-align: left;
}

.tr-table>th>div {
    background: #900052;
    border-radius: 7px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
}

.tr-table>th {
    padding: 0px;
}

.tbody-table>td>div {
    background: #eaecee;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.tbody-table>td {
    padding: 0px;
}

.tbody-table>td>div>div {
    border-radius: 10px;
}

#btn-close {
    width: 49%;
}

.table-th {
    width: 50% !important;
}

.table-th-state {
    width: 18% !important;
}

.page-link {
    color: black !important;
}

.page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
}

.page {
    color: white !important;
    background: rgb(144, 0, 82);
}

.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}

@media (max-width: 480px) {
    #btn-edit {
        font-size: 13px !important;
    }

    #btn-remove {
        font-size: 13px !important;
    }

    #btn-close {
        width: 100%;
    }

    .container {
        font-size: 11px !important;
    }

    .btn-register {
        width: 100% !important;
        margin-top: 0 !important;
    }

    .td-div-a {
        padding-top: 10px !important;
    }

    .td-div-state>div>div {
        margin-top: 3px !important;
    }
}

@media (max-width: 415px) {
    #state_schedule {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px !important;
    }

    #state_schedule div {
        width: 100%;
    }
}

.custom-table th,
.custom-table td {
    font-size: 11px;
}

.table-container {
overflow-x: auto;
white-space: nowrap;
scrollbar-width: thin;
scrollbar-color: darkgray lightgray;
}

.large-text {
    font-size: 9px;
}
</style>

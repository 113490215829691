<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'md'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
    <div class="row">
      <div class="col-md-12 py-2">
        <div class="row d-flex">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 com-xs-12">
            <div class="input-group input-group mb-3">
              <v-select
                id="serviceSelect"
                class="text-dark form-control"
                label="name"
                :options="serviceOptions"
                placeholder="- Buscar Tipo de Servicio -"
                v-model="Register.selectedService"
                :reduce="(name) => name.id"
              />
            </div>

            <div class="input-group input-group mb-2">
              <v-select
                id="serviceShift"
                class="text-dark form-control"
                label="name"
                :options="shiftOptions"
                placeholder="- Buscar Turno -"
                v-model="Register.selectedShift"
                :reduce="(name) => name.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="btn-group">
        <button @click="mtdClose" class="btn btn-danger btn-sm custom-btn">
          <i class="fa fa-times"></i>&nbsp;Cerrar
        </button>
        <button @click="mtdRegister" class="btn btn-success btn-sm custom-btn" :disabled="isRegisterDisabled"><i class="fas fa-solid fa-file-pdf"></i>&nbsp;REGISTRAR</button>
      </div>
    </template>

      <SweetAlert :swal="swal" ref="SweetAlert"/>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";

export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
      CModal,
      SweetAlert,
    },
    data() {
    return {
        swal: null,
        Register: {
          selectedService: "",
          selectedShift: "",
        },
      serviceOptions: [],
      shiftOptions: [],
    };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: [],
    },
    created() {
      this.mtdGetService();
      this.mtdGetShift();
    },
    computed: {
        cpData() {
            return this.item.map(element => element);
        },
        isRegisterDisabled() {
          return !this.Register.selectedService || !this.Register.selectedShift;
        },
    },
    methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
        this.Register = {
          selectedService: "",
          selectedShift: "",
        },
        this.$emit("closeModalTurn");
    },

    mtdGetService: function () {
      this.post({
        url: this.$store.getters.get__url + "/Maintainer/hours/selectSer",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.serviceOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mtdGetShift: function () {
      this.post({
        url: this.$store.getters.get__url + "/Maintainer/hours/selectShi",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.shiftOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mtdRegister: function () {
      this.post({
        url: this.$store.getters.get__url + "/Maintainer/hours/turnRegister",
        token: this.$store.getters.get__token,
        params: {
            shift: this.Register.selectedShift,
            service: this.Register.selectedService,
        },
      })
        .then((response) => {
          if(response.state == 1){
            this.$refs.SweetAlert.showWarning("Ya existe un Turno Registrado con esos Datos");
          } else {
            this.$refs.SweetAlert.showSuccess(response.message);
            this.mtdClose();
            this.$emit("registerModalTurn");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    },

};
</script>
<style scoped>
.custom-btn {
  font-size: 13px; 
  font-weight: bold;
}
</style>
  
  
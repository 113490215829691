<template>
    <div>
        <div class="container">
            <div class="row text-end">
                <div class="col-md-12 mt-3 mb-4 float-right btn-home">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-anular" class="d-flex justify-content-center">
                <div id="div-anular" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>VUELTOS</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 table-container" id="content-timelinetable">
                                <table class="table table-bordered ">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class="text-white table-th text-center text-xs">
                                            <th class="align-middle border border-5 bg-main border-white">N°</th>
                                            <th class="align-middle border border-5 bg-main border-white">RESPONSABLE
                                            </th>
                                            <th class="align-middle border border-5 bg-main border-white">VUELTO
                                                PENDIENTE S/</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-xs">
                                        <tr v-for="(item, index) in dataTurned" :key="index" class="table-body" v-if="item.passage.state==2">
                                            <td class="border border-5 border-white align-middle">{{ index + 1}}</td>
                                            <td class="border border-5 border-white align-middle">{{  item.passage.document}}-
                                            {{item.passage.name}}</td>
                                            <td class="border border-5 border-white align-middle text-end"
                                                @click="mtdTurnedProccess(item.id,item.state)">S/ {{ item.turned }}</td>
                                        </tr>
                                        <tr class="table-body">
                                            <td class="text-center" colspan="2">
                                                <strong>TOTAL</strong>
                                            </td>
                                            <td class="text-end">
                                                <strong>S/ {{cpTurnedTotal}}</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>

</template>

<script>
import {mapActions} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-oftalmologia-egresos',
    components: {
        SweetAlert
    },
    data() {
        return {
            swal: null,
            process: {
        box: 0,
        initial: 0,
        observation: "",
        status: 0,
      },
      dataTurned: [],
      sumTurned: 0.0,
        }
    },
    computed:{
        cpTurnedTotal() {
        var sumVueltos = 0.0;
        for (let i = 0; i < this.dataTurned.length; i++) {
            if (this.dataTurned[i].state == 1 && this.dataTurned[i].passage.state==2) {
                sumVueltos = sumVueltos + parseFloat(this.dataTurned[i].turned);
            }
        }
        return sumVueltos.toFixed(2);
    },
    recargarLista() {
        return this.mtdGetData();
    },
    },
    props: {
        boxes: [],
        bead: {},
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            this.$emit("mtdChangePage", "home");
        },
        mtdSelectBox: function (item) {
            this.process = item.process.length > 0 ? item.process : this.process;
            this.process.box = item.id;
        },
        mtdSendOption: function (page) {
            if (this.verifBead == true) {
                this.page = page;
                this.$emit("mtdChangePage", this.page);
            }
        },
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Admision/turned",
                token: this.$store.getters.get__token,
                params: {
                    Campus: this.$store.getters.get__campus,
                    bead: this.bead.id,
                },
            })
                .then((response) => {
                    this.dataTurned = response.data;
                })
                .catch((errors) => { });
        },
        mtdTurnedProccess: function (id,state) {
            Swal.fire({
                title: "¿Vuelto entregado conforme?",
                showDenyButton: true,
                confirmButtonText: "SI",
                denyButtonText: "NO",
                confirmButtonColor: "#900052",
                denyButtonColor: "#900052",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.post({
                        url: this.$store.getters.get__url + "/Admision/turned/proccess",
                        token: this.$store.getters.get__token,
                        params: {
                            turned_id: id,
                            state: 2,
                            bead: this.bead.id,
                        },
                    })
                        .then((response) => {
                            const numero =parseFloat(response.data.turned);
                            this.bead.turned = numero;
                            this.$emit("mtdCommitBead", this.bead);
                            this.mtdGetData();
                            this.$refs.SweetAlert.showSuccess("Vuelto confirmado"); 
                        })
                        .catch((errors) => { });
                        this.recargarLista;
                } else if (result.isDenied) {
                    this.$refs.SweetAlert.showInfo("El vuelto no es conforme"); 
                }
            });
        }
    }
}
</script>
<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}

#cmbEgreso {
    height: 30px !important
}

#cmbEgreso div .vs__search {
    height: 30px !important
}
</style>
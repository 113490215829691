<template>
<div>

    <div class="row mx-0 mt-3 p-3 d-flex justify-content-start">
        <div class="col-xl-4 col-lg-6 col-md-12 border mb-3 p-3">
            <cPatient v-if="optionShow == 0" :patient="patient" :dataTableImages="jsonData" @toggleVisibilityGraph="toggleVisibilityGraph"></cPatient>
            <div v-if="optionShow == 1" id="div-informe" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="row d-flex justify-content-start align-items-center">
                        <div class="col-xl-auto col-md-4 col-sm-4 mt-2">
                            <div class="mt-1 mb-1 ms-auto btn-success">
                                <button type="button" class="btn btn-sm btn-outline-main w-100" @click="mtdChangeOption()">
                                    <i class="fas fa-arrow-left"></i> Atrás
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row px-3">
                        <div class="my-2 border-top border-bottom">
                            <span class="text-center">Informe {{ itemImagen.dateCreated }}</span>
                        </div>
                        <div class="text-start">
                            <p class="ps-2 text-xs"> > Indicacion para {{ itemImagen.patient.indicacion.value }}</p>
                        </div>
                        <div class="ps-3">
                            <div class="text-start">
                                <div class="mb-1">
                                    <span class="fst-italic"><b>Informe final:</b></span>
                                </div>
                                <div class="ps-1">
                                    <div class="my-1">
                                        <ul class="text-xs">
                                            <li class="text-start"><b>Ojo derecho</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b>Errores Falsos Positivos : </b> {{ itemImagen.informe.ojo_derecho.errores_falsos_positivos }}</li>
                                                    <li class="text-center d-inline-flex"><b>Errores Falsos Negativos: </b> {{ itemImagen.informe.ojo_derecho.errores_falsos_negativos }}</li>
                                                </ul>
                                            </li>
                                            <li class="text-start"><b>Ojo izquierdo</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b>Errores Falsos Positivos : </b> {{ itemImagen.informe.ojo_izquierdo.errores_falsos_positivos }}</li>
                                                    <li class="text-center d-inline-flex"><b>Errores Falsos Negativos: </b> {{ itemImagen.informe.ojo_izquierdo.errores_falsos_negativos }}</li>
                                                </ul>
                                            </li>
                                            <li class="text-start"><b>DM</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b>OD : </b> {{ itemImagen.informe.dm.ojo_derecho }}</li>
                                                    <li class="text-center d-inline-flex"><b>OI: </b> {{ itemImagen.informe.dm.ojo_izquierdo }}</li>
                                                </ul>
                                            </li>
                                            <li class="text-start"><b>DSM</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b>OD : </b> {{ itemImagen.informe.dsm.ojo_derecho}}</li>
                                                    <li class="text-center d-inline-flex"><b>OI: </b> {{ itemImagen.informe.dsm.ojo_izquierdo }}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ps-3">
                            <div class="text-start">
                                <div class="mb-1">
                                    <span class="fst-italic"><b>Conclusiones:</b></span>
                                </div>
                                <dl class="row" v-for="(item, index) in itemImagen.conclusiones.value" :key="index">
                                    <dd class="col-sm-12 text-xs">{{item.name}} - {{ item.ojo }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!---Imagen-->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 px-3" v-if="isVisibleResultArray[pos] == true">
            <div class="row rounded-5 d-flex justify-content-center border-2 h-100 p-2" id="div-img-campovisual">
                <div id="carouselExampleIndicators" class="carousel slide home-carousel mt-0" data-bs-ride="carousel" v-if="dataImg.length > 0">
                    <div class="carousel-indicators">
                        <button v-for="(imagen, index) in dataImg" :key="index" type="button" :data-bs-target="'#carouselExampleIndicators'" :data-bs-slide-to="index" :class="{ 'active': index === 0 }" aria-current="true" :aria-span="'Slide ' + (index + 1)"></button>
                    </div>
                    <div class="carousel-inner">
                        <div v-for="(imagen, index) in dataImg" :key="index" :class="{ 'carousel-item': true, 'active': index === 0 }">
                            <img :src="'https://oftalmologia.howertpi.com/assets/'+imagen.route" class="d-block w-100" @click="mtOpenCarrusel" :data-bs-slide-to="index" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <div class="h-100 p-5" v-if="dataImg.length == 0">
                    <h3>No hay Imagenes para mostrar</h3>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-md-6 col-lg-6 col-sm-12  px-0" v-if="isVisibleResultArray[pos] == true">
            <div class="row rounded-5 border-2 h-100" id="div-img-campovisual">
                <div class="justify-content-center p-1 z-index-button" v-if="dataPdf[0]">
                    <iframe :src="'https://oftalmologia.howertpi.com/assets/'+dataPdf[0].route" width="100%" height="600px" title="Pdf"></iframe>
                </div>
                <div class="justify-content-center p-5 z-index-button" v-if="!dataPdf[0]">
                    <h3>No hay Archivos para mostrar</h3>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <cCarrusel :title="modal.title" :boo_modal="modal.boo_modal" :dataImg="dataImg" @closeCarrusel="closeCarrusel" />

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import cPatient from '@/components/Medico/AyudaDx/cPatient.vue'
import VuePdfApp from "vue-pdf-app";
import cCarrusel from '@/components/Medico/AyudaDx/modals/cCarrusel.vue';
export default {
    name: 'c-medico-campovisual',
    components: {
        VuePdfApp,
        cPatient,
        cCarrusel,
    },
    data() {
        return {
            page: 'campovisual',
            stateHistory: null,
            viewInput: null,
            pos: 0,
            optionShow: 0,
            jsonData: [],
            dataPdf: [],
            dataImg: [],
            itemImagen: {},
            isVisibleResultArray: [],
            pdfUrl: '/assets/pdf/prueba.pdf',
            modal:{
                title:'',
                boo_modal:false
            }

        }
    },
    props: {
        stateRolAdmin: Boolean,
        patient: {
            type: Object,
            default: () => ({})
        },
        data: {
            type: Array,
            default: () => ([])
        }
    },
    created() {

    },
    watch: {
        data: {
            handler(newVal) {
                this.mtdConversionData();
                for (let i = 0; i < this.lengthArray; i++) {
                    let atributo = false;
                    this.isVisibleResultArray.push(atributo);
                }
            },
            immediate: true // Para que se ejecute cuando el componente se monta por primera vez
        }
    },
    mounted() {
        //this.pdfUrl = 'file:///D:/HP/downloads/%C3%8Dndice%20de%20capacitaciones.pdf';

    },
    computed: {
        lengthArray() {
            return this.data.length;
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdConversionData: function () {
            this.data.forEach(element => {
                const jsonDataFromBackend = JSON.parse(element);
                this.jsonData = this.jsonData.concat(jsonDataFromBackend);
            });

        },
        toggleVisibilityGraph: function (pos) {
            this.dataPdf = [];
            this.dataImg = [];
            this.$set(this.isVisibleResultArray, pos, true);
            this.pos = pos;
            this.optionShow = 1;
            this.itemImagen = this.jsonData[pos];
            //separar pdf de img
            this.jsonData[pos].patient.examen.value.forEach(elem => {
                const extension = elem.route.split('.').pop().toLowerCase();
                if (extension === 'pdf') {
                    this.dataPdf.push(elem);
                } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                    this.dataImg.push(elem);
                }

            });
        },
        mtdChangeOption() {
            this.optionShow = 0;
            this.state = !this.state;
            for (let i = 0; i < this.lengthArray; i++) {
                this.$set(this.isVisibleResultArray, i, false);
            }
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            if (page === 'cola') {
                this.patients = {}
            }
            this.stateHistory = (page === 'campovisual') ? 1 : 0;
            this.viewInput = (page === 'campovisual') ? 1 : 0;
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        mtOpenCarrusel: function (){
            this.modal = {
                title: 'RESULTADOS CAMPO VISUAL - ' + this.patient.name,
                boo_modal: true, 
            }
        },
        closeCarrusel: function(){
            this.modal.title = '';
            this.modal.boo_modal = false;
        }

    }
}
</script>

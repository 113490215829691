<template>
    <div>
      <div class="w-100 h-100">
        <Nav :rolname="rolname" />
      </div>
      <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
        <Citas v-if="page=='citas'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Seguimiento v-if="page=='seguimiento'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Reservas v-if="page=='reservas'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Reprogramar v-if="page=='reprogramar'" :reserId="reserId" :serId="serId" :patientId="patientId" :date="date" :hour="hour" :state="state" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
      </div>
      <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" />
      </div>
    </div>
</template>
  
  <script>
    import { mapActions } from 'vuex'
    import Nav from '../../components/Call/Nav.vue'
    import Home from '../../components/Call/Home.vue'
    import Citas from '../../components/Call/CitasView.vue'
    import Seguimiento from '../../components/Call/SeguiView.vue'
    import Reservas from '../../components/Call/ReservasView.vue'
    import Reprogramar from '../../components/Call/ReprogramarView.vue'
    import Footer from '../../components/Call/Footer.vue'

    export default {
      name: "CallView",
      components: {
        Nav,
        Home,
        Citas,
        Seguimiento,
        Reservas,
        Reprogramar,
        Footer,
      },
      data () {
        return {
          prefix: 'call',
          page:'home',
          rol_name: null,
          stateRolAdmin: false,
        }
      },
      props: {
        rolname: String,
        dataRoles: Array
      },
      created () {
        this.rol_name = this.rolname;
        this.mtdGetRolAdmin();
      },
      methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function(page, reserId, date, serId, patientId,hour,state){
          if (page === 'reprogramar') {
              this.reserId = reserId;
              this.date = date;
              this.serId = serId;
              this.patientId = patientId;
              this.hour = hour;
              this.state = state;
              this.page = page;
          }
          if(page !== 'reprogramar'){
            this.page = page;
          }
        },
        mtdBackCampus: function () {
          this.$emit("mtdBackCampus");
      },
        mtdGetRolAdmin:function(){
          this.dataRoles.forEach(element => {
            if(element.role_id==1){
              this.stateRolAdmin = true;
            }
          });
        },
        
      }
      
    }
  </script>
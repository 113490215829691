<template>
    <div>
        <div class="p-3">
            <div class="d-flex title-option">
                <div class="btn-home mt-3 mb-2 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome()">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-anular" class="w-100">
                <div id="div-anular" class="card w-100">
                    <div class="card-header text-white h4 bg-main text-center">
                        <strong>SEGUIMIENTO</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 mb-2">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-3">Desde</span>
                                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init" @change="mtdGetData" />
                                        </div>
                                    </div>
                                    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12 my-sm-2 my-xl-0 mb-3">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-3">Hasta</span>
                                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end" @change="mtdGetData" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12">
                                        <button class="btn btn-sm btn-primary" type="button" @click="mtdOpenPdf()" style="background-color: #900052; border: 2px solid #900052">
                                            <i class="fas fa-solid fa-file-pdf"></i> Exportar PDF
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="input-group input-group mb-2">
                                            <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                            <input v-model="search" type="text" class="form-control form-control" placeholder="Buscar Paciente por DNI o Nombre" @input="mtdSearchFilter" />
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <!--tabla-->
                            <div class="col-md-12" id="content-timelinetable" v-if="cpData.length > 0">
                                <div>
                                    <table id="tb-anular" class="table table-borderless">
                                        <caption class="py-0 my-0"></caption>
                                        <thead>
                                            <tr class="text-white table-th text-center">
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    N°
                                                </th>
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    CHECK IN
                                                </th>
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    PACIENTE
                                                </th>
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    DOCUMENTO
                                                </th>
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    FECHA
                                                </th>
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    TELEFONO
                                                </th>
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    MOTIVO
                                                </th>
                                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                    ESTADO
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="text-xs table-body" v-for="(item, index) in cpData" :key="index">
                                                <td class="border border-5 border-white align-left align-middle">
                                                    {{ index + 1 }}
                                                </td>
                                                <td class="border border-5 border-white align-left align-middle" v-if="item.state == 2">
                                                    {{ item.checkind | formatDateTime  }}
                                                </td>
                                                <td class="border border-5 border-white align-left align-middle space" v-if="item.state !== 2">
                                                    <button type="button" class="btn btn-sm btn-outline-primary"   
                                                    :class="[
                                                        item.currentDate > item.created_at || item.booking_Re === null ? 'btn-outline-secondary' : 'btn-outline-primary'
                                                    ]"
                                                        :disabled="item.currentDate > item.created_at || item.booking_Re === null" @click="confirmSet(item.id,item.booking_Re)">
                                                        <i class="fas fa-file-check"></i> CheckIn
                                                    </button>
                                                </td>
    
                                                <td class="border border-5 border-white align-left align-middle">
                                                    {{ item.of_patient_name }}
                                                </td>
                                                <td class="border border-5 border-white align-left align-middle">
                                                    {{ item.of_patient_document }}
                                                </td>
                                                <td class="border border-5 border-white align-left align-middle">
                                                    {{ item.created | formatDateTime }}
                                                </td>
                                                <td class="border border-5 border-white align-left align-middle">
                                                    {{ item.of_patient_phone ?  item.of_patient_phone : "-"}}
                                                </td>
                                                <td class="border border-5 border-white align-left align-middle">
                                                    {{ item.motive ?  item.motive : "-"}}
                                                </td>
                                                <!--state!-->
                                                <td class="border border-5 border-white align-left align-middle">
                                                    <div v-if="item.state == 2" class="badge text-bg-success p-2">
                                                        <strong>CHECKIN</strong>
                                                    </div>
                                                    <div v-else-if="item.state == 1" class="badge text-bg-warning p-2">
                                                        <strong>REGISTRADO</strong>
                                                    </div>
                                                </td>
                                                <!--Fin state!-->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!--pag-->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center px-1 mb-4">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="
                                selectPage === 1 ? 'page-link active' : 'page-link'
                              " href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
    
                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="
                                selectPage === item ? 'page-link active' : 'page-link'
                              " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if="
                              limitedStepPagination[
                                limitedStepPagination.length - 1
                              ] <
                              stepPagination.length - 1
                            " class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if="
                              limitedStepPagination[
                                limitedStepPagination.length - 1
                              ] !== stepPagination.length
                            " class="page-item">
                                            <a :class="
                                selectPage === limitedStepPagination.length + 2
                                  ? 'page-link active'
                                  : 'page-link'
                              " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalDateVue :title="modalDate.title" :boo_modal="modalDate.modal_form" @closeModalDate="closeModalDate"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

    
<script>
import {
    mapActions
} from "vuex";
import cModalDateVue from "./Modals/cModalDate.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import moment from "moment";

export default {
    name: "c-call-citas",
    components: {
        SweetAlert,
        cModalDateVue
    },
    data() {
        return {
            swal: null,
            search: "",
            paDoc: "",
            filter: {
                init: "",
                end: "",
            },
            seguiRe: [],
            dataShowTable: [],
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            Documents: {},
            estados: {
                1: "REGISTRADO",
                2: "RESERVADO SIN PAGO",
                3: "RESERVADO PAGADO",
                4: "CHECKIN",
                5: "ALTA|CHECKOUT",
            },
            modalDate: {
                title: "SELECCIONAR FECHAS",
                item: [],
                modal_form: false,
                pos: null,
            },
        };
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;

        this.mtdGetData();
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
        paDoc(newVal) {
            if (newVal == "") {
                this.mtdSearchDocument();
            }
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },

        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/surveillance/follow",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        init: this.filter.init,
                        end: this.filter.end,
                    },
                })
                .then((response) => {
                    this.seguiRe = response.data;
                    this.calculateData(7);
                    //this.mtdSearchFilter();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        calculateData(items) {
            if (!this.seguiRe || !this.seguiRe.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.seguiRe;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.seguiRe.filter((element) => {
                    if (element.of_patient_name !== null) {
                        let name = element.of_patient_name.toString().toUpperCase();
                        let document = element.of_patient_document.toString();
                        return name.includes(query) || document.includes(query);
                    }
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        mtdSearchFilter() {
            this.calculateData(7);
        },

        returnHome() {
            this.$emit("mtdChangePage", "home");
        },
       
        confirmSet(index, idRe) {
            this.$refs.SweetAlert.showConfirmSimple2(
                "",
                "Confirmar CheckIn",
                "warning",
                "Si, Confirmar!"
            ).then((result) => {
                if (result.value) {
                    this.confirmSetDate(index, idRe);
                }
            });
        },
        confirmSetDate(index, idRe) {
            //const item = this.seguiRe[index];
            this.post({
                    url: this.$store.getters.get__url + "/surveillance/editStateRe",
                    token: this.$store.getters.get__token,
                    params: {
                        //id: item.id,
                        id: index,
                        idRe: idRe
                        //checkind: item.checkind
                    },
                })
                .then((response) => {
                    if (response.state == 1) {
                        this.$refs.SweetAlert.showError("Error");
                    } else {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdGetData();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        mtdSearchDocument: function () {
            if (this.paDoc !== "") {
                this.post({
                        url: this.$store.getters.get__url + "/Call/followDoc",
                        token: this.$store.getters.get__token,
                        params: {
                            Campus: this.$store.getters.get__campus,
                            paDoc: this.paDoc,
                            init: this.filter.init,
                            end: this.filter.end,
                        },
                    })
                    .then((response) => {
                        this.seguiRe = response.data;
                        if (this.seguiRe.length === 0 || this.paDoc == "") {
                            this.$refs.SweetAlert.showWarning(
                                "No hay Paciente con esos datos"
                            );
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else {
                this.mtdGetData();
            }
        },

        mtdOpenPdf: function () {
            /*
            this.modalDate = {
                title: "SELECCIONAR FECHAS",
                modal_form: true,
            };
            */
            const campus = this.$store.getters.get__campus; 
            const url = `${this.$store.getters.get__url}/surveillance/viewPdf/${this.filter.init}/${this.filter.end}?campus=${campus}`;
            window.open(url, "_blank");
        },

        closeModalDate: function () {
            this.modalDate = {
                title: "SELECCIONAR FECHAS",
                item: [],
                modal_form: false,
                pos: null,
            };
        },

        /** paginación */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>

    
<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}

.align {
    display: block;
    text-align: left;
}

.input-width {
    width: 130px;
}

.space {
    white-space: nowrap;
}
</style>

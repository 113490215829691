<template>
<CModal 
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'md'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true">
    <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <label for="" style="margin-bottom: 3px">Tipo de permiso</label>
            <div style="display: flex; justify-content: center; margin-bottom: 10px">
                <div class="form-check" style="margin-right: 10px">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" v-model="type" :value="1" />
                    <label class="form-check-label" for="flexRadioDefault1"> Devolución </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" v-model="type" :value="0" />
                    <label class="form-check-label" for="flexRadioDefault2"> Boletear </label>
                </div>
            </div>
        </div>

        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <label for="">Número de documento</label>
            <div class="input-group input-group-sm">
                <input type="text" placeholder="Serie" v-model="serie" aria-label="Serie" class="form-control" />
                <input type="text" placeholder="Número" v-model="number" aria-label="Número" class="form-control" />
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mt-2 text-center">
            <label for="">Motivo:</label>
            <textarea class="form-control form-control-sm" v-model="motive" placeholder="Motivo" rows="10"></textarea>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdSendPermission" :disabled="cpDisabled">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
    },
    data() {
        return {
            serie: "",
            number: "",
            motive: "",
            type: "",
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        data: {},
    },
    computed: {
        cpDisabled() {
            if (
                this.serie.length > 0 &&
                this.number.length > 0 &&
                this.motive.length > 0 &&
                (this.type === 0 || this.type === 1)
            )
                return false;
            return true;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdClose() {
            this.serie = "";
            this.number = "";
            this.motive = "";
            this.type = "";
            this.$emit("closeModalCancel");
        },
        mtdSendPermission: function () {
            this.$emit("mtdSendPermission", this.serie, this.number, this.motive, this.type);
            this.serie = "";
            this.number = "";
            this.motive = "";
            this.type = "";
        },
        updateType() {
            // Este método se llama cuando cambia el valor de los radios
            // console.log("Tipo actualizado:", this.type);
        },
    },
};
</script>
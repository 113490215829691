<template>
<div>
    <div class="container text-center mt-2">

        <div class="d-flex title-option">
            <h2 class=" h2-borde mt-3 mb-3 ms-5 text-main pt-1 px-4">
                <strong>REPORTE OPERATORIO</strong>
            </h2>
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="row mt-3 mb-2 ms-auto">
            <div class="col-xl-4 col-md-10 col-sm-10 rounded-3 px-0 bg-blue mt-2">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start" v-if="patient">{{ patient.name }}</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start" v-else></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    name: 'c-medico-reporte',
    components: {},
    data() {
        return {
            page: 'reporte',
            stateHistory: null,
            viewInput: null,
            patients: {},
        }
    },
    props: {
        stateRolAdmin: Boolean,
        patient :{
            type: Object,
        }
    },
    created() {

    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            if (page === 'cola') {
                this.patients = {}
            }
            this.stateHistory = (page === 'reporte') ? 1 : 0;
            this.viewInput = (page === 'reporte') ? 1 : 0;
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        mtdviewpage: function (payload) {
            this.page = 'reporte'
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput = 0;
        }

    }
}
</script>

<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12 text-center">
        <div class="d-flex justify-content-center">
          <div class="col-md-4 col-lg-3 mb-3 mx-3">
            <div class="input-group input-group-sm">
              <span class="input-group-text">Servico</span>
              <select id="service_id" v-model="service" @change="seleccionar" class="form-control form-control">
                <option selected value="" disabled>Seleccione ...</option>
                <option  value="0" >TODOS</option>
                <option v-for="(item, index) in services" 
                 :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-lg-3 mb-3 mx-3" v-if="service!=0">
            <div class="input-group input-group-sm">
              <span class="input-group-text">Servico Especifico</span>
              <select id="specific_service_id"  v-model="serEspeci" class="form-control form-control">
                <option selected value="" disabled >Seleccione ...</option>
                <option v-for="(item, index) in  serEspecifico" :key="index" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-lg-3  mb-3 mx-3" v>
            <div class="input-group input-group-sm">
              <span class="input-group-text">Documento</span>
              <select id="document_id" v-model="document" class="form-control form-control">
                
                <option selected value="" disabled>Seleccione ...</option>
                <option  value="0" >TODOS</option>
                <option v-for="(item, index) in voucher" :key="index" :value="item.id" v-if="item.name!='TICKET'">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <label for="tortas_report" class="mb-3">Reporte de Tortas</label>
          <input type="checkbox" id="tortas_report" class="mb-3"  v-model="tortasReport">
           &nbsp;
          <label for="barras_report"  class="">Reporte de Barras</label>
          <input type="checkbox"  id="barras_report" v-model="barrasReport">
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
        <i class="fa fa-times"></i>&nbsp;Cerrar
      </button>
      &nbsp;
      <button
        class="btn btn-success btn-sm"
        @click="mtdSendPermission"
        :disabled="cpDisabled"
      > 
        <i class="fas fa-save"></i>&nbsp; Generar PDF
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import { mapActions } from "vuex";

export default {
  name: "c-gerencia-modal-anular",
  components: {
    CModal,
    CButtonClose,
  },
  data() {
    return {
      service:"",
      serEspeci:"",
      document:"",
      tortasReport: false,
      barrasReport: false,//Esta lista se utiliza para guard
      services: [], 
      serEspecifico:[],
      voucher:[]
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    data: Object,
  },
  created(){
   this.mtdGetData();
  },
  computed: {
    cpDisabled() {
      return this.tortasReport=="" &&  this.barrasReport=="" ? true :false ;
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    seleccionar() {
    const selectedService = this.services.find(service => service.id === this.service);
    if (selectedService) {
      this.serEspecifico = selectedService.of_product;
    } 
    },
    mtdGetData: function () {
        this.post({
          url: this.$store.getters.get__url + "/Gerencia/services",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if(
              response.error==false
            ){
                this.services=response.data.services;
                this.voucher=response.data.documents;
            }
          })
          .catch((errors) => { console.log(errors); });
      },
    filterKey: function (e) {
      let b = false;
      b = /^[0-9]+$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
    // mtdSelectType: function (value) {
    //   this.transaction.type = value;
    // },
    mtdClose() {
     
      this.$emit("closeModalProccess");
    },
    mtdSendPermission: function () {
      this.serEspeci= this.service==0?0:this.serEspeci;
      this.$emit("mtdSendPermission",
      this.serEspeci,this.document,this.tortasReport,this.barrasReport);
      this.serEspeci="";
      this.service="";
      this.document="";
      this.tortasReport= false;
      this.barrasReport= false;
    },
  },
};
</script>

<style scoped>
.inpt_color {
  text-align: center !important;
  background-color: cadetblue !important;
  border-radius: 20px;
  color: white;
}
.inpt_color::placeholder {
  color: white;
}
</style>

<template>
  <div>
    <div class="container" v-if="view == 'data'">
      <div class="d-flex title-option">
          <div class="btn-home mt-3 mb-2 ms-auto">
              <button type="button" class="btn btn-outline-main" @click="returnHome()">
                  <i class="fas fa-home-lg-alt"></i> Inicio
              </button>
          </div>
      </div>
      <div id="div-modal-anular" class="w-100 border-box-main mt-4">
        <div id="div-anular" class="card w-100">

          <div
          class="card-header text-center text-white h4"
          style="background: #900052"
        >
          <strong>RECETAS</strong>
        </div>

          <div class="card-body">
            <!--DOCUMENTO-->
            <div class="row justify-content-center align-items-center mb-1">
              <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 p-2 mt-200">
                <input
                  class="form-control form-control-lg inpunt-rounded custom-input"
                  type="text"
                  v-model="reDoc"
                  @keyup.enter="mtdSearchDocument"
                  placeholder="Buscar Paciente"
                  aria-label=".form-control-lg example"
                />
              </div>
            </div>
            <!--FIN DE DOCUMENTO-->
            
            <div class="d-flex flex-row justify-content-center" v-if="reDoc !== ''" >
              <div id="div-listcola" class="flex-grow-1 m-3 table-responsive">
                  <div>
                  <div
                      class="row"
                      v-for="(patientData, patientName) in dataReDoc"
                      :key="patientName"
                  >
                      <div class="px-sm-1 px-md-3">
                      <div class="card-header text-white h6 header-main align-center">
                          <strong>RESERVAS DE {{ patientName }}</strong>
                      </div>

                      <div
                          v-for="(item, index) in patientData"
                          :key="index"
                          class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end align-left d-flex justify-content-center"
                      >
                          <!--Consultas-->
                          <div
                          v-if="item.consu == 1"
                          class="d-flex flex-column align-items-center"
                          style="margin-left: 10px; margin-right: 30px"
                          >
                          <button
                              @click="mtdOpenCon(item)"
                              class="div-button col m-2 p-2 text-white"
                              style="font-size: 10px"
                          >
                              <strong>{{ item.consulting_room_name.toUpperCase() }}</strong>
                          </button>
                          <div class="containerSvg">
                              <svg 
                                  xmlns="http://www.w3.org/2000/svg" 
                                  viewBox="0 0 576 512"
                                  width="100%"
                                  height="100%">
                              <path 
                                  fill="#900052"
                                  d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"/>
                              </svg>
                          </div>
                          <div style="font-size: 11.5px" class="fw-bold">
                              <span style="color: #900052; font-size: 9.5px">{{
                              item.consulting_insurance
                              }}</span>
                          </div>
                          </div>
                          <br />
                          <!--Operaciones-->
                          <div
                          v-if="item.ope == 1"
                          class="d-flex flex-column align-items-center"
                          style="margin-left: 10px; margin-right: 30px"
                          >
                          <button
                              @click="mtdOpenOpe(item)"
                              class="div-button col m-2 p-2 text-white"
                              style="font-size: 10px"
                          >
                              <strong>{{ item.operations_room_name.toUpperCase() }}</strong>
                          </button>
                          <div class="containerSvg">
                              <svg 
                                  xmlns="http://www.w3.org/2000/svg" 
                                  viewBox="0 0 640 512"
                                  width="100%"
                                  height="100%">
                              <path 
                                  fill="#900052"
                                  d="M15 15C24.4 5.7 39.6 5.7 49 15l63 63V40c0-13.3 10.7-24 24-24s24 10.7 24 24v96c0 13.3-10.7 24-24 24H40c-13.3 0-24-10.7-24-24s10.7-24 24-24H78.1L15 49C5.7 39.6 5.7 24.4 15 15zM133.5 243.9C158.6 193.6 222.7 112 320 112s161.4 81.6 186.5 131.9c3.8 7.6 3.8 16.5 0 24.2C481.4 318.4 417.3 400 320 400s-161.4-81.6-186.5-131.9c-3.8-7.6-3.8-16.5 0-24.2zM320 320a64 64 0 1 0 0-128 64 64 0 1 0 0 128zM591 15c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-63 63H600c13.3 0 24 10.7 24 24s-10.7 24-24 24H504c-13.3 0-24-10.7-24-24V40c0-13.3 10.7-24 24-24s24 10.7 24 24V78.1l63-63zM15 497c-9.4-9.4-9.4-24.6 0-33.9l63-63H40c-13.3 0-24-10.7-24-24s10.7-24 24-24h96c13.3 0 24 10.7 24 24v96c0 13.3-10.7 24-24 24s-24-10.7-24-24V433.9L49 497c-9.4 9.4-24.6 9.4-33.9 0zm576 0l-63-63V472c0 13.3-10.7 24-24 24s-24-10.7-24-24V376c0-13.3 10.7-24 24-24h96c13.3 0 24 10.7 24 24s-10.7 24-24 24H561.9l63 63c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0z"/>
                              </svg>
                          </div>
                          <div style="font-size: 11.5px" class="fw-bold">
                              <span style="color: #900052; font-size: 9.5px">{{
                              item.operations_insurance
                              }}</span>
                          </div>
                          </div>
                          <br />
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-3" v-else-if="view == 'tablaOpe'">
    <cOpe
      @mtdBack="mtdBack"
      :dataConsultingrooms="dataConsultingrooms"
      :recipe="recipe"
      @updateData="mtdOpenOpeUp"
      :bead="bead"
   />
  </div>
  <div class="px-3" v-else-if="view == 'tablaCon'">
    <cConsu
      @mtdBack="mtdBack"
      :dataConsultingrooms="dataConsultingrooms"
      :recipe="recipe"
      @updateData="mtdOpenConUp"
      :bead="bead"
   />
  </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cOpe from "./componentes/cRecipe.vue";
import cConsu from "./componentes/cRecipe.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
  props: {
    bead: {
      type: Object
    },
  },
  data() {
    return {
      swal: null,
      dataConsultingrooms: [],
      search: "",
      dataShowC: [],
      page: 0,
      selectPage: 1,
      stepPagination: [],
      dataConsulting: [],
      recipe: [],
      filter: {
          filter_categorie_id: null,
          filter_campus_id: null,
      },
      message: false,
      noRecordsMessage: "",

      //Recetas
      reDoc: "",
      dataReDoc: [],
      patient: "",
      view: "data",
    };
  },
  components: {
      cOpe,
      cConsu,
      SweetAlert,
  },
  computed: {},
  watch: {
      reDoc(newVal, oldVal) {
      if (newVal == "") {
          this.mtdSearchDocument();
      }
      },
  },
  methods: {
    ...mapActions(["get", "post"]),

      returnHome: function () {
          this.$emit('mtdChangePage', 'home');
      },

      mtdSearchDocument: function () {
      if (this.reDoc !== "" && this.reDoc.length > 2) {
          this.post({
          url: this.$store.getters.get__url + "/pharmacy/recetas",
          token: this.$store.getters.get__token,
          params: {
              reDoc: this.reDoc,
          },
          })
          .then((response) => {
              this.dataReDoc = response.datos;
              if (this.dataReDoc.length === 0 || this.reDoc == "") {
              this.$refs.SweetAlert.showWarning("No hay Paciente con esos datos");
              }
          })
          .catch((errors) => {});
      } else {
          this.dataReDoc = [];
      }
      },

      mtdOpenOpe: function (item) {
      this.post({
          url: this.$store.getters.get__url + "/pharmacy/filterById/tableSopCli",
          token: this.$store.getters.get__token,
          params: {
          booking: item.operations_id,
          idPatient: item.patient_id,
          },
      })
          .then((response) => {
          this.recipe = response.recipe;
          this.dataConsultingrooms = response.data;
          //if (this.dataConsultingrooms.length === 0 || !this.recipe || (this.recipe.recipe_details && this.recipe.recipe_details.length === 0)) {
          if (this.dataConsultingrooms.length === 0) {
              this.$refs.SweetAlert.showWarning("No hay Datos");
          } else {
              this.view = "tablaOpe";
          }
          })
          .catch((errors) => {});
      },

      mtdOpenCon: function (item) {
      this.post({
          url: this.$store.getters.get__url + "/pharmacy/filterById/tableCli",
          token: this.$store.getters.get__token,
          params: {
          booking: item.consulting_id,
          idPatient: item.patient_id,
          },
      })
          .then((response) => {
          this.recipe = response.recipe;
          this.dataConsultingrooms = response.data;
          //if (this.dataConsultingrooms.length === 0 || !this.recipe || (this.recipe.recipe_details && this.recipe.recipe_details.length === 0)) {
          if (this.dataConsultingrooms.length === 0) {
              this.$refs.SweetAlert.showWarning("No hay Datos");
          } else {
              this.view = "tablaCon";
          }
          })
          .catch((errors) => {});
      },

      mtdBack: function () {
      this.view = "data";
      },

      mtdOpenOpeUp: function (item) {
      this.post({
          url: this.$store.getters.get__url + "/pharmacy/filterById/tableSopCli",
          token: this.$store.getters.get__token,
          params: {
          booking: item.operations.id,
          idPatient: item.of_patient_id,
          },
      })
          .then((response) => {
          this.recipe = response.recipe;
          this.dataConsultingrooms = response.data;
          //if (this.dataConsultingrooms.length === 0 || !this.recipe || (this.recipe.recipe_details && this.recipe.recipe_details.length === 0)) {
          if (this.dataConsultingrooms.length === 0) {
              this.$refs.SweetAlert.showWarning("No hay Datos");
          } else {
              this.view = "tablaOpe";
          }
          })
          .catch((errors) => {});
      },

      mtdOpenConUp: function (item) {
        console.log(item);
      this.post({
          url: this.$store.getters.get__url + "/pharmacy/filterById/tableCli",
          token: this.$store.getters.get__token,
          params: {
          booking: item.consulting.id,
          idPatient: item.of_patient_id,
          },
      })
          .then((response) => {
          this.recipe = response.recipe;
          this.dataConsultingrooms = response.data;
          //if (this.dataConsultingrooms.length === 0 || !this.recipe || (this.recipe.recipe_details && this.recipe.recipe_details.length === 0)) {
          if (this.dataConsultingrooms.length === 0) {
              this.$refs.SweetAlert.showWarning("No hay Datos");
          } else {
              this.view = "tablaCon";
          }
          })
          .catch((errors) => {});
      },
  },
};
</script>
<style scoped>
  .div-button {
      background: #900052;
      border-radius: 15px;
      border: none;
  }
  
  .image-container {
      text-align: center;
  }
  
  .image-container img {
      width: 75px;
  }
  
  #scroll-div {
      min-height: 150px;
      max-height: 500px;
      overflow-y: auto;
  }
  
  #scroll-div button {
      height: 65px;
  }
  
  @media (max-width: 768px) {
      #div_conte2 {
      padding-left: 30px;
      padding-right: 30px;
      }
      .row {
      display: block;
      }
      #scroll-div > div > button {
      height: 44px;
      }
      .container {
      font-size: 11px;
      }
  }
  @media (max-width: 415px) {
      #scroll-div {
      height: 472px;
      }
  }
  .containerSvg {
      width: 90px;
      height: 90px;
      margin-top: 5px;
      margin-bottom: 5px;
  }
  
  .text-horario {
      font-weight: bold;
      font-size: 11px;
      color: #808080;
  }
  
  #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
  }
  .header-main {
      background-color: #900052;
  }
  
  @media (max-width: 992px) {
      .flex-row {
      flex-direction: column !important;
      align-items: center;
      padding-top: 20px !important;
      }
  
      #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
      }
  }
  
  @media (min-width: 800px) {
      /* #div-modal-cola{
          margin-top: 65px;
          } */
      #btn-registro-cola {
      text-align: left;
      }
  }
  
  @media (max-width: 992px) {
      .flex-row {
      flex-direction: column !important;
      align-items: center;
      padding-top: 20px !important;
      }
  
      #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
      }
  }
  #div-tabla-cola {
      border: 1px solid #aaa;
      height: 300px;
      background: #f1f2f3;
      overflow: auto;
      box-sizing: border-box;
      padding: 0 1rem;
  }
  
  .div-button {
      display: flex;
      align-items: center;
  }

  .custom-input {
  border-color: #EB5077;
  color: #EB5077;
  }

  .custom-input::placeholder {
      color: #EB5077; 
      }

  .inpunt-rounded{
      border-radius: var(--bs-border-radius-2xl)!important;
      border-color: #EB5077;
      color: #EB5077;
      border: 1px solid #EB5077;
      align-items: center;
      text-align: center;
  }
  .table-responsive {
  max-height: 500px;
  overflow-x: hidden;
}
</style>
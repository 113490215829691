<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Razon Social</label>
                <input type="text" class="form-control form-control-sm" placeholder="Razon Social" v-model="data_detail.name">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Ruc</label>
                <input type="text" class="form-control form-control-sm" placeholder="Ruc" v-model="data_detail.ruc">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Email</label>
                <input type="text" class="form-control form-control-sm" placeholder="Email" v-model="data_detail.email">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Persona Encargada</label>
                <input type="text" class="form-control form-control-sm" placeholder="Persona Encargada" v-model="data_detail.persona_encargada">
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-proveedores",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: '',
                ruc: '',
                email:'',
                persona_encargada:''
            }
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    if (this.num == 1) {
                        this.data_detail = {
                            ...newVal
                        };
                    }

                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataBrand: {
            type: Array,
            default: null
        }
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name.length > 0 && this.data_detail.ruc.length > 0);
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.data_detail = {
                name: '',
                ruc: ''
            }
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.data_detail= {
                name: '',
                ruc: '',
                email:'',
                persona_encargada:''
            }
        },

        mtdAddBrand(event) {
            this.data_detail.of_brand_id = event.target.value;
        }

    },

};
</script>

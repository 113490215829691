<template>
    <div>
        <div class="container mt-2">
            <div class="d-flex flex-row-reverse">
                <div class="mt-1 mb-4 btn-home">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-anular" class="w-100">
                <ul class="nav pb-0" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button @click="changePage('oc')" :class="{
                      'item-button': true,
                      'nav-link': true,
                      active: page === 'oc',
                    }" id="home-tab" type="button">
                            Facturas OC
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button @click="changePage('os')" :class="{
                      'item-button': true,
                      'nav-link': true,
                      active: page === 'os',
                    }" id="profile-tab" type="button">
                            Facturas OS
                        </button>
                    </li>
                </ul>
                <div class="tab-content mb-4" id="myTabContent">
                    <ReporteBillOc v-if="page == 'oc' " :type="page" />
                    <ReporteBillOs v-if="page == 'os' " :type="page" />
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import ReporteBillOc from "@/components/Logistica/Pages/ReporteFacturas/ReportBillOC.vue";
    import ReporteBillOs from "@/components/Logistica/Pages/ReporteFacturas/ReportBillOS.vue";
    export default {
        data() {
            return {
                page: "oc",
            };
        },
        components: {
            ReporteBillOc,
            ReporteBillOs
        },
        methods: {
            ...mapActions(["get", "post"]),
            changePage: function (page) {
                this.page = page;
            },
            returnHome: function () {
                this.$emit("returnHome");
            },
        },
    };
    </script>
    
    <style scoped>
    .h4-borde {
        border-radius: 50px;
    }
    
    .item-button {
        background: #a9a9a9 !important;
        border-radius: 10px 10px 0px 0px;
        /* margin-right: 5px !important;*/
        border: none !important;
        color: white !important;
        width: 180px;
    }
    
    .item-button1 {
        background: #a9a9a9 !important;
        border-radius: 10px 10px 0px 0px;
        /* margin-right: 5px !important;*/
        border: none !important;
        color: white !important;
        width: 160px !important;
    }
    
    .nav-link:hover {
        background: #900052 !important;
    }
    
    .active {
        background-color: #900052 !important;
    }
    
    .nav-item {
        width: 185px;
    }
    </style>
    
<template>
<div>
    <div class="container text-center mt-4">
        <div class="row d-flex justify-content-center">
            <div id="div-modal-anular" class="w-75">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-12 col-sm-11 rounded-3 px-0 bg-blue my-2">
                        <div class="row mx-0">
                            <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                                <span class=" align-middle col-md-2 col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                                <span class=" align-middle col-md-10 col-sm-8 h-100 px-0 text-start" v-if="patient">{{ patient.name }}</span>
                                <span class=" align-middle col-md-10 col-sm-8 h-100 px-0 text-start" v-else></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="div-anular" class="card w-100">
                    <div class="card-header text-white h5 bg-main text-center">
                        <strong>ANTECENTES</strong>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <textarea class="form-control form-control-sm" name="antecentes" id="" placeholder="Escribe aqui los Antecedentes" rows="7"
                                v-model="nuevas.description"></textarea>
                            </div>
                            <br>
                            <div class="btn-home mt-3 mb-2 ms-auto">
                                <button type="button" class="btn btn-outline-main" @click="mtdSave()">
                                    <i class="fas fa-save"></i> Guardar
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="px-sm-1 px-md-3 table-responsive">
                                <div v-for="(item, index) in nueHc" :key="index" class="p-3 mt-3 mb-3 border border-main border-start-0 rounded-end align-left">
                                    <span class="text-paciente">{{ item.created_at | formatDate }} - {{ item.doctor }}</span>
                                    <br>
                                    <span class="h6">{{ item.description }}</span>
                                    <br>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import moment from "moment";

export default {
    name: 'c-medico-antecedente-Hcactuales',
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            nuevas: {
                type: "",
                description: "",
                of_doctor_id: "",
                of_patient_id: "",
            },
            nueHc: [],
        }
    },
    computed: {

    },
    props: {
        patient: {
            type: Object,
        }
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Doctor/getNueva",
                token: this.$store.getters.get__token,
                params: {
                    of_patient_id: this.patient.id,
                    of_doctor_id: this.$store.state.user.id
                },
            })
                .then((response) => {
                this.nueHc = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        mtdSave: function (){
            if (!this.patient.id) {
                this.$refs.SweetAlert.showWarning("No hay Paciente en Hc. Actual");
                return; 
            }
            if (!this.nuevas.description.trim()) {
                this.$refs.SweetAlert.showWarning("Ingrese una Descripción");
                return; 
            }
            this.post({
                url: this.$store.getters.get__url + "/Doctor/antNueva",
                token: this.$store.getters.get__token,
                params: {
                    of_patient_id: this.patient.id,
                    of_doctor_id: this.$store.state.user.id,
                    description: this.nuevas.description,
                    type: 2,
                },
            })
            .then((response) => {
            this.$refs.SweetAlert.showSuccess(response.message);
            this.mtdGetData();
                this.nuevas = {
                    type: "",
                    description: "",
                };
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            this.stateHistory = (page === 'antecedente') ? 1 : 0;
            this.viewInput = (page === 'antecedente') ? 1 : 0;
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        mtdviewpage: function (payload) {
            this.page = 'antecedente'
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput = 0;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        backPage() {
            this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
        },
        addPage() {
            this.selectPage =
                this.selectPage == this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;
        },

    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>

<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}

.table-responsive {
  max-height: 250px;
  overflow-y: auto;
  display: block;
}
</style>

<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2 row mx-0">
        <div class=" row  col-xl-6">

            <div class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">{{titleTotal}}
                </label>
                <input v-model="billpay.total" type="text" class="form-control form-control-sm" placeholder="Monto Total" disabled />
            </div>
            <div class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Monto Pagado:
                </label>
                <input v-model="paid" type="text" class="form-control form-control-sm" placeholder="0.00" disabled />
            </div>
            <div class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Monto Pendiente:
                </label>
                <input v-model="pending" type="text" class="form-control form-control-sm" placeholder="Monto Pendiente" disabled />
            </div>

            <div v-if="billpay.type == 'os' && billpay.detraction > 0" class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Monto Detraccion:
                </label>
                <input v-model="billpay.detraction_amount" type="text" class="form-control form-control-sm" placeholder="Monto Total" disabled />
            </div>
            <div v-if="billpay.type == 'os' && billpay.detraction > 0" class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Monto Detraccion Pagado:
                </label>
                <input v-model="paid_d" type="text" class="form-control form-control-sm" placeholder="0.00" disabled />
            </div>
            <div v-if="billpay.type == 'os' && billpay.detraction > 0" class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Monto Detraccion Pendiente:
                </label>
                <input v-model="pending_d" type="text" class="form-control form-control-sm" placeholder="Monto Pendiente" disabled />
            </div>
            <div class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Monto a Pagar:
                </label>
                <input type="text" class="form-control form-control-sm" v-model="amount" @input="validateAmount" :disabled="mtdDisableImput" placeholder="00.00" />
                <span class="text-danger text-alert" v-if="mtdDisableButton && amount">
                    El valor tiene que ser menor o igual a {{ type_amount == 1 ? pending_d : pending}} y mayor que 0.00</span>
            </div>
            <div v-if="billpay.type == 'os' && billpay.detraction > 0" class="mb-3 col-6 d-flex justify-content-center align-bottom">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="type_amount == 2" @change="mtdChangeState" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">
                        Pago de {{ type_amount == 1 ? "Detraccion" : "Total Neto" }}
                    </label>
                </div>
            </div>

            <div class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Metodo de pago </label>
                <select v-model="type_file" class="form-control form-control-sm" aria-label="Default select example">
                    <option :value="'ADMISION'">Admision</option>
                    <option :value="'BANCO'">Banco</option>

                </select>

            </div>
            <div v-if="type_file =='BANCO'" class="mb-3 col-md-6 col-sm-6">
                <label for="exampleFormControlInput1" class="form-label">Monto Detraccion Pagado:
                </label>
                <input type="file" class="form-control form-control-sm" @change="getFile" ref="fileInput" />
            </div>

        </div>
        <div class="col-xl-6">
            <div class="col-md-12 table-responsive" id="div-tabla-anular">
                <table id="tb-anular" class="table table-bordered">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main" style="font-size: 12px">
                        <tr class="text-white">
                            <th scope="col" class="align-middle">N°</th>
                            <th scope="col" class="align-middle">MONTO PAGADO</th>
                            <th v-if="billpay.type == 'os'" scope="col" class="align-middle">TIPO</th>
                            <th scope="col" class="align-middle">FECHA</th>
                            <th scope="col" class="align-middle">USUARIO</th>
                            <th scope="col" class="align-middle">METODO DE PAGO</th>
                            <th scope="col" class="align-middle">VOUCHER</th>
                        </tr>
                    </thead>
                    <tbody class="border-main" style="font-size: 11px">
                        <tr class="tbody-table" v-for="(item, index) in data" :key="index">
                            <td class="align-middle">
                                {{ index + 1 }}
                            </td>
                            <td class="align-middle">
                                {{ item.amount }}
                            </td>
                            <td class="align-middle" v-if="billpay.type == 'os'">
                                {{ item.type == 1? 'Pago de detraccion' : 'Pago del Total neto'}}
                            </td>
                            <td class="align-middle">
                                {{ item.date }}
                            </td>
                            <td class="align-middle">
                                {{ item.user_name }}
                            </td>
                            <td class="align-middle">
                                {{ item.metodo }}
                            </td>
                            <td v-if="item.file" class="text-center">
                                <a href="#" class=" ms-1 btn btn-sm btn-danger px-2 text-white " type="button" @click="openPdf(item.file)">
                                    <i class="fa fa-file-pdf" style="font-size: 13px;"></i>
                                </a>

                            </td>
                            <td v-else class="align-middle text-center ">
                                <div>-</div>
                            </td>
                        </tr>
                        <tr v-if="!data.length > 0" class="text-center">
                            <th scope="col" :colspan="colspanValue">NO HAY PAGOS REGISTRADOS</th>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>

    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdInsertPay" :disabled="mtdDisableButton">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-ordendecompra-addPay",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            amount: "",
            paid: null,
            pending: null,
            paid_d: null,
            pending_d: null,
            data: [],
            type_amount: 2, //1-> detraccion, 2-> pago neto
            file: '',
            type_file: 'ADMISION',
            fileInput: ''
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        billpay: Object,
    },
    watch: {
        billpay: {
            handler(newVal) {
                // Realiza cualquier transformación necesaria en los datos aquí
                this.mtdShowData();
            },
            immediate: true // Para que se ejecute cuando el componente se monta por primera vez
        }
    },
    computed: {
        titleTotal() {
            return this.billpay.type == 'oc' ? 'Monto Total:' : 'Monto Total Neto:';
        },
        colspanValue() {
            return this.billpay.type == 'os' ? 7 : 6;
        },
        mtdDisableImput() {
            let paid = parseFloat(this.paid).toFixed(2);
            let paid_detraction = parseFloat(this.paid_d).toFixed(2);
            if (this.billpay.type == 'os') {
                return this.type_amount == 1 ? paid_detraction === this.billpay.detraction_amount : paid === this.billpay.total;
            } else {
                return paid === this.billpay.total;
            }
        },
        mtdDisableButton() {
            const pending = parseFloat(this.pending).toFixed(2);
            const pending_d = parseFloat(this.pending_d).toFixed(2);
            const billTotal = parseFloat(this.amount);
            let amount = parseFloat(this.amount).toFixed(2);
            /* if (this.amount.length > 0 && amount > 0.00) {
                return billTotal > pending;
            } else {
                return true;
            } */
            //
            if (this.billpay.type == 'os') {
                return (this.amount.length > 0 && amount > 0.00) ? (this.type_amount == 1 ? billTotal > pending_d : billTotal > pending) : true

            } else {
                // Verificar que valueAmount tenga una longitud mayor a 0 y sea mayor que 0.00
                return (this.amount.length > 0 && amount > 0.00) ? billTotal > pending : true;
            }
        },

    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
            this.type_amount = this.type_amount == 2 ? 1 : 2;
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.amount = sanitizedValue;
        },
        mtdShowData: function () {
            if (this.billpay.id !== '') {
                this.get({
                        url: this.$store.getters.get__url +
                            "/Logistica/" + this.billpay.type + "/showPayBill/" +
                            this.billpay.id,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.data = response.data;
                    })
                    .catch((errors) => {});
                    this.paid = this.billpay.paid;
                this.pending = this.billpay.pending;
                this.paid_d = this.billpay.detraction_paid;
                this.pending_d = this.billpay.detraction_pending;
            }
        },
        mtdInsertPay: function () {
            //bill_id
            let formData = new FormData();
            formData.append('bill_id', this.billpay.id);
            formData.append('amountDeposited', this.amount);
            formData.append('type_amount', this.type_amount);
            formData.append('file', this.file);
            formData.append('method_pay', this.type_file);
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/" + this.billpay.type + "/storePayBill",
                    token: this.$store.getters.get__token,
                    params: formData
                })
                .then((response) => {
                    //modal de confirmacion y recargar el modal
                    Swal.fire({
                        icon: "success",
                        title: "Pago registrado",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.amount = "";
                    this.file = '',
                        this.type_file = 'ADMISION'
                    this.mtdShowData();
                    this.paid = (this.billpay.type == 'oc') ? parseFloat(response.newPaid).toFixed(2) : parseFloat(response.bill.paid).toFixed(2);
                    this.pending = (this.billpay.type == 'oc') ? parseFloat(response.newPending).toFixed(2) : parseFloat(response.bill.pending).toFixed(2);
                    this.paid_d = response.bill.detraction_paid;
                    this.pending_d = response.bill.detraction_pending;
                    if (this.fileInput) {
                        this.fileInput.value = '';
                    }
                })
                .catch((errors) => {});
        },

        mtdclosemodal() {
            (this.newPaid = null), (this.newPending = null);
            this.amountDeposited = null;
            this.amount = '';
            this.file = '',
                this.type_file = 'ADMISION'
            this.$emit('mtdcloseModalPay')
        },
        getFile(e) {
            this.fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(pdf)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                Swal.fire({
                    text: 'Seleccione un archivo formato PDF, PNG O JPG',
                    icon: 'info',
                    confirmButtonColor: '#900052',
                });
                this.fileInput.value = '';
            } else {
                this.file = file;
            }
        },
        openPdf: function (url) {
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },

    },
};
</script>

    
<style scoped>
.text-alert {
    font-size: 9px;
}
</style>

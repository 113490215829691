<template>
  <div>
    <div class="" v-if="view == 'data'">
      <div class="d-flex title-option">
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Atras
                </button>
            </div>
        </div>
      <div id="div-modal-anular" class="w-100 border-box-main">
        <div
          id="div-anular"
          class="card w-100"
          style="max-height: 430px; overflow-y: auto"
        >
          <div class="d-flex justify-content-center" style="background-color: #ffffff">
            <div class="w-100 pt-4" style="background-color: #ffffff">
              <div class="d-flex px-4 responsive-search">
                <!-- Desde CH -->
                <div
                  v-if="dataConsultingrooms.operations.of_reservation.of_patient"
                  class="input-group input-group mb-3 custom-margin"
                >
                  <span class="input-group-text spam" id="basic-addon1"
                    ><strong>PACIENTE</strong></span
                  >
                  <input
                    class="form-control form-control-sm text-center"
                    style="background-color: #ffffff; font-size: 15px"
                    disabled
                    :value="
                    dataConsultingrooms.operations.of_reservation.of_patient
                        ? dataConsultingrooms.operations.of_reservation.of_patient.name
                        : ''
                    "
                  />
                </div>

                <div
                  v-if=" dataConsultingrooms.operations.of_doctor"
                  class="input-group input-group mb-3 custom-margin"
                >
                  <span
                    v-if=" dataConsultingrooms.operations.of_doctor"
                    class="input-group-text spam"
                    id="basic-addon1"
                    ><strong>DOCTOR</strong></span
                  >
                  <input
                    class="form-control form-control-sm text-center"
                    style="background-color: #ffffff; font-size: 15px"
                    disabled
                    :value="dataConsultingrooms.operations.of_doctor ? dataConsultingrooms.operations.of_doctor.name + ' ' + dataConsultingrooms.operations.of_doctor.last_name : ''"
                  />
                </div>

                <!--Boton-->
                <div class="input-group-append" style="display: flex; align-items: center; margin-top: -0.5rem">
                <div class="input-group-append" style="margin-left: 10px">
                  <button
                    @click="mtdAddService()"
                    class="btn btn-primary rounded-circle"
                    type="button"
                    style="background-color: #900052; border: 2px solid #900052"
                  >
                    <i class="far fa-cart-plus fa-sm"></i>
                  </button>
                </div>
                <div class="input-group-append" style="margin-left: 10px">
                  <button
                    @click="mtdOpenPdf(recipe.of_clinical_history_id, dataConsultingrooms.operations.of_reservation.of_patient.id)"
                    class="btn btn-primary rounded-circle"
                    type="button"
                    style="background-color: #900052; border: 2px solid #900052"
                    :disabled="isPdfButtonDisabled"
                  >
                  <i class="fas fa-solid fa-file-pdf"></i>
                  </button>
                </div>
                <div class="input-group-append" style="margin-left: 10px">
                  <button
                    @click="mtdOpenDispath()"
                    class="btn btn-primary rounded-circle"
                    type="button"
                    style="background-color: #900052; border: 2px solid #900052"
                  >
                  <i class="fas fa-pills icono-pills"></i>
                  </button>
                </div>
              </div>
                <!--Fin Boton-->

              </div>
            </div>
          </div>

          <!-- Desde CH -->
          <div class="d-flex justify-content-center mb-2">
            <div class="w-100 pt-2" style="background-color: #ffffff">
              <div class="cuerpo">
                <div class="px-4">
                  <div class="col-md-12 table-container" id="div-tabla-anular">
                    <table id="tb-anular" class="table table-sm table-bordered align-middle">
                      <caption></caption>
                      <thead class="bg-main">
                        <tr class="text-white tr-table text-center">
                          <th class="table-th-state">
                            <div>N°</div>
                          </th>
                          <th class="table-th-state">
                            <div>VIA</div>
                          </th>
                          <th class="table-th-state">
                            <div>DOSIS</div>
                          </th>
                          <th class="table-th">
                            <div>ORDEN</div>
                          </th>
                          <th class="table-th-state">
                            <div>MOLÉCULA</div>
                          </th>
                          <th class="table-th-state">
                            <div>PRESENTACIÓN</div>
                          </th>
                          <th class="table-th-state">
                            <div>CONCENTRACIÓN</div>
                          </th>
                          <th class="table-th-state">
                            <div>MEDICAMENTO</div>
                          </th>
                          <th class="table-th-state">
                            <div>DURACIÓN</div>
                          </th>
                          <th class="table-th-state">
                            <div>FRECUENCIA</div>
                          </th>
                          <th class="table-th-state">
                            <div>TOTAL</div>
                          </th>
                          <th class="table-th-state">
                            <div>PENDIENTE</div>
                          </th>
                          <!--
                          <th class="table-th-state">
                            <div>PRECIO C/IGV</div>
                          </th>
                          -->
                          <th class="table-th-state">
                            <div>ESTADO</div>
                          </th>
                          <th class="table-th-state">
                            <div>ACCIONES</div>
                          </th>
                        </tr>
                      </thead>

                      <tbody align="center" class="border-main letra">
                        <tr
                          v-if="
                            recipe === null ||
                            (recipe &&
                              recipe.recipe_details &&
                              recipe.recipe_details.length === 0)
                          "
                        >
                          <td colspan="14">No hay registros</td>
                        </tr>
                        <template v-else>
                          <tr
                            v-for="(item, index) in recipe ? recipe.recipe_details : []"
                            :key="index"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.via ? item.via.toUpperCase() : "-" }}</td>
                            <td>{{ item.dosis ? item.dosis : "-" }}</td>
                            <td>{{ item.order ? item.order.toUpperCase() : "-" }}</td>
                            <td>
                              {{ item.molecule ? item.molecule.toUpperCase() : "-" }}
                            </td>
                            <td>
                              {{
                                item.presentation ? item.presentation.toUpperCase() : "-"
                              }}
                            </td>
                            <td>
                              {{
                                item.concentration ? item.concentration.toUpperCase() : "-"
                              }}
                            </td>
                            <td>
                              {{ 
                                  item.of_productdetail && item.of_productdetail.of_product ? 
                                  item.of_productdetail.of_product.name : "-"
                              }}
                            </td>
                            <td>
                              <!--{{ item.duration ? item.duration.toUpperCase() : "-" }}-->
                              {{
                                (item.presentation && item.presentation.toUpperCase().includes('GOTA')) ? '-' : 
                                (item.duration ? item.duration.toUpperCase() : "-")
                              }}
                            </td>
                            <td>
                              <!--{{ item.frequency ? item.frequency.toUpperCase() : "-" }}-->
                              {{
                                (item.presentation && item.presentation.toUpperCase().includes('GOTA')) ? '-' : 
                                (item.frequency ? item.frequency.toUpperCase() : "-")
                              }}
                            </td>
                            <td>
                              <!--
                              {{
                                item.duration && item.frequency
                                ? Math.ceil(item.duration * item.frequency)
                                : "-"
                              }}
                              -->
                              {{
                                (item.presentation && item.presentation.toUpperCase().includes('GOTA')) ? 1 : 
                                (item.duration && item.frequency ? Math.ceil(item.duration * item.frequency) : "-")
                              }}
                            </td>
                            <td>
                              <!--
                              {{
                                item.duration && item.frequency && item.state == 1
                                  ? Math.ceil(item.duration * item.frequency)
                                  : item.pending
                              }}
                              -->
                              {{
                                (item.presentation && item.presentation.toUpperCase().includes('GOTA')) && 
                                  item.duration && item.frequency && item.state === 1
                                  ? 1 
                                  : (item.duration && item.frequency && item.state === 1 
                                  ? Math.ceil(item.duration * item.frequency) 
                                  : item.pending)
                              }}
                            </td>
                            <!--
                            <td>
                              {{
                                (item.of_productdetail.of_product.price_sale * 1.18).toFixed(2)
                              }}
                            </td>
                            -->

                            <td align="center">
                              <div class="badge text-bg-danger" v-if="item.state == 1">
                                <div class=""><strong>SIN ATENDER</strong></div>
                              </div>
                              <div
                                class="badge text-bg-warning"
                                v-else-if="item.state == 2"
                              >
                                <div class="">
                                  <strong>ATENDIDO PARCIAL</strong>
                                </div>
                              </div>
                              <div
                                class="badge text-bg-success"
                                v-else-if="item.state == 3"
                              >
                                <div class="">
                                  <strong>COMPLETADO</strong>
                                </div>
                              </div>
                            </td>
                            <td>
                              <a
                                class="btn btn-primary btn-sm rounded-circle"
                                type="button"
                                v-if="item.state != 3 "
                                style="
                                  background-color: #900052;
                                  border: 2px solid #900052;
                                "
                                @click="traerLote(item, index)"
                              >
                                <i class="far fa-plus"></i>
                              </a>

                              <a
                                @click="verDetalle(item, index)"
                                class="btn btn-primary btn-sm rounded-circle"
                                type="button"
                                style="
                                  background-color: #900052;
                                  border: 2px solid #900052;
                                  margin-left: 5px;
                                "
                              >
                                <i class="fas fa-eye"></i>
                              </a>

                              <a
                              v-if="(item.state == 1) && (item.total_quantity == 0 || item.total_quantity === null)"
                              @click=" mtdAddEdit(item, index)"
                              class="btn btn-primary btn-sm rounded-circle"
                              type="button"
                              style="
                                background-color: #900052;
                                border: 2px solid #900052;
                                margin-left: 5px;
                              "
                            >
                              <i class="fas fa-edit"></i>
                            </a>
                            
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--Carro Sale-->
          <!--
          <div class="d-flex justify-content-center">
            <div class="w-100 pt-2" style="background-color: #ffffff">
              <div
                class="text-left mb-3"
                style="color: #900052; font-size: 16px; padding-left: 22px"
              >
                <label style="margin-right: 10px; font-weight: bold" @click="toggleTabla"
                  >MEDICAMENTOS DESPACHADOS</label
                >
                <i class="fas fa-pills icono-pills" @click="toggleTabla"></i>
              </div>
              <div class="cuerpo">
                <div class="px-4">
                  <div class="col-md-12 table-container" id="div-tabla-anular">
                    <table id="tb-anular" class="table table-sm table-bordered" :class="{ 'ocultar-tabla': tablaOculta }">
                      <caption></caption>
                      <thead class="bg-main">
                        <tr class="text-white tr-table text-center">
                          <th class="table-th-state">
                            <div>GENÉRICO</div>
                          </th>
                          <th class="table-th-state">
                            <div>MARCA</div>
                          </th>
                          <th class="table-th">
                            <div>MOLECULA</div>
                          </th>
                          <th class="table-th-state">
                            <div>PRESENTACIÓN</div>
                          </th>
                          <th class="table-th-state">
                            <div>CONCENTRACIÓN</div>
                          </th>
                          <th class="table-th-state">
                            <div>DESPACHADO</div>
                          </th>
                        </tr>
                      </thead>

                      <tbody align="center" class="border-main">
                          <tr
                            v-for="(det, index) in dataConsultingrooms.operations
                              .of_reservation.of_sale &&
                            dataConsultingrooms.operations.of_reservation.of_sale.detail
                              ? dataConsultingrooms.operations.of_reservation.of_sale.detail
                              : []"
                            :key="index"
                          >
                            <template               
                            v-if="
                              det.of_product &&
                              det.of_product.product_detail
                            ">
                              <td>
                                {{ 
                                  det.of_product.product_detail.comercial ?
                                  det.of_product.product_detail.comercial.toUpperCase() : '-' 
                                }}
                              </td>
                              <td>
                                {{
                                  det.of_product.product_detail.of_brand ? 
                                  det.of_product.product_detail.of_brand.name.toUpperCase() : '-'
                                }}
                              </td>
                              <td>
                                {{
                                  det.of_product.product_detail.molecule ? 
                                  det.of_product.product_detail.molecule.toUpperCase() : '-'
                                }}
                              </td>
                              <td>
                                {{
                                  det.of_product.product_detail.presentacion ? 
                                  det.of_product.product_detail.presentacion.toUpperCase() : '-'
                                }}
                              </td>
                              <td>
                                {{
                                  det.of_product.product_detail.concentration ? 
                                  det.of_product.product_detail.concentration.toUpperCase() : '-'
                                }}
                              </td>
                              <td>
                                {{ det.quantity }}
                              </td>
                            </template>
                            <template
                              v-if="dataConsultingrooms.operations.of_reservation.of_sale.detail.length < 2">
                              <td colspan="6" style="font-size: 14px">No hay registros</td>
                            </template>
                          </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->

          <!--
          <div class="d-flex mt-2 justify-content-end">
            <span class="bg-main border-0 text-white px-4 py-1">TOTAL: S/ {{ calcularTotalPrecios() }}</span>
          </div>
          -->
        </div>
      </div>
    </div>
    <div class="container" v-else-if="view == 'carrito'">
      <cCarrito
          @mtdBack="mtdBack"
          @mtdMediDespachado="mtdMediDespachar"
          :datos="carroDispath.item" 
          :recipeId="carroDispath.recipeId"
          :destino="carroDispath.destino"
          @mtdDelete="mtdDelete"
          :client="{
            ...dataConsultingrooms.operations.of_reservation.of_patient,
            type: 'particular'
          }"
           :bead="bead"
      />
    </div>
    <div class="container" v-else-if="view == 'carritoSi'">
        <cCarritoSi
        ref="modalCarroComponent"
          @mtdBack="mtdBack"
          @mtdSendCarrito="mtdSendCarritoCliEdit"
          :recipeId="carrito.recipeId"
          :molecule="carrito.molecule"
          :detailId="carrito.detailId"
          :nameMedic="carrito.nameMedic"
        >
        </cCarritoSi>
    </div>
    <cModalLoteVue
      ref="modalLoteComponent"
      :title="modalLote.title"
      :boo_modal="modalLote.boo_modal"
      :item="modalLote.item"
      :total="modalLote.total"
      :producto="modalLote.producto"
      :idProducto="modalLote.idProducto"
      :idRecipeDetail="modalLote.idRecipeDetail"
      :price="modalLote.price"
      :id="modalLote.id"
      @mtdCloseModalLote="mtdCloseModalLote"
      @mtdMediDespachado="mtdMediDespachado"
      @datos-agregados="handleDatosAgregados"
    />
    <cModalDetalleVue
      :title="modalDeta.title"
      :boo_modal="modalDeta.boo_modal"
      :item="modalDeta.item"
      :producto="modalDeta.producto"
      @mtdCloseModalDeta="mtdCloseModalDeta"
    />

    <!--Control Interno-->
    <cModalMedicDespachadoVue
      :title="modalDispath.title"
      :boo_modal="modalDispath.boo_modal"
      :item="modalDispath.item"
      @mtdCloseModalDispath="mtdCloseModalDispath"
    />
    <SweetAlert :swal="swal" ref="SweetAlert"/>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cCarrito from "./cCarroDispath.vue";
import cCarritoSi from "./cCarritoCliEdit.vue";
import cModalLoteVue from "../Modals/cModalLote.vue";
import cModalDetalleVue from "../Modals/cModalDetalle.vue";
import cModalMedicDespachadoVue from "../Modals/cModalMedicDespachado.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
  props: {
    dataConsultingrooms: [],
    recipe: [],
    bead: {
      type: Object,
    },
  },
  data() {
    return {
      swal: null,
      view: "data",
      search: "",
      page: 0,
      sale: {
        id: "",
        patient_id: "",
        total: "",
        pending: "",
        state: "",
      },
      /** carrito */
      carrito: {
        type_patient: "",
        data: [],
        saleId: null,
        amount: [0, 0, 0],
        recipeId: 0,
        molecule: "",
        detailId: 0,
        nameMedic: "",
      },
      doctors: [],
      posEditQuantity: null,
      posEditDespacho: null,
      editedQuantity: "",
      editedDespacho: "",
      modalLote: {
        title: "Despachar",
        item: [],
        boo_modal: false,
        pos: null,
        total: null,
        producto: "",
        idProducto: null,
        idRecipeDetail: null,
        price: null,
        id: null
      },
      modalDeta: {
        title: "Detalles de Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
        producto: "",
      },
      tablaOculta: true,
      carroDispath: {
          item: [],
          recipeId: null,
          destino: null,
        },
      dispath: [],
      destino: 'SOP',
      client:null,

      //CONTROL INTERNO
      modalDispath: {
        title: "Detalles de Despacho",
        item: [],
        boo_modal: false,
        pos: null,
      },
    };
  },
  components: {
    cCarrito,
    cModalLoteVue,
    cModalDetalleVue,
    SweetAlert,
    cCarritoSi,
    cModalMedicDespachadoVue
  },
  computed: {
    isPdfButtonDisabled() {
      return Array.isArray(this.recipe.recipe_details) && this.recipe.recipe_details.length === 0;
    }
  },
  methods: {
    ...mapActions(["get", "post"]),

    //metodo retornar a Home
    returnHome: function () {
      this.$emit("mtdBack");
    },

    mtdGetDispath: function() {
      this.post({
        url: this.$store.getters.get__url + "/pharmacy/mtdGetDispath",
        token: this.$store.getters.get__token,
        params: {
          recipeId: this.recipe.id,
          patientId: this.recipe.of_patient_id,
        },
      })
        .then((response) => {
          this.dispath = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    calcularTotalPrecios() {
      let total = 0;
      if (this.recipe && this.recipe.recipe_details) {
        this.recipe.recipe_details.forEach(item => {
          if (item.of_productdetail && item.of_productdetail.of_product) {
            let precioConIVA = (parseFloat(item.of_productdetail.of_product.price_sale) * 1.18).toFixed(2);
            let multiplicador = 1;
            if(item.state == 1){
              if(item.presentation && item.presentation.toUpperCase().includes('GOTA')){
                multiplicador = 1;
              }else{
                multiplicador = item.frequency * item.duration;
              }
            } else {
              if(item.presentation && item.presentation.toUpperCase().includes('GOTA')){
                multiplicador = item.pending;
              }else{
                multiplicador = item.pending;
              }
            }

            total += precioConIVA * multiplicador;
          }
        });
      }
      return total.toFixed(2);
    },
    toggleTabla() {
      this.tablaOculta = !this.tablaOculta;
    },
    handleClickButton() {
      if (!this.recipe) {
        Swal.fire({
          text: "No hay Datos",
          icon: "warning",
          confirmButtonText: "Aceptar",
          confirmButtonColor: "#900052",
        });
      }
    },

    mtdBack(data) {
      this.carrito.type_patient = "";
      this.carrito.data = [];
      this.carrito.saleId = null;
      this.carrito.amount = [0, 0, 0];
      //this.carroDispath.item = [];
      this.view = "data";
      if(data){
        this.carroDispath.item = [];
        this.recipe.recipe_details=data;
      }
    },
    mtdMediDespachar: function (data) {
      this.carrito.type_patient = "";
      this.carrito.data = [];
      this.carrito.saleId = null;
      this.carrito.amount = [0, 0, 0];
      this.view = "data";
      if(data){
        this.carroDispath.item = [];
      }
      this.$emit("updateData");
      /* CONTROL INTERNO */
      this.mtdGetDispath();
    },
    mtdAddService: function () {
        this.view = "carrito";
    },
    mtdSendCarrito: function (carrito) {
      this.carrito.data = carrito;
      this.post({
        url: this.$store.getters.get__url + "/pharmacy/add/carrito",
        token: this.$store.getters.get__token,
        params: this.carrito,
      })
        .then((response) => {
          if (response.state == 0) {
            Swal.fire({
              title: "Registro Exitoso",
              text: "Perfecto!",
              icon: "success",
              width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
            this.mtdBack();
            this.$emit(
              "updateDataConsultingrooms",
              this.dataConsultingrooms.operations_room_id
            );
          } else {
            Swal.fire({
              title: "Registro Incorrecto",
              text: "Error!",
              icon: "error",
              width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    mtdSendCarritoCliEdit: function (carrito) {
      this.carrito.data = carrito;
      this.post({
        url: this.$store.getters.get__url + "/pharmacy/add/carritoCliEdit",
        token: this.$store.getters.get__token,
        params: this.carrito,
      })
        .then((response) => {
          if (response.state == 0) {
            Swal.fire({
              title: "Registro Exitoso",
              text: "Perfecto!",
              icon: "success",
              width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
            this.mtdBack();
            this.$emit("updateData");
          } else if(response.state == 2){
            Swal.fire({
              text: "Ya se encuentra agregado el medicamento",
              icon: "warning",
              width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          } else {
            Swal.fire({
              title: "Registro Incorrecto",
              text: "Error!",
              icon: "error",
              width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    //TABLA
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    mtdDelCarrito: function (detId) {
      Swal.fire({
        title: "¿Estás seguro que deseas eliminar este item?",
        // text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Eliminar",
        width: "400px",
      }).then((result) => {
        if (result.isConfirmed) {
          this.mtdCommitDel(detId);
        }
      });
    },

    mtdCommitDel: function (detId) {
      this.get({
        url: this.$store.getters.get__url + "/pharmacy/deleteRecipedetail/" + detId,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.state == 0) {
            // Eliminar el detalle de la venta en la interfaz
            this.dataConsultingrooms.operations.of_reservation.of_sale.detail = this.dataConsultingrooms.operations.of_reservation.of_sale.detail.filter(
              (detail) => detail.id !== detId
            );

            Swal.fire({
              title: "Eliminado",
              text: "El item ha sido eliminado correctamente.",
              icon: "success",
              width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "Hubo un error al eliminar el item.",
              icon: "error",
              width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
        })
        .catch((errors) => {
          Swal.fire({
            title: "Error",
            text: errors,
            icon: "error",
            width: "400px",
            confirmButtonColor: "rgb(170, 2, 95)",
          });
        });
    },

    //qua
    mtdEditQuantity: function (index) {
      this.posEditQuantity = index;
      this.editedQuantity = this.dataConsultingrooms.of_reservation.of_sale.detail[
        index
      ].quantity.toString();
    },
    mtdOutFocusEditPrice: function () {
      if (this.editedQuantity !== "") {
        const quantity = parseFloat(this.editedQuantity);
        if (!isNaN(quantity)) {
          this.mtdCommitEditQuantity(this.posEditQuantity, quantity);
        } else {
          // Manejar el caso en el que la entrada no es un número válido
        }
      }
      this.posEditQuantity = null;
      this.editedQuantity = "";
    },
    mtdEditDespacho: function (index) {
      this.posEditDespacho = index;
      this.editedDespacho = this.dataConsultingrooms.of_reservation.of_sale.detail[
        index
      ].quantity.toString();
    },
    mtdOutFocusEditDesp: function () {
      if (this.editedDespacho !== "") {
        const quantity = parseFloat(this.editedDespacho);
        if (!isNaN(quantity)) {
          //this.mtdCommitEditQuantity(this.posEditDespacho, quantity);
        } else {
          // Manejar el caso en el que la entrada no es un número válido
        }
      }
      this.posEditDespacho = null;
      this.editedDespacho = "";
    },
    mtdCommitEditQuantity: function (pos, quantity) {
      this.get({
        url:
          this.$store.getters.get__url +
          "/admision/editquantity/" +
          this.dataConsultingrooms.of_reservation.of_sale.detail[pos].id +
          "/" +
          quantity,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.state == 0) {
            let oldQuantity = parseFloat(
              this.dataConsultingrooms.of_reservation.of_sale.detail[pos].quantity
            );
            this.$set(this.dataConsultingrooms.of_reservation.of_sale.detail, pos, {
              ...this.dataConsultingrooms.of_reservation.of_sale.detail[pos],
              quantity: quantity.toString(),
            });

            // Calcula la diferencia, pero elimina la actualización de 'pending'
            let dif =
              parseFloat(this.dataConsultingrooms.of_reservation.of_sale.detail[pos].price) *
              (parseFloat(quantity) - parseFloat(oldQuantity));

            Swal.fire({
              title: "Correcto!!",
              text: "Editado correctamente",
              icon: "success",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "",
              icon: "error",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
        })
        .catch((errors) => {
          Swal.fire({
            title: "Error",
            text: errors,
            icon: "error",
            confirmButtonColor: "rgb(170, 2, 95)",
          });
        });
    },
    traerLote(item, pos) {
        this.post({
          url: this.$store.getters.get__url + "/pharmacy/filterById/stockProductId",
          params: { productdetail: item.of_product_id },
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.state == 0 && response.data.length > 0) {
              this.modalLote.boo_modal = true;
              this.modalLote.pos = pos;

              this.modalLote.item = response.data;
              /*
              this.modalLote.total =
                item.duration && item.frequency && item.state == 1
                  ? Math.ceil(item.duration * item.frequency)
                  : item.pending;
              */
              this.modalLote.total = (item.presentation && item.presentation.toUpperCase().includes('GOTA')) && 
              item.duration && item.frequency && item.state === 1 ? 1 
              : (item.duration && item.frequency && item.state === 1 
                ? Math.ceil(item.duration * item.frequency) 
                : item.pending);
              this.modalLote.producto = item.of_productdetail.of_product.name;
              this.modalLote.idProducto = item.of_product_id;
              this.modalLote.idRecipeDetail = item.id;
              this.modalLote.price = item.of_productdetail.of_product.price_sale;
              this.modalLote.id = item.of_productdetail.of_product.id;
              
              this.$nextTick(() => {
                this.$refs.modalLoteComponent.validarAutomaticamente();
                });
              
            } else {
              this.modalLote.boo_modal = false;
              Swal.fire({
                text: "Sin Stocks",
                icon: "warning",
                confirmButtonColor: "rgb(170, 2, 95)",
              });
            }
          })
          .catch((errors) => {
            Swal.fire({
              title: "Error",
              text: errors,
              icon: "error",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          });
    },

    verDetalle(item, pos) {
      this.post({
        url: this.$store.getters.get__url + "/pharmacy/recetasDetalle",
        params: { productdetail: item.id },
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.res.length > 0) {
            this.modalDeta.boo_modal = true;
            this.modalDeta.pos = pos;

            this.modalDeta.item = response.res;
            this.modalDeta.producto = response.product;
          } else {
            this.modalDeta.boo_modal = false;
            Swal.fire({
              text: "Sin Medicamentos Despachados",
              icon: "warning",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
        })
        .catch((errors) => {
          Swal.fire({
            title: "Error",
            text: errors,
            icon: "error",
            confirmButtonColor: "rgb(170, 2, 95)",
          });
        });
    },

    mtdCloseModalDeta: function () {
      this.modalDeta = {
        title: "Detalles de Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdCloseModalLote: function (item) {
      this.modalLote = {
        title: "Despachar",
        item: [],
        boo_modal: false,
        pos: null,
      };
      if (item && Object.keys(item).length > 0) {
        const index = this.recipe.recipe_details.findIndex((date) => date.id == item.id);
        if (index !== -1) {
          this.recipe.recipe_details[index].state = item.state;
          this.recipe.recipe_details[index].pending = item.pending;
        }
      }
    },
    mtdMediDespachado: function () {
      this.$emit("updateData");
    },

    mtdAddEdit: function (item) {
        //this.carrito.saleId = this.dataConsultingrooms.operations.of_reservation.of_sale.id;
        this.carrito.recipeId = this.recipe.id;
        //this.carrito.molecule= item.of_productdetail.of_product.name;
        this.carrito.molecule = (item.molecule ? item.molecule.toUpperCase() : '') + '-' + (item.presentation ? item.presentation.toUpperCase() : '') + '-' + (item.concentration ? item.concentration.toUpperCase() : '');
        this.carrito.nameMedic = item.of_productdetail.of_product.name;
        this.carrito.detailId= item.id;
        /*
        this.view = "carritoSi";
        this.$nextTick(() => {
            this.$refs.modalCarroComponent.mtd_filter_product();
        });
        */
        this.mtdGetDataSubSub();
    },

    mtdGetDataSubSub: function () {
      const encodedQuery = encodeURIComponent(this.carrito.molecule);
      this.post({
        url:
          this.$store.getters.get__url +
          "/pharmacy/" +
          0 +
          "/" +
          null +
          "/subsubcategoriesCliEdit?search_product=" +
          encodedQuery,
        params: { nameMedic: this.carrito.nameMedic},
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if(response.data.length > 0){
            this.view = "carritoSi";
            this.$nextTick(() => {
                this.$refs.modalCarroComponent.mtd_filter_product();
            });
          }else{
            this.$refs.SweetAlert.showWarning("Sin Medicamentos Similares");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    handleDatosAgregados(datos) {
      //const existe = this.carroDispath.item.some(item => item.idProducto === datos.idProducto);
      //console.log(this.carroDispath);
      const existe = this.carroDispath.item.some(item => item.idRecipeDetail === datos.idRecipeDetail);

      if (existe) {
          Swal.fire({
            text: "Ya existe agregado ese Medicamento en el Carrito",
            icon: "warning",
            confirmButtonColor: "rgb(170, 2, 95)",
          });
          this.mtdCloseModalLote();
      } else {
          this.carroDispath.item.push(datos);
          this.carroDispath.recipeId = this.recipe.id;
          this.carroDispath.destino = this.destino;
          this.view = "carrito";
          this.mtdCloseModalLote();
      }
    },

    mtdDelete(idProducto) {
      const indice = this.carroDispath.item.findIndex(item => item.idProducto === idProducto);

      if (indice !== -1) {
        this.carroDispath.item.splice(indice, 1);
        //console.log("Elemento eliminado:", idProducto);
      } 
    },

    mtdOpenPdf(hc, patient) {
      window.open(
          this.$store.getters.get__url + "/pharmacy/recetasPdf/" + hc + "/" + patient,
          "_blank"
      );
    },

    mtdOpenDispath() {
      this.post({
        url: this.$store.getters.get__url + "/pharmacy/mtdGetDispath",
        token: this.$store.getters.get__token,
        params: {
          recipeId: this.recipe.id,
          patientId: this.recipe.of_patient_id,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          this.modalDispath.boo_modal = true;
          this.modalDispath.item = response.data;
        } else {
            this.modalDispath.boo_modal = false;
            Swal.fire({
              text: "Sin Medicamentos Despachados",
              icon: "warning",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
      })
      .catch((errors) => {
        console.log(errors);
      });
    },

    mtdCloseModalDispath: function () {
      this.modalDispath = {
        title: "Detalles de Despacho",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

  },
};
</script>
<style scoped>
.tr-table > th > div {
  background: #900052;
  border-radius: 7px;
  padding: 0px;
  margin: 4px;
  height: 45px;
  padding-top: 10px;
}

.ocultar-tabla {
  display: none;
}

.tr-table > th {
  padding: 0px;
}
.tbody-table > td > div {
  background: #eaecee;
  border-radius: 15px;
  padding: 0px;
  margin: 4px;
  height: 45px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.tbody-table > td {
  padding: 0px;
}

.tbody-table > td > div > div {
  border-radius: 10px;
}
.tbody-table1 > td > div {
  background: white;
  border-radius: 15px;
  padding: 0px;
  margin: 4px;
  height: 45px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.tbody-table1 > td {
  padding: 0px;
}

.tbody-table1 > td > div > div {
  border-radius: 10px;
}
.acciones {
  background-color: #900052;
  border-radius: 10px;
}

.table-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: darkgray lightgray;
}

.table-container::-webkit-scrollbar {
  width: 1px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: darkgray;
}

.table-container::-webkit-scrollbar-track {
  background-color: lightgray;
}

.custom-margin {
  margin-left: 5px;
}

.spam {
  background: #900052;
  color: #ffffff;
  width: 90px;
  line-height: 20px;
  justify-content: center;
  border-radius: 1rem;
}

@media (max-width: 1000px) {
  .responsive-search {
    flex-direction: column;
  }
  .responsive-search > .input-group {
    width: 100% !important;
  }

  .table-container > table > thead {
    font-size: 12px !important;
  }

  .table-container > table > tbody {
    font-size: 11px !important;
  }
}
.text-doctor {
  font-weight: bold;
  font-size: 14px;
  color: #900052;
}

.text-horario {
  font-weight: bold;
  font-size: 11px;
  color: #808080;
}

.header-main {
  background-color: #900052;
}
#div-tabla-cola {
  border: 1px solid #aaa;
  height: 300px;
  background: #f1f2f3;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#div-tabla-cola::-webkit-scrollbar {
  -webkit-appearance: none;
}

#div-tabla-cola::-webkit-scrollbar:vertical {
  width: 10px;
}

#div-tabla-cola::-webkit-scrollbar-button:increment,
#div-tabla-cola::-webkit-scrollbar-button {
  display: none;
}

#div-tabla-cola::-webkit-scrollbar:horizontal {
  height: 10px;
}

#div-tabla-cola::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#div-tabla-cola::-webkit-scrollbar-track {
  border-radius: 10px;
}
#content-timeline {
  border: 1px solid #aaa;
  height: 420px;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#content-timeline::-webkit-scrollbar {
  -webkit-appearance: none;
}

#content-timeline::-webkit-scrollbar:vertical {
  width: 10px;
}

#content-timeline::-webkit-scrollbar-button:increment,
#content-timeline::-webkit-scrollbar-button {
  display: none;
}

#content-timeline::-webkit-scrollbar:horizontal {
  height: 10px;
}

#content-timeline::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#content-timeline::-webkit-scrollbar-track {
  border-radius: 10px;
}

@media (max-width: 992px) {
  .flex-row {
    flex-direction: column !important;
    align-items: center;
    padding-top: 20px !important;
  }

  #div-listcola {
    width: 300px !important;
    margin-bottom: 20px !important;
  }
}

.letra {
  font-size: 12.5px;
}
</style>

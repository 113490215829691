<template>
  <div>
    <div class="container" v-if="page == 'venta'">
      <div class="row" style="text-align: end">
        <div id="btn-home" class="col-md-12 mt-3 mb-5">
          <button type="button" class="btn bg-main text-white" @click="retroceder">
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div
        id="div-modal-venta"
        style="margin-top: -15px"
        class="d-flex justify-content-center"
      >
        <div id="div-venta" class="card w-100">
          <div
            class="card-header text-white h5 text-center"
            style="background: #900052"
          >
            <strong>VENTA </strong>
          </div>
          <div class="card-body" style="background-color: #e6e6e6">
            <div class="row" v-if="page == 'venta'">
              <div class="col-md-6" id="div-venta-body">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-1">
                    <span
                      class="input-group-text"
                      style="background: #900052; color: #ffffff"
                      ><i class="fas fa-search"></i
                    ></span>
                    <input
                      type="search"
                      id="search_product"
                      class="form-control"
                      placeholder="Digitar Producto"
                      aria-describedby="search_product"
                      v-model="search_product"
                      @keyup="mtd_filter_product"
                    />
                  </div>
                </div>

                <div class="row py-1" id="list-product">
                <div
                    v-for="(item, index) in data_product_filter"
                    :key="index"
                    class="col-md-12 py-1 d-flex align-items-center"
                    id="item-product"
                    style="background-color: #e6e6e6; height: 80px"
                  >
                    <div
                      class="p-1 d-flex flex-column w-100 justify-content-center"
                      style="background-color: #ffffff; height: 100%"
                      @click="mtdSelectSubsubcatergorie(item)"
                    >
                      <div class="align-items-center" id="name-product">
                        <p class="d-flex align-items-center mb-0 m-1">
                          <strong
                            class="mb-0 bg-secondary text-white"
                            style="text-align: CENTER; width: 12%; border-radius: 10px"
                          >
                            S/{{ item.price_sale }}
                          </strong>
                          <span style="width: 5%">&nbsp;</span>
                          <strong
                            class="mb-0 item-name custom-tooltip"
                            data-toggle="tooltip"
                            :data-placement="'top'"
                            :title="item.name"
                            style="width: 88%"
                            :class="{ 'text-success': item.total_quantity > 5, 
                            'text-danger': item.total_quantity === 0, 
                            'text-custom-light-green': item.total_quantity <= 5 && item.total_quantity > 0 }"
                          >
                            {{ item.name }}&nbsp;
                            <span style="font-weight: bold; font-size: 14px;">({{ item.total_quantity }})</span>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- doctores -->
              <div class="col-md-3" v-if="this.doctors.length != 0">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span
                      class="input-group-text rounded-pill w-100"
                      style="background: #900052; color: #ffffff; padding-left: 25px"
                    >
                      <strong>Médico</strong>
                    </span>
                    <strong>Seleccione un médico</strong>
                  </div>
                </div>
                <div class="col-md-12 list-doctor">
                  <div class="container-list">
                    <div class="" v-if="doctors.length > 0">
                      <div
                        class="box item-doctor"
                        v-for="(item, index) in doctors"
                        :key="index"
                        @click="mtdSelectDoctor(item)"
                      >
                        <p class="text-doctor">
                          DR(A).
                          {{
                            item.last_name != null
                              ? item.last_name.toUpperCase()
                              : ""
                          }}
                          {{ item.name.toUpperCase() }}
                        </p>
                      </div>
                    </div>
                    <div class="" v-else>
                    </div>
                  </div>
                </div>
              </div>
              <!-- carrito -->
              <div class="col-md-6" id="div-venta-body">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span
                      class="input-group-text rounded-pill w-100"
                      style="background: #900052; color: #ffffff; padding-left: 25px"
                      ><strong>Monto</strong></span
                    >
                  </div>
                </div>
                <div class="col-md-12 list-monto">
                  <div class="container-list">
                    <div class="" v-if="carrito.length > 0">
                      <div
                        class="box item-monto"
                        v-for="(item, index) in carrito"
                        :key="index"
                      >
                        <p class="text-monto">({{ item.quantity }}) {{ item.producto}}</p>
                        <div class="input-group" style="">
                          <span class="input-group-text" :class="posEditPrice != index && posobs!=index &&
                               itemDiscount!=index ?'':'col-md-12'" style="color: #8a8a97">
                            <strong v-if="posEditPrice != index && posobs!=index &&
                               itemDiscount!=index"
                              >
                              Precio S/ {{ parseFloat(item.price).toFixed(2)}} <br/>
                              Desc. S/ {{ parseFloat(item.montodescuento).toFixed(2)}}<br/>
                              SubTotal  S/
                              {{
                                parseFloat(item.subtotal).toFixed(2)
                              }}</strong
                            >
                            <input
                              v-if="posEditPrice == index"
                              type="text"
                              style="background: white"
                              class="form-control-sm"
                              @keyup.enter="mtdOutFocusEditPrice(index)"
                              v-model="item.price"
                              @keypress="validateNumberInput"
                            />
                            <input
                            v-if="posobs==index"
                              type="text"
                              style="background: white; width: 200%;"
                              class="form-control-sm"
                              @keyup.enter="mtdOutFocusEditObs(index)"
                              @blur="mtdOutFocusEditObs(index)"
                              v-model="item.obs"
                              placeholder="Motivo de Descuento."
                            />
                            <div v-if="itemDiscount == index" style="display: flex; align-items: center;">
                            <input
                              type="text"
                              style="background: white; width:90px;  margin-right:5px;"
                              class="form-control-sm"
                              @keyup.enter="AplicarDescuento(index)"
                              v-model="item.descuento"
                              placeholder="Descuento"
                            />
                            <label style="margin:0 5px;">
                              <input type="radio" v-model="item.tipodescuento" value="porcentaje"  /> %
                            </label>
                            <label style="margin:0 5px;">
                              <input type="radio" v-model="item.tipodescuento" value="monto" /> Monto
                            </label>
                          </div> 
                          </span>
                          &nbsp;
                          <div
                            class="form-control text-right"
                            v-if="posEditPrice != index && posobs!=index && 
                            itemDiscount!=index"
                          >
                            <!--
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdAddQuantity(index)"
                            >
                              <i class="fas fa-plus text-white"></i>
                            </button>
                            -->
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdDelQuantity(index)"
                            >
                              <i class="fas fa-minus text-white"></i>
                            </button>
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdChangePrice(index)"
                            >
                              <i class="fas fa-triangle text-white"></i>
                            </button>
                            <button
                            type="button"
                            class="rounded-circle m-1"
                            @click="mtdChangeDiscount(index)"
                            >
                            <i class="fa fa-percent text-white" aria-hidden="true"></i>
                          </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12"></div>
            </div>
            <div class="row" v-else-if="page == 'programar'">escoger camas y citas</div>
         </div>
         <div
            align="right"
            class="card-footer text-white"
            style="background: #900052; font-size: 20px"
          >
          <span  v-if="!posDesc && !posMotivo"
              class="btn border border-white text-white"
              style="margin-left: 20px"
              @click="addDsc()"
              >
              <strong>DESC.</strong>
            </span>
            &nbsp;
            <strong v-if="!posDesc && !posMotivo">SUBTOTAL: S/  {{ cpTotal }}</strong
            > &nbsp;
            <strong v-if="!posDesc && !posMotivo">DESC: - S/  {{parseFloat(montoDescuento).toFixed(2)}}</strong
            > &nbsp;
            <!-- <strong v-if="!posDesc && !posMotivo">IGV:  S/  {{igv}}</strong
            > &nbsp; -->
            <strong v-if="!posDesc && !posMotivo">TOTAL: S/ {{ cpTotalDesc}}</strong
            >
            <strong v-if="posDesc">
              DESC:&nbsp;
              <input
              type="text"
                style="background: white"
                class="form-control-sm"
                placeholder="Descuento"
                @keyup.enter="mtdOutFocusDesc()"
                v-model="dsc"
                @keypress="validateNumberInput"
              />
              <label style="margin:0 5px;">
                <input type="radio" v-model="TypeDiscount" value="porcentaje"/>%
              </label>
              <label style="margin:0 5px;">
                <input type="radio" v-model="TypeDiscount" value="monto"/> Monto
              </label>
              </strong>
            <strong v-if="posMotivo">
              <textarea class="form-control-xl"
              style="background: white"
              placeholder="Motivo"
                v-model="motivo"               
                @keyup.enter="mtdOutFocusMotivo()"
                @blur="mtdOutFocusMotivo()"
                ></textarea>
              <!-- <input
              type=""
                style="background: white"
                class="form-control-sm"
                placeholder="Motivo"
                v-model="motivo"               
                @keyup.enter="mtdOutFocusMotivo()"
                @blur="mtdOutFocusMotivo()"
              /> -->
              </strong
            >
            <span  v-if="!posDesc && !posMotivo"
              class="btn border border-white text-white"
              style="margin-left: 20px"
              @click="verifyZero()"
              >
              <strong>OK</strong></span>
          </div>
        </div>
      </div>
      <br />
    </div>
    <SweetAlert :swal="swal" @confirmed="confirmed" ref="SweetAlert"/>
    <ConsultorioVue
      v-if="page == 'consultas'"
      :elementProcess="elementProcess"
      :key="componentKey"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <SopVue
      v-if="page == 'sop'"
      :elementProcess="elementProcess"
      :key="componentKey"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <Laboratorio
      v-if="page == 'laboratorio'"
      :elementProcess="elementProcess"
      :key="componentKey"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
    />
    <Imagenes
      v-if="page == 'imagenes'"
      :elementProcess="elementProcess"
      :key="componentKey"
      @retroceder="retroceder"
      @mtdNextProcess="mtdNextProcess"
      @mtdBehindProcess="mtdBehindProcess"
      :client="client"
    />
    <PagoVue
      v-if="page == 'pago'"
        :castCarrito="castCarrito"
        :key="componentKey"
        :dsc="dsc"
        :montodescuento="montoDescuento"
       :permissionValue="permissionValue"
       @mtdBehindProcess="mtdBehindProcess"
       @mdtCommitPayment="mdtCommitPayment"
       :client="client"
    /> 
    <cModalCarroRecipeVue
      :title="modalReci.title"
      :boo_modal="modalReci.boo_modal"
      :item="modalReci.item"
      @registrarReci="mtdSelectSubCar"
      @mtdCloseModalDeta="mtdCloseModalReci"
     
      />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import PagoVue from "./Venta/Pago.vue"; 
import Imagenes from "./Venta/Imagenes.vue";
import Laboratorio from "./Venta/Laboratorio.vue";
import ConsultorioVue from "./Venta/Consulta.vue";
import SopVue from "./Venta/Operaciones.vue";

import cModalCarroRecipeVue from "../FarmaciaOjos/Modals/cModalCarroVenta.vue";
export default {
  name: "c-admision-venta",
  
  components: {
    SweetAlert,
    PagoVue,
    Imagenes,
    Laboratorio,
    ConsultorioVue,
    SopVue,

    cModalCarroRecipeVue,
  },
  data() {
    return {
      TypeDiscount:"porcentaje",
      validaDescuento:0,
      validaDescuentoGeneral:0,
      itemDiscount:null,
      dsc:"0",
      montoDescuento:"0",
      motivo:"",
      componentKey:0,
      posDesc:false,
      posMotivo:false,
      page: "venta",
      view: "table",
      dataSubSubCategories: [],
      data_product_filter: [],
      search_product: "",
      setTimeoutSearch: "",
      posobs:null,
      doctors: [],
      carrito: [],
      item_carrito: {},
      castCarrito: [],
      elementProcess: {},
      users: [],
      medicos:[],
      posEditPrice: null,
      permissionValue: null,
      swal:null,
      isconfirmed:null,

      modalReci: {
          title: "Medicamento",
          item: [],
          boo_modal: false,
          pos: null,
      },
    };
  },
  props: {
    boxes:Array,
    bead: Object,
    client: Object,
  },
  computed: {
    cpTotal() {
      this.validaDescuento=0;
      if(this.carrito.length==0){
        this.dsc=0;
      }
      let total = 0;
      this.carrito.forEach((element) => {
       total+=element.subtotal;
       if((parseFloat(element.montodescuento))>0){
        this.validaDescuento=1;
       }
      });
      if(parseFloat(this.montoDescuento)>0){
        this.validaDescuentoGeneral=1;
      }else{
        this.validaDescuentoGeneral=0;
      }
      // return parseFloat((total/1.18)).toFixed(2);
      return parseFloat((total)).toFixed(2);
    },
    cpTotalDesc(){
      this.validaDescuento=0;
      if(this.carrito.length==0){
        this.dsc='0';
      }
      let total = 0;
      this.carrito.forEach((element) => {
       total+=element.subtotal;
       if((parseFloat(element.montodescuento))>0){
        this.validaDescuento=1;
       }
      });
      let totalsig=total/1.18;
       let final =parseFloat(totalsig-this.montoDescuento);
       let TotalFinal=final +(final*0.18);
      return parseFloat(TotalFinal).toFixed(2);
    },
    igv(){
      if(this.carrito.length==0){
        this.dsc='0';
      }
      let total = 0;
      this.carrito.forEach((element) => {
       total+=element.subtotal;
       if((parseFloat(element.montodescuento))>0){
        this.validaDescuento=1;
       }else{
        this.validaDescuento=0;
       }
      });
      let totalsig=total/1.18;
      let final =parseFloat(totalsig-this.montoDescuento);
      return parseFloat(final*0.18).toFixed(2);
    }
  },
  created() {
    // this.mtdGetDataSubSubCategories();
    //this.mtdGetDataPerAndData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    updateTipodescuento(tipo, index) {
      this.carrito[index].tipodescuento = tipo;
    },

    confirmed(data){
     if (data=="1") { 
            this.page = "venta";
            this.search_product = "";
          }
    },
    mtdGetDataSubSubCategories: function (q) {
      this.post({
        url:
          this.$store.getters.get__url +
          "/pharmacy/products?search_product=" +
          q,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          let data = [];
          response.data.data.forEach((element) => {
            let tmpPrice = parseFloat(element.price_sale); // Obtener el precio de venta actual
            let ivaAmount = tmpPrice * 0.18; // Calcular el 18% de IVA
            let newPrice = tmpPrice + ivaAmount; // Calcular el nuevo precio con el IVA
            element.price_sale = newPrice.toFixed(2); // Redondear el nuevo precio a 2 decimales
            data.push(element);
            });
           this.dataSubSubCategories = data;
           this.data_product_filter = data;
           this.medicos= response.data.medicos;
        })
        .catch((errors) => { console.log(errors); });
    },
    retroceder() {
      this.$emit("mtdChangePage", "home");
    },
     mtd_filter_product: function () {
      if (this.search_product.length >= 5 && this.search_product != "") {
        let q = this.search_product.toString().toUpperCase();
        this.mtdGetDataSubSubCategories(q);
        this.data_product_filter = [];         
         this.dataSubSubCategories.forEach((element) => {
          let name = element.name.toString().toUpperCase();
          if (name.includes(q)) {
            this.data_product_filter.push(element);
           }
         });
      } 
      else {
         this.data_product_filter = this.dataSubSubCategories;
       }
    },
    search() {
      clearTimeout(this.setTimeoutSearch);
      this.setTimeoutSearch = setTimeout(this.mtdGetDataSubSubCategories, 360);
    },
    addDsc(){
      if (this.carrito.length > 0) {
        if(this.validaDescuento==1){
          this.$refs.SweetAlert.showWarning("Ya Realizo Descuentos por Item.");  
        return false;
        }
        this.$refs.SweetAlert.showConfirmSimple2(
        "",
        "Va Registrar Un Descuento Global",
        "warning",
        "Si, Confirmar!"
      ).then((result) => {
        if (result.value) {
      const todosMayoresQueCero = this.carrito.every(
        (item) => parseFloat(item.price) > 0
      );
      if (todosMayoresQueCero) {
        this.posDesc=true;
        return true;
      } else {
        this.$refs.SweetAlert.showWarning("Hay Productos con precio 0.00");  
        return false;
      }
      }
       });
    }
      else{
        this.$refs.SweetAlert.showWarning("No hay productos en el carrito");  
        return false;
      }
    },
    /** carrito */
    mtdSelectSubsubcatergorie: function (item) {
      this.modalReci.boo_modal = true;
      let bo = false;
      if (this.carrito.length == 0) {
        this.item_carrito = {
          id: item.id,
          name: item.name,
          price: item.price_sale,
          price_v:item.price_sale,
          obs:"",
          descuento:"0",
          subtotal:item.price_sale*1,
          tipodescuento:"porcentaje",
          montodescuento:"0",
          quantity: 1,
          servicio: {
            id: item.of_service_id,
            name:item.service.name,
          },

          doctor: {
            id: null,
            name: null,
          },
          dataRe: {
              frecuencia:0,
              //duracion: 0,
          },
        };
        
        this.modalReci.item = item;
        if (this.modalReci.boo_modal !== true){
          this.carrito.push(this.item_carrito);
          this.actulizarDscGlobal();
       }

        bo = true;
      } else {
        this.carrito.forEach((element) => {
          
          if (element.id == item.id) {
          this.modalReci.boo_modal = false;
          this.$refs.SweetAlert.showWarning("Ya está agregado el Medicamento en el Carrito");
            //element.quantity = element.quantity + 1;
            bo = true;
          }
        });
      }
      if (!bo) {

        this.item_carrito = {
          id: item.id,
          name: item.name,
          price: item.price_sale,
          price_v:item.price_sale,
          obs:"",
          descuento:"0",
          tipodescuento:"porcentaje",
          montodescuento:"0",
          subtotal:item.price_sale*1,
          quantity: 1,
          servicio: {
            id: item.of_service_id,
            name:item.service.name,
          },
          doctor: {
            id: null,
            name: null,
          },
          dataRe: {
              frecuencia:0,
              //duracion: 0,
          },
        };
        this.modalReci.item = item;
        if (this.modalReci.boo_modal !== true){
          this.carrito.push(this.item_carrito);
          this.actulizarDscGlobal();
      }
        /*
        if (this.stock.length == 0) {
          this.carrito.push(this.item_carrito);
        }
        */
        bo = false;
      }
      this.search_product = "";
    },

    mtdSelectSubCar: function (item) {
      this.carrito.push(item);
      this.actulizarDscGlobal();
    },

    mtdCloseModalReci: function () {
      this.modalReci = {
        title: "Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdSelectDoctor: function (item) {
      this.item_carrito.doctor.id = item.id;
      this.item_carrito.doctor.name = item.name + " " + item.last_name;
      this.doctors = [];
      this.carrito.push(this.item_carrito);
    },
    mtdAddQuantity: function (pos) {
      this.carrito[pos].quantity = this.carrito[pos].quantity + 1;
      this.carrito[pos].subtotal = this.carrito[pos].quantity *this.carrito[pos].price;
      this.AplicarDescuento1(pos);
    },
    mtdDelQuantity: function (pos) {
      let cantidad = this.carrito[pos].quantity;

        let temp = [];
        this.carrito.forEach((element, index) => {
          if (index != pos) {
            temp.push(element);
          }
        });
        this.carrito = temp;
      
    },

    /** programas citas o reservas */
    mtdNextStep: function (step) {
        if (this.carrito.length > 0) {
          if (step == "2") {
            this.castCarrito = [];
            this.carrito.forEach((element) => {
              element.data = [];
              //element.programado = false; 
              element.actualPage = false;
              
              element.programado = true;
              element.component = "pago";
              
              this.castCarrito.push(element);
            });
            //poner  los de pago al ultimo
            this.castCarrito.sort((a, b) => {
              if (a.component === "pago" && b.component !== "pago") {
                return 1;
              } else if (a.component !== "pago" && b.component === "pago") {
                return -1;
              } else {
                return 0;
              }
            });
            
            this.mtdProcessCastCarrito();
          }
        } else {
          this.$refs.SweetAlert.showWarning("Seleccione un Medicamento");  
        }
    },

    verifyZero: function () {
      const todosMayoresQueCero = this.carrito.every(
        (item) => parseFloat(item.price) > 0
      );
      if (todosMayoresQueCero) {
        this.mtdNextStep("2");
        return true;
      } else {
        this.$refs.SweetAlert.showWarning("Hay valores iguales o menores que 0.00");  
        return false;
      }
    },
    mtdProcessCastCarrito: function () {
      this.componentKey++;
    // console.log("cascarito en recorrido",this.castCarrito);
      let boo = false;
      for (let index = 0; index < this.castCarrito.length; index++) {
        const element = this.castCarrito[index];
        if (element.actualPage == true) {
          boo = true;
          element.actualPage = false;
          if (index == this.castCarrito.length - 1) {
            this.page = "pago";
          } else {
            this.elementProcess={};
            this.elementProcess = this.castCarrito[index + 1];
            this.castCarrito[index + 1].actualPage = true;
            this.page = this.castCarrito[index + 1].component;
            index = this.castCarrito.length;
          }
        }
      }
      if (boo == false) {
        this.castCarrito[0].actualPage = true;
        this.elementProcess={}; 
        this.elementProcess = this.castCarrito[0];
        // this.page = "pago";  sin sreserva
        this.page=this.castCarrito[0].component;
      }
    },
    mtdNextProcess: function (process) {
      this.componentKey++;
      this.page="";
      for (let index = 0; index < this.castCarrito.length; index++) {
        let element = this.castCarrito[index];
        if (element.id ==process.id) {
          this.castCarrito[index] = process;
        }
      }
      this.mtdProcessCastCarrito();
    },
    mtdBehindProcess: function () {
      this.componentKey++;
      let boo = false;
      for (let index = 0; index < this.castCarrito.length; index++) {
        const element = this.castCarrito[index];
        if (element.actualPage) {
          if (index != 0) {
            boo = true;
            this.elementProcess = this.castCarrito[index - 1];
            element.actualPage = false;
            this.castCarrito[index - 1].actualPage = true;
            this.page = this.castCarrito[index - 1].component;
            break;
          } else {
            break;
          }
        }
      }
      if (this.page == "pago") {
       
        //let cat = this.castCarrito[this.castCarrito.length - 1].servicio.id;
        let cat = 5;
        // console.log(this.castCarrito[this.castCarrito.length - 1],"sddd");
        // console.log(cat,"cat");
         if (cat==5||cat ==6||cat ==7||cat==8) {
          // console.log("..edentoro")
          this.page = "venta";
          this.search_product = "";
        } else {
          this.castCarrito[this.castCarrito.length - 1].actualPage = true;
          this.elementProcess = this.castCarrito[this.castCarrito.length - 1];
          this.page = this.castCarrito[this.castCarrito.length - 1].component;
        }
      }
      else 
      if (boo==false) {
         this.$refs.SweetAlert.showConfirmVenta("¿Desea volver al menú?","¡Se perderá todo los datos seleccionados!","warning","Si")
      }
    },

    mdtCommitPayment: function (payment, chkPatiente, planilla, garantia, impuestos, selectedVoucherCancel) {
      this.post({
        url: this.$store.getters.get__url + "/pharmacy/ventas/storeHome",
        token: this.$store.getters.get__token,
        params: {
          data: this.castCarrito,
          payment: payment,
          patient: this.client,
          bead: this.bead,
          campu_id: this.$store.getters.get__campus,
          chkPatiente: chkPatiente,
          planilla: planilla,
          garantia: garantia,
          impuestos: impuestos,
          motivoDesc:this.motivo,
          descuento:this.dsc,
          tipodescuento:this.TypeDiscount,
          montodescuento:this.montoDescuento,
          validardescuento:this.validaDescuentoGeneral,
          selectedVoucherCancel: selectedVoucherCancel
        },
      })
        .then((response) => {
          if (response.state == 0) {
            if (response.data != null) {
              Swal.fire({
                title: 'Venta Finalizada',
                text: 'Seleccione una opción',
                icon: 'success',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: '<i class="fas fa-file-pdf"></i> Imprimir PDF',
                cancelButtonText: '<i class="fas fa-envelope"></i> Enviar a Correo',
                denyButtonText: '<i class="fab fa-whatsapp"></i> Enviar a WhatsApp',
                customClass: {
                  confirmButton: 'btn btn-primary', 
                  cancelButton: 'btn btn-secondary', 
                  denyButton: 'btn btn-success', 
                  actions: 'd-flex justify-content-between gap-2'
                },
                buttonsStyling: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
                showCloseButton: true
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // Acción PDF
                  if (response.data.document == 'TICKET') { 
                    window.open(this.$store.getters.get__url + "/Admision/ticket/" + response.data.id, "_blank");
                  } else {
                    let res = response.data.sunat;
                    window.open(res.json.enlace_del_pdf_ticket, "_blank");
                  }
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  // Acción para correo
                  window.location.href = 'mailto:correo@example.com';
                } else if (result.dismiss === Swal.DismissReason.deny) {
                  // Acción para WhatsApp
                  let voucherLink;
                  if (response.data.document == 'TICKET') {
                    voucherLink = this.$store.getters.get__url + "/Admision/ticket/" + response.data.id;
                  } else {
                    let res = response.data.sunat;
                    voucherLink = res.json.enlace_del_pdf_ticket;
                  }
                  const cleanedPhone = this.cleanPhoneNumber(this.client.celphone);
                  const whatsappPhone = '+51' + cleanedPhone; 
                  window.open(this.generateWhatsAppLinks(this.client.fullName, whatsappPhone, voucherLink), '_blank');
                  //console.log(this.client);
                }
              });
            }
            this.$emit("mtdCommitPayment", this.bead);
          } else {
            alert(response.exception);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },

    generateWhatsAppLinks(patient, phone, voucherLink) {
      const message = `Hola, ${patient}. Te saluda BM CLINICA DE OJOS, te enviamos tu comprobante de pago: ${voucherLink}`;
      const encodedMessage = encodeURIComponent(message);
      const whatsappLink = `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
      return whatsappLink;
    },

    cleanPhoneNumber(phone) {
      return phone.replace(/\D/g, '');
    },




    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
    /** temporal cambiar precio */
    mtdChangePrice: function (pos) {
      this.posEditPrice = pos;
    },
    mtdOutFocusEditPrice: function (index) {
      let carrito =this.carrito[index];
      this.carrito[index].subtotal=carrito.price*carrito.quantity;
      this.AplicarDescuento1(index);
      this.posEditPrice = null;
    },
     AplicarDescuento: function(index) {
         let item=null;
         item = this.carrito[index];
         item.subtotal=item.quantity*item.price;
        if (item.tipodescuento === "monto" && item.descuento != '0') {
            let subtotalSING = item.subtotal / 1.18;
            subtotalSING = subtotalSING - parseFloat(item.descuento);
            item.montodescuento = parseFloat(item.descuento);
            item.subtotal = subtotalSING + (subtotalSING * 0.18);
        } else if (item.tipodescuento === "porcentaje" && item.descuento != '0') {
            let subtotalSING = item.subtotal / 1.18;
            const porcentajeDescuento = parseFloat(item.descuento) / 100;
            subtotalSING = subtotalSING - (subtotalSING * porcentajeDescuento);
            item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
            item.subtotal = subtotalSING + (subtotalSING * 0.18);
        } else if (item.descuento == '0') {
            item.montodescuento ='0';
            item.subtotal = item.quantity * parseFloat(item.price);
        }
        if (item.price < 0) {
            item.price = 0;
        }
        this.carrito[index] = item;
        this.itemDiscount = null;
        this.actulizarDscGlobal();
        if (parseFloat(item.montodescuento) > 0) {
            this.posobs = index;
        }
    },
    mtdChangeDiscount: function (index) {
      if(this.validaDescuentoGeneral==1){
        this.$refs.SweetAlert.showWarning("Ya Registro Un descuento Global");
        return false;
      }
      this.itemDiscount = index;
    },
    mtdOutFocusEditObs(index){
      if (this.carrito[index].obs!="") {
        this.posobs=null;
       }else{
        this.$refs.SweetAlert.showWarning("Registre un motivo.");
        return ;
       }
    },
    mtdOutFocusDesc(){
      if (parseFloat(this.dsc)>0) {
        this.posDesc=false;
        let total = 0;
      this.carrito.forEach((element) => {
       total+=element.subtotal;
      });
         let TotalSIG=total/1.18;
       if(this.TypeDiscount=='monto' && this.dsc!='0'){
        this.montoDescuento =parseFloat(this.dsc);
       }
       else if(this.TypeDiscount=='porcentaje'&& this.dsc!='0'){
        const porcentajeDescuento = parseFloat(this.dsc) / 100;
        this.montoDescuento=TotalSIG*porcentajeDescuento;
       }
      //  else if(this.dsc=='0'){
      //   this.montoDescuento='0';
      //   this.dsc='0';
      //  }
      this.$refs.SweetAlert.showWarning("Registre un motivo.");
      this.posMotivo=true;
       }else{
        this.dsc='0';
        this.montoDescuento='0';
        this.posDesc=false;
        this.posMotivo=false;
        this.motivo="";
       }
    },
    mtdOutFocusMotivo(){
      if (this.motivo!="") {
        this.posMotivo=false;
       }else{
        this.$refs.SweetAlert.showWarning("Registre un motivo.");
        return ;
       }
    },
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    mtdGetDataPerAndData: function () {
      this.post({
        url: this.$store.getters.get__url + "/jefatura/otrosPermisos",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.permissionValue = response.data[0].permission;
          //console.log("Per: " + this.permissionValue);
        })
        .catch((errors) => { console.log(errors); });
    },
    AplicarDescuento1: function(index) {
          let item=null;
         item = this.carrito[index];
        if (item.tipodescuento === "monto" && item.descuento != '0') {
            let subtotalSING = item.subtotal / 1.18;
            subtotalSING = subtotalSING - parseFloat(item.descuento);
            item.montodescuento = parseFloat(item.descuento);
            item.subtotal = subtotalSING + (subtotalSING * 0.18);
        } else if (item.tipodescuento === "porcentaje" && item.descuento != '0') {
            let subtotalSING = item.subtotal / 1.18;
            const porcentajeDescuento = parseFloat(item.descuento) / 100;
            subtotalSING = subtotalSING - (subtotalSING * porcentajeDescuento);
            item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
            item.subtotal = subtotalSING + (subtotalSING * 0.18);
        } else if (item.descuento == '0') {
            item.montodescuento ='0';
            item.subtotal = item.quantity * parseFloat(item.price);
        }
        if (item.price < 0) {
            item.price = 0;
        }
        this.carrito[index] = item;
        this.actulizarDscGlobal();
    },
    actulizarDscGlobal:function(){
      if (parseFloat(this.dsc)>0) {
        this.posDesc=false;
        let total = 0;
      this.carrito.forEach((element) => {
       total+=element.subtotal;
      });
      let TotalSIG=total/1.18;
       if(this.TypeDiscount=='monto' && this.dsc!='0'){
        this.montoDescuento =parseFloat(this.dsc);
       }
       else if(this.TypeDiscount=='porcentaje'&& this.dsc!='0'){
        const porcentajeDescuento = parseFloat(this.dsc) / 100;
        this.montoDescuento= (TotalSIG * porcentajeDescuento);
       }
       }else{
        this.dsc='0';
        this.montoDescuento='0';
        this.posDesc=false;
        this.posMotivo=false;
        this.motivo="";
       }
    }
  },
};
</script>

<style scoped>
.btn-home {
  z-index: 1;
}

/* Scrool de vista */
#div-venta-body {
  /* border: 1px solid #aaa; */
  height: 450px;
  /* background: #f1f2f3; */
  overflow: auto;
  box-sizing: border-box;
  padding: 1rem 1rem;
}

#div-venta-body::-webkit-scrollbar {
  -webkit-appearance: none;
}

#div-venta-body::-webkit-scrollbar:vertical {
  width: 10px;
}

#div-venta-body::-webkit-scrollbar-button:increment,
#div-venta-body::-webkit-scrollbar-button {
  display: none;
}

#div-venta-body::-webkit-scrollbar:horizontal {
  height: 10px;
}

#div-venta-body::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#div-venta-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*  */

#name-product {
  color: #8a8a97;
  font-size: 13px;
}

.list-doctor,
.list-monto {
  padding-right: 25px;
}

.text-doctor {
  color: #900052;
  font-size: 13px;
  font-weight: bold;
}

.text-monto {
  color: #900052;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 0px;
}

.container-list {
  margin: 30px;
  width: 100%;
}

.box {
  margin-bottom: 10px;
  position: relative;
}

.box::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #808080;
  left: -15px;
  top: 30px;
}

.box::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #900052;
  left: -23px;
  top: 10px;
}

.item-doctor,
.item-monto {
  padding: 10px 10px;
  border: #900052 3px solid;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.item-doctor:hover {
  cursor: pointer;
  background-color: #900052;
}

.item-doctor:hover p {
  color: #ffffff !important;
}

#item-product {
  align-items: center;
}

#item-product:hover {
  cursor: pointer;
  align-items: center;
  transform: scale(1.05);
}

.item-monto .input-group-text,
.item-monto .form-control {
  background-color: #e6e6e6 !important;
  border: 0 !important;
  padding: 0px;
}

.item-monto .form-control button {
  background-color: #808080 !important;
  border: 0 !important;
}

.item-monto .form-control button:hover {
  background-color: #000000 !important;
  border: 0 !important;
}
.item-name {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-name strong {
  padding: 0px 10px;
  width: 120px;
}

/* #btn-autorizar-venta{
    color: #39b54a;
    font-size: 25px;
  }
  #btn-autorizar-venta:hover{
    color: #217b2d;
  }
  #btn-error-venta{
    color: #ff0000;
    font-size: 25px;
  }
  #btn-error-venta:hover{
    color: #a30404;
  }
  #btn-pediente-venta{
    color: #ffe600;
    font-size: 25px;
  }
  
  #btn-pediente-venta:hover{
    color: #c0ac01;
  }
  #btn-registro-venta a{
    color: #900052;
  }
  #btn-registro-venta a:hover{
    color: #65013a;
    align-items: center;
    transform:scale(1.5);
  } */
.circulo {
  vertical-align: middle;
  border-style: none;
}

@media (max-width: 800px) {
  #btn-open-caja {
    justify-content: center !important;
  }

  #btn-registro-venta {
    text-align: center;
  }

  #div-tabla-venta {
    overflow-x: auto;
  }
}

@media (min-width: 800px) {
  /* #div-modal-venta{
      margin-top: 10px;
    } */
  #btn-registro-venta {
    text-align: left;
  }
}

.text-custom-light-green {
  color: #60d004;
}

</style>

<template>
<div class="p-4">
    <div class="d-flex flex-row-reverse">
        <div class="mt-1 mb-0 d-inline-block btn-home">
            <button type="button" class="btn text-white" id="btn-edit" @click="mtdReportBead('INGRESO')">
                <i class="fas fa-money-bill-alt"></i> Reporte Ingresos
            </button>
            &nbsp;
            <button type="button" class="btn text-white" id="btn-edit" @click="mtdReportBead('EGRESO')">
                <i class="fas fa-money-check-alt"></i> Reporte Egresos
            </button>
            &nbsp;
            <button type="button" class="btn text-white" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>
    <div class="row w-100">
        <div class="col-md-12 col-lg-12 col-xs-12 mt-3 p-2 text-center bg-main" v-if="type.length > 0">
            <h4 class="modal-title text-white" id="exampleModalLabel">
                <i class="far fa-money-bill-alt"></i> REPORTE DE CAJA -
                {{ this.type }}
            </h4>
        </div>
        <div v-else class="d-flex justify-content-center p-4">
            <h3>Seleccione un tipo</h3>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12 mb-3">
            <div class="row mt-2 d-flex" v-if="type == 'INGRESO' || type == 'EGRESO'">
                <div class="col-md-4 col-lg-3 mb-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">Sede</span>
                        <select id="campu_id" class="form-control form-control" v-model="filterModal.campu_id" @change="mtdSelectCampusToBox">
                            <option selected value="0" disabled>Seleccione ...</option>
                            <option v-for="(item, index) in dataCampus" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-4 col-lg-3 mb-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">Caja</span>
                        <select id="bead_id" class="form-control form-control" v-model="filterModal.bead_id" @change="mtdDataReport">
                            <option selected value="0" disabled>Seleccione ...</option>
                            <option v-for="(item, index) in dataBeadsList" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-md-4 col-lg-3 mb-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">Desde</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filterModal.begindate" @change="mtdDataReport" />
                    </div>
                </div>

                <div class="col-md-4 col-lg-3 mb-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text">Hasta</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filterModal.enddate" @change="mtdDataReport" />
                    </div>
                </div>
                <div class="col-md-12 col-lg-12 my-1" >
                    <div class="row d-flex justify-content-end">
                        <div class="col-md-3 col-sm-3 text-end">
                            <button  v-if="type == 'INGRESO'" type="button" name="accion" class="btn btn-sm mb-3 w-100 bg-main text-white" 
                            @click="mtdModalProcess()"
                            :disabled="filterModal.campu_id==0"
                            >
                                <i class="fas fa-file-pdf"></i> PDF
                            </button>
                            <button v-else type="button" name="accion" class="btn btn-sm mb-3 w-100 bg-main text-white" @click="exportExcel">
                                <i class="fas fa-file-excel"></i> Exportar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2 d-flex" v-if="type == 'INGRESO'">

            </div>
        </div>
        <div v-if="this.type == 'INGRESO'" class="col-md-12" id="content-timelinetable">
            <table class="table table-bordered table-responsive" aria-describedby="mydesc">
                <thead>
                    <tr class="text-white border-white text-center bg-main text-xxs">
                        <th scope="col">N°</th>
                        <th scope="col">ESTADO</th>
                        <th scope="col">ADM</th>
                        <th scope="col">SEDE</th>
                        <th scope="col">FECHA</th>
                        <th scope="col">COMPROBANTE</th>
                        <th scope="col">A NOMBRE DE</th>
                        <th scope="col">SERIE</th>
                        <th scope="col">NÚMERO</th>
                        <th scope="col">CONVENIO</th>
                        <th scope="col">%</th>
                        <th scope="col">INFORMACIÓN DEL PACIENTE</th>
                        <th scope="col">CATEGORÍA</th>
                        <th scope="col">SERVICIO ESPECÍFICO</th>
                        <th scope="col">MONTO</th>
                        <th scope="col">MEDIO DE PAGO</th>
                        <th scope="col">DOCUMENTO</th>
                    </tr>
                </thead>
                <tbody class="text-xs" v-html="cpTableBodyModal"></tbody>
            </table>
        </div>
        <div v-if="this.type == 'EGRESO'" class="col-md-12" id="content-timelinetable">
            <table id="tb-egreso" class="table table-bordered border-main table-responsive" aria-describedby="tb-egreso">
                <thead>
                    <tr class="text-white border-white text-center bg-main text-xxs">
                        <th scope="col">N°</th>
                        <th scope="col">ESTADO</th>
                        <th scope="col">ADM</th>
                        <th scope="col">SEDE</th>
                        <th scope="col">FECHA EGRESO</th>
                        <th scope="col">FECHA DE COMPROBANTE</th>
                        <th scope="col">TIPO COMPROBANTE</th>
                        <th scope="col">SERIE</th>
                        <th scope="col">NÚMERO</th>
                        <th scope="col">RAZÓN SOCIAL</th>
                        <th scope="col">RUC / DNI</th>
                        <th scope="col">TOTAL</th>
                        <th scope="col">MONTO PAGADO</th>
                        <th scope="col">PENDIENTE</th>
                        <th scope="col">MEDIO DE PAGO</th>
                        <th scope="col">MOTIVO, DESCRIPCIÓN O DETALLE DE COMPRA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="text-xxs" v-for="(item2, index) in dataReport" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>
                            {{ item2.state == 1 ? 'PENDIENTE DE APROBACIÓN' : (item2.state == 2 ? 'APROBADO' : 'RECHAZADO') }}
                        </td>
                        <td>
                            {{ item2.of_bead.user.last_name }}
                            {{ item2.of_bead.user.name }}
                        </td>
                        <td>{{ item2.campu.name }}</td>
                        <td>{{ item2.date | formatDate }}</td>
                        <td>
                            {{
                    item2.date_doc == null
                    ? item2.date
                    : item2.date_doc | formatDate
                  }}
                        </td>
                        <td>
                            {{
                    item2.of_document_type_id != null
                    ? item2.of_document_type.name
                              : item2.modulo == 'receipt'?"RECIBO POR HONORARIOS":"DOC. INTERNO",
                  }}
                        </td>
                        <td>{{ item2.serie == null ? "-" : item2.serie }}</td>
                        <td>
                            {{ item2.number == null ? "-" : item2.number }}
                        </td>
                        <td>{{ item2.modulo == 'passage' ? item2.passage.name : (item2.modulo == 'retiro' ? item2.of_bead.user.last_name + 
                " "+item2.of_bead.user.name:(item2.modulo =='receipt'? item2.receipt.name:item2.company )) }}</td>
                        <td>{{ item2.modulo == 'passage' ? item2.passage.document : (item2.modulo == 'retiro' ? item2.of_bead.user.dni : (
                  item2.modulo == 'receipt' ? item2.receipt.document : item2.document)) }}</td>
                        <td>{{ item2.total }}</td>
                        <td>{{ item2.amount }}</td>
                        <td>{{ item2.pending }}</td>
                        <td>{{ item2.method }}</td>
                        <td>{{ item2.description }}</td>
                    </tr>
                    <tr v-if="dataReport.length == 0">
                        <td colspan="18" class="text-center text-xs">NO HAY REGISTROS PARA MOSTRAR</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <cModalReporteIngreso
      :title="modalProccess.title"
      :boo_modal="modalProccess.modal_form"
      :data="modalProccess.data"
      @closeModalProccess="closeModalProccess"
      @mtdSendPermission="mtdSendPermission"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

  
<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import exportFromJson from "export-from-json";
import cModalReporteIngreso from "@/components/Gerencia/modal/cModalReporteIngreso.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: "c-gerencia-ingresosegresos",
    components: {
        SweetAlert,
        cModalReporteIngreso
    },
    data() {
        return {
            swal: null,
            // post:null,
            data: [],
            finalCash: 0,
            totalPagesToShow: 2,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataShow: [],
            positem: null,
            filter: {
                categorie: 0,
                subcategorie: 0,
                eps: true,
                particular: true,
            },
            categories: [],
            categoriesModal: [],
            subcategories: [],
            subcategoriesModal: [],
            dataCampus: [],
            dataBeadsList: [],
            type: "",
            dataReport: [],
            dataReportView: [],
            filterModal: {
                campu_id: 0,
                bead_id: 0,
                begindate: null,
                enddate: null,
                eps: true,
                particular: true,
                categorie: 0,
                subcategorie: 0,
            },
            excelIncome: [],
            excelExpense: [],
            /** retiros */
            modalRetiros: {
                title: "",
                modal_form: false,
                item: [],
            },
            modalDetalle: {
                title: "",
                modal_form: false,
                item: [],
            },
            modalProccess: {
            title: "",
            modal_form: false,
            data: {
                type: "",
                motive: "",
                days: 0,
            },
            }
        };
    },
    created() {
        this.mtdDataBeads();
    },
    computed: {
        cpTableBodyModal() {
            let html = "";
            let temp = [];
            if (this.filterModal.campu_id === 0 && this.filterModal.bead_id === 0) {
                html = "<tr><td colspan='18' align='center'>SELECCIONE UN CAMPO Y CAJA</td><tr>";
            } else {
                temp = this.dataReport;
                if (temp.length == 0) {
                    html =
                        "<tr><td colspan='18' align='center'>NO HAY REGISTROS PARA MOSTRAR</td><tr>";
                } else {
                    temp.forEach((element, index) => {
                        let model = element.of_voucher.sale.detail ?
                            element.of_voucher.sale.detail[0].product.service.name :
                            "-";
                        let tdContent = [];
                        for (let i = 0; i < element.of_voucher.sale.detail.length; i++) {
                            let ssEspecifico = element.of_voucher.sale.detail[i] ?
                                element.of_voucher.sale.detail[i].product.name :
                                "-";
                            tdContent.push(ssEspecifico);
                        }
                        let insurance =
                            element.of_voucher.sale.of_insurance != null ?
                            element.of_voucher.sale.of_insurance.name :
                            "-";
                            if(element.of_voucher.of_document_type_id !=3){
                        let ticket = JSON.parse(element.of_voucher.sunat);
                        let enlace = ticket.json.enlace_del_pdf_ticket;
                       
                        let itemHtml =
                            "<tr>" +
                            "<td>" +
                            (index + 1) +
                            "</td>" +
                            "<td>" +
                            (element.of_voucher.state == 1 ? "ACTIVO" : "ANULADO") +
                            "</td>" +
                            "<td>" +
                            element.of_voucher.of_bead.user.name +
                            "</td>" +
                            "<td>" +
                            element.of_voucher.sale.campu.name +
                            "</td>" +
                            "<td>" +
                            element.of_voucher.date +
                            "</td>" +
                            "<td>" +
                            element.of_voucher.document +
                            "</td>" +
                            "<td>" +
                            element.of_voucher.vaucher_document + "-" + element.of_voucher.vaucher_fullname +
                            "</td>" +
                            "<td align='center'>" +
                            element.of_voucher.serie +
                            "</td>" +
                            "<td align='center'>" +
                            element.of_voucher.number +
                            "</td>" +
                            "<td>" +
                            insurance +
                            "</td>" +
                            "<td>" +
                            element.of_voucher.sale.payment_patient +
                            "</td>" +
                            "<td>" +
                            element.of_voucher.sale.patient.name +
                            "</td>" +
                            "<td>" +
                            model +
                            "</td>" +
                            "<td>" +
                            tdContent.join(",") +
                            "</td>" +
                            "<td>" +
                            (element.of_voucher.state === 2 ? "0.00" : element.amount) +
                            "</td>" +
                            "<td>" +
                            element.method +
                            "</td>" +
                            "<td>" +

                            "<a id='btn-check'  style='margin-left: 5px; font-weight: bold; text-decoration: none;' href='" + enlace + "' target='_blank'>" +
                            "<i class='fas fa-file-pdf'></i><span  style='font-size: 14px;'> VER</span>" +
                            "</a>"

                            +
                            "</td>" +
                            // +"<td>"+element.voucher.sale.billing_period+"</td>"
                            "</tr>";
                        
                        html += itemHtml;
                        }
                    });
                }

            }

            return html;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdShowModal: function (title) {
            this.modalDetalle.title = title;
            if (title == "Ingresos") {
                if (title !== "Planilla") {
                    this.modalDetalle.modal_form = true;
                }
                this.process.incomes.forEach((income) => {
                    const efectivoCharge = income.charge.find(
                        (charge) => charge.method === "efectivo"
                    );
                    // Si encontramos el charge efectivo, agregamos solo ese ingreso
                    if (efectivoCharge) {
                        this.modalDetalle.item.push({
                            date: income.date,
                            vaucher_fullname: title !== "Egresos" ? income.vaucher_fullname : null,
                            serie: income.serie,
                            number: income.number,
                            chargeAmount: efectivoCharge.amount,
                            state: income.state,
                        });
                    }
                });
            } else if (title == "Egresos") {
                this.modalDetalle.modal_form = true;
                this.process.expenses.forEach((element) => {
                    this.modalDetalle.item.push(element);
                });
            } else {
                if (title == "Tarjeta") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "tarjeta"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
                if (title == "Transferencia") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "transferencia"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
                if (title == "Deposito") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "deposito"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
                if (title == "Planilla") {
                    this.modalDetalle.modal_form = true;
                    this.process.incomes.forEach((income) => {
                        const efectivoCharge = income.charge.find(
                            (charge) => charge.method === "planilla"
                        );
                        // Si encontramos el charge efectivo, agregamos solo ese ingreso
                        if (efectivoCharge) {
                            this.modalDetalle.item.push({
                                date: income.date,
                                vaucher_fullname: income.vaucher_fullname,
                                serie: income.serie,
                                number: income.number,
                                chargeAmount: efectivoCharge.amount,
                                state: income.state,
                            });
                        }
                    });
                }
            }
            //console.log(this.modalDetalle.item);
        },

        closeModalDetalle: function () {
            this.modalDetalle = {
                title: "",
                modal_form: false,
                item: [],
            };
        },
        closeModalProccess: function () {
        this.pos = null;
        this.modalProccess = {
          title: "",
          modal_form: false,
          data: {
            id: null,
            type: "",
            motive: "",
          },
        };
      },
        mtdModalProcess: function () {
        this.modalProccess.title = "GENERAR  PDF";
        this.modalProccess.modal_form = true;
        this.modalProccess.data.id = 1
        this.modalProccess.data.type=0
        this.pos = 0;
        this.modalProccess.data.days =2;
      },
        mtdSetCategories: function () {
            let str = "";
            this.data = [];
            this.process.incomes.forEach((element) => {
                let efec = 0;
                let otros = [];

                element.charge.forEach((ele) => {
                    if (ele.method == "efectivo") {
                        efec += parseFloat(ele.amount);
                    } else {
                        element.sale.detail.forEach((d) => {
                            //otro = parseFloat(d.price);
                            otros.push(parseFloat(d.price));
                        });
                    }
                });
                element.sale.detail.forEach((ele, index) => {
                    if (!str.includes("," + ele.product.service.id + ",")) {
                        this.categories.push({
                            label: ele.subsub_categorie.subategorie.categorie.name,
                            id: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.product.service.id + ",";
                    }
                    this.data.push({
                        categorie_id: ele.subsub_categorie.subategorie.categorie.id,
                        categorie: ele.subsub_categorie.subategorie.categorie.name,
                        subcategorie_id: ele.subsub_categorie.subategorie.id,
                        subcategorie: ele.subsub_categorie.subategorie.name,
                        servicio: ele.subsub_categorie.name,
                        efectivo: efec,
                        otros: otros[index] !== undefined ? otros[index] : 0.0,
                        total: element.sale.detail.length > 1 ?
                            parseFloat(ele.quantity * ele.price) : parseFloat(element.amount).toFixed(2),
                        type: element.sale.type_patient,
                    });
                });
            });
        },
        mtdSetSubcategories: function () {
            let str = "";
            this.process.incomes.forEach((element) => {
                element.sale.detail.forEach((ele) => {
                    if (!str.includes("," + ele.subsub_categorie.subategorie.id + ",")) {
                        this.subcategories.push({
                            label: ele.subsub_categorie.subategorie.name,
                            id: ele.subsub_categorie.subategorie.id,
                            categorie: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.subsub_categorie.subategorie.id + ",";
                    }
                });
            });
        },
        returnHome: function () {
            this.$emit("returnHome", "home");
        },
        mtdReportBead: function (type) {
            if (this.type != type) {
                this.mtdHideModal();
            }
            this.type = type;
            moment.locale("es");
            this.filterModal.begindate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-01";
            this.filterModal.enddate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-" +
                moment().format("L").substr(0, 2);
        },
        mtdHideModal: function () {
            this.type = "";
            this.filterModal.campu_id = 0;
            this.filterModal.bead_id = 0;
            this.filterModal.begindate = "";
            this.filterModal.enddate = "";
            this.dataReport = [];
        },
        mtdSelectCampusToBox: function () {
            this.dataBeadsList = [];
            this.dataCampus.forEach((element) => {
                if (element.id == this.filterModal.campu_id) {
                    this.dataBeadsList = element.boxes;
                }
            });
        },
        mtdDataReport: function () {
            if (this.filterModal.bead_id == 0) {
                this.$refs.SweetAlert.showWarning("¡Escoja una caja!");
                return;
            }
            this.get({
                    url: this.$store.getters.get__url +
                        "/Gerencia/reportecaja?type=" +
                        this.type +
                        "&campu_id=" +
                        this.filterModal.campu_id +
                        "&bead_id=" +
                        this.filterModal.bead_id +
                        "&begindate=" +
                        this.filterModal.begindate +
                        "&enddate=" +
                        this.filterModal.enddate,
                    token: this.$store.getters.get__token,
                    //params: this.bead,
                })
                .then((response) => {
                    this.dataReport = response.data;
                    //Data oara Excel
                    this.dataReport.forEach((element, index) => {
                        if (this.type != "EGRESO") {
                            let model = element.of_voucher.sale.detail ?
                                element.of_voucher.sale.detail[0].product.service.name :
                                "-";
                            let tdContent = [];
                            for (let i = 0; i < element.of_voucher.sale.detail.length; i++) {
                                let ssEspecifico = element.of_voucher.sale.detail[i] ?
                                    element.of_voucher.sale.detail[i].product.name :
                                    "-";
                                tdContent.push(ssEspecifico);
                            }
                            let insurance =
                                element.of_voucher.sale.of_insurance != null ?
                                element.of_voucher.sale.of_insurance.name :
                                "-";
                            if(element.of_voucher.of_document_type_id !=3 ){
                            this.excelIncome.push({
                                "N°": index + 1,
                                "ESTADO": (element.of_voucher.state == 1 ? "ACTIVO" : "ANULADO"),
                                "ADM": element.of_voucher.of_bead.user.name,
                                "SEDE": element.of_voucher.sale.campu.name,
                                "FECHA": element.of_voucher.date,
                                "COMPROBANTE": element.of_voucher.document,
                                "A NOMBRE DE": element.of_voucher.vaucher_document + "-" + element.of_voucher.vaucher_fullname,
                                "SERIE": element.of_voucher.serie,
                                "NÚMERO": element.of_voucher.number,
                                "CONVENIO": insurance,
                                "%": element.of_voucher.sale.payment_patient,
                                "INFORMACIÓN DEL PACIENTE": element.of_voucher.sale.patient.name,
                                "CATEGORÍA": model,
                                "SERVICIO ESPECÍFICO": tdContent.join(","),
                                "MONTO": (element.of_voucher.state === 2 ? "0.00" : element.amount),
                                "MEDIO DE PAGO": element.method,
                                // 'PER. FACTURACIÓN':element.of_voucher.sale.billing_period,
                            });}
                        } else {
                            this.excelExpense.push({
                                "N°": index + 1,
                                "ESTADO": element.state == 1 ? 'PENDIENTE DE APROBACIÓN' : (element.state == 2 ? 'APROBADO' : 'RECHAZADO'),
                                "ADMISIONISTA": element.of_bead.user.name,
                                "SEDE": element.campu.name,
                                "FECHA DE EGRESO": element.date,
                                "FECHA DE COMPROBANTE": element.date_doc == null ? element.date : element.date_doc,
                                "TIPO DE COMPROBANTE": element.of_document_type_id != null ?
                                    element.of_document_type.name : element.modulo == 'receipt' ? "RECIBO POR HONORARIOS" : "DOC. INTERNO",
                                "NÚMERO DE COMPROBANTE": element.modulo == 'passage' ? 'PLANILLA-PASAJES' : (element.number ? element.serie + "-" + element.number : (element.serie ? element.serie : '-')),
                                "RAZON SOCIAL/NOMBRE": element.modulo == 'passage' ? element.passage.name : (element.modulo == 'retiro' ? element.of_bead.user.name : (element.modulo == 'receipt' ? element.receipt.name : element.company)),
                                "RUC/DNI": element.modulo == 'passage' ? element.passage.document : (element.modulo == 'retiro' ? element.of_bead.user.dni : (element.modulo == 'receipt' ? element.receipt.document : element.document)),
                                "TOTAL": element.total,
                                "MONTO PAGADO": element.amount,
                                "PENDIENTE": element.pending,
                                "MEDIO DE PAGO": element.method,
                                "MOTIVO, DESCRIPCION O DETALLE DE COMPRA": element.description,
                            });
                        }
                    });
                    //this.mtdSetCategoriesModal();
                    //this.mtdSetSubcategoriesModal();
                })
                .catch(() => {});
        },
        mtdSetCategoriesModal: function () {
            let str = "";
            this.dataReport.forEach((element) => {
                element.of_voucher.sale.detail.forEach((ele) => {
                    if (!str.includes("," + ele.subsub_categorie.subategorie.categorie.id + ",")) {
                        this.categoriesModal.push({
                            label: ele.subsub_categorie.subategorie.categorie.name,
                            id: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.subsub_categorie.subategorie.categorie.id + ",";
                    }
                });
            });
        },
        mtdSetSubcategoriesModal: function () {
            let str = "";
            this.dataReport.forEach((element) => {
                element.of_voucher.sale.detail.forEach((ele) => {
                    if (!str.includes("," + ele.subsub_categorie.subategorie.id + ",")) {
                        this.subcategoriesModal.push({
                            label: ele.subsub_categorie.subategorie.name,
                            id: ele.subsub_categorie.subategorie.id,
                            categorie: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.subsub_categorie.subategorie.id + ",";
                    }
                });
            });
        },
        exportExcel: function () {
            const datos = this.type == "INGRESO" ? this.excelIncome : this.excelExpense;
            const fileName = "REPORTE " + this.type;
            const typeExport = exportFromJson.types.xls;
            exportFromJson({
                data: datos,
                fileName: fileName,
                exportType: typeExport,
            });
        },
        FormatJson(FilterData, JsonData) {
            return JsonData.map((v) =>
                FilterData.map((j) => {
                    return v[j];
                })
            );
        },

        mtdDataBeads: function () {
            this.filterModal.campu_id = this.$store.getters.get__campus;
            this.post({
                    url: this.$store.getters.get__url + "/Gerencia/incomeAndExpense",
                    token: this.$store.getters.get__token,
                    params: {
                        id: this.filterModal.campu_id,
                    }
                })
                .then((response) => {
                    this.dataCampus = response.data.campus;
                    this.mtdSelectCampusToBox();
                })
                .catch(() => {});
        },
        mtdSendPermission(especifico, documento, torta, barra) {
            let url = this.$store.getters.get__url +
                "/gerencia/viewpdf/ingresos?type=INGRESO&campu=" +
                this.filterModal.campu_id +
                "&bead=" +
                this.filterModal.bead_id +
                "&doc=" +
                documento +
                "&ser=" +
                especifico +
                "&torta=" +
                torta +
                "&barra=" +  
                barra +
                "&begin=" +
                this.filterModal.begindate +
                "&end=" +
                this.filterModal.enddate;
                window.open(url, '_blank');
        this.closeModalProccess();
        },
    },

    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>

<template>
<div>
    <div class="container">
        <div class="d-flex flex-row-reverse">
            <div class="mt-5 mb-4 btn-home">
                <button type="button" class="btn text-white" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="card-header text-white h4 bg-main text-center">
                    <strong>PROVEEDORES</strong>
                </div>
                <div class="card-body w-100">
                    <cTable :button_num="button_num" :columns="columns" :data="data" @openmodal="openmodal" @deleteData="deleteData" />
                </div>
            </div>
        </div>
        <cModalProviderVue :dataBrand="dataBrand" :num="modal.num" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
        <SweetAlert :swal="swal" ref="SweetAlert" @deleteDataDetail="deleteDataDetail" />
        <cModalMarcaVue :productopormarcas="productopormarcas" :provider_id="provider_id" @mtdaddproduct="mtdaddproduct" @mtdaddbrand="mtdaddbrand" :idMarca="idMarca" :dataProvider="dataProvider" :num="modalMarca.num_data" :dataBrandProvider="dataBrandProvider" :dataBrand="dataBrand" :title="modalMarca.title" :boo_modal="modalMarca.modal_form" @closeModalMarca="closeModalMarca" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cTable from '../shared/Table/cTable.vue'
import cModalProviderVue from './Modals/cModalProvider.vue'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalMarcaVue from './Modals/cModalMarca.vue';
export default {
    name: 'c-mantenimiento-provider',
    data() {
        return {
            swal: null,
            provider_id: null,
            search: "",
            columns: [
                'N°',
                'RAZON SOCIAL',
                'RUC',
                'EMAIL',
                'PERSONA ENCARGADA',
                'ESTADO',
                'ACCIONES'

            ],
            data: [],
            dataBrand: [],
            dataBrandProvider: [],
            productopormarcas:[],
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalMarca: {
                title: '',
                modal_form: false,
                num_data: null,
            },
            idMarca: [],
            dataProvider: {},
            button_num: null,
        }
    },
    components: {
        cTable,
        cModalProviderVue,
        SweetAlert,
        cModalMarcaVue
    },
    created() {
        this.getData();
        this.getDataBrand()
    },
    methods: {
        ...mapActions(['get', 'post']),
        getData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexProvider",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    this.data = response.data
                    // this.button_num = 1

                })
                .catch(error => {
                    console.log(error);
                })
        },
        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail)
            } else {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail)
            }
        },
        addDatadetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/Provider",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.closeModal()
                    this.getData();
                })
                .catch(error => {
                    console.log(error);
                })
        },
        editDatadetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/Provider",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.closeModal()
                    this.getData();
                })
                .catch(error => {
                    console.log(error);
                })
        },
        deleteData: function (payload_detail) {
            payload_detail.action = 'd';
            this.$refs.SweetAlert.showDelete(payload_detail, 'deleteDataDetail');

        },
        deleteDataDetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/Provider",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (response.statusCode == 200) {
                        this.getData();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        getDataBrand: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexBrands",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    this.dataBrand = response.data
                })
                .catch(error => {
                    console.log(error);
                })

        },
        mtdaddbrand: function (brands) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/addBrandProvider",
                    token: this.$store.getters.get__token,
                    params: brands,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.getData();
                    this.closeModalMarca()
                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtdaddproduct: function (products) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/addProductProvider",
                    token: this.$store.getters.get__token,
                    params: products,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.getData();
                    this.closeModalMarca()
                })
                .catch(error => {
                    console.log(error);
                })

        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        openmodal: function (num, payload) {
            if (num == 2 || num == 3) {
                if (num == 3) {
                    this.dataBrandProvider = payload.brands
                    this.provider_id = payload.id;
                    this.post({
                            url: this.$store.getters.get__url + "/Maintainer/indexProductBrand",
                            token: this.$store.getters.get__token,
                            params:{
                               id: payload.id
                            }
                        })
                        .then(response => {
                            this.productopormarcas = response.data.productos_por_marca;
                
                        })
                        .catch(error => {
                            console.log(error);
                        })

                } else {
                    this.dataProvider = payload;
                    this.idMarca = [];
                    payload.brands.forEach(element => {
                        this.idMarca.push(element.brand_id)
                    });

                }
                this.modalMarca = {

                    title: (num == 2) ? 'Listado de Marcas' : 'Marcas del Proveedor',
                    modal_form: true,
                    num_data: num
                }
            } else {
                this.modal.title = (num == 0) ? 'Registrar Proveedor' : 'Editar Proveedor';
                this.modal.modal_form = true;
                this.modal.num = num;
                this.modal.payload = payload;
            }

        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
        closeModalMarca: function () {
            this.modalMarca = {
                title: '',
                modal_form: false,
            }
            this.idMarca = [];
            this.dataProvider = {}
        }
    }
}
</script>

<template>
<div>
    <div class="container-history text-center">

        <div class="d-flex title-option">
            <!-- <h2 class=" h2-borde mt-3 mb-3 ms-5 text-main pt-1 px-4">
              <strong>AYUDA AL DX</strong>
          </h2> -->
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>

        <ul class="nav pb-0 d-none d-lg-flex" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button @click="changePage('campovisual')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'campovisual' }" id="home-tab" type="button">
                    <strong class="text-xs">Campo Visual</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('oct')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'oct' }" id="profile-tab" type="button">
                    <strong class="text-xs">OCT</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('octmacula')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'octmacula' }" id="profile-tab" type="button">
                    <strong class="text-xs">OCT Macula</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('paquimetria')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'paquimetria' }" id="profile-tab" type="button">
                    <strong class="text-xs">Paquimetría</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('topografia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'topografia' }" id="profile-tab" type="button">
                    <strong class="text-xs">Topografía</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('microscopia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'microscopia' }" id="profile-tab" type="button">
                    <strong class="text-xs">Microscopía E.</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('ecografia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'ecografia' }" id="profile-tab" type="button">
                    <strong class="text-xs">Ecografía Ocular</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('biometria')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'biometria' }" id="profile-tab" type="button">
                    <strong class="text-xs">Biometría</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('angiografia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'angiografia' }" id="profile-tab" type="button">
                    <strong class="text-xs">Angiografía R.</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('resonancia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'resonancia' }" id="profile-tab" type="button">
                    <strong class="text-xs">Resonancia</strong>
                </button>
            </li>
        </ul>

        <div class="tab-content mb-3 px-0 d-none d-lg-block" id="myTabContent">
            <CampoVisual @mtdviewpage="mtdviewpage" v-if="page=='campovisual'" class="px-3" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="campoVisual" />
            <Oct @changePage="changePage" v-if="page=='oct'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="oct" />
            <OctMacula @changePage="changePage" v-if="page=='octmacula'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="octMacula" />
            <Paquimetria @changePage="changePage" v-if="page=='paquimetria'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="paquimetria" />
            <Topografia @changePage="changePage" v-if="page=='topografia'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="topografia" />
            <Microscopia @changePage="changePage" v-if="page=='microscopia'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="microscopia" />
            <Ecografia @changePage="changePage" v-if="page=='ecografia'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="ecografia" />
            <Biometria @changePage="changePage" v-if="page=='biometria'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="biometria" />
            <Angiografia @changePage="changePage" v-if="page=='angiografia'" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="angiografia" />
            <Resonancia v-if="page=='resonancia'" @mchangePage="changePage" :stateRolAdmin="stateRolAdmin" :patient="patient" :data="resonancia" />
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none">
            <div class="wrapper-list">
                <dropdown title="Campo Visual" component="CampoVisual" @changePage="changePage" :patient="patient" :data="campoVisual"></dropdown>
                <dropdown title="OCT" component="Oct" @changePage="changePage" :patient="patient" :data="oct"></dropdown>
                <dropdown title="OCT Macula" component="OctMacula" @changePage="changePage" :patient="patient" :data="octMacula"></dropdown>
                <dropdown title="Paquimetría" component="Paquimetria" @changePage="changePage" :patient="patient" :data="paquimetria"></dropdown>
                <dropdown title="Topografía" component="Topografia" @changePage="changePage" :patient="patient" :data="topografia"></dropdown>
                <dropdown title="Microscopía E." component="Microscopia" @changePage="changePage" :patient="patient" :data="microscopia"></dropdown>
                <dropdown title="Ecografía Ocular" component="Ecografia" @changePage="changePage" :patient="patient" :data="ecografia"></dropdown>
                <dropdown title="Biometría" component="Biometria" @changePage="changePage" :patient="patient" :data="biometria"></dropdown>
                <dropdown title="Angiografía R." component="Angiografia" @changePage="changePage" :patient="patient" :data="angiografia"></dropdown>
                <dropdown title="Resonancia" component="Resonancia" @changePage="changePage" :patient="patient" :data="resonancia"></dropdown>

            </div>
        </div>
        <!--  -->

    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'

import CampoVisual from '@/components/Medico/AyudaDx/CampoVisual.vue';
import Oct from '@/components/Medico/AyudaDx/Oct.vue';
import OctMacula from '@/components/Medico/AyudaDx/OctMacula.vue';
import Paquimetria from '@/components/Medico/AyudaDx/Paquimetria.vue';
import Topografia from '@/components/Medico/AyudaDx/Topografia.vue';
import Microscopia from '@/components/Medico/AyudaDx/Microscopia.vue';
import Ecografia from '@/components/Medico/AyudaDx/Ecografia.vue';
import Biometria from '@/components/Medico/AyudaDx/Biometria.vue';
import Angiografia from '@/components/Medico/AyudaDx/Angiografia.vue';
import Resonancia from '@/components/Medico/AyudaDx/Resonancia.vue';
import Dropdown from "@/components/Medico/AyudaDx/Dropdown.vue";

export default {
    name: 'c-medico-ayudadx',
    components: {
        CampoVisual,
        Oct,
        OctMacula,
        Paquimetria,
        Topografia,
        Microscopia,
        Ecografia,
        Biometria,
        Angiografia,
        Dropdown,
        Resonancia,
    },
    data() {
        return {
            page: 'campovisual',
            stateHistory: null,
            viewInput: null,
            patients: {},
            campoVisual: [],
            oct: [],
            octMacula: [],
            paquimetria: [],
            topografia: [],
            microscopia: [],
            ecografia: [],
            biometria: [],
            angiografia: [],
            resonancia: []
        }
    },
    props: {
        stateRolAdmin: Boolean,
        patient: {
            type: Object,
            default: () => ({})
        }
    },
    mounted() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Doctor/ListAyudaDx/" + this.patient.id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.campoVisual = response.data.campoVisual;
                    this.oct = response.data.oct;
                    this.octMacula = response.data.octMacula;
                    this.paquimetria = response.data.paquimetria;
                    this.topografia = response.data.topografia;
                    this.microscopia = response.data.microscopia;
                    this.ecografia = response.data.ecografiaOcular;
                    this.biometria = response.data.biometria;
                    this.angiografia = response.data.angiografia;
                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            if (page === 'cola') {
                this.patients = {}
            }
            this.stateHistory = (page === 'ayudadx') ? 1 : 0;
            this.viewInput = (page === 'ayudadx') ? 1 : 0;
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        mtdviewpage: function (payload) {
            this.page = 'ayudadx'
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput = 0;
        }

    }
}
</script>./AyudaDx/ResonanciaView.vue./AyudaDx/TopografiaView.vue

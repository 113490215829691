<template>
<div class="container">
    <div class="row">
        <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>

    <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class="card w-100">
            <div class="card-header text-white h4 bg-main align-center">
                <strong>AUTORIZAR PAGOS</strong>
            </div>
            <div class="card-body w-100">
                <div class="row d-flex">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-4">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" @change="mtdGetData" />
                        </div>
                    </div>
                    <div class="col-lg-9 col-md-9 col-xs-9  py-2">
                        <div class="d-flex">
                            <div class="input-group input-group mb-3">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input type="text" class="form-control form-control" placeholder="Numero Documento" v-model="search" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-xs-3 py-2">
                        <div class="d-flex">
                            <div class="input-group input-group mb-3">
                                <button type="button" :disabled="this.DataAutorizar.length==0" name="enviar" class="btn btn-sm mb-3 w-100 btn-success text-white" @click="enviarremision()">
                                    <i class="fas fa-save"></i> Autorizar
                                </button>
                            </div>
                        </div>
                    </div>
                    <div cclass="col-md-12" id="content-timelinetable">
                        <table id="tb-anular" class="table table-bordered border-main">
                            <caption class="py-0 my-0"></caption>
                            <thead>
                                <tr class="align-center text-white border-white bg-main">
                                    <th style="vertical-align: middle" scope="col">N°</th>
                                    <th style="vertical-align: middle" scope="col">
                                        TIPO
                                    </th>
                                    <th style="vertical-align: middle" scope="col">
                                        FECHA
                                    </th>

                                    <th style="vertical-align: middle" scope="col">
                                        SERIE Y N°
                                    </th>
                                    <th style="vertical-align: middle" scope="col">
                                        MONTO</th>
                                    <th style="vertical-align: middle" scope="col">
                                        ACCIÓN</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in cpData" :key="index">
                                    <th style="vertical-align:middle;" scope="row">{{ mtdNumber(index) }}</th>
                                    <td class="align-center">
                                        {{ item.name}}
                                    </td>
                                    <td class="align-center">
                                        {{ item.date | formatDate }}
                                    </td>
                                    <td class="align-center">{{ item.number }}</td>
                                    <td class="align-right">
                                        {{ item.money == 'soles' ? "S/." : '$.' }} {{ parseFloat(item.total).toFixed(2) }}
                                    </td>
                                    <td class="align-center">
                                        <input type="checkbox" :id="'checkbox'+ item.id" :checked="item.states == 1" class="form-check-input" @click="volveremitir(item)">
                                    </td>
                                </tr>
                                <tr v-if="cpData.length == 0">
                                  <td class="align-center" colspan="6">
                                        No hay facturas para autorizar pagos
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--paginate-->
                    <div class="d-flex justify-content-center" style="align-items: center">
                        <nav aria-label="Page navigation example">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPAge">Anterior</a>
                                </li>
                                <li class="page-item" v-for="(item, index) in stepPagination" :key="index">
                                    <a :class="
                          selectPage == item ? 'page-link active' : 'page-link'
                        " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="addPAge">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: "c-gerencia-pages-anulaciones",
    components: {

        SweetAlert,
    },
    data() {
        return {
            swal: null,
            hoy: null,
            filter: {
                begindate: null,
                enddate: null,
            },
            data: [],
            dataShow: [],
            DataAutorizar: [],
            noRecordsMessage: "",
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            pos: null,
            modalProccess: {
                title: "",
                modal_form: false,
                data: {
                    type: "",
                    motive: "",
                    days: 0,
                },
            }
        }
    },
    computed: {
        cpData() {
            let bo = false;
            if (this.search.length >= 2 && this.search != "") {
                let q = this.search.toString().toUpperCase();
                this.dataShow = [];
                this.data.forEach((element) => {
                    let name = element.name.toString().toUpperCase();
                    let date = element.date.toString().toUpperCase();
                    let number = element.number.toString().toUpperCase();
                    let states = element.states.toString().toUpperCase();
                    let id = element.id.toString().toUpperCase();
                    if (
                        name.includes(q) ||
                        date.includes(q) ||
                        number.includes(q)
                    ) {
                        this.dataShow.push(element);
                    }
                });
                if (this.dataShow.length === 0 && this.data.length !== 0) {
                    this.noRecordsMessage = "No se encontraron resultados de la búsqueda";
                } else {
                    this.noRecordsMessage = "";
                }
                bo = true;
            } else {
                let fin = this.selectPage * 5 - 1;
                let ini = fin - 4;
                this.dataShow = [];
                this.data.forEach((element, index) => {
                    if (ini <= index && index <= fin) {
                        this.dataShow.push(element);
                    }
                });
                bo = false;
                this.noRecordsMessage = "";
            }
            /** paginación */
            this.stepPagination = [];
            let cantidad =
                bo == false ?
                parseInt(this.data.length / 5) :
                parseInt(this.dataShow.length / 5);
            let residuo =
                bo == false ?
                parseInt(this.data.length % 5) :
                parseInt(this.dataShow.length % 5);
            cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            for (let index = 0; index < cantidad; index++) {
                this.stepPagination.push(index + 1);
            }
            return this.dataShow;
        },
        cpTotalFiltro() {
            let tot = 0;
            this.dataShow.forEach((element) => {
                tot = parseFloat(tot) + parseFloat(element.amount);
            });
            return parseFloat(tot).toFixed(2);
        },
    },
    created() {
        moment.locale("es");
        this.filter.begindate = moment().startOf('month').format("YYYY-MM-DD");
        this.filter.enddate = moment().format("YYYY-MM-DD");
        this.hoy = this.filter.enddate;
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        volveremitir(doc) {
            let existingIndex = this.data.findIndex(item => (item.id == doc.id && item.tipo == doc.tipo));
            if (doc.states == 0) {
                if (existingIndex !== -1) {
                    this.data[existingIndex].states = 1;
                    const docu = {
                        "id": doc.id,
                        "tipo": doc.tipo
                    };
                    this.DataAutorizar.push(docu);
                }
            } else {
                if (existingIndex !== -1) {
                    this.data[existingIndex].states = 0;
                }
                const indexToRemove = this.DataAutorizar.findIndex(item => (item.id === doc.id && item.tipo == doc.tipo));
                if (indexToRemove !== -1) {
                    this.DataAutorizar.splice(indexToRemove, 1);
                }
            }
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Gerencia/pagos",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.DataAutorizar = [];
                    this.data = response.data.services;
                    /** paginación */
                    let cantidad = parseInt(this.data.length / 5);
                    let residuo = parseInt(this.data.length % 5);
                    cantidad = residuo > 0 ? cantidad + 1 : cantidad;
                    for (let index = 0; index < cantidad; index++) {
                        this.stepPagination.push(index + 1);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        /** pagination */
        selectedPage: function (page) {
            this.selectPage = page;
        },
        backPAge: function () {
            this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
        },
        addPAge: function () {
            this.selectPage =
                this.selectPage == this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;
        },
        enviarremision() {
            this.$refs.SweetAlert.showConfirmSimple2("", "¿Está seguro de Autorizar los Documentos?", "warning", "Confirmar")
                .then((result) => {
                    if (result.value) {
                        this.post({
                                url: this.$store.getters.get__url + "/Gerencia/autorizarpagos",
                                token: this.$store.getters.get__token,
                                params: {
                                    documents: this.DataAutorizar
                                },
                            })
                            .then((response) => {
                                if (response.statusCode === 200) {
                                    this.mtdGetData();
                                    // Limpiar el array emitirnuevos
                                    this.DataAutorizar = [];
                                    this.$refs.SweetAlert.showSuccess("¡Éxito!");
                                }
                            })
                            .catch((errors) => {
                                console.log(errors);
                            });
                    }
                });
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 5);
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>

<style scoped>
#btn-autorizar-anular {
    color: #39b54a;
    font-size: 25px;
}

#btn-autorizar-anular:hover {
    color: #217b2d;
}

#btn-error-anular {
    color: #ff0000;
    font-size: 25px;
}

#btn-error-anular:hover {
    color: #a30404;
}

#btn-pediente-anular {
    color: #ffe600;
    font-size: 25px;
}

#btn-pediente-anular:hover {
    color: #c0ac01;
}

#btn-registro-anular a {
    color: #900052;
}

#btn-registro-anular a:hover {
    color: #65013a;
    align-items: center;
    transform: scale(1.5);
}

.circulo {
    vertical-align: middle;
    border-style: none;
}

@media (max-width: 800px) {
    #btn-open-caja {
        justify-content: center !important;
    }

    #div-tabla-anular {
        overflow-x: auto;
    }
}

/*Paginacion*/
.page-link {
    color: black !important;
}

.page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
}

.page {
    color: white !important;
    background: rgb(144, 0, 82);
}
</style>

<template>
    <div>
        <div class="p-5">
            <div class="row">
                <div id="btn-home" class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div align="center" class="card-header text-white h4" style="background: #900052">
                        <strong>REPORTE DE NOTAS DE CREDITO</strong>
                    </div>
    
                    <div class="card-body w-100">
                        <div class="row">
    
                            <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-3">Desde</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" />
                                </div>
                            </div>
    
                            <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text col-md-3">Hasta</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" />
                                </div>
                            </div>
    
                            <div class="col-md-12 col-xl-2 col-lg-2 col-sm-12 col-xs-12 mb-2">
                                <button type="button" name="accion" class="btn btn-sm w-100 btn-register text-white" @click="mtdGetData">
                                    <i class="fas fa-search"></i> Buscar
                                </button>
                            </div>
    
                            <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                                <button @click=" exportExcel()" type="button" name="accion" class="btn btn-sm mb-3 w-100 btn-register text-white">
                                    <i class="fas fa-file-excel"></i>Exportar
                                </button>
                            </div>
    
                            <div class="col-md-12 table-container mt-2" id="content-timelinetable">
                                <table class="table table-bordered border-main" >
                                    <thead>
                                        <tr class="text-white border-white bg-main text-center text-xs" style="vertical-align: middle;">
                                            <th scope="col" colspan="2" rowspan="2">
                                                NUMERO CORRELATIO DEL REGISTRO CODIGO
                                                UNICO DE LA OPERACION</th>
                                            <th scope="col" colspan="1" rowspan="2">
                                                FECHA EMISION DE COMPROBANTE
                                                DE PAGO DE DOCUMENTO
                                            </th>
                                            <th scope="col" colspan="1" rowspan="2">
                                                FECHA DE VENCIMIENTO
                                            </th>
                                            <th scope="col" colspan="1" rowspan="2">
                                                COD.
                                            </th>
                                            <th scope="col" colspan="1" rowspan="2">
                                                MOTIVO
                                            </th>
                                            <th scope="col" colspan="3">COMPROBANTE DE PAGO
                                                O DOCUMENTO
                                            </th>
                                            <th scope="col" colspan="1">BASE</th>
                                            <th scope="col" colspan="1" rowspan="2">IGV Y/O IPM
                                            </th>
                                            <th scope="col" colspan="1" rowspan="2">IMPORTE TOTAL DEL
                                                COMPROBANTE DE PAGO
                                            </th>
                                            <th scope="col" colspan="4">REFERENCIA DE COMPROBANTE O
                                                DOCUMENTO ORIGINAL QUE NO SE MODIFICA
                                            </th>
                                            <th scope="col" colspan="1" rowspan="2">
                                                PDF</th>
                                        </tr>
                                        <tr align="center" class="text-white border-white" style="background: #900052 ; vertical-align: middle;">
                                            <th scope="col">TP</th>
                                            <th scope="col">SERIE</th>
                                            <th scope="col">NUMERO</th>
                                            <th scope="col">IMPONIBLE DE LA OPERACIÓN GRAVADA
                                            </th>
                                            <th scope="col">FECHA</th>
                                            <th scope="col">TP</th>
                                            <th scope="col">SERIE</th>
                                            <th scope="col">NUMERO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in dataCxcs" :key="index">
                                            <td align="center">-</td>
                                            <td align="center">-</td>
                                            <td align="center">{{item.date}}</td>
                                            <td align="center">-</td>
                                            <td align="center">{{ item.of_note_credit_type.code }}</td>
                                            <td align="center">{{ item.of_note_credit_type.name }}</td>
                                            <td align="center">{{ tipo(item)}}</td>
                                            <td align="center">{{ item.serie }}</td>
                                            <td align="center">{{ item.number}}</td>
                                            <td align="center">{{ base(item)}}</td>
                                            <td align="center">{{ '-'+igv(item) }}</td>
                                            <td align="center">{{ '-'+total(item) }}</td>
                                            <td align="center">{{ item.of_voucher.date }}</td>
                                            <td align="center">{{ item.of_voucher.document =='FACTURA'? '01':'03' }}</td>
                                            <td align="center">{{ item.of_voucher.serie }}</td>
                                            <td align="center">{{ item.of_voucher.number }}</td>
                                            <td align="center">
                                                <a @click="pdf(item)" rel="noopener noreferrer">
                                                    <i class="fas fa-eye" style="color: #900052;"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr v-if="dataCxcs.length == 0">
                                            <td colspan="20" class="text-center text-xs">
                                                <strong>No se encontraron resultados</strong>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--paginate-->
                            <!-- <div class="d-flex justify-content-center" style="align-items: center">
                                <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                                    <ul class="pagination">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                        </li>
                                        <li v-if="
                        limitedStepPagination[limitedStepPagination.length - 1] <
                        stepPagination.length - 1
                      " class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if="
                        limitedStepPagination[limitedStepPagination.length - 1] !==
                        stepPagination.length
                      " class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import exportFromJson from "export-from-json";
    import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
    import moment from "moment";
    import * as XLSX from 'xlsx';
    export default {
        name: "c-contabilidad-pages-ingreso",
        components: {
            SweetAlert,
        },
        data() {
            return {
                swal: null,
                search: "",
                dataShow: [],
                dataCxcs: [],
                selectPage: 1,
                noRecordsMessage: "",
                filter: {
                    begindate: null,
                    enddate: null
                },
                totalPagesToShow: 7,
                page: 0,
                selectPage: 1,
                stepPagination: [],
                excel: []
            };
        },
        computed: {
            // cpData() {
            //     let bo = false;
            //     if (this.search.length >= 2 && this.search != "") {
            //         let q = this.search.toString().toUpperCase();
            //         this.dataShow = [];
            //         let i = 0;
            //         this.dataCxcs.forEach((element) => {
            //             let name = element.patient.name.toString().toUpperCase();
            //             if (name.includes(q)) {
            //                 this.dataShow.push(element);
            //             }
            //         });
            //         if (this.dataShow.length === 0 && this.dataCxcs.length !== 0) {
            //             this.noRecordsMessage = "No se encontraron resultados de la búsqueda";
            //         } else {
            //             this.noRecordsMessage = "";
            //         }
            //         bo = true;
            //     } else {
            //         let fin = this.selectPage * 10 - 1;
            //         let ini = fin - 9;
            //         this.dataShow = [];
            //         this.dataCxcs.forEach((element, index) => {
            //             if (ini <= index && index <= fin) {
            //                 this.dataShow.push(element);
            //             }
            //         });
            //         bo = false;
            //         this.noRecordsMessage = "";
            //     }
            //     /** paginación */
            //     this.stepPagination = [];
            //     let cantidad =
            //         bo == false
            //             ? parseInt(this.dataCxcs.length / 10)
            //             : parseInt(this.dataShow.length / 10);
            //     let residuo =
            //         bo == false
            //             ? parseInt(this.dataCxcs.length % 10)
            //             : parseInt(this.dataShow.length % 10);
            //     cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            //     for (let index = 0; index < cantidad; index++) {
            //         this.stepPagination.push(index + 1);
            //     }
            //     return this.dataShow;
            // },
            cpData() {
                let filteredData = this.dataCxcs;
                if (this.search.length >= 2 && this.search != "") {
                    let query = this.search.toString().toUpperCase();
                    filteredData = this.dataCxcs.filter((element) => {
                        let name = element.vaucher_fullname.toString().toUpperCase();
                        return name.includes(query);
                    });
                }
    
                let totalPages = Math.ceil(filteredData.length / 10);
    
                this.stepPagination = Array.from({
                        length: totalPages,
                    },
                    (_, index) => index + 1
                );
                let startIndex = (this.selectPage - 1) * 10;
                let endIndex = startIndex + 9;
                this.dataShow = filteredData.slice(startIndex, endIndex + 1);
                return this.dataShow;
            },
            limitedStepPagination() {
                const totalPages = this.stepPagination.length;
                const currentPage = this.selectPage;
    
                let startPage = 1;
                let endPage = totalPages;
                if (totalPages > this.totalPagesToShow) {
                    const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);
    
                    if (currentPage > halfPagesToShow) {
                        startPage = currentPage - halfPagesToShow;
                        endPage = currentPage + halfPagesToShow - 1;
    
                        if (endPage > totalPages) {
                            endPage = totalPages;
                            startPage = totalPages - this.totalPagesToShow + 1;
                        }
                    } else {
                        endPage = this.totalPagesToShow;
                    }
                }
    
                return Array.from({
                        length: endPage - startPage + 1,
                    },
                    (_, i) => startPage + i
                );
            },
        },
        created() {
            // this.mtdGetData();
            moment.locale("es");
            this.filter.begindate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-01";
            this.filter.enddate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-" +
                moment().format("L").substr(0, 2);
        },
        methods: {
            ...mapActions(["get", "post"]),
            //metodo obtener data
            mtdGetData: function () {
                this.post({
                        url: this.$store.getters.get__url + "/contabilidad/notescredit/" +
                            this.$store.getters.get__campus,
                        token: this.$store.getters.get__token,
                        params: this.filter
                    })
                    .then((response) => {
                        this.dataCxcs = response.data;
                        if (this.dataCxcs.length === 0) {
                            this.noRecordsMessage = "No se encontraron resultados.";
                        } else {
                            this.noRecordsMessage = "";
                        }
                        this.dataCxcs.forEach((element, index) => {
                            this.excel.push({
                                "N°": index + 1,
                                "FECHA EMISION DE COMPROBANTE DE PAGO DE DOCUMENTO": element.date,
                                "FECHA DE VENCIMIENTO": '',
                                "COMPROBANTE DE PAGO O DOCUMENTO": this.tipo(element) + '-' + element.serie + '-' + element.number,
                                "CODIGO":element.of_note_credit_type.code,
                                "MOTIVO":element.of_note_credit_type.name,
                                //"TP": this.tipo(element),
                                // "SERIE": element.serie,
                                // "NUMERO": element.number,
                                "BASE": this.base(element),
                                "IGV Y/O IPM": '-' + this.igv(element),
                                "IMPORTE TOTAL DEL COMPROBANTE DE PAGO": '-' + this.total(element),
                                "REFERENCIA DE COMPROBANTE O DOCUMENTO ORIGINAL QUE NO SE MODIFICA": element.of_voucher.date + '-' + element.of_voucher.document == 'FACTURA' ? '01' : '03' + '-' + element.of_voucher.serie + '-' + element.of_voucher.number,
                                "FECHA DE REFERENCIA DE COMPROBANTE": element.of_voucher.date,
                                // "TP1": element.of_voucher.document == 'FACTURA' ? '01' : '03',
                                // "SERIE1": element.of_voucher.serie,
                                // "NUMERO1": element.of_voucher.number,
                            });
                        });
    
                        let cantidad = parseInt(this.dataCxcs.length / 10);
                        let residuo = parseInt(this.dataCxcs.length % 10);
                        cantidad = residuo > 0 ? cantidad + 1 : cantidad;
                        for (let index = 0; index < cantidad; index++) {
                            this.stepPagination.push(index + 1);
                        }
                    })
                    .catch((errors) => {});
            },
            mtdSearch: function () {
                if (this.search.length >= 2 && this.search != "") {
                    let q = this.search.toString().toUpperCase();
                    this.dataShow = [];
                    let i = 0;
                    this.dataCxcs.forEach((element) => {
                        let name = element.patient.name.toString().toUpperCase();
                        if (name.includes(q)) {
                            this.dataShow.push(element);
                        }
                    });
                    this.selectPage = 1;
                } else {
                    // this.data_product_filter = this.dataSubSubCategories;
                }
            },
            selectedPage: function (page) {
                this.selectPage = page;
            },
            backPAge: function () {
                this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
            },
            addPAge: function () {
                this.selectPage =
                    this.selectPage == this.stepPagination.length ?
                    this.stepPagination.length :
                    this.selectPage + 1;
            },
            /** coponent */
            changePage(page) {
                this.$emit("changePage", page);
            },
            mtdBackCampus: function () {
                this.$emit("mtdBackCampus");
            },
            mtdBackRol: function () {
                this.$emit("mtdBackRol");
            },
            pdf(item) {
                this.post({
                        url: this.$store.getters.get__url + "/contabilidad/consultnotecredit/" + item.id,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.mtdGetData();
                    })
                    .catch((errors) => {});
                let json = JSON.parse(item.json);
                const pdfLink = json.enlace_del_pdf_ticket;
                if (pdfLink) {
                    window.open(pdfLink, '_blank');
                }
            },
            tipo(item) {
                let json = JSON.parse(item.json);
                return json.tipo_de_comprobante || '-';
            },
            base(item) {
                let json = JSON.parse(item.json);
                const qrData = json.codigo_qr;
                if (!qrData) return "-";
    
                const qrDataArray = qrData.split('|');
                const montoTotal = qrDataArray[4] ? qrDataArray[4].trim() : '';
                const montoNeto = qrDataArray[5] ? qrDataArray[5].trim() : '';
    
                if (montoTotal && montoNeto) {
                    const diferencia = parseFloat(montoTotal) - parseFloat(montoNeto);
                    const diferenciaFormateada = diferencia.toFixed(2);
                    return parseFloat(diferenciaFormateada);
                }
    
                return '-';
            },
            igv(item) {
                let json = JSON.parse(item.json);
                const qrData = json.codigo_qr;
                if (!qrData) return "-";
    
                const qrDataArray = qrData.split('|');
                return qrDataArray[4] ? qrDataArray[4].trim() : '-';
            },
            total(item) {
                let json = JSON.parse(item.json);
                const qrData = json.codigo_qr;
                if (!qrData) return "-";
    
                const qrDataArray = qrData.split('|');
                return qrDataArray[5] ? qrDataArray[5].trim() : '-';
            },
    
            backPage() {
                this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
            },
            nextPage() {
                this.selectPage =
                    this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
            },
            selectedPage(page) {
                this.selectPage = page;
            },
            exportExcel: function () {
                // this.post({
                //         url: this.$store.getters.get__url + "/exportexcel",
                //         token: this.$store.getters.get__token,
                //          params: this.excel,
                //     })
                //     .then((response) => {
    
                //     })
                //     .catch((errors) => {});
    
                // const datos = this.excel;
                // const fileName = 'Reporte notas de credito';
                // const typeExport = exportFromJson.types.xls;
                // exportFromJson({
                //     data: datos,
                //     fileName: fileName,
                //     exportType: typeExport,
                // });
                const ws = XLSX.utils.table_to_sheet(document.getElementById('dtb-cxc'));
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'N. Cred.');
                XLSX.writeFile(wb, 'Notas_credito.xlsx');
            },
        },
        filters: {
            formatDate(date) {
                return date != null ? moment(date).format("DD/MM/YYYY") : '-';
            },
        },
    };
    </script>
    
    <style scoped>
    #dtb-cxc {
        max-height: 400px;
        overflow-y: auto;
    }
    
    #dtb-cxc {
        max-width: 1400px;
        overflow-x: auto;
    }
    
    #btn-edit {
        color: #39b54a;
        font-size: 25px;
        margin: 0px 2px;
    }
    
    #btn-edit:hover {
        color: #217b2d;
    }
    
    #btn-remove {
        color: #ff0000;
        font-size: 25px;
        margin: 0px 2px;
    }
    
    #btn-remove:hover {
        color: #a30404;
    }
    
    .btn-bm-noradius {
        margin-left: 10px;
        width: 150px;
        height: 38px;
    }
    
    .btn-bm-noradius:hover {
        background: rgb(170, 2, 95);
        background-color: rgb(170, 2, 95);
        color: white;
    }
    
    .btn-register {
        background: rgb(144, 0, 82);
    }
    
    .btn-register:hover {
        background: rgb(170, 2, 95);
    }
    
    .modal-header {
        background: rgb(144, 0, 82);
    }
    
    .close {
        cursor: pointer;
    }
    
    .text-start {
        text-align: left;
    }
    
    .tr-table>th>div {
        background: #900052;
        border-radius: 7px;
        padding: 0px;
        margin: 4px;
        height: 45px;
        padding-top: 10px;
    }
    
    .tr-table>th {
        padding: 0px;
    }
    
    .tbody-table>td>div {
        background: #eaecee;
        border-radius: 15px;
        padding: 0px;
        margin: 4px;
        height: 45px;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;
    }
    
    .tbody-table>td {
        padding: 0px;
    }
    
    .tbody-table>td>div>div {
        border-radius: 10px;
    }
    
    #btn-close {
        width: 49%;
    }
    
    .table-th {
        width: 50% !important;
    }
    
    .table-th-state {
        width: 18% !important;
    }
    
    .page-link {
        color: black !important;
    }
    
    .page-link.active {
        color: white !important;
        border-color: white !important;
        background: rgb(144, 0, 82);
    }
    
    .page {
        color: white !important;
        background: rgb(144, 0, 82);
    }
    
    .checkbox-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 8px;
    }
    
    @media (max-width: 480px) {
        #btn-edit {
            font-size: 13px !important;
        }
    
        #btn-remove {
            font-size: 13px !important;
        }
    
        #btn-close {
            width: 100%;
        }
    
        .container {
            font-size: 11px !important;
        }
    
        .btn-register {
            width: 100% !important;
            margin-top: 0 !important;
        }
    
        .td-div-a {
            padding-top: 10px !important;
        }
    
        .td-div-state>div>div {
            margin-top: 3px !important;
        }
    }
    
    @media (max-width: 415px) {
        #state_schedule {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0px !important;
        }
    
        #state_schedule div {
            width: 100%;
        }
    }
    </style>
    
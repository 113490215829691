<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Nombre</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.name" placeholder="Nombre">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Apellido</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.last_name" placeholder="Apellido">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Dni</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.dni" placeholder="Dni" @keydown="filterKey" @keyup="mtdUniqueDni" maxlength="8">
            </div>
            <span class="text-danger" style="font-size: 10px;" v-if="!dniUnique">
                Ya existe un usuario registrado con este DNI</span>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Email</label>
                <input type="email" class="form-control form-control-sm" v-model="data_detail.email" @keyup="mtdUniqueEmail" placeholder="Email">
                <span class="text-danger" style="font-size: 10px;" v-if="!emailUnique">
                    Correo ya usado</span>
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-12 col-xl-6 px-1">
                <label for="name" class="form-label">S/ Precio IC</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_ic" @keyup="validatePrice_Ic" placeholder="Precio ic">
            </div>
            <div class="mb-2 col-12 col-xl-6 px-1">
                <label for="name" class="form-label">S/ Precio Consulta</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_consulta" @keyup="validatePrice_Consultas" placeholder="Precio Consulta">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-12 col-xl-6 px-1">
                <label for="name" class="form-label">CMP</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.cmp" placeholder="CMP" maxlength="6">
            </div>
            <div class="mb-2 col-12 col-xl-6 px-1">
                <label for="name" class="form-label">RNE</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.rne" placeholder="RNE">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="col-md-12 mb-2 px-1" v-if="num == 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="data_detail.state == 1 ? true : false" @change="mtdChangeState" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">{{ data_detail.state == 1 ? "Activo" : "Inactivo" }}</label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpDisable">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                id: "",
                name: '',
                last_name: '',
                dni: '',
                email: '',
                price_ic: '',
                price_consulta: '',
                cmp: '',
                rne: '',
                state: 1
            },
            emailUnique: true,
            dniUnique: true,
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        data: Array,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpDisable() {
            const {
                name,
                last_name,
                dni,
                price_ic,
                price_consulta,
                rne,
                cmp,
                email
            } = this.data_detail;

            const state = [
                name,
                last_name,
                dni,
                price_ic,
                price_consulta,
                rne,
                cmp,
                email
            ].every(field => field && field !== null) && dni.length === 8;

            // Verificar si el DNI o el email no son únicos
            if (state) {
                return !this.dniUnique || !this.emailUnique;
            }

            return !state;
        },

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
            this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdClose() {
            this.data_detail.name = '';
            this.data_detail.last_name = '';
            this.data_detail.dni = '';
            this.data_detail.email = '';
            this.data_detail.price_ic = '';
            this.data_detail.price_consulta = '';
            this.data_detail.cmp = '';
            this.data_detail.rne = '';
            this.data_detail.email = '';
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num);
            this.data_detail.name = '';
            this.data_detail.last_name = '';
            this.data_detail.dni = '';
            this.data_detail.email = '';
            this.data_detail.price_ic = '';
            this.data_detail.price_consulta = '';
            this.data_detail.cmp = '';
            this.data_detail.rne = '';
            this.data_detail.email = '';
        },
        mtdSelectRol(event) {
            this.data_detail.rol_id = event.target.value;
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdUniqueEmail: function () {
            let dataFilter = this.data.filter(i => i.id !== this.data_detail.id);
            let data = dataFilter.filter(item => item.email === this.data_detail.email);
            this.emailUnique = data.length == 0;
        },
        mtdUniqueDni: function () {
            let dataFilter = this.data.filter(i => i.id !== this.data_detail.id);
            let data = dataFilter.filter(item => item.dni === this.data_detail.dni);
            this.dniUnique = data.length == 0;
        },
        validatePrice_Ic(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_ic = sanitizedValue;
        },
        validatePrice_Consultas(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_consulta = sanitizedValue;
        },
    },

};
</script>

<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
        <Proforma v-if="page == 'proforma'" @mtdChangePage="mtdChangePage" />
        <Cirujia v-if="page == 'cirujia'" @mtdChangePage="mtdChangePage" />
            
    </div>
    <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Nav from '../../components/Sat/Nav.vue'
import Home from '../../components/Sat/Home.vue'
import Footer from '../../components/Sat/Footer.vue'
import Proforma from '@/components/Sat/Proformas.vue'
import Cirujia from '@/components/Sat/Cirujias.vue'
export default {
    name: "SatView",
    components: {
        Nav,
        Home,
        Proforma,
        Footer,
        Cirujia
    },
    data() {
        return {
            prefix: 'sat',
            page: 'home',
            rol_name: null,
            stateRolAdmin: false,
        }
    },
    props: {
        rolname: String,
        dataRoles: Array
    },
    created() {
        this.rol_name = this.rolname;
        this.mtdGetRolAdmin();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function (page) {
            this.page = page;
        },
        mtdGetRolAdmin: function () {
            this.dataRoles.forEach(element => {
                if (element.role_id == 6) {
                    this.stateRolAdmin = true;
                }
            });
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
    }

}
</script>

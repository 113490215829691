<template>
  <div>
    <div class="container">
      <div class="row text-end">
        <div class="col-md-12 mt-3 mb-4 float-right btn-home">
          <button
            type="button"
            class="btn btn-outline-main"
            @click="returnHome"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-anular" class="d-flex justify-content-center">
        <div id="div-anular" class="card w-100 mb-5">
          <div class="text-center card-header text-white h4 bg-main">
            <strong>COTIZACION</strong>
          </div>
          <div class="card-body">
            <div class="mx-0">
              <div class="row mx-0 mt-3">
                <div class="col-xl-8 py-xl-1">
                  <span class="fw-bold text-main">Apellido y Nombre:</span>&nbsp; 
                  <a href="#" id="newPatientLink" style="color: #900052;" @click="mtdAddPatient">[ + Nuevo ]</a>
                  <v-select
                    class="text-dark"
                    :options="lstPatient"
                    v-model="patient"
                    :reduce="(patient) => patient"
                    label="formattedLabel"
                    placeholder="'- Buscar Paciente dni o nombres -'"
                    required
                    :clearable="false"
                    @search="searchPatient"
                    @input="clearData"
                  >
                    <template slot="no-options">
                      No hay pacientes para la busqueda.
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.document }} {{ option.name }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.document }} {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                </div>
                <div class="col-xl-4 py-xl-1">
                    <div style="margin-bottom: 10px;">
                        <span class="text-main fw-bold">N° Historia Clinica:</span>
                        <strong>
                            {{
                                patient.name.length > 0 ? (patient.id !== null && patient.id !== 0 ? "HC-" + patient.id : "EXTERNO") : ""
                            }}
                        </strong>
                    </div>
                    <div>
                        <span class="text-main fw-bold">Doctor:</span>
                        <strong>
                            {{
                                patient.doctor_name ?  patient.doctor_name : "-"
                            }}
                        </strong>
                    </div>
                  </div>
                <div class="col-xl-8 py-xl-1">
                  <span class="fw-bold text-main">Celular:</span>
                  <span> {{ patient.phone }} </span>
                </div>
                <div class="col-xl-4 py-xl-1">
                  <span class="text-main fw-bold">Edad:</span>
                  <span> {{ patient.year }}</span>
                </div>
                <div class="col-xl-8 py-xl-1">
                  <span class="fw-bold text-main">Fecha:</span>
                  <span> {{ date }}</span>
                </div>
                <div class="col-xl-4 py-xl-0 pt-2">
                  <span class="bg-main text-white py-1 px-1">VIGENCIA :</span>
                  <span class="bg-main text-white py-1 px-1">
                    {{ vigencia }} MESES</span
                  >
                </div>
                <hr class="mt-3" />

                <div class="col-xl-12 mt-2 border-2"  v-if="patient.id !== null">
                  <div class="row mx-0 border border-main py-2 px-2">
                    <div class="col-12 row mx-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Técnica Quirúrgica:</span>
                      </div>
                      <!--Select Tecnica-->
                      <div class="col-xl-7 py-xl-0 py-2" v-if="patient.products && patient.products.length > 0">
                          <v-select
                              class="text-dark"
                              :options="patient.products"
                              v-model="product"
                              :reduce="(product) => product"
                              label="name"
                              placeholder="- Buscar Servicio -"
                              required
                              :clearable="false"
                          >
                              <template slot="no-options">
                                  No hay servicios para la búsqueda.
                              </template>
                          </v-select>
                      </div>
                      <div class="col-xl-7 py-xl-0 py-2" v-else>
                          <v-select
                              class="text-dark"
                              :options="filteredServicesTecnica"
                              v-model="product"
                              :reduce="(product) => product"
                              label="name"
                              placeholder="- Buscar Servicio -"
                              required
                              :clearable="false"
                              @search="searchProduct"
                          >
                              <template slot="no-options">
                                  No hay servicios para la búsqueda.
                              </template>
                          </v-select>
                      </div>
                      <!-- Fin Select Tecnica-->
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="product.price_sale"
                          @keypress="validateNumberInput"
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <div class="col-12 row mx-0 my-xl-2 my-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Lente Intraocular:</span>
                      </div>
                      <div class="col-xl-7 py-xl-0 py-2">
                        <v-select
                          class="text-dark"
                          :options="servicesLente"
                          v-model="lente"
                          :reduce="(lente) => lente"
                          label="name"
                          placeholder="- Buscar Servicio -"
                          required
                          :clearable="false"
                          @search="searchOptica"
                        >
                          <template slot="no-options">
                            No hay servicios para la busqueda.
                          </template>
                        </v-select>
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="lente.price_sale"
                          @keypress="validateNumberInput"
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <div class="col-12 row mx-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Gancho de Iris:</span>
                      </div>
                      <div class="row px-0 mx-0 col-xl-7 py-xl-0 py-2">
                        <div class="col-xl-9"></div>
                        <div class="col-xl-3">
                          <select
                          v-model="selectedOption"
                            @change="actualizarOpcion"
                            class="form-control form-control-sm"
                            aria-label="Default select example"
                            id="miSelect"
                          >
                            <option value="" selected disabled>
                              Seleccione...
                            </option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="detailsOpcion.gancho.price_sale"
                          disabled
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <div class="col-12 row mx-0 mt-xl-2 mb-xl-1 my-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Otro Item:</span>
                      </div>
                      <div class="col-xl-7 py-xl-0 py-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="detailsItem.servicio"
                        />
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="detailsItem.price_sale"
                          @keypress="validateNumberInput"
                        />
                      </div>
                      <div class="col-xl-1 py-xl-0 pt-2 px-xl-3">
                        <div class="h-100">
                          <button
                            :disabled="mtddisableotro"
                            @click="adddetailsitem"
                            class="border-0 h-100 btn btn-success py-0 px-xl-2 w-100"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 row mx-0 my-xl-1 my-0"
                      v-for="(item, index) in detailsOpcion.otros.items"
                      :key="index"
                    >
                      <div class="col-xl-2">
                        <span class="fw-bold"></span>
                      </div>
                      <div class="col-xl-7 py-xl-0 py-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="item.servicio"
                          disabled
                        />
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="item.price_sale"
                          disabled
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <!--Observacion-->
                    <div class="col-12 row mx-0 mt-xl-2 mb-xl-1 my-0">
                      <div class="col-xl-2 mb-1">
                        <span class="fw-bold">Recomendación:</span>
                      </div>
                     
                      <div class="col-xl-12 py-xl-0 py-2">
                        <textarea v-model="detailsOpcion.recomend" rows="3" class="form-control"></textarea>
                      </div>

                    </div>
                    <!--Fin Observacion-->
                    <div class="col-12 row mx-0 pt-xl-1 py-2 my-0 my-2">
                      <div class="col-xl-3"></div>
                      <div class="col-xl-6 btn-home">
                        <button
                          class="border-0 h-100 btn py-0 px-xl-2 w-100"
                          @click="adddetailsopcionPro"
                        >
                          AÑADIR OPCION
                        </button>
                      </div>
                      <div class="col-xl-3"></div>
                    </div>
                  </div>
                </div>
                
                <div class="col-xl-12 mt-2 border-2" v-else>
                  <div class="row mx-0 border border-main py-2 px-2">
                    <div class="col-12 row mx-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Técnica Quirúrgica:</span>
                      </div>
                      <!--Select Tecnica-->
                      <div class="col-xl-7 py-xl-0 py-2">
                          <v-select
                              class="text-dark"
                              :options="servicesTecnica"
                              v-model="product"
                              :reduce="(product) => product"
                              label="name"
                              placeholder="- Buscar Servicio -"
                              required
                              :clearable="false"
                              @search="searchProduct"
                          >
                              <template slot="no-options">
                                  No hay servicios para la búsqueda.
                              </template>
                          </v-select>
                      </div>
                      <!-- Fin Select Tecnica-->
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="product.price_sale"
                          @keypress="validateNumberInput"
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <div class="col-12 row mx-0 my-xl-2 my-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Lente Intraocular:</span>
                      </div>
                      <div class="col-xl-7 py-xl-0 py-2">
                        <v-select
                          class="text-dark"
                          :options="servicesLente"
                          v-model="lente"
                          :reduce="(lente) => lente"
                          label="name"
                          placeholder="- Buscar Servicio -"
                          required
                          :clearable="false"
                          @search="searchOptica"
                        >
                          <template slot="no-options">
                            No hay servicios para la busqueda.
                          </template>
                        </v-select>
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="lente.price_sale"
                          @keypress="validateNumberInput"
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <div class="col-12 row mx-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Gancho de Iris:</span>
                      </div>
                      <div class="row px-0 mx-0 col-xl-7 py-xl-0 py-2">
                        <div class="col-xl-9"></div>
                        <div class="col-xl-3">
                          <select
                          v-model="selectedOption"
                            @change="actualizarOpcion"
                            class="form-control form-control-sm"
                            aria-label="Default select example"
                            id="miSelect"
                          >
                            <option value="" selected disabled>
                              Seleccione...
                            </option>
                            <option value="SI">SI</option>
                            <option value="NO">NO</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="detailsOpcion.gancho.price_sale"
                          disabled
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <div class="col-12 row mx-0 mt-xl-2 mb-xl-1 my-0">
                      <div class="col-xl-2">
                        <span class="fw-bold">Otro Item:</span>
                      </div>
                      <div class="col-xl-7 py-xl-0 py-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="detailsItem.servicio"
                        />
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="detailsItem.price_sale"
                          @keypress="validateNumberInput"
                        />
                      </div>
                      <div class="col-xl-1 py-xl-0 pt-2 px-xl-3">
                        <div class="h-100">
                          <button
                            :disabled="mtddisableotro"
                            @click="adddetailsitem"
                            class="border-0 h-100 btn btn-success py-0 px-xl-2 w-100"
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 row mx-0 my-xl-1 my-0"
                      v-for="(item, index) in detailsOpcion.otros.items"
                      :key="index"
                    >
                      <div class="col-xl-2">
                        <span class="fw-bold"></span>
                      </div>
                      <div class="col-xl-7 py-xl-0 py-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="item.servicio"
                          disabled
                        />
                      </div>
                      <div class="col-xl-2">
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="item.price_sale"
                          disabled
                        />
                      </div>
                      <div class="col-xl-1"></div>
                    </div>
                    <!--Observacion-->
                    <div class="col-12 row mx-0 mt-xl-2 mb-xl-1 my-0">
                      <div class="col-xl-2 mb-1">
                        <span class="fw-bold">Recomendación:</span>
                      </div>
                     
                      <div class="col-xl-12 py-xl-0 py-2">
                        <textarea v-model="detailsOpcion.recomend" rows="3" class="form-control"></textarea>
                      </div>

                    </div>
                    <!--Fin Observacion-->
                    <div class="col-12 row mx-0 pt-xl-1 py-2 my-0 my-2">
                      <div class="col-xl-3"></div>
                      <div class="col-xl-6 btn-home">
                        <button
                          class="border-0 h-100 btn py-0 px-xl-2 w-100"
                          @click="adddetailsopcion"
                        >
                          AÑADIR OPCION
                        </button>
                      </div>
                      <div class="col-xl-3"></div>
                    </div>
                  </div>
                </div>

                <hr class="mt-4" />
                <div class="col-12">
                  <div
                    class="row mx-0 px-0"
                    v-for="(opcion, index) in opciones"
                    :key="index"
                  >
                    <div class="col-12">
                      <span class="fw-bold">Opcion {{ index + 1 }}</span>
                    </div>
                    <div class="col-xl-2"></div>
                    <div class="col-xl-10" id="content-timelinetable">
                      <table class="table table-borderless">
                        <caption class="py-0 my-0"></caption>
                        <th></th>
                        <tbody>
                          <tr
                            class="border-0"
                            v-if="
                              opcion.tecnica.service != '' &&
                              opcion.tecnica.price != ''
                            "
                          >
                            <td class="text-start w-25">
                              T&eacute;cnica Quir&uacute;rgica
                            </td>
                            <td class="text-center w-50">
                              {{ opcion.tecnica.name }}
                            </td>
                            <td class="border border-2 text-end w-25">
                              {{
                                parseFloat(opcion.tecnica.price_sale).toFixed(2)
                              }}
                            </td>
                          </tr>
                          <tr
                            class="border-0"
                            v-if="
                              opcion.lente.service != '' &&
                              opcion.lente.price != ''
                            "
                          >
                            <td class="text-start w-25">Lente Intraocular</td>
                            <td class="text-center w-50">
                              {{ opcion.lente.name }}
                            </td>
                            <td class="border border-2 text-end w-25">
                              {{
                                parseFloat(opcion.lente.price_sale).toFixed(2)
                              }}
                            </td>
                          </tr>
                          <tr
                            class="border-0"
                            v-if="opcion.gancho.opcion != ''"
                          >
                            <td class="text-start w-25">Gancho de Iris</td>
                            <td class="text-center w-50">
                              {{ opcion.gancho.opcion }}
                            </td>
                            <td class="border border-2 text-end w-25">
                              {{
                                parseFloat(opcion.gancho.price_sale).toFixed(2)
                              }}
                            </td>
                          </tr>
                          <tr
                            class="border-0"
                            v-for="(otro, ind) in opcion.otros.items"
                            :key="ind"
                          >
                            <td class="text-start w-25" v-if="ind == 0">
                              Otros:
                            </td>
                            <td v-else class="w-25"></td>
                            <td class="text-center w-50">
                              {{ otro.servicio }}
                            </td>
                            <td class="border border-2 text-end w-25">
                              {{ parseFloat(otro.price_sale).toFixed(2) }}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td colspan="2" class="fw-bold">
                              Total Cirugía (Precio por ojo) :
                            </td>
                            <td
                              class="border border-2 bg-main text-white text-end"
                            >
                              S/ {{mtdgetSubtotal(index)}}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="fw-bold">
                              Recomendacion :
                            </td>

                            <td colspan="2" class="fw-bold">
                              <div class="col-xl-12 py-xl-0 py-2">
                                <textarea rows="3" class="form-control" v-model="opcion.recomend" disabled></textarea>
                            </div>
                            </td>

                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div v-if="opciones.length > 0 && (patient.idPa && patient.name && patient.document)" class="row mx-0 px-0 mt-3">
                    <div class="col-xl-2"></div>
                    <div class="col-xl-8 btn-home px-0">
                      <button
                        class="border-0 h-100 btn py-0 px-xl-2 w-100"
                        @click="mtdStore"
                      >
                        GUARDAR
                      </button>
                    </div>
                    <div class="col-xl-2"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cModalAddPatientVue
      :title="modalAddPatient.title"
      :boo_modal="modalAddPatient.modal_form"
      @closeModalAddPatient="closeModalAddPatient"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import moment from "moment";
import cModalAddPatientVue from "./Modals/cModalAddPatient.vue";

export default {
  name: "c-sat-proforma",
  components: {
    SweetAlert,
    cModalAddPatientVue,
  },
  data() {
    return {
      swal: null,
      lstPatient: [],
      date: "",
      patient: {
        id: "",
        name: "",
        document: "",
        phone: "",
        year: "",
      },
      vigencia: 3,
      servicesTecnica: [],
      product: {
        id: "",
        name: "",
        price_sale: "",
      },
      servicesLente: [],
      lente: {
        id: "",
        name: "",
        price_sale: "",
      },
      detailsOpcion: {
        tecnica: {
          id: "",
          name: "",
          price: "",
        },
        lente: {
          id: "",
          name: "",
          service: "",
          price: "",
        },
        gancho: {
          id: "",
          name: "",
          opcion: "",
          price_sale: "",
        },
        otros: {
          name: "",
          items: [],
        },
        recomend:"",
      },
      detailsItem: {
        id: "",
        servicio: "",
        price_sale: "",
      },
      opciones: [],
      recomendation:"",
      selectedOption: '', 

      modalAddPatient: {
        title: "AGREGAR NUEVO PACIENTE",
        item: [],
        modal_form: false,
        pos: null,
      },
      aparecer: false,
    };
  },
  computed: {
    mtddisableotro: function () {
      return !(
        this.detailsItem.price_sale !== ""
      );
    },
    filteredServicesTecnica() {
      return this.servicesTecnica.filter(service => 
        !this.opciones.some(op => op.tecnica && op.tecnica.id === service.id));
    }
  },
  created() {
    moment.locale("es");
    this.date =
      moment().format("L").substr(0, 2) +
      "/" +
      moment().format("L").substr(3, 2) +
      "/" +
      moment().format("L").substr(6, 4);
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
      this.$emit("mtdChangePage", "home");
    },
    searchPatient: function (search, loading) {
      if (search.length > 3) {
        this.clearData();
        this.lstPatient = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/quote/patient/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            //this.lstPatient = response.data;
            this.lstPatient = response.data.map(patient => ({
              ...patient,
              formattedLabel: `${patient.name} - ${patient.document}`,
            }));
            this.aparecer = true;
            loading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      // loading(true)
    },

    clearDataa: function() {
    },
    clearData: function() {
        this.product = {
          id: "",
          name: "",
          price_sale: "",
        };
        this.lente = {
          id: "",
          name: "",
          price_sale: "",
        };
        this.servicesTecnica = [];
        this.servicesLente = [];
        this.detailsOpcion = {
          tecnica: {
            id: "",
            name: "",
            price: "",
          },
          lente: {
            id: "",
            name: "",
            service: "",
            price: "",
          },
          gancho: {
            id: "",
            name: "",
            opcion: "",
            price_sale: "",
          },
          otros: {
            name: "",
            items: [],
          },
          recomend:"",
        };
        this.detailsItem =  {
          id: "",
          servicio: "",
          price_sale: "",
        };
        this.opciones = [];
        this.selectedOption = '';
    },

    searchProduct: function (search, loading) {
      if (search.length > 3) {
        this.servicesTecnica = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/quote/product/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.servicesTecnica = response.data;
            loading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    searchOptica: function (search, loading) {
      if (search.length > 3) {
        this.servicesLente = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/quote/lente/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.servicesLente = response.data;
            loading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    actualizarOpcion(evento) {
      this.detailsOpcion.gancho.opcion = evento.target.value;
      if (this.detailsOpcion.gancho.opcion == "SI") {
        this.detailsOpcion.gancho.price_sale = "500.00";
      } else {
        this.detailsOpcion.gancho.price_sale = "0.00";
      }
    },
    adddetailsitem: function () {
      this.detailsItem.id = this.detailsOpcion.otros.items.length + 1;
      this.detailsOpcion.otros.items.push(this.detailsItem);
      this.mtdhiddenDetailsitem();
    },
    mtdhiddenDetailsitem: function () {
      this.detailsItem = {
        id: "",
        servicio: "",
        price_sale: "",
      };
    },
    adddetailsopcion: function () {
      let boo = false;
      if (this.product.name.length > 0) {
        this.detailsOpcion.tecnica = this.product;
        if (isNaN(this.detailsOpcion.tecnica.price_sale) || this.detailsOpcion.tecnica.price_sale === undefined || this.detailsOpcion.tecnica.price_sale === "") {
          this.detailsOpcion.tecnica.price_sale = "0.00";
        }
        boo = true;
      }
      if (this.lente.name.length > 0) {
        this.detailsOpcion.lente = this.lente;
        if (isNaN(this.detailsOpcion.lente.price_sale) || this.detailsOpcion.lente.price_sale === undefined || this.detailsOpcion.lente.price_sale === "") {
          this.detailsOpcion.lente.price_sale = "0.00";
        }
        boo = true;
      }
      if (this.detailsOpcion.otros.items.length > 0) {
        boo = true;
      }

      if (boo) {
        this.opciones.push(this.detailsOpcion);
        // console.log(this.opciones);
      }
      this.mtdhiddenDetailopcion();
    },

    adddetailsopcionPro: function () {
      let boo = false;
      if (this.product.name.length > 0) {
        this.detailsOpcion.tecnica = this.product;
        if (isNaN(this.detailsOpcion.tecnica.price_sale) || this.detailsOpcion.tecnica.price_sale === undefined || this.detailsOpcion.tecnica.price_sale === "") {
          this.detailsOpcion.tecnica.price_sale = "0.00";
        }
        boo = true;
        // Eliminar el elemento seleccionado de las opciones disponibles
        this.patient.products = this.patient.products.filter(option => option !== this.product);
      }
      if (this.lente.name.length > 0) {
        this.detailsOpcion.lente = this.lente;
        if (isNaN(this.detailsOpcion.lente.price_sale) || this.detailsOpcion.lente.price_sale === undefined || this.detailsOpcion.lente.price_sale === "") {
          this.detailsOpcion.lente.price_sale = "0.00";
        }
        boo = true;
        // Eliminar el elemento seleccionado de las opciones disponibles
        this.servicesLente = this.servicesLente.filter(option => option !== this.lente);
      }
      if (this.detailsOpcion.otros.items.length > 0) {
        boo = true;
      }

      if (boo) {
        this.opciones.push(this.detailsOpcion);
        // console.log(this.opciones);
      }
      this.mtdhiddenDetailopcion();
    },

    mtdhiddenDetailopcion() {
      this.detailsOpcion = {
        tecnica: {
          name: "Tecnica quirúrgica",
          service: "",
          price: "",
        },
        lente: {
          name: "Lente intraocular",
          service: "",
          price: "",
        },
        gancho: {
          name: "Gancho de Iris",
          opcion: "",
          price: "",
        },
        otros: {
          name: "Otros",
          items: [],
        },
      };
      this.servicesTecnica = [];
      this.product = {
        id: "",
        name: "",
        price_sale: "",
      };
      this.servicesLente = [],
      this.lente = {
        id: "",
        name: "",
        price_sale: "",
      };
      this.selectedOption = '';
    },
    /** get sub total */
    mtdgetSubtotal: function(pos){
      let item = this.opciones[pos];
      let tot = 0;
      if (item.tecnica.name.length > 0 && !isNaN(parseFloat(item.tecnica.price_sale))) {
        tot += parseFloat(item.tecnica.price_sale);
      }
      if (item.lente.name.length > 0 && !isNaN(parseFloat(item.lente.price_sale))) {
        tot += parseFloat(item.lente.price_sale);
      }
      if(item.gancho.opcion === 'SI' && !isNaN(parseFloat(item.gancho.price_sale))) {
        tot += parseFloat(item.gancho.price_sale);
      }
      if (item.otros.items.length > 0) {
        const to = item.otros.items.map(item => item.price_sale).reduce((prev, curr) => parseFloat(prev) + parseFloat(curr), 0);
        tot += parseFloat(to);
      }
      return parseFloat(tot).toFixed(2);
    },
    /** store */
    mtdStore: function(){
      this.post({
          url: this.$store.getters.get__url + "/quote/store",
          token: this.$store.getters.get__token,
          params:{
            patient: this.patient,
            data:this.opciones,
            //recomendation:this.recomendation
          }
        })
          .then((response) => {            
            if (!response.error) {
              this.lstPatient= [];
              this.patient= {
                id: "",
                name: "",
                document: "",
                phone: "",
                year: "",
              };
              this.mtdhiddenDetailopcion();
              this.opciones= [],
              this.$refs.SweetAlert.showSuccess(response.message);
              this.clearData();
            } else {
              this.$refs.SweetAlert.showError(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$refs.SweetAlert.showError(response.message);
          });
    },

    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },

    mtdAddPatient: function () {
      this.modalAddPatient = {
        title: "AGREGAR NUEVO PACIENTE",
        modal_form: true,
      };
    },

    closeModalAddPatient: function () {
      this.modalAddPatient = {
        title: "AGREGAR NUEVO PACIENTE",
        item: [],
        modal_form: false,
        pos: null,
      };
    },
  },
};
</script>

<style scoped>
.text-main {
  color: #900052;
}
</style>

<template>
  <div id="component-llamar" class="home__wait-llamar">
    <div v-if="stateColaPaciente==''" class="">
      <button class="btn bg-main text-white" @click="mtd_listsPatients('cola_paciente')">
        Pacientes
      </button>
      <br />
      <button class="btn btn-dark mt-2" @click="mtd_listsPatients('cola_paciente_fo')">
        Pacientes (FO)
      </button>
    </div>
    <div class="">
      <Paciente v-if="stateColaPaciente=='cola_paciente'" @mtdSelectPatient="mtdSelectPatient" ref="colaPacienteRef" @mtd_listsPatients="mtd_listsPatients" />
      <PacienteFO v-if="stateColaPaciente=='cola_paciente_fo'" @mtd_listsPatients="mtd_listsPatients" @mtdSelectPatient="mtdSelectPatient" />
    </div> 
  </div>
</template>
<script>

import { mapActions } from "vuex";
import Paciente from '../../components/Medico/Cola/Paciente.vue'
import PacienteFO from '../../components/Medico/Cola/PacienteFO.vue'

export default {
  name: "ColaPacienteView",
  components: {
    Paciente,
    PacienteFO
  },
  data () {
    return {
      prefix: 'cola-pacientes',
      //page:'home',
      stateColaPaciente: ''
    }
  },
  computed: {
    /* cpd_patient() {
      return this.$store.getters["call__patient"];
    },
    cpd_call_state_end() {
      return this.$store.getters["call__state_end"];
    }, */
  },
  methods: {
    ...mapActions(["post", "execute_commit"]),

    mtd_listsPatients: function (value) {
      this.stateColaPaciente = value;
    },
    mtdSelectPatient: function (patient, code) {
        this.$emit('mtdSelectPatient', patient,code);
    },
    refreshPatients: function(){
      this.stateColaPaciente = 'cola_paciente';
      this.$nextTick(() => {
        this.$refs.colaPacienteRef.refreshPatients();
            });
    },
    showCola: function(){
      this.stateColaPaciente = 'cola_paciente';
    }
  },
};
</script>
<style>
.home__wait-llamar {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

</style>
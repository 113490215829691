<template>
<div>
    <div class="container">
        <div id="btn-home" class="col-md-12 mt-3 mb-4 d-flex justify-content-end btn-home">
            <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="card-header text-white h4 text-center" style="background: #900052">
                    <strong>MOVIMIENTO DE ALMACENES</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row mx-0 px-0">
                        <div class="col-xl-5 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                            <label for="" class="mb-2"> Almacenes Origen: </label>
                            <v-select class="text-dark form-control" v-model="almacen" @input="mtdGetDataProductos()" label="name" :options="dataAlmacenes" placeholder="- Buscar Almacenes (Escriba uno...) -" :reduce="(name) => name.id" required :clearable="false">
                                <template slot="no-options">
                                    No hay Proveedores para la busqueda.
                                </template>
                            </v-select>
                        </div>
                        <div class="col-xl-5 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                            <label for="" class="mb-2"> Almacenes Destino: </label>
                            <v-select class="text-dark form-control" v-model="destino" label="name" :options="dataDestino" placeholder="- Buscar Almacenes de Destino (Escriba uno...) -" :reduce="(name) => name.id" required :clearable="false">
                                <template slot="no-options">
                                    No hay Proveedores para la busqueda.
                                </template>
                            </v-select>
                        </div>
                        <div class="col-xl-2 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                            <label for="" class="mb-2"> </label>
                            <div class="py-xl-2 btn-home">
                                <button type="button" class="btn w-100 py-2" @click="mtdGuardar()" :disabled="mtddisabled"><i class="fas fa-warehouse"></i> GUARDAR</button>

                            </div>
                        </div>
                        <div v-if="num == 1" class="col-xl-12 mt-3 mb-4" id="content-timelinetable">
                            <div class="d-flex justify-content-between px-1 mb-2 ">
                                <h6> PRODUCTOS DEL ALMACEN {{ dataAlmacen.name }}</h6>
                                <div v-if="dataProductos.length > 0 && cpData.length > 0">
                                    <nav aria-label="Page navigation example ">
                                        <ul class="pagination mb-0 ">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>

                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>

                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 ">
                                <div class="input-group input-group mb-2">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input v-model="search" type="text" class="form-control form-control" placeholder="Escriba un producto para realizar la búsqueda" @input="calculateData(6)" />
                                </div>
                            </div>
                            <table class="table text-xxs table-bordered border-main text-center align-middle">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">FECHA DE VENCIMIENTO </th>
                                        <th scope="col">LOTE</th>
                                        <th scope="col">NOMBRE</th>
                                        <th scope="col">MOLECULE/LENTE</th>
                                        <th scope="col">MARCA</th>
                                        <th scope="col">COMERCIAL</th>
                                        <th scope="col">PRESENTACION</th>
                                        <th scope="col">CONCENTRACION</th>
                                        <th scope="col">MODELO</th>
                                        <th scope="col">SERIE</th>
                                        <th scope="col">CANTIDAD</th>
                                        <th scope="col">SALIENTE</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-if="dataProductos.length === 0">
                                        <td colspan="6" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr v-for="(item, index) in cpData" :key="index">

                                        <td>{{ item.of_dispatch_detail.date}}</td>
                                        <td>{{ item.of_dispatch_detail.lote }}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.of_product.name }}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.molecule }}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.of_brand == null? '-':item.of_dispatch_detail.of_productodetail.of_brand.name }}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.comercial== null ? '-': item.of_dispatch_detail.of_productodetail.comercial}}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.presentacion == null ? '-': item.of_dispatch_detail.of_productodetail.presentacion }}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.concentration == null ? '-' :item.of_dispatch_detail.of_productodetail.concentration }}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.modelo == null ? '-' : item.of_dispatch_detail.of_productodetail.modelo }}</td>
                                        <td>{{ item.of_dispatch_detail.of_productodetail.serie == null ? '-' : item.of_dispatch_detail.of_productodetail.modelo}}</td>
                                        <td>{{ mtdcantidad(item) }}</td>
                                        <td> <input type="text" class="form-control form-control-sm" v-model="item.saliente" @input="mtdvalidatecantidad(item)" placeholder="saliente"></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-pages-movimientos",
    data() {
        return {
            dataShow: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataAlmacenes: [],
            almacen: '',
            destino: '',
            dataProductos: [],
            dataAlmacen: {},
            num: 0,
            dataDestino: [],
            numproduct: 0,
            search: '',
        }
    },
    created() {
        this.mtdGetData();
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },

    },
    computed: {
        mtddisabled() {
            return !(this.almacen != '' && this.destino != '' && this.dataProductos.length != 0 && this.numproduct != 0)
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdvalidatecantidad(item) {
            let salientenum = []
            this.dataProductos.forEach(item => {
                if (item.saliente) {
                    salientenum.push(item.saliente)

                }
            })
            this.numproduct = salientenum.length
            if (item.new_quantity < item.saliente) {
                Swal.fire({
                    title: "¡Advertencia!",
                    text: "El valor ingresado excede el permitido. El valor máximo permitido es: " + parseInt(item.new_quantity),
                    icon: "warning",
                    width: "400px",
                    confirmButtonColor: "rgb(170, 2, 95)",
                });
                item.saliente = '';
                this.numproduct = this.numproduct - 1
            }
        },
        changePage(page) {
            this.$emit("returnHome", page);
        },
        mtdGetDataProductos: function () {
            console.log(this.almacen)
            this.search =''
            this.get({
                url: this.$store.getters.get__url + "/Logistica/ProductoAlmacen/" + this.almacen,
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.dataProductos = response.data.productos;
                this.dataAlmacen = response.data.almacen
                this.num = 1
                this.destino = '';
                this.dataDestino = [];
                const today = new Date();
                this.dataProductos = this.dataProductos.filter(producto => {
                    const fechaVencimiento = new Date(producto.of_dispatch_detail.date);
                    if (fechaVencimiento < today) {
                        return false;
                    }
                    return true;
                });
                this.dataProductos.sort((a, b) => {
                    const fechaA = new Date(a.of_dispatch_detail.date);
                    const fechaB = new Date(b.of_dispatch_detail.date);
                    return fechaA - fechaB;
                });
                this.dataAlmacenes.forEach(element => {
                    if (this.almacen != element.id) {
                        this.dataDestino.push(element)
                    }
                });
                this.calculateData(6)
                
            }).catch((error) => {
                console.log(error);
            });
        },
        calculateData(items) {
            let filteredData = this.dataProductos;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataProductos.filter((element) => {
                    let molecule = element.of_dispatch_detail.of_productodetail.molecule.toString().toUpperCase();
                    let name = element.of_dispatch_detail.of_productodetail.of_product.name.toString().toUpperCase();
                    return molecule.includes(query) || name.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Maintainer/indexAlmacenes",
                token: this.$store.getters.get__token,
            }).then((response) => {
                let dataalmacens = []
                dataalmacens = response.data;
                dataalmacens.forEach(item => {
                    if (item.id != 5) {
                        this.dataAlmacenes.push(item)
                    }
                });
                this.almacen = this.dataAlmacenes[0].id;
                this.mtdGetDataProductos();
              
            }).catch((error) => {
                console.log(error);
            });
        },
        mtdcantidad(cantidad) {
            return parseInt(cantidad.new_quantity)
        },

        mtdGuardar: function () {
            let productsaliente = []
            this.dataProductos.forEach(item => {
                if (item.saliente) {
                    productsaliente.push(item)
                }
            })
            let data = {
                id_origen: this.almacen,
                id_destino: this.destino,
                productsaliente: productsaliente
            }
            this.post({
                url: this.$store.getters.get__url + "/Logistica/storeMovimiento",
                token: this.$store.getters.get__token,
                params: data
            }).then((response) => {
                Swal.fire({
                    title: "Registro Exitoso",
                    text: "Perfecto!",
                    icon: "success",
                    width: "400px",
                    confirmButtonColor: "rgb(170, 2, 95)",
                });
                this.dataProductos = [];
                this.almacen = "";
                this.destino = "";
                this.num = 0;
                this.numproduct = 0;
                this.msg = '';
                this.search =''
            }).catch((error) => {
                console.log(error);
            })
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },

    },
}
</script>

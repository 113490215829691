<template>
<div>
    <div class="p-3">
        <div class="d-flex title-option">
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="card-header text-white h4 bg-main text-center">
                    <strong>RESULTADOS DE IMAGÉNES</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex">
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-sm-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-sm-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <div class="input-group input-group-sm mb-2 mb-sm-2">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input v-model="search" type="text" class="form-control form-control" placeholder="Buscar Paciente por DNI o Nombre" @input="mtdSearchFilter" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!--tabla-->
                        <div class="col-md-12" id="content-timelinetable">
                            <div>
                                <table id="tb-anular" class="table table-borderless">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class="text-white table-th text-center">
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                N°
                                            </th>
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                PACIENTE
                                            </th>
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                DNI
                                            </th>
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                FECHA
                                            </th>
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                TELEFONO
                                            </th>
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                IMAGENES
                                            </th>
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                ESTADOS
                                            </th>
                                            <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">
                                                OPCIONES
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="text-xs table-body" v-if="cpData.length == 0">
                                            <td class="border border-5 border-white align-middle text-center" colspan="8">
                                                No hay resultados
                                            </td>
                                        </tr>
                                        <tr class="text-xs table-body" v-for="(item, index) in cpData" :key="index">
                                            <td class="border border-5 border-white align-left align-middle">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="border border-5 border-white align-left align-middle">
                                                {{ item.patient_name }}
                                            </td>
                                            <td class="border border-5 border-white align-left align-middle">
                                                {{ item.of_patient_document }}
                                            </td>
                                            <td class="border border-5 border-white align-left align-middle">
                                                {{ item.date }}
                                            </td>
                                            <td class="border border-5 border-white align-left align-middle">
                                                {{ item.of_patient_phone ?  item.of_patient_phone : "-"}}
                                            </td>
                                            <td class="border border-5 border-white align-left align-middle">
                                                <div class="dropdown">
                                                    <a class="btn btn-sm btn-info dropdown-toggle text-xxs" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        {{item.ayudadx.length }} Examenes
                                                    </a>
                                                    <ul class="dropdown-menu" v-if="item.state == 3">
                                                        <li v-for="(exam, inda) in item.ayudadx"><a class="dropdown-item text-xxs"  @click="openmodalexam(exam,item)">{{exam}}</a></li>
                                                    </ul>
                                                    <ul class="dropdown-menu" v-else>
                                                        <li v-for="(exam, inda) in item.ayudadx" :key="inda"><a class="dropdown-item text-xxs" @click="showAlert" >{{exam}}</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                            <!--state!-->
                                            <td class="border border-5 border-white align-left align-middle">
                                                {{ item.state == 2? 'En proceso' : 'Culminado' }}
                                            </td>
                                            <td class="text-center border border-5 border-white align-middle">
                                                <div class="d-flex justify-content-center">
                                                    <a :href="generateWhatsAppLink(item.patient_name, item.date , item.of_patient_phone)" target="_blank" class="btn btn-success btn-sm me-2">
                                                        <i class="fab fa-whatsapp" style="font-size: 20px;"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!--pag-->
                        <div v-if="cpData.length > 0" class="d-flex justify-content-center px-1 mb-4">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination mb-1">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="
                            selectPage === 1 ? 'page-link active' : 'page-link'
                          " href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="
                            selectPage === item ? 'page-link active' : 'page-link'
                          " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if="
                          limitedStepPagination[
                            limitedStepPagination.length - 1
                          ] <
                          stepPagination.length - 1
                        " class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if="
                          limitedStepPagination[
                            limitedStepPagination.length - 1
                          ] !== stepPagination.length
                        " class="page-item">
                                        <a :class="
                            selectPage === limitedStepPagination.length + 2
                              ? 'page-link active'
                              : 'page-link'
                          " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
    <cModalViewExamen :title="modal.title" :boo_modal="modal.boo_modal" :dataImg="dataImg" :dataPdf="dataPdf" @closeCarrusel="closeCarrusel" />
    <cModalExamenesVue @openmodalexam="openmodalexam" :other_exams="other_exams" :patient_id="patient_id" :title="modaldexam.title" :boo_modal="modaldexam.modal_form" @mtdclosemodalexam="mtdclosemodalexam" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import cModalExamenesVue from './Modals/cModalExamenes.vue';
import cModalViewExamen from '@/components/Oftalmologia/Modals/cModalViewImagen.vue';
import moment from "moment";

export default {
    name: "c-admision-result",
    components: {
        SweetAlert,
        cModalExamenesVue,
        cModalViewExamen

    },
    data() {
        return {
            swal: null,
            search: "",
            filter: {
                init: "",
                end: "",
            },
            dataImg: [],
            dataPdf: [],
            dataExamen: [],
            data: [],
            dataShowTable: [],
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            Documents: {},
            modaldexam: {
                title: '',
                modal_form: false
            },
            patient_id: null,
            other_exams: [],
            cabecera: {
                title: "",
                patient: "",
                option: "",
                of_reservation_id: "",
            },
            modal:{
                title:'',
                boo_modal:false
            }

        };
    },
    props: {
        stateRolAdmin: Boolean,
    },
    created() {
        const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            this.filter.init = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.end = lastDayOfMonth.toISOString().split('T')[0];
        //this.filter.init = this.filter.end;

        this.mtdGetData();
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },

        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/imagen",
                    token: this.$store.getters.get__token,
                    params: {
                        init: this.filter.init,
                        end: this.filter.end,
                    },
                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(7);
                    //this.mtdSearchFilter();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    if (element.patient_name !== null) {
                        let name = element.patient_name.toString().toUpperCase();
                        let document = element.of_patient_document.toString();
                        return name.includes(query) || document.includes(query);
                    }
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            //console.log(this.cp.length);
        },

        mtdSearchFilter() {
            this.calculateData(7);
        },

        mtdGetStyle: function (option) {
            if (option == this.page) return "background: #900052";
            return "";
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return "color:white";
            return "";
        },
        returnHome() {
            this.$emit("mtdChangePage", "home");
        },

        /** paginación */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        showAlert(){
            this.$refs.SweetAlert.showWarning("Aun está en proceso");
        },
        generateWhatsAppLinks(patient, date, hour) {
            const currentDate = moment().utcOffset(-5).startOf('day');
            const appointmentDate = moment(date, "YYYY-MM-DD");
            const formattedDate = moment(date).format("DD/MM/YYYY");
            const diasFaltantes = appointmentDate.diff(currentDate, "days");
            const message = `Hola, ${patient}. Te saluda BM CLINICA DE OJOS, te recordamos que tienes una cita con nosotros el día ${formattedDate} a las ${hour}. Faltan ${diasFaltantes} días para tu cita.
          `;
            const encodedMessage = encodeURIComponent(message);
            const whatsappLink = `https://api.whatsapp.com/send?phone=${this.phone}&text=${encodedMessage}`;
            return whatsappLink;
        },

        generateWhatsAppLink(patient, date, hour, phone) {
            const currentDate = moment().utcOffset(-5).startOf('day');
            const appointmentDate = moment(date, "YYYY-MM-DD");
            const formattedDate = moment(date).format("DD/MM/YYYY");
            let diasFaltantes = appointmentDate.diff(currentDate, "days");

            const firstPartOfHour = hour.split(' ')[0];

            let message;
            if (currentDate.isSame(appointmentDate, 'day')) {
                message = `Hola, ${patient}. Te saluda BM CLINICA DE OJOS, te recordamos que tienes una cita con nosotros el día de hoy ${formattedDate} a las ${firstPartOfHour}.`;
            } else if (currentDate.isAfter(appointmentDate, 'day')) {
                diasFaltantes = Math.abs(diasFaltantes);
                message = `Hola, ${patient}. Te saluda BM CLINICA DE OJOS, te recordamos que ha pasado ${diasFaltantes} días desde tu última cita el día ${formattedDate}. Te esperamos pronto.`;
            } else {
                message = `Hola, ${patient}. Te saluda BM CLINICA DE OJOS, te recordamos que tienes una cita con nosotros el día ${formattedDate} a las ${firstPartOfHour}. Faltan ${diasFaltantes} días para tu cita.`;
            }

            const encodedMessage = encodeURIComponent(message);
            const formattedPhone = '+51' + phone;
            const whatsappLink = `https://api.whatsapp.com/send?phone=${formattedPhone}&text=${encodedMessage}`;
            return whatsappLink;
        },
        openmodalexam: function (exam,item) {
            let option = "";
            switch (exam) {
                case "Campo Visual":
                    this.dataExamen = JSON.parse(item.campo_visual);
                    option = "visual_field";
                    break;
                case "Oct":
                    this.dataExamen = JSON.parse(item.oct);
                    option = "oct";
                    break;
                case "Oct Macula":
                    this.dataExamen = JSON.parse(item.oct_macula);
                    option = "oct_macula";
                    break;
                case "Paquimetria":
                    this.dataExamen = JSON.parse(item.paquimetria);
                    option = "pachymetry";
                    break;
                case "Topografia":
                    this.dataExamen = JSON.parse(item.topografia);
                    option = "topography";
                    break;
                case "Microscopia":
                    this.dataExamen = JSON.parse(item.microscopia);
                    option = "specular_microscopy";
                    break;
                case "Ecografia Ocular":
                    this.dataExamen = JSON.parse(item.ecografia);
                    option = "eye_ultrasound";
                    break;
                case "Biometria":
                    this.dataExamen = JSON.parse(item.biometria);
                    option = "biometrics";
                    break;
                case "Angiografia":
                    this.dataExamen = JSON.parse(item.angiografia);
                    option = "retinal_angiography";
                    break;
                default:
                    break;
            }
            this.mtdShowData();
            this.cabecera = {
                title: exam,
                patient: item.patient_name,
                option: option,
                of_reservation_id: item.of_reservation_id,
            }
            this.modal = {
                title: exam + " "  + item.patient_name,
                boo_modal: true, 
            }
        },
        mtdShowData:function(){
            //separar pdf de img
            this.dataExamen[0].patient.examen.value.forEach(elem => {
                const extension = elem.route.split('.').pop().toLowerCase();
                if (extension === 'pdf') {
                    this.dataPdf.push(elem);
                } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                    this.dataImg.push(elem);
                }

            });
        },
        mtdclosemodalexam: function () {
            this.modaldexam = {
                title: '',
                modal_form: false,
            }
        },
        closeCarrusel: function(){
            this.modal.title = '';
            this.modal.boo_modal = false;
        }

    },
};
</script>

<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}

.align {
    display: block;
    text-align: left;
}

.input-width {
    width: 130px;
}

.space {
    white-space: nowrap;
}
</style>

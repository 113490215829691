<template>
  <div>
    <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
      <div :style="mtdGetStyle('ayudadx')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('ayudadx')"><span :style="mtdGetStyleSpan('ayudadx')"
          class="btn w-100"><strong>AYUDA AL DX</strong></span>
      </div>
      <div :style="mtdGetStyle('consulta')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('consulta')"><span :style="mtdGetStyleSpan('consulta')"
          class="btn w-100"><strong>CONSULTAS</strong></span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'FooterEnfermeraComponent',
  props: {
    patient: Object,
    pageView: String,
  },
  data() {
    return {
      page: null
    }
  },
  created() {

  },
  methods: {
    mtdGetStyle: function (option) {
      if (option == this.page && this.pageView != 'home') return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan: function (option) {
      if (option == this.page && this.pageView != 'home') return 'color:white';
      return '';
    },
    mtdSendOption: function (page) {
      if(page === 'ayudadx'){
        if(Object.keys(this.patient).length !== 0){
          this.page = page;
          this.$emit('mtdChangePage', this.page);
        }
      }else{
        this.page = page;
        this.$emit('mtdChangePage', this.page);
      }
    }
  },
}
</script>
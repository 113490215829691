<template>
  <div>
    <div class="w-100 h-100">
      <Nav @rolname="rolname" />
    </div>
    <div class="content h-100">
      <div class="container">
        <div class="row" id="div_conte2">
          <div id="div_conte" class="col-md-8 mx-auto">
            <div class="bg-secondary text-white bg-opacity-10">
              <div class="text-center py-4 px-5" id="scroll-div">
                <div class="row">
                    <h4 class="text-dark fw-bold">SELECCIONA UN ROL</h4>
                </div>
                <div id="contenedorPadre" class="row d-flex">
                  <div v-for="(item, index) in dataRoles" :key="index" class="col-md-4">
                    <div class="divInterior" v-if="item.role.id == 1 || item.role.id == 2 || item.role.id == 3 || item.role.id == 4 || item.role.id == 5 || item.role.id == 6 || item.role.id == 10 
                    || item.role.id == 11 || item.role.id == 12 || item.role.id == 13 || item.role.id == 14">
                      <button class="div-rol col m-2 p-2 text-white fw-bold"
                        @click="mtdSelectRol(item.role.id, item.role.name)"
                      >
                        {{ item.role.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from '../../components/Oftalmologia/Nav.vue'

export default {
  name: "v-rol",
  components: {
    Nav,
  },
  data () {
    return {
      rolname: '',
    }
  },
  props: {
    dataRoles: [],
  },
  methods: {
    mtdSelectRol: function (id, name_rol) {
      this.$emit("mtdSelectRol", id, name_rol);
      this.rolname = name_rol;
    }
  },
};
</script>
<style>
#contenedorPadre {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.divInterior {
    flex: 1;
    margin: 3px;
    display: flex; /* Aplicamos flexbox dentro de cada divInterior */
    flex-direction: column; /* Orientamos los elementos hijos en columna */
}

.divInterior button {
    flex: 1; /* Los botones ocuparán todo el espacio disponible en la dirección principal (vertical) */
    margin: 5px; /* Añadimos margen entre los botones */
}

</style>

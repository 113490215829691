<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
    <div class="row mx-0 px-0">
        <div>
            <ul class="nav nav-tabs " id="myTab" role="tablist">
                <li v-for="(item, index) in ordendecompra" :key="index" class="nav-item px-1 py-0 w-auto" role="presentation">
                    <button @click="selectOc(index)" :class="['item-button', 'nav-link', { active: index === posOc }]" :id="'tab-' + index" type="button">
                        {{ item.cabecera.proveedor_name}}
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="" v-if="tablaDetail.length > 0">
                <div v-for="(item, index) in tablaDetail" :key="index">
                    <div class=" row mx-0 px-0 modal-title mt-4" id="exampleModalLabel">
                        <div class=" row mx-0 px-0 col-xl-12">
                            <div class="col-xl-1 d-none d-xl-inline ">
                                <div class=" text-center   h-100  d-flex align-items-center justify-content-center">
                                    <i class="fas fa-shopping-cart text-white bg-main px-3 py-3 rounded-circle "></i>
                                </div>
                            </div>
                            <div class="bg-white row col-xl-4 px-1 mx-0  align-items-center ">
                                <div class="col-xl-auto ">
                                    <label for="observaciones" class="col-form-label">OBSERVACIONES:</label>
                                </div>
                                <div class="col-xl-7 px-0">
                                    <input v-model="item.cabecera.observation" class="form-control  form-control-sm" />
                                </div>
                            </div>
                            <div class="bg-white row col-xl-4  px-1 mx-0 align-items-center ">
                                <div class="col-xl-auto ">
                                    <label for="observaciones" class="col-form-label ">COSTO ENVIO S/:</label>
                                </div>
                                <div class="col-xl-6 px-0">
                                    <input v-model="item.cabecera.send" class=" form-control form-control-sm " />
                                </div>
                            </div>
                           
                            <div class="bg-white row col-xl-3  px-1 mx-0 align-items-center ">
                                <div class="col-xl-auto ">
                                    <label for="observaciones" class="col-form-label"> MONEDA:</label>
                                </div>
                                <div class="col-xl-6 px-0">
                                    <select v-model=" item.cabecera.moneda " class="form-control" aria-label="Default select example">
                                        <option value="SOLES">Soles</option>
                                        <option value="DOLARES"> Dolares </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" mt-4">
                        <div class=" table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main text-xs">
                                    <tr class="text-white text-center" style="vertical-align: middle">
                                        <th scope="col">N°</th>
                                        <th scope="col">GRAVADO/ NO GRAVADO</th>
                                        <th scope="col">MARCA</th>
                                        <th scope="col">NOMBRE</th>
                                        <th scope="col">MOLECULA / LENTE</th>
                                        <th scope="col">COMERCIAL</th>
                                        <th scope="col">PRESENTACIÓN</th>
                                        <th scope="col">CONCENTRACION</th>
                                        <th scope="col">MODELO</th>
                                        <th scope="col">SERIE</th>
                                        <th scope="col" colspan="1">CANTIDAD</th>
                                        <th scope="col" colspan="1">UNIDAD</th>
                                        <th scope="col" colspan="1">PRECIO SIN S/IGV</th>
                                        <th scope="col" colspan="1">PREC. UNIT. SIN S/IGV</th>
                                        <th scope="col" colspan="1">SUBTOTAL</th>
                                        <th scope="col" colspan="1" class="align-middle">DESCUENTO%</th>
                                        <th scope="col" colspan="1" class="align-middle">S/SUBTOTAL CON DESC</th>
                                        <th scope="col" colspan="1">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xs align-middle">
                                    <tr class="tbody-table1 text-center" v-for="(carro, ind) in item.detalle" :key="ind">
                                        <td>
                                            {{ ind + 1 }}
                                        </td>
                                        <td v-if="carro.cargado_state === null">
                                            <div class="form-check form-switch">
                                                <input v-model="carro.grabado" class="form-check-input" @change="mtdChangeState(index, ind)" type="checkbox" />
                                                {{ carro.cargado == 1 ? "GRAVADO" : "NO GRAVADO" }}
                                            </div>
                                        </td>
                                        <td v-else-if="carro.cargado === 1">GRAVADO</td>
                                        <td v-else>NO GRAVADO</td>
                                        <td>
                                            {{ carro.brand }}
                                        </td>
                                        <td>
                                            {{ carro.name }}
                                        </td>
                                        <td>
                                            {{ carro.molecule }}
                                        </td>
                                        <td>
                                            {{ carro.commercial ==null ? '-' : carro.commercial  }}
                                        </td>

                                        <td>
                                            {{ carro.presentation == null ? '-' : carro.presentation}}
                                        </td>
                                        <td>
                                            {{ carro.concentration == null ? '-' : carro.concentration}}
                                        </td>
                                        <td>
                                            {{ carro.modelo == null ? '-' : carro.modelo }}
                                        </td>
                                        <td>
                                            {{ carro.serie == null ? '-' : carro.serie }}
                                        </td>
                                        <td v-if="posEditQuantity1 != ind" @dblclick="mtdEditQuantity(index, ind, 'cantidad')">
                                            {{ carro.quantity }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" v-model="carro.editQuantity" @keyup.enter="
                                            mtdOutFocusEditPrice(index, ind, 'cantidad')" @blur="mtdOutFocusEditPrice(index, ind, 'cantidad')" class="form-control form-control-sm input-cantidad text-center " placeholder="CANTIDAD" />
                                        </td>
                                        <td>
                                            <select class="form-control form-control-sm " aria-label=".form-select-sm example" v-model="carro.unit" @change="mtdSelectUnit(index, ind)">
                                                <option v-for="(un, pos) in carro.units" :key="pos" :value="un.id">
                                                    {{ un.unit.name }}
                                                </option>
                                            </select>
                                        </td>
                                        <td v-if="posEditQuantity != ind" @dblclick="mtdEditQuantity(index, ind, 'Precio')">
                                            {{ carro.precio_purchase }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="PRECIO UNITARIO" v-model="carro.editPrecio" @keyup.enter="
                                            mtdOutFocusEditPrice(index, ind, 'Precio')
                                            " @blur="mtdOutFocusEditPrice(index, ind, 'Precio')" />
                                        </td>
                                        <td>
                                            {{ carro.precio_purchase}}
                                        </td>
                                        <td>
                                            {{ calculateSubtotal(index, ind)}}
                                        </td>
                                        <td v-if="posEditDiscount != ind" @dblclick="mtdEditQuantity(index, ind, 'Descuento')">
                                            {{ carro.discount !== null ? carro.discount + '%' : '0%' }}
                                        </td>
                                        <td v-else>
                                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" style="text-align: center" placeholder="Descuento" v-model="carro.editDiscount" @keydown="filterKey" @keyup.enter="
                                            mtdOutFocusEditPrice(index, ind, 'Descuento')
                                            " @blur="mtdOutFocusEditPrice(index, ind, 'Descuento')" />
                                        </td>
                                        <td>
                                            {{
                                            carro.price_discount = carro.editDiscount
                                                ? (carro.price_purchase - (carro.editDiscount / 100 * carro.price_purchase)).toFixed(2)
                                                : '0.00'
                                            }}
                                        </td>
                                        <td class="div-pointer">
                                            <a id="btn-remove " class="a-danger2" @click="mtdBeforeDelete(index, ind)">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex mt-3 justify-content-end">
                            <div class="px-2 py-1 bg-white">
                                NO GRAVADA S/: {{ mtdValornograbado() }}
                            </div>
                            <div class="px-2 py-1 bg-white">
                                VALOR VENTA S/: {{ mtdValor() }}
                            </div>
                            <div class="px-2 py-1 bg-white">IGV S/: {{ mtdGetIgv() }}</div>
                            <div class="px-2 py-1 mx-1 bg-white">
                                TOTAL C/ IGV: {{ mtdGetTotal() }}
                            </div>
                            <button @click="mtdInsertData()" type="button" class="px-3 border-0 text-white bg-main">
                                ENVIAR
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>

    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-modals-cmodalviewcarritoordencompra",
    components: {
        CModal,
    },
    data() {
        return {
            posOc: 0,
            tablaDetail: [],
            posEditQuantity1: null,
            posEditQuantity: null,
            posEditDiscount: null,
            selectOcExecuted: false
        };
    },
    watch: {
        // ordendecompra: {
        //     handler: function (newValue, oldValue) {
        //         if (newValue.length > 0 && !this.selectOcExecuted) {
        //             this.selectOc(0);
        //             this.selectOcExecuted = true;
        //         }
        //     },
        //     deep: true
        // }
        numcarrito: {
            handler: function (newValue, oldValue) {
                if (newValue > 0) {
                    this.selectOc(newValue - 1);

                }
            },
            deep: true,
            immediate: true,
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        ordendecompra: {
            type: Array,
            default: () => [],
        },
        numcarrito: {
            type: Number,
            default: 0
        }
    },

    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.$emit("closeModalCarrito");
           
        },
        mtdInsertData: function () {
            const valor = this.mtdValor();
            if(valor!== '0.00'){
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/oc/store",
                    token: this.$store.getters.get__token,
                    params: {
                        carrito: this.ordendecompra,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        for (let index = 0; index < response.data.length; index++) {
                            const element = response.data[index];
                            window.open(
                                this.$store.getters.get__url +
                                "/Logistica/viewpdf/oc/" +
                                element,
                                "_blank"
                            );
                        }
                        Swal.fire({
                            title: "Registro Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.selectOcExecuted = false
                        this.$emit("registro-exitoso");
                        this.mtdClose();
                        this.tablaDetail = [];
                    } else {
                        Swal.fire({
                            title: "Registro Incorrecto!",
                            text: "Error!",
                            icon: "error",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {});
            }
                else{
                    Swal.fire({
                    icon: "warning",
                    title: "El valor no puede ser 0.00",
                    showConfirmButton: false,
                    timer: 1500,
                });
                }                                                                          
        },
        selectOc: function (pos) {
            if (pos >= 0 && pos < this.ordendecompra.length) {
                this.tablaDetail = [];
                this.posOc = pos;
                this.tablaDetail.push(this.ordendecompra[this.posOc]);
            }
        },
        mtdEditQuantity: function (index, pos, edit) {
            if (edit === "Precio") {
                this.posEditQuantity = pos;
                this.posEditQuantity1 = -1;
                // Inicializa editPrecio con el valor actual
                this.tablaDetail[index].detalle[pos].editPrecio =
                    this.tablaDetail[index].detalle[pos].precio_purchase;
            } else if (edit === "cantidad") {
                this.posEditQuantity1 = pos;
                this.posEditQuantity = -1;
                // Inicializa editQuantity con el valor actual
                this.tablaDetail[index].detalle[pos].editQuantity =
                    this.tablaDetail[index].detalle[pos].quantity;
            } else if (edit === 'Descuento') {
                this.posEditDiscount = pos;
                this.posEditQuantity1 = -1;
                this.tablaDetail[index].detalle[pos].editDiscount =
                    this.tablaDetail[index].detalle[pos].discount;
            }
        },
        mtdChangeState: function (index, ind) {
            this.tablaDetail[index].detalle[ind].cargado = this.tablaDetail[index]
                .detalle[ind].grabado ?
                1 :
                0;
        },
        mtdOutFocusEditPrice(ind, index, field) {
            if (index >= 0 && index < this.tablaDetail[ind].detalle.length) {
                if (field === "Precio") {
                    const newPrecio = parseFloat(
                        this.tablaDetail[ind].detalle[index].editPrecio
                    );
                    if (!isNaN(newPrecio)) {
                        this.tablaDetail[ind].detalle[index].precio_purchase =
                            newPrecio.toFixed(2);
                    }
                    this.mtdSelectUnit(ind, index);
                } else if (field === "cantidad") {
                    const newQuantity = parseInt(
                        this.tablaDetail[ind].detalle[index].editQuantity
                    );
                    if (!isNaN(newQuantity)) {
                        this.tablaDetail[ind].detalle[index].quantity = newQuantity;
                    }
                    this.mtdSelectUnit(ind, index);
                } else if (field === 'Descuento') {
                    const newDiscount = parseInt(this.tablaDetail[ind].detalle[index].editDiscount);
                    if (!isNaN(newDiscount)) {
                        this.tablaDetail[ind].detalle[index].discount = newDiscount.toFixed(2);
                    }
                }
                this.calculateSubtotal(ind, index);
                this.posEditDiscount = -1;
                this.posEditQuantity = -1;
                this.posEditQuantity1 = -1;
            }
        },
        mtdSelectUnit: function (index, pos) {
            let cantidadUnidades = 1;
            this.tablaDetail[index].detalle[pos].units.forEach((element) => {
                if (element.id == this.tablaDetail[index].detalle[pos].unit) {
                    cantidadUnidades = element.unit.quantity;
                }
            });
            this.tablaDetail[index].detalle[pos].precio_unitario = parseFloat(
                this.tablaDetail[index].detalle[pos].precio_purchase / cantidadUnidades
            ).toFixed(2);
        },
        calculateSubtotal(ind, index) {
            const precioUnitario =
                parseFloat(this.tablaDetail[ind].detalle[index].precio_purchase) || 0;
            const cantidad =
                parseInt(this.tablaDetail[ind].detalle[index].quantity) || 0;
            const subtotal = precioUnitario * cantidad;
            this.tablaDetail[ind].detalle[index].price_purchase = subtotal.toFixed(2);
            return subtotal.toFixed(2);
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdGetTotal: function () {
            if (this.ordendecompra.length > 0) {
                let valor = 0;
                let valornogravada = 0;
                this.ordendecompra[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 0) {
                        valornogravada += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                    if (element.cargado == 1) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                let igv = valor * 0.18;
                let total = 
                    parseFloat(valor) + parseFloat(igv) + parseFloat(valornogravada) ;
                
                return parseFloat(total).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValornograbado: function () {
            if (this.ordendecompra.length > 0) {
                let valor = 0;
                this.ordendecompra[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 0) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdValor: function () {
            if (this.ordendecompra.length > 0) {
                let valor = 0;
                this.ordendecompra[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },

        mtdGetIgv: function () {
            if (this.ordendecompra.length > 0) {
                let valor = 0;
                this.ordendecompra[this.posOc].detalle.forEach((element) => {
                    if (element.cargado == 1) {
                        valor += parseFloat(element.discount !== null ? element.price_discount : element.price_purchase);
                    }
                });
                let igv = parseFloat(valor * 0.18);
                return parseFloat(igv).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdBeforeDelete: function (index, ind) {
            
            if (this.tablaDetail[index].detalle.length === 1) {
                this.selectOcExecuted = false
                if (this.ordendecompra.length === 1) {
                    this.ordendecompra.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    this.mtdClose();
                } else {
                    this.ordendecompra.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    if (this.posOc - 1 < 0) {
                        this.selectOc(this.posOc)
                    } else {
                        this.selectOc(this.posOc - 1)
                    }
                }
            } else {
                this.tablaDetail[index].detalle.splice(ind, 1);
               
            }
        },

    },

};
</script>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'lg'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
      :scrollable="true"
    >
      <template #header>
        <div class="d-flex justify-content-between align-items-center header-container">
          <div style="font-size: 18px; font-weight: bold">{{ title }}: <span style="font-size: 14px">{{ item.name }}</span></div>
          <button @click="mtdClose" class="btn text-white close-button float-right ml-auto">
            <i class="fas fa-times" style="font-size: 12px;"></i>
          </button>
        </div>
      </template>
  
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text col-md-3" id="document_type">VIA</span>
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Via"
                v-model="dataRe.via"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text col-md-3" id="document_type">DOSIS</span>
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Dosis"
                v-model="dataRe.dosis"
              />
            </div>
          </div>
          <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text col-md-3" id="document_type">ORDEN</span>
              <input
                type="text"
                class="form-control form-control-sm"
                placeholder="Dosis"
                v-model="dataRe.orden"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text col-md-4" id="document_type">FRECUENCIA</span>
              <input
                type="number"
                class="form-control form-control-sm"
                placeholder="Frecuencia"
                v-model="dataRe.frecuencia"
                step="0.1"
                @input="validateInput('frecuencia')"
              />
            </div>
          </div>
          <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
            <div class="input-group input-group-sm mb-3">
              <span class="input-group-text col-md-4" id="document_type">DURACIÓN</span>
              <input
                type="number"
                class="form-control form-control-sm"
                placeholder="Duración"
                v-model="dataRe.duracion"
                step="0.1"
                @input="validateInput('duracion')"
              />
            </div>
          </div>
        </div>
      </div>
  
      <template #footer>
        <button class="btn btn-success btn-sm" @click="registrarReci">
          <i class="fas fa-save" />&nbsp; Agregar
        </button>
      </template>
      <SweetAlert :swal="swal" ref="SweetAlert"/>
    </CModal>
</template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import { mapActions } from "vuex";
  export default {
    name: "c-vigilancia-modal-control",
    components: {
      CModal,
      SweetAlert,
    },
    data() {
      return {
        swal: null,
        tabla: [],
        dataRe: {
          via: "",
          dosis: "",
          orden: "",
          frecuencia: null,
          duracion: null,
        },
      };
    },
    created() {},
    computed: {
      cpData() {
        return this.item.map(element => element);
      },
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: [],
    },
    methods: {
      ...mapActions(["get", "post"]),
      validateNumberInput(event) {
        const regex = /^[0-9]*\.?[0-9]*$/;
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      },
  
      mtdClose() {
        this.dataRe = {
          via: "",
          dosis: "",
          orden: "",
          frecuencia: null,
          duracion: null,
        };
        this.$emit("mtdCloseModalDeta");
      },
  
      validateInput(field) {
        if (this.dataRe[field] < 0) {
          this.dataRe[field] = 0;
        }
      },
  
      registrarReci() {
        /*
        if (this.dataRe.frecuencia === null || this.dataRe.frecuencia === "") {
          this.dataRe.frecuencia = 0;
        }
        if (this.dataRe.duracion === null || this.dataRe.duracion === "") {
          this.dataRe.duracion = 0;
        }
        */
  
        if (this.dataRe.via.trim() == "" || this.dataRe.dosis.trim() == "" || this.dataRe.orden.trim() == "") {
        /*
          Swal.fire({
            text: "Llene todos los campos",
            icon: "warning",
            confirmButtonColor: "#900052",
          });
          return;
          */
          this.$refs.SweetAlert.showWarning("Llene todos los campos");
          return;
        }
  
        if (
          this.dataRe.frecuencia === null ||
          this.dataRe.duracion === null ||
          this.dataRe.frecuencia === "" ||
          this.dataRe.duracion === "" ||
          this.dataRe.frecuencia <= 0 ||
          this.dataRe.duracion <= 0
        ) {
            /*
          Swal.fire({
            text:
              "La frecuencia y la duración deben ser mayores que 0",
            icon: "warning",
            confirmButtonColor: "#900052",
          });
          return;
          */
          this.$refs.SweetAlert.showWarning("La frecuencia y la duración deben ser mayores que 0");
          return;
        }
  
        const newItem = {
          ...this.item,
          ...this.dataRe,
        };
  
        this.$emit("registrarReci", newItem);
        this.mtdClose();
      },
    },
  };
  </script>
  <style scoped>
  #content-timeline {
    border: 1px solid #aaa;
    height: 100px;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  #content-timeline::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  #content-timeline::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-button:increment,
  #content-timeline::-webkit-scrollbar-button {
    display: none;
  }
  
  #content-timeline::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  #content-timeline::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .header-main {
    background-color: #900052;
  }
  
  @media (max-width: 992px) {
    .flex-row {
      flex-direction: column !important;
      align-items: center;
      padding-top: 20px !important;
    }
  
    #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
    }
  }
  
  .text-doctor {
    font-weight: bold;
    font-size: 14px;
    color: #900052;
  }
  .selected {
    background-color: yellow;
  }
  
  .table-th-state {
    width: 20% !important;
  }
  
  #tb-lote th {
    font-size: 9.5px;
  }
  
  #tb-lote td {
    font-size: 11px;
  }
  
  .error-message {
    color: red;
    font-size: 9px;
  }
  
  /* X a la derecha*/
  .header-container {
    width: 100%;
    margin-right: 10px;
  }
  
  .close-button {
    border: none;
    background-color: transparent;
  }
  
  .modal-body {
    margin-right: 20px;
  }
  
  @media (max-width: 767px) {
    #table-body {
      font-size: 10px !important;
    }
  }
  </style>
  
<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'lg'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 ">
          <div id="table-detalle" >
            <table id="table-body" class="table table-bordered table-responsive">
                <caption class="py-0 my-0"></caption>
              <thead>
                <tr class="text-white border-white text-center text-xs bg-main">
                  <th scope="col">FECHA RESERVADA</th>
                  <th scope="col">DESCRIPCIÓN</th>
                  <th scope="col">ESTADO</th>
                  <th scope="col">ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                <tr  class="text-xs">
                  <td class="align-left align-middle">19/05/2021 11:00am a 11:20am</td>
                  <td class="align-left align-middle">CONSULTA</td>
                  <td class="align-left align-middle">PAGADO</td>
                  <td class="align-left align-middle">
                    <div class="badge text-bg-success p-3 mb-2">
                        <strong>REPROGRAMAR</strong>
                    </div>
                    <div class="badge text-bg-danger p-3">
                        <strong>ELIMINAR</strong>
                    </div>
                  </td>
                </tr>
                <tr  class="text-xs">
                  <td class="align-left align-middle">19/05/2021 11:00am a 11:20am</td>
                  <td class="align-left align-middle">CONSULTA</td>
                  <td class="align-left align-middle">NO PAGADO</td>
                  <td class="align-left align-middle">
                    <div class="badge text-bg-success p-2 mb-2">
                        <strong>REPROGRAMAR</strong>
                    </div>
                    <div class="badge text-bg-danger p-2">
                        <strong>ELIMINAR</strong>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template #footer>
        <button @click="mtdClose"  class="btn btn-danger btn-sm">
          <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
      </template>
    </CModal>
  </template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";
  import { mapActions } from "vuex";

  export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
      CModal,
    },
    data() {
      return {
       
      };
    },
    props: {
      title: String,
      boo_modal: Boolean,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdClose() {
        this.$emit("closeModalReserva");
      },
    },
   
  };
  </script>
  

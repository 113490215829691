<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2">
        <div class="row mx-0">
            <div class="mb-3 col-xl-4 col-12">
                <label for="exampleFormControlInput1" class="form-label">Monto Total:
                </label>
                <input  type="text" v-model="item.sale_total" class="form-control form-control-sm" placeholder="Monto Total" disabled />
            </div>
            <div class="mb-3 col-xl-4 col-12">
                <label for="exampleFormControlInput1" class="form-label">Monto Pagado:
                </label>
                <input  type="text" v-model="item.sale_amount" class="form-control form-control-sm" placeholder="Monto Pagado" disabled />
            </div>
            <div class="mb-3 col-xl-4 col-12">
                <label for="exampleFormControlInput1" class="form-label">Monto Pendiente:
                </label>
                <input  type="text" class="form-control form-control-sm" v-model="item.pending" placeholder="Monto Pendiente" disabled />
            </div>
            
        </div>
        <div class="col-md-12 px-2" id="content-timelinetable">
            <table id="tb-anular" class="table table-bordered">
                <caption class="my-0 py-0"></caption>
                <thead class="bg-main text-xs" >
                    <tr class="text-white">
                        <th scope="col" class="align-middle">N°</th>
                        <th scope="col" class="align-middle">MONTO PAGADO</th>
                        <th scope="col" class="align-middle">FECHA</th>
                        <th scope="col" class="align-middle">USUARIO</th>
                        <th scope="col" class="align-middle">DOCUMENTO</th>
                        <th scope="col" class="align-middle">PDF</th>
                    </tr>
                </thead>
                <tbody class="border-main text-xs" >
                    <tr class="tbody-table" v-for="(pago, index) in pagos" :key="index" v-if="(pagos.length > 1 &&
                        pago.document === 'TICKET')
                     || (pagos.length === 1 && pago.document !== 'TICKET') || (pagos.length === 1 && pago.document == 'TICKET')">
                        <td class="align-middle">
                            {{ index + 1 }}
                        </td>
                        <td class="align-middle">
                            {{ pago.amount}}
                        </td>
                        <td class="align-middle">
                           {{pago.date}}
                        </td>
                        <td class="align-middle">
                            {{pago.bead.user.name}}
                        </td>
                        <td class="align-middle">
                            {{pago.serie}}-{{pago.number }}
                        </td>
                        <td class="align-middle">
                            <a 
                            @click="pdf(pago)"
                            class=" btn btn-info btn-sm mx-1 text-white">
                            <i class="far fa-file-pdf"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <!-- <button class="btn btn-success btn-sm">
            <i class='fas fa-save' />&nbsp; Registrar
        </button>
        &nbsp; -->
    </template>
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-oftalmologia-modal-historialpagos",
    components: {
        CModal,
    },
    data() {
        return {
            montoPagado:0
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item:Object,
        pagos:Array
    },
    computed: {
        cpData() {
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        pdf(item){
            if(item.document=='TICKET'){ 
                              window.open(this.$store.getters.get__url +"/Admision/ticket/"+item.id, "_blank");
            }else{
             let res = JSON.parse(item.sunat);
            window.open(res.json.enlace_del_pdf_ticket, "_blank");
        }
        },
        mtdClose() {
            this.$emit("closeModalHistorial");
        },
    },
};
</script>

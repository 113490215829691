<template>
<div class="container">
    <div id="btn-return" class="d-flex justify-content-end pt-1 mt-3">
        <div class="btn-home" role="group">
            <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                <i class="fas fa-recycle"></i> Cambiar de Sede
            </button>
        </div>
    </div>
    <div id="btn_home" class="c-app align-items-center">
        <div class="d-grid gap-2 mx-auto text-center col-md-12">

            <div id="content-timelinetable" class="mb-2 mt-4">
                <!--
                <table id="table-historia" class="table table-bordered border-main mb-0">
                    <caption></caption>
                    <thead class="align">
                        <tr class="text-white border-white text-center bg-main text-xs">
                            <th scope="col" v-for="(columnHeader, columnIndex) in Object.keys(data).slice(0, 2)" :key="columnIndex">
                                {{ columnHeader }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-xs align">
                        <tr>
                            <td v-for="(header, headerIndex) in Object.keys(data).slice(0, 2)" :key="headerIndex">
                                <draggable :list="data[header]" :group="{ name: 'cells', put: true }" class="draggable" @change="onItemMove">
                                    <div v-for="(item, index) in data[header]" :key="index" :class="{'highlighted': index === 0 }" class="extra-space-td">
                                        <span class="drag-handle" @dblclick="mtdSelectPatient(item)">
                                            <b v-if="item.status!==3"> <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                            <b v-else> <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }} (FO)</b>
                                        </span>
                                    </div>
                                </draggable>
                            </td>
                        </tr>
                        <tr class="text-white border-white text-center bg-main text-xs">
                            <th scope="col" v-for="(columnHeader, columnIndex) in Object.keys(data).slice(2)" :key="columnIndex + 2">
                                {{ columnHeader }}
                            </th>
                        </tr>
                        <tr>
                            <td v-for="(header, headerIndex) in Object.keys(data).slice(2)" :key="headerIndex + 2">
                                <draggable :list="data[header]" :group="{ name: 'cells', put: true }" class="draggable" @change="onItemMove">
                                    <div v-for="(item, index) in data[header]" :key="index + 2" :class="{'highlighted': index === 0 }" class="extra-space-td">
                                        <span class="drag-handle" @dblclick="mtdSelectPatient(item)">
                                            <b> <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                        </span>
                                    </div>
                                </draggable>
                            </td>
                        </tr>
                    </tbody>
                </table> -->
                <div class="row d-flex">
                    <div class="col-md-6 col-sm-12 px-0">
                        <div class="card h-100">
                            <div class="card-header bg-main text-white">Consultas</div>
                            <div class="card-body">
                                <ul class="draggable list-unstyled text-start">
                                    <li v-for="(item, index) in dataPatientConsulta" :key="index" :class="{'highlighted': index === 0 }" class="extra-space-td" @dblclick="mtdSelectPatient(item)">
                                        <span class="drag-handle">
                                            <b v-if="item.status !== 3">
                                                <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}
                                            </b>
                                            <!-- <b v-else>
                                                <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }} (FO)
                                            </b> -->
                                        </span>
                                    </li>
                                </ul>
                                <div v-if="dataPatientConsulta.length == 0">
                                    <span class="drag-handle">
                                    <b>No hay pacientes por atender</b>
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 px-0">
                        <div class="card h-100">
                            <div class="card-header bg-main text-white">Fondo de Ojos</div>
                            <div class="card-body">
                                <ul class="draggable list-unstyled text-start">
                                    <li v-for="(item, index) in dataPatientFondoOjos" :key="index" :class="{'highlighted': index === 0}" class="extra-space-td" @dblclick="mtdSelectPatient(item)">
                                        <span class="drag-handle">
                                            <b><i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>&nbsp;&nbsp;
                                            <button class="btn btn-sm btn-info text-white" v-if="item.remainingTime > 0">
                                                {{ Math.floor(item.remainingTime / 60) }} min {{ item.remainingTime % 60 }} seg
                                            </button>
                                            <button v-else-if="item.remainingTime == -1" class="btn btn-sm btn-success">Reingreso</button>
                                            <button v-else-if="item.remainingTime == 0" class="btn btn-sm btn-danger">Tiempo culminado</button>
                                        </span>
                                    </li>
                                </ul>
                                <div v-if="dataPatientFondoOjos.length == 0">
                                    <span class="drag-handle">
                                    <b>No hay pacientes por atender</b>
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex">
                    <div class="col-md-6 col-sm-12 px-0">
                        <div class="card h-100">
                            <div class="card-header bg-main text-white">Lectura de Resultados</div>
                            <div class="card-body">
                                <ul class="draggable list-unstyled text-start">
                                    <li v-for="(item, index) in dataPatientLectura" :key="index" :class="{'highlighted': index === 0 }" class="extra-space-td" @dblclick="mtdSelectPatient(item)">
                                        <span class="drag-handle">
                                            <b><i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                        </span>
                                    </li>
                                </ul>
                                <div v-if="dataPatientLectura.length == 0">
                                    <span class="drag-handle">
                                    <b>No hay pacientes por atender</b>
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 px-0">
                        <div class="card h-100">
                            <div class="card-header bg-main text-white">Control por Infeccion</div>
                            <div class="card-body">
                                <ul class="draggable list-unstyled text-start">
                                    <li v-for="(item, index) in dataPatientControl" :key="index" :class="{'highlighted': index === 0 }" class="extra-space-td" @dblclick="mtdSelectPatient(item)">
                                        <span class="drag-handle">
                                            <b><i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                        </span>
                                    </li>
                                </ul>
                                <div v-if="dataPatientControl.length == 0">
                                    <span class="drag-handle">
                                    <b>No hay pacientes por atender</b>
                                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="container principal">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="row">
                            <div class="col-lg-3 col-md-12 mb-5" v-for="(columnHeader, columnIndex) in Object.keys(dataPantalla)" :key="columnIndex">
                                <div class="card-section border rounded p-3">
                                    <div class="card-header-first rounded pb-2">
                                        <h3 v-if="columnHeader=='consulta'" class="card-header-title text-white py-2">CONSULTA NUEVA O REINGRESO</h3>
                                        <h3 v-if="columnHeader=='fondo'" class="card-header-title text-white py-2">FONDO DE OJOS</h3>
                                        <h3 v-if="columnHeader=='lectura'" class="card-header-title text-white py-2">LECT. DE RESULTADOS</h3>
                                        <h3 v-if="columnHeader=='control'" class="card-header-title text-white py-2">CONTROL POR INFECCIÓN</h3>
                                    </div>
                                    <div v-for="(header, headerIndex) in Object.keys(dataPantalla)" :key="headerIndex">
                                        <draggable v-if="columnHeader==header" :list="dataPantalla[header]" :group="{ name: 'cells', put: true }" class="draggable" @change="onItemMove">
                                            <div v-for="(item, index) in dataPantalla[header]" :key="index" :class="{'highlighted': index === 0 }" class="card-body pt-2 text-center">
                                                <span v-if="columnHeader=='consulta'" class="drag-handle card-text" @dblclick="mtdSelectPatient(item)">
                                                    <b v-if="item.status!==3"> <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                                    <b v-else> <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }} (FO)</b>
                                                </span>
                                                <span v-else class="drag-handle" @dblclick="mtdSelectPatient(item)">
                                                    <b> <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                                </span>
                                            </div>
                                        </draggable>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" @confirmed="Confirmed" />

    </div>
</div>
</template>

<script>
import draggable from 'vuedraggable';
import {
    mapActions
} from 'vuex'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from 'moment';

export default {
    name: 'HomeEnfermeraComponent',
    props: {},
    components: {
        draggable,
        SweetAlert
    },
    data() {
        return {
            user_id: null,
            swal: null,
            page: 0,
            stepPagination: [],
            msg: "",
            data: {},
            dataPatientConsulta: [],
            dataPatientFondoOjos: [],
            dataPatientLectura: [],
            dataPatientControl: [],
            dataPantalla: {},
            consultasLength: 0,
            fondoOjosLength: 0,
            resultadosLength: 0,
            controlLength: 0,
            statusNew: null,
            codesIa:[]
        };
    },
    mounted() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Doctor/PatientsHome",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                    }
                })
                .then((response) => {
                    /*this.data = {
                        "CONSULTA NUEVA O REINGRESO": response.data.consultas,
                        "FONDO DE OJOS": response.data.fondoOjos,
                        "LECT. DE RESULTADOS": response.data.lectura,
                        "CONTROL POR INFECCIÓN": response.data.control,
                    };*/
                    this.codesIa =  response.data.code;
                    this.dataPantalla = {
                        "consulta": response.data.consultas,
                        "fondo": response.data.fondoOjos,
                        "lectura": response.data.lectura,
                        "control": response.data.control,
                    };
                    /*this.consultasLength = response.data.consultas.length;
                    this.fondoOjosLength = response.data.fondoOjos.length;
                    this.resultadosLength = response.data.lectura.length;
                    this.controlLength = response.data.control.length;*/

                    //llenar los array de cada uno de los tipos: consulta, fondo de ojos
                    this.dataPatientConsulta = response.data.consultas;
                    this.dataPatientLectura = response.data.lectura;
                    this.dataPatientControl = response.data.control;

                    //seetear el array de fondo de ojos
                    this.dataPatientFondoOjos = response.data.fondoOjos.map((patient, index) => {
                        return {
                            ...patient,
                            remainingTime: patient.status == 2 ? this.contarTiempo(patient, index):-1,
                        };
                    });
                })
                .catch((errors) => {

                    console.log(errors);
                });
        },
        contarTiempo(patient, index) {
            const originalTime = moment.utc(patient.hour_change); //tiempo en que se edito
            const newTime = moment.utc(originalTime).add(patient.fo_wait_time, 'minutes');
            const diferenciaInicial = newTime.diff(moment.utc(), 'seconds');
            let diferenciaSegundos = diferenciaInicial;
            // Actualizar diferencia cada segundo
            const timer = setInterval(() => {
                if (diferenciaSegundos > 0) {
                    diferenciaSegundos = Math.max(0, diferenciaSegundos - 1);
                    // Actualizar la lista de pacientes para reflejar los cambios
                    this.$set(this.dataPatientFondoOjos[index], 'remainingTime', diferenciaSegundos);
                } else {
                    // Detener el temporizador cuando el tiempo restante sea 0
                    this.$set(this.dataPatientFondoOjos[index], 'remainingTime', 0);
                    clearInterval(timer);
                }
            }, 1000);

        },
        refreshDataHome: function () {
            this.data = {};
            this.mtdGetData();
        },
        onItemMove(evt) {
            let cuadrante1Length = 0;
            let cuadrante2Length = 0;
            let cuadrante3Length = 0;
            let cuadrante4Length = 0;
            if (evt.added) {
                let id_reservation = 0;
                let statusActual = evt.added.element.status;
                id_reservation = evt.added.element.id_reservation;
                cuadrante1Length = this.data['CONSULTA NUEVA O REINGRESO'].length;
                cuadrante2Length = this.data['FONDO DE OJOS'].length;
                cuadrante3Length = this.data['LECT. DE RESULTADOS'].length;
                cuadrante4Length = this.data['CONTROL POR INFECCIÓN'].length;

                if (this.controlLength < cuadrante4Length) {
                    this.statusNew = 4;
                } else {
                    if (this.consultasLength < cuadrante1Length) {
                        //verificar si es reingreso de fondo de ojos
                        this.statusNew = (statusActual == 2) ? 3 : 1;

                    } else {
                        if (this.fondoOjosLength < cuadrante2Length) {
                            this.statusNew = 2;
                        } else {
                            if (this.resultadosLength < cuadrante3Length) {
                                this.statusNew = 5;
                            } else {
                                this.statusNew = 0;
                            }
                        }
                    }
                }
                this.mtdChangeStatus(id_reservation);

            }

        },
        mtdChangeStatus: function (id_reservation) {
            if (this.statusNew !== 0) {
                this.post({
                        url: this.$store.getters.get__url + "/Doctor/changeStatus",
                        token: this.$store.getters.get__token,
                        params: {
                            id_reservation: id_reservation,
                            status: this.statusNew,
                        }
                    })
                    .then((response) => {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.statusNew = 0;
                        this.refreshDataHome();

                    })
                    .catch((errors) => {

                        console.log(errors);
                    });
            }
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        mtdSelectPatient: function (patient) {
            if (patient.status == 2) {
                this.patient = patient;
                this.$refs.SweetAlert.showConfirmSimple('Culminación de Examen de Fondo de ojos', '¿Desea culminar examen?', 'warning', 'Si, culminar', 3);
            } else {
                this.$emit('mtdSelectPatient', patient,this.codesIa);
            }

        },
        mtdSendOption: function (page) {
            this.page = page;
            this.$emit('mtdChangePage', this.page)
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        Confirmed: function (status) {

            this.post({
                    url: this.$store.getters.get__url + "/Doctor/changeStatus",
                    token: this.$store.getters.get__token,
                    params: {
                        id_reservation: this.patient.id_reservation,
                        status: status,
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.patient.status = response.data.status;
                    this.mtdGetData();
                    this.$emit('mtdSelectPatient',this.patient,this.codesIa);
                    //this.$emit('mtdShowOffCanva');

                })
                .catch((errors) => {

                    console.log(errors);
                });
        },
    },
}
</script>

<style>
.align {
    text-align: left;
}

.fas.fa-asterisk {
    font-size: xx-small;
    margin-right: 3px;
    color: #900052;
    font-size: 0.6em;
}

.no-border {
    border: none;
}

tr:last-child {
    border-bottom: 1px solid #900052;
}

#table-historia {
    width: 80%;
    margin: 0 auto;
}

.extra-space-td {
    padding: 7px !important;
}

#table-historia th {
    font-size: 15px;
}

#table-historia td {
    font-size: 12.5px;
}

.draggable {
    cursor: grab;
}

.highlighted {
    color: green;
}

.principal {
    margin-top: 50px;
    font-family: 'Roboto';
    /* background: linear-gradient(-20deg, #6b003d, #900052); */
}

.card-header-first {
    margin-top: -40px;
    height: 90px;
    background: linear-gradient(-70deg, #900052, #EA0028);
    box-shadow: 1px 5px 15px #a2a2a2;
}

/*efecto shadows para todas las tarjetas*/
.card-section {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

/*Para el efecto sombra al pasar por encima el mouse*/
.card-section:hover {
    box-shadow: 1px 1px 20px #900052;
}
</style>

<template>
  <div class="container-history mb-5">
    <div class="text-center mt-2">
      <div class="d-flex title-option">
        <div class="btn-home mt-1 mb-1 ms-auto">
          <button
            type="button"
            class="btn btn-outline-main"
            @click="initCallVoice()"
          >
            <i class="fas fa-headset"></i
            >{{ isListe ? " Parar Dictado" : " Iniciar dictado" }}
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-outline-main"
            @click="returnHome()"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="content-history" v-if="opt == 1">
        <div class="row text-cent mt-2">
          <div class="col-xl-12 mb-2">
            <div
              class="row d-flex justify-content-start align-items-center px-3"
            >
              <div class="col-xl-auto col-md-2 col-sm-2 ps-0 mt-2">
                <div class="mt-1 mb-1 ms-auto btn-success">
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-main w-100"
                    @click="mtdBackPage()"
                  >
                    <i class="fas fa-arrow-left"></i> Atrás
                  </button>
                </div>
              </div>
              <div
                class="col-xl-4 col-md-10 col-sm-10 rounded-3 px-0 bg-blue mt-2"
              >
                <div class="row mx-0">
                  <div
                    class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex"
                  >
                    <span class="align-middle col-sm-4 h-100 px-2 text-start"
                      >Nombre y Apellido:</span
                    >
                    <span
                      class="align-middle col-sm-8 h-100 px-0 text-start"
                      v-if="patient"
                      >{{ patient.name }}</span
                    >
                    <span
                      class="align-middle col-sm-8 h-100 px-0 text-start"
                      v-else
                    ></span>
                  </div>
                </div>
              </div>
              <div class="col-xl-1 col-md-2 col-sm-4 mt-2">
                <div id="div-te" class="form-group align-left d-flex">
                  <label for="te" class="color-main fw-bold align-self-center"
                    >T.E.</label
                  >
                  <div class="flex-grow-1 ms-2">
                    <input
                      ref="tiempoEnfermedad"
                      v-if="cpData[0]"
                      type="text"
                      v-model="cpData[0].te"
                      :readonly="
                        (patient.status == 3 &&
                          cpData[0].of_reservation_id !==
                            patient.id_reservation) ||
                        (patient.status == 1 && cpData[0].id !== null)
                      "
                      class="form-control form-control-input form-control-sm"
                      id="te"
                    />
                  </div>
                </div>
              </div>
              <div class="col-xl-2 col-md-3 btn-home mt-1">
                <button
                  @click="optaccion('2')"
                  type="button"
                  class="btn btn-outline-light w-100 btn-sm"
                >
                  <strong>OPTOMETRIA</strong>
                </button>
              </div>
              <div class="col-xl-2 col-md-3 btn-home mt-1" v-if="cpData[0]">
                <button
                  v-if="
                    patient.status !== 3 && cpData[0].of_reservation_id == null
                  "
                  @click="referPatient()"
                  :disabled="!patient.name"
                  type="button"
                  class="btn btn-outline-light w-100 btn-sm"
                >
                  <strong>FONDO DE OJOS</strong>
                </button>
              </div>
              <div class="col-xl-2 col-md-4 pe-0 mt-1">
                <div class="row d-flex justify-content-end">
                  <div class="col-12 px-0">
                    <nav
                      aria-label="Page navigation example"
                      v-if="cpData.length > 0"
                      class="pagination-container"
                    >
                      <ul
                        class="pagination pagination-xs justify-content-end"
                        style="
                          margin-bottom: 0;
                          margin-top: 0.1rem;
                          padding-left: 40px;
                        "
                      >
                        <li class="page-item">
                          <a
                            style="cursor: pointer"
                            href="javascript:void(0)"
                            @click="backPage"
                            class="page-link"
                            >«</a
                          >
                        </li>
                        <template v-if="stepPagination.length > 0">
                          <li class="page-item">
                            <a
                              class="page-link active"
                              href="javascript:void(0)"
                              >{{ stepPagination[selectPage - 1].date }}</a
                            >
                          </li>
                        </template>
                        <!-- <li class="page-item" v-if="selectPage < stepPagination.length - 4">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li class="page-item" v-if="selectPage < stepPagination.length - 3">
                                            <a class="page-link" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">
                                                {{ stepPagination.length }}
                                            </a>
                                        </li> -->
                        <li class="page-item">
                          <a
                            style="cursor: pointer"
                            class="page-link"
                            @click="nextPage"
                            >»</a
                          >
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 z-index-button" v-if="viewMedico === 0">
            <div class="py-1 d-flex justify-content-start">
              <button
                type="button"
                class="btn text-white btn-sm bg-main text-xs"
                @click="limpiarhistorial"
              >
                Vaciar Historial
              </button>
            </div>
          </div>
        </div>
        <div
          class="row text-cent mt-2"
          v-for="(item1, index) in cpData"
          :key="index"
        >
          <div class="col-xl-6 col-lg-12 text-xs">
            <div v-if="patient.status == 3 || item1.id !== null">
              <div
                class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
                @click="toggleDropdown(7)"
              >
                <h6 class="fw-bold color-main d-inline-block">Fondo De Ojos</h6>
                <img
                  src="@/assets/helpers/circle-chevron-down-solid.svg"
                  alt=""
                  class="dropdown-icon-content d-inline-block float-end"
                  :class="[titleDropdown[7].state && 'active']"
                />
              </div>
              <div class="panel-border mb-0 pt-1" v-if="titleDropdown[7].state">
                <div class="row px-3">
                  <div class="col-md-12 my-1">
                    <textarea
                      id="relato"
                      v-model="item1.fo"
                      :readonly="
                        item1.of_reservation_id !== patient.id_reservation
                      "
                      class="form-control form-control-sm"
                      rows="3"
                      placeholder=""
                      style="resize: none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(5)"
            >
              <h6 class="fw-bold color-main d-inline-block">
                Motivo de Consulta
              </h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[5].state && 'active']"
              />
            </div>
            <!-- item1.reason -->
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[5].state">
              <div class="row px-3">
                <div class="col-md-12 my-1">
                  <textarea
                    ref="motivo"
                    id="relato"
                    v-model="item1.reason"
                    :readonly="
                      (patient.status == 3 &&
                        item1.of_reservation_id !== patient.id_reservation) ||
                      (patient.status == 1 && item1.id !== null)
                    "
                    class="form-control form-control-sm"
                    rows="3"
                    placeholder=""
                    style="resize: none"
                  />
                </div>
              </div>
            </div>
            <div class="panel-border my-1 text-xs pt-1">
              <h6 class="color-main fw-bold">Exploración Física</h6>
              <div class="row px-3">
                <div class="col-md-12 mt-1 align-left">
                  <div id="div-otro" class="form-group row">
                    <div class="col-sm-12 mb-2">
                      <ckeditor 
                        :editor="editor" 
                        v-model="item1.physical_exploration"
                        :congif="editorConfig"
                        @input="mtdProccessExploracion($event)"
                      ></ckeditor>
                      <!-- <textarea
                        v-model="item1.physical_exploration"
                        @keyup.enter="mtdProccessExploracion"
                        :readonly="
                          (patient.status == 3 &&
                            item1.of_reservation_id !==
                              patient.id_reservation) ||
                          (patient.status == 1 && item1.id !== null)
                        "
                        class="form-control form-control-sm"
                        id="otro"
                        ref="refOtro"
                        rows="3"
                        placeholder=""
                        style="resize: none"
                      /> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(4)"
            >
              <h6 class="fw-bold color-main d-inline-block">Diagnosticos</h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[4].state && 'active']"
              />
            </div>
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[4].state">
              <div class="row my-1 px-3">
                <div class="col-md-12">
                  <table class="w-100">
                    <caption class="div-text"></caption>
                    <thead class="div-text div-title">
                      <tr>
                        <th class="color-main w-25">OTROS</th>
                        <!-- <th v-if="inputchecked == true" class=" color-main">CODIGO</th> -->
                        <th class="color-main">DX</th>

                        <th class="color-main">CIE10</th>
                        <th class="color-main">TIPO</th>
                        <th class="color-main">ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody class="div-text">
                      <tr class="px-1">
                        <td>
                          <input
                            v-model="inputchecked"
                            class="form-check-input me-1"
                            type="checkbox"
                            :disabled="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            id="flexCheckDefault"
                            @click="openmodaldx"
                          />
                          <label
                            class="form-check-label"
                            for="flexCheckDefault"
                          >
                            Otros
                          </label>
                        </td>
                        <td>
                          <v-select
                            class="text-dark"
                            :options="dataCies"
                            v-model="diagnostico"
                            :reduce="(cie) => cie"
                            label="name"
                            placeholder="- Buscar Cie -"
                            required
                            style="width: 200px"
                            :clearable="false"
                            :disabled="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            @search="mtdgetCies"
                            @input="mtdaddDiagnosticoUnit"
                          >
                            <template slot="no-options">
                              No hay diagnosticos para la busqueda.
                            </template>
                          </v-select>
                        </td>
                        <!-- <td v-if="inputchecked == false">
                                <v-select
                                  :options="dataCies"
                                  :reduce="(cie) => cie"
                                  label="name"
                                  placeholder="- Seleccione -"
                                  v-model='diagnostico'
                                  required
                                  :clearable="false"
                                  style="width: 200px;"
                                  @input="mtdaddDiagnosticoUnit"
                                />
                              </td> -->
                        <!-- <td v-else>
                                <input type="text" v-model="diagnostics_unit.codigo" class="form-control form-control-input form-control-sm" />

                              </td>
                              <td v-if="inputchecked == true">
                                <input type="text" v-model="diagnostics_unit.name" class="form-control form-control-input form-control-sm" />

                              </td> -->
                        <td>
                          <input
                            type="text"
                            v-model="diagnostics_unit.codigo"
                            readonly
                            class="form-control form-control-input form-control-sm"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            v-model="diagnostics_unit.type"
                            readonly
                            class="form-control form-control-input form-control-sm"
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            @click="mtdaddDiagnostico"
                            :disabled="disabledx"
                            class="btn btn-success btn-sm px-1 py-0"
                          >
                            <i class="fas fa-plus text-white"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="w-100">
                    <caption class="div-text"></caption>
                    <thead class="div-text div-title">
                      <tr>
                        <th class="color-main">#</th>
                        <th class="color-main">DX</th>
                        <th class="color-main">CIE10</th>
                        <th class="color-main">TIPO</th>
                      </tr>
                    </thead>
                    <tbody class="div-text">
                      <tr
                        v-for="(item1, index) in diagnostics"
                        :key="index"
                        class="px-1"
                      >
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          {{ item1.name }}
                        </td>
                        {{
                          item1.codigo
                        }}
                        <td>
                          {{ item1.type }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12">
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(6)"
            >
              <h6 class="fw-bold color-main d-inline-block">Otros examenes</h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[6].state && 'active']"
              />
            </div>
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[6].state">
              <div class="row my-1 px-3">
                <div class="col-lg-3 col-md-3">
                  <label>Agregar examen</label>
                </div>
                <div class="btn-home col-lg-3 col-md-3 mb-2">
                  <button
                    type="button"
                    class="btn btn-sm"
                    @click="openmodalexam"
                    :disabled="
                      (patient.status == 3 &&
                        item1.of_reservation_id !== patient.id_reservation) ||
                      (patient.status == 1 && item1.id !== null)
                    "
                  >
                    <i class="fas fa-upload"></i> Cargar PDF
                  </button>
                </div>
                <div class="col-md-12">
                  <table class="w-100 justify-content-center">
                    <caption class="div-text"></caption>
                    <thead class="div-text div-title">
                      <tr>
                        <th scope="col">N°</th>
                        <th class="color-main w-25">Fecha</th>
                        <th class="color-main w-25">Name</th>
                        <th class="color-main w-25">Usuario</th>
                        <th class="color-main w-25">Acciones</th>
                      </tr>
                    </thead>
                    <tbody v-if="other_exams.length > 0" class="div-text">
                      <tr
                        v-for="(file, index) in other_exams"
                        :key="index"
                        class="px-1"
                      >
                        <td class=" ">{{ index + 1 }}</td>
                        <td class=" ">{{ file.date }}</td>
                        <td class=" ">{{ file.name }}</td>
                        <td class=" ">{{ file.user_name }}</td>
                        <td class="text-center align-middle">
                          <div class="d-flex justify-content-center">
                            <button
                              class="btn btn-sm btn-danger text-white"
                              @click="openPdf(file)"
                            >
                              <i class="fa fa-file-pdf"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else class="div-text fw-bold">
                      <tr>
                        <td colspan="5" class="text-center">
                          No hay archivos subidos
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(3)"
            >
              <h6 class="fw-bold color-main d-inline-block">Plan</h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[3].state && 'active']"
              />
            </div>
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[3].state">
              <div class="row px-3">
                <div class="col-xl-12 col-sm-12 py-2 pb-0">
                  <div
                    class="flex-input d-sm-flex row input-group input-group mx-0 mb-2"
                  >
                    <span
                      class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"
                      ><i class="fas fa-search"></i
                    ></span>
                    <v-select
                      class="col-xl-11 col-md-11 col-sm-11 px-0"
                      :options="dataLaboratorio"
                      :disabled="
                        patient.status == 3 ||
                        (patient.status == 1 && item1.id !== null)
                      "
                      :reduce="(laboratorio) => laboratorio"
                      label="name"
                      placeholder="Laboratorio"
                      required
                      @input="mtdaddPlan"
                      v-model="plan_unit"
                    />
                  </div>
                </div>
                <div class="col-xl-12 col-sm-12 py-2 pb-0">
                  <div
                    class="flex-input d-sm-flex row input-group input-group mx-0 mb-2"
                  >
                    <span
                      class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"
                      ><i class="fas fa-search"></i
                    ></span>
                    <v-select
                      class="col-xl-11 col-md-11 col-sm-11 px-0"
                      :options="dataImagenes"
                      :reduce="(Imagenes) => Imagenes"
                      label="name"
                      placeholder="Imagenes"
                      required
                      @input="mtdaddPlan"
                      :disabled="
                        patient.status == 3 ||
                        (patient.status == 1 && item1.id !== null)
                      "
                      v-model="plan_unit"
                    />
                  </div>
                </div>
                <div class="col-xl-12 col-sm-12 py-2">
                  <div
                    class="flex-input d-sm-flex row input-group input-group mx-0 mb-2"
                  >
                    <span
                      class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"
                      ><i class="fas fa-search"></i
                    ></span>
                    <v-select
                      class="col-xl-11 col-md-11 col-sm-11 px-0"
                      :options="dataProcedimiento"
                      :reduce="(Procedimiento) => Procedimiento"
                      label="name"
                      placeholder="Procedimiento"
                      required
                      :disabled="
                        patient.status == 3 ||
                        (patient.status == 1 && item1.id !== null)
                      "
                      @input="mtdaddPlan"
                      v-model="plan_unit"
                    />
                  </div>
                </div>
              </div>
              <div class="row mx-0 px-3">
                <div
                  v-for="(planunit, index) in plan"
                  :key="index"
                  class="col-md-12 py-1"
                >
                  <div
                    @click="mtddelete(index, 'mtddeleteplan')"
                    class="input-group input-group btn-home px-0 div-pointer text-white fw-bold border bg-main h-100 border-2 align-middle"
                  >
                    <button
                      type=" button"
                      class="w-100 text-start"
                      :disabled="
                        patient.status == 3 ||
                        (patient.status == 1 && item1.id !== null)
                      "
                    >
                      {{ planunit.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-12 text-xs pe-xl-0">
            <div class="panel-border my-1" style="height: auto">
              <h6 class="color-main fw-bold">Indicaciones / Rp</h6>

              <div class="row my-1 px-0">
                <div id="" class="px-4">
                  <table class="w-100">
                    <caption class="div-text"></caption>
                    <thead class="div-text div-title">
                      <tr>
                        <th scope="col" class="color-main">Rp.</th>
                        <th class="color-main">Medicamentos</th>
                        <th class="color-main">Presentación</th>
                        <th class="color-main">Dosis</th>
                        <th class="color-main">Vía</th>
                        <th class="color-main">Frecuencia</th>
                        <th class="color-main">Duración</th>
                        <th class="color-main">Ojo</th>
                        <th class="color-main">Acciones</th>
                      </tr>
                    </thead>
                    <tbody class="div-text">
                      <tr class="px-1 border-bottom">
                        <td>
                          <input
                            v-model="inputcheckedRp"
                            class="form-check-input me-1"
                            type="checkbox"
                            id="checkboxRp"
                          />
                          <label class="form-check-label" for="checkboxRp">
                            Otros
                          </label>
                        </td>
                        <td v-if="!inputcheckedRp">
                          <v-select
                            :options="productdetails"
                            :reduce="(product) => product"
                            label="molecule"
                            :disabled="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            placeholder="- Seleccione -"
                            required
                            v-model="selectedProduct"
                            :clearable="false"
                            style="width: 200px"
                          />
                        </td>
                        <td v-else>
                          <input
                            type="text"
                            :readonly="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            class="form-control form-control-input form-control-sm"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            v-model="selectedProduct.presentacion"
                            :readonly="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            class="form-control form-control-input form-control-sm"
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            v-model="recipe_unit.dosis"
                            :readonly="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            class="form-control form-control-input form-control-sm"
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            v-model="recipe_unit.via"
                            :readonly="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            class="form-control form-control-input form-control-sm"
                            style="width: 70px"
                          />
                        </td>
                        <td>
                          <input
                            v-on:keypress="isNumber($event)"
                            type="text"
                            v-model="recipe_unit.frequency"
                            :readonly="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            class="form-control form-control-input form-control-sm"
                            style="width: 70px"
                          />
                        </td>
                        <td>
                          <input
                            v-on:keypress="isNumber($event)"
                            type="text"
                            v-model="recipe_unit.duration"
                            :readonly="
                              (patient.status == 3 &&
                                item1.of_reservation_id !==
                                  patient.id_reservation) ||
                              (patient.status == 1 && item1.id !== null)
                            "
                            class="form-control form-control-input form-control-sm"
                            style="width: 70px"
                          />
                        </td>
                        <td>
                          <select
                            class="form-control form-control-sm pb-0 pt-0"
                            v-model="recipe_unit.ojo"
                          >
                            <option value="" disabled selected>
                              Seleccionar...
                            </option>
                            <option value="OD">Derecho</option>
                            <option value="OI">Izquierdo</option>
                            <option value="OA">Ambos ojos</option>
                          </select>
                        </td>
                        <td>
                          <button
                            type="button"
                            :disabled="validationInputrecipe"
                            @click="mtdaddrecipe()"
                            class="btn btn-success btn-sm px-1 py-0"
                          >
                            <i class="fas fa-plus text-white"></i>
                          </button>
                        </td>
                      </tr>
                      <tr
                        v-for="(recipeunit, index) in recipe"
                        :key="index"
                        class="px-1"
                      >
                        <td>
                          <span>{{ index + 1 }}</span>
                        </td>
                        <td>
                          <span>{{ recipeunit.molecule }}</span>
                        </td>
                        <td>
                          <span>{{ recipeunit.presentation }}</span>
                        </td>
                        <template v-if="recipeunit.multidosis && recipeunit.multidosis.length > 1">
                          <td colspan="4">
                            <span
                              >Este medicamento contiene
                              {{ recipeunit.multidosis.length }} dosis
                            </span>
                          </td>
                          <td>
                            <button
                              type="button"
                              @click="mtdOpenModalDosis(recipeunit)"
                              class="btn btn-info btn-sm px-1 py-0"
                            >
                              <i class="fas fa-eye text-white"></i>
                            </button>
                          </td>
                        </template>
                        <template v-if="recipeunit.multidosis && recipeunit.multidosis.length == 1">
                          <td>
                            <span>{{ recipeunit.dosis }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.via }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.frequency }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.duration }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.ojo }}</span>
                          </td>
                        </template>
                        <td>
                          <button
                            type="button"
                            @click="mtddelete(index, 'mtddeleterecipe')"
                            class="btn btn-danger btn-sm px-1 py-0"
                          >
                            <i class="fas fa-trash-alt text-white"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-12 my-1 text-xs">
            <div class="panel-border">
              <h6 class="color-main fw-bold">Recomendaciones</h6>
              <div class="row px-3 mb-2">
                <div class="col-md-12 mt-1 align-left">
                  <div id="div-otro" class="form-group row">
                    <div class="col-sm-12 mb-2">
                      <textarea
                        v-model="item1.recomendations"
                        class="form-control form-control-sm"
                        :readonly="
                          (patient.status == 3 &&
                            item1.of_reservation_id !==
                              patient.id_reservation) ||
                          (patient.status == 1 && item1.id !== null)
                        "
                        id="otro"
                        rows="4"
                        placeholder=""
                        style="resize: none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="cpData[0]">
            <div
              @click="mtdopenmodal()"
              v-if="
                cpData[0].id === null ||
                cpData[0].of_reservation_id == patient.id_reservation
              "
              class="z-index-button div-pointer sticky-bottom navbar-brand px-0 py-0 bg-main footer-historial mb-5 text-center nav-footer w-100 mb-3"
            >
              <span class="w-100 px-0 py-0 text-white">
                <strong><i class="fas fa-search"></i> DESTINO</strong>
              </span>
            </div>
          </div>
        </div>
      </div>

      <SweetAlert
        :swal="swal"
        ref="SweetAlert"
        @mtddeleteplan="mtddeleteplan"
        @mtddeleterecipe="mtddeleterecipe"
        @confirmed="Confirmed"
      />
      <cModaldestino
        :status="status"
        :title="modal.title"
        :boo_modal="modal.modal_form"
        @mtdclosemodal="mtdclosemodal"
        @confirmed="Confirmed"
      />
      <Optometria
        v-if="opt == 2"
        @optaccion="optaccion"
        :dataOptometria="dataOp"
        :hc="cpData[0].id"
      />
      <cModaldiagnosticoVue
        :title="modaldx.title"
        :boo_modal="modaldx.modal_form"
        @mtdclosemodaldx="mtdclosemodaldx"
        @mtdcie="mtdcie"
      />
      <cModalExamenesVue
        :patient_id="patient_id"
        :title="modaldexam.title"
        :boo_modal="modaldexam.modal_form"
        @mtdclosemodalexam="mtdclosemodalexam"
        @addpdf="addpdf"
      />
      <cModalViewDosis
        :data="modalDosis.dosis"
        :product_id="modalDosis.product_id"
        :title="modalDosis.title"
        :boo_modal="modalDosis.modal_form"
        :edit="modalDosis.edit"
        @mtdclosemodal="mtdclosemodal"
        @mtddeletedosis="mtddeletedosis"
      />
      <Listen v-show="isListe" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import cModaldestino from "./AyudaDx/modals/cModaldestino.vue";
import Optometria from "./Historia/Optometria.vue";
import cModalViewDosis from "./Historia/cModalViewDosis.vue";
import cModaldiagnosticoVue from "./AyudaDx/modals/cModaldiagnostico.vue";
import cModalExamenesVue from "./AyudaDx/modals/cModalExamenes.vue";
import moment from "moment";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Listen from "@/components/loading/Listen.vue";

const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const sr = new Recognition();

export default {
  name: "c-medico-historia",
  components: {
    SweetAlert,
    cModaldestino,
    Optometria,
    cModalViewDosis,
    cModaldiagnosticoVue,
    cModalExamenesVue,
    Listen
  },
  data() {
    return {
      status: false,
      page: "historia",
      /* paginacion */
      itemsPerPage: 1,
      dataShow: [],
      loading: false,
      patient_id: null,
      selectPage: this.history.length,
      stepPagination: [],
      stateHistory: null,
      viewInput: null,
      productdetails: [],
      swal: null,
      opt: "1",
      other_exams: [],
      other_exams_admision: [],
      other_examsadicional: [],
      fileExam_unit: {
        name: "",
        date: "",
        user_name: "",
        user_id: "",
        pdf_id: "",
      },
      inputchecked: false,
      inputcheckedRp: false,
      clinica_history: {
        id: null,
        user_id: null,
        patient_id: null,
        reason: "",
        fo: "",
        physical_exploration: "",
        other_exams: null,
        te: "",
        diagnostics: null,
        plan: null,
        recipe: null,
        recomendations: "",
        optometria: null,
        of_reservation_id: null,
        status: null,
        option: null,
      },
      selectedProduct: {
        molecule: "",
        presentacion: "",
        dosis: "",
        id: "",
      },
      recipe_unit: {
        order: null,
        product_id: null,
        molecule: "",
        presentation: "",
        multidosis: [],
        dosis: "",
        via: "",
        frequency: "",
        duration: "",
        ojo: "",
      },
      recipe: [],
      recipeadicional: [],
      titleDropdown: [
        {
          title: "Antecedentes",
          state: true,
        },
        {
          title: "Signos",
          state: true,
        },
        {
          title: "Signos vitales",
          state: true,
        },
        {
          title: "Plan",
          state: true,
        },
        {
          title: "DX",
          state: true,
        },
        {
          title: "Relato",
          state: true,
        },
        {
          title: "Otros examenes",
          state: true,
        },
        {
          title: "Fondo de ojos",
          state: true,
        },
      ],
      dataLaboratorio: [],
      dataImagenes: [],
      dataProcedimiento: [],
      dataCies: [],
      plan_unit: null,
      plan_unit_detail: {
        name: "",
        of_service_id: null,
        of_product_id: null,
      },
      plan: [],
      planadicional: [],
      modal: {
        title: "",
        modal_form: false,
      },
      modaldx: {
        title: "",
        modal_form: false,
      },
      modaldexam: {
        title: "",
        modal_form: false,
      },
      modalDosis: {
        title: "",
        modal_form: false,
        dosis: [],
        product_id: 0,
        edit: false,
      },
      diagnostics: [],
      diagnosticsadicional: [],
      diagnostics_unit: {
        id: "",
        codigo: "",
        name: "",
        cie: "",
        type: "",
      },
      calendario: {
        doctor_id: "",
        patient_id: "",
        status: "",
      },
      diagnostico: "",
      dataOptometria: {
        autorefractometro: {
          od: {
            esf: "",
            cil: "",
            eje: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
          },
          comentario: {
            lentepremium: "",
          },
        },

        medida_uso: {
          od: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
        },

        agudeza: {
          od: {
            avsc: "",
            avcc: "",
          },
          oi: {
            avsc: "",
            avcc: "",
          },
        },
        medidad_cerca: {
          od: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          // adicion: {
          //     value: '',

          // },
          tipo_de_luna: {
            value: "",
          },
          dip: {
            value: "",
          },
        },
        medidad_lejos: {
          od: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          adicion: {
            od: "",
            oi: "",
          },
          tipo_de_luna: {
            value: "",
          },
          dip: {
            value: "",
          },
        },
        cicloplegia: {
            od: {
                esf: '',
                cil: '',
                eje: '',
                av: '',
                prisma: '',
                cae: ''
            },
            oi: {
                esf: '',
                cil: '',
                eje: '',
                av: '',
                prisma: '',
                cae: ''
            },
            adicion: {
                od: '',
                oi: '',

            },
            tipo_de_luna: {
                value: ''
            },
            dip: {
                value: ''
            }
        },
        /*
        cicloplegia: {
          od: "",
          oi: "",
        },
        */
      },
      fo_wait_time: null,
      /** voice */
      isListe: false,
      dictado: "",
      input: "",
      /** ckeditor */
      editor: ClassicEditor,
      editorConfig:{
        toolbar:[]
      },
      dataOp: {
        autorefractometro: {
          od: {
            esf: "",
            cil: "",
            eje: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
          },
          comentario: {
            lentepremium: "",
          },
        },

        medida_uso: {
          od: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
        },

        agudeza: {
          od: {
            avsc: "",
            avcc: "",
          },
          oi: {
            avsc: "",
            avcc: "",
          },
        },
        medidad_cerca: {
          od: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          // adicion: {
          //     value: '',

          // },
          tipo_de_luna: {
            value: "",
          },
          dip: {
            value: "",
          },
        },
        medidad_lejos: {
          od: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          oi: {
            esf: "",
            cil: "",
            eje: "",
            av: "",
            prisma: "",
            cae: "",
          },
          adicion: {
            od: "",
            oi: "",
          },
          tipo_de_luna: {
            value: "",
          },
          dip: {
            value: "",
          },
        },
        cicloplegia: {
            od: {
                esf: '',
                cil: '',
                eje: '',
                av: '',
                prisma: '',
                cae: ''
            },
            oi: {
                esf: '',
                cil: '',
                eje: '',
                av: '',
                prisma: '',
                cae: ''
            },
            adicion: {
                od: '',
                oi: '',

            },
            tipo_de_luna: {
                value: ''
            },
            dip: {
                value: ''
            }
        },
        /*
        cicloplegia: {
          od: "",
          oi: "",
        },
        */
      },
    };
  },
  computed: {
    validationInputrecipe: function () {
      let value = true;
      if (
        this.selectedProduct.molecule &&
        this.recipe_unit.frequency &&
        this.recipe_unit.via &&
        this.recipe_unit.duration &&
        this.recipe_unit.ojo
      ) {
        value = false;
      }
      return value;
    },
    estadoChecklist: function () {
      const completadas = this.checklist.filter((item) => item.checked).length;
      return `${completadas} de ${this.checklist.length} tareas completadas.`;
    },
    disabledx: function () {
      return !this.diagnostics_unit.name;
    },
    cpData() {
      const startIndex = (this.selectPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      let itemTemporal = this.history.slice(startIndex, endIndex);
      if (itemTemporal.length > 0) {
        this.procesarPlans(itemTemporal[0]);
        this.procesarRecipe(itemTemporal[0]);
        this.procesarDiagnostics(itemTemporal[0]);
        this.procesarExams(itemTemporal[0]);
      }
      //console.log(this.history.slice(startIndex, endIndex));
      return this.history.slice(startIndex, endIndex);
    },
  },
  watch: {
    history: {
      handler() {
        // Llamas a mtdfilldata cuando history cambia
        this.mtdfilldata();
      },
      deep: true, // Para observar cambios en la profundidad de la historia
      immediate: true,
    },
    status: {
      handler() {
        this.mtdenviarstatus();
      },
    },
  },
  props: {
    stateRolAdmin: Boolean,
    patient: {
      type: Object,
      default: () => ({}),
    },
    history: {
      type: Array,
    },
    viewMedico: Number,
    codesIa:Array,
  },
  created() {
    this.mtdgetSubsubcategories();
    this.mtdgetproductdetails();
    this.mtdExamenesAdmision();
  },
  mounted() {
    sr.continuous = true;
    sr.interimResults = true;
    sr.onstart = () => {
     // console.log("SR Started");
    };

    sr.onend = () => {
      //console.log("SR Stopped");
      this.ProcessVocie(this.dictado);
    };

    sr.onresult = (evt) => {
      for (let i = 0; i < evt.results.length; i++) {
        const result = evt.results[i];
        if (result.isFinal) {
          this.CheckForCommand(result);
        }
      }
      const t = Array.from(evt.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");

      this.dictado = t;
    };
  },

  methods: {
    ...mapActions(["get", "post"]),
    mtdGetStyle: function (option) {
      if (option == this.page) return "background: #900052";
      return "";
    },
    mtdGetStyleSpan: function (option) {
      if (option == this.page) return "color:white";
      return "";
    },
    changePage: function (page) {
      this.page = page;
      if (page === "cola") {
        this.patients = {};
      }
      this.stateHistory = page === "historia" ? 1 : 0;
      this.viewInput = page === "historia" ? 1 : 0;
    },
    mtdOpenModalDosis: function (item) {
      this.modalDosis = {
        title: "DOSIS DE " + item.molecule,
        modal_form: true,
        dosis: item.multidosis,
        product_id: item.product_id,
        edit: (this.patient.status == 3 &&
        this.cpData[0].of_reservation_id !== this.patient.id_reservation) ||
                      (this.patient.status == 1 && this.cpData[0].id !== null)
      };
    },
    mtddeletedosis: function (index, product_id) {
      this.recipe.forEach((element) => {
        if (element.product_id == product_id) {
          element.multidosis.splice(index, 1);
        }
      });
    },
    returnHome() {
      this.$emit("mtdChangePage", "home");
      this.imagenes = [];
      this.plan = [];
    },
    mtdBackPage() {
      this.$emit("mtdBackPage");
    },
    mtdviewpage: function (payload) {
      this.page = "historia";
      this.stateHistory = 0;
      this.patients = payload;
      this.viewInput = 0;
    },
    mtdopenmodal: function () {
      this.modal = {
        title: "DESTINO",
        modal_form: true,
      };
    },
    optaccion(num, dataOptometria) {
      //if(num == 2){
        if(this.cpData[0].optometry !== this.dataOp){
          this.dataOp = this.cpData[0].optometry;
          //console.log(this.dataOp);
        }else {
          const optometriaData = JSON.stringify(this.dataOptometria);
          this.dataOp = optometriaData;
         // console.log(this.dataOp);
        }
      //}
      this.opt = num;
      this.dataOptometria = dataOptometria;
    },
    referPatient() {
      this.$refs.SweetAlert.showConfirmSimple(
        "Examen de Fondo de ojos",
        "¿Desea derivarlo al paciente?",
        "warning",
        "Si, derivarlo",
        2
      );
    },
    Confirmed: function (status) {
      this.clinica_history.user_id = this.$store.getters.get__user.id;
      this.clinica_history.patient_id = this.patient.id;
      this.clinica_history.recipe = this.recipe;
      this.clinica_history.plan = this.plan;
      this.clinica_history.of_reservation_id = this.patient.id_reservation;
      this.clinica_history.status = status;
      this.clinica_history.option = this.patient.status;
      this.clinica_history.diagnostics = this.diagnostics;
      this.clinica_history.other_exams = this.other_exams;
      this.clinica_history.optometria = this.dataOptometria;
      this.post({
        url: this.$store.getters.get__url + "/Doctor/store",
        token: this.$store.getters.get__token,
        params: this.clinica_history,
      })
        .then((response) => {
          this.clinica_history = response.data;
          this.$refs.SweetAlert.showSuccess(response.message);
          if (status == 7 || status == 2 || status == 6) {
            //comentario de status de la tabla reservacion
            if (status == 2) {
              Swal.fire({
                title: "Ingrese el tiempo de espera del paciente",
                input: "number",
                inputAttributes: {
                  autocapitalize: "off",
                  placeholder: "5 min",
                },
                showCancelButton: true,
                confirmButtonText: "Guardar",
                showLoaderOnConfirm: true,
                preConfirm: async (time) => {
                  try {
                    // Validar que el tiempo ingresado sea un número positivo
                    if (isNaN(time) || parseInt(time) <= 0) {
                      throw new Error(
                        "Por favor, ingrese un tiempo válido en minutos."
                      );
                    }
                    // Convertir el tiempo a segundos
                    this.fo_wait_time = time;
                    const waitTime = parseInt(time) * 60;
                    // Devolver el tiempo en segundos
                    return waitTime;
                  } catch (error) {
                    Swal.showValidationMessage(error.message);
                  }
                },
                allowOutsideClick: () => !Swal.isLoading(),
              }).then((result) => {
                if (result.isConfirmed) {
                  const waitTimeInSeconds = result.value;
                  // Ahora puedes usar waitTimeInSeconds como el tiempo de espera del paciente
                  console.log(
                    "Tiempo de espera del paciente (segundos):",
                    waitTimeInSeconds
                  );
                  //cambiar en el back
                    this.mtdChangeFoWaitTime();
                  
                }
              });
            } else {
              this.$emit("mtdShowOffCanva");
            }
          } else {
            this.calendario = {
              doctor_id: this.$store.getters.get__user.id,
              patient_id: this.patient.id,
              status: status,
            };
            this.$emit("mtdCitas", this.calendario);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
      // this.calendario = {
      //     doctor_id: this.$store.getters.get__user.id,
      //     patient_id: this.patient.id,
      //     status: status,
      // }
      // this.$emit('mtdCitas',this.calendario);
      this.mtdclosemodal();
      this.mtdhideHistoryal();
      this.mtdhiderecipeunit();
    },
    mtdChangeFoWaitTime: function () {
      this.post({
        url: this.$store.getters.get__url + "/Doctor/changeFoWaitTime",
        token: this.$store.getters.get__token,
        params: {
          id_reservation: this.patient.id_reservation,
          fo_wait_time: this.fo_wait_time,
        },
      })
        .then((response) => {
          this.$emit("mtdShowOffCanva");
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    mtdgetSubsubcategories: function () {
      this.post({
        url: this.$store.getters.get__url + "/Doctor/plans",
        token: this.$store.getters.get__token,
      }).then((response) => {
        if (response.statusCode === 200) {
          this.dataLaboratorio = response.data.dataLaboratorio;
          this.dataImagenes = response.data.dataImagenes;
          this.dataProcedimiento = response.data.dataProcedimiento;
        }
      });
    },
    mtdgetproductdetails: function () {
      this.post({
        url: this.$store.getters.get__url + "/Doctor/productdetails",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.productdetails = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    mtdhiderecipeunit: function () {
      this.recipe_unit = {
        order: null,
        product_id: null,
        molecule: "",
        presentation: "",
        dosis: "",
        multidosis: [],
        via: "",
        frequency: "",
        duration: "",
        ojo: "",
      };

      this.selectedProduct = {
        id: "",
        molecule: "",
        presentation: "",
        dosis: "",
      };
      this.plan_unit = null;
    },
    mtdhideHistoryal: function () {
      this.clinica_history = {
        id: null,
        physical_exam: null,
        lab_question: "",
        symploms: null,
        vital_sings: null,
        fe: "",
        fi: "",
        fo: "",
        course: "",
        story: "",
        another_file: "",
        diagnostics: null,
        plan: null,
        recipe: null,
        recomendations: "",
        physical_exam_text: "",
        background: null,
        patient_id: null,
        model_id: null,
      };
      this.recipe = null;
      this.plan = null;
      this.diagnostics = null;
    },
    mtdaddrecipe: function () {
      const exists = this.recipe.some(
        (item) => item.product_id === this.selectedProduct.id
      );
      const productId = this.selectedProduct.id;
      let itemDosis = {
        dosis: this.recipe_unit.dosis,
        via: this.recipe_unit.via,
        frequency: this.recipe_unit.frequency,
        duration: this.recipe_unit.duration,
        ojo: this.recipe_unit.ojo,
      };
      if (exists) {
        //si existe consultar si se quiere agregar como segunda dosis
        this.$refs.SweetAlert.showConfirmSimple2(
          "",
          "Medicamento ya agregado ¿Desea registrar otra dosis?",
          "warning",
          "Confirmar"
        ).then((result) => {
          if (result.value) {
            //agregar Dosis
            this.recipe.forEach((element) => {
              if (element.product_id == productId) {
                element.multidosis.push(Object.assign({}, itemDosis));
              }
            });
          }
        });
        //this.$refs.SweetAlert.showWarning("Medicamento ya agregado");
        this.mtdhiderecipeunit();
        return;
      }
      this.recipe_unit.order = this.recipe.length + 1;
      this.recipe_unit.product_id = this.selectedProduct.id;
      this.recipe_unit.presentation = this.selectedProduct.presentacion;
      this.recipe_unit.molecule = this.selectedProduct.molecule;
      this.recipe_unit.multidosis.push(Object.assign({}, itemDosis));
      this.recipe.push(Object.assign({}, this.recipe_unit));
      this.recipeadicional = this.recipe;
      this.mtdhiderecipeunit();
    },
    mtdaddPlan: function (laboratorioSeleccionado) {
      if (laboratorioSeleccionado.of_service_id == 4) {
        this.status = true;
      }

      const exist = this.plan.some(
        (item) => item.of_product_id === laboratorioSeleccionado.id
      );

      if (exist) {
        this.$refs.SweetAlert.showWarning(
          "El item seleccionado a sido agregado"
        );
        this.mtdhiderecipeunit();
        return;
      }
      this.plan_unit = laboratorioSeleccionado;
      this.plan_unit_detail = {
        name: this.plan_unit.name,
        of_service_id: this.plan_unit.of_service_id,
        of_product_id: this.plan_unit.id,
      };

      this.plan.push(Object.assign({}, this.plan_unit_detail));
      this.planadicional = this.plan;
      this.mtdhiderecipeunit();
    },
    mtddelete: function (index, msg) {
      this.$refs.SweetAlert.showDelete(index, msg);
    },
    mtddeleterecipe: function (index) {
      this.recipe.splice(index, 1);
      for (let i = 0; i < this.recipe.length; i++) {
        this.recipe[i].order = i + 1;
      }
      this.recipeadicional = this.recipe;
    },
    mtddeleteplan: function (index) {
      this.plan.splice(index, 1);
      if (this.plan.length == 0) {
        this.status = false;
      } else {
        this.plan.forEach((element) => {
          if (element.of_service_id == 4) {
            this.status = true;
          } else {
            this.status = false;
          }
        });
      }

      this.planadicional = this.plan;
    },
    toggleDropdown(title) {
      this.titleDropdown[title].state = !this.titleDropdown[title].state;
    },
    mtdclosemodal: function () {
      this.modal = {
        title: "",
        modal_form: false,
      };
      this.modalDosis = {
        title: "",
        modal_form: false,
        dosis: [],
        product_id: 0,
        edit: true,
      };
    },
    limpiarhistorial() {
      this.mtdhideHistoryal();
      this.mtdhiderecipeunit();
    },
    mtdfilldata: function () {
      this.selectPage = this.history.length;
      this.stepPagination = [];
      let cantidad = parseInt(this.history.length);

      for (let index = 0; index < cantidad; index++) {
        if (this.history[index].created_at !== "HC Actual") {
          if (this.patient.status !== 1 && index == cantidad - 1) {
            //cuando viene de fondo de ojos el HC Actual es el último
            this.stepPagination.push({
              index: index + 1,
              date: "HC Actual",
            });
          } else {
            let fechaFormateada = moment(this.history[index].created_at).format(
              "DD/MM/YYYY"
            );
            let item = {
              index: index + 1,
              date: fechaFormateada,
            };
            this.stepPagination.push(item);
          }
        } else {
          this.stepPagination.push({
            index: index + 1,
            date: "HC Actual",
          });
        }
      }
      this.historyItem = this.history[this.selectPage - 1];

      if (this.history.length > 0) {
        if (Object.keys(this.historyItem).length !== 0) {
          this.clinica_history.id = this.historyItem.id;
          this.procesarPlans(this.historyItem);
          this.procesarRecipe(this.historyItem);
          this.procesarDiagnostics(this.historyItem);
          this.procesarExams(this.historyItem);
          this.clinica_history.reason = this.historyItem.reason;
          this.clinica_history.physical_exploration =
            this.historyItem.physical_exploration;
          this.clinica_history.fo = this.historyItem.fo;
          this.clinica_history.te = this.historyItem.te;
          this.clinica_history.recomendations = this.historyItem.recomendations;
          this.clinica_history.optometria = this.historyItem.optometria;
          this.clinica_history.of_reservation_id =
            this.historyItem.of_reservation_id;
        }
      }
    },
    mtdgetCies: function (search, loading) {
      if (search.length > 4) {
        this.dataCies = [];
        loading(true);
        this.get({
          url: this.$store.getters.get__url + "/Doctor/cies/" + search,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.statusCode === 200) {
              this.dataCies = response.data;
            }
            loading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    mtdaddDiagnosticoUnit: function (diagnostico) {
      this.diagnostics_unit = {
        id: diagnostico.id,
        codigo: diagnostico.code,
        name: diagnostico.name,
        type: diagnostico.type,
        cie: diagnostico.cie,
      };
      this.dataCies = [];
    },
    mtdaddDiagnostico: function () {
      const exists = this.diagnostics.some(
        (item) => item.id === this.diagnostics_unit.id
      );
      if (exists) {
        this.$refs.SweetAlert.showWarning("Diagnostico ya agregado");
        this.mtdhidediagnosticounit();
        return;
      }
      this.diagnostics.push(Object.assign({}, this.diagnostics_unit));
      this.diagnosticsadicional = this.diagnostics;
      this.mtdhidediagnosticounit();
    },
    mtdhidediagnosticounit: function () {
      this.diagnostics_unit = {
        id: "",
        codigo: "",
        name: "",
        cie: "",
        type: "",
      };
      this.diagnostico = null;
    },
    openmodaldx: function () {
      //console.log(this.inputchecked);
      if (this.inputchecked == false) {
        this.modaldx = {
          title: "Nuevo Diagnostico",
          modal_form: true,
        };
        this.mtdhidediagnosticounit();
      }
    },
    mtdclosemodaldx: function () {
      this.modaldx = {
        title: "",
        modal_form: false,
      };
      this.inputchecked = false;
    },
    mtdcie: function (itemdetalle) {
      this.diagnostics_unit = {
        id: itemdetalle.id,
        codigo: itemdetalle.code,
        name: itemdetalle.name,
      };
      this.diagnostico = itemdetalle.name;
    },
    openmodalexam: function () {
      this.modaldexam = {
        title: "Subir Archivo",
        modal_form: true,
      };
    },
    mtdclosemodalexam: function () {
      this.modaldexam = {
        title: "",
        modal_form: false,
      };
    },
    addpdf: function (pdf) {
      // fileExam: [],
      this.fileExam_unit = {
        name: pdf.name,
        date: pdf.date,
        user_name: this.$store.getters.get__user.name,
        user_id: pdf.user_id,
        pdf_id: pdf.id,
      };
      this.other_exams.push(this.fileExam_unit);
      this.other_examsadicional = this.other_exams;
    },
    procesarDiagnostics: function (historyItem) {
      if (historyItem.diagnostics != null) {
        this.diagnostics =
          historyItem.of_reservation_id == this.patient.id_reservation &&
          this.diagnosticsadicional.length > 0
            ? this.diagnosticsadicional
            : JSON.parse(historyItem.diagnostics);
      } else {
        this.diagnostics =
          this.diagnosticsadicional.length > 0 ? this.diagnosticsadicional : [];
      }
    },
    procesarPlans: function (historyItem) {
      if (historyItem.plan != null) {
        this.plan =
          historyItem.of_reservation_id == this.patient.id_reservation &&
          this.planadicional.length > 0
            ? this.planadicional
            : JSON.parse(historyItem.plan);
      } else {
        this.plan = this.planadicional.length > 0 ? this.planadicional : [];
      }
      if (this.plan.length > 0) {
        // Comprobar si algún elemento en el plan tiene el ID de servicio igual a 4
        this.status = this.plan.some((element) => element.of_service_id === 4);
      } else {
        this.status = false;
      }
    },
    procesarRecipe: function (historyItem) {
      if (historyItem.recipe != null) {
        this.recipe =
          historyItem.of_reservation_id == this.patient.id_reservation &&
          this.recipeadicional.length > 0
            ? this.recipeadicional
            : JSON.parse(historyItem.recipe);
      } else {
        this.recipe =
          this.recipeadicional.length > 0 ? this.recipeadicional : [];
      }
    },
    procesarExams: function (historyItem) {
      if (historyItem.other_exams != null) {
        this.other_exams =
          historyItem.of_reservation_id == this.patient.id_reservation &&
          this.other_examsadicional.length > 0
            ? this.other_examsadicional
            : JSON.parse(historyItem.other_exams);
      } else {
        this.other_exams =
          this.other_examsadicional.length > 0
            ? this.other_examsadicional
            : this.other_exams_admision;
      }
    },
    /** pagination */
    selectedPage: function (page) {
      this.selectPage = page.index;
    },
    backPage: function () {
      this.selectPage = this.selectPage === 1 ? 1 : this.selectPage - 1;
    },
    nextPage: function () {
      this.selectPage =
        this.selectPage === this.stepPagination.length
          ? this.stepPagination.length
          : this.selectPage + 1;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdenviarstatus: function () {
      this.$emit("mtdenviarstatus", this.status);
    },
    mtdExamenesAdmision: function () {
      this.patient_id = this.patient.id;
      this.get({
        url:
          this.$store.getters.get__url +
          "/Doctor/indexexamenes/" +
          this.patient.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            if (response.data.length > 0) {
              response.data.forEach((element) => {
                this.fileExam_unit = {
                  name: element.name,
                  date: element.date,
                  user_name: element.user.name,
                  user_id: element.user.id,
                  pdf_id: element.id,
                };
                this.other_exams_admision.push(this.fileExam_unit);
              });
            } else {
              this.other_exams_admision = [];
            }

            // this.other_exams = response.data
          }
        })
        .catch((errors) => {});
    },
    openPdf: function (pdf) {
      this.get({
        url: this.$store.getters.get__url + "/Doctor/getExamen/" + pdf.pdf_id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            let url = response.data.url;
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, "_blank");
          }
        })
        .catch((errors) => {});
      // console.log(pdf);
    },
    /** voice */
    initCallVoice: function () {
      if (
        "webkitSpeechRecognition" in window ||
        "SpeechRecognition" in window
      ) {
        if (this.isListe) {
          sr.stop();
          this.isListe = !this.isListe;
        } else {
          sr.start();
          this.isListe = !this.isListe;
        }
      } else {
        alert("no compatible");
      }
    },
    /** CONTROL DE COMANDOS */
    CheckForCommand: function (result) {
      const t = result[0].transcript.toUpperCase();
      if (t.includes("STOP")) {
        this.isListe = !this.isListe;
        sr.stop();
      } else if (t.includes("optometría") || t.includes("Optometría")) {
        this.optaccion("2");
      } else {
      }
    },
    ProcessVocie: function (text) {
      let arHeader = ["TIEMPO", "MOTIVO", "EXPLORACIÓN", "STOP"];
      let arHeaderIndex = [];
      text = text.toUpperCase().replace("Stop", "");
      let arText = text.split(" ");
      //console.log(arText);
      for (let index = 0; index < arHeader.length; index++) {
        const element = arHeader[index];
        arHeaderIndex.push(arText.indexOf(element));
      }
      // console.log(arHeaderIndex);
      for (let index = 0; index < arHeaderIndex.length; index++) {
        let concatenacion = arText
          .slice(arHeaderIndex[index], arHeaderIndex[index + 1])
          .join(" ");
        concatenacion = concatenacion
          .replace(arHeader[index], "")
          .toUpperCase()
          .trim();
        switch (index) {
          case 0:
            this.history.slice(-1)[0].te = concatenacion;
            break;
          case 1:
            this.history.slice(-1)[0].reason = concatenacion;
            break;
          case 2:
            this.history.slice(-1)[0].physical_exploration = concatenacion;
            break;
          default:
            break;
        }
      }
    },
    /** codes IA1 */
    mtdProccessExploracion: function(event){      
      let explo =  this.history.slice(-1)[0].physical_exploration.trim();
      if(explo.length==0) return;
      let posIniArroba = explo.indexOf("@");
      if (posIniArroba > 0) {        
        let posFinArroba = explo.indexOf("<",posIniArroba)==-1?explo.length:explo.indexOf("<",posIniArroba);
        let arroba = explo.substring(posIniArroba+1,posFinArroba).trim().toString();
        let html = this.codesIa.filter((item) => item.code == arroba);
        if (html.length > 0) {
          this.history.slice(-1)[0].physical_exploration = explo.substring(0,posIniArroba).trim() + html[0].description.trim();
        }

      }
    }
  },
};
</script>
<style scoped>
</style>

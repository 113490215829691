<template>
    <div>
      <div class="container text-center mt-2">
  
        <div class="d-flex title-option">
          <h2 class=" h2-borde mt-3 mb-3 ms-5 text-main pt-1 px-4">
            <strong>RESONANCIA</strong>
          </h2>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import {
      mapActions
  } from 'vuex'
  
  export default {
      name: 'c-doctor-consulta-resonancia',
      components: {
      },
      data() {
        return {
          page: 'resonancia_dx',
          stateHistory: null,
          viewInput:null,
          patients: {},
        }
      },
      props:{
          stateRolAdmin: Boolean,
      },
      created() {
          
      },
      methods: {
          ...mapActions(['get', 'post']),
          mtdGetStyle: function (option) {
              if (option == this.page) return 'background: #900052';
              return '';
          },
          mtdGetStyleSpan: function (option) {
              if (option == this.page) return 'color:white';
              return '';
          },
          changePage: function (page) {
              this.page = page;
              if(page==='cola'){
                  this.patients ={}
              }
              this.stateHistory = (page === 'resonancia_dx') ? 1 : 0;
              this.viewInput = (page === 'resonancia_dx') ? 1 : 0;
          },
          returnHome() {
           this.$emit('mtdChangePage','home')
          },
          mtdviewpage: function (payload) {
              this.page = 'resonancia_dx'
              this.stateHistory = 0;
              this.patients = payload;
              this.viewInput=0;
          }
          
      }
  }
  </script>
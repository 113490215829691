<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'md'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" checked name="flexRadioDefault" @change="mtdSelectType(1)">
              <label class="form-check-label" >
                  Depósito
              </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              @change="mtdSelectType(2)"
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"     
            />
            <label class="form-check-label" >
              Retiro
            </label>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xs-12">
          <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Monto a {{transaction.type==1?'Depositar':'Retirar'}}</label>
              <input class="form-control form-control-sm" placeholder="0.00" v-model="transaction.amount">
          </div>
        </div>
      </div>
      <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
          <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdSendPermission">
          <i class='fas fa-save' />&nbsp; Registrar
        </button>
        &nbsp;
      </template>
    </CModal>
</template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";

  import { mapActions } from "vuex";
  
  export default {
    name: "c-vigilancia-modal-control",
    components: {
      CModal,
    },
    data() {
      return {
        transaction:{
            type:1,
            amount:""
        }
    };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: {},
      size: String,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
      mtdSelectType: function (value) {
        this.transaction.type=value;
      },
      filterKey: function (e) {
        let b = false;
        b = /^[0-9]+$/.test(e.key);
        if (b == false && e.key != "Backspace") {
          e.preventDefault();
        }
      },
      mtdClose() {
        this.transaction = {
          type: 1,
          amount: ""
        };
        this.$emit("closeModalRetiros");
      },
      mtdSendPermission: function(){
          this.$emit("mtdSendPermission",this.transaction);
      }
    },
  };
  </script>

  
  
  
<template>
    <div class="container">
      <div id="btn-return" class="d-flex justify-content-end pt-1 mt-3">
        <div class="btn-home" role="group">
            <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                <i class="fas fa-recycle"></i> Cambiar de Sede
            </button>
        </div>
    </div>
    <div>
      <div id="btn_home" class="c-app align-items-center">
        <div class="d-grid gap-2 mx-auto text-center col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <h2>HOME ATENCION AL CLIENTE</h2>  
        </div>
      </div>
    </div>
    </div>
  </template>
  
  <script>

    export default {
      name: 'HomeSatComponent',
      props: {
      },
      data () {
        return {
            page: null,
        };
      },
      created () {
        
      },
      methods: {
        mtdGetStyle:function(option){
          if (option == this.page) return 'background: #900052';
          return '';
        },
        mtdGetStyleSpan:function(option){
          if (option == this.page) return 'color:white';
          return '';
        },
        mtdSendOption: function(page){
          this.page=page;
          this.$emit('mtdChangePage',this.page)
        },
        mtdBackCampus: function () {
              this.$emit("mtdBackCampus");
          },
        mtdChangePage: function(page){
          this.page=page;
          this.$emit('mtdChangePage',this.page)
        },
      },
    }
  </script>
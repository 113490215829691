import Vue from 'vue'
import Vuex from 'vuex'

import * as actions from "./actions";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //url:"http://oftalmologia-back.test/api/v1", // local
    url: "https://oftalmologia-back.howertpi.com/api/v1", // nube
    // url: "https://bmback.v2.howertpi.com/api/v2", // nube
    token:null,
     /** auth */
    user: {
      id: null,
      name: null,
      email: null,
      last_name: null,
      document_type: null,
      dni: null,
      password: null,
      rol_id: null,
      //campu_id: null,
      // departure_id: null,
      state: null,
      roles: [],
      permissions: [],
    },
    dataCampus:[],
    campus:null
  },
  getters: {
    get__url(state){
      return state.url;
    },
    get__token(state){
      return state.user.token;
    },
    get__user(state){
      return state.user;
    },
    get__campus(state){
      return state.campus;
    },
    get__datacampus(state){
      return state.dataCampus;
    },
  },
  mutations: {
    /** auth */
    mt_set_auth(state,payload) {
      state.user = payload;
    },
    mt_set_refresh(state , user) {
      state.user = user;
    },
    mt_logout_auth(state){
      state.user= {
        id: null,
        name: null,
        email: null,
        last_name: null,
        document_type: null,
        dni: null,
        password: null,
        rol_id: null,
        //campu_id: null,
        // departure_id: null,
        state: null,
        roles: [],
        permissions: [],
      };
    },
    mt_set_datacampus(state,payload){
      state.dataCampus = payload;
    },
    mt_set_campus(state,payload){
      state.campus = payload;
    },
    CALL__STATE_VIEW_PATIENT(state,payload){
        state.campus = payload;
    }
  },
  actions,
  modules: {
  },
  plugins: [
    createPersistedState({
        reducer: (persistedState) => {
            const stateFilter = JSON.parse(JSON.stringify(persistedState));
            return stateFilter;
        }
    })
  ]
})

<template>
<div>
    <div class="container">
        <div class="d-flex flex-row-reverse">
            <div class="mt-1 mb-4 btn-home">
                <button type="button" class="btn btn-outline-main ms-1" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>ORDEN DE COMPRA</strong>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="w-100 border-box-main pt-4" style="background-color: #f2f4f4">
                        <div class=" row mx-0 px-0 responsive-search px-4">
                            <div class="col-xl-6 px-0">
                                <div class="input-group  input-group mb-3">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input v-model="filter.molecula" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #f9e79f" placeholder="Nombre / Molécula/ Comercial/ Marca" />
                                </div>
                            </div>

                            <div class="col-xl-2 px-0 px-xl-1 ">
                                <div class=" col-xl-2 input-group input-group mb-3 ">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input v-model="filter.proveedor" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #aed6f1" placeholder="PROVEEDOR" />
                                </div>

                            </div>
                            <div class="col-xl-2 px-0">
                                <div class="input-group input-group mb-3 pe-xl-1 ">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <input v-model="filter.ruc" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #abebc6" placeholder="RUC" />
                                </div>

                            </div>
                            <div class="col-xl-2 px-0">
                                <div class="input-group input-group mb-3">
                                    <span class="input-group-text text-white bg-main " @click="mtdChangeFilterOC()"><i class="fas fa-search"></i></span>
                                    <input v-model="filter_oc_id" type="text" @keyup.enter="mtdChangeFilterOC()" class="form-control form-control" style="background-color: #f5b7b1" placeholder="O.C." />
                                </div>
                            </div>
                        </div>
                        <div class="cuerpo">

                            <OC v-if="page == 'oc'" :filter_oc="filter_oc_id" />
                            <Medicamento v-if="page == 'medicamento'" :medicaments="Medicaments" @mtdAddItemCarrito="mtdAddItemCarrito" :ordendecompra="ordendecompra" @mtdviewcarrito="mtdOpenModalCarrito" @registro-exitoso="manejarRegistroExitoso" />
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>

    <cModalViewCarritoOrdenCompraVue :numcarrito="numcarrito" :title="modalCarrito.title" :boo_modal="modalCarrito.modal_form" @closeModalCarrito="closeModalCarrito" :ordendecompra="ordendecompra" @registro-exitoso="manejarRegistroExitoso" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import OC from "@/components/Logistica/Pages/Ordendecompra/Ocpendiente.vue";
import Medicamento from "@/components/Logistica/Pages/Ordendecompra/Medicamento.vue";
import cModalViewCarritoOrdenCompraVue from "../modals/cModalViewCarritoOrdenCompra.vue";
export default {
    name: "c-logistica-pages-ordendecompra",
    data() {
        return {
            filter: {
                molecula: "",
                proveedor: "",
                ruc: "",
            },
            filter_oc_id: "",
            page: "oc",
            Medicaments: [],
            modalCarrito: {
                title: '',
                modal_form: false
            },
            ordendecompra: [],
            itemOrdendeCompra: {
                cabecera: {},
                detalle: []
            },
            cargado_edit: '',
            numcarrito :null

        }
    },
    components: {
        OC,
        Medicamento,
        cModalViewCarritoOrdenCompraVue,
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            if (this.page == "medicamento") {
                Swal.fire({
                    icon: "warning",
                    title: "SI SALE SE PERDERA LOS ITEMS, SI A AGREGADO EN EL CARRITO DE COMPRAS",
                    width: "400px",
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit("returnHome");
                    }
                });
            } else {
                this.$emit("returnHome");
            }

        },
        mtdChangeFilter() {
            this.page = "medicamento";
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/oc/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.Medicaments = response.data;
                    this.mtdHide();

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdHide() {
            this.filter = {
                molecula: "",
                proveedor: "",
                ruc: "",
            }
        },
        mtdChangeFilterOC() {
            this.page = "oc";
        },
        mtdOpenModalCarrito: function () {
            this.modalCarrito.modal_form = true;
            this.modalCarrito.title = "ORDENES DE COMPRA ";
        },
        mtdAddItemCarrito: function (payload) {

            let existeProveedor = this.ordendecompra.some(item => item.cabecera.id === payload.proveedor.id);

            if (!existeProveedor) {
                let nuevoItemOrdenCompra = {
                    cabecera: {
                        id: payload.proveedor.id,
                        proveedor_name: payload.proveedor.name,
                        observation: '',
                        send: '',
                        moneda: 'SOLES'
                    },
                    detalle: []
                };
                this.ordendecompra.push(nuevoItemOrdenCompra);
            }

            if (payload.cargado === null) {
                this.cargado_edit = 1;
            } else {
                this.cargado_edit = payload.cargado;
            }

            let itemDetalle = {
                id: payload.id,
                name:payload.name,
                molecule: payload.molecule,
                commercial: payload.commercial,
                brand: payload.brand,
                presentation: payload.presentation,
                concentration: payload.concentration,
                units: payload.units,
                precio_purchase: payload.proveedor.price_purchase,
                precio_unitario:payload.proveedor.price_purchase,
                modelo:payload.modelo,
                serie:payload.serie,
                quantity: 1,
                discount: null,
                price_discount: null,
                price_purchase: 0,
                grabado: true,
                cargado_state: payload.cargado,
                cargado: this.cargado_edit,
                utilidad: payload.utilidad,
                unit: payload.units[0].id
            };

            let ordenCompraProveedor = this.ordendecompra.find(item => item.cabecera.id === payload.proveedor.id);

            let itemExistente = ordenCompraProveedor.detalle.find(detalle => detalle.id === payload.id);
            if (!itemExistente) {
                ordenCompraProveedor.detalle.push(itemDetalle);
                this.numcarrito =this.ordendecompra.length;
                this.mtdOpenModalCarrito();
            } else {
                swal.fire({
                    title: "Producto Ya Agregado",
                    text: "El producto que intenta agregar ya se encuentra en la orden de compra.",
                    icon: "warning",
                    button: "Aceptar",
                });
            }    
        },
        closeModalCarrito: function () {
            this.modalCarrito.modal_form = false;
            this.modalCarrito.title = "";
            if(this.ordendecompra.length == 0){
                this.numcarrito =null
            }
        },
        manejarRegistroExitoso() {
            this.ordendecompra = [];
            this.itemOrdemCompra = {
                cabecera: {},
                detalle: [],
            };
            this.closeModalCarrito();
            this.mtdChangeFilterOC()
        },

    }
}
</script>

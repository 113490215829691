<template>
    <div>
      <div class="container text-center mt-2">
        <div class="d-flex title-option">
          <div class="btn-home mt-3 mb-2 ms-auto">
            <button
              type="button"
              class="btn btn-outline-main"
              @click="returnHome()"
            >
              <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
          </div>
        </div>
        <div id="div-modal-anular" class="w-100">
          <div id="div-anular" class="card w-100">
            <div class="card-header text-white h4 bg-main text-center">
              <strong>SEGUIMIENTO A SOLICITUDES</strong>
            </div>
            <div class="card-body w-100">
              <div class="row">
                <div class="col-md-12 py-2">
                  <div class="row d-flex">
                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-2">
                      <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-3">Desde</span>
                        <input
                          type="date"
                          aria-label="shift_date"
                          class="form-control form-control-sm"
                          id="init"
                          v-model="filter.init"
                          @change="mtdGetData"
                        />
                      </div>
                    </div>
                    <div
                      class="col-xl-6 col-lg-12 col-md-12 col-sm-12 my-sm-2 my-xl-0"
                    >
                      <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-3">Hasta</span>
                        <input
                          type="date"
                          aria-label="shift_date"
                          class="form-control form-control-sm"
                          id="end"
                          v-model="filter.end"
                          @change="mtdGetData"
                        />
                      </div>
                    </div>
                    <!--
                                  <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 text-center">
                                      <div class="input-group input-group-sm text-center w-100">
                                          <button
                                          class="btn btn-sm w-100 text-white w-100"
                                          style="background: #900052"
                                          @click="mtdGetData"
                                          >
                                          <i class="fas fa-search"></i>&nbsp;Buscar
                                          </button>
                                      </div>
                                  </div>
                                  !-->
                  </div>
                </div>
                <div class="col-md-12 py-2">
                  <div class="row d-flex">
                    <div class="col-lg-12 col-md-12">
                      <div class="input-group input-group mb-2">
                        <span class="input-group-text text-white bg-main"
                          ><i class="fas fa-search"></i
                        ></span>
                        <input
                          v-model="search"
                          type="text"
                          class="form-control form-control"
                          placeholder="Buscar Paciente por DNI o Nombre"
                          @input="mtdSearchFilter"
                        />
                      </div>
                    </div>
                  </div>
                </div>
  
                <!--tabla-->
                <div
                  class="col-md-12"
                  id="content-timelinetable"
                  v-if="cpData.length > 0"
                >
                  <div>
                    <table id="tb-anular" class="table table-borderless">
                      <caption class="py-0 my-0"></caption>
                      <thead>
                        <tr class="text-white table-th text-center">
                          <th
                            scope="col"
                            colspan="1"
                            class="align-middle border border-5 bg-main border-white"
                          >
                            N°
                          </th>
                          
                          <th
                            scope="col"
                            colspan="1"
                            class="align-middle border border-5 bg-main border-white"
                          >
                            PACIENTE
                          </th>
                          <th
                            scope="col"
                            colspan="1"
                            class="align-middle border border-5 bg-main border-white"
                          >
                            DOCUMENTO
                          </th>
                          <th
                            scope="col"
                            colspan="1"
                            class="align-middle border border-5 bg-main border-white"
                          >
                            FECHA
                          </th>
                          
                          
                          <th
                            scope="col"
                            colspan="1"
                            class="align-middle border border-5 bg-main border-white"
                          >
                            SERVICIO
                          </th>
                          <th
                            scope="col"
                            colspan="1"
                            class="align-middle border border-5 bg-main border-white"
                          >
                            N° de  Llamadas
                          </th>
                          <th
                            scope="col"
                            colspan="1"
                            class="align-middle border border-5 bg-main border-white"
                          >
                            MEDIO DE CAPTACIÓN
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="text-xs table-body"
                          v-for="(item, index) in cpData"
                          :key="index"
                        >
                          <td
                            class="border border-5 border-white align-left align-middle"
                          >
                            {{ index + 1 }}
                          </td>
                         
                        
  
                          <td
                            class="border border-5 border-white align-left align-middle"
                          >
                            {{ item.of_patient_name }}
                          </td>
                          <td
                            class="border border-5 border-white align-left align-middle"
                          >
                            {{ item.of_patient_document }}
                          </td>
                          <td
                            class="border border-5 border-white align-left align-middle"
                          >
                          {{ item.created_at | formatDate }}
                          </td>
                          <td
                            class="border border-5 border-white align-left align-middle"
                          >
                            {{ item.of_product_name }}
                          </td>
                        
                          <td
                            class="text-center border border-5 border-white align-middle"
                          >
                            <div class="d-flex align-items-start">
                              
                              &nbsp;
                              <button
                                class="btn btn-sm btn-outline-success mt-2 space"
                                @click="mtdSeeCall(item)"
                              >
                              {{ item.phones.length}}-
                                   Ver Llamadas
                              </button>
                             
                            </div>
                          </td>
                          <td
                            class="border border-5 border-white align-left align-middle"
                          >
                            {{ item.medioc}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!--pag-->
                <div
                  v-if="cpData.length > 0"
                  class="d-flex justify-content-center px-1 mb-4"
                >
                  <nav aria-label="Page navigation example">
                    <ul class="pagination mb-1">
                      <li class="page-item">
                        <a
                          class="page-link page"
                          href="javascript:void(0)"
                          @click="backPage"
                          >Anterior</a
                        >
                      </li>
                      <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a
                          :class="
                            selectPage === 1 ? 'page-link active' : 'page-link'
                          "
                          href="javascript:void(0)"
                          @click="selectedPage(1)"
                          >1</a
                        >
                      </li>
                      <li
                        v-if="limitedStepPagination[0] > 2"
                        class="page-item disabled"
                      >
                        <span class="page-link">...</span>
                      </li>
  
                      <li
                        v-for="(item, index) in limitedStepPagination"
                        :key="index"
                        class="page-item"
                      >
                        <a
                          :class="
                            selectPage === item ? 'page-link active' : 'page-link'
                          "
                          href="javascript:void(0)"
                          @click="selectedPage(item)"
                          >{{ item }}
                        </a>
                      </li>
                      <li
                        v-if="
                          limitedStepPagination[
                            limitedStepPagination.length - 1
                          ] <
                          stepPagination.length - 1
                        "
                        class="page-item disabled"
                      >
                        <span class="page-link">...</span>
                      </li>
                      <li
                        v-if="
                          limitedStepPagination[
                            limitedStepPagination.length - 1
                          ] !== stepPagination.length
                        "
                        class="page-item"
                      >
                        <a
                          :class="
                            selectPage === limitedStepPagination.length + 2
                              ? 'page-link active'
                              : 'page-link'
                          "
                          href="javascript:void(0)"
                          @click="selectedPage(stepPagination.length)"
                          >{{ stepPagination.length }}
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link page"
                          href="javascript:void(0)"
                          @click="nextPage"
                          >Siguiente</a
                        >
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <cModalSeeCallVue
        :title="modalSeeCall.title"
        :boo_modal="modalSeeCall.modal_form"
        :data = modalSeeCall.data
        @closeModalSeeCall="closeModalSeeCall"
      />
      <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
    
    <script>
  import { mapActions } from "vuex";
  import cModalSeeCallVue from "@/components/Gerencia/modal/cModalSeeCall.vue";
  import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
  import moment from "moment";
  
  export default {
    name: "c-call-citas",
    components: {
      SweetAlert,
      cModalSeeCallVue,
    },
    data() {
      return {
        swal: null,
        search: "",
        paDoc: "",
        filter: {
          init: "",
          end: "",
        },
        seguiRe: [],
        dataShowTable: [],
        dataShow: [],
        page: 0,
        selectPage: 1,
        stepPagination: [],
        totalPagesToShow: 4,
        Documents: {},
        modalAddCall: {
          title: "AGREGAR LLAMADA",
          item: [],
          modal_from: false,
          pos: null,
        },
        modalSeeCall: {
          title: "VER LLAMADA",
          data: [],
          modal_from: false,       
        },
        estados: {
          1: "REGISTRADO",
          2: "RESERVADO SIN PAGO",
          3: "RESERVADO PAGADO",
          4: "CHECKIN",
          5: "ALTA|CHECKOUT",
        },
      };
    },
    props: {
      stateRolAdmin: Boolean,
    },
    created() {
      moment.locale("es");
      this.filter.end =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
      this.filter.init = this.filter.end;
  
      this.mtdGetData();
    },
    watch: {
      selectPage() {
          this.calculateData(7);
      },
      paDoc(newVal) {
          if (newVal == "") {
              this.mtdSearchDocument();
          }
      },
    },
    computed: {
      cpData() {
          return this.dataShow;
      },

      limitedStepPagination() {
          const totalPages = this.stepPagination.length;
          const currentPage = this.selectPage;

          let startPage = 1;
          let endPage = totalPages;
          if (totalPages > this.totalPagesToShow) {
              const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

              if (currentPage > halfPagesToShow) {
                  startPage = currentPage - halfPagesToShow;
                  endPage = currentPage + halfPagesToShow - 1;

                  if (endPage > totalPages) {
                      endPage = totalPages;
                      startPage = totalPages - this.totalPagesToShow + 1;
                  }
              } else {
                  endPage = this.totalPagesToShow;
              }
          }

          return Array.from({
                  length: endPage - startPage + 1,
              },
              (_, i) => startPage + i
          );
      },
    },
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdGetData: function () {
        this.post({
          url: this.$store.getters.get__url + "/Gerencia/follow",
          token: this.$store.getters.get__token,
          params: {
            Campus: this.$store.getters.get__campus,
            init: this.filter.init,
            end: this.filter.end,
          },
        })
          .then((response) => {
            this.seguiRe = response.data;
            this.calculateData(7);
            //this.mtdSearchFilter();
          })
          .catch((error) => {
            console.log(error);
          });
      },
  
      calculateData(items) {
          if (!this.seguiRe || !this.seguiRe.length) {
              return (this.dataShow = []);
          }
          let filteredData = this.seguiRe;
          let indexInitial = this.selectPage;
          if (this.search.length >= 2 && this.search != "") {
              let query = this.search.toString().toUpperCase();
              filteredData = this.seguiRe.filter((element) => {
                  if (element.of_patient_name !== null) {
                      let name = element.of_patient_name.toString().toUpperCase();
                      let document = element.of_patient_document.toString();
                      return name.includes(query) || document.includes(query);
                  }
              });
              indexInitial = 1;
          }

          let totalPages = Math.ceil(filteredData.length / items);
          this.stepPagination = Array.from({
                  length: totalPages,
              },
              (_, index) => index + 1
          );
          let startIndex = (indexInitial - 1) * items;
          let endIndex = startIndex + (items - 1);
          this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      },
  
      /*
          mtdSearchFiltere() {
              if (!this.seguiRe) {
                  // Si seguiRe no está definido, no podemos filtrar, entonces mostramos todos los datos
                  this.dataShow = [];
                  return;
              }
              if (this.search.length < 2 || this.search.trim() === '') {
                  // Si el campo de búsqueda está vacío, mostrar todos los datos
                  this.dataShow = this.seguiRe;
              } else {
                  // Filtrar los datos basados en el término de búsqueda
                  const searchTerm = this.search.trim().toLowerCase();
                  this.dataShow = this.seguiRe.filter(item => {
                      const patientName = item.of_patient_name.toLowerCase();
                      const patientDocument = item.of_patient_document.toLowerCase();
                      return patientName.includes(searchTerm) || patientDocument === searchTerm;
                  });
              }
          },
          */
  
      mtdSearchFilter() {
        this.calculateData(7);
      },
  
      mtdGetStyle: function (option) {
        if (option == this.page) return "background: #900052";
        return "";
      },
      mtdGetStyleSpan: function (option) {
        if (option == this.page) return "color:white";
        return "";
      },
      returnHome() {
        this.$emit("mtdChangePage", "home");
      },
      /** agregar llamadas */
      mtdAddCall: function (item) {
        // console.log(item);
        this.modalAddCall = {
          title: "AGREGAR LLAMADA",
          modal_form: true,
          idReservation: item.id,
        };
      },
      closeModalAddCall: function () {
        this.modalAddCall = {
          title: "AGREGAR LLAMADA",
          modal_form: false,
          idReservation: 0,
        };
      },
      mtdCommitCall: function (payload) {
        this.post({
          url: this.$store.getters.get__url + "/Call/follow/store",
          token: this.$store.getters.get__token,
          params: {
            of_reservation_id: this.modalAddCall.idReservation,
            comment: payload,
          },
        })
          .then((response) => {
            if (response.error == false) {
              this.$refs.SweetAlert.showSuccess(response.message);
              this.closeModalAddCall();
            } else {
              this.$refs.SweetAlert.showError(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$refs.SweetAlert.showError(response.message);
          });
      },
      /** ver llamadas */
      mtdSeeCall: function (item) {
        this.get({
          url: this.$store.getters.get__url + "/Call/follow/getcall/" + item.id,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.length > 0) {
              this.modalSeeCall = {
                title: "VER LLAMADA",
                modal_form: true,
                data: response.data
              };
            } else {
              this.$refs.SweetAlert.showWarning("Aun no tiene llamadas.");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$refs.SweetAlert.showError(response.message);
          });
      },
      closeModalSeeCall: function () {
        this.modalSeeCall = {
          title: "VER LLAMADA",    
          modal_form: false,
          data: []
        };
      },
      /** finalizar llamadas */
      finishReservation: function(item){
        this.$refs.SweetAlert.showConfirmSimple2(
          "",
          "¿Desea finalizar esta reserva?",
          "warning",
          "Si, Confirmar!"
        ).then((result) => {
          if (result.value) {
            this.mtdCommitFinishReservation(item.id);
          }
        });
      },
      mtdCommitFinishReservation: function(id){
        this.get({
          url: this.$store.getters.get__url + "/Call/follow/finish/" + id,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.error ==false) {
              this.mtdGetData();
              this.$refs.SweetAlert.showSuccess(response.message);
            } else {
              this.$refs.SweetAlert.showWarning(response.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$refs.SweetAlert.showError(response.message);
          });
      },
  
  
      confirmSet(index) {
        this.$refs.SweetAlert.showConfirmSimple2(
          "",
          "Confirmar CheckIn",
          "warning",
          "Si, Confirmar!"
        ).then((result) => {
          if (result.value) {
            this.confirmSetDate(index);
          }
        });
      },
      confirmSetDate(index) {
        //const item = this.seguiRe[index];
        this.post({
          url: this.$store.getters.get__url + "/Call/editStateRe",
          token: this.$store.getters.get__token,
          params: {
            //id: item.id,
            id:index
            //checkind: item.checkind
          },
        })
          .then((response) => {
            if (response.state == 1) {
              this.$refs.SweetAlert.showError("Error");
            } else {
              this.$refs.SweetAlert.showSuccess(response.message);
              this.mtdGetData();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
      mtdSearchDocument: function () {
        if (this.paDoc !== "") {
          this.post({
            url: this.$store.getters.get__url + "/Call/followDoc",
            token: this.$store.getters.get__token,
            params: {
              Campus: this.$store.getters.get__campus,
              paDoc: this.paDoc,
              init: this.filter.init,
              end: this.filter.end,
            },
          })
            .then((response) => {
              this.seguiRe = response.data;
              if (this.seguiRe.length === 0 || this.paDoc == "") {
                this.$refs.SweetAlert.showWarning(
                  "No hay Paciente con esos datos"
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.mtdGetData();
        }
      },
  
      /** paginación */
      backPage() {
        this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
      },
      nextPage() {
        this.selectPage =
          this.selectPage < this.stepPagination.length
            ? this.selectPage + 1
            : this.stepPagination.length;
      },
      selectedPage(page) {
        this.selectPage = page;
      },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
  };
  </script>
  
  <style scoped>
  .table-th > th {
    border-radius: 15px;
  }
  .table-body > td {
    background: #eaecee;
    border-radius: 15px;
  }
  
  .align {
    display: block;
    text-align: left;
  }
  
  .input-width {
    width: 130px;
  }
  
  .space {
    white-space: nowrap;
  }
  </style>
<template>
<div>
    <div class="px-4 mb-3">

        <div  class="d-flex justify-content-between px-1 ">
            <h6 class="mt-2">
                <i class="fas fa-cog"></i> ORDENES DE SERVICIOS - PENDIENTES
            </h6>
            <div v-if="dataservice.length > 0 && cpData.length > 0">
                <nav aria-label="Page navigation example ">
                    <ul class="pagination mb-0 ">
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                        </li>
                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                        </li>
                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>

                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                            </a>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>

        </div>

        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <p id="mydesc"></p>
            <table id="content-timelinetable " class="table table-bordered" aria-describedby="mydesc">
                <caption class="py-0 my-0"></caption>
                <thead class="bg-main text-xxxs">
                    <tr class="text-white text-center">
                        <th scope="col" class="align-middle">F. ORDEN COMPRA</th>
                        <th scope="col" class="align-middle">ORDEN COMPRA</th>
                        <th scope="col" class="align-middle">PROVEEDOR</th>
                        <th scope="col" class="align-middle">RUC</th>
                        <th scope="col" class="align-middle">N° FACTURA</th>
                        <th scope="col" class="align-middle">F. FACTURA</th>
                        <th scope="col" class="align-middle">S/ EXONERADO</th>
                        <th scope="col" class="align-middle">S/ INAFECTO</th>
                        <th scope="col" class="align-middle">MONEDA</th>
                        <th scope="col" class="align-middle">DETRACCION</th>
                        <!-- <th scope="col" class="align-middle">NO GRAVADA</th> -->
                        <th scope="col" class="align-middle">S/ VALOR DE VENTA</th>
                        <th scope="col" class="align-middle">S/ IGV</th>
                        <th scope="col" colspan="1" class="align-middle">S/ TOTAL CON IGV</th>
                        <th scope="col" class="align-middle">S/ TOTAL FACTURA</th>
                        <th scope="col" class="align-middle">% DETRACCION</th>
                        <th scope="col" class="align-middle">MONTO DETRACCION</th>
                        <th scope="col" class="align-middle">S/TOTAL NETO</th>
                        <th scope="col" class="align-middle">S/ PAGADO FACTURA</th>
                        <th scope="col" colspan="1" class="align-middle">S/ PENDIENTE FACTURA</th>
                        <th scope="col" class="align-middle">S/ PAGADO DETRACCION</th>
                        <th scope="col" colspan="1" class="align-middle">S/ PENDIENTE DETRACCION</th>
                        <th scope="col" colspan="1" class="align-middle">SERVICIOS</th>
                    </tr>
                </thead>
                <tbody class="border-main text-xxs">
                    <tr class="tbody-table" v-for="(items, index) in cpData" :key="index">
                        <td>
                            {{ items.date }}
                        </td>
                        <td>
                            {{ mtdGenerateCode(items.id) }}
                        </td>
                        <td>
                            {{ items.provider_name }}
                        </td>
                        <td>
                            {{ items.provider_ruc }}
                        </td>
                        <td @click="mtdOutFocusInput(index, items.id)">
                            <input id="input-factura" type="text" class="form-control form-control-sm input-cantidad" :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" style="text-align: center" placeholder="N° de Factura" v-if="items.number == null" v-model="bill[index].number" />
                            <span v-else>
                                {{ items.number }}
                            </span>
                        </td>
                        <td @click="mtdOutFocusInput(index)">
                            <input id="input-f-factura" type="date" class="form-control form-control-sm input-cantidad" :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" style="text-align: center" v-if="items.bill_date == null" v-model="bill[index].date" />
                            <span v-else>
                                {{ items.bill_date }}
                            </span>
                        </td>
                        <td>
                            {{ items.exonerated }}
                        </td>
                        <td>
                            {{ items.unaffected }}
                        </td>
                        <td @click="mtdOutFocusInput(index)">
                            <select id="select-moneda" class="form-control form-control-sm" :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" style="text-align: center" v-if="items.money === null">
                                <option value="soles" :selected="
                             posEditnumber === index && bill[index].money === 'soles'
                           ">
                                    Soles
                                </option>
                                <option value="dolares" :selected="
                             posEditnumber === index && bill[index].money === 'dolares'
                           ">
                                    Dólares
                                </option>
                            </select>
                            <span v-else>
                                {{ items.money }}
                            </span>
                        </td>
                        <td @click="mtdOutFocusInput(index)">
                            <select id="select-remision" class="form-control form-control-sm" :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" style="text-align: center" v-if="items.detraction == null" v-model="bill[index].detraction">
                                <option value="0">
                                    No aplica
                                </option>
                                <option value="1">
                                    Aplica
                                </option>
                            </select>
                            <span v-else>
                                {{ items.detraction }}
                            </span>
                        </td>
                        <!-- <td>{{ items.not_engraved }}</td> -->
                        <td>{{ items.total_sin_IGV }}</td>
                        <td>{{ mtdGetIgv(index) }}</td>
                        <td>{{ items.total_IGV }}</td>
                        <!-- <td>{{ items.shipping_cost == null? '0.00' : items.shipping_cost }}</td> -->

                        <td @click="mtdOutFocusInput(index)">
                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" @input="validateAmount" :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" style="text-align: center" placeholder="0.00" v-if="items.bill_total == null" v-model="bill[index].total" />
                            <span v-else>
                                {{ items.bill_total }}
                            </span>
                            <div v-if="!isValidInput[index]" class="error-message">
                                Monto no permitido
                            </div>
                        </td>
                        <!--Habilitar si ha seleccionado detraccion--->
                        <td @click="mtdOutFocusInput(index)">
                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" @keypress="isNumber" :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" @input="mtdCalculateDetraccion(index)" style="text-align: center" placeholder="0" v-if="items.bill_id == null && bill[index].detraction == 1" v-model="bill[index].detraction_percentage" />
                            <span v-else>
                                {{ items.detraction_percentage == null ? '0' : items.detraction_percentage }}
                            </span>
                        </td>
                        <!--Habilitar si ha seleccionado detraccion monto--->
                        <td @click="mtdOutFocusInput(index)">
                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" @keypress="isNumber" :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" style="text-align: center" placeholder="0" v-if="items.bill_id == null && bill[index].detraction == 1" v-model="bill[index].detraction_amount" />
                            <span v-else>
                                {{ items.detraction_amount == null ? '0.00' : items.detraction_amount }}
                            </span>
                        </td>
                        <!---total neto-->
                        <td>
                            <input id="input-cantidad" type="text" class="form-control form-control-sm input-cantidad" disabled :class="{
                           'input-with-opacity':
                             isButtonDisabled && posEditnumber !== index,
                         }" style="text-align: center" placeholder="0" v-if="items.bill_id == null && bill[index].detraction == 1" v-model="bill[index].net_total" />
                            <span v-else>
                                {{ items.net_total == null ? '0.00' : items.net_total }}
                            </span>
                        </td>
                        <!---paid total factura-->
                        <td>
                            {{ items.paid == null ? '-' :  items.paid }}
                        </td>
                        <td>
                            {{ items.pending == null ? '-' : items.pending }}
                        </td>
                        <td>
                            {{ items.detraction_paid == null ? '-' :  items.detraction_paid }}
                        </td>
                        <td>
                            {{ items.detraction_pending == null ? '-' : items.detraction_pending }}
                        </td>
                        <td class="align-middle d-flex justify-content-center">
                            <a class="acciones text-center px-2 py-2" v-if="dataShow[index].bill_id == null" href="#" @click="openModal(items.id, index)" :class="{ disabledbutton: !isValidInput[index] }">
                                <i class="fas fa-plus text-white"></i>
                            </a>
                            <a class="acciones-3-s bg-info text-center  py-2" href="# " @click="viewservice(items)" style="margin-left: 2px">
                                <i class="fas fa-eye text-white"></i>
                            </a>
                            <!-- <a class="acciones-2 text-center px-2 py-2" href="#" @click="openModalViewDispatch(items.bill_id, index)" style="margin-left: 2px">
                                       <i class="fas fa-dolly text-white"></i>
                                   </a> -->
                            <a class="acciones-3 text-center px-2 py-2" href="# " @click="viewfactura(items.id)" style="margin-left: 2px">
                                <i class="fas fa-file-pdf text-white"></i>
                            </a>
                            <!-- <a class="acciones-4 text-center px-2 py-2" href="#" @click="openModalPay(index)" style="margin-left: 2px">
                                    <i class="fas fa-dollar-sign text-white"></i>
                                </a> -->
                            <a class="acciones-5 text-center px-2 py-2 bg-warning" v-if="showButton(items)" href="#" @click="openModalStoreBill(items,index)" style="margin-left: 2px">
                                <i class="fas fa-file-invoice-dollar text-white"></i>
                            </a>

                        </td>
                    </tr>
                    <tr v-if="!cpData.length > 0" class="text-center">
                        <th scope="col" colspan="17">NO SE ENCONTRARON RESULTADOS</th>
                    </tr>

                </tbody>

            </table>

        </div>
    </div>
    <div class="d-flex justify-content-end mt-5">
        <button class="d-flex justify-content-end bg-main border-0 text-white px-5 py-1">
            NOTIFICAR
        </button>
    </div>
    <cModaladdPay :title="modalPay.title" :boo_modal="modalPay.modal_form" @mtdcloseModalPay="mtdcloseModalPay" :billpay="billpay" :dataBillPayment="dataBillPayment" />
    <cModalGenerarFactura :title="modalStoreBill.title" :boo_modal="modalStoreBill.modal_form" @closeModalStoreBill="closeModalStoreBill" @mtdShowModalStoreDispatch="mtdShowModalStoreDispatch" :purcharseOrder="purcharseOrder" />
    <cModalViewServicesVue :dataservicedetails="dataservicedetails" :title="modalService.title" :boo_modal="modalService.modal_form" @closeModalService="closeModalService" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModaladdPay from "../../modals/cModaladdPayOs.vue";
import cModalGenerarFactura from "../../modals/cModalGenerarFacturaOs.vue";
import cModalViewServicesVue from "../../modals/cModalViewServices.vue";
export default {
    name: "c-logistica-pages-ordendeservicio-ospendiente",
    components: {
        cModaladdPay,
        cModalGenerarFactura,
        cModalViewServicesVue,
    },
    data() {
        return {
            dataShow: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            search: "",
            dataservice: [],
            modalStoreDispatch: {
                title: "",
                modal_form: false,
                cod_purchase_order: "",
                bill_id: null
            },
            modalViewDispatch: {
                title: "",
                modal_form: false,
            },
            modalPay: {
                title: "",
                modal_form: false,
            },
            modalStoreBill: {
                title: "",
                modal_form: false,
            },
            dataordendetalle: [],
            dataDispatches: [],
            dataBillPayment: [],
            cabecera: {
                name: "",
                id: null,
                ruc: "",
                total_IGV: 0,
                total_sin_IGV: 0,
                not_engraved: 0,
                activeButtonFormBill: false,
                observation: "",
            },
            bill: [],
            isButtonDisabled: false,
            posEditnumber: null,
            dataBillDetailEdit: [],
            filter_id: "",
            billpay: {
                bill_id: null,
                paid: "",
                pending: "",
                total: "",
            },
            purcharseOrder: {
                id: "",
                number: "",
                date: "",
                provider: "",
                provider_id: "",
                total: "",
                pending: "",
            },
            dataservicedetails: [],
            modalService: {
                title: "",
                modal_form: false,
            },
            isValidInput: [],
        };
    },
    props: {
        filter_os: String,
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },
        filter_os(newValue, oldValue) {
            this.filter_id = newValue;
            this.mtdGetData();
        },
        dataShow: {
            immediate: true, // Para ejecutar el watcher inmediatamente al inicio
            handler(newArrayDetalle) {
                this.isValidInput = Array(newArrayDetalle.length).fill(true);
            }
        }
    },

    created() {
        this.mtdGetData();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            if (this.filter_id) {
                this.post({
                        url: this.$store.getters.get__url + "/Logistica/os/filterByOsPendientes",
                        token: this.$store.getters.get__token,
                        params: {
                            filter_id: this.filter_id,
                        },
                    })
                    .then((response) => {
                        this.dataservice = response.data;
                        this.calculateData(6)
                        this.mtdHide();
                    })
                    .catch((errors) => {});
            } else {
                this.get({
                        url: this.$store.getters.get__url + "/Logistica/os/indexOsPendienteFactura",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.dataservice = response.data;
                        this.calculateData(6)

                    })
                    .catch((errors) => {});
            }
        },
        mtdOutFocusInput(index, id) {
            if (this.posEditnumber !== index && this.posEditnumber !== null) {
                Swal.fire({
                    title: "Desea cambiar de fila?",
                    text: "Se borrarán los datos registrados!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, cambiar!",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.posEditnumber = index;
                    }
                });
            } else {
                this.posEditnumber = index;
            }

            this.isButtonDisabled = true;
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        showButton(item) {
            let total = 0.00;
            let subtotal = 0.00;
            this.dataservice.forEach(element => {
                if (element.id == item.id) {
                    subtotal = parseFloat(parseFloat(element.net_total) + parseFloat(element.detraction_amount)).toFixed(2);
                    total += subtotal;
                }

            })
            return item.bill_id && parseFloat(total) !== parseFloat(item.total);
        },
        mtdInsertBill: function (id, index) {
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/os/storeBill",
                    token: this.$store.getters.get__token,
                    params: this.bill[index],

                })
                .then((response) => {
                    if (response.state == 0) {
                        Swal.fire({
                            icon: "success",
                            title: "Factura registrada",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                        this.mtdGetData()

                    }
                })
                .catch((errors) => {});
        },
        mtdShowModalStoreDispatch: function () {
            this.closeModalStoreBill();
            this.mtdGetData();
        },
        mtdShowBillPayment(bill_id) {
            this.get({
                    url: this.$store.getters.get__url + "/Logistica/os/showPayBill/" + bill_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataBillPayment = response.data;
                })
                .catch((errors) => {});
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");

            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }

            const decimalIndex = sanitizedValue.indexOf(".");
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(
                 0,
                 decimalIndex
               )}.${decimalPart.substring(0, 2)}`;
            }
            //validar que no sea mayor que el total
            const ocTotal = parseFloat(this.dataShow[this.posEditnumber].total).toFixed(2);
            const billTotal = parseFloat(sanitizedValue);
            let amount = parseFloat(sanitizedValue).toFixed(2);
            let validation = (amount > 0.00) ? billTotal > ocTotal : true
            if (validation == false) {
                this.bill[this.posEditnumber].total = sanitizedValue;
                this.isValidInput[this.posEditnumber] = true;

            } else {
                this.isValidInput[this.posEditnumber] = false;
            }

        },

        openModal: function (id, index) {
            //verificar si no hay campos nulos
            if (
                this.bill[index].number !== null &&
                this.bill[index].money !== null &&
                this.bill[index].date !== null &&
                this.bill[index].total !== null &&
                this.bill[index].detraction !== null
            ) {
                if (this.posEditnumber !== index) {
                    Swal.fire({
                        icon: "error",
                        title: "El boton no pertenece a la fila",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    this.bill[index].purchase_service_id = id;
                    Swal.fire({
                        title: "¿Desea registrar la factura?",
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Si, guardar",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.mtdInsertBill(id, index);
                        }
                    });
                }
            } else {
                if (this.posEditnumber === null) {
                    Swal.fire({
                        icon: "error",
                        title: "No hay factura registrada",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Debe registrar todo los campos",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
            }

        },
        //    openModalViewDispatch: function (bill_id, index) {
        //        if (this.dataShow[index].bill_id !== null) {
        //            const type = this.dataShow[index].number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
        //            const title = "DESPACHOS DE LA " + type + " " + this.dataShow[index].number;
        //            this.mtdGetDispatches(bill_id, title);
        //        } else {
        //            Swal.fire({
        //                icon: "error",
        //                title: "No hay factura registrada",
        //                showConfirmButton: false,
        //                timer: 1500,
        //            });
        //        }
        //    },
        openModalPay: function (index) {
            if (this.dataShow[index].bill_id !== null) {
                const type = this.dataShow[index].number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
                const title = "GENERAR PAGO A LA " + type + " " + this.dataShow[index].number;
                this.billpay = {
                    bill_id: this.dataShow[index].bill_id,
                    paid: this.dataShow[index].paid,
                    pending: this.dataShow[index].pending,
                    total: this.dataShow[index].bill_total,
                };
                this.mtdShowBillPayment(this.dataShow[index].bill_id);
                this.modalPay = {
                    title: title,
                    modal_form: true,
                };
            } else {
                Swal.fire({
                    icon: "error",
                    title: "No hay factura registrada",
                    showConfirmButton: false,
                    timer: 1500,
                });

                this.modalPay = {
                    title: "",
                    modal_form: false,
                };
            }
        },
        mtdCalculateDetraccion: function (index) {
            let total = this.bill[index].total;
            let percentage = this.bill[index].detraction_percentage;
            let d_amount = parseFloat(total * (percentage / 100)).toFixed(2);
            let neto = total - d_amount;
            //this.dataservicedetails[index].detraction_amount = d_amount.toFixed(2);
            this.$set(this.bill[index], 'detraction_amount', d_amount);
            this.$set(this.bill[index], 'net_total', parseFloat(neto).toFixed(2));
        },
        openModalStoreBill: function (oc, index) {
            this.purcharseOrder = {
                id: oc.id,
                index: index,
                number: this.mtdGenerateCode(oc.id),
                date: oc.date,
                provider: oc.provider_name,
                provider_id: oc.provider_id,
                ruc: oc.provider_ruc,
                total: oc.total_IGV,
                pending: oc.pending,
            }
            this.modalStoreBill = {
                title: "Agregar Factura",
                modal_form: true,
            };
        },
        closeModalStoreBill: function () {
            this.modalStoreBill = {
                title: "",
                modal_form: false,
            };
        },
        //    mtdGetProducts: function (id) {
        //        this.get({
        //                url: this.$store.getters.get__url + "/Logistica/oc/getProductsProvider/" + id,
        //                token: this.$store.getters.get__token,
        //            })
        //            .then((response) => {
        //                this.dataordendetalle = response.data;
        //                this.dataDispatches = response.dispatch;
        //                this.cabecera = {
        //                    id: response.proveedor.provider.id,
        //                    name: response.proveedor.provider.name,
        //                    ruc: response.proveedor.provider.ruc,
        //                    total_IGV: response.proveedor.total_IGV,
        //                    total_sin_IGV: response.proveedor.total_sin_IGV,
        //                    not_engraved: response.proveedor.not_engraved,
        //                    observation: response.proveedor.observation,
        //                };
        //                this.dataBillDetailEdit = Array(this.dataordendetalle.length)
        //                    .fill("")
        //                    .map((_, index) => ({
        //                        id_product_details: null,
        //                        lote: "",
        //                        date: null,
        //                        incoming_quantity: null,
        //                        register_sanitary: "",
        //                        quantity_pending_order: null,
        //                        id_purcharse_order_detail: null,
        //                        barcode: "",
        //                    }));
        //            })
        //            .catch((errors) => {});
        //    },
        //    mtdGetDispatches: function (idbill, titleModal) {
        //        this.get({
        //                url: this.$store.getters.get__url +
        //                    "/Logistica/oc/getDispatches/" +
        //                    idbill,
        //                token: this.$store.getters.get__token,
        //            })
        //            .then((response) => {
        //                this.dataDispatches = response.dispatch;
        //                if (this.dataDispatches.length > 0) {
        //                    this.modalViewDispatch = {
        //                        title: titleModal,
        //                        modal_form: true,
        //                    };
        //                } else {
        //                    Swal.fire({
        //                        icon: "error",
        //                        title: "No hay despachos para mostrar",
        //                        showConfirmButton: false,
        //                        timer: 1500,
        //                    });
        //                }
        //            })
        //            .catch((errors) => {});
        //    },

        mtdCloseModalFactura: function () {
            this.modalStoreDispatch = {
                title: "",
                modal_form: false,
            };
            this.modalViewDispatch = {
                title: "",
                modal_form: false,
            };
            this.posEditnumber = null;
            this.mtdGetData();
        },

        mtdcloseModalPay: function () {
            this.modalPay = {
                title: "",
                modal_form: false,
            };
            this.mtdGetData();
        },

        mtdGetIgva: function (ind) {
            if (this.dataservice[ind].total_sin_IGV) {
                const igv = 0.18 * this.dataservice[ind].total_sin_IGV;
                return parseFloat(igv).toFixed(2);
            } else {
                return 0.0;
            }
        },
        mtdGetIgv: function (ind) {
            if (this.cpData[ind].total_sin_IGV) {
                const igv = 0.18 * this.cpData[ind].total_sin_IGV;
                return parseFloat(igv).toFixed(2);
            } else {
                return 0.0;
            }
        },
        mtdGenerateCode(item) {
            if (0 < item && item < 10) {
                return " OS-000" + item;
            }

            if (9 < item && item < 100) {
                return " OS-00" + item;
            }
            if (99 < item && item < 1000) {
                return " OS-0" + item;
            }
            if (999 < item && item < 10000) {
                return " OS-" + item;
            }
        },

        viewfactura: function (id) {
            window.open(
                this.$store.getters.get__url + "/Logistica/viewpdf/os/" + id,
                "_blank"
            );
        },

        calculateData(items) {
            let filteredData = this.dataservice;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            /* Mostrar el contenido para editar */
            this.bill = Array(this.dataShow.length)
                .fill("")
                .map((_, index) => ({
                    id: null,
                    number: null,
                    date: null,
                    purchase_service_id: null,
                    money: "soles",
                    paid: null,
                    total: this.dataShow[index].total,
                    detraction: 1,
                    detraction_percentage: null,
                    detraction_amount: null,
                    net_total: null,
                }));
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        viewservice: function (item) {
            this.get({
                    url: this.$store.getters.get__url + "/Logistica/os/indexservicedetails/" + item.id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataservicedetails = response.data;
                })
                .catch((errors) => {});
            this.modalService = {
                title: item.provider_name,
                modal_form: true,
            }
        },
        closeModalService: function () {
            this.modalService = {
                title: '',
                modal_form: false,
            }
        }

    },
}
</script>

<style scoped>
.input-p::placeholder {
    font-size: 10px;
}

.input-p {
    font-size: 10px;
}

.input-with-opacity {
    opacity: 0.4;
}

.input-factura {
    width: 100px;
    font-size: 11px;
}

#btn-save {
    color: #39b54a;
    font-size: 25px;
    margin: 0px 2px;
}

.acciones {
    background-color: #900052;
    border-radius: 10px;
    width: 25px;
}

.acciones-2 {
    background-color: #39b54a;
    border-radius: 10px;
    width: 25px;
}

.acciones-3 {
    background-color: blue;
    border-radius: 10px;
    width: 25px;
}

.acciones-4 {
    background-color: gray;
    border-radius: 10px;
    width: 25px;
}

.acciones-5 {
    border-radius: 10px;
    width: 25px;
}

.anterior-siguiente:focus {
    background: #900052;
}

.anterior-siguiente:hover {
    background: #bb026c;
}

#input-cantidad {
    font-size: 10px;
}

#input-f-factura {
    font-size: 10px;
    width: 98px;
}

#select-moneda {
    font-size: 10px;
    width: 52px;
}

#select-remision {
    font-size: 10px;
    width: 62px;
}

#input-factura {
    font-size: 10px;
    width: 79px;
}

.acciones-3-s {
    border-radius: 10px;
    width: 25px;
}

.error-message {
    font-size: 10px;
    color: red;
}

.disabledbutton {
    pointer-events: none;
    background-color: #e180b7;
    cursor: not-allowed;
    text-decoration: none;
}
</style>

import Vue from 'vue'
import Router from "vue-router";

import HomeView from '../views/HomeView.vue'
import LoginView from "../views/Login.vue"
import Cola1View from "../views/Cola1.vue"
import Cola2View from "../views/Cola2.vue"

Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: '',
    component: LoginView
  },
  {
    path: '/cola1',
    name: '',
    component: Cola1View
  },
  {
    path: '/cola2',
    name: '',
    component: Cola2View
  },
  {
    path: '/home',
    name: 'inicio',
    component: HomeView
  },
  {
    path: '/admision',
    name: 'admision',
    component: HomeView
  },
  {
    path: '/medico',
    name: 'medico',
    component: HomeView
  },
  {
    path: '/farmacia',
    name: 'farmacia',
    component: HomeView
  },
  {
    path: '/mantenimiento',
    name: 'mantenimiento',
    component: HomeView
  },
  {
    path: '/sat',
    name: 'sat',
    component: HomeView
  },
  {
    path: '/enfermera',
    name: 'enfermera',
    component: HomeView
  },
  {
    path: '/callcenter',
    name: 'callcenter',
    component: HomeView
  },
  {
    path: '/logistica',
    name: 'logistica',
    component: HomeView
  },
  {
    path: '/gerencia',
    name: 'gerencia',
    component: HomeView
  },
  {
    path: '/contabilidad',
    name: 'contabilidad',
    component: HomeView
  },
  {
    path: '/vigilancia',
    name: 'vigilancia',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  }
];

export default new Router({
  mode: 'hash',
  // base: process.env.BASE_URL,
  // mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes
})

<template>
    <div>
      <div class="" v-if="page == 'venta'">
        <div class="d-flex title-option">
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Atras
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100 border-box-main mt-2">
          <div id="div-anular" class="card w-100">
  
            <div
            class="card-header text-center text-white h4"
            style="background: #900052"
          >
                <div  class="d-flex justify-content-between align-items-center">
                <strong class="col text-center">MEDICAMENTOS SELECCIONADOS</strong>
                </div>

          </div>
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="w-100 pt-2" style="background-color: #ffffff">
                  <div class="cuerpo">
                    <div class="px-4">
                      <div class="col-md-12 table-container table-responsive" id="div-tabla-anular" >
                        <table
                          id="tb-anular"
                          class="table tablaRe table-bordered table-container align-middle"
                        >
                          <caption></caption>
                          <thead class="bg-main">
                            <tr class="text-white table-th text-center text-xs">
                              <th class="table-th-state">
                                <div>MEDICAMENTO</div>
                              </th>
                              <th class="table-th-state">
                                <div>CANTIDAD</div>
                              </th>
                              <th class="table-th-state">
                                <div>PRECIO C/IGV</div>
                              </th>
                              <th class="table-th-state">
                                <div>SUBTOTAL</div>
                              </th>
                              <th class="table-th-state">
                                <div>DESCUENTO</div>
                              </th>
                              <th class="table-th-state">
                                <div>TOTAL</div>
                              </th>
                              <th class="table-th-state">
                                <div>ACCION</div>
                              </th>
                            </tr>
                          </thead>
              
                          <tbody align="center" class="border-main">
                            <template v-if="datos.length === 0">
                                <tr class="text-xs table-body">
                                    <td colspan="7">Sin Medicamentos</td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr class="text-xs table-body" v-for="(item, index) in datos" :key="index">
                                    <td class="table-td-state">{{ item.producto }}</td>
                                    <td class="table-td-state">{{ calculateTotalQuantityDispatch(item.tabla) }}</td>

                                    <td v-if="posEditPrice != index && posobs!=index &&
                                        itemDiscount!=index" class="table-td-state">
                                          {{ item.price }}
                                    </td>
                                    <td v-if="posEditPrice == index">
                                      <input
                                        type="text"
                                        style="background: white"
                                        class="form-control-sm"
                                        @keyup.enter="mtdOutFocusEditPrice(index)"
                                        v-model="item.price"
                                        @keypress="validateNumberInput"
                                      />
                                    </td>
                                    <td v-if="posobs==index">
                                      <input
                                        type="text"
                                        style="background: white; width:90px;  margin-right:5px;"
                                        class="form-control-sm"
                                        @keyup.enter="mtdOutFocusEditObs(index)"
                                        @blur="mtdOutFocusEditObs(index)"
                                        v-model="item.obs"
                                        placeholder="Motivo"
                                      />
                                    </td>
                                    <td v-if="itemDiscount == index">
                                      <input
                                        type="text"
                                        style="background: white; width:90px;  margin-right:5px;"
                                        class="form-control-sm"
                                        @keyup.enter="AplicarDescuento(index)"
                                        v-model="item.descuento"
                                        placeholder="Descuento"
                                      />
                                      <label style="margin:0 5px;">
                                        <input type="radio" v-model="item.tipodescuento" value="porcentaje"/> %
                                      </label>
                                      <label style="margin:0 5px;">
                                        <input type="radio" v-model="item.tipodescuento" value="monto"/> Monto
                                      </label>
                                    </td>
                                


                                    <td class="table-td-state">{{ (item.price * item.quantity).toFixed(2) }}</td>
                                    <td class="table-td-state">{{ item.montodescuento ? item.montodescuento.toFixed(2) : '0.00' }}</td>
                                    <td class="table-td-state text-green-bold">{{ (item.subtotal).toFixed(2)  }}</td>
                                    <td>
                                        <a   v-if="posEditPrice != item.idProducto && item.price == '0.00' " class="btn btn-primary btn-sm rounded-circle" type="button" style="background-color: #900052; border: 2px solid #900052; margin-right: 5px;" 
                                          @click="mtdChangePrice(item.idProducto)">
                                          <i class="fas fa-money-bill-alt"></i>
                                        </a>
                                        <a class="btn btn-primary btn-sm rounded-circle" type="button" style="background-color: #900052; border: 2px solid #900052; margin-right: 5px;" 
                                           @click="mtdDelete(item.idProducto)">
                                          <i class="fas fa-trash-alt"></i>
                                        </a>
                                        <a class="btn btn-primary btn-sm rounded-circle" type="button" style="background-color: #900052; border: 2px solid #900052;" 
                                          @click="mtdChangeDiscount(index)">
                                          <i class="fa fa-percent text-white"></i>
                                        </a>
                                    </td>
                                </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--
            <div class="d-flex mt-2 justify-content-end">
              <button class="bg-main border-0 text-white px-4 py-1" @click="verifyZero()">SIGUIENTE</button>
            </div>
            -->
            <div
              align="right"
              class="card-footer text-white"
              style="background: #900052; font-size: 18px"
            >
            <span  v-if="!posDesc && !posMotivo"
                class="btn border border-white text-white"
                style="margin-left: 20px"
                @click="addDsc()"
                >
                <strong>DESC.</strong>
              </span>
              &nbsp;
              <strong v-if="!posDesc && !posMotivo">SUBTOTAL: S/  {{ cpTotal }}</strong
              > &nbsp;
              <strong v-if="!posDesc && !posMotivo">DESC: - S/  {{parseFloat(montoDescuento).toFixed(2)}}</strong
              > &nbsp;
              <strong v-if="!posDesc && !posMotivo">TOTAL: S/ {{ cpTotalDesc}}</strong
              >
              <strong v-if="posDesc">
                DESC:&nbsp;
                <input
                type="text"
                  style="background: white"
                  class="form-control-sm"
                  placeholder="Descuento"
                  @keyup.enter="mtdOutFocusDesc()"
                  v-model="dsc"
                  @keypress="validateNumberInput"
                />
                <label style="margin:0 5px;">
                  <input type="radio" v-model="TypeDiscount" value="porcentaje"/>%
                </label>
                <label style="margin:0 5px;">
                  <input type="radio" v-model="TypeDiscount" value="monto"/> Monto
                </label>
                </strong>
              <strong v-if="posMotivo">
                <textarea class="form-control-xl"
                style="background: white"
                placeholder="Motivo"
                  v-model="motivo"               
                  @keyup.enter="mtdOutFocusMotivo()"
                  @blur="mtdOutFocusMotivo()"
                  ></textarea>
                </strong
              >
              <span  v-if="!posDesc && !posMotivo"
                class="btn border border-white text-white"
                style="margin-left: 20px"
                @click="verifyZero()"
                >
                <strong>SIGUIENTE</strong></span>
            </div>
          </div>
  
        </div>
      </div>
      <SweetAlert :swal="swal" ref="SweetAlert"/>
      <PagoVue
        v-if="page == 'pago'"
        :castCarrito="castCarrito"
        :key="componentKey"
        :dsc="dsc"
        :montodescuento="montoDescuento"
        :permissionValue="permissionValue"
        @mtdBehindProcess="mtdBehindProcess"
        @mdtCommitPayment="mdtCommitPayment"
        :client="client"
        :bead="bead"
    /> 
    </div>
</template>
  <script>
  import { mapActions } from "vuex";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  import PagoVue from "./Venta/Pago.vue"; 
  
  export default {
    props: {
        datos: [],
        client: Object,
        bead:{
          type: Object,

        },
        recipeId: Number,
        destino: String,
    },
    data() {
      return {
        swal: null,
        page: "venta",
        view: "table",
        posEditPrice: null,
        componentKey:0,
        castCarrito: [],
        permissionValue: null,

        //Descuento
        TypeDiscount:"porcentaje",
        validaDescuento:0,
        validaDescuentoGeneral:0,
        itemDiscount:null,
        dsc:"0",
        montoDescuento:"0",
        motivo:"",
        posDesc:false,
        posMotivo:false,
        posobs:null,
        
      };
    },
    components: {
      SweetAlert,
      PagoVue,
    },
    created() {
          this.datos.forEach(item => {
            if (!item.priceMultiplied) {
                item.price *= 1.18;
                item.price = item.price.toFixed(2);
                item.priceMultiplied = true;
            }
        });
    },
    computed: {
      cpTotal() {
        if(this.datos.length==0){
          this.dsc=0;
        }
        let total = 0;
        this.validaDescuento=0;
        this.datos.forEach((element) => {
        total+=element.subtotal;
        if((parseFloat(element.montodescuento))>0){
          this.validaDescuento=1;
        }
        });
        if(parseFloat(this.montoDescuento)>0){
          this.validaDescuentoGeneral=1;
        }else{
          this.validaDescuentoGeneral=0;
        }
        // return parseFloat((total/1.18)).toFixed(2);
        return parseFloat((total)).toFixed(2);
      },
      cpTotalDesc(){
        this.validaDescuento=0;
        if(this.datos.length==0){
          this.dsc='0';
        }
        let total = 0;
        this.datos.forEach((element) => {
        total+=element.subtotal;
        if((parseFloat(element.montodescuento))>0){
          this.validaDescuento=1;
        }
        });
        let totalsig=total/1.18;
        let final =parseFloat(totalsig-this.montoDescuento);
        let TotalFinal=final +(final*0.18);
        return parseFloat(TotalFinal).toFixed(2);
      },
      igv(){
        if(this.datos.length==0){
          this.dsc='0';
        }
        let total = 0;
        this.datos.forEach((element) => {
        total+=element.subtotal;
        if((parseFloat(element.montodescuento))>0){
          this.validaDescuento=1;
        }else{
          this.validaDescuento=0;
        }
        });
        let totalsig=total/1.18;
        let final =parseFloat(totalsig-this.montoDescuento);
        return parseFloat(final*0.18).toFixed(2);
      }
    },
    methods: {
      ...mapActions(["get", "post"]),
  
        returnHome: function () {
            this.$emit("mtdBack");
        },

        calculateTotalQuantityDispatch(tabla) {
            let total = 0;
            if (tabla && Array.isArray(tabla)) {
                tabla.forEach(obj => {
                    if (obj.dates && Array.isArray(obj.dates)) {
                        obj.dates.forEach(dateObj => {
                            total += parseFloat(dateObj.quantity_dispath) || 0;
                        });
                    }
                });
            }
            return total;
        },

       mtdDelete: function (item) {
        this.$emit("mtdDelete", item);
       },

       mtdChangePrice: function (pos) {
        this.posEditPrice = pos;
      },

      validateNumberInput(event) {
        const regex = /^[0-9]*\.?[0-9]*$/;

        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      },

      mtdOutFocusEditPrice: function () {
        this.posEditPrice = null;
      },

      verifyZero: function () {
        const todosMayoresQueCero = this.datos.every(
          (item) => parseFloat(item.price) > '0.00'
        );
        if (todosMayoresQueCero) {
          this.mtdNextStep("2");
          return true;
        } else {
          this.$refs.SweetAlert.showWarning("Hay valores iguales o menores que 0.00");  
          return false;
        }
      },

      mtdNextStep: function (step) {
        if (this.datos.length > 0) {
          if (step == "2") {
            this.castCarrito = [];
            this.datos.forEach((element) => {
              element.data = [];
              //element.programado = false; 
              element.actualPage = false;
              
              element.programado = true;
              element.component = "pago";
              
              this.castCarrito.push(element);
            });
            //poner  los de pago al ultimo
            this.castCarrito.sort((a, b) => {
              if (a.component === "pago" && b.component !== "pago") {
                return 1;
              } else if (a.component !== "pago" && b.component === "pago") {
                return -1;
              } else {
                return 0;
              }
            });
            
            this.mtdProcessCastCarrito();
          }
        } else {
          this.$refs.SweetAlert.showWarning("Seleccione un Medicamento");  
        }
      },

      mtdProcessCastCarrito: function () {
        this.componentKey++;
        //console.log("cascarito en recorrido",this.castCarrito);
        let boo = false;
        for (let index = 0; index < this.castCarrito.length; index++) {
          const element = this.castCarrito[index];
          if (element.actualPage == true) {
            boo = true;
            element.actualPage = false;
            if (index == this.castCarrito.length - 1) {
              this.page = "pago";
            } else {
              this.elementProcess={};
              this.elementProcess = this.castCarrito[index + 1];
              this.castCarrito[index + 1].actualPage = true;
              this.page = this.castCarrito[index + 1].component;
              index = this.castCarrito.length;
            }
          }
        }
        if (boo == false) {
          this.castCarrito[0].actualPage = true;
          this.elementProcess={}; 
          this.elementProcess = this.castCarrito[0];
          // this.page = "pago";  sin sreserva
          this.page=this.castCarrito[0].component;
        }
      },

      mtdBehindProcess: function () {
        this.componentKey++;
        let boo = false;
        for (let index = 0; index < this.castCarrito.length; index++) {
          const element = this.castCarrito[index];
          if (element.actualPage) {
            if (index != 0) {
              boo = true;
              this.elementProcess = this.castCarrito[index - 1];
              element.actualPage = false;
              this.castCarrito[index - 1].actualPage = true;
              this.page = this.castCarrito[index - 1].component;
              break;
            } else {
              break;
            }
          }
        }
        if (this.page == "pago") {
        
          //let cat = this.castCarrito[this.castCarrito.length - 1].servicio.id;
          let cat = 5;
          //console.log(this.castCarrito[this.castCarrito.length - 1],"sddd");
          //console.log(cat,"cat");
          if (cat==5||cat ==6||cat ==7||cat==8) {
           //console.log("..edentoro")
            this.page = "venta";
            this.search_product = "";
          } else {
            this.castCarrito[this.castCarrito.length - 1].actualPage = true;
            this.elementProcess = this.castCarrito[this.castCarrito.length - 1];
            this.page = this.castCarrito[this.castCarrito.length - 1].component;
          }
        }
        else 
        if (boo==false) {
          this.$refs.SweetAlert.showConfirmVenta("¿Desea volver al menú?","¡Se perderá todo los datos seleccionados!","warning","Si")
        }
      },

      mdtCommitPayment: function (payment, chkPatiente, planilla, garantia, impuestos, selectedVoucherCancel) {
        this.post({
          url: this.$store.getters.get__url + "/pharmacy/ventas/store",
          token: this.$store.getters.get__token,
          params: {
            data: this.castCarrito,
            payment: payment,
            patient: this.client,
            bead: this.bead,
            campu_id: this.$store.getters.get__campus,
            chkPatiente: chkPatiente,
            planilla: planilla,
            garantia: garantia,
            impuestos: impuestos,
            motivoDesc:this.motivo,
            descuento:this.dsc,
            tipodescuento:this.TypeDiscount,
            montodescuento:this.montoDescuento,
            validardescuento:this.validaDescuentoGeneral,
            selectedVoucherCancel: selectedVoucherCancel,

            recipeId: this.recipeId,
            destino: this.destino,
          },
        })
          .then((response) => {
            if (response.state == 0) {
              if (response.data != null) {
                /*
                if(response.data.document=='TICKET'){ 
                  window.open(this.$store.getters.get__url +"/Admision/ticket/"+response.data.id, "_blank");
                }
                else{
                  let res = response.data.sunat;
                  window.open(res.json.enlace_del_pdf_ticket, "_blank");
                }
                */
               if(this.destino == "SOP"){
                window.open(
                    this.$store.getters.get__url + "/pharmacy/recetasDispathPdf/" + response.dispatchDePhar.of_dispatch_pharmacies_id,
                    "_blank"
                );
                }
                Swal.fire({
                  title: 'Venta Finalizada',
                  text: 'Seleccione una opción',
                  icon: 'success',
                  showCancelButton: true,
                  showDenyButton: true,
                  confirmButtonText: '<i class="fas fa-file-pdf"></i> Imprimir PDF',
                  cancelButtonText: '<i class="fas fa-envelope"></i> Enviar a Correo',
                  denyButtonText: '<i class="fab fa-whatsapp"></i> Enviar a WhatsApp',
                  customClass: {
                    confirmButton: 'btn btn-primary', 
                    cancelButton: 'btn btn-secondary', 
                    denyButton: 'btn btn-success', 
                    actions: 'd-flex justify-content-between gap-2'
                  },
                  buttonsStyling: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  allowEnterKey: true,
                  showCloseButton: true
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    // Acción PDF
                    if (response.data.document == 'TICKET') { 
                      window.open(this.$store.getters.get__url + "/Admision/ticket/" + response.data.id, "_blank");
                    } else {
                      let res = response.data.sunat;
                      window.open(res.json.enlace_del_pdf_ticket, "_blank");
                    }
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // Acción para correo
                    window.location.href = 'mailto:correo@example.com';
                  } else if (result.dismiss === Swal.DismissReason.deny) {
                    // Acción para WhatsApp
                    let voucherLink;
                    if (response.data.document == 'TICKET') {
                      voucherLink = this.$store.getters.get__url + "/Admision/ticket/" + response.data.id;
                    } else {
                      let res = response.data.sunat;
                      voucherLink = res.json.enlace_del_pdf_ticket;
                    }
                    const cleanedPhone = this.cleanPhoneNumber(this.client.phone);
                    const whatsappPhone = '+51' + cleanedPhone; 
                    window.open(this.generateWhatsAppLinks(this.client.name, whatsappPhone, voucherLink), '_blank');
                  }
                });

              }
              //this.$emit("mtdBack",response.recipedetil);
              this.$emit("mtdMediDespachado",response.recipedetil);
              //this.$refs.SweetAlert.showSuccess("Venta Finalizada");
            } else {
              alert(response.exception);
            }
          })
          .catch((errors) => {
            console.log(errors);
          });
      },

      generateWhatsAppLinks(patient, phone, voucherLink) {
        const message = `Hola, ${patient}. Te saluda BM CLINICA DE OJOS, te enviamos tu comprobante de pago: ${voucherLink}`;
        const encodedMessage = encodeURIComponent(message);
        const whatsappLink = `https://api.whatsapp.com/send?phone=${phone}&text=${encodedMessage}`;
        return whatsappLink;
      },

      cleanPhoneNumber(phone) {
        return phone.replace(/\D/g, '');
      },
      
      mtdGetDataPerAndData: function () {
        this.post({
          url: this.$store.getters.get__url + "/jefatura/otrosPermisos",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            this.permissionValue = response.data[0].permission;
          })
          .catch((errors) => { console.log(errors); });
      },

      //Descuentos

      mtdChangeDiscount: function (index) {
        if(this.validaDescuentoGeneral==1){
          this.$refs.SweetAlert.showWarning("Ya Registro Un descuento Global");
          return false;
        }
        this.itemDiscount = index;
      },

      AplicarDescuento1: function(index) {
            let item=null;
          item = this.datos[index];
          if (item.tipodescuento === "monto" && item.descuento != '0') {
              let subtotalSING = item.subtotal / 1.18;
              subtotalSING = subtotalSING - parseFloat(item.descuento);
              item.montodescuento = parseFloat(item.descuento);
              item.subtotal = subtotalSING + (subtotalSING * 0.18);
          } else if (item.tipodescuento === "porcentaje" && item.descuento != '0') {
              let subtotalSING = item.subtotal / 1.18;
              const porcentajeDescuento = parseFloat(item.descuento) / 100;
              subtotalSING = subtotalSING - (subtotalSING * porcentajeDescuento);
              item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
              item.subtotal = subtotalSING + (subtotalSING * 0.18);
          } else if (item.descuento == '0') {
              item.montodescuento ='0';
              item.subtotal = item.quantity * parseFloat(item.price);
          }
          if (item.price < 0) {
              item.price = 0;
          }
          this.datos[index] = item;
          this.actulizarDscGlobal();
      },

      actulizarDscGlobal:function(){
        if (parseFloat(this.dsc)>0) {
          this.posDesc=false;
          let total = 0;
        this.datos.forEach((element) => {
        total+=element.subtotal;
        });
        let TotalSIG=total/1.18;
        if(this.TypeDiscount=='monto' && this.dsc!='0'){
          this.montoDescuento =parseFloat(this.dsc);
        }
        else if(this.TypeDiscount=='porcentaje'&& this.dsc!='0'){
          const porcentajeDescuento = parseFloat(this.dsc) / 100;
          this.montoDescuento= (TotalSIG * porcentajeDescuento);
        }
        }else{
          this.dsc='0';
          this.montoDescuento='0';
          this.posDesc=false;
          this.posMotivo=false;
          this.motivo="";
        }
      },

      AplicarDescuento: function(index) {
          let item=null;
          item = this.datos[index];
          item.subtotal=item.quantity*item.price;
          if (item.tipodescuento === "monto" && item.descuento != '0') {
              let subtotalSING = item.subtotal / 1.18;
              subtotalSING = subtotalSING - parseFloat(item.descuento);
              item.montodescuento = parseFloat(item.descuento);
              item.subtotal = subtotalSING + (subtotalSING * 0.18);
          } else if (item.tipodescuento === "porcentaje" && item.descuento != '0') {
              let subtotalSING = item.subtotal / 1.18;
              const porcentajeDescuento = parseFloat(item.descuento) / 100;
              subtotalSING = subtotalSING - (subtotalSING * porcentajeDescuento);
              item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
              item.subtotal = subtotalSING + (subtotalSING * 0.18);
          } else if (item.descuento == '0') {
              item.montodescuento ='0';
              item.subtotal = item.quantity * parseFloat(item.price);
          }
          if (item.price < 0) {
              item.price = 0;
          }
          this.datos[index] = item;
          this.itemDiscount = null;
          this.actulizarDscGlobal();
          if (parseFloat(item.montodescuento) > 0) {
              this.posobs = index;
          }
      },

      mtdOutFocusEditObs(index) {
        if (this.datos[index].obs!="") {
          this.posobs=null;
        }else{
          this.$refs.SweetAlert.showWarning("Registre un motivo.");
          return ;
        }
      },

      addDsc(){
        if (this.datos.length > 0) {
          if(this.validaDescuento==1){
            this.$refs.SweetAlert.showWarning("Ya Realizo Descuentos por Item.");  
          return false;
          }
          this.$refs.SweetAlert.showConfirmSimple2(
          "",
          "Va Registrar Un Descuento Global",
          "warning",
          "Si, Confirmar!"
        ).then((result) => {
          if (result.value) {
        const todosMayoresQueCero = this.datos.every(
          (item) => parseFloat(item.price) > 0
        );
        if (todosMayoresQueCero) {
          this.posDesc=true;
          return true;
        } else {
          this.$refs.SweetAlert.showWarning("Hay Productos con precio 0.00");  
          return false;
        }
        }
        });
      }
        else{
          this.$refs.SweetAlert.showWarning("No hay productos en el carrito");  
          return false;
        }
      },

      mtdOutFocusDesc(){
        if (parseFloat(this.dsc)>0) {
          this.posDesc=false;
          let total = 0;
        this.datos.forEach((element) => {
        total+=element.subtotal;
        });
          let TotalSIG=total/1.18;
        if(this.TypeDiscount=='monto' && this.dsc!='0'){
          this.montoDescuento =parseFloat(this.dsc);
        }
        else if(this.TypeDiscount=='porcentaje'&& this.dsc!='0'){
          const porcentajeDescuento = parseFloat(this.dsc) / 100;
          this.montoDescuento=TotalSIG*porcentajeDescuento;
        }
        this.$refs.SweetAlert.showWarning("Registre un motivo.");
        this.posMotivo=true;
        }else{
          this.dsc='0';
          this.montoDescuento='0';
          this.posDesc=false;
          this.posMotivo=false;
          this.motivo="";
        }
      },

      mtdOutFocusMotivo(){
        if (this.motivo!="") {
          this.posMotivo=false;
        }else{
          this.$refs.SweetAlert.showWarning("Registre un motivo.");
          return ;
        }
      },
    
    },
  };
  </script>
  <style scoped>
  .tablaRe td,
  .tablaRe th {
    font-size: 17px;
  }
  
  .tr-table > th > div {
    background: #900052;
    border-radius: 7px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
  }
  
  .tr-table > th {
    padding: 0px;
  }
  .tbody-table > td > div {
    background: #eaecee;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .tbody-table > td {
    padding: 0px;
  }
  
  .tbody-table > td > div > div {
    border-radius: 10px;
  }
  .tbody-table1 > td > div {
    background: white;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  
  .tbody-table1 > td {
    padding: 0px;
  }
  
  .tbody-table1 > td > div > div {
    border-radius: 10px;
  }
  .acciones {
    background-color: #900052;
    border-radius: 10px;
  }
  
  .table-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
  }
  
  .table-container::-webkit-scrollbar {
    width: 1px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
  
  .table-container::-webkit-scrollbar-track {
    background-color: lightgray;
  }
  
  .custom-margin {
    margin-left: 5px;
  }
  
  .spam {
    background: #900052;
    color: #ffffff;
    width: 90px;
    line-height: 20px;
    justify-content: center;
    border-radius: 1rem;
  }
  
  @media (max-width: 1000px) {
    .responsive-search {
      flex-direction: column;
    }
    .responsive-search > .input-group {
      width: 100% !important;
    }
  
    .table-container > table > thead {
      font-size: 12px !important;
    }
  
    .table-container > table > tbody {
      font-size: 11px !important;
    }
  }
  .text-doctor {
    font-weight: bold;
    font-size: 14px;
    color: #900052;
  }
  
  .text-horario {
    font-weight: bold;
    font-size: 11px;
    color: #808080;
  }
  
  .header-main {
    background-color: #900052;
  }
  #div-tabla-cola {
    border: 1px solid #aaa;
    height: 300px;
    background: #f1f2f3;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  #div-tabla-cola::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  #div-tabla-cola::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  #div-tabla-cola::-webkit-scrollbar-button:increment,
  #div-tabla-cola::-webkit-scrollbar-button {
    display: none;
  }
  
  #div-tabla-cola::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  #div-tabla-cola::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  #div-tabla-cola::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  #content-timeline {
    border: 1px solid #aaa;
    height: 420px;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  #content-timeline::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  #content-timeline::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-button:increment,
  #content-timeline::-webkit-scrollbar-button {
    display: none;
  }
  
  #content-timeline::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  #content-timeline::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  @media (max-width: 992px) {
    .flex-row {
      flex-direction: column !important;
      align-items: center;
      padding-top: 20px !important;
    }
  
    #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
    }
  }
  
  .custom-input {
  border-color: #900052;
  color: #900052;
  }
  
  .custom-input::placeholder {
  color: #900052; 
  }

  .table-body td{
    font-size: 14px;
  }

  .table-responsive {
    max-height: 250px;
    overflow-y: auto;
    display: block;
  }

  .text-green-bold {
    color: green;
    font-weight: bold;
  }
  </style>
  
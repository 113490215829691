<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @changePage="changePage" @mtdBackCampus="mtdBackCampus" />
        <OC v-if="page == 'oc'" @returnHome="returnHome" @changePage="changePage" @mtdChangeFilter="mtdChangeFilter" />
        <Factura v-if="page == 'factura'" @returnHome="returnHome" />
        <OS v-if="page == 'os'" @returnHome="returnHome" />
        <Inventario v-if="page == 'inventario'" @returnHome="returnHome" />
        <StockMinimo v-if="page == 'stockminimo'" @returnHome="returnHome" />
        <Mantenimiento v-if="page == 'mantenimiento'" @returnHome="returnHome" />
        <Kardex v-if="page == 'kardex'" @returnHome="returnHome" />
        <Requerimiento v-if="page=='requerimiento'" @returnHome="returnHome" />
        <Consignaciones v-if="page=='consignaciones'" @returnHome="returnHome" />
        <Movimiento v-if="page=='movimiento'" @returnHome="returnHome"/>
        <KardexInterno v-if="page == 'kardesInterno'" @returnHome="returnHome" />
        <HisMovimiento v-if="page == 'hisMovimiento'" @returnHome="returnHome" />
    </div>
    <div class="footer">
        <FooterVue @mtdChangePage="mtdChangePage" :pageView="page" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Nav from '../../components/Logistica/Nav.vue'
import Home from '../../components/Logistica/Home.vue'
import Factura from "../../components/Logistica/Pages/ReporteFactura.vue";
import OS from "../../components/Logistica/Pages/Ordendeservicios.vue";
import Inventario from "../../components/Logistica/Pages/Inventario.vue";
import StockMinimo from "../../components/Logistica/Pages/Stockminimo.vue";
import Mantenimiento from "../../components/Logistica/Pages/Mantenimiento.vue";
import Kardex from "../../components/Logistica/Pages/Kardexxx.vue";
import Requerimiento from "../../components/Logistica/Pages/Requerimiento.vue";
import OC from "../../components/Logistica/Pages/Ordendecompra.vue"
import FooterVue from "@/components/Logistica/Footer.vue";
import Consignaciones from '@/components/Logistica/Pages/Consignaciones.vue';
import Movimiento from '../../components/Logistica/Pages/Movimiento.vue';
import KardexInterno from "../../components/Logistica/Pages/KardexInterno.vue";
import HisMovimiento from "../../components/Logistica/Pages/HisMovimiento.vue"
export default {
    name: "SatView",
    components: {
        Nav,
        Home,
        Factura,
        OS,
        Inventario,
        StockMinimo,
        Mantenimiento,
        Kardex,
        Requerimiento ,
        OC,
        FooterVue,
        Consignaciones,
        Movimiento,
        KardexInterno,
        HisMovimiento
    },
    data() {
        return {
            prefix: 'sat',
            page: 'home',
            rol_name: null,
            stateRolAdmin: false,
        }
    },
    props: {
        rolname: String,
        dataRoles: Array
    },
    created() {
        this.rol_name = this.rolname;
        this.mtdGetRolAdmin();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function (page) {
            this.page = page;
        },
        changePage: function (page) {
            this.page = page;
        },
        returnHome: function () {
            this.page = "home";
        },
        mtdChangeFilter: function (data) {
            this.filter = data;
        },
        mtdGetRolAdmin: function () {
            this.dataRoles.forEach(element => {
                if (element.role_id == 11) {
                    this.stateRolAdmin = true;
                }
            });
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
    }

}
</script>

<template>
  <div>
    <div class="container text-center mt-2 mb-3">
      <div class="d-flex title-option">
        <div class="btn-home mt-3 mb-2 ms-auto">
          <button type="button" class="btn btn-outline-main" @click="returnHome()">
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>

      <div id="div-modal-anular" class="w-100">
        <div id="div-anular" class="card w-100">
          <div class="card-header text-white h5 bg-main text-center">
            <strong>PROGRAMAR LECTURA DE RESULTADOS</strong>
          </div>
          <div class="card-body w-100">
            <div class="row">
              <div class="col-md-12 py-2">
                <div class="row d-flex">
                  <div class="col-lg-5 col-md-5">
                    <div class="input-group input-group mb-2">
                      <v-select
                        id="medicSelect"
                        class="text-dark form-control"
                        :options="medics"
                        @input="handleSelectItemClick"
                        :reduce="(name) => name.id"
                        label="name"
                        placeholder="- Buscar Médico -"
                        required
                        :clearable="false"
                        v-model="Reservation.selectedMedic"
                        @change="filterData"
                      />
                    </div>
                  </div>
                  <div class="btn-home col-lg-2 col-md-2 mb-2 mt-2">
                    <button
                      type="button"
                      class="btn w-100"
                      :class="{ 'btn-outline-custom': isFilterDisabled }"
                      :disabled="isFilterDisabled"
                      @click="filterData"
                    >
                      <i class="fas fa-filter"></i> Filtrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2 mb-2" v-if="showCalendar">
        <div class="col-sm-12 col-md-12 col-xl-8 mt-4">
          <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
              <div class="card-body w-100">
                <header class="card-header bg-main text-white align">
                  <h6 class="card-title">Fecha</h6>
                </header>
                <div class="row">
                  <div class="col-md-12 py-2">
                    <div class="card-body">
                      <FullCalendar :options="calendarOptions" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-xl-4 mt-4">
          <div id="div-anular" class="card w-100">
            <div class="card-body w-100">
              <div
                class="alert alerta"
                role="alert"
                style="padding: 5px"
                v-if="turnsCount !== 0"
              >
                HORARIOS DISPONIBLES: {{ turnsCount }}
              </div>
              <section class="card">
                <header class="card-header bg-main text-white align">
                  <h6 class="card-title">Crear Cita</h6>
                </header>
                <div class="card-body">
                  <div class="row">
                    <div
                      class="col-md-12 legend d-flex justify-content-center legend mb-2"
                    >
                      <div class="legend-item">
                        <div class="circle" style="background-color: blue"></div>
                        <span class="spam">CONSULTAS</span>
                      </div>
                      <div class="legend-item">
                        <div class="circle" style="background-color: green"></div>
                        <span class="spam">CIRUGIAS</span>
                      </div>
                      <div class="legend-item">
                        <div class="circle" style="background-color: orange"></div>
                        <span class="spam">IMAGENES</span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="col-form-label label-modal">Fecha</label>
                        <input
                          type="text"
                          class="form-control"
                          autocomplete="off"
                          name="date"
                          v-model="quote.dateStr"
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="col-form-label label-modal">Sala</label>
                        <div>
                          <v-select
                            id="patientSelect"
                            class="text-dark form-control"
                            :options="filteredRoom"
                            v-model="Reservation.selectedConRoom"
                            :reduce="(room) => room.id"
                            label="name"
                            placeholder="- Buscar Sala-"
                            required
                            :clearable="false"
                            @input="mtdGetTurn()"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="col-form-label label-modal">Paciente</label>
                        <div>
                          <input class="form-control" v-model="patient.name" readonly/>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label
                          class="col-form-label label-modal"
                          v-if="quote.dateStr && Reservation.selectedConRoom !== ''"
                          >Turno</label
                        >
                        <div class="row d-flex justify-content-center text-center">
                          <div
                            v-for="turno in turns"
                            :key="turno.id"
                            class="col-md-12 col-sm-12 col-xl-4 col-lg-4 mt-2"
                          >
                            <button
                              type="button"
                              class="btn input-group-text form-control"
                              :class="{
                                'btn-outline-custom': selectedTurno !== turno.name,
                                'text-white bg-main': selectedTurno === turno.name,
                              }"
                              @click="selectTurno(turno.name)"
                              :disabled="
                                !quote.dateStr ||
                                Reservation.selectedConRoom == ''
                              "
                            >
                              {{ turno.name }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 mt-2" v-if="selectedTurno !== ''">
                      <div class="table-responsive">
                        <table
                          aria-describedby="mydesc"
                          class="table table-striped table-scroll"
                        >
                          <thead>
                            <tr>
                              <th scope="col">Horas</th>
                              <th scope="col">
                                <i class="fas fa-calendar-check"></i> Estado
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <template v-if="selectedTurno !== '' && ofHours.length > 0">
                              <tr
                                v-for="hour in ofHours"
                                :key="hour.id"
                                class="cursor text-lg table-body"
                              >
                                <!--
                                <td class="wide-cell">{{ hour.name }}</td>
                                -->
                                <td class="wide-cell">
                                  <button
                                    v-if="hour.status == 1"
                                    type="button"
                                    class="btn input-group-text form-control"
                                  >
                                    {{ hour.name }}
                                  </button>
                                  <button
                                    v-else
                                    type="button"
                                    class="btn input-group-text form-control"
                                    :class="{
                                      'btn-warning text-black': selectedHour === hour.id,
                                    }"
                                  >
                                    {{ hour.name }}
                                  </button>
                                </td>
                                <td class="wide-cell">
                                  <span v-if="hour.status == 1">
                                    <i class="fas fa-circle text-danger"></i>
                                    Reservado
                                  </span>
                                  <!--
                                  <span
                                    v-else-if="hour.status == 0"
                                    @click="mtdCitas(hour.id)"
                                  >
                                    <i class="fas fa-circle text-success"></i>
                                    Libre
                                  </span>
                                  <span v-else-if="hour.status == 2">
                                    <i class="fas fa-circle text-warning"></i>
                                  </span>
                                  -->
                                  <span v-else-if="hour.status == 0" @click="mtdCitas(hour.id)">
                                    <i class="fas fa-circle text-success"></i>
                                    {{
                                      selectedHour === hour.id ? "Seleccionado" : "Libre"
                                    }}
                                  </span>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td colspan="2">SIN HORAS</td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cModalCitasVue
      :title="modalCitas.title"
      :boo_modal="modalCitas.modal_form"
      @closeModalCitas="closeModalCitas"
      @mtdReservarCitas="reservarCita"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";
import cModalCitasVue from '@/components/Call/Modals/cModalCitas.vue';
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
// import esLocale from '@fullcalendar/core/locales/es';
import moment from "moment";

export default {
  name: "c-call-citas",
  components: {
    FullCalendar,
    cModalCitasVue,
    SweetAlert,
    Multiselect,
  },
  data() {
    return {
      swal: null,
      viewInput: null,
      pos: 0,
      showCalendar: false,
      Reservation: {
        selectedMedic: "",
        selectedPatient: "",
        selectedService: 1,
        selectedSerEs: 6,
        selectedConRoom: "",
        selectedSerEsImg: [],
      },
      calendarOptions: {
        // plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        // initialView: 'dayGridMonth',
        // locale: esLocale,
        // themeSystem: 'bootstrap',
        // headerToolbar: {
        //     right: 'prev today next',
        //     left: 'title',
        //     center: 'dayGridMonth,timeGridWeek,timeGridDay'
        // },
        // buttonText: {
        //     today: 'Hoy',
        //     month: 'Meses',
        //     week: 'Semanas',
        //     day: 'Días',
        // },
        // eventTimeFormat: {
        //     hour: '2-digit',
        //     minute: '2-digit',
        //     hour12: true,
        // },
        // weekends: true,
        // selectable: true,
        // editable: false,
        // dateClick: this.dateClick,
        // events: this.events

        locale: "es",
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        events: [],
        initialView: "dayGridMonth",
        buttonText: {
          today: "Hoy",
          month: "Meses",
          week: "Semanas",
          day: "Días",
        },
        headerToolbar: {
          left: "title",
          center: "",
          right: "prev today next",
        },
        weekends: true,
        selectable: true,
        editable: false,
        validRange: {
          start: Date.now(),
        },
        dateClick: this.dateClick,
        /*
                dayMaxEventRows: true, 
                views: {
                    timeGrid: {
                        dayMaxEventRows: 6 
                    }
                }
                */
      },
      serviceOptions: [],
      clinicalHistory: [],
      medics: [],
      patients: [],
      turns: [],
      ofHours: [],
      conRoom: [],
      opeRoom: [],
      imgRoom: [],
      events: [],
      quote: {
        medic_id: null,
        dateStr: "",
        campu_id: null,
        turn: 1,
      },
      selectedTurno: "",
      ultimaFechaSeleccionada: null,
      lastSelectedDay: null,
      modalCitas: {
        title: "CONFIRMACIÓN",
        item: [],
        modal_from: false,
        pos: null,
      },
      hourbycita: "",
      ofCall: [],

      turnsCount: 0,
      selectedHour: "",
    };
  },
  created() {
    this.mtdGetService();
    this.mtdGetDoctor();
    this.mtdGetTurn();
  },
  watch: {
    events: {
      handler(newEvents) {
        this.calendarOptions.events = newEvents;
      },
      deep: true,
    },
  },
  props: {
    patient: Object,
  },
  computed: {
    isFilterDisabled() {
      this.Reservation.selectedPatient = this.patient.patient_id;
      return !this.Reservation.selectedMedic;
    },
    filteredProducts() {
      if (this.Reservation.selectedService) {
        const selectedService = this.serviceOptions.find(
          (service) => service.id === this.Reservation.selectedService
        );
        if (selectedService) {
          return selectedService.of_product;
        }
      }
      return [];
    },
    filteredRoom() {
      if (
        this.Reservation.selectedService == 1 ||
        this.Reservation.selectedService == 4
      ) {
        const selectedService = this.serviceOptions.find(
          (service) => service.id === this.Reservation.selectedService
        );
        if (selectedService) {
          return selectedService.of_consulting_room;
        }
      }
      return [];
    },
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdGetService: function () {
      this.post({
        url: this.$store.getters.get__url + "/Call/services",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.serviceOptions = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mtdGetDoctor: function () {
      this.post({
        url: this.$store.getters.get__url + "/Call/doctors",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.medics = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mtdGetPatient: function () {
      this.post({
        url: this.$store.getters.get__url + "/Call/patients",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.patients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    mtdGetTurn: function () {
      if (this.quote.dateStr != "" && this.Reservation.selectedConRoom != "") {
        const formattedDate = this.quote.dateStr.split("/").reverse().join("-");
        this.ofHours = [];
        this.selectedTurno = "";
        this.post({
          url: this.$store.getters.get__url + "/Call/turns",
          token: this.$store.getters.get__token,
          params: {
            room: this.Reservation.selectedConRoom,
            date: formattedDate,
            option: this.Reservation.selectedService,
          },
        })
          .then((response) => {
            this.turns = response.data;
            this.turnsCount = response.totalStatusZero;

            this.ofHours = [];
            this.turns.forEach((turn) => {
              turn.of_hour.forEach((hour) => {
                this.ofHours.push({
                  id: hour.id,
                  name: hour.name,
                });
              });
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    mtdGetHour: function () {
      this.post({
        url: this.$store.getters.get__url + "/Call/countReserva",
        token: this.$store.getters.get__token,
        params: {
          Campus: this.$store.getters.get__campus,
          //Reservation: this.Reservation,
        },
      })
        .then((response) => {
          this.ofCall = response.data;
          this.bringDate();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    bringDate: function () {
      this.ofCall.forEach((item) => {
        let backgroundColor, title;
        switch (item.of_service_id) {
          case 1:
            backgroundColor = "blue";
            title = "CONSULTAS";
            break;
          case 2:
            backgroundColor = "green";
            title = "CIRUGIAS";
            break;
          case 4:
            backgroundColor = "orange";
            title = "IMAGENES";
            break;
          default:
            backgroundColor = "gray";
            title = "Servicio Desconocido";
            break;
        }
        const newEvent = {
          title: title + " " + item.total,
          start: item.date,
          backgroundColor: backgroundColor,
          borderColor: backgroundColor,
          textColor: "white",
        };
        this.events.push(newEvent);
      });
    },

    dateClick: function (info) {
      const date2 = new Date(info.date);
      const date = info.dateStr;
      const olddate = new Date();
      var ano = olddate.getFullYear();
      var mes = ("0" + (olddate.getMonth() + 1)).slice(-2);
      var dia = ("0" + olddate.getDate()).slice(-2);
      var fechaFormateada = ano + "-" + mes + "-" + dia;
      if (date < fechaFormateada) {
        this.$refs.SweetAlert.showWarning("Elija una Fecha Válida");
      } else {
        const auxiliar = this.fixDate(date2, "date-en");
        this.quote.dateStr = moment(auxiliar).format("DD/MM/YYYY");
        this.ultimaFechaSeleccionada = date;
        this.mtdGetTurn();
      }
    },

    handleSelectItemClick: function (medicSelect) {
      const position = this.medics.findIndex((medics) => medics.id === medicSelect.id);
      this.pos = position + 1;
      //this.showCalendar = false;
      this.clearService();
      //this.events = [];
      //this.mtdGetHour();
    },

    handleSelectItemClickPaBu: function (patientSelect) {
      const position = this.patientsBu.findIndex(
        (patientBu) => patientBu.id === patientSelect.id
      );
      this.pos = position + 1;
    },

    handleSelectItemClickRo: function (selectedRoom) {
      const position = this.filteredRoom.findIndex((room) => room.id === selectedRoom.id);
      this.pos = position + 1;
    },
    mtdGetStyle: function (option) {
      if (option == this.page) return "background: #900052";
      return "";
    },
    mtdGetStyleSpan: function (option) {
      if (option == this.page) return "color:white";
      return "";
    },
    returnHome() {
      this.$emit("mtdChangePage", "home");
    },
    filterData() {
      this.events = [];
      this.showCalendar = true;
      this.mtdGetHour();
    },

    fixDate(date, type) {
      let dateFixed = "";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = "";
      switch (type) {
        case "date-es":
          if (date.getDate() < 10) day = "0" + day;
          dateFixed = `${day}/${month}/${year}`;
          break;
        case "date-en":
          if (date.getDate() < 10) day = "0" + day;
          dateFixed = `${year}-${month}-${day}`;
          break;
        case "time":
          date.getHours() < 10
            ? (hours += "0" + date.getHours())
            : (hours = date.getHours());
          dateFixed = `${hours}:${date.getMinutes().toString().padStart(2, "0")}`;
          break;

        default:
          break;
      }
      return dateFixed;
    },

    selectTurno(turno) {
      if (this.selectedTurno === turno) {
        this.selectedTurno = "";
        this.ofHours = [];
      } else {
        this.selectedTurno = turno;

        const selectedTurn = this.turns.find((turn) => turn.name === turno);
        if (selectedTurn) {
          this.ofHours = selectedTurn.of_hour.map((hour) => ({
            id: hour.id,
            name: hour.name,
            status: hour.status,
          }));
        }
      }
    },

    mtdCitas: function (hour) {
      this.selectedHour = hour;
      this.hourbycita = hour;
      this.modalCitas = {
        title: "CONFIRMACIÓN",
        modal_form: true,
      };
    },
    closeModalCitas: function () {
      this.selectedHour = "";
      this.modalCitas = {
        title: "CONFIRMACIÓN",
        item: [],
        modal_form: false,
        pos: null,
      };
      this.hourbycita = "";
    },

    reservarCita() {
      this.mtdReservation();
    },

    mtdReservation: function () {
      const formattedDate = this.quote.dateStr.split("/").reverse().join("-");
      let ruta = '';
      const hoy = moment().format('DD/MM/YYYY');
      ruta = this.quote.dateStr == hoy ? 'enfermera' : '';

      this.Reservation.selectedSerEs = 6;
      this.post({
        url: this.$store.getters.get__url + "/Call/reservationAsis",
        token: this.$store.getters.get__token,
        params: {
          Campus: this.$store.getters.get__campus,
          Reservation: this.Reservation,
          hour: this.hourbycita,
          date: formattedDate,
          option: this.Reservation.selectedService,
          ruta: ruta,
        },
      })
        .then((response) => {
          this.$refs.SweetAlert.showSuccess(response.message);
          this.events.splice(0, this.events.length);
          this.returnHome();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    returnHome() {
      this.$emit('mtdChangePage', 'home');
    },
    clearService: function () {
      this.Reservation.selectedSerEs = "";
      this.Reservation.selectedPatient = "";
      this.quote.dateStr = "";
      this.Reservation.selectedConRoom = "";
      this.selectedTurno = "";
      this.clinicalHistory = [];
      this.ofHours = [];
    },
  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
#newPatientLink {
  text-decoration: none;
  color: #900052;
}

.btn-outline-custom {
  color: #900052;
  border-color: #900052;
}

.cursor {
  cursor: pointer;
}

.wide-cell {
  padding: 4px;
  font-size: 14px;
}

.align {
  display: block;
  text-align: left;
}

.fc-highlight {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #000000;
}

/*leyenda*/
.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.spam {
  font-size: 14px;
}

.circle {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 50%;
}

.table-responsive {
  max-height: 250px;
  overflow-y: auto;
  display: block;
}

.alerta {
  font-weight: bold;
  color: white;
  background-color: #900052;
  border-color: #900052;
}
</style>

<template>
  <div class="container">
    <div class="row">
      <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-main" @click="returnHome">
          <i class="fas fa-home-lg-alt"></i> Inicio
        </button>
      </div>
    </div>

    <div id="div-modal-cxc" class="d-flex justify-content-center">
      <div id="div-cxc" class="card w-100">
        <div class="card-header text-white h4 bg-main align-center">
          <strong>PERMISOS DE ANULACIONES</strong>
        </div>
        <div class="card-body w-100">
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
              <div class="input-group input-group-sm">
                <span class="input-group-text col-md-3">Desde</span>
                <input
                  type="date"
                  aria-label="shift_date"
                  class="form-control form-control-sm"
                  id="begindate"
                  v-model="filter.begindate"
                  @change="mtdGetData"
                />
              </div>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
              <div class="input-group input-group-sm">
                <span class="input-group-text col-md-3">Hasta</span>
                <input
                  type="date"
                  aria-label="shift_date"
                  class="form-control form-control-sm"
                  id="enddate"
                  v-model="filter.enddate"
                  @change="mtdGetData"
                />
              </div>
            </div>
            <!-- <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 text-center">
              <div class="input-group input-group-sm text-center w-100">
                <button
                  class="btn btn-sm w-100 text-white w-100 bg-main"
                  @click="mtdGetData"
                >
                  <i class="fas fa-search"></i>&nbsp;Buscar
                </button>
              </div>
            </div> -->
            <!-- <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
              <div class="input-group input-group-sm">
                <span class="input-group-text text-white bg-main">
                  <i class="fas fa-search"></i>&nbsp;&nbsp;Filtrar
                </span>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Buscar dni o nombre de paciente, o por número de boleta..."
                  v-model="search"
                />
              </div>
            </div> -->

            <div cclass="col-md-12"
                  id="content-timelinetable">
              <table id="tb-anular" class="table table-bordered border-main">
                <caption class="py-0 my-0"></caption>
                <thead>
                  <tr class="align-center text-white border-white bg-main">
                    <th style="vertical-align: middle" scope="col">N°</th>
                    <th style="vertical-align: middle" scope="col">
                      USUARIO QUE REGISTRA
                    </th>
                    <th style="vertical-align: middle" scope="col">FECHA</th>
                    <th style="vertical-align: middle" scope="col">
                      COMPROBANTE
                    </th>
                    <th style="vertical-align: middle" scope="col">
                      SERIE Y N°
                    </th>
                    <th style="vertical-align: middle" scope="col">MONTO</th>
                    <th style="vertical-align: middle" scope="col">MOTIVO</th>
                    <th style="vertical-align: middle" scope="col">
                      USUARIO AUTORIZA
                    </th>
                    <th style="vertical-align: middle" scope="col">ACCIÓN</th>
                    <th style="vertical-align: middle" scope="col" colspan="1">
                      ENVIAR
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in cpData" :key="index">
                    <th style="vertical-align:middle;" scope="row">{{ index + 1 }}</th>
                    <td style="vertical-align:middle;">{{ item.user.name }} {{ item.user.last_name }}</td>
                    <td class="align-center"> 
                      {{ item.date | formatDate }} {{ item.hour | formatTime }}
                    </td>
                    <td class="align-center">{{ item.of_voucher.document }}</td>
                    <td class="align-left">{{ item.of_voucher.serie }}-{{ item.of_voucher.number }}</td>
                    <td class="align-right">
                      S/ {{ parseFloat(item.of_voucher.amount).toFixed(2) }}
                    </td>
                    <td style="vertical-align:middle;">{{ item.motive_cancel }}</td>
                    <td style="vertical-align:middle;">
                      {{
                        item.user_autoriza_id
                          ? item.user_autoriza.name
                          : "-"
                      }}
                    </td>
                    <td class="align-center" v-html="mtdGetDays(item)"></td>
                    <td class="align-center">
                      <a
                        href="javascript:void(0)"
                        v-if="item.state == 2"
                        onclick=""
                        id="btn-autorizar-anular"
                      >
                        <i class="fas fa-check-circle"></i>
                      </a>
                      <a
                        href="#"
                        v-if="item.state == 3"
                        id="btn-error-anular"
                      >
                        <i class="fas fa-times-circle"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        v-if="item.state == 1"
                        id="btn-pediente-anular"
                        @click="mtdModalProcess(item, index)"
                      >
                        <i class="far fa-circle"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!--paginate-->
            <div
              class="d-flex justify-content-center"
              style="align-items: center"
            >
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a
                      class="page-link page"
                      href="javascript:void(0)"
                      @click="backPAge"
                      >Anterior</a
                    >
                  </li>
                  <li
                    class="page-item"
                    v-for="(item, index) in stepPagination"
                    :key="index"
                  >
                    <a
                      :class="
                        selectPage == item ? 'page-link active' : 'page-link'
                      "
                      href="javascript:void(0)"
                      @click="selectedPage(item)"
                      >{{ item }}</a
                    >
                  </li>
                  <li class="page-item">
                    <a
                      class="page-link page"
                      href="javascript:void(0)"
                      @click="addPAge"
                      >Siguiente</a
                    >
                  </li>
                </ul>
              </nav>
            </div>

          </div>
        </div>
      </div>
    </div>
    <cModalProccessAnularVue
      :title="modalProccess.title"
      :boo_modal="modalProccess.modal_form"
      :data="modalProccess.data"
      @closeModalProccess="closeModalProccess"
      @mtdSendPermission="mtdSendPermission"
    />
  </div>
</template>
  
<script>
  import { mapActions } from 'vuex'
  import moment from "moment";
  import cModalProccessAnularVue from "@/components/Gerencia/modal/cModalProccessAnular.vue";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

  export default {
    name: "c-gerencia-pages-anulaciones",
    components: {
      cModalProccessAnularVue,
      SweetAlert,
    },
    data () {
      return {
        hoy: null,
        filter: {
          begindate: null,
          enddate: null,
        },
        data: [],
        dataShow: [],
        noRecordsMessage: "",
        search: "",
        page: 0,
        selectPage: 1,
        stepPagination: [],
        pos: null,
        modalProccess: {
          title: "",
          modal_form: false,
          data: {
            type: "",
            motive: "",
            days: 0,
          },
        }
      }
    },
    computed: {
      cpData() {
        let bo = false;
        if (this.search.length >= 2 && this.search != "") {
          let q = this.search.toString().toUpperCase();
          this.dataShow = [];
          this.data.forEach((element) => {
            let doc = element.sale.patient.document.toString().toUpperCase();
            let name = element.sale.patient.name.toString().toUpperCase();
            let serie = element.serie.toString().toUpperCase();
            let number = element.number.toString().toUpperCase();
            if (
              doc.includes(q) ||
              name.includes(q) ||
              serie.includes(q) ||
              number.includes(q)
            ) {
              this.dataShow.push(element);
            }
          });
          if (this.dataShow.length === 0 && this.data.length !== 0) {
            this.noRecordsMessage = "No se encontraron resultados de la búsqueda";
          } else {
            this.noRecordsMessage = "";
          }
          bo = true;
        } else {
          let fin = this.selectPage * 5 - 1;
          let ini = fin - 4;
          this.dataShow = [];
          this.data.forEach((element, index) => {
            if (ini <= index && index <= fin) {
              this.dataShow.push(element);
            }
          });
          bo = false;
          this.noRecordsMessage = "";
        }
        /** paginación */
        this.stepPagination = [];
        let cantidad =
          bo == false
            ? parseInt(this.data.length / 5)
            : parseInt(this.dataShow.length / 5);
        let residuo =
          bo == false
            ? parseInt(this.data.length % 5)
            : parseInt(this.dataShow.length % 5);
        cantidad = residuo > 0 ? cantidad + 1 : cantidad;
        for (let index = 0; index < cantidad; index++) {
          this.stepPagination.push(index + 1);
        }
        return this.dataShow;
      },
      cpTotalFiltro() {
        let tot = 0;
        this.dataShow.forEach((element) => {
          tot = parseFloat(tot) + parseFloat(element.amount);
        });
        return parseFloat(tot).toFixed(2);
      },
    },
    created() {
      moment.locale("es");
      this.filter.enddate =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
        this.filter.begindate = this.filter.enddate;
      this.hoy = this.filter.enddate;
      this.mtdGetData();
    },
    methods: {
      ...mapActions(['get', 'post']),
      mtdGetStyle:function(option){
        if (option == this.page) return 'background: #900052';
        return '';
      },
      mtdGetStyleSpan:function(option){
        if (option == this.page) return 'color:white';
        return '';
      },
      returnHome: function () {
        this.$emit("returnHome");
      },
      mtdGetData: function () {
        this.post({
          url: this.$store.getters.get__url + "/Gerencia/anular",
          token: this.$store.getters.get__token,
          params: this.filter,
        })
          .then((response) => {
            this.data = response.data;
            /** paginación */
            let cantidad = parseInt(this.data.length / 5);
            let residuo = parseInt(this.data.length % 5);
            cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            for (let index = 0; index < cantidad; index++) {
              this.stepPagination.push(index + 1);
            }
          })
          .catch((errors) => { console.log(errors); });
      },
      mtdModalProcess: function (item, pos) {
        this.modalProccess.title = "PROCESAR ANULACIÓN";
        this.modalProccess.modal_form = true;
        this.modalProccess.data.id = item.id;
        this.modalProccess.data.type=item.type;
        this.pos = pos;
        this.modalProccess.data.days = moment(this.hoy).diff(
          moment(item.of_voucher.date),
          "days"
        );
        console.log(this.modalProccess);
      },
      closeModalProccess: function () {
        this.pos = null;
        this.modalProccess = {
          title: "",
          modal_form: false,
          data: {
            id: null,
            type: "",
            motive: "",
          },
        };
      },
      mtdSendPermission: function (payload) {
        this.modalProccess.data.type = payload.type;
        this.modalProccess.data.motive = payload.motive;
        this.post({
          url: this.$store.getters.get__url + "/Gerencia/anular/store",
          token: this.$store.getters.get__token,
          params: this.modalProccess,
        })
          .then((response) => {
            if (response.state == 0) {
              if ( this.modalProccess.data.days > 3) {
                let res = JSON.parse(response.data.sunat);
              if (res && res.json && res.json.enlace_del_pdf_ticket) {
                  window.open(res.json.enlace_del_pdf_ticket, '_blank');
              }}
              this.closeModalProccess();
              this.mtdGetData();

              this.$refs.SweetAlert.showSuccess("Registrado con Exito!");
            } else {
              this.$refs.SweetAlert.showError(response.message);
            }
          })

          .catch((errors) => { console.log(errors); });
      },
      /** pagination */
      selectedPage: function (page) {
        this.selectPage = page;
      },
      backPAge: function () {
        this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
      },
      addPAge: function () {
        this.selectPage =
          this.selectPage == this.stepPagination.length
            ? this.stepPagination.length
            : this.selectPage + 1;
      },
      /** diferencia de fecha */
      mtdGetDays: function (data) {
        if (data.state == 2) {
          return "-";
        } else {
          let dif = moment(this.hoy).diff(moment(data.of_voucher.date), "days");
          if (dif > 3) {
            return '<span class="badge text-bg-info">Nota de Crédito</span>';
          } else {
            return '<span class="badge text-bg-info">Anular</span>';
          }
        }
      },
    },
    filters: {
      formatDate(date) {
        return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatTime(time) {
        let arTime = time.split(":");
        if (arTime[0] <= 12) {
          return time + " am";
        } else {
          return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
        }
      },
    },
}
</script>
<style scoped>
#btn-autorizar-anular {
  color: #39b54a;
  font-size: 25px;
}
#btn-autorizar-anular:hover {
  color: #217b2d;
}
#btn-error-anular {
  color: #ff0000;
  font-size: 25px;
}
#btn-error-anular:hover {
  color: #a30404;
}
#btn-pediente-anular {
  color: #ffe600;
  font-size: 25px;
}
#btn-pediente-anular:hover {
  color: #c0ac01;
}
#btn-registro-anular a {
  color: #900052;
}
#btn-registro-anular a:hover {
  color: #65013a;
  align-items: center;
  transform: scale(1.5);
}
.circulo {
  vertical-align: middle;
  border-style: none;
}

@media (max-width: 800px) {
  #btn-open-caja {
    justify-content: center !important;
  }
  #div-tabla-anular {
    overflow-x: auto;
  }
}


/*Paginacion*/
.page-link {
  color: black !important;
}

.page-link.active {
  color: white !important;
  border-color: white !important;
  background: rgb(144, 0, 82);
}
.page {
  color: white !important;
  background: rgb(144, 0, 82);
}
</style>
<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2">
        <div class="row">
            <div class="mb-3 col-md-3">
                <label for="exampleFormControlInput1" class="form-label">Monto Total:
                </label>
                <input v-model="billpay.total" type="text" class="form-control form-control-sm" placeholder="Monto Total" disabled />
            </div>
            <div class="mb-3 col-md-3">
                <label for="exampleFormControlInput1" class="form-label">Monto Pagado:
                </label>
                <input v-model="cpShowPaid" type="text" class="form-control form-control-sm" placeholder="Monto Pagado" disabled />
            </div>
            <div class="mb-3 col-md-3">
                <label for="exampleFormControlInput1" class="form-label">Monto Pendiente:
                </label>
                <input v-model="cpShowPending" type="text" class="form-control form-control-sm" placeholder="Monto Pendiente" disabled />
            </div>
            <div class="mb-3 col-md-3">
                <label for="exampleFormControlInput1" class="form-label">Monto a Pagar:
                </label>
                <input type="text" class="form-control form-control-sm" v-model="amount" @input="validateAmount" :disabled="mtdDisableImput" placeholder="00.00" />
                <span class="text-danger text-alert" v-if="mtdDisableButton && amount">
                    El valor tiene que ser menor o igual a {{ cpShowPending }}</span>
            </div>
        </div>
        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <table id="tb-anular" class="table table-bordered">
              <caption class="mx-0 px-0"></caption>
                <thead class="bg-main" style="font-size: 12px">
                    <tr class="text-white">
                        <th scope="col" class="align-middle">N°</th>
                        <th scope="col" class="align-middle">MONTO PAGADO</th>
                        <th scope="col" class="align-middle">FECHA</th>
                        <th scope="col" class="align-middle">USUARIO</th>
                    </tr>
                </thead>
                <tbody class="border-main" style="font-size: 11px">
                    <tr class="tbody-table" v-for="(item, index) in cpData" :key="index">
                        <td class="align-middle">
                            {{ index + 1 }}
                        </td>
                        <td class="align-middle">
                            {{ item.amount }}
                        </td>
                        <td class="align-middle">
                            {{ item.date }}
                        </td>
                        <td class="align-middle">
                            {{ item.user_name }}
                        </td>
                    </tr>
                    <tr v-if="!cpData.length > 0" class="text-center">
                        <th scope="col" colspan="4">NO HAY PAGOS REGISTRADOS</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdInsertPay" :disabled="mtdDisableButton">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-ordendecompra-addPay",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            amount: "",
            stateInitial: 0,
            newPaid: null,
            newPending: null,
            newDataPayment: [],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        billpay: Object,
        dataBillPayment: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        cpShowPaid() {
            return this.stateInitial == 0 ?
                this.billpay.paid :
                parseFloat(this.newPaid).toFixed(2);
        },
        cpShowPending() {
            return this.stateInitial == 0 ?
                this.billpay.pending :
                parseFloat(this.newPending).toFixed(2);
        },
        cpData() {
            return this.stateInitial == 0 ?
                this.dataBillPayment :
                this.newDataPayment;
        },
        mtdDisableImput() {
            let paid =
                this.stateInitial == 0 ?
                this.billpay.paid :
                parseFloat(this.newPaid).toFixed(2);
            return paid === this.billpay.total;
        },
        mtdDisableButton() {
            const pending = this.stateInitial == 0 ? this.billpay.pending : parseFloat(this.newPending).toFixed(2);
            const billTotal = parseFloat(this.amount);
            if(this.amount.length > 0){
               return billTotal > pending;
            }else{
                return true;
            }
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.amount = sanitizedValue;
        },
        mtdShowData: function () {
            this.get({
                    url: this.$store.getters.get__url +
                        "/Logistica/os/showPayBill/" +
                        this.billpay.bill_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.newDataPayment = response.data;
                })
                .catch((errors) => {});
        },
        mtdInsertPay: function () {
            //bill_id
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/os/storePayBill",
                    token: this.$store.getters.get__token,
                    params: {
                        ...this.billpay,
                        amountDeposited: this.amount,
                    },
                })
                .then((response) => {
                    //modal de confirmacion y recargar el modal
                    Swal.fire({
                        icon: "success",
                        title: "Pago registrado",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                    this.amount = "";
                    this.stateInitial = 1;
                    this.newPaid = response.newPaid;
                    this.newPending = response.newPending;
                    this.mtdShowData();
                })
                .catch((errors) => {});
        },

        mtdClose() {
            (this.newPaid = null), (this.newPending = null), (this.stateInitial = 0);
            this.amountDeposited = null;
            this.amount='';
            this.$emit("mtdcloseModalPay");
        },
        mtdclosemodal() {
            (this.newPaid = null), (this.newPending = null), (this.stateInitial = 0);
            this.amountDeposited = null;
            this.amount='';
            this.$emit('mtdcloseModalPay')
        },

    },
};
</script>

<style scoped>
.text-alert{
    font-size: 9px;
}
</style>

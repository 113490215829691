<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="inputs px-5 pt-3">
        <div class="row w-100">
            <div class="mb-3 col-lg-auto col-md-6">
                    <label for="staticEmail" class=" col-form-label me-2">N° Orden de compra</label>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
                    <input type="text" class="form-control " id="staticEmail" v-model="purcharseOrder.number" disabled>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
                <label for="total" class=" col-form-label me-2">S/TOTAL CON IGV</label>
            </div>
            <div class="mb-3 col-lg-3 col-md-6">
                <input type="text" class="form-control " id="total" v-model="purcharseOrder.total" disabled>
            </div>
        </div>
        <div class=" row">
            <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">Proveedor</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" disabled v-model="purcharseOrder.provider">
            </div>
            <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">RUC</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" disabled v-model="purcharseOrder.ruc">
            </div>
            <div class="mb-3 col-md-4">
                <label for="exampleFormControlInput1" class="form-label">Fecha OC</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" v-model="purcharseOrder.date" disabled>
            </div>
        </div>
        <div class=" row">
            <div class="mb-3 col-md-4 ">
                <label for="num-serie" class="form-label">N° de serie</label>
                <input type="text" class="form-control" id="num-serie" placeholder="N° de serie" v-model="bill.number">
            </div>
            <div class="mb-3 col-md-4">
                <label for="date" class="form-label">Fecha</label>
                <input type="date" class="form-control" id="date" v-model="bill.date">
            </div>
            <div class="mb-3 col-md-4">
                <label for="select-money" class="form-label">Moneda</label>
                <select id="select-money" class="form-control" v-model="bill.money">
                    <option value="soles" selected>Soles</option>
                    <option value="dolares">Dólares</option>
                </select>
            </div>
        </div>
        <div class=" row">
            <div class="mb-3 col-md-4">
                <label for="select-detraction" class="form-label">Detraccion</label>
                <select id="select-detraction" class="form-control" v-model="bill.detraction">
                    <option value="0" selected>No Aplica</option>
                    <option value="1">Aplica</option>
                </select>
            </div>
            <div class="mb-3 col-md-4 ">
                <label for="pay" class="form-label">S/ Total</label>
                <input type="text" class="form-control" id="pay" placeholder="00.00" v-model="bill.total" @input="validateTotal">
                <span class="text-danger text-alert" v-if="cpInputPaid">Monto no permitido</span>
            </div>
            <!-- <div class="mb-3 col-md-4 ">
                <label for="pay" class="form-label">S/ Pagado</label>
                <input type="text" class="form-control" id="pay" placeholder="00.00" v-model="bill.paid" @input="validateAmount" :disabled="cpInputPaid">
            </div> -->
        </div>
        <div class="d-flex justify-content-end">
            <button type="button" class=" border-0 rounded-2 bg-main px-5 py-2 text-white" id="btn-guardar" @click="mtdStore" :disabled="cpButton">GUARDAR</button>
        </div>
    </div>

</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            bill: {
                number: "",
                date: "",
                money: "soles",
                paid: "",
                detraction: "1",
                purchase_orders_id:"",
                provider_id:"",
                total:"",
            }

        };
    },
    watch: {
        purcharseOrder(newvalor, old) {
            this.bill.total = parseFloat(newvalor.pendiente).toFixed(2);
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        purcharseOrder: Object,
    },
    computed: {
        cpButton() {
            const billTotal = parseFloat(this.bill.total);
            const billpaid = parseFloat(this.bill.paid);
            return (this.bill.number === "" || this.bill.date === "" ||  this.bill.total === "");
        },
        cpInputPaid() {
            const billTotal = parseFloat(this.bill.total).toFixed(2);
            const purchaseOrderTotal = parseFloat(this.purcharseOrder.pendiente).toFixed(2);
            return billTotal > purchaseOrderTotal;
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdStore: function () {
            this.bill.purchase_orders_id = this.purcharseOrder.id;
            this.bill.provider_id = this.purcharseOrder.provider_id;
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/oc/storeBill",
                    token: this.$store.getters.get__token,
                    params: this.bill,
                    
                })
                .then((response) => {
                    if (response.state == 0) {
                        
                        Swal.fire({
                            icon: "success",
                            title: "Factura registrada",
                            showConfirmButton: false,
                            timer: 1300,
                        });
                        const type = this.bill.number.substring(0,1) === 'B'? "BOLETA" : "FACTURA";
                        const state = this.purcharseOrder.pending === 0 ? "CONTANDO" : "CREDITO";
                        const title =  "DESPACHO DE LA " + type + " " + this.bill.number +  " - " + state + " / ESTADO: " + response.stateBill; //agregar el estado de la factura
                        this.$emit('mtdShowModalStoreDispatch', { title: title, bill_id: response.id,id:this.purcharseOrder.id});
                        
                    }
                })
                .catch((errors) => {});
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");

            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }

            const decimalIndex = sanitizedValue.indexOf(".");
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(
          0,
          decimalIndex
        )}.${decimalPart.substring(0, 2)}`;
            }
            
            this.bill.paid = sanitizedValue;
        },
        validateTotal(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");

            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }

            const decimalIndex = sanitizedValue.indexOf(".");
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(
          0,
          decimalIndex
        )}.${decimalPart.substring(0, 2)}`;
            }
            
            this.bill.total = sanitizedValue;
        },
        mtdclosemodal() {
            this.bill = {
                number: "",
                date: "",
                money: "soles",
                paid: "",
                detraction: "1",
                total:null,
            };
            this.$emit("closeModalStoreBill");
        },
    },
};
</script>
<style scoped>
#btn-guardar:disabled {
    background-color: #cd4592 !important;
}
.text-alert{
    font-size: 10px;
}
</style>

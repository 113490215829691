<template>
    <div>
      <div class="container">

        <div class="d-flex flex-row-reverse">
            <div class="mt-5 mb-4 btn-home">
                <button type="button" class="btn text-white" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>

        <div id="div-modal-anular" class="w-100">
          <div id="div-anular" class="card w-100">
            <div
              align="center"
              class="card-header text-white h4"
              style="background: #900052"
            >
              <strong>HORARIOS</strong>
            </div>
            <div class="card-body w-100">
              <div class="row">

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2" id="content-timelinetable">
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-home">
                    <button type="button" class=" btn btn-bm-noradius w-100" @click="mtdOpenTurn()">
                        <i class="fas fa-plus"></i> Agregar Turno
                    </button>
                  </div>
                </div>

                <!--tabla-->
                <div class="col-md-12" id="content-timelinetable">
                    <table id="tb-anular" class="table table-borderless align-center-middle">
                        <caption class="py-0 my-0"></caption>
                        <thead>
                            <tr class="text-white table-th text-center text-xs">
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">N°</th>
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">SERVICIO</th>
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">TURNO</th>
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">HORARIOS</th>
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">ESTADO</th>
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <tr class="text-xs table-body" v-for="(item, index) in cpData" :key="index">
                                <td class="border border-5 border-white align-middle">{{ index + 1}}</td>
                                <td class="border border-5 border-white align-middle">{{item.service_name}}</td>
                                <td class="border border-5 border-white align-middle">{{ item.name.toUpperCase() }}</td>
                                <td class="border border-5 border-white align-middle text-center" style="white-space: nowrap;"> 
                                    <div>
                                        <button
                                            class="btn btn-primary btn-sm custom-btn"
                                            @click="mtdGetHorarios(item.id)"
                                        >
                                            <i class="fas fa-eye"></i>
                                            Ver Horario
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center border border-5 border-white align-middle">
                                    <span class=" text-white rounded rounded-2 px-3  bg-success">Activo</span>
                                </td>
                                <td class="text-center border border-5 border-white align-middle">
                                    <div class="d-flex  justify-content-center">
                                        <button class="btn btn-sm btn-primary text-white me-1"
                                        @click="mtdGetDataIdRe(item.id)"
                                        id="btn-edit"
                                        data-bs-toggle="modal"
                                        data-bs-target="#new"
                                        :disabled="item.shift_id === 3"
                                        >
                                            <i class="fa fa-edit"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!--tabla-->

                <!--pag-->
                <div v-if="cpData.length > 0" class="d-flex justify-content-center px-1 mb-4">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-1">
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                            </li>
                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                <a :class="
                    selectPage === 1 ? 'page-link active' : 'page-link'
                  " href="javascript:void(0)" @click="selectedPage(1)">1</a>
                            </li>
                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>

                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                <a :class="
                    selectPage === item ? 'page-link active' : 'page-link'
                  " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                </a>
                            </li>
                            <li v-if="
                  limitedStepPagination[
                    limitedStepPagination.length - 1
                  ] <
                  stepPagination.length - 1
                " class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>
                            <li v-if="
                  limitedStepPagination[
                    limitedStepPagination.length - 1
                  ] !== stepPagination.length
                " class="page-item">
                                <a :class="
                    selectPage === limitedStepPagination.length + 2
                      ? 'page-link active'
                      : 'page-link'
                  " href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>

              </div>
            </div>
          </div>
        </div>
  
        <!--Modal Form-->
        <div
          class="modal fade"
          id="new"
          ref="modalForm"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content col-sm-11 p-0">
              <div class="modal-header mod-h p-3 bg-main">
                <h4
                  class="modal-title text-white mx-4 mt-4"
                  id="exampleModalLabel"
                >
                  <i class="fa fa-clock"></i> Editar Horario
                </h4>
                <div
                  @click="mtdHideModal"
                  style="border: none"
                  class="close text-white mx-2"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  &times;
                </div>
              </div>
              <div class="modal-body">
                <div class="modal-body mr-2 mb-2 ml-2">
                  <div class="row">
  
                     <label for="inputtext" class="mb-3">Seleccione Horario</label>
                     <div class="row">
                        <div class="col">
                          <div class="mb-3">
                            <label for="horaInicio" class="form-label">Desde:</label>
                           
                            <div class="form-group">
                              <!--
                                <select id="horaInicio" name="horaInicio" class="form-control" v-model="horaInicio">
                                    <option v-for="(option, index) in horaOptions" :key="index" :value="option.value">{{ option.label }}</option>
                                </select>
                               -->
                               <select id="horaInicio" name="horaInicio" class="form-control" v-model="horaInicio">
                                <template v-if="selectedItemId == '1'">
                                  <option v-for="(option, index) in horaMa" :key="index" :value="option.value">{{ option.label }}</option>
                                </template>
                                <template v-else-if="selectedItemId == '2'">
                                  <option v-for="(option, index) in horaTa" :key="index" :value="option.value">{{ option.label }}</option>
                                </template>
                              </select>

                            </div>
                          </div>
                        </div>
                        <div class="col">
                          <div class="mb-3">
                            <label for="horaFin" class="form-label">Hasta:</label>
                            <div class="form-group">
                            <!--
                                <select id="horaFin" name="horaFin" class="form-control" v-model="horaFin">
                                    <option v-for="(option, index) in horaOptions" :key="index" :value="option.value">{{ option.label }}</option>
                                </select>
                            -->

                            <select id="horaFin" name="horaFin" class="form-control" v-model="horaFin">
                              <template v-if="selectedItemId == '1'">
                                <option v-for="(option, index) in horaMa" :key="index" :value="option.value">{{ option.label }}</option>
                              </template>
                              <template v-else-if="selectedItemId == '2'">
                                <option v-for="(option, index) in horaTa" :key="index" :value="option.value">{{ option.label }}</option>
                              </template>
                            </select>

                             
                            </div>
                          </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col">
                            <div class="mb-3">
                              <label class="form-label">Rango:&nbsp;</label>
                              <div v-for="option in options" :key="option.value" class="form-check form-check-inline">
                                <input
                                  :id="'rango' + option.value"
                                  name="rango"
                                  type="radio"
                                  class="form-check-input"
                                  :value="option.value"
                                  :checked="rango === option.value"
                                  @change="updateRango(option.value)"
                                />
                                <label class="form-check-label" :for="'rango' + option.value">{{ option.label }}min</label>
                              </div>
                            </div>
                          </div>
                      </div>
  
                    </div>
                    <button 
                      id="btn-close" 
                      type="button" 
                      class="btn btn-secondary me-1 mt-5 mb-4" 
                      data-bs-dismiss="modal"
                      @click="mtdHideModal"
                    >   
                       Cerrar
                    </button>
                    <button
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      type="button"
                      name="accion"
                      class="btn  mt-5 mb-4 w-50 btn-register bg-main text-white"
                      @click="mtdModifyData" 
                      :disabled="cpButtonForm"
                    >
                      Editar
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <!--Modal form-->
          
      </div>
      <cModalDateVue :title="modalDate.title" :boo_modal="modalDate.modal_form" :item="modalDate.item" @closeModalDate="closeModalDate"/>
      <cModalAddTurnVue :title="modalTurn.title" :boo_modal="modalTurn.modal_form" :item="modalTurn.item" @closeModalTurn="closeModalTurn" @registerModalTurn="getData"/>
      <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalAddTurnVue from "./Modals/cModalTurn.vue";
import cModalDateVue from "./Modals/cModalDate.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

export default {
    components: {
        SweetAlert,
        cModalDateVue,
        cModalAddTurnVue
    },
    data() {
    return {
        swal: null,
        dataSchedule: [],
        dataCampus:[],
        estado: "activo",
        schedule: {
        schedule: "",
        id: "",
        state: "",
        //type:"",
        //campu_id:"",
        },
        filter: {
        campus_id:null
        },
        modal: {
        title: "",
        action: "",
        },
        posEdit:null,
        noRecordsMessage:"",
        checkbox:[],
        
        horaInicio:'',
        horaFin:'',
        rango:'',
        options: [ // Opciones de rango
            { value: "15", label: "15" },
            { value: "20", label: "20" },
            { value: "30", label: "30" },
            { value: "40", label: "40" }
        ],
        horaOptions: [
            { value: "00:00", label: "00:00" },
            { value: "01:00", label: "01:00" },
            { value: "02:00", label: "02:00" },
            { value: "03:00", label: "03:00" },
            { value: "04:00", label: "04:00" },
            { value: "05:00", label: "05:00" },
            { value: "06:00", label: "06:00" },
            { value: "07:00", label: "07:00" },
            { value: "08:00", label: "08:00" },
            { value: "09:00", label: "09:00" },
            { value: "10:00", label: "10:00" },
            { value: "11:00", label: "11:00" },
            { value: "12:00", label: "12:00" },
            { value: "13:00", label: "13:00" },
            { value: "14:00", label: "14:00" },
            { value: "15:00", label: "15:00" },
            { value: "16:00", label: "16:00" },
            { value: "17:00", label: "17:00" },
            { value: "18:00", label: "18:00" },
            { value: "19:00", label: "19:00" },
            { value: "20:00", label: "20:00" },
            { value: "21:00", label: "21:00" },
            { value: "22:00", label: "22:00" },
            { value: "23:00", label: "23:00" },
        ],
        horaMa: [
            { value: "05:00", label: "05:00" },
            { value: "06:00", label: "06:00" },
            { value: "07:00", label: "07:00" },
            { value: "08:00", label: "08:00" },
            { value: "09:00", label: "09:00" },
            { value: "10:00", label: "10:00" },
            { value: "11:00", label: "11:00" },
            { value: "12:00", label: "12:00" },
        ],
        horaTa: [
            { value: "13:00", label: "13:00" },
            { value: "14:00", label: "14:00" },
            { value: "15:00", label: "15:00" },
            { value: "16:00", label: "16:00" },
            { value: "17:00", label: "17:00" },
            { value: "18:00", label: "18:00" },
            { value: "19:00", label: "19:00" },
            { value: "20:00", label: "20:00" },
            { value: "21:00", label: "21:00" },
            { value: "22:00", label: "22:00" },
            { value: "23:00", label: "23:00" },
        ],
        data: [],
        selectedItemId: '',
        modalDate: {
            title: "HORARIOS",
            item: [],
            modal_form: false,
            pos: null,
        },
        modalTurn: {
            title: "REGISTRAR TURNO",
            item: [],
            modal_form: false,
            pos: null,
        },
        //Pag
        dataShow: [],
        page: 0,
        selectPage: 1,
        stepPagination: [],
        totalPagesToShow: 4,
    };
    },

created() {
    this.getData();

},
watch: {
    selectPage() {
        this.calculateData(7);
    },
},
computed: {
    cpDisabled: function(){
    if(this.filter.campus_id){
        return false ;      
    }
        return true ;
    },
    cpButtonForm: function () {
      if (
          !this.horaInicio ||
          !this.horaFin ||
          !this.rango ||
          this.horaInicio >= this.horaFin
      ) {
          return true; 
      }
      return false; 
    },

    cpData() {
        return this.dataShow;
    },

    limitedStepPagination() {
        const totalPages = this.stepPagination.length;
        const currentPage = this.selectPage;

        let startPage = 1;
        let endPage = totalPages;
        if (totalPages > this.totalPagesToShow) {
            const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

            if (currentPage > halfPagesToShow) {
                startPage = currentPage - halfPagesToShow;
                endPage = currentPage + halfPagesToShow - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = totalPages - this.totalPagesToShow + 1;
                }
            } else {
                endPage = this.totalPagesToShow;
            }
        }

        return Array.from({
                length: endPage - startPage + 1,
            },
            (_, i) => startPage + i
        );
    },
    
    },

    methods: {
    ...mapActions(["get", "post"]),

      getData: function () {
          this.get({
                  url: this.$store.getters.get__url + "/Maintainer/indexHours",
                  token: this.$store.getters.get__token,
          }).then(response => {
              this.data = response.data;
              this.calculateData(7);
          })
          .catch(error => {
              console.log(error);
          })
      },

      calculateData(items) {
          if (!this.data || !this.data.length) {
              return (this.dataShow = []);
          }
          let filteredData = this.data;
          let indexInitial = this.selectPage;
          /*
          if (this.search.length >= 2 && this.search != "") {
              let query = this.search.toString().toUpperCase();
              filteredData = this.data.filter((element) => {
                  if (element.of_patient_name !== null) {
                      let name = element.of_patient_name.toString().toUpperCase();
                      let document = element.of_patient_document.toString();
                      return name.includes(query) || document.includes(query);
                  }
              });
              indexInitial = 1;
          }
          */

          let totalPages = Math.ceil(filteredData.length / items);
          this.stepPagination = Array.from({
                  length: totalPages,
              },
              (_, index) => index + 1
          );
          let startIndex = (indexInitial - 1) * items;
          let endIndex = startIndex + (items - 1);
          this.dataShow = filteredData.slice(startIndex, endIndex + 1);
          this.count = this.count + 1;
      },

      updateRango(value) {
          this.rango = value; 
      },

      mtdHideModal: function () {
          this.modal.title = "";
          this.modal.action = "";
          this.schedule = {
              schedule: "",
              id: "",
              state: "",
              type: "",
          };
          this.posEdit = null;
          (this.checkbox = null), (this.horaInicio = "");
          this.horaFin = "";
          this.rango = "";
      },

      //metodo calcular Rango
      calculateRango(horaInicio, horaFin) {
        const inicio = new Date(`2000-01-01 ${horaInicio}`);
        const fin = new Date(`2000-01-01 ${horaFin}`);
        const diff = Math.abs(fin - inicio); // Diferencia en milisegundos
        const diffMinutes = Math.floor(diff / 1000 / 60); // Diferencia en minutos
        return diffMinutes.toString();
      },

      mtdGetDataIdRe: function (id) {
        this.get({
          url: this.$store.getters.get__url + "/Maintainer/hours/" + id + "/showRe",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.schedule = response;

          this.selectedItemId = response.shift_id;
          const scheduleArray = this.schedule.schedule.split(",");
          this.horaInicio = scheduleArray[0];
          this.horaFin = scheduleArray[scheduleArray.length - 1];
          this.rango = scheduleArray.length > 1 ? this.calculateRango(scheduleArray[0], scheduleArray[1]) : '';
            const horaInicioIndex = this.horaOptions.findIndex(option => option.value === this.horaInicio);
          // Si el valor está presente, establecer el índice correspondiente en horaInicio
          if (horaInicioIndex !== -1) {
              this.horaInicio = this.horaOptions[horaInicioIndex].value;
          }
        })
        .catch((errors) => {});
      },

      //metodo modificar
      mtdModifyData: function () {

        // Obtener los valores seleccionados de los elementos select
        const horaInicio = document.getElementById('horaInicio').value;
        const horaFin = document.getElementById('horaFin').value;
        const rango = document.querySelector('input[name="rango"]:checked').value;


        if (!horaInicio || !horaInicio.trim() || !horaFin || !horaFin.trim() || !rango) {
              this.$refs.SweetAlert.showWarning("Por favor complete todos los campos");
              return;
          }

        // Convertir las horas seleccionadas a objetos Date para facilitar el cálculo
        const fechaInicio = new Date(`2000/01/01 ${horaInicio}`);
        const fechaFin = new Date(`2000/01/01 ${horaFin}`);

        // Calcular los intervalos de rango en minutos entre las horas de inicio y fin
        const intervalos = [];
        let fechaActual = new Date(fechaInicio);

        while (fechaActual <= fechaFin) {
          intervalos.push(
            fechaActual.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })
          );
          fechaActual.setMinutes(fechaActual.getMinutes() + parseInt(rango));
        }

        // Guardar los intervalos en this.schedule.schedule
        this.schedule.schedule = intervalos.join(',');

        this.$refs.SweetAlert.showConfirmSimple2("","Desea Editar las Horas en ese Rango","warning","Confirmar") 
        .then((result) => {
            if (result.value) {
            
              this.post({
                url: this.$store.getters.get__url + "/Maintainer/hours/update",
                token: this.$store.getters.get__token,
                params: this.schedule,
              })
              .then((response) => {
                  console.log(response);
                if (response.state == 0) {
                    // todo correto 
                    this.$refs.SweetAlert.showSuccess("Modificación Exitosa");
                    this.mtdHideModal();
                    this.checkbox=null;
                    this.getData(); 
                } else {
                  this.$refs.SweetAlert.showError("Error");
                }
              })
              .catch((errors) => {
                this.$refs.SweetAlert.showError("Error: " + errors);
              });
            }
        });
      },

      //metodo retornar a Home
      returnHome() {
          this.$emit('mtdChangePage', 'home')
      },

      closeModalDate: function () {
          this.modalDate = {
              title: "HORARIOS",
              item: [],
              modal_form: false,
              pos: null,
          };
      },

      mtdGetHorarios: function (id) {
        this.get({
          url: this.$store.getters.get__url + "/Maintainer/hours/" + id + "/show",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.schedule = response;
          if(response.schedule == ""){
            this.$refs.SweetAlert.showWarning("Sin Horarios");
          } else{
          this.modalDate = {
                title: "HORARIOS",
                modal_form: true,
            };
          this.modalDate.item = response.schedule.split(",");
          this.selectedItemId = id;
          }
        })
        .catch((errors) => {
        });
      },

      mtdOpenTurn: function () {
          this.modalTurn = {
              title: "REGISTRAR TURNO",
              modal_form: true,
          };
      },

      closeModalTurn: function (){
        this.modalTurn = {
            title: "REGISTRAR TURNO",
            item: [],
            modal_form: false,
            pos: null,
        };
      },

      /** paginación */
      backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
      },
      nextPage() {
          this.selectPage =
              this.selectPage < this.stepPagination.length ?
              this.selectPage + 1 :
              this.stepPagination.length;
      },
      selectedPage(page) {
          this.selectPage = page;
      },

    },
};
</script>

<style  scoped>

.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}
    
</style>


<template>
  <div class="p-3">
    <div id="btn_home" class="c-app align-items-center">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="text-end">
            <div  class="mt-2 p-5">
              <div class="btn-group" role="group" >
                <button
                  type="button"
                  class="btn btn-bm-noradius bg-main text-white h-100"
                  @click="mtdBackCampus"
                  
                >
                  <i class="fas fa-recycle"></i><br />Cambiar de sede
                </button>
              </div>
              &nbsp;
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-bm-noradius bg-main text-white h-100"
                  @click="mtdCloseBead"
                >
                  <i class="fas fa-cash-register"></i><br />Cerrar
                </button>
              </div>
              <!--
              &nbsp;
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-bm-noradius bg-main text-white h-100"
                  @click="mtdOpenBead"
                >
                  <i class="fas fa-cash-register"></i><br />Abrir
                </button>
              </div>
              -->
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-12 col-sm-6">
          <div class="text-center p-5">
            <div
              class="d-grid gap-2 mx-auto col-lg-3 col-md-3 col-sm-12 col-xs-12"
            >
              <button  v-if="bead.of_box.name!='CAJA CHICA'"
                class="btn btn-lg bg-main text-white mt-100"
                @click="generarVenta"
                type="button"
              >
                VENTA
              </button>
              <button v-if="bead.of_box.name=='CAJA CHICA'"
                class="btn btn-lg bg-main text-white"
                @click="generarEgreso"
                type="button"
              >
                EGRESO
              </button>
            </div>
          </div>
        </div>

        <div class="col-md-12 col-lg-12 col-sm-6">
          <div class="p-1" :style="bead.of_box.name === 'CAJA CHICA' ? { minHeight: '10px', minWidth: '600px' } : {}">
            <div class="col-md-6 px-sm-2 px-md-4" v-if="bead.of_box.name!='CAJA CHICA'">
              <div class="row d-flex">
                <div id="btn_refresh" class="col-md-12">
                  <button @click="mtdCleanClient" type="button" class="btn">
                    <i class="fa fa-repeat"></i>
                  </button>
                </div>
                <div class="col-md-3 p-2 mt-200">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-on:keypress="isNumber($event)"
                    maxlength="12"
                    placeholder="Documento"
                    aria-label=".form-control-lg example"
                    v-model="client.document"
                    @keyup.enter="mtdSearchDocument"
                  />
                </div>
                <div class="col-md-7 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-model="client.fullName"
                    :disabled="disabledPatient"
                    placeholder="Nombres y apellidos del paciente"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-md-2 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-on:keypress="isNumber($event)"
                    v-model="client.yearOld"
                    maxlength="3"
                    placeholder="Edad"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-md-3 p-2 mt-200">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-on:keypress="isNumber($event)"
                    maxlength="12"
                    @keyup.enter="mtdSearchDocumentTutor"
                    placeholder="Documento Tutor"
                    aria-label=".form-control-lg example"
                    v-model="client.document_tutor"
                  />
                </div>
                <div class="col-md-9 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-model="client.fullName_tutor"
                    placeholder="Nombre Tutor"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <!-- <div class="col-md-4 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-model="client.fullName"
                    placeholder="Teléfono tutor"
                    aria-label=".form-control-lg example"
                  />
                </div> -->
                <div class="col-md-3 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-on:keypress="isNumber($event)"
                    v-model="client.celphone"
                    maxlength="9"
                    placeholder="Teléfono"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-md-4 p-2">
                  <input
                    class="form-control form-control-lg inpunt-rounded"
                    type="text"
                    v-model="client.email"
                    placeholder="Correo"
                    aria-label=".form-control-lg example"
                  />
                </div>
                <div class="col-md-5 p-2">
                  <select  v-model="client.referencia"  class="form-control form-control-lg inpunt-rounded" 
                  aria-label=".form-select-sm example">
                    <option value="" selected disabled>Seleccione...</option>
                    <option  v-for="(item,index) in medios" :key="index" :value="item.id">{{ item.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/>
  </div>
</template>

<script>
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
export default {
  name: 'HomeComponent',
  components: {
        SweetAlert
    },
  props: {
    bead:{
      type:Object,
    },
    verifBead: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      page: null,
      swal:null,
      disabledPatient:true,
      client: {
        document: "",
        document_tutor: "",
        celphone: "",
        email: "",
        fullName: "",
        fullName_tutor: "",
        yearOld: "",
        type:"particular",
        referencia:"",
      },
      insurances: [],
      medios:[],
    }
  },
  created () {
    this.mtdMedios();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetStyle:function(option){
      if (option == this.page) return 'background: #900052';
      return '';
    },
    mtdCleanClient: function () {
      this.client = {
        document: "",
        document_tutor: "",
        celphone: "",
        email: "",
        fullName: "",
        fullName_tutor: "",
        yearOld: "",
        id: "",
      };
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page) return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    },
    mtdSearchDocument: function () {
      if (this.client.document.length == 8) { 
        this.get({
          url: this.$store.getters.get__url + "/Consultas/Admision/" + this.client.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.boo== 3) {
               this.client.document = "";
               this.$refs.SweetAlert.showWarning(response.data.error);  
               this.disabledPatient=false;
               this.client.fullName = "";
               this.client.id='';
                this.client.celphone ='';
                this.client.email = '';
                this.client.yearOld = '';
                this.client.document_tutor ='';
                this.client.fullName_tutor ='';
            } else {
              this.client.fullName = response.data.name;
               if (response.data.patient_id) {
                this.client.id=response.data.patient_id;
                this.client.celphone = response.data.phone;
                this.client.email = response.data.email;
                this.client.yearOld = response.data.year;
                this.client.document_tutor = response.data.document_tutor;
                this.client.fullName_tutor = response.data.name_tutor;
              }
            }
          })
          .catch(() => {
          });
      }else{
        this.disabledPatient=false;
        this.client.celphone= "";
        this.client.email= "";
        this.client.fullName="";
        this.client.yearOld= "";
      }
    },
    mtdMedios(){
      this.post({
          url: this.$store.getters.get__url + "/Admision/medios",
          token: this.$store.getters.get__token,
        }).then((response) => { 
          this.medios = response.data;
          })
          .catch(() => {
          });
    },
    mtdSearchDocumentTutor: function () {
      if (this.client.document_tutor.length== 8) { 
        this.get({
          url: this.$store.getters.get__url + "/Consultas/" + this.client.document_tutor,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.boo== 3) {
               this.client.document_tutor = "";
               this.$refs.SweetAlert.showWarning(response.data.error);  
               this.client.fullName_tutor = "";
            } else {
              this.client.fullName_tutor = response.data.name + " " + response.data.last_name;
            }
          })
          .catch(() => {
          });
      }else{
        this.client.fullName_tutor="";
      }
    },
    mtdVerifClient: function () {
      if (this.client.document.trim().length === 0) {
            return { msg: "Falta ingresar el documento del cliente.", status: false };
        } else if (this.client.fullName=='') {
            return { msg: "Falta ingresar el nombre completo del cliente.", status: false };
        } else if (this.client.yearOld=='' ||!this.client.yearOld) {
            return { msg: "Ingrese la Edad", status: false };
        }
        else if ((this.client.yearOld < 18 || this.client.yearOld > 65) &&
           ((this.client.document_tutor== "" ||!this.client.document_tutor)||
            (this.client.fullName_tutor==''||!this.client.fullName_tutor))) {
            return { msg: "Ingrese Datos del Tutor", status: false };
        }
        else if (this.client.celphone==''||!this.client.celphone) {
            return { msg: "Ingrese  un número de Teléfono", status: false };
        }
         else if (this.client.referencia==''||!this.client.referencia) {
            return { msg: "Seleccione Medio de Comunicación.", status: false };
        }
        // else if (this.client.celphone =="") {
        //     return { msg: "Ingrese  un Número de Teléfono Celular.", status: false };
        // }
        // else if (this.client.email =="") {
        //     return { msg: "Ingrese  un Email.", status: false };
        // }
         else {
            return { msg: "Cliente válido.", status: true };
        }

   },
    generarVenta() {
      if (this.mtdVerifClient().status == true) { 
        this.$emit("mtdChangePage", "venta", this.client);
      } else {
        this.$refs.SweetAlert.showWarning(this.mtdVerifClient().msg);  
      }
    },
    generarEgreso() {
      this.$emit("mtdChangePage", "egreso", this.client);
    },
    mtdCloseBead() {
      this.$emit("mtdChangePage", "reporteClose");
    },
    mtdOpenBead(){
      this.$emit("mtdChangePage", "open");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
  },
}
</script>

<style scoped>
#btn_home {
  z-index: 1;
}
#form_home {
  z-index: 2;
  bottom: 100px;
}
#div_login {
  background: #ffffff;
  height: 100vh;
  border: 5px solid #900052;
}
#btn_refresh button {
  font-size: 25px;
  align-items: center;
}
#btn_refresh button:hover {
  align-items: center;
  transform: scale(1.5);
}
#btn-open-caja {
  justify-content: center !important;
}
@media (max-width: 800px) {
  #form_home {
    z-index: 1;
    position: relative !important;
    margin-bottom: 30px !important;
  }
}
@media (min-width: 800px) {
  #form_home {
    position: absolute !important;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 844px) {
  #form_home {
    position: relative !important;
    margin-bottom: 30px !important;
  }
}
@media (min-width: 916px) {
  #form_home {
    position: absolute !important;
    margin-bottom: -40px !important;
  }
}
@media (min-width: 1024px) {
  #form_home {
    position: relative !important;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 1025px) {
  #form_home {
    position: absolute !important;
  }
}
</style>
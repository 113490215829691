<template>
  <div>
    <div class="container-history text-center mt-2">
      <div class="d-flex title-option">
        <div class="btn-home mt-3 mb-2 ms-auto">
          <button
            type="button"
            class="btn btn-outline-main"
            @click="returnHome()"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>

      <div id="div-modal-anular" class="w-100 mt-3">
        <div id="div-anular" class="card w-100">
          <div class="card-header text-white h5 bg-main text-center">
            <strong>BUSCAR CITAS DE PACIENTE</strong>
          </div>
          <div class="card-body w-100">
            <div class="row">
              <div class="col-md-12 py-2">
                <div class="row d-flex">
                  <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                      <div>
                        <v-select
                          id="patientSelect"
                          class="text-dark form-control"
                          :options="patients"
                          v-model="Reservation.selectedPatient"
                          @input="handleSelectItemClickPa"
                          :reduce="(name) => name.id"
                          label="name"
                          placeholder="- Buscar Paciente -"
                          required
                          :clearable="false"
                        />
                      </div>
                    </div>
                  </div>
                  <!--
                          <div class="btn-home col-lg-2 col-md-2 mb-2 mt-2">
                              <button type="button" class="btn w-100"
                                  :class="{ 'btn-outline-custom': isFilterDisabled }"
                                  :disabled="isFilterDisabled" 
                                  @click="mtdReserva">
                                  <i class="fas fa-search fa-sm"></i>&nbsp; Buscar
                              </button>
                          </div>
                          -->
                </div>
              </div>

              <!--tabla-->
              <div
                class="col-md-12"
                id="content-timelinetable"
                v-if="cpData.length > 0"
              >
                <div>
                  <table id="tb-anular" class="table table-borderless">
                    <caption class="py-0 my-0"></caption>
                    <thead>
                      <tr class="text-white table-th text-center">
                        <th
                          scope="col"
                          colspan="1"
                          class="align-middle border border-5 bg-main border-white"
                        >
                          FECHA RESERVADA
                        </th>
                        <th
                          scope="col"
                          colspan="1"
                          class="align-middle border border-5 bg-main border-white"
                        >
                          SERVICIO
                        </th>
                        <th
                          scope="col"
                          colspan="1"
                          class="align-middle border border-5 bg-main border-white"
                        >
                          PRODUCTO
                        </th>
                        <th
                          scope="col"
                          colspan="1"
                          class="align-middle border border-5 bg-main border-white"
                        >
                          ESTADO RESERVA
                        </th>
                        <th
                          scope="col"
                          colspan="1"
                          class="align-middle border border-5 bg-main border-white"
                        >
                          ESTADO PAGO
                        </th>
                        <th
                          scope="col"
                          colspan="1"
                          class="align-middle border border-5 bg-main border-white"
                        >
                          ACCIONES
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        class="text-xs table-body"
                        v-for="(item, index) in cpData"
                        :key="index"
                      >
                        <td
                          class="border border-5 border-white align-left align-middle"
                        >
                          {{ formatDate(item.date_reservation) }}&nbsp;{{
                            item.date_hour
                          }}
                        </td>
                        <td
                          class="border border-5 border-white align-left align-middle"
                        >
                          {{ item.of_service_name }}
                        </td>
                        <td
                          class="border border-5 border-white align-left align-middle"
                        >
                          {{ item.of_product_name }}
                        </td>
                        <td
                          class="border border-5 border-white align-center align-middle"
                          v-html="mtdGetStatus(item.status)"
                        >
                          
                        </td>
                        <td
                          class="border border-5 border-white align-left align-middle"
                        >
                          <div
                            v-if="item.state == 2"
                            class="badge text-bg-danger p-2"
                          >
                            <strong>NO PAGADO</strong>
                          </div>
                          <div
                            v-else-if="item.state == 0"
                            class="badge text-bg-success p-2"
                          >
                            <strong>PAGADO</strong>
                          </div>
                          <div
                            v-else-if="item.state == 1"
                            class="badge text-bg-warning p-2"
                          >
                            <strong>PAGADO PARCIALMENTE</strong>
                          </div>
                        </td>
                        <td
                          class="border border-5 border-white align-left align-middle"
                        >
                          <button
                            v-if="item.past == 0"
                            class="btn btn-sm btn-success text-white me-2"
                            @click="
                              mtdChangePage(
                                item.id,
                                item.date_reservation,
                                item.of_service_id,
                                item.date_hour,
                                item.status
                              )
                            "
                          >
                            REPROGRAMAR
                          </button>
                          <button
                            class="btn btn-sm btn-danger text-white"
                            @click="mtdDeleteRe(index)"
                          >
                            ELIMINAR
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!--pag-->
              <div
                v-if="cpData.length > 0 && showTable"
                class="d-flex justify-content-center px-1 mb-4"
              >
                <nav aria-label="Page navigation example">
                  <ul class="pagination mb-1">
                    <li class="page-item">
                      <a
                        class="page-link page"
                        href="javascript:void(0)"
                        @click="backPage"
                        >Anterior</a
                      >
                    </li>
                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                      <a
                        :class="
                          selectPage === 1 ? 'page-link active' : 'page-link'
                        "
                        href="javascript:void(0)"
                        @click="selectedPage(1)"
                        >1</a
                      >
                    </li>
                    <li
                      v-if="limitedStepPagination[0] > 2"
                      class="page-item disabled"
                    >
                      <span class="page-link">...</span>
                    </li>

                    <li
                      v-for="(item, index) in limitedStepPagination"
                      :key="index"
                      class="page-item"
                    >
                      <a
                        :class="
                          selectPage === item ? 'page-link active' : 'page-link'
                        "
                        href="javascript:void(0)"
                        @click="selectedPage(item)"
                        >{{ item }}
                      </a>
                    </li>
                    <li
                      v-if="
                        limitedStepPagination[
                          limitedStepPagination.length - 1
                        ] <
                        stepPagination.length - 1
                      "
                      class="page-item disabled"
                    >
                      <span class="page-link">...</span>
                    </li>
                    <li
                      v-if="
                        limitedStepPagination[
                          limitedStepPagination.length - 1
                        ] !== stepPagination.length
                      "
                      class="page-item"
                    >
                      <a
                        :class="
                          selectPage === limitedStepPagination.length + 2
                            ? 'page-link active'
                            : 'page-link'
                        "
                        href="javascript:void(0)"
                        @click="selectedPage(stepPagination.length)"
                        >{{ stepPagination.length }}
                      </a>
                    </li>
                    <li class="page-item">
                      <a
                        class="page-link page"
                        href="javascript:void(0)"
                        @click="nextPage"
                        >Siguiente</a
                      >
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cModalReservaVue
      :title="modalReserva.title"
      :boo_modal="modalReserva.modal_form"
      @closeModalReserva="closeModalReserva"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalReservaVue from "./Modals/cModalReserva.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import moment from "moment";

export default {
  name: "c-call-reservas",
  components: {
    cModalReservaVue,
    SweetAlert,
  },
  data() {
    return {
      swal: null,
      viewInput: null,
      dataShow: [],
      page: 0,
      selectPage: 1,
      stepPagination: [],
      totalPagesToShow: 4,
      pos: 0,
      showCalendar: false,
      modalReserva: {
        title: "CITAS DE PACIENTE",
        item: [],
        modal_from: false,
        pos: null,
      },
      patients: [],
      citaRe: [],
      Reservation: {
        selectedPatient: "",
      },
      selectedPatient: "",
      showTable: false,
    };
  },
  watch: {
    selectPage() {
      this.calculateData(10);
    },
  },
  computed: {
    isFilterDisabled() {
      return !this.Reservation.selectedPatient;
    },

    cpData() {
      return this.dataShow;
    },
    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from(
        {
          length: endPage - startPage + 1,
        },
        (_, i) => startPage + i
      );
    },
  },
  created() {
    this.mtdGetPatient();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetPatient: function () {
      this.post({
        url: this.$store.getters.get__url + "/Call/patients",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.patients = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleSelectItemClickPa: function (patientSelect) {
      const position = this.patients.findIndex(
        (patient) => patient.id === patientSelect.id
      );
      this.pos = position + 1;
      this.citaRe = [];
      this.showTable = false;
      this.mtdReserva();
    },
    returnHome() {
      this.$emit("mtdChangePage", "home");
    },

    mtdReserva: function () {    
      this.showTable = true;
      this.post({
        url: this.$store.getters.get__url + "/Call/citaReservation",
        token: this.$store.getters.get__token,
        params: {
          Campus: this.$store.getters.get__campus,
          patienId: this.Reservation.selectedPatient,
        },
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.citaRe = response.data;
            this.calculateData(10);
          } else {
            this.$refs.SweetAlert.showWarning("Sin Reservas");
            this.calculateData(10);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    calculateData(items) {
      if (!this.citaRe || !this.citaRe.length) {
        return (this.dataShow = []);
      }
      let filteredData = this.citaRe;
      let totalPages = Math.ceil(filteredData.length / items);
      this.stepPagination = Array.from(
        {
          length: totalPages,
        },
        (_, index) => index + 1
      );
      let startIndex = (this.selectPage - 1) * items;
      let endIndex = startIndex + (items - 1);
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      //console.log(this.dataShow);
    },

    mtdDeleteRe: function (index) {
      this.$refs.SweetAlert.showConfirmSimple2(
        "",
        "Eliminar Reserva",
        "warning",
        "Si, Confirmar!"
      ).then((result) => {
        if (result.value) {
          this.confirmDelete(index);
        }
      });
    },

    confirmDelete(index) {
      const item = this.cpData[index];
      this.post({
        url: this.$store.getters.get__url + "/Call/deleteReservation",
        token: this.$store.getters.get__token,
        params: {
          id: item.id,
        },
      })
        .then((response) => {
          if (response.state == 1) {
            this.$refs.SweetAlert.showError("Error");
          } else {
            this.$refs.SweetAlert.showSuccess(response.message);
            this.mtdReserva();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    closeModalReserva: function () {
      this.modalReserva = {
        title: "CITAS DE PACIENTE",
        item: [],
        modal_form: false,
        pos: null,
      };
    },

    mtdChangePage: function (reserId, date, serId,hour,state) {
      this.$emit(
        "mtdChangePage",
        "reprogramar",
        reserId,
        date,
        serId,
        this.Reservation.selectedPatient,
        hour,
        state
      );
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    mtdGetStatus: function(item){
        let html = "";
        switch (item) {
            case 1:
                html = "<span class='badge badge-sm text-bg-info p-1'>Registrado</span>"
                break;
            case 2: 
                html = "<span class='badge badge-sm text-bg-warning p-1'>Reservado sin pago</span>"
                break;
            case 3: 
                html = "<span class='badge badge-sm text-bg-warning p-1'>Reservado y pagado</span>"
                break;
            case 4: 
                html = "<span class='badge badge-sm text-bg-primary p-1'>Checkin</span>"
                break;
            case 5: 
                html = "<span class='badge badge-sm text-bg-info p-1'>Alta-checkout</span>"
                break;
            case 6: 
                html = "<span class='badge badge-sm text-bg-danger p-1'>Cancelado</span>"
                break;
            default:
                break;
        }
        return html;
    },

    // pagination
    backPage() {
      this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
    },
    nextPage() {
      this.selectPage =
        this.selectPage < this.stepPagination.length
          ? this.selectPage + 1
          : this.stepPagination.length;
    },
    selectedPage(page) {
      this.selectPage = page;
    },
  },
};
</script>

<style scoped>
.btn-outline-custom {
  color: #900052;
  border-color: #900052;
}

.wide-cell {
  padding: 4px;
  font-size: 14px;
}

.table-th > th {
  border-radius: 15px;
}
.table-body > td {
  background: #eaecee;
  border-radius: 15px;
}

.align {
  display: block;
  text-align: left;
}
</style>

<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0 ">
            <div class="mb-2 col-xl-4 px-1">
                <label for="name" class="form-label">Nombre</label>
                <input type="text" class="form-control form-control-sm" placeholder=" Nombre" v-model="data_detail.name">
            </div>
            
            <div class="mb-2 col-xl-4 px-1">
                <label for="name" class="form-label">Marca</label>
                <select v-model="data_detail.brand_id" class="form-control form-control-sm" aria-label=".form-select-sm example">
                    <option value='' disabled selected>Selecione...</option>
                    <option v-for="(brand,index) in dataBrands" :key="index" :value="brand.id">{{ brand.name }}</option>
                </select>
            </div>
            <div class="mb-2 col-xl-4 px-1">
                <label for="name" class="form-label">Categoria</label>
                <select v-model="data_detail.categoria_id" class="form-control form-control-sm" aria-label=".form-select-sm example">
                    <option value='' disabled selected>Selecione...</option>
                    <option v-for="(categoria,index) in dataCategoria" :key="index" :value="categoria.id">{{ categoria.name }}</option>
                </select>
            </div>
        </div>
        <div class="row mx-0 px-0 ">
            <div class="mb-2 col-xl-4 col-12 px-1">
                <label for="name" class="form-label">Molecula</label>
                <input type="text" class="form-control form-control-sm" placeholder="molecula" v-model="data_detail.molecule">
            </div>
            <div class="mb-2 col-xl-4  col-12 px-1">
                <label for="name" class="form-label">Nombre Comercial</label>
                <input type="text" class="form-control form-control-sm" placeholder="Nombre Comercial" v-model="data_detail.comercial">
            </div>
            <div class=" mb-2 col-xl-4  col-12 px-1">
                <label for="name" class="form-label">Presentacion</label>
                <input type="text" class="form-control form-control-sm" placeholder="Presentacion" v-model="data_detail.presentacion">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class=" mb-2 col-xl-3  col-12 px-1">
                <label for="price_cost" class="form-label">Concentración</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.concentration"  placeholder="concentración">
            </div>
            <div class=" mb-2 col-xl-3  col-12 px-1">
                <label for="price_cost" class="form-label">Costo</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_cost" @input="validatePriceCost" placeholder="0.00">
            </div>
            <div class=" mb-2 col-xl-3   col-12 px-1">
                <label for="price_sale" class="form-label">Venta</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_sale" @input="validatePriceSale" placeholder="0.00">
            </div>
            <div class=" mb-2 col-xl-3   col-12 px-1">
                <label for="name" class="form-label">Descuento</label>
                <input type="text" class="form-control form-control-sm" placeholder="0.00" @input=" validatePriceDescuento" v-model="data_detail.descuento">
            </div>
           
        </div>
        <div class="row mx-0 px-0">
            <div class=" mb-2 col-xl-6   col-12 px-1">
                <label for="name" class="form-label">Fecha</label>
                <input type="date" class="form-control form-control-sm">
            </div>
            <div class=" mb-2 col-xl-6   col-12 px-1">
                <label for="name" class="form-label">Lote</label>
                <input type="text" class="form-control form-control-sm" placeholder="Lote">
            </div>
        </div>

        <div class="row mx-0 px-0">
            <div class="col-md-12 mb-2 px-1" v-if="num == 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="data_detail.state == 1 ? true : false" @change="mtdChangeState" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">{{ data_detail.state == 1 ? "Activo" : "Inactivo" }}</label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="mtdDisabled">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-farmacia",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                brand_id: '',
                categoria_id:'',
                name: '',
                molecule: '',
                comercial: '',
                presentacion: '',
                price_cost: '',
                price_sale: '',
                descuento: '',
                concentration:'',
                lote: '',
                state: 1
            },
        
        };
    },
    computed: {
        mtdDisabled: function () {
            return !( this.data_detail.name !== '' && this.data_detail.brand_id !== '' &&
                this.data_detail.price_cost !== '' && this.data_detail.price_sale !== '' &&
                this.data_detail.molecule !== '' && this.data_detail.comercial !== '' &&
                this.data_detail.presentacion !== '' && this.data_detail.concentration !=='');
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataBrands: {
            type: Array,
            default: null
        },
        dataCategoria: {
            type: Array,
            default: null
        },
    },
    watch: {
        payload: {
            handler(newVal) {

                if (newVal) {
                    if (this.num == 1) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
                this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdHidden: function () {
            this.data_detail = {
                categoria_id:'',
                brand_id: '',
                name: '',
                molecule: '',
                comercial: '',
                presentacion: '',
                price_cost: '',
                price_sale: '',
                descuento: '',
                lote: ''
            }
        },
        mtdClose() {
            this.mtdHidden()
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num);
            this.mtdHidden()
        },
       
        validatePriceCost(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_cost = sanitizedValue;
        },
        validatePriceDescuento(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.descuento = sanitizedValue;
        },
        validatePriceSale(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_sale = sanitizedValue;
        },
        
    },

};
</script>

<template>
<div>
    <div class="mb-3">
        <div class="row d-flex justify-content-start">
            <div class="col-auto ms-3">
                <div class="back" style="display: flex; justify-content: center;">
                    <a @click="mtd_back" class="btn btn-sm btn-dark"><i class="fas fa-arrow-left"></i></a>&nbsp;&nbsp;
                    <span class="h4">Lista de pacientes FO</span>
                </div>
            </div>
        </div>
    </div>
    <div class="patients-wait" id="content-timelinetable" style="height: 400px !important;">

        <p class="patients-wait-p" v-if="patients.length == 0">
            No hay pacientes por atender...
        </p>
        <div v-else class="patients-wait-body">
            <div class="timeline timeline-simple mt-3 mb-3">
                <div class="tm-body">
                    <ol class="tm-items fancy-list">
                        <li v-for="(item, index) in patients" :key="index" @click="mtdChangePostFo(item)" style="cursor: pointer">
                            <div class="tm-box">
                                <p class="mb-0">
                                    <span>{{ item.name }}</span>
                                </p>
                                <p class="service mb-1">
                                    {{ item.of_service_name }}
                                </p>
                                <div v-if="item.remainingTime > 0" class="timer mb-1">
                                    <span>Tiempo restante: </span>
                                    <button class="btn btn-sm btn-info text-white">
                                        {{ Math.floor(item.remainingTime / 60) }} min {{ item.remainingTime % 60 }} seg
                                    </button>
                                </div>
                                <div v-else-if="item.remainingTime == -1" class="mb-1">
                                    <button class="btn btn-sm btn-success">Reingreso</button>
                                </div>
                                <div v-else-if="item.remainingTime == 0" class="mb-1">
                                    <button class="btn btn-sm btn-danger">Tiempo culminado</button>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" @confirmed="Confirmed" />

    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from 'moment';

export default {
    name: 'c-medico-cola-pacientefo',
    components: {
        SweetAlert,
    },
    data() {
        return {
            page: 'pacientefo',
            stateHistory: null,
            swal: null,
            viewInput: null,
            patient: {},
            patients: [],
            codesIa : [],
        }
    },
    props: {
        stateRolAdmin: Boolean,
    },
    mounted() {
        this.mtd_patients();
    },
    methods: {
        ...mapActions(['get', 'post']),

        contarTiempo(patient, index) {
            const originalTime = moment.utc(patient.hour_change); //tiempo en que se edito
            const newTime = moment.utc(originalTime).add(patient.fo_wait_time, 'minutes');
            const diferenciaInicial = newTime.diff(moment.utc(), 'seconds');
            let diferenciaSegundos = diferenciaInicial;
            // Actualizar diferencia cada segundo
            const timer = setInterval(() => {
                if (diferenciaSegundos > 0) {
                    diferenciaSegundos = Math.max(0, diferenciaSegundos - 1);
                    // Actualizar la lista de pacientes para reflejar los cambios
                    this.$set(this.patients[index], 'remainingTime', diferenciaSegundos);
                } else {
                    // Detener el temporizador cuando el tiempo restante sea 0
                    this.$set(this.patients[index], 'remainingTime', 0);
                    clearInterval(timer);
                }
            }, 1000);

        },

        mtd_patients: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Doctor/PatientsHome",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                    },
                })
                .then((response) => {
                    this.codesIa =  response.data.code;
                    this.patients = response.data.fondoOjos.map((patient, index) => {
                        return {
                            ...patient,
                            remainingTime: patient.status == 2 ? this.contarTiempo(patient, index) : -1 ,
                        };
                    });

                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtd_back: function () {
            this.stateColaPaciente = '';
            this.$emit('mtd_listsPatients', this.stateColaPaciente);
        },
        mtdChangePostFo: function (item) {
            if(item.status == 2){
                this.patient = item;
                this.$refs.SweetAlert.showConfirmSimple('Culminacion de Examen de Fondo de ojos', '¿Desea culminar examen?', 'warning', 'Si, culminar', 3);
            }else{
                this.$emit('mtdSelectPatient',item, this.codesIa);
            }
        },
        Confirmed: function (status) {

            this.post({
                    url: this.$store.getters.get__url + "/Doctor/changeStatus",
                    token: this.$store.getters.get__token,
                    params: {
                        id_reservation: this.patient.id_reservation,
                        status: status,
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.patient.status = response.data.status;
                    this.$emit('mtdSelectPatient',this.patient,this.codesIa);

                })
                .catch((errors) => {

                    console.log(errors);
                });
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },

    }
}
</script>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'md'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 ">
          <div id="table-detalle" >
            <div class="modal-body">
              <div class="modal-body mr-2 ml-2">
                <div id="carouselLightbox" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div v-for="(imagen, index) in dataImg" :key="index" :class="{ 'carousel-item': true, 'active': index === 0 }">
                                <img :src="'https://oftalmologia.howertpi.com/assets/'+imagen.route" class="d-block w-100" :alt="'Slide ' + (index + 1)">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselLightbox" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselLightbox" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CModal>
  </template>
  
  <script>
  import CModal from "../../../shared/modals/CModal.vue";
  import { mapActions } from "vuex";

  export default {
    name: "c-Medico-ayudadx-modals-imagenes",
    components: {
      CModal,
    },
    data() {
      return {
       
      };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      dataImg: Array,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdClose() {
        this.$emit("closeCarrusel");
      },

    },
   
  };
  </script>
  

<template >
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
    <div class="px-2">
        <div class="row mx-0 px-0">
            <div class="col-xl-4 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                <label for="" class="mb-2"> Proveedor: </label>
                <v-select class="text-dark form-control" v-model="carrito.proveedor" @input="mtdGetDataProductos()" label="name" :options="dataprovider" placeholder="- Buscar Proveedor (Escriba uno...) -" :reduce="(name) => name" required :clearable="false">
                    <template slot="no-options">
                        No hay Proveedores para la busqueda.
                    </template>
                </v-select>
            </div>
            <div class="col-xl-5 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                <label for="" class="mb-2"> Producto: </label>
                <v-select class="text-dark form-control" v-model="carrito.producto" label="name" :options="dataproductos" placeholder="- Buscar Productos (Escriba uno...) -" :reduce="(name) => name" required :clearable="false">
                    <template slot="no-options">
                        No hay Productos para la busqueda.
                    </template>
                </v-select>
            </div>
            <div class="col-xl-1 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                <label for="" class="mb-2"> Cantidad: </label>
                <input class="form-control " @keydown="filterKey" v-model="carrito.cantidad" type="text" placeholder="Cantidad" aria-label="example">
            </div>
            <div class="col-xl-1 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                <label for="" class="mb-2"> Precio: </label>
                <input class="form-control " @input="validatePrecio" v-model="carrito.precio" type="text" placeholder="Precio" aria-label="example">
            </div>
            <div class="col-xl-1 cl-lg-12 col-md-12 col-sm-12 py-xl-0  ">
                <label for="" class="mb-2"> Accion: </label>
                <div class="text-center btn-home">
                    <button :disabled="mtdisabled" @click="mtdAddItemCarrito()" type="button" class="btn w-100 "><i class="fas fa-plus"></i> </button>
                </div>

            </div>

        </div>

        <div class="col-md-12 table-responsive mt-4" id="div-tabla-anular" v-if="ordendecompra.length>0">
            <ul class="nav nav-tabs " id="myTab" role="tablist">
                <li v-for="(item, index) in ordendecompra" :key="index" class="nav-item px-1 py-0 w-auto" role="presentation">
                    <button @click="selectOc(index)" :class="['item-button', 'nav-link', { active: index === posOc }]" :id="'tab-' + index" type="button">
                        {{ item.cabecera.proveedor_name}}
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="" v-if="tablaDetail.length > 0">
                <div v-for="(item, index) in tablaDetail" :key="index">
                    <div class=" row mx-0 px-0 modal-title mt-4" id="exampleModalLabel">
                        <div class=" row mx-0 px-0 col-xl-12">
                            <div class="col-xl-1 d-none d-xl-inline ">
                                <div class=" text-center   h-100  d-flex align-items-center justify-content-center">
                                    <i class="fas fa-shopping-cart text-white bg-main px-3 py-3 rounded-circle "></i>
                                </div>
                            </div>
                            <div class="bg-white row col-xl-4 px-1 mx-0  align-items-center ">
                                <div class="col-xl-auto ">
                                    <label for="observaciones" class="col-form-label">FECHA DE GUIA: </label>
                                </div>
                                <div class="col-xl-7 px-0">
                                    <input type="date" v-model="item.cabecera.guide" class="form-control  form-control-sm" />
                                </div>
                            </div>
                            <div class="bg-white row col-xl-4 px-1 mx-0  align-items-center ">
                                <div class="col-xl-auto ">
                                    <label for="observaciones" class="col-form-label">GUIA: </label>
                                </div>
                                <div class="col-xl-7 px-0 d-flex">
                                    <button @click="triggerFileInput()" class="btn btn-sm btn-warning text-white me-1">
                                            <i class="fas fa-upload"></i>
                                    </button>
                                    <input type="text" v-model="item.cabecera.file_name" class="form-control   form-control-sm" disabled />
                                </div>
                                <input style="display : none" class="form-control form-control-sm" type="file" @change="getFile" lang="es" name="file" ref="fileInput" >
                            </div>

                        </div>
                    </div>
                    <div class=" mt-4">
                        <div class=" table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main text-xs">
                                    <tr class="text-white text-center" style="vertical-align: middle">
                                        <th scope="col">N°</th>
                                        <th scope="col">LENTE</th>
                                        <th scope="col">MARCA</th>
                                        <th scope="col">MODELO</th>
                                        <th scope="col">SERIE </th>
                                        <th scope="col">CANTIDAD</th>
                                        <th scope="col">PRECIO</th>
                                        <th scope="col" colspan="1">SUBTOTAL</th>
                                        <th scope="col" colspan="1">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xs align-middle">
                                    <tr class="tbody-table1 text-center" v-for="(carro, ind) in item.detalle" :key="ind">
                                        <td>
                                            {{ ind + 1 }}
                                        </td>
                                        <td>
                                            {{ carro.lente }}
                                        </td>
                                        <td>
                                            {{ carro.brand }}
                                        </td>
                                        <td>
                                            {{ carro.modelo }}
                                        </td>

                                        <td>
                                            {{ carro.serie }}
                                        </td>
                                        <td>
                                            {{ carro.cantidad }}
                                        </td>
                                        <td>
                                            {{ carro.precio }}
                                        </td>
                                        <td>
                                            {{ calculateSubtotal(index, ind)}}
                                        </td>
                                        <td class="div-pointer">
                                            <a id="btn-remove " class="a-danger2" @click="mtdBeforeDelete(index, ind)">
                                                <i class="fas fa-trash-alt"></i>
                                            </a>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex mt-3 justify-content-end">
                            <div class="px-2 py-1 bg-white">
                                VALOR VENTA S/: {{ mtdValor() }}
                            </div>
                            <div class="px-2 py-1 bg-white">IGV S/: {{ mtdGetIgv() }}</div>
                            <div class="px-2 py-1 mx-1 bg-white">
                                TOTAL C/ IGV: {{ mtdGetTotal() }}
                            </div>
                            <button type="button" @click="mtdInsertData" class="px-3 border-0 text-white bg-main">
                                ENVIAR
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-modals-cModalViewConsignaciones.vue",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            carrito: {
                proveedor: '',
                producto: '',
                cantidad: '',
                precio: '',
            },
            dataproductos: [],
            ordendecompra: [],
            posOc: 0,
            tablaDetail: [],
            fileInput: [],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        dataprovider: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        mtdisabled: function () {
            return !(this.carrito.proveedor != '' && this.carrito.producto != '' && this.carrito.cantidad != '' && this.carrito.precio != '')
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.carrito = {
                proveedor: '',
                producto: '',
                cantidad: '',
                precio: '',
            }
            this.$emit('closeModalConsignacion')
            this.ordendecompra = [];
        },

        
        mtdInsertData: function () {
            let formData = new FormData();
            this.ordendecompra.forEach((item, index) => {
                formData.append(`carrito[${index}][cabecera]`, JSON.stringify(item.cabecera));
                formData.append(`carrito[${index}][file]`, item.cabecera.file);
                item.detalle.forEach((detalleItem, detalleIndex) => {
                    formData.append(`carrito[${index}][detalle][${detalleIndex}]`, JSON.stringify(detalleItem));
                });
            });
          
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/consignaciones/store",
                    token: this.$store.getters.get__token,
                    params: formData,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.mtdclosemodal();
                        Swal.fire({
                            title: "Registro Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.$emit("mtdGetData");
                    } else {
                        Swal.fire({
                            title: "Registro Incorrecto!",
                            text: "Error!",
                            icon: "error",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {});
        },

        mtdGetDataProductos: function () {
            this.carrito.producto = '';
            this.carrito.cantidad = '';
            this.carrito.precio = '';
            this.get({
                url: this.$store.getters.get__url + "/Logistica/ProductosConsignacion/" + this.carrito.proveedor.id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.dataproductos = response.data;
            }).catch((error) => {
                console.log(error);
            });
        },

        mtdAddItemCarrito: function () {
            let carrito = this.carrito;
            let existeProveedor = this.ordendecompra.some(item => item.cabecera.id === carrito.proveedor.id);

            if (!existeProveedor) {
                let nuevoItemOrdenCompra = {
                    cabecera: {
                        id: carrito.proveedor.id,
                        proveedor_name: carrito.proveedor.name,
                        guide: '',
                        file: '',
                        file_name : 'Seleccione Archivo'
                    },
                    detalle: []
                };
                this.ordendecompra.push(nuevoItemOrdenCompra);
            }

            let itemDetalle = {
                id: carrito.producto.id,
                lente: carrito.producto.lente,
                brand: carrito.producto.brand,
                cantidad: carrito.cantidad,
                precio: carrito.precio,
                modelo: carrito.producto.modelo,
                serie: carrito.producto.serie,
            };

            let ordenCompraProveedor = this.ordendecompra.find(item => item.cabecera.id === carrito.proveedor.id);

            let itemExistente = ordenCompraProveedor.detalle.find(detalle => detalle.id === carrito.producto.id);
            if (!itemExistente) {
                ordenCompraProveedor.detalle.push(itemDetalle);
            } else {
                
                swal.fire({
                    title: "Producto Ya Agregado",
                    text: "El producto que intenta agregar ya se encuentra en la orden de compra.",
                    icon: "warning",
                    button: "Aceptar",
                });
            }
            this.selectOc(this.ordendecompra.length - 1)
            this.carrito.producto = '';
            this.carrito.cantidad = '';
            this.carrito.precio = '';
        },
        selectOc: function (pos) {
            if (pos >= 0 && pos < this.ordendecompra.length) {
                this.tablaDetail = [];
                this.posOc = pos;
                this.tablaDetail.push(this.ordendecompra[this.posOc]);

            }
        },
        calculateSubtotal(ind, index) {
            const precioUnitario =
                parseFloat(this.tablaDetail[ind].detalle[index].precio) || 0;
            const cantidad =
                parseInt(this.tablaDetail[ind].detalle[index].cantidad) || 0;
            const subtotal = precioUnitario * cantidad;
            this.tablaDetail[ind].detalle[index].precio_total = subtotal.toFixed(2);
            return subtotal.toFixed(2);
        },
        mtdValor: function () {
            if (this.ordendecompra.length > 0) {
                let valor = 0;
                this.ordendecompra[this.posOc].detalle.forEach((element) => {

                    valor += parseFloat(element.precio_total);

                });
                this.ordendecompra[this.posOc].cabecera.total_sin_igv = valor;
                return parseFloat(valor).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }

        },
        mtdGetIgv: function () {
            if (this.ordendecompra.length > 0) {
                let valor = 0;
                this.ordendecompra[this.posOc].detalle.forEach((element) => {

                    valor += parseFloat(element.precio_total);

                });
                let igv = parseFloat(valor * 0.18);
                return parseFloat(igv).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        mtdGetTotal: function () {
            if (this.ordendecompra.length > 0) {
                let valor = 0;
                this.ordendecompra[this.posOc].detalle.forEach((element) => {

                    valor += parseFloat(element.precio_total);

                });
                let igv = valor * 0.18;
                let total =
                    parseFloat(valor) + parseFloat(igv);
                this.ordendecompra[this.posOc].cabecera.total_igv = total;
                return parseFloat(total).toFixed(2);
            } else {
                return parseFloat(0.00).toFixed(2);
            }
        },
        triggerFileInput() {
            this.$refs.fileInput[0].click();
        },
        getFile(e) {
            // this.fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(pdf)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                Swal.fire({
                    text: 'Seleccione un archivo formato PDF, PNG O JPG',
                    icon: 'info',
                    confirmButtonColor: '#900052',
                });
                this.ordendecompra[this.posOc].cabecera.file = '';
                this.ordendecompra[this.posOc].cabecera.file_name = 'Seleccione Archivo';

            } else {
                // let formData = new FormData();
                // formData.append("file", file);
                this.ordendecompra[this.posOc].cabecera.file = file;
                this.ordendecompra[this.posOc].cabecera.file_name = file.name;
                // fileInput[this.posOc].value = file

            }
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        validatePrecio(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, "");

            const decimalCount = sanitizedValue.split(".").length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }

            const decimalIndex = sanitizedValue.indexOf(".");
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(
          0,
          decimalIndex
        )}.${decimalPart.substring(0, 2)}`;
            }

            this.carrito.precio = sanitizedValue;

        },
        mtdBeforeDelete: function (index, ind) {

            if (this.tablaDetail[index].detalle.length === 1) {
                if (this.ordendecompra.length === 1) {
                    this.ordendecompra.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);

                } else {
                    this.ordendecompra.splice(this.posOc, 1);
                    this.tablaDetail.splice(index, 1);
                    this.selectOc(this.posOc - 1)
                }
            } else {
                this.tablaDetail[index].detalle.splice(ind, 1);

            }
        },

    },
};
</script>

<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Nombre</label>
                <input type="text" class="form-control form-control-sm" placeholder="Nombre" v-model="data_detail.name">
            </div>
        </div>
        <div v-if="this.input ==4" class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Ojo</label>
                <select class="form-control form-control-sm" v-model="data_detail.eyes">
                    <option selected disabled value="">Selecciona</option>
                    <option v-for="ojo in ojos" :key="ojo.id" :value="ojo.name">{{ojo.name}}</option>
                </select>
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div v-if="this.input !== 6" class=" mb-2 col-lg-6 col-sm-12 px-1">
                <label for="name" class="form-label">S/.Costo</label>
                <input type="text" class="form-control form-control-sm" placeholder="0.00" v-model="data_detail.price_cost" @input="validateAmount">
            </div>
            <div class=" mb-2 col-sm-12 px-1" :class="{'col-lg-6':this.input!==6,'col-lg-12':this.input ===6}">
                <label for="name" class="form-label">S/.Venta</label>
                <input type="text" class="form-control form-control-sm" placeholder="0.00" v-model="data_detail.price_sale" @input="validatePriceSale">
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="col-md-12 mb-2 px-1" v-if="num == 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="data_detail.state == 1 ? true : false" @change="mtdChangeState" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">{{ data_detail.state == 1 ? "Activo" : "Inactivo" }}</label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-cirugia",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: '',
                price_cost: '',
                price_sale: '',
                eyes: '',
                state: 1
            },
            ojos: [{
                id: 1,
                name: 'OD'
            }, {
                id: 2,
                name: 'OI'
            }, {
                id: 3,
                name: 'AO'
            }]
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        input: Number
    },
    watch: {
        payload: {
            handler(newVal) {
                if(this.num == 1){
                    if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
                }
            },
            immediate: true,
        },
    },
    computed: {
        cpButton() {
            if (this.input !== 6) {
                if(this.input === 4){
                    return !(this.data_detail.name != '' && this.data_detail.price_cost != '' && this.data_detail.price_sale != '' && this.data_detail.eyes !== '');
                    
                }else{
                    return !(this.data_detail.name != '' && this.data_detail.price_cost != '' && this.data_detail.price_sale != '');
                }
            } else {
                return !(this.data_detail.name != '' && this.data_detail.price_sale != '');
            }

        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdChangeState: function () {
                this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdChangeState: function () {
                this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdClose() {
            this.data_detail = {
                name: '',
                price_cost: '',
                price_sale: '',
                eyes: '',
            }
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.data_detail = {
                name: '',
                price_cost: '',
                price_sale: '',
                eyes: '',
            } 
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_cost = sanitizedValue;
        },
        validatePriceSale(event) {

            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            this.data_detail.price_sale = sanitizedValue;
        }

    },

};
</script>

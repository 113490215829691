<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'md'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12 ">
        <div id="table-detalle" >
          <div class="modal-body mr-2 ml-2">
            <label for="inputtext" class="mb-3 align">Observación</label>
            <div class="input-group">
                <textarea class="form-control" aria-label="With textarea" v-model="call.observation"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose"  class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <button @click="mtdCommitCall" class="btn btn-success btn-sm">
        Registrar Llamada
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";

export default {
  name: "c-oftalmologia-modal-detalle",
  components: {
    CModal,
  },
  data() {
    return {
      call :{
        observation:"",
      }
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
  },
  computed: {},
  methods: {
    mtdClose() {
      this.call.observation = "";
      this.$emit("closeModalAddCall");
    },
    mtdCommitCall: function(){
      this.$emit('mtdCommitCall',this.call.observation);
    }
  },
 
};
</script>


<template>
<div class="px-4 mb-4">
    <div class="d-flex mb-1">
        <h6 class="mt-2 me-2 "><i class="fas fa-pills"></i> RECOMENDADOS</h6>
        <button v-if="ordendecompra.length > 0" type="button" @click="mtdviewcarrito()" class="border-0 btn rounded-2 bg-main btn-ver text-white btn-sm">
            <i class="fas fa-eye"></i> Ver Carrito
        </button>
    </div>
    <!-- <div class=" mt-1  responsive-default text-xs  " v-for="(product, indexp) in cpRecommendedProducts" :key="indexp">
        <div class="d-flex div-pointer" @click="mtdAddItemCarrito(product)">
            <div class="px-3 py-1 responsive-default-div  border rounded-start" style="background-color: #abb2b9">
                {{ product.proveedor.name  }}
            </div>
            <div class="px-3 py-1 responsive-default-div  border " style="background-color: #abb2b9">
                {{ product.molecule }}
            </div>
            <div class="px-3 py-1 responsive-default-div border" style="background-color: #abb2b9">
                {{ product.brand }}
            </div>
            <div class="px-3 py-1 responsive-default-div border" style="background-color: #abb2b9">
                {{ product.presentation }}
            </div>
            <div class="px-3 py-1 responsive-default-div border rounded-end" style="background-color: #abb2b9">
                {{ product.concentration }}
            </div>

        </div>
    </div> -->
    <div class="container">
        <div class="row">
            <div class="col px-0">
                <div v-if="cpRecommendedProducts.length > 0" class=" py-1 responsive-default text-xxxs overflow-auto " style="max-height: 80px;">
                    <div v-for="(product, indexp) in cpRecommendedProducts" :key="indexp">
                        <div class="d-flex div-pointer w-100 " @click="mtdAddItemCarrito(product)">
                            <div class="px-3 py-1 responsive-default-div border rounded-start w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.proveedor.name }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.brand }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.name }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.molecule }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.commercial == null ? '-'  : product.commercial }}
                            </div>
                          
                            <div class="px-3 py-1 responsive-default-div border w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.presentation == null ? '-': product.presentation }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border  w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.concentration == null ? '-': product.concentration }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border  w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.modelo == null ? '-' : product.modelo }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border  w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.serie == null ? '-' :  product.serie  }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border  w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.proveedor.price_purchase  }}
                            </div>
                            <div class="px-3 py-1 responsive-default-div border rounded-end w-25" :class="{'bg-color1': product.color === 1, 'bg-color2': product.color === 2}">
                                {{ product.utilidad  }}
                            </div>
                            

                        </div>
                    </div>
                </div>
                <div v-else class="text-center">
                    <div class=" text-xs">NO SE ENCONTRARON PRODUCTOS RECOMENDADOS</div>
                </div>
            </div>
        </div>
    </div>

    <div  class="d-flex justify-content-between px-1 mt-xl-0 mt-3 ">
        <h6 class="mt-3 me-2 "><i class="fas fa-pills"></i> MEDICAMENTOS</h6>
        <div v-if=" medicaments.length > 0 && cpData.length > 0">
            <nav aria-label="Page navigation example" class="mt-3">
                <ul class="pagination mb-1">
                    <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                    </li>
                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                    </li>
                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
    
                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                        </a>
                    </li>
                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                        </a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                    </li>
                </ul>
            </nav>

        </div>
    </div>
    <div class="col-md-12 px-0 table-responsive mt-2" id="content-timelinetable">
        <table id="tb-anular" class="table table-bordered">
            <caption class="mx-0 px-0"></caption>
            <thead class="bg-main text-xxs">
                <tr class="text-white text-center">
                    <th scope="col" class="align-middle">
                        PROVEEDOR
                    </th>
                    <th scope="col" class="align-middle">
                        MARCA
                    </th>
                    <th scope="col" class="align-middle">
                        NOMBRE
                    </th>
                    <th scope="col" class="align-middle">
                        MOLECULA / LENTE
                    </th>
                    <th scope="col" class="align-middle">
                        COMERCIAL
                    </th>
                    
                    <th scope="col" class="align-middle">
                        PRESENTACIÓN
                    </th>
                    <th scope="col" class="align-middle">
                        CONCENTRACION
                    </th>
                    <th scope="col" class="align-middle">
                        MODELO
                    </th>
                    <th scope="col" class="align-middle">
                        SERIE
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        S/PRECIO DE COMPRA
                    </th>
                    <th scope="col" colspan="1" class="align-middle">
                        UTILIDAD UNITARIO
                    </th>
                </tr>
            </thead>
            <tbody class="border-main text-xs">
                <tr class="tbody-table" v-for="(producto, index) in cpData" :key="index">
                    <td class="align-middle div-pointer clickCarrito" @click="mtdAddItemCarrito(producto)">
                        {{ producto.proveedor.name }}
                    </td>
                    <td class="align-middle">
                        {{ producto.brand }}
                    </td>
                    <td class="align-middle">
                        {{ producto.name }}
                    </td>
                    <td class="align-middle">
                        {{ producto.molecule }}
                    </td>
                    <td class="align-middle">
                        {{ producto.commercial == null ? '-' : producto.commercial }}
                    </td>
                    
                    <td class="align-middle">
                        {{ producto.presentation ==null ? '-' : producto.presentation }}
                    </td>
                    <td class="align-middle text-end">
                        {{ producto.concentration == null ? '-' : producto.concentration}}
                    </td>
                    <td class="align-middle text-end">
                        {{ producto.modelo == null ? '-':  producto.modelo }}
                    </td>
                    <td class="align-middle text-end">
                        {{ producto.serie == null ? '-' : producto.serie }}
                    </td>
                    <td class="align-middle text-end">
                        {{ producto.proveedor.price_purchase }}
                    </td>
                    <td class="align-middle text-end">
                        {{ producto.utilidad }}
                    </td>
                </tr>
                <tr v-if="!cpData.length > 0" class="text-center">
                    <th scope="col" colspan="11">NO SE ENCONTRARON RESULTADOS</th>
                </tr>
            </tbody>

        </table>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
export default {
    name: "c-logistica-pages-ordendecompra-medicamento",
    data() {
        return {
            dataShow: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
        }
    },
    props: {
        medicaments: {
            type: Array,
            default: () => [],
        },
        ordendecompra: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },
        medicaments: {
            handler: 'mtdGetData',
            deep: true,
            immediate: true
        }

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        cpRecommendedProducts() {
            let productosOrdenados = [...this.medicaments].sort((a, b) => b.utilidad - a.utilidad);
            let productosAgrupados = {};
            productosOrdenados.forEach(producto => {
                let clave = producto.presentation + '_' + producto.concentration + '_' + producto.molecule;
                if (!productosAgrupados[clave]) {
                    productosAgrupados[clave] = [];
                }
                productosAgrupados[clave].push(producto);
            });
            let recommendedProducts = [];
            let colorCounter = 1;
            for (let clave in productosAgrupados) {
                let productosDeGrupo = productosAgrupados[clave];
                let productosRecomendados = productosDeGrupo.slice(0, 2);
                productosRecomendados.forEach(producto => {
                    producto.color = colorCounter;
                });
                recommendedProducts.push(...productosRecomendados);
                colorCounter = colorCounter === 1 ? 2 : 1;
            }
            return recommendedProducts;
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.calculateData(6)
        },
        calculateData(items) {
            let filteredData = this.medicaments;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdAddItemCarrito: function (payload) {
            this.$emit("mtdAddItemCarrito", payload);
        },
        mtdviewcarrito: function () {
            this.$emit("mtdviewcarrito");
        },

    }
}
</script>

<style scoped>
.bg-color1{
    background-color: #D5DBDB;
}
.bg-color2{
    background-color: #BFC9CA ;
}
</style>
<template>
  <div>
    <div class="container text-center mt-2 mb-3">
      <div class="d-flex title-option">
        <div class="btn-home mt-3 mb-2 ms-auto">
          <button
            type="button"
            class="btn btn-outline-main"
            @click="mtdViewSelected"
          >
            <i class="fas fa-eye"></i> Ver Seleccionados
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-outline-main"
            @click="returnHome()"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-anular" class="w-100">
        <div id="div-anular" class="card w-100">
          <div class="card-header text-white h5 bg-main text-center">
            <strong>MEDICAMENTOS</strong>
          </div>
          <div class="card-body w-100">
            <div class="row">
              <div class="col-md-12 py-2">
                <div class="row d-flex" id="div-tabla-anular">
                  <div class="col-lg-12 col-md-12 col-sm-12 table-container">
                    <table class="table table-bordered border-main">
                      <caption class="py-0 my-0"></caption>                  
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Marca</th>
                          <th>Molecula</th>
                          <th>Comercial</th>
                          <th>Presentaci&oacute;n</th>
                          <th>Stock Minimo</th>
                          <th>Stock Actual</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(item, index) in data" >
                          <tr :key="index" :class="mtdGetClassTr(item.state)">
                            <td>
                              <input type="checkbox" @click="mtdSelectedForRequeriment(index)">
                            </td>
                            <td>{{ item.brand ? item.brand : "-" }}</td>
                            <td>{{ item.molecule ? item.molecule : "-" }}</td>
                            <td>{{ item.comercial ? item.comercial : "-" }}</td>
                            <td>{{ item.presentacion ? item.presentacion : "-" }}</td>
                            <td>{{ item.min_stock ? item.min_stock : "-" }}</td>
                            <td>{{ item.stock ? item.stock : "-" }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <cModalViewSelectRequirementVue
      :title="modalStoreReq.title"
      :boo_modal="modalStoreReq.modal_from"
      :data="modalStoreReq.data"
      @mtdCloseModalView="mtdCloseModalView"
      @mtdCommitRequeriment="mtdCommitRequeriment"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalViewSelectRequirementVue from './Modals/cModalViewSelectRequirement.vue';
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

export default {
  name: "c-farmacia-requirement",
  components:{cModalViewSelectRequirementVue,SweetAlert},
  data() {
    return {
      swal: null,
      data:[],
      forRequirement:[],
      modalStoreReq:{
        title: "Registrar Requerimiento",
        modal_from: false,
        data: [],
      }
    }
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/pharmacy/requirement",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          //console.log(response);
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mtdGetClassTr: function(state){
      switch (state) {
        case 1:
          return 'table-danger';
          break;
        case 2:
          return 'table-warning';
          break;
        case 3:
          return 'table-success';
          break;
        default:
          break;
      }
    },
    mtdSelectedForRequeriment: function(index){
      this.data[index].selected = !this.data[index].selected;
    },
    mtdViewSelected: function(){
      let selected = this.data.filter(it=> it.selected==true);
      if (selected.length > 0) {
        this.modalStoreReq= {
          title: "Registrar Requerimiento",
          modal_from: true,
          data: selected,
        }; 
      } else {
        this.$refs.SweetAlert.showWarning("No tiene medicamentos selccionados");
      } 
    },

    mtdCloseModalView:function(){
      this.modalStoreReq = {
        title: "Registrar Requerimiento",
        modal_from: false,
        data: [],
      };
    },
    mtdCommitRequeriment: function(detalle){
      this.post({
        url: this.$store.getters.get__url + "/pharmacy/registerRequirement",
        token: this.$store.getters.get__token,
        params: {
          detalle: detalle
        },
      })
      .then((response) => {
          this.$refs.SweetAlert.showSuccess(response.message);
          this.mtdCloseModalView();
      })
      .catch((error) => {
          this.$refs.SweetAlert.showError(error);
      });
    },

    returnHome() {
      this.$emit("mtdChangePage", "home");
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: darkgray lightgray;
}
</style>
<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">

        <div v-if="num == 2" class=" row col-xl-12 mt-3 mx-0 px-0 ">
            <div class="col-xl-4" v-for="(roles,index) in dataRole" :key="index">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="roles.id" v-model="localIdRoles" :id="index">
                    <label class="form-check-label" :for="index">
                        {{ roles.name }}
                    </label>
                </div>
            </div>
        </div>
        <div v-else class=" row col-xl-12 mt-3 mx-0 px-0 ">
            <div class="row mx-0 px-0" v-if="dataRolesUsuario.length > 0">
                <div class="col-xl-3  px-1 " v-for="(roles,index) in dataRolesUsuario" :key="index">
                    <div class=" py-2 text-center mb-1  text-white rounded rounded-2 bg-info text-xxs">
                        <span> {{ roles.role_name }}</span>
                    </div>
                </div>

            </div>
            <div class="text-center fw-bold" v-else>
                <span>No hay registros</span>
            </div>

        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button v-if="num ==2" class="btn btn-success btn-sm" @click="mtdaddroles">
            Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-marca",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            filteredData: [],
            rolprovider: {
                roles_id: [],
                user_id: null
            },
            marca: {
                id: '',
                name: ''
            },
            product_provider: {
                id: null,
                products: []
            },
            productosselecionandos: [],
            productosnoselecionados: [],
            localIdRoles: [],
            product_brand: {
                id: null,
                id_provider: null,
                bran_id: null
            }
        };
    },
    watch: {
        idRoles(newValue) {
            this.localIdRoles = [...newValue];
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        dataRole: {
            type: Array,
            default: null
        },
        num: {
            type: Number,
            default: null
        },
        provider_id: {
            type: Number,
            default: null
        },
        dataRolesUsuario: {
            type: Array,
            default: null
        },
        dataUser: {
            type: Object,
            default: null
        },
        idRoles: {
            type: Array,
            default: null
        }

    },

    computed: {},

    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.productosselecionandos = []
            this.productosnoselecionados = []
            this.marca = {
                id: '',
                name: ''
            }
            this.$emit("closeModalRoles");
        },
        mtdaddroles: function () {
            this.rolprovider = {
                roles_id: this.localIdRoles,
                user_id: this.dataUser.id
            }
            this.$emit('mtdaddroles', this.rolprovider);

        },

    },

};
</script>

<template>
<div>
    <div class="mb-3">
        <div class="row d-flex justify-content-start">
            <div class="col-auto ms-3">
            <div class="back" style="display: flex; justify-content: center;">
                <a @click="mtd_back" class="btn btn-sm btn-dark"><i class="fas fa-arrow-left"></i></a>&nbsp;&nbsp;
                <span class="h4">Lista de pacientes</span>
            </div>
            </div>
        </div>
    </div>
    <div class="patients-wait" id="content-timelinetable" style="height: 400px !important;">
        
        <p class="patients-wait-p" v-if="patients.length == 0">
            No hay pacientes por atender...
        </p>
        <div v-else class="patients-wait-body">
            <div class="timeline timeline-simple mt-3 mb-3">
                <div class="tm-body">
                    <ol class="tm-items fancy-list">
                        <li v-for="(item, index) in patients" :key="index" @click="mtdSelectPatient(item)" style="cursor: pointer">
                            <div class="tm-box">
                                <p class="mb-0">
                                    <span v-if="item.status === 1">{{ item.name }}</span>
                                    <span v-if="item.status === 3">{{ item.name }} (FO)</span>
                                </p>
                                <!-- <p class="hour my-0">
                                    {{ item.of_service_name }}
                                </p> -->
                                <p class="service mb-0">
                                    {{ item.of_service_name }}
                                </p>
                            </div>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import {
    mapActions
} from 'vuex'

export default {
    name: 'c-medico-cola-paciente',
    components: {},
    data() {
        return {
            page: 'paciente',
            patients: [],
            codesIa : [],
            history:{},
        }
    },
    mounted() {
        this.mtd_patients();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtd_patients: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Doctor/patients",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                    },
                })
                .then((response) => {
                    this.patients = response.data.list;
                    this.codesIa = response.data.code;

                })
                .catch(error => {
                    console.log(error);
                })
        },
        refreshPatients: function () {
            this.patients = [];
            this.mtd_patients();

        },
        mtd_back: function () {
            this.stateColaPaciente = '';
            this.$emit('mtd_listsPatients', this.stateColaPaciente);
        },
        mtdSelectPatient: function (patient) {
            this.$emit('mtdSelectPatient', patient, this.codesIa);
            /*this.history={};
            if(patient.status == 3){ 
                this.get({
                    url: this.$store.getters.get__url + "/Doctor/history/"+ patient.id_reservation ,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.history = response.data;
                    this.$emit('mtdViewHistory', patient,this.history);
                })
                .catch((errors) => {
                    console.log(errors);
                });  
            }else{
                this.$emit('mtdViewHistory', patient,this.history); 
            }*/
            
            
        },
    }
}
</script>

<style>
/* .patients-wait {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.patients-wait .patients-wait-header {
    display: flex;
    position: relative;
    padding: 1rem 0 0 1rem;
}

.patients-wait .patients-wait-p {
    text-align: center;
    padding: 0;
    margin: 2rem 0 0;
    font-weight: 600;
}

.patients-wait .patients-wait-header .back {
    position: absolute;
    right: 5px;
} */
</style>

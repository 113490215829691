<template>
  <div>
    <div id="btn_home" class="c-app align-items-center">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="text-end">
            <div class="mt-2 p-3">
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-bm-noradius bg-main text-white h-100" @click="mtdBackCampus">
                  <i class="fas fa-recycle"></i><br />Cambiar de sede
                </button>
              </div>
              &nbsp;
              <div class="btn-group" role="group">
                <button type="button" class="btn btn-bm-noradius bg-main text-white h-100" @click="mtdCloseBead">
                  <i class="fas fa-cash-register"></i><br />Cerrar
                </button>
              </div>
              <!--
              &nbsp;
              <div class="btn-group" role="group">
                <button
                  type="button"
                  class="btn btn-bm-noradius bg-main text-white h-100"
                  @click="mtdOpenBead"
                >
                  <i class="fas fa-cash-register"></i><br />Abrir
                </button>
              </div>
              -->
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="text-center p-5">
            <div class="d-grid gap-2 mx-auto col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <button class="btn btn-lg bg-main text-white mt-100" @click="generarVenta" type="button">
                VENTA
              </button>
              <!-- <button class="btn btn-lg bg-main text-white" @click="generarEgreso" type="button">
                EGRESO
              </button> -->
            </div>
          </div>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div class="p-1">
            <div class="col-md-6 px-sm-2 px-md-4">
              <div class="row">
                <div id="btn_refresh" class="col-md-12">
                  <button @click="mtdCleanClient" type="button" class="btn">
                    <i class="fa fa-repeat"></i>
                  </button>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-7 col-sm-12 p-2 mt-200">
                  <input class="form-control form-control-lg inpunt-rounded" type="text"
                    v-on:keypress="isNumber($event)" maxlength="12" placeholder="Documento"
                    aria-label=".form-control-lg example" v-model="client.document" @keyup.enter="mtdSearchDocument" />
                </div>
                <div class="col-xl-7 col-lg-9 col-md-12 col-sm-12 p-2">
                  <input class="form-control form-control-lg inpunt-rounded" type="text" v-model="client.fullName"
                    :disabled="disabledPatient" placeholder="Nombres y apellidos del paciente"
                    aria-label=".form-control-lg example" />
                </div>
                <div class="col-xl-2 col-lg-6 col-md-7 col-sm-12 p-2">
                  <input class="form-control form-control-lg inpunt-rounded" type="text"
                    v-on:keypress="isNumber($event)" v-model="client.yearOld" maxlength="3" placeholder="Edad"
                    aria-label=".form-control-lg example" />
                </div>
                <div class="col-xl-3 col-lg-6 col-md-7 col-sm-12 p-2">
                  <input class="form-control form-control-lg inpunt-rounded" type="text"
                    v-on:keypress="isNumber($event)" v-model="client.celphone" maxlength="9" placeholder="Teléfono"
                    aria-label=".form-control-lg example" />
                </div>
                <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 p-2">
                  <input class="form-control form-control-lg inpunt-rounded" type="text" v-model="client.email"
                    placeholder="Correo" aria-label=".form-control-lg example" />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
export default {
  name: 'HomeFarmaciaComponent',
  components: {
    SweetAlert
  },
  props: {
    verifBead: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: null,
      swal: null,
      disabledPatient: true,
      client: {
        document: "",
        document_tutor: "",
        celphone: "",
        email: "",
        fullName: "",
        fullName_tutor: "",
        yearOld: "",
        type: "particular",
        referencia: "",
      },
      insurances: [],
      medios: [],
    }

  },
  created() {

  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdCleanClient: function () {
      this.client = {
        document: "",
        document_tutor: "",
        celphone: "",
        email: "",
        fullName: "",
        fullName_tutor: "",
        yearOld: ""
      };
    },
    mtdSearchDocumentt: function () {
      if (this.client.document.length == 8) {
        this.get({
          url: this.$store.getters.get__url + "/Consultas/" + this.client.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.boo == 3) {
              this.client.document = "";
              this.$refs.SweetAlert.showWarning(response.data.error);
              this.disabledPatient = false;
              this.client.fullName = "";
            } else {
              this.client.fullName = response.data.name + " " + response.data.last_name;
              // if (response.hasOwnProperty("patient")) {
              //   this.client.celphone = response.patient.phone;
              //   this.client.email = response.patient.email;
              //   this.client.yearOld = response.patient.year;
              // } else {
              //   this.client.celphone = "";
              //   this.client.email = "";
              //   this.client.yearOld = "";
              //   this.$refs.SweetAlert.showWarning("Paciente Nuevo"); 
              //   this.disabledPatient=true;
              // }
            }
          })
          .catch(() => {
          });
      } else {
        this.disabledPatient = false;
        this.client.celphone = "";
        this.client.email = "";
        this.client.fullName = "";
        this.client.yearOld = "";
      }
    },
    mtdSearchDocument: function () {
      if (this.client.document.length == 8) { 
        this.get({
          url: this.$store.getters.get__url + "/Consultas/Admision/" + this.client.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.boo== 3) {
               this.client.document = "";
               this.$refs.SweetAlert.showWarning(response.data.error);  
               this.disabledPatient=false;
               this.client.fullName = "";
               this.client.id='';
                this.client.celphone ='';
                this.client.email = '';
                this.client.yearOld = '';
            } else {
              this.client.fullName = response.data.name;
               if (response.data.patient_id) {
                this.client.id=response.data.patient_id;
                this.client.celphone = response.data.phone;
                this.client.email = response.data.email;
                this.client.yearOld = response.data.year;
                this.client.document_tutor = response.data.document_tutor;
                this.client.fullName_tutor = response.data.name_tutor;
              }
            }
          })
          .catch(() => {
          });
      }else{
        this.disabledPatient=false;
        this.client.celphone= "";
        this.client.email= "";
        this.client.fullName="";
        this.client.yearOld= "";
      }
    },
    mtdVerifClient: function () {
      if (this.client.document.trim().length === 0) {
        return { msg: "Falta ingresar el documento del cliente.", status: false };
      } else if (this.client.fullName.trim().length === 0) {
        return { msg: "Falta ingresar el nombre completo del cliente.", status: false };
      }
      else if (this.client.celphone =="" || this.client.celphone == null) {
        return { msg: "Ingrese  un Número de Teléfono Celular.", status: false };
      }
      // else if (this.client.email =="") {
      //     return { msg: "Ingrese  un Email.", status: false };
      // }
      else {
        return { msg: "Cliente válido.", status: true };
      }

    },
    generarVenta() {
      if (this.mtdVerifClient().status == true) {
        this.$emit("mtdChangePage", "venta", this.client);
      } else {
        this.$refs.SweetAlert.showWarning(this.mtdVerifClient().msg);
      }
    },
    generarEgreso() {
      this.$emit("mtdChangePage", "egreso", this.client);
    },
    mtdCloseBead() {
      this.$emit("mtdChangePage", "reporteClose");
    },
    mtdOpenBead() {
      this.$emit("mtdChangePage", "open");
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdGetStyle: function (option) {
      if (option == this.page) return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan: function (option) {
      if (option == this.page) return 'color:white';
      return '';
    },
    mtdSendOption: function (page) {
      this.page = page;
      this.$emit('mtdChangePage', this.page)
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdChangePage: function (page) {
      this.page = page;
      this.$emit('mtdChangePage', this.page)
    },
  },
}
</script>
<template>
  <div class="home" id="div_home" v-if="rol != null">
    <div class="" v-if="campus != null">
      <div v-if="rol == 1" class="">
        <OftalmologiaComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles" />
      </div>
      <div v-else-if="rol == 2" class="">
        <MaintenanceComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol == 3" class="">
        <MedicoComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==4" class="">
        <EnfermeraComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==5" class="">
        <CallComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==6" class="">
        <SatComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==10" class="">
        <FarmaciaComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==11" class="">
        <LogisticaComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==12" class="">
        <GerenciaComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==13" class="">
        <ContabilidadComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
      <div v-else-if="rol==14" class="">
        <VigilanciaComponent @mtdBackCampus="mtdBackCampus" :rolname="rolname" :dataRoles="dataRoles"/>
      </div>
    </div>
    <div class="" v-else>
      <CampusComponent
        :dataRoles="dataRoles"
        :dataCampus="dataCampus"
        @mtdSelectCampus="mtdSelectCampus"
        @mtdBackRol="mtdBackRol"
      />
    </div>
  </div>
  <div class="" v-else>
    <RolesVue :dataRoles="dataRoles" @mtdSelectRol="mtdSelectRol" />
  </div>
</template>

<script>
import CampusComponent from "./Campus/index.vue";
import RolesVue from "./Rol/index.vue";
import OftalmologiaComponent from './Oftalmologia/OftalmologiaView.vue';
import MaintenanceComponent from './Maintenance/MaintenanceView.vue';
import MedicoComponent from './Medico/MedicoView.vue';
import EnfermeraComponent from './Enfermera/EnfermeraView.vue';
import CallComponent from './Call/CallView.vue';
import SatComponent from './Sat/Sat.vue'
import FarmaciaComponent from './Farmacia/FarmaciaView.vue'
import LogisticaComponent from './Logistica/LogisticaView.vue'
import GerenciaComponent from './Gerencia/GerenciaView.vue'
import ContabilidadComponent from './Contabilidad/ContabilidadView.vue'
import VigilanciaComponent from './Vigilancia/VigilanciaView.vue'

import { mapActions } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    CampusComponent,
    RolesVue,
    OftalmologiaComponent,
    MaintenanceComponent,
    MedicoComponent,
    EnfermeraComponent,
    CallComponent,
    SatComponent,
    FarmaciaComponent,
    LogisticaComponent,
    GerenciaComponent,
    ContabilidadComponent,
    VigilanciaComponent
  },
  data () {
    return {
      rol: null,
      rolname: null,
      dataCampus: [],
      campus: null,
      dataRoles: [],
    }
  },
  created () {
    const user = this.$store.getters.get__user

    if (user.roles.length == 1) {
      this.rol = user.roles[0].role_id;
      this.rolname = user.roles[0].role.name;
    } else {
      this.dataRoles = user.roles;
      this.rolname = '';
    }
    this.dataCampus = this.$store.getters.get__datacampus;
    this.mtdgetcheck();
  },
  methods: {
    ...mapActions(['get', 'post']),

    mtdSelectCampus: function (id) {
      this.campus = id;
      this.$store.commit("mt_set_campus", id);
    },
    mtdSelectRol: function (id, name_rol) {
      this.rol = id;
      this.rolname = name_rol;
      this.mtdgetcheck();
    },
    mtdBackRol: function () {
      this.rol = null;
    },
    mtdBackCampus: function () {
      this.campus = null;
      this.$store.commit("mt_set_campus", null);
    },
    mtdgetcheck: function () {
      this.get({
          url: this.$store.getters.get__url + "/Authentication/check",
          token: this.$store.getters.get__token,
        })
        .then((response) => {
          this.check = response.data.check;
          if(this.check){
            if(this.rol == 1){
              this.$router.push('/admision')
            }else
            if(this.rol == 2){
              this.$router.push('/mantenimiento')
            }else
            if(this.rol == 3){
              this.$router.push('/medico')
            }else
            if(this.rol == 4){
              this.$router.push('/enfermera')
            }else
            if(this.rol == 5){
              this.$router.push('/callcenter')
            }else
            if(this.rol == 6){
              this.$router.push('/sat')
            }else
            if(this.rol == 10){
              this.$router.push('/farmacia')
            }else
            if(this.rol == 11){
              this.$router.push('/logistica')
            }else
            if(this.rol == 12){
              this.$router.push('/gerencia')
            }
            if(this.rol == 13){
              this.$router.push('/contabilidad')
            }
            if(this.rol == 14){
              this.$router.push('/vigilancia')
            } else{
              this.$router.push('/home')
            }
          }else{
            this.$router.push('/login')
          }
        })
    },
  }
}
</script>
<template>
    <div>
      <div class="w-100 h-100">
        <Nav :rolname="rolname" />
      </div>
      <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus = "mtdBackCampus"/>
        <PermisionsVue v-if="page == 'permiso_caja'" @returnHome="returnHome" />
        <AnulacionesVue v-if="page == 'anulaciones'" @returnHome="returnHome" />
        <CierreCajaVue v-if="page == 'permiso_cierre_caja'" @returnHome="returnHome" />
        <RepCajaVue v-if="page == 'reporte_caja'" @returnHome="returnHome" />
        <RepIngresoEgreso v-if="page == 'ingreso_egreso'" @returnHome="returnHome" />
        <Call v-if="page == 'call'" @returnHome="returnHome" @mtdChangePage="mtdChangePage" />
        <Pagos v-if="page == 'pagos'" @returnHome="returnHome" @mtdChangePage="mtdChangePage" />
      </div>
      <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :pageView="page" />
      </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import Home from "@/components/Gerencia/Home.vue";
import Nav from "../../components/Gerencia/Nav.vue";
import Footer from "../../components/Gerencia/Footer.vue";
import PermisionsVue from "@/components/Gerencia/pages/PermisionsView.vue";
import AnulacionesVue from "@/components/Gerencia/pages/AnulacionesView.vue";
import CierreCajaVue from "@/components/Gerencia/pages/PermisoCierreCaja.vue";
import RepCajaVue from "@/components/Gerencia/pages/RepCajaView.vue";
import RepIngresoEgreso from "@/components/Gerencia/pages/IngresoEgresoView.vue";
import Call from "@/components/Gerencia/pages/reportecall.vue";
import Pagos from "@/components/Gerencia/pages/PagosFacturas.vue";

//import OtrosPermiso from "@/components/Gerencia/pages/OtrosPermisoView.vue";

export default {
  name: "GerenciaView",
  components: {
    Nav,
    Home,
    Footer,
    PermisionsVue,
    AnulacionesVue,
    CierreCajaVue,
    RepCajaVue,
    RepIngresoEgreso,
    Call,
    Pagos
    //OtrosPermiso,
  },

  data() {
    return {
      prefix: 'gerencia',
      page:'home',
      rol_name: null,
      stateRolAdmin: false,
    };
  },
  props: {
    rolname: String,
    dataRoles: Array,
    campu: Number,
  },
  created () {
    this.rol_name = this.rolname;
    this.mtdGetRolAdmin();
  },
  methods: {
    ...mapActions(['get', 'post']),
    mtdChangePage: function(page){
      this.page = page;
    },
    returnHome: function () {
      this.page = "home";
    },
    mtdGetRolAdmin:function(){
      this.dataRoles.forEach(element => {
        if(element.role_id==1){
          this.stateRolAdmin = true;
        }
      });
    },
    mtdBackCampus:function(){
      this.$emit('mtdBackCampus');
    }
  }
};
</script>
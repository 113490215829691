<template>
    <div>
      <div class="" v-if="view == 'data'">
        <div class="d-flex title-option">
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Atras
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100 border-box-main mt-4">
          <div id="div-anular" class="card w-100">
  
            <div
            class="card-header text-center text-white h5"
            style="background: #900052"
          >
                <div  class="d-flex justify-content-between align-items-center">
                <strong class="col text-center">RECETAS</strong>
                <!--
                    <div class="input-group-append"  v-if="dataConsultingrooms.model == '\\App\\Models\\OfOperationsRoomBooking'">
                        <button
                            @click="mtdAddServiceOpe()"
                            class="btn btn-primary rounded-circle"
                            type="button"
                            style="background-color: #EB5077; border: 2px solid #EB5077"
                        >
                            <i class="far fa-cart-plus fa-sm"></i>
                        </button>
                    </div>
                    <div class="input-group-append"  v-else-if="dataConsultingrooms.model == '\\App\\Models\\OfConsultingRoomBooking'">
                        <button
                            @click="mtdAddServiceCon()"
                            class="btn btn-primary rounded-circle"
                            type="button"
                            style="background-color: #EB5077; border: 2px solid #EB5077"
                        >
                            <i class="far fa-cart-plus fa-sm"></i>
                        </button>
                    </div>
                    -->
                </div>

          </div>
            <div class="card-body">
              <div class="d-flex justify-content-center">
                <div class="w-100 pt-2" style="background-color: #ffffff">
                  <div class="cuerpo">
                    <div class="px-4">
                      <div class="col-md-12 table-container" id="div-tabla-anular">
                        <table
                          id="tb-anular"
                          class="table tablaRe table-bordered table-container align-middle"
                        >
                          <caption></caption>
                          <thead class="bg-main">
                            <tr class="text-white table-th text-center text-xs">
                              <th class="table-th-state">
                                <div>FECHA</div>
                              </th>
                              <th class="table-th-state">
                                <div>PACIENTE</div>
                              </th>
                              <th class="table-th-state">
                                <div>DOCTOR</div>
                              </th>
                              <th class="table-th-state">
                                <div>ACCIONES</div>
                              </th>
                            </tr>
                          </thead>
              
                          <tbody align="center" class="border-main">
                            <tr v-if="recipe === null" class="text-xs table-body">
                              <td colspan="4">No hay registros</td>
                            </tr>
                            <template v-else>
                              <tr class="text-xs table-body">
                                <td>{{ formatDate(recipe.created_at) }}</td>
                                <td
                                  v-if="
                                    dataConsultingrooms.model ===
                                    '\\App\\Models\\OfConsultingRoomBooking'
                                  "
                                >
                                  {{
                                    dataConsultingrooms.consulting.of_reservation.of_patient
                                    ? dataConsultingrooms.consulting.of_reservation.of_patient.name
                                    : ''
                                  }}
                                </td>
                                <td
                                  v-if="
                                    dataConsultingrooms.model ===
                                    '\\App\\Models\\OfConsultingRoomBooking'
                                  "
                                >
                                  {{
                                    dataConsultingrooms.consulting.of_doctor
                                      ? dataConsultingrooms.consulting.of_doctor.name
                                      : "-"
                                  }}
                                  {{
                                    dataConsultingrooms.consulting.of_doctor
                                      ? dataConsultingrooms.consulting.of_doctor.last_name
                                      : "-"
                                  }}
                                </td>
                                <td
                                  v-if="
                                    dataConsultingrooms.model ===
                                    '\\App\\Models\\OfOperationsRoomBooking'
                                  "
                                >
                                  {{
                                    dataConsultingrooms.operations.of_reservation.of_patient
                                    ? dataConsultingrooms.operations.of_reservation.of_patient.name
                                    : ''
                                  }}
                                </td>
                                <td
                                  v-if="
                                    dataConsultingrooms.model ===
                                    '\\App\\Models\\OfOperationsRoomBooking'
                                  "
                                >
                                  {{
                                    dataConsultingrooms.operations.of_doctor
                                      ? dataConsultingrooms.operations.of_doctor.name
                                      : "-"
                                  }}
                                  {{
                                    dataConsultingrooms.operations.of_doctor
                                      ? dataConsultingrooms.operations.of_doctor.last_name
                                      : "-"
                                  }}
                                </td>
                                <td>
                                  <a
                                    @click="openDetail()"
                                    class="btn btn-primary btn-sm rounded-circle"
                                    type="button"
                                    style="
                                      background-color: #900052;
                                      border: 2px solid #900052;
                                    "
                                  >
                                    <i class="far fa-plus"></i>
                                  </a>
              
                                  <a
                                    @click="viewDetail(recipe)"
                                    class="btn btn-primary btn-sm rounded-circle"
                                    type="button"
                                    style="
                                      background-color: #900052;
                                      border: 2px solid #900052;
                                      margin-left: 5px;
                                    "
                                  >
                                    <i class="fas fa-eye"></i>
                                  </a>
                                </td>
                              </tr>
                            </template>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
  
        </div>
      </div>
      <div class="px-3" v-else-if="view == 'detailSop'">
      <cDetailSop
        @mtdBack="mtdBack"
        :dataConsultingrooms="dataConsultingrooms"
        :recipe="recipe"
        @updateData="updateData"
        :bead="bead"
      >
      </cDetailSop>
    </div>
    <div class="px-3" v-else-if="view == 'detailCon'">
      <cDetailCon
        @mtdBack="mtdBack"
        :dataConsultingrooms="dataConsultingrooms"
        :recipe="recipe"
        @updateData="updateData"
        :bead="bead"
      />
    </div>
      <div class="px-3" v-else-if="view == 'carrito'">
        <cCarrito
          @mtdBack="mtdBackCar"
          @mtdSendCarrito="mtdSendCarritoCli"
          :recipeId="carrito.recipeId"
          :bead="bead"
        />
      </div>
      <cModalViewDetailVue
        :title="modalDeta.title"
        :boo_modal="modalDeta.boo_modal"
        :item="modalDeta.item"
        @mtdCloseModalView="mtdCloseModal"
        
      />
      <SweetAlert :swal="swal" ref="SweetAlert"/>
    </div>
</template>
  <script>
  import { mapActions } from "vuex";
  import cDetailSop from "./cSopCliOf.vue";
  import cDetailCon from "./cConCliOf.vue";
  import cModalViewDetailVue from "../Modals/cModalViewDetail.vue";
  import cCarrito from "./cCarritoCli.vue";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
  
  export default {
    props: {
        dataConsultingrooms: [],
        recipe: [],
        bead:{
          type:Object,
        }
    },
    data() {
      return {
        swal: null,
        view: "data",
        search: "",
        page: 0,
        reDoc: "",
        modalDeta: {
          title: "Detalles de Receta",
          item: [],
          boo_modal: false,
          pos: null,
        },
        carrito: {
          type_patient: "",
          data: [],
          saleId: null,
          amount: [0, 0, 0],
          recipeId: 0,
        },
      };
    },
    components: {
      cCarrito,
      cDetailSop,
      cDetailCon,
      cModalViewDetailVue,
      SweetAlert,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
  
      returnHome: function () {
        this.$emit("mtdBack");
      },
  
      updateRecipe(newRecipe) {
      this.recipe = newRecipe;
      },
      
      formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""}${month}/${year}`;
      },
  
      openDetail: function () {
        if (this.dataConsultingrooms.model == "\\App\\Models\\OfOperationsRoomBooking") {
          this.view = "detailSop";
        } else if (this.dataConsultingrooms.model == "\\App\\Models\\OfConsultingRoomBooking") {
            this.view = "detailCon";
        }
      },
  
      mtdBack: function () {
        this.view = "data";
      },
  
      updateData: function () {
        this.$emit("updateData", this.dataConsultingrooms);
      },
  
      mtdCloseModal: function () {
        this.modalDeta = {
          title: "Detalles de Despacho",
          item: [],
          boo_modal: false,
          pos: null,
        };
      },
  
      viewDetail(item) {
        console.log(item);
        this.modalDeta.boo_modal = true;
        this.post({
          url: this.$store.getters.get__url + "/pharmacy/viewDetail",
          params: { id: item.id },
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.length > 0) {
              this.modalDeta.item = response.data;
            } else {
              this.modalDeta.boo_modal = false;
              this.$refs.SweetAlert.showWarning("Sin Detalles de Receta");
            }
          })
          .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
          });
      },
  
      mtdAddServiceOpe: function () {
        if (this.dataConsultingrooms.operations.of_reservation.of_sale) {
            this.carrito.saleId = this.dataConsultingrooms.operations.of_reservation.of_sale.id;
        }
        //this.carrito.saleId = this.dataConsultingrooms.operations.of_reservation.of_sale.id;
          this.carrito.recipeId = this.recipe ? this.recipe.id : null;
          this.view = "carrito";
      },
      mtdAddServiceCon: function () {
        if (this.dataConsultingrooms.consulting.of_reservation.of_sale) {
            this.carrito.saleId = this.dataConsultingrooms.consulting.of_reservation.of_sale.id;
        }
        //this.carrito.saleId = this.dataConsultingrooms.consulting.of_reservation.of_sale.id;
          this.carrito.recipeId = this.recipe ? this.recipe.id : null;
          this.view = "carrito";
      },

      mtdBackCar: function() {
        this.carrito.type_patient = "";
        this.carrito.data = [];
        this.carrito.saleId = null;
        this.carrito.amount = [0, 0, 0];
        this.view = "data";
      },
  
      mtdSendCarritoCli: function (carrito) {
        this.carrito.data = carrito;
        this.carrito.patient_id = this.dataConsultingrooms.of_patient_id;
        this.carrito.clinical_history_id = this.dataConsultingrooms.id;
        this.post({
          url: this.$store.getters.get__url + "/pharmacy/add/carritoCli",
          token: this.$store.getters.get__token,
          params: this.carrito,
        })
          .then((response) => {
            if (response.state == 0) {
              this.$refs.SweetAlert.showSuccess("Registro Exitoso");
              this.mtdBack();
              this.$emit("updateData" , this.dataConsultingrooms);
            } else {
              this.$refs.SweetAlert.showError("Registro Incorrecto");
            }
          })
          .catch((errors) => {
            console.log(errors);
          });
      },
    },
  };
  </script>
  <style scoped>
  .tablaRe td,
  .tablaRe th {
    font-size: 17px;
  }
  
  .tr-table > th > div {
    background: #900052;
    border-radius: 7px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
  }
  
  .tr-table > th {
    padding: 0px;
  }
  .tbody-table > td > div {
    background: #eaecee;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .tbody-table > td {
    padding: 0px;
  }
  
  .tbody-table > td > div > div {
    border-radius: 10px;
  }
  .tbody-table1 > td > div {
    background: white;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  
  .tbody-table1 > td {
    padding: 0px;
  }
  
  .tbody-table1 > td > div > div {
    border-radius: 10px;
  }
  .acciones {
    background-color: #900052;
    border-radius: 10px;
  }
  
  .table-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
  }
  
  .table-container::-webkit-scrollbar {
    width: 1px;
  }
  
  .table-container::-webkit-scrollbar-thumb {
    background-color: darkgray;
  }
  
  .table-container::-webkit-scrollbar-track {
    background-color: lightgray;
  }
  
  .custom-margin {
    margin-left: 5px;
  }
  
  .spam {
    background: #900052;
    color: #ffffff;
    width: 90px;
    line-height: 20px;
    justify-content: center;
    border-radius: 1rem;
  }
  
  @media (max-width: 1000px) {
    .responsive-search {
      flex-direction: column;
    }
    .responsive-search > .input-group {
      width: 100% !important;
    }
  
    .table-container > table > thead {
      font-size: 12px !important;
    }
  
    .table-container > table > tbody {
      font-size: 11px !important;
    }
  }
  .text-doctor {
    font-weight: bold;
    font-size: 14px;
    color: #900052;
  }
  
  .text-horario {
    font-weight: bold;
    font-size: 11px;
    color: #808080;
  }
  
  .header-main {
    background-color: #900052;
  }
  #div-tabla-cola {
    border: 1px solid #aaa;
    height: 300px;
    background: #f1f2f3;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  #div-tabla-cola::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  #div-tabla-cola::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  #div-tabla-cola::-webkit-scrollbar-button:increment,
  #div-tabla-cola::-webkit-scrollbar-button {
    display: none;
  }
  
  #div-tabla-cola::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  #div-tabla-cola::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  #div-tabla-cola::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  #content-timeline {
    border: 1px solid #aaa;
    height: 420px;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  #content-timeline::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  #content-timeline::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-button:increment,
  #content-timeline::-webkit-scrollbar-button {
    display: none;
  }
  
  #content-timeline::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  #content-timeline::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  @media (max-width: 992px) {
    .flex-row {
      flex-direction: column !important;
      align-items: center;
      padding-top: 20px !important;
    }
  
    #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
    }
  }
  
  .custom-input {
  border-color: #900052;
  color: #900052;
  }
  
  .custom-input::placeholder {
  color: #900052; 
  }

  .table-body td{
    font-size: 15px;
  }
  </style>
  
<template>
  <div class="container">
    <div id="btn-return" class="d-flex justify-content-end pt-1 mt-3">
      <div class="btn-home" role="group">
        <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
          <i class="fas fa-recycle"></i> Cambiar de Sede
        </button>
      </div>
    </div>
    <div>
      <div id="btn_home" class="c-app align-items-center">
        <div class="d-grid gap-2 mx-auto text-center col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <h2>HOME CONTABILIDAD</h2>
        </div>
        <li v-for="item in queueData" :key="item.name">
          {{ item.name }} - {{ item.status }}
        </li>
      </div>
    </div>
  </div>
</template>
<script>
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '7ad99a1cfb910b29e4bc',
  cluster: 'sa1',

});
export default {
  data() {
    return {
      page: null,
      queueData: [],
    };
  },
  mounted() {
    window.Echo.channel('queue-channel')
    .listen('QueueUpdated', (data) => {
      this.queueData= data.queueData;
    });
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <nav class="navbar">
      <div class="container-fluid">
        <div class="" id="img_logo_login">
          <img src="../../assets/logo_bm.png" alt="Logo" class="d-none d-sm-block" />
        </div>
        <div v-if="page=='Login'" class="col-md-12 w-100">
            <div class="row justify-content-md-end d-flex">
                <div class="text-white col-md-8 col-xs-12 align-center">&nbsp;</div>
                <div id="login-title" class="text-white h4 col-md-4 col-xs-12">{{ hoy }}</div>
            </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'NavBarComponent',
  props: {
    page: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      hoy: null
    }
  },
  created () {
    moment.locale('es')
    this.hoy = moment().format('LLL')
  },
  mounted: function () {
    setInterval(() => {
      moment.locale('es')
      this.hoy = moment().format('LLL')
    }, 1000)
  }
}
</script>

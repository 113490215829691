<template>
<div>
    <div class="row h-100 d-flex justify-content-center">
        <div class="col-md-11 rounded-3 mb-2">
            <div class="row d-flex">
                <div class="col-md-12 col-sm-12 rounded-3 px-0 bg-blue mt-2">
                    <div class="row mx-0">
                        <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                            <span class=" align-middle col-sm-4 h-100 px-2 text-start">Paciente:</span>
                            <span class=" align-middle col-sm-8 h-100 px-0 text-start" v-if="patientEdit">{{ patientEdit.name }}</span>
                            <span class=" align-middle col-sm-8 h-100 px-0 text-start" v-else></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 mt-3">
            <table class="table table-responsive table-bordered px-0">
                <caption></caption>
                <thead class="bg-main text-white">
                    <tr>
                        <th scope="col">N°</th>
                        <th scope="col">Enfermera</th>
                        <th scope="col">Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in dataTableImages" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.userRegister }}</td>
                        <td class="concept">
                            <div class="left"><span>{{item.dateCreated}}</span></div>
                            <div class="right"><span><a href="#" @click="toggleVisibilityGraph(index)"><i class="color-main fas fa-solid fa-eye"></i></a></span></div>
                        </td>
                    </tr>
                    <tr v-if="dataTableImages.length == 0">
                        <td colspan="3" class="text-center">No hay registros aun</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'c-medico-ayudadx-cPatient',
    components: {

    },
    data() {
        return {
            patientEdit: {
                name: ''
            },
        }
    },
    props: {
        patient: {
            type: Object,
            default: () => ({})
        },
        dataTableImages: {
            type: Array,
            default: () => ([])
        }
    },
    watch: {
        patient: {
            handler(newVal) {
                if (newVal) {
                    this.patientEdit = {
                        ...newVal
                    };
                }
            },
            immediate: true,
        },
    },
    created() {

    },
    mounted() {
    },
    methods: {

        toggleVisibilityGraph: function (index) {
            this.$emit('toggleVisibilityGraph',index);
        },
    }
}
</script>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >

    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <div class="checkbox-grid ms-4">
                    <div class="checkbox-item" v-for="(item, index) in cpData" :key="index">
                        <label class="form-check-label" :for="'checkbox-' + index">
                            {{ item }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <SweetAlert :swal="swal" ref="SweetAlert"/>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";

export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
    CModal,
    SweetAlert,
    },
    data() {
    return {
        swal: null,
        filter: {
            init: "",
            end: "",
        },
    };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: [],
    },
    created() {
    },
    computed: {
        cpData() {
            return this.item.map(element => element);
        },
    },
    methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
        this.$emit("closeModalDate");
    },

    },

};
</script>
<style scoped>
.checkbox-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; 
    max-height: 210px; 
    overflow-y: auto;
}

.form-check-label {
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
    border-radius: 20px;
    font-size: 14px;
}
</style>
  
  
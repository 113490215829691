import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
Vue.use(CKEditor);
Vue.config.productionTip = false
Vue.prototype.$log = console.log.bind(console)
Vue.component("v-select", vSelect);
export const bus = new Vue();

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
  template: '<App/>',
  components: {
    App
  }
})
//.$mount('#app')

<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
  
    <div class="dropdown-header" @click="toggleDropdown(title)">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='CampoVisual'">
                <CampoVisual @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></CampoVisual>
            </div>
            <div id="myTabContentHijo" v-if="component=='Oct'">
                <Oct @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></Oct>
            </div>
            <div id="myTabContentHijo" v-if="component=='OctMacula'">
                <OctMacula @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></OctMacula>
            </div>
            <div id="myTabContentHijo" v-if="component=='Paquimetria'">
                <Paquimetria @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></Paquimetria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Topografia'">
                <Topografia @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></Topografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Microscopia'">
                <Microscopia @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></Microscopia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Ecografia'">
                <Ecografia @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></Ecografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Biometria'">
                <Biometria @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></Biometria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Angiografia'">
                <Angiografia @returnHome="returnHome" @returnReadingResult="returnReadingResult" :patient="patient"></Angiografia>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import CampoVisual from '@/components/Enfermera/AyudaDx/CampoVisual.vue';
import Oct from '@/components/Enfermera/AyudaDx/Oct.vue';
import OctMacula from '@/components/Enfermera/AyudaDx/OctMacula.vue';
import Paquimetria from '@/components/Enfermera/AyudaDx/Paquimetria.vue';
import Topografia from '@/components/Enfermera/AyudaDx/Topografia.vue';
import Microscopia from '@/components/Enfermera/AyudaDx/Microscopia.vue';
import Ecografia from '@/components/Enfermera/AyudaDx/Ecografia.vue';
import Biometria from '@/components/Enfermera/AyudaDx/Biometria.vue';
import Angiografia from '@/components/Enfermera/AyudaDx/Angiografia.vue';

export default {
    name: 'dropdown-component-ayudadx',
    props: {
        title: String,
        component: String,
        patient:Object,
    },
    components: {
        CampoVisual,
        Oct,
        OctMacula,
        Paquimetria,
        Topografia,
        Microscopia,
        Ecografia,
        Biometria,
        Angiografia
    },
    data: () => ({
        dropdown: true,
        dataPlanDetailType: [],
    }),
    methods: {
        ...mapActions(['get', 'post']),
        toggleDropdown(subpage) {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },

        changePage(){
            this.$emit('changePage','cola')
        },
        returnHome() {
            this.$emit('returnHome');
        },
        returnReadingResult: function (patient) {
            this.$emit("returnReadingResult", patient);
        },
        
    }
}
</script>

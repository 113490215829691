<template>
  <div>
    <div class="container">
      <div class="d-flex flex-row-reverse">
        <div class="mt-1 mb-4 btn-home">
          <button type="button" class="btn btn-outline-main" @click="returnHome">
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-anular" class="w-100">
        <ul class="nav pb-0" id="myTab" role="tablist">
          <!-- <li class="nav-item" role="presentation">
            <button
              @click="changePage('insumos')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'insumos',
              }"
              id="home-tab"
              type="button"
            >
              Insumos
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              @click="changePage('price')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'price',
              }"
              id="profile-tab"
              type="button"
            >
              Precios de compra
            </button>
          </li> -->
          <li class="nav-item" role="presentation">
            <button
              @click="changePage('provider')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'provider',
              }"
              id="profile-tab"
              type="button"
            >
              Proveedores
            </button>
          </li>
          <!--<li class="nav-item" role="presentation">
            <button
              @click="changePage('medic')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'medic',
              }"
              id="profile-tab"
              type="button"
            >
              Medicamentos
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              @click="changePage('unit')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'unit',
              }"
              id="profile-tab"
              type="button"
            >
              Unidades
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              @click="changePage('almacen')"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === 'almacen',
              }"
              id="profile-tab"
              type="button"
            >
              Inventario 2023
            </button>
          </li> -->
        </ul>
        <div class="tab-content " id="myTabContent">
          <!-- <InsumosVue v-if="page == 'insumos'" />
          <PrecioCompraVue
            v-if="page == 'price'"
            :providers="providers"
            :products="products"
            @mtdSearchProducts="mtdSearchProducts"
            @mtdCommitEditPrice="mtdCommitEditPrice"
          /> -->
          <ProveedoresVue v-if="page == 'provider'" />
          <!-- <MedicineVue v-if="page == 'medic'" />
          <UnitsVue v-if="page=='unit'" />
          <Almacen v-if="page=='almacen'" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
// import InsumosVue from "./Mantenimiento/Muebles.vue";
// import PrecioCompraVue from "./Mantenimiento/PrecioCompra.vue";
import ProveedoresVue from "./Mantenimiento/Proveedores.vue";
// import MedicineVue from "./Mantenimiento/Medicine.vue";
// import UnitsVue from './Mantenimiento/Units.vue';
// import Almacen from "./Mantenimiento/Almacen.vue";
export default {
  data() {
    return {
      page: "provider",
      /** precios */
      providers: [],
      products: [],
    };
  },
  components: { 
    // InsumosVue, PrecioCompraVue,
     ProveedoresVue,
      // MedicineVue, UnitsVue, Almacen 
    },
  // created() {
  //   this.mtdGetData();
  // },
  methods: {
    ...mapActions(["get", "post"]),
    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.$emit("returnHome");
    },
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/logistics/getProviders",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.providers = response.providers;
        })
        .catch((errors) => {});
    },
    mtdSearchProducts: function (provider) {
      this.get({
        url: this.$store.getters.get__url + "/logistics/getProducts/" + provider,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.products = response.data;
        })
        .catch((errors) => {});
    },
    mtdCommitEditPrice: function (pos, price, provider) {
      this.post({
        url: this.$store.getters.get__url + "/logistics/update/pricepurchase",
        token: this.$store.getters.get__token,
        params: {
          detail: this.products[pos].id,
          provider: provider,
          price: price,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            let oldPrice = parseFloat(this.products[pos].price_old);
            this.products[pos].price_old = parseFloat(price).toFixed(2);
            Swal.fire({
              title: "Correcto!!",
              text: "Editado correctamente",
              icon: "success",
              // width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          } else {
            Swal.fire({
              title: "Error",
              text: "",
              icon: "error",
              // width: "400px",
              confirmButtonColor: "rgb(170, 2, 95)",
            });
          }
        })
        .catch((errors) => {
          Swal.fire({
            title: "Error",
            text: errors,
            icon: "error",
            // width: "400px",
            confirmButtonColor: "rgb(170, 2, 95)",
          });
        });
    },
  },
};
</script>


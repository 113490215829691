<template>
    <div>
        <div class="p-3">
            <div class="row text-end">
                <div class="col-md-12 mt-3 mb-4 float-right btn-home">
                    <!-- <button type="button" class="btn btn-outline-main">
                        <i class="fas fa-table"></i> Reportes
                    </button> -->
                    &nbsp;
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>CXC</strong>
                    </div>
                    <div class="card-body ">
                        <div class="row">
                            <div class="row mx-0">
                                <div class="col-xl-2 col-12 px-0">
                                    <div class="input-group input-group-sm w-100  ">
                                        <span class="input-group-text ">Desde</span>
                                        <input type="date"
                                        @input="mtdGetData()" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 col-12 px-0 px-xl-1 my-xl-0 my-2">
                                    <div class="input-group input-group-sm  w-100 ">
                                        <span class="input-group-text ">Hasta</span>
                                        <input  @input="mtdGetData()" 
                                        type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end"/>
                                    </div>
    
                                </div>
                                <div class="col-xl-3 col-12 px-0 px-xl-1 my-xl-0 my-2">
                                    <div class="input-group input-group-sm mb-3">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input v-model="search" type="text" class="form-control form-control" placeholder="Buscar Paciente por DNI o Nombre"
                                        @input="mtdSearchFilter"/>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-12 px-0 px-xl-1 my-xl-0 my-2">
                                    <div class="input-group input-group-sm mb-3">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <v-select id="serviceSelect" class="text-dark form-control" label="name" :options="serviceOptions" 
                                        placeholder="- Buscar Tipo de Servicio -" v-model="selectedService"  @input="mtdSearchFilter"/>
                                    </div>
                                </div>
                                <div class="col-xl-2 px-0">
                                <div class="input-group input-group-sm text-center btn-home">
                                    <span class="input-group-text text-white bg-main d-block mx-auto">
                                        <input type="checkbox" id="pagado" class="mb-2" v-model="pagado" @click="pagadoestado()" style="transform: scale(1.5);">  
                                       <label for="pagado" class="ml-2">&nbsp;Ver Pagados</label>
                                    </span>
                                </div>
                            </div>

                            </div>
                            
                            <div class="col-md-12 table-container" id="content-timelinetable">
    
                                <table class="table table-bordered ">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class="text-white table-th text-center text-xs ">
                                            <th class="align-middle border border-5 bg-main border-white">N°</th>
                                            <th class="align-middle border border-5 bg-main border-white">CHECK IN</th>
                                            <th class="align-middle border border-5 bg-main border-white">FECHA</th>
                                            <th class="align-middle border border-5 bg-main border-white">MEDICO</th>
                                            <th class="align-middle border border-5 bg-main border-white">SERVICIO</th>
                                            <th class="align-middle border border-5 bg-main border-white">SERVICIO ESPECIFICO</th>
                                            <th class="align-middle border border-5 bg-main border-white">PACIENTE</th>
                                            <th class="align-middle border border-5 bg-main border-white">PRECIO DEL SERVICIO</th>
                                            <th class="align-middle border border-5 bg-main border-white"> AMORTIZADO</th>
                                            <th class="align-middle border border-5 bg-main border-white">PENDIENTE</th>
                                            <th class="align-middle border border-5 bg-main border-white">ESTADO</th>
                                            <th class="align-middle border border-5 bg-main border-white">OJOS</th>
                                            <th class="align-middle border border-5 bg-main border-white">LENTE INTRACULAR</th>
                                            <th class="align-middle border border-5 bg-main border-white">DIOPTRIA</th>
                                            <th class="align-middle border border-5 bg-main border-white">MARCA</th>
                                            <th class="align-middle border border-5 bg-main border-white">PEGAMENTO</th>
                                            <th class="align-middle border border-5 bg-main border-white">ACCIÓNES</th>
                                        </tr>
                                    </thead>
    
                                    <tbody class="text-xs">
                                        <tr v-if="cpData.length === 0">
                                            <td colspan="16" class="text-center">No hay registros</td>
                                        </tr>
                                        <tr class="table-body" v-for="(item, index) in cpData" :key="index">
                                            <td class="border border-5 border-white align-middle">
                                                {{ index+1}}
                                            </td>
                                            <td class="border border-5 border-white align-left align-middle" v-if="item.state == 4">
                                                {{ item.checkin | formatDateTime  }}
                                            </td>
                                            <td class="border border-5 border-white align-left align-middle space" v-if="item.state !== 4">
                                                <button type="button" class="btn btn-sm btn-outline-success" :disabled="item.payment !== 0 || item.present !== 1"  @click="confirmSet(item.id)">
                                                    <i class="fas fa-file-check"></i> CheckIn
                                                </button>
                                            </td>
                                            <td class="border border-5 border-white align-middle text-center">
                                                {{ item.created_at}}
                                            </td>
                                            <td class="border border-5 border-white align-middle">
                                                {{ item.of_doctor_name}}
                                            </td>
                                            <td class="border border-5 border-white align-middle">
                                                {{ item.of_service_name}}
                                            </td>
                                            <td class="border border-5 border-white align-middle">
                                                {{ item.of_product_name}}
                                            </td>
                                            <td class="border border-5 border-white align-middle">
                                                {{ item.of_patient_document}} - {{ item.of_patient_name}}
                                            </td>
                                            <td class="border border-5 border-white align-middle text-end">
                                                {{ item.total != '' ? 'S/.' + item.total:
                                                'S/.' +
                                                parseFloat(parseFloat(item.price_sale) +
                                                (parseFloat(item.price_sale)*0.18)).toFixed(2) }}
                                            </td>
                                            <td class="border border-5 border-white align-middle text-end">
                                                {{ item.amount!==''? 'S/.' + item.amount : 'S/. 0.00' }}
                                            </td>
                                            <td class="text-danger border border-5 border-white align-middle text-end">
                                                {{
                                                    item.pending !== ''
                                                        ? 'S/. ' + (
                                                            item.pending < 0
                                                                ? parseFloat(item.pending * -1).toFixed(2)
                                                                : parseFloat(item.pending).toFixed(2)
                                                        )
                                                        :'S/. ' + parseFloat(parseFloat(item.price_sale) +
                                                (parseFloat(item.price_sale)*0.18)).toFixed(2) 
                                                }}
                                            </td>
                                            <td class="td-div-state border border-5 border-white align-middle text-center ">
                                                <div>
                                                    <div class="" v-if="item.pending < 0 || item.pending==''
                                                    || item.sale_pendidng !='0.00'" >
                                                        <div class="text-danger"><strong>DEBE</strong></div>
                                                    </div>
                                                    <div class="" v-else-if="item.pending < 0">
                                                        <div class="text-success">
                                                            <strong>DEVOLUCIÓN</strong>
                                                        </div>
                                                    </div>
                                                    <div class="" v-else-if="item.sale_pendidng=='0.00'">
                                                        <div class="text-success">
                                                            <strong>PAGADO</strong>
                                                        </div>
                                                    </div>
                                                    <div class="" v-else>
                                                        <div class="text-success">
                                                            <strong>-</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
    
                                            <td class=" border border-5 border-white align-middle ">
                                                <select class="form-control  form-control-xss py-0 px-1 text-xs" 
                                                 v-model="item.eyes" name="indicacion" :disabled="item.of_service_id!=2"
                                                 @input="guardaroperacion(item,index)"
                                                 >
                                                    <option value="" selected> Seleccione </option>
                                                    <option value="AO">AO </option>
                                                    <option value="OD">OD </option>
                                                    <option value="OI">OI </option>
                                                </select>
                                            </td>
                                            <td class="border border-5 border-white align-middle ">
                                                <input  v-model="item.lens" class="form-control form-control-xss" 
                                                :disabled="item.of_service_id!=2"
                                                @keyup.enter="guardaroperacion(item,index)">
                                            </td>
                                            <td class=" border border-5 border-white align-middle ">
                                                <input  v-model="item.diopter" class="form-control form-control-xss "
                                                :disabled="item.of_service_id!=2"
                                                @keyup.enter="guardaroperacion(item,index)">
                                            </td>
                                            <td class=" border border-5 border-white align-middle ">
                                                <v-select
                                                id="brand"
                                                class="text-dark form-control"
                                                label="name"
                                                :options="brands"
                                                placeholder="- seleccione -"
                                                v-model="item.brand"
                                                :reduce="(name) => name.id"
                                                :disabled="item.of_service_id!=2"
                                                @input="guardaroperacion(item,index)"
                                            />
                                            </td>
                                            <td class=" border border-5 border-white align-middle ">
                                                <select class="form-control  form-control-xss py-0 px-1 text-xs" 
                                                :disabled="item.of_service_id!=2"
                                                 v-model="item.pegamento" name="indicacion"
                                                 @input="guardaroperacion(item,index)">
                                                    <option value="" selected> Seleccione </option>
                                                    <option value="SI">SI</option>
                                                    <option value="NO">NO</option>
                                                </select>
                                            </td>
                                            <td class="text-center border border-5 border-white align-middle">
                                                <div class="d-flex justify-content-evenly">
                                                    <a class=" btn btn-success btn-sm px-1" v-if="item.pending!='0.00'"  @click="mtdViewPago(item)">
                                                        <i class="far fa-money-bill-alt"></i>
                                                    </a>
                                                    <a class=" btn btn-info btn-sm mx-1 text-white"
                                                     @click="pdfgeneral(item)">
                                                        <i class="far fa-file-pdf"></i>
                                                    </a>
                                                    <a class="btn btn-primary btn-sm  " @click="mtdViewHistorial(item, index)">
                                                        <i class="fas fa-list"></i>
                                                    </a>
                                                    <a class=" btn btn-warning btn-sm  mx-1 text-white" @click="mtdReprogramar(item)">
                                                        <i class="fas fa-clock"></i>
                                                    </a>
                                                    <a class="btn btn-danger btn-sm " 
                                                    @click="mtdDeleteRe(item)"
                                                    >
                                                        <i class="fas fa-times-circle"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!--pag-->
                            <div  v-if="cpData.length > 0" class="d-flex justify-content-center px-1 ">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
    
                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
            <cModalRegistrarPagoVue  :bead="bead" :key="key":item="item" :title=" modalPagos.title"
             :boo_modal="modalPagos.modal_form" @closeModalPagos="closeModalPagos" 
              :canje="canje"/>
            <cModalHistorialPagosVue :title="modalEdit.title" 
            :pagos="pagos" :key1="key1"
            :boo_modal="modalEdit.modal_form" :item="item" 
            @closeModalHistorial="closeModalHistorial" />
            <cModalAnulacionVue :title=" modalAnulacion.title" :boo_modal="modalAnulacion.modal_form" @closeModalAnulacion="closeModalAnulacion" />
            <SweetAlert :swal="swal" ref="SweetAlert"/>
        </div>
    </div>
</template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import cModalAnulacionVue from './Modals/cModalAnulacion.vue';
    import moment from "moment";
    import cModalHistorialPagosVue from './Modals/cModalHistorialPagos.vue';
    import cModalRegistrarPagoVue from './Modals/cModalRegistrarPago.vue';
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    export default {
        name: 'c-oftalmologia-cxc',
        components: {
            cModalRegistrarPagoVue,
            cModalHistorialPagosVue,
            cModalAnulacionVue,
            SweetAlert,
        },
        data() {
            return {
                brands:[],
                key:0,
                key1:0,
                pagado:false,
                canje:false,
                swal: null,
                item:{},
                view: "data",
                serviceOptions: [],
                selectedService: "",
                filter: {
                    init: null,
                    end: null,
                },
                search: "",
                totalPay: 0.0,
                bottonActiveM: "",
                bottonActiveT: "",
                dataShow: [],
                dataDocumentType: [],
                filter: {
                    filter_area_name: "ADMISION",
                },
                disabledChkDoc: true,
                chkDoc: false,
                chkPatiente: {
                    document: "",
                    fullName: "",
                    address: "",
                },
                page: 0,
                selectPage: 1,
                stepPagination: [],
                dataCxcs: [],
                dataCharges: [],
                payment: {
                    document: "",
                    method: "",
                    type: "",
                    amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                    },
                    pending: 0,
                    vuelto: 0,
                    payment_patient: null,
                    payment_eps: 0,
                },
                sale: {
                    id: "",
                    patient_id: "",
                    total: "",
                    pending: "",
                    state: "",
                },
                modal: {
                    title: "",
                    action: "",
                },
                posEdit: null,
                noRecordsMessage: "",
                vuelto: false,
                pending: false,
                procede: false,
                posEditPeriod: null,
                newAdmissionPeriod: null,
                /** carrito */
                carrito: {
                    type_patient: "",
                    data: [],
                    saleId: null,
                    amount: [0, 0, 0],
                    insurance: null,
                },
                /** canje */
                modalCanje: {
                    title: "Canjear documento",
                    item: [],
                    boo_modal: false,
                    pending: null,
                    bead: null,
                    method: null,
                    sustento: null,
                    type: null,
                    patient: null,
                    sale: null,
                },
                /** canje */
                modalFechas: {
                    title: "Periodo del servicio",
                    item: [],
                    boo_modal: false,
                    date_ind: null,
                    date_end: null,
                },
                datePos: 0,
                /** editar carrito */
                modalEdit: {
                    title: "HISTORIAL DE PAGOS",
                    item: [],
                    modal_form: false,
                    pos: null,
                },
                /** pdf  */
                doctors: [],
                modalPpdfLiqui: {
                    title: "Datos para el pdf de liquidación",
                    boo_modal: false,
                    doctors: [
                    {
                        name: "",
                        id: "",
                    },
                    ],
                    sale: null,
                },
                permissionValue: null,
                permissionEli: null,
                //Otro
                carnocidad: false,
                modalPagos: {
                    title: '',
                    modal_form: false,
                },
                modalHistorial:{
                    title: '',
                    modal_form: false,
                },
                modalAnulacion:{
                    title: '',
                    modal_form: false, 
                },
                pagos:[]
            }
        },
        props:{
            bead:Object
        },
        created() {
            moment.locale("es");
            this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
            this.filter.init = this.filter.end;
            this.mtdGetData();
            this.mtdBrands();
        },
    
        watch: {
            selectPage() {
                this.calculateData(7);
            },
          },
    
        computed: {
            CvalidateSevicio() {
                return !this.carnocidad;
            },
    
            cpData() {
              return this.dataShow;
            },
    
            limitedStepPagination() {
                const totalPages = this.stepPagination.length;
                const currentPage = this.selectPage;
    
                let startPage = 1;
                let endPage = totalPages;
                if (totalPages > this.totalPagesToShow) {
                    const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);
    
                    if (currentPage > halfPagesToShow) {
                        startPage = currentPage - halfPagesToShow;
                        endPage = currentPage + halfPagesToShow - 1;
    
                        if (endPage > totalPages) {
                            endPage = totalPages;
                            startPage = totalPages - this.totalPagesToShow + 1;
                        }
                    } else {
                        endPage = this.totalPagesToShow;
                    }
                }
    
                return Array.from({
                        length: endPage - startPage + 1,
                    },
                    (_, i) => startPage + i
                );
            },
        },
        methods: {
            ...mapActions(["get", "post"]),
            returnHome: function () {
                this.$emit("mtdChangePage", "home");
            },
            pagadoestado(){
             this.pagado=!this.pagado;
             this.calculateData(7);
            },
            mtdGetData: function () {
                this.post({
                    url: this.$store.getters.get__url + "/Admision/cxc",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        init: this.filter.init,
                        end: this.filter.end,
                    },
                })
                .then((response) => {
                    this.dataCxcs = [];
                    this.dataCxcs = response.data;
                    let uniqueServiceNames = new Set(); 
                    this.dataCxcs.forEach(item => {
                        uniqueServiceNames.add(item.of_service_name); 
                    });
                    //this.serviceOptions = Array.from(uniqueServiceNames);
                    this.serviceOptions = ["TODOS", ...Array.from(uniqueServiceNames)]; 
    
                    //this.doctors = response.doctors;
                    if (this.dataCxcs.length === 0) {
                        this.noRecordsMessage = "No hay registros";
                        this.dataShow=[];
                    } else {
                        this.calculateData(7);
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            },
            mtdBrands: function () {
                this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexBrands",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                   this.brands=response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            },
    
            calculateData(items) {
                if (!this.dataCxcs || !this.dataCxcs.length) {
                    return this.dataShow = [];
                }
                
                this.dataShow = [];
                let filteredData = this.dataCxcs.slice(); // Clonar la lista original
                let indexInitial = this.selectPage; // Almacenar la página actual antes de aplicar el filtro

                if (this.search.length >= 2 && this.search != "") {
                    let query = this.search.toString().toUpperCase();
                    filteredData = filteredData.filter((element) => {
                        let of_patient_name = element.of_patient_name.toString().toUpperCase();
                        let of_patient_document = element.of_patient_document.toString().toUpperCase();
                        return of_patient_name.includes(query) || of_patient_document.includes(query);
                    });
                     // Reiniciar la paginación cuando se realiza una búsqueda por nombre o DNI
                }

                if (this.selectedService && this.selectedService !== 'TODOS') {
                    filteredData = filteredData.filter((element) => {
                        return element.of_service_name === this.selectedService;
                    });
                    
                }

                if (this.pagado == false) {
                    filteredData = filteredData.filter((element) => {
                        return element.sale_pendidng !== '0.00';
                    });
                   // Si se aplica un filtro, volver a la página 1
                }

                let totalPages = Math.ceil(filteredData.length / items);
                if (totalPages < indexInitial) {
                    indexInitial = totalPages; // Si la página actual está más allá del nuevo total de páginas, establecerla en la última página
                }
                
                this.stepPagination = Array.from({
                    length: totalPages,
                }, (_, index) => index + 1);

                let startIndex = (indexInitial - 1) * items;
                let endIndex = startIndex + (items - 1);
                this.dataShow = filteredData.slice(startIndex, endIndex + 1);
                this.currentPage = indexInitial;
                this.totalPages = totalPages;
                this.totalItems = filteredData.length;  
            },
            mtdSearchFilter() {
                this.calculateData(7)
            },
    
            mtdViewPago (item) {
                if(item.created_at=='PROGRAMAR FECHA'){
                Swal.fire({
                    text: "Programe  una fecha antes de realizar el pago",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;}
                this.key++;
                this.modalPagos = {
                    title: 'AGREGAR PAGO  ',
                    modal_form: true,
                }
                this.item=item;
            },
            closeModalPagos:function(){
                this.mtdGetData();
                this.modalPagos = {
                title: '',
                modal_form: false,
                }
                this.item={};
                this.canje=false;

            },
            mtdViewHistorial:function(item, pos){
                if(item.sale_id==""){
                    this.$refs.SweetAlert.showWarning("Aun No  ha registrado  pagos.");
                    return;
                }
                this.post({
                    url: this.$store.getters.get__url + "/Admision/cxcpagos",
                    token: this.$store.getters.get__token,
                    params: {
                        sale: item.sale_id,
                    },
                })
                .then((response) => {
                    if(response.error==false){
                    this.pagos= response.data;
                    if(this.pagos.length>0){
                        this.key1++;
                        this.modalEdit.item = item.detail;
                        this.modalEdit.modal_form = true;
                        this.modalEdit.pos = pos;
                        this.item=item; 
                      }else{
                        this.$refs.SweetAlert.showWarning("Aun No  ha registrado  pagos.");
                        return;
                      }
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            },
            closeModalHistorial:function(){
                this.modalEdit = {
                    title: "HISTORIAL DE PAGOS",
                    item: [],
                    modal_form: false,
                    pos: null,
                };
            },
            pdfgeneral:function(item) {
                if(item.sale_id==""){
                    this.$refs.SweetAlert.showWarning("Aun No  ha registrado  pagos.");
                    return;
                }
                if(item.pending!="0.00"){
                    this.$refs.SweetAlert.showWarning("Complete el Pago del Servicio .");
                    return;
                }
                this.post({
                    url: this.$store.getters.get__url + "/Admision/cxcpagos",
                    token: this.$store.getters.get__token,
                    params: {
                        sale: item.sale_id,
                    },
                })
                .then((response) => {
                    if(response.error==false){
                    this.pagos= response.data;
                    if(this.pagos.length>0){
                        this.key++;
                        const ultimoPago = this.pagos[this.pagos.length - 1];
                        if(ultimoPago.document=='TICKET'){
                            this.modalPagos = {
                                title: 'CANJEAR PAGOS',
                                modal_form: true,
                            }
                            this.item=item;
                            this.canje=true;
                        }else{
                         let res = JSON.parse( ultimoPago.sunat);
                          window.open(res.json.enlace_del_pdf_ticket, "_blank");
                        }
                      }else{
                        this.$refs.SweetAlert.showWarning("Aun No  ha registrado  pagos.");
                        return;
                      }
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            },
            mtdViewAnulacion:function(){
                this.modalAnulacion={
                    title: 'REGISTRAR ANULACIÓN',
                    modal_form: true, 
                }
            },
            closeModalAnulacion:function(){
               this.modalAnulacion={
                    title: '',
                    modal_form: false, 
                }
            },
            mtdReprogramar(item){
             this.$emit('mtdReprogramar',
                'cita',item.id,
                 item.created_at,
                 item.of_service_id,
                 item.of_patient_id,
                 item.hour,
                 item.of_doctor_id ? item.of_doctor_id : 0,
                 item.state
             );
            },
            // pagination
            backPage() {
                this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
            },
            nextPage() {
                this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
            },
            selectedPage(page) {
                this.selectPage = page;
            },
    mtdDeleteRe: function (item) {
        // console.log(item);
            if (item.amount!="") {
                this.$refs.SweetAlert.showWarning("La Reserva no se puede Anular.");
                return;
            }
      this.$refs.SweetAlert.showConfirmSimple2(
        "",
        "Eliminar Reserva",
        "warning",
        "Si, Confirmar!"
      ).then((result) => {
        if (result.value) {
          this.confirmDelete(item.id);
        }
      });
    },
    confirmDelete(ids) {
      this.post({
        url: this.$store.getters.get__url + "/Call/deleteReservation",
        token: this.$store.getters.get__token,
        params: {
          id: ids,
        },
      })
        .then((response) => {
          if (response.state == 1) {
            this.$refs.SweetAlert.showError("Error");
          } else {
            this.$refs.SweetAlert.showSuccess(response.message);
            this.mtdGetData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    guardaroperacion(item,index){
        this.$refs.SweetAlert.showConfirmSimple2(
        "",
        "Esta Seguro de Registrar",
        "warning",
        "Si, Confirmar!"
      ).then((result) => {
        if (result.value) {
          this.EditReserva(item,index);
        }
       });
    },
    EditReserva(item,index){
        this.post({
        url: this.$store.getters.get__url + "/Admision/cxc/reserva",
        token: this.$store.getters.get__token,
        params:item
      })
        .then((response) => {
          if (response.error == false) {
            this.$refs.SweetAlert.showSuccess("Exito", "Registro Exitoso");
            this.mtdGetData();
          } else {
            this.$refs.SweetAlert.showError("Error");}
        })
        .catch((error) => {
          console.log(error);
        });
    },
    
    confirmSet(index) {
            this.$refs.SweetAlert.showConfirmSimple2(
                "",
                "Confirmar CheckIn",
                "warning",
                "Si, Confirmar!"
            ).then((result) => {
                if (result.value) {
                    this.confirmSetDate(index);
                }
            });
        },
        confirmSetDate(index) {
            this.post({
                    url: this.$store.getters.get__url + "/Call/editStateRe",
                    token: this.$store.getters.get__token,
                    params: {
                        id: index
                    },
                })
                .then((response) => {
                    if (response.state == 1) {
                        this.$refs.SweetAlert.showError("Error");
                    } else {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdGetData();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
   
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
    }
    </script>
    
    <style scoped>
    .table-th>th {
        border-radius: 15px;
    }
    
    .table-body>td {
        background: #eaecee;
        border-radius: 15px;
    }
    </style>
    
<template>
<div>
    <div class="container">
        <div class="d-flex flex-row-reverse">
            <div class="mt-5 mb-4 btn-home">
                <button type="button" class="btn text-white" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="card-header text-white h4 bg-main text-center">
                    <strong>USUARIOS</strong>
                </div>
                <div class="card-body w-100">
                    <cTable :button_num="button_num" :columns="columns" :data="data" @openmodal="openmodal" @deleteData="deleteData" />
                </div>
            </div>
        </div>
        <cModalUserVue :dataRole="dataRole" :num="modal.num" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" :data="data"/>
        <cModalRolesVue @mtdaddroles="mtdaddroles" :num="modalRoles.num_data" :idRoles="idRoles" :title="modalRoles.title" :boo_modal="modalRoles.modal_form" @closeModalRoles="closeModalRoles" :dataRolesUsuario="dataRolesUsuario" :dataRole="dataRole" :dataUser="dataUser"/>
        <SweetAlert :swal="swal" ref="SweetAlert" @deleteDataDetail="deleteDataDetail" />
        <!-- <cModalRolesVue :provider_id="provider_id"   @mtdaddbrand="mtdaddbrand" :idMarca="idMarca" :dataProvider="dataProvider" :num="modalMarca.num_data" :dataBrandProvider="dataBrandProvider" :dataRole="dataRole"  :title="modalMarca.title" :boo_modal="modalMarca.modal_form" @closeModalMarca="closeModalMarca" /> -->
    </div>
</div>
</template>

    
<script>
import {
    mapActions
} from "vuex";
import cTable from '../shared/Table/cTable.vue'
import cModalUserVue from './Modals/cModalUser.vue'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalMarcaVue from './Modals/cModalMarca.vue';
import cModalRolesVue from './Modals/cModalRoles.vue';
export default {
    name: 'c-mantenimiento-provider',
    data() {
        return {
            swal: null,
            provider_id: null,
            search: "",
            columns: [
                'N°',
                'NAME',
                'DNI',
                'EMAIL',
                'ROLES',
                'ESTADO',
                'ACCIONES'

            ],
            data: [],
            dataRole: [],
            dataRolesUsuario: [],
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalRoles: {
                title: '',
                modal_form: false,
                num_data: null,
            },
            idRoles: [],
            dataUser: {},
            button_num: null,
        }
    },
    components: {
        cTable,
        cModalUserVue,
        SweetAlert,
        cModalMarcaVue,
        cModalRolesVue
    },
    created() {
        this.getData();
        this.getDataRole()
    },
    methods: {
        ...mapActions(['get', 'post']),
        getData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexuser",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    this.data = response.data
                    this.button_num = 2
                })
                .catch(error => {
                    console.log(error);
                })
        },
        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail)
            } else {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail)
            }
        },
        addDatadetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/User",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.closeModal()
                    this.getData();
                })
                .catch(error => {
                    console.log(error);
                })
        },
        deleteData: function (payload_detail) {
            payload_detail.action = 'd';
            this.$refs.SweetAlert.showDelete(payload_detail, 'deleteDataDetail');

        },
        deleteDataDetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/User",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (response.statusCode == 200) {
                        this.getData();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        getDataRole: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexroles",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    this.dataRole = response.data
                })
                .catch(error => {
                    console.log(error);
                })

        },
        mtdaddroles: function (roles) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/addRolesProvider",
                    token: this.$store.getters.get__token,
                    params: roles,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.getData();
                    this.closeModalRoles()
                })
                .catch(error => {
                    console.log(error);
                })
        },

        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        openmodal: function (num, payload) {
            if (num == 2 || num == 3) {
                if (num == 3) {
                    this.dataRolesUsuario = payload.roles
                    this.provider_id = payload.id;
                } else {
                    this.dataUser = payload;
                    this.idRoles = [];
                    payload.roles.forEach(element => {
                        this.idRoles.push(element.role_id)
                    });
                }
                this.modalRoles = {
                    title: (num == 2) ? 'Listado de Roles' : 'Roles del Usuario',
                    modal_form: true,
                    num_data: num
                }
            } else {
                this.modal.title = (num == 0) ? 'Registrar Usuario' : 'Editar Usuario';
                this.modal.modal_form = true;
                this.modal.num = num;
                this.modal.payload = payload;
            }

        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
        closeModalRoles: function () {
            this.modalRoles = {
                title: '',
                modal_form: false,
            }
            this.idRoles = [];
            this.dataProvider = {}
        }
    }
}
</script>

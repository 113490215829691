<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Nombre y Apellidos</label>
                <input type="text" class="form-control form-control-sm" placeholder="Nombre y Apellidos" v-model="data_detail.name">
            </div>
        </div>
        <div v-if="payload == null" class="mb-2 col-lg-12 col-md-12 px-1">
            <label for="name" class="form-label">Rol Principal</label>
            <select class="form-control form-control-sm" aria-label=".form-select-sm example" v-model="data_detail.rol_id" @change="mtdSelectRol">
                <option selected disabled value="">Selecione...</option>
                <option v-for="(role,index) in dataRole" :key="index" :value="role.id">{{ role.name }}</option>
            </select>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Dni</label>
                <input type="text" class="form-control form-control-sm" placeholder="Dni" @keydown="filterKey" @keyup="mtdUniqueDni" v-model="data_detail.dni" :maxlength="8">
            </div>
            <span class="text-danger" style="font-size: 10px;" v-if="!dniUnique">
                Ya existe un usuario registrado con este DNI</span>
        </div>
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Email</label>
                <input type="text" class="form-control form-control-sm" placeholder="Email" v-model="data_detail.email" @keyup="mtdUniqueEmail" >
            </div>
            <span class="text-danger" style="font-size: 10px;" v-if="!emailUnique">
                Correo ya usado</span>
        </div>
        <div class="row mx-0 px-0">
            <div class="col-md-12 mb-2 px-1" v-if="num == 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="data_detail.state == 1 ? true : false" @change="mtdChangeState" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">{{ data_detail.state == 1 ? "Activo" : "Inactivo" }}</label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-proveedores",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                id:'',
                name: '',
                dni: '',
                email: '',
                rol_id: '',
                state: 1
            },
            emailUnique: true,
            dniUnique: true,

        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    if (this.num == 1) {
                        this.data_detail = {
                            ...newVal
                        };
                    }

                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataRole: {
            type: Array,
            default: null
        },
        data: Array
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name.length > 0 && this.data_detail.dni.length > 0 && this.emailUnique && this.dniUnique);
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
            this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdClose() {
            this.data_detail = {
                name: '',
                dni: '',
                rol_id: '',
            }
            this.emailUnique =  true,
            this.dniUnique =  true,
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.data_detail = {
                name: '',
                dni: '',
                email: '',
                rol_id: ''
            }
        },
        mtdUniqueEmail: function () {
            let dataFilter = this.data.filter(i => i.id !== this.data_detail.id);
            let data = dataFilter.filter(item => item.email === this.data_detail.email);
            this.emailUnique = data.length == 0;
        },
        mtdUniqueDni: function () {
            let dataFilter = this.data.filter(i => i.id !== this.data_detail.id);
            let data = dataFilter.filter(item => item.dni === this.data_detail.dni);
            this.dniUnique = data.length == 0;
        },
        mtdSelectRol(event) {
            this.data_detail.rol_id = event.target.value;
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },

    },

};
</script>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'lg'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 ">
          <div id="table-detalle" >
            <div class="modal-body">
              <div class="modal-body mr-2 ml-2">
                <div class="row">

                    <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                        <label for="inputtext" class="mb-3 align"><strong>DOCUMENTO</strong></label>
                        <div class="input-group">
                            <input type="text" class="form-control" name="documento" placeholder="Documento" v-model="Patient.document" @keydown="filterKey" maxlength="8"/>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                      <label for="inputtext" class="mb-3 align"><strong>NOMBRES</strong></label>
                      <div class="input-group">
                          <input type="text" class="form-control" name="nombres" placeholder="Nombres" v-model="Patient.name"/>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xl-4 mb-1">
                      <label for="inputtext" class="mb-3 align"><strong>TELEFONO</strong></label>
                      <div class="input-group">
                          <input type="text" class="form-control" name="telefono" placeholder="Telefono" v-model="Patient.phone" @keydown="filterKey" maxlength="9"/>
                      </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-xl-4 mb-3">
                      <label for="inputtext" class="mb-3 mt-3 align"><strong>EMAIL</strong></label>
                      <div class="input-group">
                          <input type="email" class="form-control" name="email" placeholder="Email" v-model="Patient.email" />
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-xl-4">
                      <label for="inputtext" class="mb-3 mt-3 align"><strong>EDAD</strong></label>
                      <div class="input-group">
                          <input type="text" class="form-control" name="edad" placeholder="Edad" v-model="Patient.year" @keydown="filterKey"  maxlength="3"/>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <button @click="mtdClose"  class="btn btn-danger btn-sm">
          <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button @click="mtdNewPatient" class="btn btn-success btn-sm">
          Registrar Paciente
        </button>
        &nbsp;
      </template>
      <SweetAlert :swal="swal" ref="SweetAlert"/>
    </CModal>
</template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";
  import { mapActions } from "vuex";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

  export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
      CModal,
      SweetAlert,
    },
    data() {
      return {
        swal: null,
        Patient: {
          //type_document: "",
          document: "",
          name: "",
          phone: "",
          email: "",
          year: "",
        },
      };
    },
    props: {
      title: String,
      boo_modal: Boolean,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdClose() {
        this.Patient = {
          document: "",
          name: "",
          phone: "",
          email: "",
          year: "",
        },
        this.$emit("closeModalAddPatient");
      },

      mtdNewPatient() {
        if (this.Patient.document && this.Patient.name && this.Patient.phone && this.Patient.email && this.Patient.year) {
          this.post({
                      url: this.$store.getters.get__url + "/Call/addPatients",
                      token: this.$store.getters.get__token,
                      params: this.Patient
                  })
                  .then((response) => {
                    if(response.state == 1){
                      this.$refs.SweetAlert.showWarning("Documento o Email ya Registrado");
                    }else{
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.Patient = {
                        document: "",
                        name: "",
                        phone: "",
                        email: "",
                        year: ""
                    };
                    this.$emit("AddPatient");
                    this.$emit("closeModalAddPatient");
                  }
                  })
                  .catch(error => {
                      console.log(error);
                  })
        } else {
          this.$refs.SweetAlert.showWarning("LLene todos los Campos");
        }
      },

      filterKey: function (e) {
        let b = false;
        b = /^[0-9]+$/.test(e.key);
        if (b == false && e.key != "Backspace") {
          e.preventDefault();
        }
      },
    },
   
  };
  </script>
  

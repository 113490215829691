<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-2">
        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <table id="tb-anular" class="table table-bordered">
                <caption class="mx-0 px-0"></caption>
                <thead class="bg-main" style="font-size: 12px">
                    <tr class="text-white">
                        <th scope="col" class="align-middle">N°</th>
                        <th scope="col" class="align-middle">LENTE</th>
                        <th scope="col" class="align-middle">MARCA</th>
                        <th scope="col" class="align-middle">MODELO</th>
                        <th scope="col" class="align-middle">SERIE</th>
                        <th scope="col" class="align-middle">CANTIDAD</th>
                        <th scope="col" class="align-middle">PRECIO UNITARIO S/IGV</th>
                        <th scope="col" class="align-middle">CANTIDAD SALIENTE</th>
                        <th scope="col" class="align-middle">TOTAL S/IGV</th>
                    </tr>
                </thead>
                <tbody class="border-main" style="font-size: 11px">
                    <tr class="tbody-table" v-for="(item, index) in productos" :key="index">
                        <td class="align-middle">
                            {{ index + 1 }}
                        </td>
                        <td class="align-middle">
                            {{ item.productodetail.molecule }}
                        </td>
                        <td class="align-middle">
                            {{ item.productodetail.of_brand.name }}
                        </td>
                        <td class="align-middle">
                            {{ item.productodetail.modelo }}
                        </td>
                        <td class="align-middle">
                            {{ item.productodetail.serie }}
                        </td>
                        <td class="align-middle">
                            {{ item.quantity }}
                        </td>
                        <td class="align-middle">
                            {{ item.price_unit }}
                        </td>
                        <td class="align-middle">
                            {{ item.quantity_outgoing }}
                        </td>
                        <td class="align-middle">
                            {{ item.price }}
                        </td>
                    </tr>
                    <tr v-if="!productos.length > 0" class="text-center">
                        <th scope="col" colspan="9">NO HAY CONSIGNACIONES REGISTRADOS</th>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <!-- &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdInsertPay" :disabled="mtdDisableButton">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp; -->
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-logistica-modals-cModalViewConsignaciones.vue",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        productos: {
            type: Array,
            default: () => []
        }
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {

            this.$emit('mtdcloseModal')
        },

    },
};
</script>

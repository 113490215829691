<template>
  <div id="div_cola">

    <div class="logo-flotante">
        <img src="../assets/bm_sin_fondo.png" alt="Logo" class="d-none d-sm-block" />
    </div>

    <div class="c-app flex-row align-items-center">
      <div class="container" id="contenedor">

        <div id="btn_home" class="c-app align-items-center">
          <div class="d-grid gap-2 mx-auto text-center col-md-12">

            <div class="container principal">
                <div class="row">
                    <div class="col-lg-12 text-center">
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mb-5" v-for="(columnHeader, columnIndex) in Object.keys(data)" :key="columnIndex">
                                <div class="card-section border rounded p-3">
                                    <div class="card-header-first rounded pb-2">
                                        <h3 v-if="columnHeader=='campo_visual'" class="card-header-title text-white py-2">CAMPO VISUAL</h3>
                                        <h3 v-if="columnHeader=='oct'" class="card-header-title text-white py-2">OCT</h3>
                                        <h3 v-if="columnHeader=='oct_macula'" class="card-header-title text-white py-2">OCT MACULA</h3>
                                        <h3 v-if="columnHeader=='paquimetria'" class="card-header-title text-white py-2">PAQUIMETRÍA</h3>
                                        <h3 v-if="columnHeader=='microscopia'" class="card-header-title text-white py-2">MICROSCOPÍA ESPEC.</h3>
                                        <h3 v-if="columnHeader=='topografia'" class="card-header-title text-white py-2">TOPOGRAFÍA</h3>
                                        <h3 v-if="columnHeader=='ecografia'" class="card-header-title text-white py-2">ECOGRAFÍA OCULAR</h3>
                                        <h3 v-if="columnHeader=='biometria'" class="card-header-title text-white py-2">BIOMETRÍA</h3>
                                        <h3 v-if="columnHeader=='angiografia'" class="card-header-title text-white py-2">ANGIOGRAFÍA</h3>
                                    </div>
                                    <div v-for="(header, headerIndex) in Object.keys(data)" :key="headerIndex">
                                        <draggable class="draggable pt-3" v-if="columnHeader==header" :list="data[header]" :group="{ name: 'cells', put: true }">
                                            <div v-for="(item, index) in data[header]" :key="index" :class="{'highlighted': index === 0 }" class="card-body pt-2 text-center">
                                                <span class="drag-handle card-text">
                                                    <b v-if="item.status!==3"> <i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                                </span>
                                            </div>
                                        </draggable>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapActions } from 'vuex'

export default {
  name: 'Cola2View',
  props: {},
    components: {
      draggable
    },
    data() {
      return {
        data: {},
        campoLength:0,
        octLength:0,
        paquimetriaLength:0,
        topografiaLength:0,
        microscopiaLength:0,
        biometriaLength:0,
        statusNew: null,
      };
    },
  created () {
    this.url = this.$store.getters.get__url
    this.mtdGetData();
  },
  methods: {
    ...mapActions(['get', 'post', "post_sin_bus"]),
    mtdGetData: function () {
      this.post_sin_bus({
          url: this.url + '/patientScreen',
          params: {
            campu_id: 1, // Fijo campus
            type: 'imagen', // Segundo piso imagenes

          }
      })
      .then((response) => {
        this.data = {
            campo_visual: response.data.campo,
            oct: response.data.oct,
            oct_macula: response.data.oct_macula,
            paquimetria: response.data.paquimetria,
            microscopia: response.data.microscopia,
            topografia: response.data.topografia,
            ecografia: response.data.ecografia,
            biometria: response.data.biometria,
            angiografia: response.data.angiografia,
        };
        this.campoLength = response.data.campo.length;
        this.octLength = response.data.oct.length;
        this.paquimetriaLength = response.data.paquimetria.length;
        this.topografiaLength = response.data.topografia.length;
        this.microscopiaLength=response.data.microscopia.length;
        this.biometriaLength=response.data.biometria.length;

      })
      .catch((errors) => {
          console.log(errors);
      });

      setTimeout(() => {
        this.refreshDataHome();
      }, 30000); // 30000 milisegundos = 30 segundos
    },
    refreshDataHome: function () {
        this.data = {};
        this.mtdGetData();
    },
   
  }
}
</script>

<style>

.logo-flotante {
    position: fixed;
    top: 20px; /* Ajusta según la distancia desde la parte superior que deseas */
    left: 20px; /* Ajusta según la distancia desde la derecha que deseas */
    z-index: 999; /* Ajusta según sea necesario */
}

.logo-flotante img {
    width: 200px; /* Ajusta el ancho del logo según sea necesario */
    height: auto; /* Para mantener la proporción */
}

.principal{
	margin-top:50px;
	font-family: 'Roboto';
	/* background: linear-gradient(-20deg, #6b003d, #900052); */ 
}

.card-header-first{
	margin-top:-40px;
    height: 90px;
	background: linear-gradient(-40deg, #6b003d, #900052); 
	box-shadow:1px 5px 15px #a2a2a2;
}

/*efecto shadows para todas las tarjetas*/
.card-section{
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
/*Para el efecto sombra al pasar por encima el mouse*/
.card-section:hover{
	box-shadow:1px 1px 20px #900052;
}
</style>
<template>
<div class="container ">

    <div class="row justify-content-md-center mt-3">
        <div class="col-lg-4 mb-3">
            <section class="card">
                <header class="card-header bg-main text-white">
                    <h6 class="card-title">Datos del paciente</h6>
                </header>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label label-modal">Paciente</label>
                                <input class="form-control" v-model="patient.name" placeholder="Gloria Noelia Loro" disabled>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label label-modal">Medico tratante</label>
                                <input class="form-control" v-model="patient.doctor_name" placeholder="Admin null" disabled>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label label-modal">Indicación</label>
                                <select v-model="dataSend.jsonData.patient.indicacion.value" class="form-control" name="indicacion">
                                    <option value="" selected disabled>seleccione...</option>
                                    <option value="AMBOS OJOS">AMBOS OJOS</option>
                                    <option value="OD">OD</option>
                                    <option value="OI">OI</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label label-modal">Examen</label>
                                <input class="form-control" type="file" @change="handleFileUpload" name="file" id="file" ref="file" />
                            </div>
                        </div>
                        <div class=" row mx-0 px-0 mt-2  col-lg-12">
                            <div class="col-xl-4" v-for="(file, index) in allFiles" :key="index">
                                <div @click=" mtddelete(index,'removeFile')">

                                    <template v-if="isImage(file.name)">
                                        <img :src="getFilePreview(file)" style="max-width: 70px; max-height: 70px;" />
                                    </template>
                                    <template v-else-if="isPDF(file.name)">
                                        <i class="far fa-file-pdf" style="color: red; font-size: 60px;"></i>
                                    </template>
                                    <template v-else>
                                        <i class="far fa-file"></i>
                                    </template>
                                    <div class="text-xs"> {{ file.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-lg-4 mb-3">
            <section class="card">
                <header class="card-header bg-main text-white">
                    <h6 class="card-title">Detalle</h6>
                </header>
                <div class="card-body">
                    <table class="table table-striped mb-0">
                        <tbody>
                            <tr>
                                <td class="text-center" colspan="4">
                                    <b>GROSOR DE LA CORNEA</b>
                                </td>
                            </tr>
                            <tr>
                                <td>OD</td>
                                <td>
                                    <input type="text" name="g_od" v-model="dataSend.jsonData.details.cornea.od" class="form-control" />
                                </td>
                                <td>OI</td>
                                <td>
                                    <input type="text" name="g_od" v-model="dataSend.jsonData.details.cornea.oi" class="form-control" />
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center" colspan="4">
                                    <b>QUERATOMETRIAS ANTERIOR</b>
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center" colspan="4">
                                    K1
                                </td>
                            </tr>
                            <tr>
                                <td>OD</td>
                                <td>
                                    <input type="text" name="g_od" v-model="dataSend.jsonData.details.queratometrias.k1.od" class="form-control form-control-xss" />
                                </td>
                                <td>OI</td>
                                <td>
                                    <input type="text" name="g_od" v-model="dataSend.jsonData.details.queratometrias.k1.oi" class="form-control form-control-xss" />
                                </td>
                            </tr>
                            <tr>
                                <td class="text-center" colspan="4">
                                    K2
                                </td>
                            </tr>
                            <tr>
                                <td>OD</td>
                                <td>
                                    <input type="text" name="g_od" v-model="dataSend.jsonData.details.queratometrias.k2.od" class="form-control form-control-xss" />
                                </td>
                                <td>OI</td>
                                <td>
                                    <input type="text" name="g_od" v-model="dataSend.jsonData.details.queratometrias.k2.oi" class="form-control form-control-xss" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
        <div class="col-lg-4">
            <section class="card">
                <header class="card-header bg-main text-white d-flex justify-content-between">
                    <h6 class="card-title">Conclusiones</h6>
                </header>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th>Numero</th>
                                    <th>Conclusion</th>
                                    <th>Ojo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        No se puede registrar conclusiones
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-lg-5 btn-home mt-3">
            <button class="btn btn-block w-100" :disabled="patient.id !== '527'" @click="mtdaddData">
                Guardar
            </button>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @removeFile="removeFile" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-enfermera-topografia_dx',
    components: {
        SweetAlert
    },
    data() {
        return {
            page: 'topografia_dx',
            swal: null,
            allFiles: [],
            dataSend: {
                option: 'topography',
                reservation_id: this.patient.reservation_id,
                jsonData: {
                    userRegister: this.$store.getters.get__user.name,
                    dateCreated: new Date().toLocaleDateString(),
                    patient: {
                        patient: {
                            patient_id: this.patient.patient_id,
                        },
                        doctor: {
                            doctor_id: this.patient.doctor_id,
                        },
                        indicacion: {
                            name: 'indicacion',
                            value: "",
                        },
                        examen: {
                            name: 'examen',
                            value: []
                        }
                    },
                    details: {
                        cornea: {
                            name: 'GROSOR DE CORNEA',
                            od: '',
                            oi: ''
                        },
                        queratometrias: {
                            name: "QUERATOMETRIAS ANTERIOR",
                            k1: {
                                od: '',
                                oi: ''
                            },
                            k2: {
                                od: '',
                                oi: ''
                            }
                        },
                    },
                    conclusiones: {
                        name: 'conclusiones',
                        value: []
                    }
                }

            },
        }
    },
    props: {
        stateRolAdmin: Boolean,
        patient: Object
    },
    created() {

    },
    methods: {
        ...mapActions(['get', 'post']),
        returnHome() {
            this.$emit('returnHome');
        },
        returnReadingResult: function () {
            this.$emit("returnReadingResult", this.patient);
        },
        mtdaddData: function () {
            // let formData = new FormData();
            // formData.append('data', JSON.stringify(this.data));
            // formData.append('file', this.allFiles);
            this.post({
                    url: this.$store.getters.get__url + "/Enfermera/store",
                    token: this.$store.getters.get__token,
                    params: this.dataSend,
                })
                .then((response) => {
                    if (!response.error) {

                        if (this.allFiles.length > 0) {
                            this.mtdStoreFile(response.data);
                        } else {
                            this.$refs.SweetAlert.showSuccess(response.message);
                            response.data == 0 ? this.returnHome() : this.returnReadingResult();
                        }
                    } else {
                        this.$refs.SweetAlert.showWarning(response.data.error_message);
                    }
                    //this.oct = response.data;
                })
                .catch((errors) => {

                    console.log(errors);
                });

        },
        mtdStoreFile: function (resp) {
            let formData = new FormData();
            formData.append("length", this.allFiles.length);
            formData.append("option", this.dataSend.option);
            formData.append("reservation_id", this.dataSend.reservation_id);
            this.allFiles.forEach((element, index) => {
                let temp = [];
                temp.push(element);
                formData.append("file_" + index, this.mtdArrayToFileList(temp)[0]);
            });
            this.post({
                    url: this.$store.getters.get__url + "/Enfermera/storeFiles",
                    token: this.$store.getters.get__token,
                    params: formData,
                })
                .then((response) => {
                    if (!response.error) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.allFiles = [];
                        resp == 0 ? this.returnHome() : this.returnReadingResult();
                    } else {
                        this.$refs.SweetAlert.showWarning(response.data.error_message);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdArrayToFileList: function (arr) {
            const fileList = new DataTransfer();
            arr.forEach((file) => fileList.items.add(file));
            return fileList.files;
        },
        handleFileUpload(event) {
            const files = event.target.files;
            const allowedFormats = ['pdf', 'png', 'jpg', 'jpeg'];
            const invalidFiles = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileExtension = file.name.split('.').pop().toLowerCase();
                const fileAlreadyExists = this.allFiles.some(existingFile => existingFile.name === file.name);
                if (fileAlreadyExists) {
                    continue;
                }
                if (allowedFormats.includes(fileExtension)) {
                    this.allFiles.push(file);
                } else {
                    invalidFiles.push(file.name);
                }
            }
            if (invalidFiles.length > 0) {
                let message;
                if (invalidFiles.length === 1) {
                    message = `El archivo ${invalidFiles[0]} tiene un formato no permitido.`;
                } else {
                    message = `Los siguientes archivos tienen formatos no permitidos:\n\n${invalidFiles.join('\n')}`;
                }
                this.$refs.SweetAlert.showWarning(message);

                // Limpiar el input
                // event.target.value = '';
            }
        },
        isImage(fileName) {
            const ext = fileName.split('.').pop().toLowerCase();
            return ext === 'png' || ext === 'jpg' || ext === 'jpeg';
        },
        isPDF(fileName) {
            const ext = fileName.split('.').pop().toLowerCase();
            return ext === 'pdf';
        },
        getFilePreview(file) {
            return URL.createObjectURL(file);
        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);
        },
        removeFile(indexToRemove) {
            this.allFiles.splice(indexToRemove, 1);

        }
    }
}
</script>

<template>
<div class="p-4">
    <div class=" w-100  d-flex justify-content-end   ">
        <div class="btn-home">
            <button @click="mtdCloseBead(0)" class="btn btn-outline-main w-100 ">
                <i class="fas fa-window-close"></i>&nbsp;{{bead.authorize==0?' Solicitar Aprobación':
                (bead.authorize==1?"Pendiente de Aprobacion":(bead.authorize==2?"Cerrar Caja":"Corregir Cierre"))}} 
            </button> 
        </div>
        &nbsp;
        <div class="btn-home">
            <button type="button" class="btn btn-outline-main w-100" @click="mtdRetiros">
                <i class="fas fa-cash-register"></i> Retiros/Depósitos
            </button>
        </div>
        &nbsp;
        <div class="btn-home" v-if="bead.of_box.name!='CAJA CHICA'">
            <button type="button" class="btn btn-outline-main w-100  "  @click="mtdReportBead('INGRESO')" data-bs-toggle="modal"
            data-bs-target="#new">
                <i class="fas fa-file-excel"></i> Reporte Ingresos
            </button> 
        </div>
        &nbsp;
        <div class="btn-home px-xl-1 px-0 py-1 py-xl-0" v-if="bead.of_box.name =='CAJA CHICA'">
            <button type="button" class="btn btn-outline-main  w-100  " @click="mtdReportBead ('EGRESO')" data-bs-toggle="modal"
            data-bs-target="#new">
                <i class="fas fa-file-excel"></i> Reporte Egresos
            </button>
        </div>
        <div class="btn-home">
            <button type="button" class="btn btn-outline-main  w-100  " @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>

        </div>

    </div>
    <div class="row d-flex mt-3">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12" v-if="bead.of_box.name!='CAJA CHICA'">
            <div id="div-modal-caja-filtro" class="d-flex">
                <div id="div-caja-filtro" class="card w-100">
                    <div class="card-header text-white h4 text-center bg-main">
                        <strong>REPORTE DE CIERRE</strong>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="mb-3">
                                    <label class="form-label">Servicio</label>
                                    <select class="form-control form-control-sm" v-model="filter.categorie">
                                        <option value="0" selected>[Todas las Servicios..]</option>
                                        <option
                                            v-for="(item, index) in categories"
                                            :key="index"
                                            :value="item.id"
                                        >
                                            {{ item.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!--
                            <div class="col-md-6">
                                <div class="mb-3">
                                    <label class="form-label">Sub categorias</label>
                                    <select class="form-control form-control-sm">
                                        <option value="0">[Todas las subcategorias..]</option>
                                        <option>
                                            CONSULTAS
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-md-12 row mx-0">
                                <div class="form-check  col-xl-12">
                                    <input class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">EPS</label>
                                </div>
                                <div class="form-check  col-xl-12">
                                    <input class="form-check-input" type="checkbox" />
                                    <label class="form-check-label">PARTICULAR</label>
                                </div>
                            </div>
                            <hr />
                            -->
                            <div class="col-md-12">
                                <div id="div-tabla-caja" class="position-relative table-responsive">
                                    <table class="table text-xs table-bordered table-responsive" aria-describedby="mydesc">
                                        <caption class="py-0 my-0"></caption>
                                        <thead>
                                            <tr>
                                                <th scope="col" rowspan="2">Servicio</th>
                                                <th scope="col" rowspan="2">Servicio especifico</th>
                                                <th scope="col" class="text-center" colspan="3">Medios de pagos</th>
                                            </tr>
                                            <tr>
                                                <th scope="col">Efectivo</th>
                                                <th scope="col">Otros</th>
                                                <th scope="col">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody v-html="cpTableBody"></tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col-lg-12 col-md-12 col-sm-12 col-xs-12" :class="bead.of_box.name!='CAJA CHICA'?'col-xl-6':'col-xl-12'">
            <div id="div-modal-caja" class="d-flex justify-content-center">
                <div id="div-caja" class="card w-100">
                    <div class="card-header text-white h4 text-center bg-main">
                        <strong>ARQUEO DE CAJA</strong>
                    </div>
                    <div class="card-body">
                        <div id=" row div-dataMoney" class="container">
                            <div class="row">
                                <div class="col-md-12 col-xl-6 col-lg-12 col-sm-12 col-xs-12 mt-0 text-center">
                                    <label class="form-label text-center"><strong>Billetes</strong></label>
                                    <div v-for="(item, index) in process.billetes" :key="index" class="">
                                        <div class="input-group input-group-sm mb-2">
                                            <span class="input-group-text col-4">{{ item.label }}</span>
                                            <input :disabled="bead.authorize==2"
                                            type="text"
                                            class="form-control form-control-sm"
                                            @keyup="cpContarBilletes"
                                            @keydown="filterKey"
                                            aria-label="Amount (to the nearest dollar)"
                                            placeholder="0"
                                            v-model="item.cantidad"
                                            width="10px"
                                            />
                                            <span v-if="item.total == 0" class="input-group-text col-4"
                                            >= S/. 0.00</span
                                            >
                                            <span v-else class="input-group-text col-4"
                                            >= S/{{ item.total }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-xl-6 col-lg-12 col-sm-12 col-xs-12 text-center">
                                    <label class="form-label text-center"><strong>Monedas</strong></label>
                                    <div v-for="(item, index) in process.monedas" :key="index" class="">
                                        <div class="input-group input-group-sm mb-2">
                                            <span class="input-group-text col-4">{{ item.label }}</span>
                                            <input :disabled="bead.authorize==2"
                                            type="text"
                                            class="form-control form-control-sm"
                                            @keyup="cpContarBilletes"
                                            @keydown="filterKey"
                                            aria-label="Amount (to the nearest dollar)"
                                            placeholder="0"
                                            v-model="item.cantidad"
                                            />
                                            <span v-if="item.total == 0" class="input-group-text col-4"
                                            >= S/. 0.00</span
                                            >
                                            <span v-else class="input-group-text col-4"
                                            >= S/ {{ item.total }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-flex text-center mt-3">
                                <div class="col-md-4 col-lg-4 col-xl-4 col-sm-12 text-center btn-home" :class="bead.of_box.name=='CAJA CHICA'?'col-xl-6':'col-xl-4'" >
                                    <strong> TOTAL EFECTIVO </strong>
                                    <button class="btn btn-sm w-100 mt-1">
                                        <strong>APERTURA: S/. {{ bead.initial }}</strong>
                                    </button>
                                    <button class="btn btn-sm w-100 mt-1" v-if="bead.of_box.name!='CAJA CHICA'"  @click="mtdShowModal('Ingresos')">
                                        <strong>INGRESOS: S/. {{ cpTotalEfectivo }}</strong>
                                    </button>

                                    <button v-if="bead.of_box.name=='CAJA CHICA'" class="btn btn-sm w-100 mt-1"  @click="mtdShowModal('Egresos')">
                                        <strong>EGRESOS: S/. {{ cpTotalExpense }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1" v-if="bead.of_box.name!='CAJA CHICA'">
                                        <strong>ANULACION: S/. {{ cpTotalAnulacion }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1">
                                        <strong>TOTAL:S/. {{ cpEfectitoTotal }}</strong>
                                    </button>
                                </div>
                                <div class="col-md-4 col-lg-4 col-xl-4 col-sm-12 text-center" v-if="bead.of_box.name!='CAJA CHICA'">
                                    <strong> OTROS INGRESOS </strong>
                                    <!--
                                    <div class="btn-home">
                                        <button class="btn btn-sm w-100 mt-1" @click="mtdShowModal">
                                            <strong> Tranferencia: S/.0.00</strong>
                                        </button>
                                        <button class="btn btn-sm w-100 mt-1" @click="mtdShowModal">
                                            <strong> Tarjeta: S/.0.00</strong>
                                        </button>
                                        <button class="btn btn-sm w-100 mt-1" @click="mtdShowModal">
                                            <strong> Deposito: S/.0.00</strong>
                                        </button>
                                        <button class="btn btn-sm w-100 mt-1" @click="mtdShowModal">
                                            <strong> Planilla: S/.0.00</strong>
                                        </button>
                                    </div>
                                    -->
                                    <div v-for="(item, index) in process.otroIngresos" :key="index">
                                        <button
                                            class="btn btn-sm w-100 mt-1"
                                            style="background: #900052; color: white"
                                            @click="mtdShowModal(item.label)"
                                        >
                                            <strong
                                            >{{ item.label }}: S/.
                                            {{
                                                item.label == "Transferencia"
                                                ? cpTotalTransferencia
                                                : item.label == "Tarjeta"
                                                ? cpTotalTarjeta
                                                : item.label == "Deposito"
                                                ? cpTotalDeposito
                                                : item.label == "Planilla"
                                                ? cpTotalPlanilla
                                                : item.total
                                            }}
                                            </strong>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4 col-xl-4 col-sm-12 text-center btn-home" :class="bead.of_box.name=='CAJA CHICA'?'col-xl-6':'col-xl-4'">
                                    <strong> SALDOS </strong>
                                    <button class="btn btn-sm w-100 mt-1">
                                        <strong>Saldo efectivo S/ {{ cpEfectitoTotal }}</strong>
                                    </button>

                                    <button class="btn btn-sm w-100 mt-1" v-if="bead.of_box.name!='CAJA CHICA'">
                                        <strong>Saldo General S/ {{ cpSaldoGeneral }}</strong>
                                    </button>
                                    <button class="btn btn-sm w-100 mt-1">
                                        <strong>Vueltos S/ {{ cpTotalTurned }}</strong>
                                    </button>
                                    <button
                                        class="btn btn-sm w-100 mt-1"
                                        style="background: #900052; color: white"
                                        >
                                        <strong v-if="cpSobranteFaltante < 0">
                                            Faltante S/{{ cpSobranteFaltante }}
                                        </strong>
                                        <strong v-else> Sobrante S/{{ cpSobranteFaltante }}</strong>
                                    </button>
                                </div>
                            </div>
                            <div class="row btn-home d-flex justify-content-between mt-2 mb-2">
                                <div class="col-md-3 col-lg-3 col-xl-3 "></div>
                                <div class="col-md-3 col-lg-3 col-xl-3 col-sm-12">
                                    <button class="btn btn-sm w-100 mt-1">
                                        <strong>Depósitos: {{ parseFloat(process.deposit).toFixed(2) }}</strong>
                                    </button>
                                </div>
                                <div class="col-md-3 col-lg-3 col-xl-3 col-sm-12">
                                    <button class="btn btn-sm w-100 mt-1">
                                        <strong>Retiros: {{ parseFloat(process.retirement).toFixed(2) }}</strong>
                                    </button>
                                </div>
                                <div class="col-md-3 col-lg-3 col-xl-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!--Modal Form-->
    <div
        class="modal fade"
        id="new"
        ref="modalForm"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false">
        <div class="modal-dialog modal-fullscreen" role="document">
            <div class="modal-content col-sm-11 p-0">
            <div class="modal-header mod-h p-3 bg-main">
                <h4 class="modal-title text-white mx-4 mt-2" id="exampleModalLabel">
                <i class="far fa-money-bill-alt"></i>&nbsp; Reporte de Caja -
                {{ this.type }}
                </h4>
                <div
                @click="mtdHideModal"
                style="border: none"
                class="close text-white mx-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                >
                &times;
                </div>
            </div>
            <div class="modal-body">
                <div class="modal-body mr-2 mb-2 ml-2">
                <div class="row w-100">
                    <div class="col-xl-12 col-md-12 col-lg-12 col-xs-12 mb-3">
                    <div class="row mt-2">
                        <div class="col-md-12 col-lg-12  col-xl-2 col-sm-12 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Sede</span>
                            <select
                            id="campu_id"
                            class="form-control form-control"
                            v-model="filterModal.campu_id"
                            @change="mtdSelectCampusToBox"
                            >
                            <option selected value="0" disabled>Seleccione ...</option>
                            <option
                                v-for="(item, index) in dataCampus"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                            </select>
                        </div>
                        </div>

                        <div class="col-md-12 col-lg-12  col-xl-2 col-sm-12 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Caja</span>
                            <select
                            id="bead_id"
                            class="form-control form-control"
                            v-model="filterModal.bead_id"
                            >
                            <option selected value="0" disabled>Seleccione ...</option>
                            <option
                                v-for="(item, index) in dataBeadsList"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                            </select>
                        </div>
                        </div>

                        <div class="col-md-12 col-lg-12 col-xl-2 col-sm-12 col-xs-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Desde</span>
                            <input
                            type="date"
                            aria-label="shift_date"
                            class="form-control form-control-sm"
                            id="begindate"
                            v-model="filterModal.begindate"
                            />
                        </div>
                        </div>

                        <div class="col-md-12 col-lg-12 col-xl-2 col-sm-12 col-xs-12 mb-3">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Hasta</span>
                            <input
                            type="date"
                            aria-label="shift_date"
                            class="form-control form-control-sm"
                            id="enddate"
                            v-model="filterModal.enddate"
                            />
                        </div>
                        </div>

                        <div class="col-md-12 col-lg-12 col-xl-2 col-sm-12 col-xs-12">
                        <button
                            type="button"
                            name="accion"
                            class="btn btn-sm mb-3 w-100 btn-register text-white bg-main"
                            @click="mtdDataReport"
                        >
                            <i class="fas fa-search"></i> Buscar
                        </button>
                        </div>
                        <div class="col-md-12 col-lg-12 col-xl-2 col-sm-12 col-xs-12">
                        <button
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            type="button"
                            name="accion"
                            class="btn btn-sm mb-3 w-100 btn-register text-white bg-main"
                            @click="exportExcel"
                        >
                            <i class="fas fa-file-excel"></i> Exportar
                        </button>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="type == 'INGRESO'">
                        <div class="col-md-12">
                        <div class="mb-3">
                            <label class="form-label">Categoría</label>
                            <select
                            class="form-control form-control-sm"
                            v-model="filterModal.categorie"
                            >
                            <option value="0" selected>[Todas las categorias..]</option>
                            <option
                                v-for="(item, index) in categoriesModal"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.label }}
                            </option>
                            </select>
                        </div>
                        </div>
                        <!--
                        <div class="col-md-4">
                            <label>Tipo de paciente</label>
                            <br />
                            <div class="form-check form-check-inline mt-2">
                                <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="filterModal.eps"
                                />
                                <label class="form-check-label">EPS</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                class="form-check-input"
                                type="checkbox"
                                v-model="filterModal.particular"
                                />
                                <label class="form-check-label">PARTICULAR</label>
                            </div>
                        </div>
                        -->
                    </div>
                    </div>

                    <div
                    v-if="this.type == 'INGRESO'"
                    class="col-md-12"
                    id="div-tabla-ingreso"
                    >
                    <table class="table table-bordered" aria-describedby="mydesc">
                        <thead>
                        <tr
                            class="text-white border-white text-center"
                            style="background: #900052"
                        >
                            <th scope="col">N°</th>
                            <th scope="col">ESTADO</th>
                            <th scope="col">ADM</th>
                            <th scope="col">SEDE</th>
                            <th scope="col">FECHA</th>
                            <th scope="col">COMPROBANTE</th>
                            <th scope="col">A NOMBRE DE</th>
                            <th scope="col">SERIE</th>
                            <th scope="col">NÚMERO</th>
                            <th scope="col">CONVENIO</th>
                            <th scope="col">%</th>
                            <th scope="col">INFORMACIÓN DEL PACIENTE</th>
                            <th scope="col">CATEGORÍA</th>
                            <th scope="col">SERVICIO ESPECÍFICO</th>
                            <th scope="col">MONTO</th>
                            <th scope="col">MEDIO DE PAGO</th>
                        </tr>
                        </thead>
                        <tbody
                        style="background: #f1f2f3; vertical-align: middle;"
                        v-html="cpTableBodyModal"
                        ></tbody>
                    </table>
                    </div>

                    <div
                    v-if="this.type == 'EGRESO'"
                    class="col-md-12"
                    id="div-tabla-egreso"
                    >
                    <table id="tb-egreso" class="table table-bordered border-main" aria-describedby="tb-egreso">
                        <thead>
                        <tr
                            class="text-white border-white text-center"
                            style="background: #900052"
                        >
                            <th style="vertical-align: middle" scope="col">N°</th>
                            <th style="vertical-align: middle" scope="col">ESTADO</th>
                            <th style="vertical-align: middle" scope="col">ADM</th>
                            <th style="vertical-align: middle" scope="col">SEDE</th>
                            <th style="vertical-align: middle" scope="col">FECHA EGRESO</th>
                            <th style="vertical-align: middle" scope="col">
                            FECHA DE COMPROBANTE
                            </th>
                            <th style="vertical-align: middle" scope="col">
                            TIPO COMPROBANTE
                            </th>
                            <th style="vertical-align: middle" scope="col">SERIE</th>
                            <th style="vertical-align: middle" scope="col">NÚMERO</th>
                            <th style="vertical-align: middle" scope="col">RAZÓN SOCIAL</th>
                            <th style="vertical-align: middle" scope="col">RUC / DNI</th>
                            <th style="vertical-align: middle" scope="col">TOTAL</th>
                            <th style="vertical-align: middle" scope="col">MONTO PAGADO</th>
                            <th style="vertical-align: middle" scope="col">PENDIENTE</th>
                            <th style="vertical-align: middle" scope="col">
                            MEDIO DE PAGO
                            </th>
                            <th style="vertical-align: middle" scope="col">
                            MOTIVO, DESCRIPCIÓN O DETALLE DE COMPRA
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item2, index) in dataReport" :key="index">
                            <th scope="row">{{ index + 1 }}</th>
                            <td>
                            {{
                                item2.state == 1
                                ? "PENDIENTE DE APROBACIÓN"
                                : item2.state == 2
                                ? "APROBADO"
                                : "RECHAZADO"
                            }}
                            </td>
                            <td>
                            {{ item2.of_bead.user.name }}
                            </td>
                            <td>{{ item2.campu.name }}</td>
                            <td>{{ item2.date | formatDate }}</td>
                            <td>
                            {{
                                item2.date_doc == null
                                ? item2.date
                                : item2.date_doc | formatDate
                            }}
                            </td>
                            <td>
                            {{
                                item2.document_type_id != null
                                ? item2.document_type.name
                                : item2.modulo == 'receipt'?"RECIBO POR HONORARIOS":"DOC. INTERNO",
                            }}
                            </td>
                            <td>{{ item2.serie == null ? "-" : item2.serie }}</td>
                            <td>
                            {{ item2.number == null ? "-" : item2.number }}
                            </td>
                            <td>
                            {{
                                item2.modulo == "passage"
                                ? item2.passage.name
                                : item2.modulo == "retiro"
                                ? item2.of_bead.user.name
                                : item2.modulo == "receipt"
                                ? item2.receipt.name
                                : item2.company
                            }}
                            </td>
                            <td>
                            {{
                                item2.modulo == "passage"
                                ? item2.passage.document
                                : item2.modulo == "retiro"
                                ? item2.of_bead.user.dni
                                : item2.modulo == "receipt"
                                ? item2.receipt.document
                                : item2.document
                            }}
                            </td>
                            <td>{{ item2.total }}</td>
                            <td>{{ item2.amount }}</td>
                            <td>{{ item2.pending }}</td>
                            <td>{{ item2.method }}</td>
                            <td>{{ item2.description }}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="">
                <button
                    id="btn-close"
                    type="button"
                    class="btn btn-secondary w-100 btn-sm me-1 mt-3 mb-3"
                    data-bs-dismiss="modal"
                    @click="mtdHideModal"
                >
                    Cerrar
                </button>
                </div>
            </div>
            </div>
        </div>
    </div>
    <!--Modal form-->
    
    <cModalRetiroDepositoVue 
        :title="modalRetiros.title" 
        :boo_modal="modalRetiros.modal_form"  
        :item="modalRetiros.item" 
        @closeModalRetiros="closeModalRetiros" 
        @mtdSendPermission="mtdSendPermission"
    />
    <cModalDetalleVue 
        :title="modalDetalle.title" 
        :boo_modal="modalDetalle.modal_form" 
        :item="modalDetalle.item" 
        @closeModalDetalle="closeModalDetalle" 
    />
    <cModalReporteVue 
        :title="modalReporte.title" 
        :boo_modal="modalReporte.modal_form" 
        @closeModalReporte="closeModalReporte" 
        :type="type" 
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import exportFromJson from "export-from-json";
import cModalRetiroDepositoVue from './Modals/cModalRetiroDeposito.vue';
import cModalDetalleVue from "./Modals/cModalDetalle.vue";
import cModalReporteVue from "./Modals/cModalReporte.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-oftalmologia-close',

    components: {
        cModalRetiroDepositoVue,
        cModalDetalleVue,
        cModalReporteVue,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            process: {
                billetes: [
                {
                    label: "200.00",
                    total: 0,
                    key: "dosCientos",
                    value: 200,
                    cantidad: "",
                },
                {
                    label: "100.00",
                    total: 0,
                    key: "unCiento",
                    value: 100,
                    cantidad: "",
                },
                {
                    label: "50.00",
                    total: 0,
                    key: "cincuenta",
                    value: 50,
                    cantidad: "",
                },
                {
                    label: "20.00",
                    total: 0,
                    key: "veinte",
                    value: 20,
                    cantidad: "",
                },
                {
                    label: "10.00",
                    total: 0,
                    key: "diez",
                    value: 10,
                    cantidad: "",
                },
                ],
                monedas: [
                {
                    label: "5.00",
                    total: 0,
                    key: "cinco",
                    value: 5,
                    cantidad: "",
                },
                { label: "2.00", total: 0, key: "dos", value: 2, cantidad: "" },
                { label: "1.00", total: 0, key: "uno", value: 1, cantidad: "" },
                {
                    label: "0.50",
                    total: 0,
                    key: "cincuentaCentimos",
                    value: 0.5,
                    cantidad: "",
                },
                {
                    label: "0.20",
                    total: 0,
                    key: "veinteCentimos",
                    value: 0.2,
                    cantidad: "",
                },
                {
                    label: "0.10",
                    total: 0,
                    key: "diezCentimos",
                    value: 0.1,
                    cantidad: "",
                },
                ],
                otroIngresos: [
                {
                    label: "Transferencia",
                    total: 0,
                    value: null,
                },
                {
                    label: "Tarjeta",
                    total: 0,
                    value: null,
                },
                {
                    label: "Deposito",
                    total: 0,
                    value: null,
                },
                {
                    label: "Planilla",
                    total: 0,
                    value: null,
                },
                ],
                observation: "",
                incomes: [],
                expenses: [],
                anulaciones: [],
                pasajes: [],
                totEfectivo: 0,
                totTurned: 0,
                totIncome: 0,
                totExpense: 0,
                totAnulacion: 0,
                totTransferencia: 0,
                totDeposito: 0,
                totTarjeta: 0,
                totPlanilla: 0,
                totalArqueo: 0,
                retirement: 0,
                deposit: 0,
                initial: 0,
            },
            data: [],
            finalCash: 0,
            filter: {
                categorie: 0,
                subcategorie: 0,
                eps: true,
                particular: true,
            },
            categories: [],
            categoriesModal: [],
            subcategories: [],
            subcategoriesModal: [],
            /* fin */
            dataCampus: [],
            dataBeadsList: [],
            type: "",
            dataReport: [],
            dataReportView: [],
            filterModal: {
                campu_id: 0,
                bead_id: 0,
                begindate: null,
                enddate: null,
                eps: true,
                particular: true,
                categorie: 0,
                subcategorie: 0,
            },
            excelIncome: [],
            excelExpense: [],
            modalRetiros: {
                title: '',
                modal_form: false,
                item: [],
            },
            modalDetalle: {
                title: '',
                modal_form: false,
                item: [],
            },
            modalReporte: {
                title: '',
                modal_form: false,
                item: [],
            },
            type:'',
            
        }
    },
    created() {
        this.mtdDataBeads();
    },
    props: {
        bead: {},
    },
    computed: {
        cpTotalEfectivo() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                if (ele.method == "efectivo") {
                    tot += parseFloat(ele.amount);
                }
                });
            });
            this.process.totIncome = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },

        cpTotalExpense() {
            this.process.totExpense = parseFloat(this.bead.expense).toFixed(2);
            return parseFloat(this.process.totExpense).toFixed(2);
        },

        cpTotalAnulacion() {
            this.process.totAnulacion = this.bead.cancellations !== undefined ? parseFloat(this.bead.cancellations).toFixed(2) : '0.00';
            return this.process.totAnulacion;
        },

        cpTotalTurned() {
            this.process.totTurned = parseFloat(this.bead.turned).toFixed(2);
            return parseFloat(this.process.totTurned).toFixed(2);
        },

        cpEfectitoTotal() {
            let tot =
                parseFloat(this.bead.initial) +
                parseFloat(this.process.totIncome) -
                parseFloat(this.process.totExpense) +
                parseFloat(this.process.deposit) -
                parseFloat(this.process.retirement) -
                parseFloat(this.process.totAnulacion);

            this.process.totEfectivo = parseFloat(tot);
            parseFloat(this.process.retirement);

            this.process.totEfectivo = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },

        cpTotalTransferencia() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                if (ele.method == "transferencia") {
                    tot += parseFloat(ele.amount);
                }
                });
            });
            this.process.totTransferencia = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },

        cpTotalTarjeta() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                if (ele.method == "tarjeta") {
                    tot += parseFloat(ele.amount);
                }
                });
            });
            this.process.totTarjeta = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },

        cpTotalDeposito() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                if (ele.method == "deposito") {
                    tot += parseFloat(ele.amount);
                }
                });
            });
            this.process.totDeposito = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },

        cpTotalPlanilla() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                element.charge.forEach((ele) => {
                if (ele.method == "planilla") {
                    tot += parseFloat(ele.amount);
                }
                });
            });
            this.process.totPlanilla = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },

        cpTotalT() {
            let tot = 0;
            this.process.incomes.forEach((element) => {
                if (element.method == "tarjeta") {
                tot += parseFloat(element.amount);
                }
            });
            this.process.totTarjeta = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },

        cpSaldoGeneral() {
            let tot =
                parseFloat(this.process.totTarjeta) +
                parseFloat(this.process.totDeposito) +
                parseFloat(this.process.totTransferencia) +
                parseFloat(this.process.totPlanilla);
            return parseFloat(tot).toFixed(2);
        },

        cpSobranteFaltante() {
            let dif =
                parseFloat(this.finalCash) -
                parseFloat(this.process.totEfectivo) -
                parseFloat(this.process.totTurned);
            return parseFloat(dif).toFixed(2);
        },

        cpSubcategories() {
            let view = [];
            this.subcategories.forEach((element) => {
                if (this.filter.categorie == element.categorie) {
                view.push(element);
                }
            });
            this.filter.subcategorie = 0;
            return view;
        },

        cpSubcategoriesModal() {
            let view = [];
            this.subcategoriesModal.forEach((element) => {
                if (this.filterModal.categorie == element.categorie) {
                view.push(element);
                }
            });
            this.filterModal.subcategorie = 0;
            return view;
        },

        cpTableBody() {
            let html = "";
            let boo = true; // true => si hay filtro
            if (this.filter.categorie == 0) {
                boo = false;
            }
            let temp = [];
            if (this.filter.eps == true && this.filter.particular == true) {
                temp = this.data;
            } else if (this.filter.eps == true && this.filter.particular == false) {
                temp = this.data.filter(function (el) {
                return el.type != "particular";
                });
            } else if (this.filter.eps == false && this.filter.particular == true) {
                temp = this.data.filter(function (el) {
                return el.type == "particular";
                });
            } else {
                temp = [];
            }

            temp.forEach((element, index) => {
                let itemHtml =
                "<tr>" +
                "<td>" +
                element.categorie +
                "</td>" +
                "<td>" +
                element.servicio +
                "</td>" +
                "<td>" +
                parseFloat(element.efectivo).toFixed(2) +
                "</td>" +
                "<td>" +
                parseFloat(element.otros).toFixed(2) +
                "</td>" +
                "<td>" +
                parseFloat(element.total).toFixed(2) +
                "</td>" +
                "</td>" +
                "</td>";
                if (!boo) {
                html += itemHtml;
                } else {
                if (this.filter.subcategorie != 0) {
                    if (
                    element.subcategorie_id != 0 &&
                    element.subcategorie_id == this.filter.subcategorie
                    ) {
                    html += itemHtml;
                    }
                } else {
                    if (
                    element.categorie_id != 0 &&
                    element.categorie_id == this.filter.categorie
                    ) {
                    html += itemHtml;
                    }
                }
                }
            });
            return html;
        },

        cpTableBodyModal() {
            let html = "";
            let boo = true; // true => si hay filtro
            if (this.filterModal.categorie == 0) {
                boo = false;
            }
            let temp = this.dataReport;
            
            if (temp.length == 0) {
                html =
                "<tr><td colspan='16' align='center'>NO HAY REGISTROS PARA MOSTRAR</td><tr>";
            } else {
                temp.forEach((element, index) => {
                    let model = element.of_voucher.of_sale.detail
                        ? element.of_voucher.of_sale.detail[0].of_product.service.name
                        : "-";
    
                    let tdContent = [];
                    for (let i = 0; i < element.of_voucher.of_sale.detail.length; i++) {
                    let ssEspecifico = element.of_voucher.of_sale.detail[i]
                        ? element.of_voucher.of_sale.detail[i].of_product.name
                        : "-";
                    tdContent.push(ssEspecifico);
                    };
                    let insurance =
                        element.of_voucher.of_sale.of_insurance != null
                        ? element.of_voucher.of_sale.of_insurance.name
                        : "-";
                    let itemHtml =
                        "<tr>" +
                        "<td>" +
                        (index + 1) +
                        "</td>" +
                        "<td>" +
                        (element.of_voucher.state == 1 ? "ACTIVO" : "ANULADO") +
                        "</td>" +
                        "<td>" +
                        element.of_voucher.of_bead.user.name +
                        "</td>" +
                        "<td>" +
                        element.of_voucher.of_sale.campu.name +
                        "</td>" +
                        "<td>" +
                        element.of_voucher.date +
                        "</td>" +
                        "<td>" +
                        element.of_voucher.document +
                        "</td>" +
                        "<td>" +
                        element.of_voucher.vaucher_document +
                        "-" +
                        element.of_voucher.vaucher_fullname +
                        "</td>" +
                        "<td align='center'>" +
                        element.of_voucher.serie +
                        "</td>" +
                        "<td align='center'>" +
                        element.of_voucher.number +
                        "</td>" +
                        "<td>" +
                        insurance +
                        "</td>" +
                        "<td>" +
                        element.of_voucher.of_sale.payment_patient +
                        "</td>" +
                        "<td>" +
                        element.of_voucher.of_sale.of_patient.name +
                        "</td>" +
                        "<td>" +
                        model +
                        "</td>" +
                        "<td>" +
                        tdContent.join(",") +
                        "</td>" +
                        "<td>" +
                        (element.of_voucher.state === 2 ? "0.00" : element.amount) +
                        "</td>" +
                        "<td>" +
                        element.method +
                        "</td>" +
                        // +"<td>"+element.voucher.sale.billing_period+"</td>"
                        "</tr>";

                    if (!boo) {
                        html += itemHtml;
                    } else {
                        if (
                        element.of_voucher.of_sale.detail[0].of_product.service.id !=
                            0 &&
                        element.of_voucher.of_sale.detail[0].of_product.service.id ==
                            this.filterModal.categorie
                        ) {
                        html += itemHtml;
                        }
                    }
                });
        }
        return html;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdDataBeads: function () {
            this.post({
                url: this.$store.getters.get__url + "/bead/close",
                token: this.$store.getters.get__token,
                params: this.bead,
            })
            .then((response) => {
                this.bead.authorize = response.bead.authorize;
                this.process.bead = this.bead.id;
                this.process.retirement = this.bead.retirement;
                this.process.deposit = this.bead.deposit;
                this.process.incomes = response.income;
                this.process.expenses = response.expense;
                this.process.anulaciones = response.cancel;
                this.process.pasajes = response.pasajes;
                this.process.initial = response.initial;
                this.dataCampus = response.campus;
                if (response.bead.authorize!=0) {
                    let cierre= JSON.parse(response.transaction.json_cierre);
                    this.process.billetes=cierre.billetes;
                    this.process.monedas=cierre.monedas;
                    this.cpContarBilletes();
                }
                this.mtdSetCategories();
                //this.mtdSetSubcategories();
                })
            .catch((error) => {
                console.log(error);
            });
        },

        mtdShowModal: function (title) {
            this.modalDetalle.title = title;
            if (title == "Ingresos") {
                if (title !== "Planilla") {
                this.modalDetalle.modal_form = true;
                }
                this.process.incomes.forEach((income) => {
                const efectivoCharge = income.charge.find(
                    (charge) => charge.method === "efectivo"
                );
                // Si encontramos el charge efectivo, agregamos solo ese ingreso
                if (efectivoCharge) {
                    this.modalDetalle.item.push({
                    date: income.date,
                    vaucher_fullname: title !== "Egresos" ? income.vaucher_fullname : null,
                    serie: income.serie,
                    number: income.number,
                    chargeAmount: efectivoCharge.amount,
                    state: income.state,
                    });
                }
                });
            } else if (title == "Egresos") {
                this.modalDetalle.modal_form = true;
                this.process.expenses.forEach((element) => {
                this.modalDetalle.item.push(element);
                });
            } else {
                if (title == "Tarjeta") {
                this.modalDetalle.modal_form = true;
                this.process.incomes.forEach((income) => {
                    const efectivoCharge = income.charge.find(
                    (charge) => charge.method === "tarjeta"
                    );
                    // Si encontramos el charge efectivo, agregamos solo ese ingreso
                    if (efectivoCharge) {
                    this.modalDetalle.item.push({
                        date: income.date,
                        vaucher_fullname: income.vaucher_fullname,
                        serie: income.serie,
                        number: income.number,
                        chargeAmount: efectivoCharge.amount,
                        state: income.state,
                    });
                    }
                });
                }
                if (title == "Transferencia") {
                this.modalDetalle.modal_form = true;
                this.process.incomes.forEach((income) => {
                    const efectivoCharge = income.charge.find(
                    (charge) => charge.method === "transferencia"
                    );
                    // Si encontramos el charge efectivo, agregamos solo ese ingreso
                    if (efectivoCharge) {
                    this.modalDetalle.item.push({
                        date: income.date,
                        vaucher_fullname: income.vaucher_fullname,
                        serie: income.serie,
                        number: income.number,
                        chargeAmount: efectivoCharge.amount,
                        state: income.state,
                    });
                    }
                });
                }
                if (title == "Deposito") {
                this.modalDetalle.modal_form = true;
                this.process.incomes.forEach((income) => {
                    const efectivoCharge = income.charge.find(
                    (charge) => charge.method === "deposito"
                    );
                    // Si encontramos el charge efectivo, agregamos solo ese ingreso
                    if (efectivoCharge) {
                    this.modalDetalle.item.push({
                        date: income.date,
                        vaucher_fullname: income.vaucher_fullname,
                        serie: income.serie,
                        number: income.number,
                        chargeAmount: efectivoCharge.amount,
                        state: income.state,
                    });
                    }
                });
                }
                if (title == "Planilla") {
                this.modalDetalle.modal_form = true;
                this.process.incomes.forEach((income) => {
                    const efectivoCharge = income.charge.find(
                    (charge) => charge.method === "planilla"
                    );
                    // Si encontramos el charge efectivo, agregamos solo ese ingreso
                    if (efectivoCharge) {
                    this.modalDetalle.item.push({
                        date: income.date,
                        vaucher_fullname: income.vaucher_fullname,
                        serie: income.serie,
                        number: income.number,
                        chargeAmount: efectivoCharge.amount,
                        state: income.state,
                    });
                    }
                });
                }
            }
            //console.log(this.modalDetalle.item);
        },

        closeModalDetalle: function () {
            this.modalDetalle = {
                title: "",
                modal_form: false,
                item: [],
            };
        },

        mtdSetCategories: function () {
            let str = "";
            this.data = [];
            this.process.incomes.forEach((element) => {
                let efec = 0;
                let efect = [];
                let otro = 0;
                let otros = [];

                element.charge.forEach((ele) => {
                if (ele.method == "efectivo") {
                    element.of_sale.detail.forEach((d) => {
                    efect.push(parseFloat(d.price));
                    });
                } else {
                    element.of_sale.detail.forEach((d) => {
                    otros.push(parseFloat(d.price));
                    });
                }
                });


                element.of_sale.detail.forEach((ele, index) => {
                if (!str.includes("," + ele.of_product.service.id + ",")) {
                    this.categories.push({
                    label: ele.of_product.service.name,
                    id: ele.of_product.service.id,
                    });
                    str += "," + ele.of_product.service.id + ",";
                }
                this.data.push({
                    categorie_id: ele.of_product.service.id,
                    categorie: ele.of_product.service.name,
                    subcategorie_id: ele.of_product.service.id,
                    subcategorie: ele.of_product.service.name,
                    servicio: ele.of_product.name + " (" + ele.quantity + ")",
                    efectivo: efect[index] !== undefined ? efect[index] : 0.0,
                    otros: otros[index] !== undefined ? otros[index] : 0.0,
                    total:
                    element.of_sale.detail.length > 1
                        ? parseFloat(ele.quantity * ele.price)
                        : parseFloat(element.amount).toFixed(2),
                    //type: element.of_sale.type_patient,
                });
                });
            });
        },
   
        /*
        mtdSetSubcategories: function () {
            let str = "";
            this.process.incomes.forEach((element) => {
                element.sale.detail.forEach((ele) => {
                if (!str.includes("," + ele.subsub_categorie.subategorie.id + ",")) {
                    this.subcategories.push({
                    label: ele.subsub_categorie.subategorie.name,
                    id: ele.subsub_categorie.subategorie.id,
                    categorie: ele.subsub_categorie.subategorie.categorie.id,
                    });
                    str += "," + ele.subsub_categorie.subategorie.id + ",";
                }
                });
            });
        },
        */

        cpContarBilletes: function () {
            let total = 0;
            this.process.billetes.forEach((element) => {
                let can = 0;
                if (!element.cantidad) {
                can = 0;
                }
                can = element.cantidad != "" && element.cantidad != null ?
                parseFloat(element.cantidad).toFixed(2) : 0;
                let tot = parseFloat(element.value).toFixed(2);
                element.total = tot == NaN ? 0 : parseFloat(can * tot).toFixed(2);
                total += parseFloat(element.total);
            });

            this.process.monedas.forEach((element) => {
                let can = 0;
                if (!element.cantidad) {
                can = 0;
                }
                can = element.cantidad != "" && element.cantidad != null ?
                parseFloat(element.cantidad).toFixed(2) : 0;
                let tot = parseFloat(element.value).toFixed(2);
                element.total = tot == NaN ? 0 : parseFloat(can * tot).toFixed(2);
                total += parseFloat(element.total);
            });
            this.finalCash = total;
        },

        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },

        returnHome: function () {
            this.$emit("mtdChangePage", "home");
        },

        mtdCloseBead: function (status) {
        this.process.otroIngresos.forEach((ingreso) => {
            switch (ingreso.label) {
            case "Transferencia":
                ingreso.total = parseFloat(this.cpTotalTransferencia).toFixed(2);
                break;
            case "Tarjeta":
                ingreso.total = parseFloat(this.cpTotalTarjeta).toFixed(2);
                break;
            case "Deposito":
                ingreso.total = parseFloat(this.cpTotalDeposito).toFixed(2);
                break;
            case "Planilla":
                ingreso.total = parseFloat(this.cpTotalPlanilla).toFixed(2);
                break;
            }
        });

        if (this.finalCash > 0) {
            this.process.status = status;
            this.process.box = this.bead.box_id;
            this.process.vueltos = []; // falta
            this.process.initial = this.bead.initial;
            this.process.observation = "";
            if(this.bead.authorize==0){
            this.post({
            url: this.$store.getters.get__url + "/bead/close/store",
            token: this.$store.getters.get__token,
            params: { process: this.process },
            })
            .then((response) => {
                this.$refs.SweetAlert.showSuccess("Solicitud de Aprobación Registrada");
                this.$emit("mtdChangePage", "home");
            })
            .catch((errors) => {});
            }else if(this.bead.authorize==1){
            this.$refs.SweetAlert.showConfirmSimple2("","Va reenviar la Solictud de Aprobación","warning","Reenviar") 
            .then((result) => {
                if (result.value) {
                    this.post({
                        url: this.$store.getters.get__url + "/bead/close/store",
                        token: this.$store.getters.get__token,
                        params: { process: this.process },
                    })
                        .then((response) => {
                        this.$refs.SweetAlert.showSuccess("Solicitud de Aprobación Reenviada");
                        this.$emit("mtdChangePage", "home");
                        })
                        .catch((errors) => {});  
                }
            });
            /*
            Swal.fire({
                    icon: "warning",
                    title: "Va reenviar la Solictud de Aprobación",
                    width: "400px",
                    showDenyButton: true,
                    confirmButtonText: "Reenviar",
                    confirmButtonColor: "#3085d6",
                    showCancelButton: false,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cerrar",
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.post({
                        url: this.$store.getters.get__url + "/bead/close/store",
                        token: this.$store.getters.get__token,
                        params: { process: this.process },
                    })
                        .then((response) => {
                        this.$refs.SweetAlert.showSuccess("Solicitud de Aprobación Reenviada");
                        this.$emit("mtdChangePage", "home");
                        })
                        .catch((errors) => {});  
                        
                    }
                });
            */
            }
            else if(this.bead.authorize==2){
            this.$refs.SweetAlert.showConfirmSimple2("","Cerrar Caja","warning","Confirmar")
            .then((result) => {
                if (result.value) {
                    this.post({
                        url: this.$store.getters.get__url + "/bead/persimo/cierre/final",
                        token: this.$store.getters.get__token,
                        params: { process: this.process },
                    })
                        .then((response) => {
                        this.$refs.SweetAlert.showSuccess("Arqueo de Caja Finalizado");
                        
                            const beadId = this.bead.id;
                            window.open(
                            this.$store.getters.get__url + "/Admision/viewpdf/cierre/" + beadId,
                            "_blank"
                            );
                        
                            this.$emit("mtdBackCampus");
                        })
                        .catch((errors) => {});      
                }
            });
            /*
            Swal.fire({
                    icon: "warning",
                    title: "Cerrar Caja",
                    width: "400px",
                    showDenyButton: true,
                    confirmButtonText: "Confirmar",
                    confirmButtonColor: "#3085d6",
                    showCancelButton: false,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cerrar",
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.post({
                        url: this.$store.getters.get__url + "/bead/persimo/cierre/final",
                        token: this.$store.getters.get__token,
                        params: { process: this.process },
                    })
                        .then((response) => {
                        this.$refs.SweetAlert.showSuccess("Arqueo de Caja Finalizado");
                        
                            const beadId = this.bead.id;
                            window.open(
                            this.$store.getters.get__url + "/gerencia/viewpdf/cierre/" + beadId,
                            "_blank"
                            );
                            this.$emit("mtdBackCampus");
                        })
                        .catch((errors) => {});  
                        
                    }
                });
            */
            }
            else{
            this.$refs.SweetAlert.showConfirmSimple2("","Enviar Corrección para la Autorización","warning","Enviar")
            .then((result) => {
                if (result.value) {
                    if (result.isConfirmed) {
                    this.post({
                        url: this.$store.getters.get__url + "/bead/close/store",
                        token: this.$store.getters.get__token,
                        params: { process: this.process },
                    })
                        .then((response) => {
                        this.$refs.SweetAlert.showSuccess("Solicitud de Cierre Registrada");
                        this.$emit("mtdChangePage", "home");
                        })
                        .catch((errors) => {});  
                        
                    }
                }
            });
            /*
            Swal.fire({
                    icon: "warning",
                    title: "Enviar Corrección para la Autorización",
                    width: "400px",
                    showDenyButton: true,
                    confirmButtonText: "Enviar",
                    confirmButtonColor: "#3085d6",
                    showCancelButton: false,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cerrar",
                }).then((result) => {
                    if (result.isConfirmed) {
                    this.post({
                        url: this.$store.getters.get__url + "/bead/close/store",
                        token: this.$store.getters.get__token,
                        params: { process: this.process },
                    })
                        .then((response) => {
                        this.$refs.SweetAlert.showSuccess("Solicitud de Cierre Registrada");
                        this.$emit("mtdChangePage", "home");
                        })
                        .catch((errors) => {});  
                        
                    }
                });
            */
            }
        } else {
            this.$refs.SweetAlert.showWarning("Digite cantidades de arqueo");
        }
        },

        /* REPORTES */

        mtdReportBead: function (type) {
            this.type = type;
            moment.locale("es");
            this.filterModal.begindate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-01";
            this.filterModal.enddate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-" +
                moment().format("L").substr(0, 2);
        },

        mtdViewReporte: function ( msg) {
            this.modalReporte = {
                title: "REPORTES DE " + msg,
                modal_form: true
            }
            this.type=msg;
        },

        mtdHideModal: function () {
            this.type = "";
            this.filterModal.campu_id = 0;
            this.filterModal.bead_id = 0;
            this.filterModal.begindate = "";
            this.filterModal.enddate = "";
            this.dataReport = [];
        },

        mtdSelectCampusToBox: function () {
            this.dataBeadsList = [];
            this.dataCampus.forEach((element) => {
                if (element.id == this.filterModal.campu_id) {
                this.dataBeadsList = element.boxes;
                }
            });
        },

        mtdDataReport: function () {
            if (this.filterModal.campu_id == 0) {
                this.$refs.SweetAlert.showWarning("Escoja una Sede!!");
                return;
            }
            if (this.filterModal.bead_id == 0) {
                this.$refs.SweetAlert.showWarning("Escoja una Caja!!");
                return;
            }
            this.get({
                url:
                this.$store.getters.get__url +
                "/reporte/reportecaja?type=" +
                this.type +
                "&campu_id=" +
                this.filterModal.campu_id +
                "&bead_id=" +
                this.filterModal.bead_id +
                "&begindate=" +
                this.filterModal.begindate +
                "&enddate=" +
                this.filterModal.enddate,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                this.dataReport = response.data;
                this.filterModal.categorie = 0;
                this.filterModal.subategorie = 0;
                this.dataReport.forEach((element, index) => {
                    if (this.type != "EGRESO") {
                    let model = element.of_voucher.of_sale.detail
                        ? element.of_voucher.of_sale.detail[0].of_product.service.name
                        : "-";
                    let tdContent = [];
                    for (let i = 0; i < element.of_voucher.of_sale.detail.length; i++) {
                    let ssEspecifico = element.of_voucher.of_sale.detail[i]
                        ? element.of_voucher.of_sale.detail[i].of_product.name
                        : "-";
                    tdContent.push(ssEspecifico);
                    };
                    let insurance =
                        element.of_voucher.of_sale.of_insurance != null
                        ? element.of_voucher.of_sale.of_insurance.name
                        : "-";
                    this.excelIncome.push({
                        "N°": index + 1,
                        ESTADO: element.of_voucher.state == 1 ? "ACTIVO" : "ANULADO",
                        ADM:
                        element.of_voucher.of_bead.user.name,
                        SEDE: element.of_voucher.of_sale.campu.name,
                        FECHA: element.of_voucher.date,
                        COMPROBANTE: element.of_voucher.document,
                        "A NOMBRE DE":
                        element.of_voucher.vaucher_document +
                        " " +
                        element.of_voucher.vaucher_fullname,
                        SERIE: element.of_voucher.serie,
                        NÚMERO: element.of_voucher.number,
                        "PACIENTE TIPO": element.of_voucher.of_sale.type_patient,
                        CONVENIO: insurance,
                        "%": element.of_voucher.of_sale.payment_patient,
                        "INFORMACIÓN DEL PACIENTE": element.of_voucher.of_sale.of_patient.name,
                        CATEGORÍA: model,
                        "SERVICIO ESPECÍFICO": tdContent.join(","),
                        MONTO: (element.of_voucher.state === 2 ? "0.00" : element.amount),
                        "MEDIO DE PAGO": element.method,
                        // 'PER. FACTURACIÓN':element.voucher.sale.billing_period,
                    });
                    } else {
                    this.excelExpense.push({
                        "N°": index + 1,
                        ESTADO:
                        element.state == 1
                            ? "PENDIENTE DE APROBACIÓN"
                            : element.state == 2
                            ? "APROBADO"
                            : "RECHAZADO",
                        ADMISIONISTA: element.of_bead.user.name,
                        SEDE: element.campu.name,
                        "FECHA DE EGRESO": element.date,
                        "FECHA DE COMPROBANTE":
                        element.date_doc == null ? element.date : element.date_doc,
                        "TIPO DE COMPROBANTE":
                        element.document_type_id != null
                            ? element.document_type.name
                            : element.modulo == "receipt"
                            ? "RECIBO POR HONORARIOS"
                            : "DOC. INTERNO",
                        "NÚMERO DE COMPROBANTE":
                        element.modulo == "passage"
                            ? "PLANILLA-PASAJES"
                            : element.number
                            ? element.serie + "-" + element.number
                            : element.serie
                            ? element.serie
                            : "-",
                        "RAZON SOCIAL/NOMBRE":
                        element.modulo == "passage"
                            ? element.passage.name
                            : element.modulo == "retiro"
                            ? element.of_bead.user.name
                            : element.modulo == "receipt"
                            ? element.receipt.name
                            : element.company,
                        "RUC/DNI":
                        element.modulo == "passage"
                            ? element.passage.document
                            : element.modulo == "retiro"
                            ? element.of_bead.user.dni
                            : element.modulo == "receipt"
                            ? element.receipt.document
                            : element.document,
                        TOTAL: element.total,
                        "MONTO PAGADO": element.amount,
                        PENDIENTE: element.pending,
                        "MEDIO DE PAGO": element.method,
                        "MOTIVO, DESCRIPCION O DETALLE DE COMPRA": element.description,
                    });
                    }
                });
                this.mtdSetCategoriesModal();
                this.mtdSetSubcategoriesModal();
                })
                .catch((errors) => {});
        },

        mtdSetCategoriesModal: function () {
            let str = "";
            this.dataReport.forEach((element) => {
                element.of_voucher.of_sale.detail.forEach((ele) => {
                if (!str.includes("," + ele.of_product.service.id + ",")) {
                    this.categoriesModal.push({
                    label: ele.of_product.service.name,
                    id: ele.of_product.service.id,
                    });
                    str += "," + ele.of_product.service.id + ",";
                }
                });
            });
        },

        /*
        mtdSetSubcategoriesModal: function () {
            let str = "";
            this.dataReport.forEach((element) => {
                element.voucher.sale.detail.forEach((ele) => {
                if (!str.includes("," + ele.subsub_categorie.subategorie.id + ",")) {
                    this.subcategoriesModal.push({
                    label: ele.subsub_categorie.subategorie.name,
                    id: ele.subsub_categorie.subategorie.id,
                    categorie: ele.subsub_categorie.subategorie.categorie.id,
                    });
                    str += "," + ele.subsub_categorie.subategorie.id + ",";
                }
                });
            });
        },
        */

        exportExcel: function () {
            const datos = this.type == "INGRESO" ? this.excelIncome : this.excelExpense;
            const fileName = this.type;
            const typeExport = exportFromJson.types.xls;
            exportFromJson({
                data: datos,
                fileName: fileName,
                exportType: typeExport,
            });
        },

        FormatJson(FilterData, JsonData) {
            return JsonData.map((v) =>
                FilterData.map((j) => {
                return v[j];
                })
            );
        },

        /*Retiros/Depositos */

        mtdRetiros: function () {
            this.modalRetiros = {
                title: "REGISTRAR AUTORIZACIÓN PARA DEPÓSITOS O RETIROS",
                modal_form: true,
            }
        },

        closeModalRetiros: function () {
            this.modalRetiros = {
                action: "",
                title: "",
                modal_form: false,
                item: [],
            };
        },

        mtdSendPermission: function (payload) {
            this.post({
                url: this.$store.getters.get__url + "/Admision/store/transaction",
                token: this.$store.getters.get__token,
                params: {
                bead: this.bead,
                data: payload,
                campu: this.$store.getters.get__campus,
                },
            })
            .then((response) => {
                // console.log(response);
                if (response.state == 0) {
                    this.$refs.SweetAlert.showSuccess("Registro correcto");
                    this.closeModalRetiros();
                } else {
                    this.$refs.SweetAlert.showError("No se puedo registrar");
                }
                })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
                });
        },
        

        /*Otro Report*/
        closeModalReporte: function() {
            this.modalReporte = {
                title: '',
                modal_form: false
            }
            this.type='';
        },


    },

    filters: {
        formatDate(date) {
        return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
        let arTime = time.split(":");
        if (arTime[0] <= 12) {
            return time + " am";
        } else {
            return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
        }
        },
    },

}
</script>

<style scoped>
#div-tabla-ingreso,
#div-tabla-egreso {
  padding: 0px !important;
  height: auto;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 0rem;
}

#div-tabla-ingreso::-webkit-scrollbar,
#div-tabla-egreso::-webkit-scrollbar {
  -webkit-appearance: none;
}

#div-tabla-ingreso::-webkit-scrollbar:vertical,
#div-tabla-egreso::-webkit-scrollbar:vertical {
  width: 10px;
}

#div-tabla-ingreso::-webkit-scrollbar-button:increment,
#div-tabla-egreso::-webkit-scrollbar-button:increment,
#div-tabla-ingreso::-webkit-scrollbar-button,
#div-tabla-egreso::-webkit-scrollbar-button {
  display: none;
}

#div-tabla-ingreso::-webkit-scrollbar:horizontal,
#div-tabla-egreso::-webkit-scrollbar:horizontal {
  height: 10px;
}

#div-tabla-ingreso::-webkit-scrollbar-thumb,
#div-tabla-egreso::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#div-tabla-ingreso::-webkit-scrollbar-track,
#div-tabla-egreso::-webkit-scrollbar-track {
  border-radius: 10px;
}
#div-tabla-caja {
  max-height: 400px; /* Ajusta la altura máxima según tus necesidades */
  overflow-y: auto; /* Agrega un scroll vertical cuando el contenido exceda la altura máxima */
}
#div-tabla-caja::-webkit-scrollbar {
  width: 4px; /* Ancho del scroll */
}
</style>

<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12">
        <div id="table-detalle">
          <table id="table-body" class="table table-bordered table-responsive">
            <caption class="py-0 my-0"></caption>
            <thead>
              <tr class="text-white border-white text-center text-sm bg-main">
                <th>#</th>
                <th>Marca</th>
                <th>Molecula</th>
                <th>Comercial</th>
                <th>Presentaci&oacute;n</th>
                <th>Stock Minimo</th>
                <th>Stock Actual</th>
                <th>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in data"
                :key="index"                
              >
                <td>{{ index + 1}}</td>
                <td>{{ item.brand ?  item.brand : "-" }}</td>
                <td>{{ item.molecule ? item.molecule : "-"}}</td>
                <td>{{ item.comercial ?item.comercial : "-" }}</td>
                <td>{{ item.presentacion ? item.presentacion : "-"}}</td>
                <td>{{ item.min_stock ? item.min_stock : "-"}}</td>
                <td>{{ item.stock ? item.stock : "-"}}</td>
                <td>
                    <input type="text" v-model="item.quantity" class="form-control form-control-sm w-50" @keydown="filterKey">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      <button @click="mtdCommitCall" class="btn btn-success btn-sm">
        Registrar Requerimiento
      </button>
      &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert"/>
  </CModal>
</template>
  
  <script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
export default {
  name: "c-vigilancia-modal-control",
  components: {
    CModal,
    SweetAlert
  },
  data() {
    return {
      swal: null,
      detalle: [],
    };
  },
  created() {},
  computed: {
    cpData() {
      return this.item.map((element) => element);
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    data: [],
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.data.forEach(item => {
        item.quantity = 0;
      });
      this.$emit("mtdCloseModalView");
    },
    filterKey: function (e) {
      let b = false;
      b = /^[0-9]+$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },

    mtdCommitCall: function () {

      const hasInvalidQuantity = this.data.some(item => item.quantity === 0 || item.quantity === "");

      if (hasInvalidQuantity) {
        this.$refs.SweetAlert.showWarning("Al menos una cantidad es 0 o está vacía. Por favor, corríjalo");
      } else {
        this.detalle = this.data.map(item => ({
          of_product_detail: item.of_product_detail,
          quantity: item.quantity
        }));

        this.$emit("mtdCommitRequeriment", this.detalle);

        this.data.forEach(item => {
          item.quantity = 0; 
        });
      }
    },

  },
};
</script>
  <style scoped>
#content-timeline {
  border: 1px solid #aaa;
  height: 100px;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#content-timeline::-webkit-scrollbar {
  -webkit-appearance: none;
}

#content-timeline::-webkit-scrollbar:vertical {
  width: 10px;
}

#content-timeline::-webkit-scrollbar-button:increment,
#content-timeline::-webkit-scrollbar-button {
  display: none;
}

#content-timeline::-webkit-scrollbar:horizontal {
  height: 10px;
}

#content-timeline::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#content-timeline::-webkit-scrollbar-track {
  border-radius: 10px;
}

.header-main {
  background-color: #900052;
}

@media (max-width: 992px) {
  .flex-row {
    flex-direction: column !important;
    align-items: center;
    padding-top: 20px !important;
  }

  #div-listcola {
    width: 300px !important;
    margin-bottom: 20px !important;
  }
}

.text-doctor {
  font-weight: bold;
  font-size: 14px;
  color: #900052;
}
.selected {
  background-color: yellow;
}

.table-th-state {
  width: 20% !important;
}

#tb-lote th {
  font-size: 9.5px;
}

#tb-lote td {
  font-size: 11px;
}

.error-message {
  color: red;
  font-size: 9px;
}

/* X a la derecha*/
.header-container {
  width: 100%;
  margin-right: 10px;
}

.close-button {
  border: none;
  background-color: transparent;
}

.modal-body {
  margin-right: 20px;
}

@media (max-width: 767px) {
  #table-body {
    font-size: 10px !important;
  }
}
</style>
  
<template>
  <div>    
    <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
      <div v-if="bead.of_box.name!='CAJA CHICA'" :style="mtdGetStyle('cxc')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('cxc')"><span :style="mtdGetStyleSpan('cxc')" class="btn w-100"><strong>CXC</strong></span></div>
      <div  v-if="bead.of_box.name=='CAJA CHICA'"  :style="mtdGetStyle('egresos')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('egresos')"><span :style="mtdGetStyleSpan('egresos')" class="btn w-100"><strong>REPORTE EGRESOS</strong></span></div>
      <div   v-if="bead.of_box.name=='CAJA CHICA'"  :style="mtdGetStyle('vueltos')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('vueltos')"><span :style="mtdGetStyleSpan('vueltos')" class="btn w-100"><strong>VUELTOS</strong></span></div>
      <div v-if="bead.of_box.name!='CAJA CHICA'" :style="mtdGetStyle('anulaciones')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('anulaciones')"><span :style="mtdGetStyleSpan('anulaciones')" class="btn w-100"><strong>ANULACIONES</strong></span></div>
      <div  v-if="bead.of_box.name!='CAJA CHICA'"  :style="mtdGetStyle('seguimiento')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('seguimiento')"><span :style="mtdGetStyleSpan('seguimiento')" class="btn w-100"><strong>SEGUIMIENTO</strong></span></div>
      <div  v-if="bead.of_box.name!='CAJA CHICA'"  :style="mtdGetStyle('resultadosImagenes')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('resultadosImagenes')"><span :style="mtdGetStyleSpan('resultadosImagenes')" class="btn w-100"><strong>RESULTADOS IMG</strong></span></div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
  props: {
    bead:{
      type: Object,
    },
    pageView: String
  },
  data () {
    return {
      page: null
    }
  },
  created () {
   //console.log(this.bead,"caja footer");
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != "home" && this.pageView != "venta" && this.pageView != "close" ) return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != "home" && this.pageView != "venta" && this.pageView != "close" ) return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>
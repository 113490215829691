<template>
<div class="row px-4">
    <!-- <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 py-2 mt-3">
      <div class="input-group input-group-sm">
        <span class="input-group-text col-md-3">Desde</span>
        <input
          type="date"
          aria-label="shift_date"
          class="form-control form-control-sm"
          id="begindate"
          v-model="filter.begindate"
        />
      </div>
    </div>

    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 py-2 mt-3">
      <div class="input-group input-group-sm">
        <span class="input-group-text col-md-3">Hasta</span>
        <input
          type="date"
          aria-label="shift_date"
          class="form-control form-control-sm"
          id="enddate"
          v-model="filter.enddate"
        />
      </div>
    </div>
    <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 py-2 mt-3">
      <button
        type="button"
        class="btn btn-sm w-100"
        style="background-color: rgb(170, 2, 95); color: white"
        @click="mtdGetdataIDfilter"
      >
        <i class="fas fa-filter"></i> Filtrar
      </button>
    </div>
    -->
    <div class="py-2">

        <div class="d-flex mb-3 mt-2">
            <h6 class="mt-2 me-2 "><i class="fas fa-exclamation-circle"></i> REQUERIMIENTOS </h6>
            <button v-if="ordendecompra.length > 0" type="button" @click="mtdviewcarrito()" class="border-0 btn rounded-2 bg-main btn-ver text-white btn-sm">
                <i class="fas fa-eye"></i> Ver Carrito
            </button>
        </div>

        <div class="col-md-12 table-responsive" id="div-tabla-anular">
            <table id="tb-anular" class="table table-bordered text-center" aria-describedby="mydesc">
                <thead class="" style="font-size: 12px">
                    <tr class="text-center" style="vertical-align: middle">
                        <th scope="col">N°</th>
                        <th scope="col">USUARIO</th>
                        <th scope="col">FECHA</th>
                        <!-- <th scope="col">MOLECULA</th>
                        <th scope="col">MARCA</th>
                        <th scope="col">CANTIDAD</th> -->
                        <th scope="col">ESTADO</th>
                        <th scope="col" colspan="1">ACCIONES</th>
                    </tr>
                </thead>
                <tbody v-if="data.length > 0" class="border-main" style="font-size: 12px; vertical-align: middle">
                    <tr v-for="(items, index) in data" :key="index" class="tbody-table1 text-center">
                        <td>
                            {{ index + 1 }}
                        </td>
                        <td>{{ items.user.name }}</td>
                        <td>
                            {{ items.date }}
                        </td>
                        <!-- <td>
                            {{ items.of_product_detail.molecule }}
                        </td>
                        <td>
                            {{ items.of_product_detail.of_brand.name }}
                        </td>
                        <td>
                            {{ items.quantity }}
                        </td> -->
                        <td>
                            <span v-if="items.state == 1" class="fw-bold rounded-3 px-2 bg-success text-white py-1">
                                REGISTRADO
                            </span>
                            <span v-if="items.state == 2" class="fw-bold rounded-3 px-2 bg-primary text-white py-1">
                                APROBADO
                            </span>
                            <span v-if="items.state == 3" class="fw-bold rounded-3 px-2 bg-warning text-white py-1">
                                TERMINADO
                            </span>
                        </td>
                        <td>
                            <a href="#" @click="mtdAddItemCarrito(items.detalle, items)" id="btn-remove " class="me-1" style="font-size: 15px">
                                <i class="fas fa-shopping-cart"></i>
                            </a>
                            <a href="#" @click="mtdviewmodal(items.detalle)" id="btn-remove " style="font-size: 15px">
                                <i class="fas fa-eye"></i>
                            </a>

                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="8" class="fw-bold">Sin registros</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="d-flex justify-content-center" style="align-items: center">
            <nav v-if="data.length > 0" aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                    </li>
                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                    </li>
                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                    </li>
                    <li v-if="
                limitedStepPagination[limitedStepPagination.length - 1] <
                stepPagination.length - 1
              " class="page-item disabled">
                        <span class="page-link">...</span>
                    </li>
                    <li v-if="
                limitedStepPagination[limitedStepPagination.length - 1] !==
                stepPagination.length
              " class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <cModalViewRequeimientoDetailsVue :requerimiento_id ="requerimiento_id" @registro-exitoso="manejarRegistroExitoso" :ordendecompra="ordendecompra" :dataRequerimientosDetails="dataRequerimientosDetails" @mtdcloseModal="mtdcloseModal" :title="modalRequerimientos.title" :boo_modal="modalRequerimientos.modal_form" :view="modalRequerimientos.view" :medicaments="modalRequerimientos.medicaments" />
</div>
</template>

<script>
import moment from "moment";
import {
    mapActions
} from "vuex";
import cModalViewRequeimientoDetailsVue from "../../modals/cModalViewRequeimientoDetails.vue";

export default {
    components: {
        cModalViewRequeimientoDetailsVue
    },
    props: {
        area: 0,
    },
    data() {
        return {

            filter: {
                area_filter_id: 0,
                molecula: "",
                proveedor: "",
                ruc: "",
            },
            data: [],
            ordendecompra: [],
            modalRequerimientos: {
                title: "",
                modal_form: false,
                view: "",
                medicaments: []
            },
            dataRequerimientosDetails: [],
            totalPagesToShow: 7,
            selectPage: 1,
            stepPagination: [],
            dataproductos: [],
            requerimiento_id:null
        };
    },
    created() {
        this.filter.area_filter_id = this.area;
        // moment.locale("es");
        // this.filter.begindate =
        //   moment().format("L").substr(6, 4) +
        //   "-" +
        //   moment().format("L").substr(3, 2) +
        //   "-01";
        // this.filter.enddate =
        //   moment().format("L").substr(6, 4) +
        //   "-" +
        //   moment().format("L").substr(3, 2) +
        //   "-" +
        //   moment().format("L").substr(0, 2);
        this.mtdGetdataIDfilter();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetdataIDfilter: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/requerimientos/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(6)
                })
                .catch((errors) => {});
        },
        calculateData(items) {
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        mtdviewmodal: function (payload) {
            // this.filter.molecula = payload.of_product_detail.molecule;
            // this.post({
            //         url: this.$store.getters.get__url + "/Logistica/oc/filter",
            //         token: this.$store.getters.get__token,
            //         params: this.filter,
            //     })
            //     .then((response) => {
            this.modalRequerimientos = {
                title: "REQUERIMIENTOS",
                modal_form: true,
                view: "req",
                medicaments: payload
            };
            //     this.filter = {
            //         area_filter_id: 0,
            //         molecula: "",
            //         proveedor: "",
            //         ruc: "",
            //     };
            // })
            // .catch((errors) => {
            //     console.log(errors);
            // });
        },
        mtdcloseModal: function () {
            this.modalRequerimientos = {
                title: "",
                modal_form: false,
            };
            this.ordendecompra = [];
        },

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdviewcarrito() {
            this.modalRequerimientos = {
                title: "ORDENES DE COMPRA",
                modal_form: true,
            };
        },
        mtdAddItemCarrito: function (payload, item) {
            this.requerimiento_id = item.id
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/oc/filterId",
                    token: this.$store.getters.get__token,
                    params: payload,
                })
                .then((response) => {
                    this.dataproductos = response.data;
                    let productosPorId = {};
                    this.dataproductos.forEach(item => {
                        if (productosPorId.hasOwnProperty(item.id)) {
                            if (item.utilidad > productosPorId[item.id].utilidad) {
                                productosPorId[item.id] = item;
                            }
                        } else {
                            productosPorId[item.id] = item;
                        }
                    });
                    this.dataproductosutilidad = Object.values(productosPorId);
                    //generamos ordenes de compra
                    this.dataproductosutilidad.forEach(element => {
                        let existeProveedor = this.ordendecompra.some(item => item.cabecera.id === element.proveedor.id);

                        if (!existeProveedor) {
                            let nuevoItemOrdenCompra = {
                                cabecera: {
                                    id: element.proveedor.id,
                                    proveedor_name: element.proveedor.name,
                                    observation: '',
                                    moneda: 'SOLES',
                                    send: '',

                                },
                                detalle: []
                            };
                            this.ordendecompra.push(nuevoItemOrdenCompra);

                        }
                        payload.forEach(element1 =>{
                            if(element.id === element1.of_product_detail_id){
                               this.cantidad = element1.quantity 
                            }
                        })

                        if (element.cargado === null) {
                            this.cargado_edit = 1;
                        } else {
                            this.cargado_edit = element.cargado;
                        }

                        let itemDetalle = {
                            id: element.id,
                            name:element.name,
                            molecule: element.molecule,
                            commercial: element.commercial,
                            brand: element.brand,
                            presentation: element.presentation,
                            concentration: element.concentration,
                            units: element.units,
                            precio_purchase: element.proveedor.price_purchase,
                            precio_unitario: element.proveedor.price_purchase,
                            quantity: this.cantidad,
                            discount: null,
                            price_discount: null,
                            price_purchase: 0,
                            grabado: true,
                            cargado_state: element.cargado,
                            cargado: this.cargado_edit,
                            utilidad: element.utilidad,
                            unit: element.units[0].id
                        };

                        let ordenCompraProveedor = this.ordendecompra.find(item => item.cabecera.id === element.proveedor.id);

                        let itemExistente = ordenCompraProveedor.detalle.find(detalle => detalle.id === element.id);
                        if (!itemExistente) {
                            ordenCompraProveedor.detalle.push(itemDetalle);
                            
                            this.modalRequerimientos = {
                                title: "ORDENES DE COMPRA",
                                modal_form: true,
                            };
                        } else {
                            swal.fire({
                                title: "Producto Ya Agregado",
                                text: "El producto que intenta agregar ya se encuentra en la orden de compra.",
                                icon: "warning",
                                button: "Aceptar",
                            });
                        }

                    });
                })
                .catch((errors) => {});

        },
        manejarRegistroExitoso() {
            this.ordendecompra = [];
            this.mtdcloseModal();
            this.mtdGetdataIDfilter();
        }

        

    },
};
</script>

<template>
    <div>

        <div class="container text-center">

            <div class="d-flex title-option">
                <!-- <h2 class=" h2-borde mt-3 mb-3 ms-5 text-main pt-1 px-4">
                    <strong>CONSULTAS</strong>
                </h2> -->
                <div class="btn-home mt-3 mb-2 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome()">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>

            
            <ul class="nav pb-0 d-none d-lg-flex" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
              <button @click="changePage('campovisual')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'campovisual' }" id="home-tab" type="button">
                  <strong class="text-xs">Campo Visual</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('oct')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'oct' }" id="profile-tab" type="button">
                  <strong class="text-xs">OCT</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('octmacula')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'octmacula' }" id="profile-tab" type="button">
                  <strong class="text-xs">OCT Macula</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('paquimetria')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'paquimetria' }" id="profile-tab" type="button">
                  <strong class="text-xs">Paquimetría</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('topografia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'topografia' }" id="profile-tab" type="button">
                  <strong class="text-xs">Topografía</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('microscopia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'microscopia' }" id="profile-tab" type="button">
                  <strong class="text-xs">Microscopía E.</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('ecografia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'ecografia' }" id="profile-tab" type="button">
                  <strong class="text-xs">Ecografía Ocular</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('biometria')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'biometria' }" id="profile-tab" type="button">
                  <strong class="text-xs">Biometría</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('angiografia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'angiografia' }" id="profile-tab" type="button">
                  <strong class="text-xs">Angiografía R.</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('resonancia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'resonancia' }" id="profile-tab" type="button">
                  <strong class="text-xs">Resonancia</strong>
              </button>
          </li>
      </ul>

      <div class="tab-content mb-3 px-0 d-none d-lg-block" id="myTabContent">
          <CampoVisual @mtdviewpage="mtdviewpage" v-if="page=='campovisual'" class="px-3" :stateRolAdmin="stateRolAdmin"/>
          <Oct @changePage="changePage" v-if="page=='oct'" :stateRolAdmin="stateRolAdmin" />
          <OctMacula @changePage="changePage" v-if="page=='octmacula'" :stateRolAdmin="stateRolAdmin" />
          <Paquimetria @changePage="changePage" v-if="page=='paquimetria'" :stateRolAdmin="stateRolAdmin" />
          <Topografia @changePage="changePage" v-if="page=='topografia'" :stateRolAdmin="stateRolAdmin" />
          <Microscopia @changePage="changePage" v-if="page=='microscopia'" :stateRolAdmin="stateRolAdmin" />
          <Ecografia @changePage="changePage" v-if="page=='ecografia'" :stateRolAdmin="stateRolAdmin" />
          <Biometria @changePage="changePage" v-if="page=='biometria'" :stateRolAdmin="stateRolAdmin" />
          <Angiografia @changePage="changePage" v-if="page=='angiografia'" :stateRolAdmin="stateRolAdmin" />
          <Resonancia @changePage="changePage" v-if="page=='resonancia'" :stateRolAdmin="stateRolAdmin" />
      </div>

      <!-- Tablet -->
      <div id="app" class="root d-md-block d-lg-none">
          <div class="wrapper-list">
              <dropdown title="Campo Visual" component="CampoVisual" @changePage="changePage"></dropdown>
              <dropdown title="OCT" component="Oct" @changePage="changePage"></dropdown>
              <dropdown title="OCT Macula" component="OctMacula" @changePage="changePage"></dropdown>
              <dropdown title="Paquimetría" component="Paquimetria" @changePage="changePage"></dropdown>
              <dropdown title="Topografía" component="Topografia" @changePage="changePage"></dropdown>
              <dropdown title="Microscopía E." component="Microscopia" @changePage="changePage"></dropdown>
              <dropdown title="Ecografía Ocular" component="Ecografia" @changePage="changePage"></dropdown>
              <dropdown title="Biometría" component="Biometria" @changePage="changePage"></dropdown>
              <dropdown title="Angiografía R." component="Angiografia" @changePage="changePage"></dropdown>
              <dropdown title="Resonancia" component="Resonancia" @changePage="changePage"></dropdown>
          </div>
      </div>
      <!--  -->

        </div>

    </div>
</template>

<script>
import {
    mapActions
} from 'vuex'

import CampoVisual from '@/components/Medico/Consulta/CampoVisual.vue';
import Oct from '@/components/Medico/Consulta/Oct.vue';
import OctMacula from '@/components/Medico/Consulta/OctMacula.vue';
import Paquimetria from '@/components/Medico/Consulta/Paquimetria.vue';
import Topografia from '@/components/Medico/Consulta/Topografia.vue';
import Microscopia from '@/components/Medico/Consulta/Microscopia.vue';
import Ecografia from '@/components/Medico/Consulta/Ecografia.vue';
import Biometria from '@/components/Medico/Consulta/Biometria.vue';
import Angiografia from '@/components/Medico/Consulta/Angiografia.vue';
import Resonancia from '@/components/Medico/Consulta/Resonancia.vue';
import Dropdown from "@/components/Medico/Consulta/Dropdown.vue";

export default {
    name: 'c-enfermera-consulta',
    components: {
      CampoVisual,
      Oct,
      OctMacula,
      Paquimetria,
      Topografia,
      Microscopia,
      Ecografia,
      Biometria,
      Angiografia,
      Resonancia,
      Dropdown
    },
    data() {
        return {
            page: 'campovisual',
            stateHistory: null,
            viewInput:null,
            patients: {},
        }
    },
    props:{
        stateRolAdmin: Boolean,
    },
    created() {
        
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            if(page==='cola'){
                this.patients ={}
            }
            this.stateHistory = (page === 'consulta') ? 1 : 0;
            this.viewInput = (page === 'consulta') ? 1 : 0;
        },
        returnHome() {
         this.$emit('mtdChangePage','home')
        },
        mtdviewpage: function (payload) {
            this.page = 'consulta'
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput=0;
        }
        
    }
}
</script>
<template>
<div>
    <div class="container text-center">
        <div class="d-flex title-option mt-3">
            <div class="btn-home mt-1 mb-1 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="row d-flex mt-2" v-if="subpage=='table'">
            <div class="col-md-12 col-lg-12">
                <div class="px-0">
                    <div class="input-group input-group mb-3">
                        <span class="input-group-text text-white bg-main "><i class="fas fa-search"></i></span>
                        <input v-model="search" type="text" class="form-control form-control" placeholder="Buscar Nombre o Dni" @keyup.enter="mtdGetData" />
                    </div>
                </div>
            </div>
            <div v-if="data.length > 0 && cpData.length > 0" class="d-flex justify-content-end px-2">
                <nav aria-label="Page navigation example ">
                    <ul class="pagination mb-0 ">
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                        </li>
                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                        </li>
                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>

                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                            </a>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                            <span class="page-link">...</span>
                        </li>
                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                            </a>
                        </li>
                        <li class="page-item">
                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-md-12 mt-3">
                <table class="table table-responsive table-bordered px-0">
                    <caption></caption>
                    <thead class="bg-main text-white">
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Edad</th>
                            <th scope="col">DNI</th>
                            <th scope="col">Cant. Atenciones</th>
                            <th scope="col">Ultima Atencion</th>
                            <th scope="col">Accion</th>
                        </tr>
                    </thead>
                    <tbody class="text-xs">
                        <tr v-for="(item, index) in cpData" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.year }}</td>
                            <td>{{ item.document }}</td>
                            <td>{{ item.clinica_histories.length }}</td>
                            <td>
                                {{ mtdFormatDate(item) }}
                            </td>
                            <td class="text-center">
                                <div class="btn-success p-0">
                                    <button type="button" class="btn text-white btn-sm" @click="mtdGetHC(item)">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="cpData.length === 0">
                            <td colspan="7" class="text-center">No hay Examenes para llenar</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <HistoriaView v-if="subpage=='hcs'" :patient="patient" :history="dataHC" @changeSubpage="changeSubpage" />
        <SweetAlert :swal="swal" ref="SweetAlert" />

    </div>

</div>
</template>

    
<script>
import {
    mapActions
} from "vuex";
import HistoriaView from '@/components/Medico/HCconsulta.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from "moment";

export default {
    name: 'c-Medico-conclusiones',
    components: {
        HistoriaView,
        SweetAlert
    },
    data() {
        return {
            search: "",
            swal: null,
            totalPagesToShow: 5,
            selectPage: 1,
            data: [],
            stepPagination: [],
            dataShow: [],
            subpage: 'table',
            patient: {
                title: "",
                name: "",
                option: "",
                of_reservation_id: "",
            },
            dataHC: [],
        }
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            if(this.search.length > 0 ){
            this.get({
                    url: this.$store.getters.get__url + "/Doctor/hc/" + this.search,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.data = response.data;
                    if (this.data.length === 0) {
                        this.dataShow = [];
                    } else {
                        this.calculateData(10);
                    }
                })
                .catch((errors) => {});
            }else{
                //mostrar alerta
                this.$refs.SweetAlert.showWarning('Debe digitar un nombre o dni');
            }
        },
        mtdGetHC: function (patient) {
            this.subpage = patient.clinica_histories.length > 0 ? 'hcs' : 'table';
            this.dataHC = patient.clinica_histories;
            this.patient.name = patient.name;
        },
        mtdSearchFilter() {
            this.calculateData(10);
        },
        mtdFormatDate(item){
            let date = item.clinica_histories.length > 0? item.clinica_histories[item.clinica_histories.length-1].created_at : '-';
            return date !== '-' ?  moment(date).format("DD/MM/YYYY") : "-";
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        refreshSubpage() {
            //this.mtdGetData();
            this.subpage = 'table';
        },
        changeSubpage: function (subpage) {
            this.subpage = subpage;
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let selectPage = this.selectPage;
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>

<template>
  <div>
    <div class="container" align="center">
      <div id="div-modal-anular" class="w-md-100 w-lg-75 mt-5">
        <div id="div-anular" class="card w-100">
          <div class="card-header text-white h3" align="center" style="background: #900052">
            <strong>RESUMEN DEL PROCESO DE VENTA</strong>
          </div>
          <div class="card-body border-box-main px-0 py-0">
            <div id="row" class="row w-100 border-custom px-0">
              <div class="col border-right">
                <div class="row p-4">
                  <div class="col button-1">
                    <button id="contado" :class="bottonActiveP == 'contado'
                        ? 'myButton-div fw-bold active-div-btn'
                        : 'myButton-div fw-bold'
                      " @click="activarPago('contado')" :disabled="isGarantia">
                      CONTADO
                    </button>
                  </div>
                  <div class="col">
                    <button id="credito" :class="bottonActiveP == 'credito'
                        ? 'myButton-div fw-bold active-div-btn'
                        : 'myButton-div fw-bold'
                      " @click="activarPago('credito')" :disabled="isGarantia">
                      CREDITO
                    </button>
                  </div>
                  <div class="col">
                    <button :disabled="isGarantia == false" id="credito" :class="garantia
                        ? 'myButton-div fw-bold active-div-btn'
                        : 'myButton-div fw-bold'
                      " @click="activarGarantia('garantia')">
                      GARANTÍA
                    </button>
                  </div>
                  <div class="col button-1">
                    <button id="planilla" :class="bottonActiveP == 'planilla'
                        ? 'myButton-div fw-bold active-div-btn'
                        : 'myButton-div fw-bold'
                      " @click="activarPago('planilla')" :disabled="isGarantia">
                      PLANILLA
                    </button>
                  </div>
                </div>
                <div class="border-custom-text fw-bold" v-if="bottonActiveP == 'planilla'">
                  DESCUENTO PLANILLA
                </div>
                <div class="row" v-if="bottonActiveP == 'planilla'">
                  <div class="col-md-6 p-2">
                    <select class="from-control form-control-sm" v-model="planilla.user">
                      <option value="0">[Seleccione un usuario..]</option>
                      <option v-for="(item, index) in users" :key="index" :value="item.id">
                        {{ item.last_name }} {{ item.name }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-3 p-2">
                    <select class="from-control form-control-sm" v-model="planilla.aplazamiento">
                      <option value="0">[Aplazamiento...]</option>
                      <option v-for="(item, index) in planillaMes" :key="index" :value="index + 1">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-1 p-2">
                    <input type="text" class="form-control form-control-sm" v-on:keypress="isNumber($event)"
                      placeholder="Fraccionamiento" v-model="planilla.fracionamiento" />
                  </div>
                </div>
              </div>
              <div class="col" v-if="pago.type != 'credito'">
                <div id="row" class="row mt-3 mb-3 d-sm-flex justify-content-around">
                  <div class="d-sm-flex justify-content-end">
                    <i @click="mtdGetDataComprobantes" class="fas fa-recycle"></i>
                  </div>
                  <div class="d-sm-flex justify-content-around">
                    <div class="col-md-4" v-for="(comprobante, index) in dataDocument" :key="index">
                      <button v-bind:class="computedClass(comprobante.name)" v-on:click="activarTipo(comprobante.name)">
                        <div v-bind:class="activeClass(comprobante.name)">
                          <i class="far fa-file-invoice-dollar fa-2x mt-3"></i>
                        </div>
                        <div>{{ comprobante.name }}</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="input-group input-group-sm mb-3 b">
                    <div class="input-group-text">
                      <input :disabled="disabledChkDoc" class="form-check-input mt-0" @change="mtdSelectDoc"
                        type="checkbox" v-model="chkDoc" aria-label="Checkbox for following text input" />&nbsp;{{
                      chkDoc == true
                        ? "A nombre del paciente "
                        : "A nombre de: "
                    }}
                    </div>
                    <input type="text" class="form-control" v-on:keypress="isNumber($event)" @keyup="mtdSearchDocument"
                      :maxlength="bottonActiveT == 'BOLETA' ? 8 : 11" id="txtDoc" v-model="chkPatiente.document"
                      :disabled="chkDoc" placeholder="Documento de paciente" />
                    <input type="text" aria-label="Last name" id="txtDoc2" placeholder="Nombre de paciente" disabled
                      class="form-control" v-model="chkPatiente.name" />
                  </div>
                  <div class="input-group input-group-sm mb-3 b" v-if="bottonActiveT == 'FACTURA'">
                    <div class="input-group-text">Dirección</div>
                    <input type="text" class="form-control" id="txtDoc" v-model="chkPatiente.address" :disabled="true"
                      placeholder="Documento de paciente" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row w-100 border-custom" v-if="pago.type != 'credito'">
              <div id="row-3" class="row mt-2 mb-3 px-5">
                <div class="col-md-2">
                  <button id="efectivo" :class="bottonActiveM == 'efectivo'
                        ? 'myButton active-btn w-100'
                        : 'myButton w-100'
                      " @click="activarMedio('efectivo')" :disabled="isGarantia || isplanilla">
                    <div :class="bottonActiveM == 'efectivo'
                        ? 'myButton-icon active-ico'
                        : 'myButton-icon'
                      ">
                      <i class="far fa-money-bill-alt fa-2x mt-3"></i>
                    </div>
                    <div>Efectivo</div>
                  </button>
                </div>
                <div class="col-md-2">
                  <button id="tarjeta" :class="bottonActiveM == 'tarjeta'
                        ? 'myButton active-btn w-100'
                        : 'myButton w-100'
                      " @click="activarMedio('tarjeta')" :disabled="isGarantia || isplanilla">
                    <div :class="bottonActiveM == 'tarjeta'
                        ? 'myButton-icon active-ico'
                        : 'myButton-icon'
                      ">
                      <i class="fas fa-credit-card fa-2x mt-3"></i>
                    </div>
                    <div>Tarjeta</div>
                  </button>
                </div>
                <div class="col-md-2">
                  <button id="deposito" :class="bottonActiveM == 'deposito'
                        ? 'myButton active-btn w-100'
                        : 'myButton w-100'
                      " @click="activarMedio('deposito')" :disabled="isGarantia || isplanilla">
                    <div :class="bottonActiveM == 'deposito'
                        ? 'myButton-icon active-ico'
                        : 'myButton-icon'
                      ">
                      <i class="fas fa-money-check-alt fa-2x mt-3"></i>
                    </div>
                    <div>Deposito</div>
                  </button>
                </div>
                <div class="col-md-2">
                  <button id="transferencia" :class="bottonActiveM == 'transferencia'
                        ? 'myButton active-btn w-100'
                        : 'myButton w-100'
                      " @click="activarMedio('transferencia')" :disabled="isGarantia || isplanilla">
                    <div :class="bottonActiveM == 'transferencia'
                        ? 'myButton-icon active-ico'
                        : 'myButton-icon'
                      ">
                      <i class="fas fa-exchange-alt fa-2x mt-3"></i>
                    </div>
                    <div>Transferencia</div>
                  </button>
                </div>
                <div class="col-md-2">
                  <button id="planilla" :class="bottonActiveM == 'planilla'
                        ? 'myButton active-btn w-100'
                        : 'myButton w-100'
                      " @click="activarMedio('planilla')" :disabled="isGarantia || isplanilla == false">
                    <div :class="bottonActiveM == 'planilla'
                        ? 'myButton-icon active-ico'
                        : 'myButton-icon'
                      ">
                      <i class="fas fa-user-minus fa-2x mt-3"></i>
                    </div>
                    <div>Desc. planilla</div>
                  </button>
                </div>
                <div class="col-md-2">
                  <button id="mixto" :class="bottonActiveM == 'mixto'
                        ? 'myButton active-btn w-100'
                        : 'myButton w-100'
                      " :disabled="isplanilla" @click="activarMedio('mixto')">
                    <div :class="bottonActiveM == 'mixto'
                        ? 'myButton-icon active-ico'
                        : 'myButton-icon'
                      ">
                      <i class="fas fa-wallet fa-2x mt-3"></i>
                    </div>
                    <div>Mixto</div>
                  </button>
                </div>
              </div>
            </div>
            <div class="row w-100 mt-2 mb-3">
              <div class="row mt-1 px-5">
                <div class="col-md-12 mt-2">
                  <div class="d-md-flex justify-content-around">
                    <div class="mb-3" v-if="bottonActiveM == 'efectivo' || bottonActiveM == 'mixto'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">EFECTIVO</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('efectivo')" v-model="pago.amount.efectivo"
                        placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'efectivo' &&
                      bottonActiveP == 'contado'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">VUELTO</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text" disabled
                        v-model="pago.vuelto" placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <!-----------TARJETA----------------------->
                    <div class="mb-3" v-if="bottonActiveM == 'tarjeta' && bottonActiveP == 'contado'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">TARJETA</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text" readonly
                        @blur="mtdCalculate('tarjeta')" v-model="cpTarjeta" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'tarjeta' && bottonActiveP == 'credito'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">TARJETA</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('tarjeta')" v-model="pago.amount.tarjeta"
                        placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'mixto'">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">TARJETA</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('tarjeta')" v-model="pago.amount.tarjeta"
                        placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'deposito' &&
                      bottonActiveP == 'contado'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">DEPÓSITO</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-model="cpDeposito" aria-label="default input example" readonly />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'deposito' &&
                      bottonActiveP == 'credito'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">DEPÓSITO</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('deposito')" v-model="pago.amount.deposito"
                        placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'mixto'">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">DEPÓSITO</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('deposito')" v-model="pago.amount.deposito"
                        placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'transferencia' &&
                      bottonActiveP == 'contado'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">TRANSFERENCIA</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-model="cpTransferencia" aria-label="default input example" readonly />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'transferencia' &&
                      bottonActiveP == 'credito'
                      ">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">TRANSFERENCIA</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('transferencia')"
                        v-model="pago.amount.transferencia" placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'mixto'">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">TRANSFERENCIA</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('transferencia')"
                        v-model="pago.amount.transferencia" placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3" v-if="bottonActiveM == 'planilla' &&
                      bottonActiveP == 'planilla'
                      ">
                      <label for="exampleFormControlInput1" class="form-label border-custom-text-2 fw-bold">DESC.
                        PLANILLA</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text"
                        v-model="cpPlanilla" aria-label="default input example" readonly />
                    </div>
                    <div class="mb-3" v-if="bottonActiveP == 'credito'">
                      <label for="exampleFormControlInput1"
                        class="form-label border-custom-text-2 fw-bold">PENDIENTE</label>
                      <input style="text-align: center" class="form-control input-1 fw-bold" type="text" disabled
                        v-model="pago.pending" placeholder="S/0.00" aria-label="default input example" />
                    </div>
                    <div class="mb-3">
                      <label class="form-label border-custom-text-2 fw-bold">TOTAL</label>
                      <input style="text-align: center" class="form-control fw-bold input_total" readonly type="text"
                        v-model="totalPay" aria-label="default input example" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer text-white" style="background: #900052; font-size: 20px">
            <div class="row">
              <div class="col" align="left">
                <span class="btn border border-white text-white" @click="mtdBehindStep"><strong>ATRAS</strong></span>
              </div>
              <div align="right" class="col">
                <span class="btn border border-white text-white" @click="mdtCommitPayment"><strong>FINALIZAR
                    VENTA</strong></span>
              </div>
            </div>
          </div>
        </div>
        <br /><br />
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';


export default {
  name: "c-admision-pago",
  components: {
    SweetAlert
  },
  data() {
    return {
      swal: null,
      users: [],
      isplanilla: false,
      payment1: {},
      totalPay: 0.00,
      totalEnd: 0.00,
      bottonActiveM: "",
      bottonActiveT: "",
      bottonActiveP: "",
      filter: {
        filter_area_name: "ADMISION",
      },
      dataDocumentType: [],
      disabledChkDoc: true,
      garantia: false,
      chkDoc: false,
      chkPatiente: {
        document: "",
        name: "",
        address: "",
      },
      planilla: {
        user: 0,
        aplazamiento: 0,
        fracionamiento: "",
      },
      pago: {
        document: "",
        type: "",
        amount: {
          efectivo: 0,
          tarjeta: 0,
          deposito: 0,
          transferencia: 0,
          planilla: 0,
        },
        pending: 0,
        vuelto: 0,
        payment_patient: null,
        payment_eps: 0,
      },
      borde: false,
      procede: true,
      planillaMes: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      impuestos: {
        detraccion: false,
        retencion: false,
        factor: 0,
      },
      isGarantia: false,
      selectedVoucherCancel: null,
      voucherCancels: [],
      dataDocument: [
        { name: 'BOLETA' },
        { name: 'FACTURA' },
        { name: 'TICKET' },
      ],
    };
  },
  computed: {
    cpTarjeta() {
      return this.totalPay;
    },
    cpDeposito() {
      return this.totalPay;
    },
    cpPlanilla() {
      return this.totalPay;
    },
    cpTransferencia() {
      return this.totalPay;
    },
    cpPagoPaciente() {
      let pago = (this.totalEnd * this.pago.payment_patient) / 100;
      let totalPay = pago.toFixed(2);
      // No modifiques this.totalPay aquí, solo calcula su valor y devuélvelo
      return totalPay;
    },
  },

  props: {
    castCarrito: Array,
    client: Object,
    permissionValue: Number,
    dsc: {
    type: [Number, String],
    },
    montodescuento: {
    type: [Number, String],
    }
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData() {
      let tottal = 0;
      this.castCarrito.forEach((element) => {
        //tottal += parseFloat(element.price) * parseFloat(element.quantity);
        tottal += parseFloat(element.subtotal);
      });
      tottal=tottal/1.18;
      tottal= tottal-parseFloat(this.montodescuento);
       //let descuento= parseFloat(this.dsc).toFixed(2);
       this.totalEnd = parseFloat(tottal+(tottal*0.18)).toFixed(2);
      if (this.client.type == "eps") {
        this.borde = true;
        this.totalPay = this.totalEnd;
      } else {
        this.totalPay = this.totalEnd;
      }
      this.pago.payment_patient = 100 - this.client.copago;
      this.pago.payment_eps = this.client.copago;
      this.mtdGetDataComprobantes();
      //activar garantia directo
      if (this.castCarrito.length == 1) {
        if (this.castCarrito[0].id == 13061) {
          this.isGarantia = true;
          this.activarGarantia("garantia");
        }
      }
    },
    mtdGetDataComprobantes() {
      this.post({
        url: this.$store.getters.get__url + "/VoucherType/filter",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          this.dataDocumentType = response.data;
          this.users = response.data.users;
        })
    },
    computedClass(comprobante) {
      return this.bottonActiveT == comprobante
        ? "myButton active-btn w-75"
        : "myButton w-75";
    },
    activeClass(comprobante) {
      return this.bottonActiveT == comprobante
        ? "myButton-icon-2 active-ico"
        : "myButton-icon-2";
    },
    activarMedio(value) {
      if (this.pago.document != "") {
        this.pago.method = value;
        this.bottonActiveM = value;
        this.pago.amount = {
          efectivo: 0,
          tarjeta: 0,
          deposito: 0,
          transferencia: 0,
          planilla: 0,
        };
        this.pago.pending = 0;
        this.pago.vuelto = 0;
        if (value != "mixto") {
          this.garantia = false;
        }
        this.mtdCalculate(this.pago.method);
      }
      else {
        this.$refs.SweetAlert.showWarning("Escoga un tipo Comprobante");
      }

    },
    mtdSelectDoc() {
      if (this.chkDoc) {
        this.chkPatiente.document = this.client.document;
        this.chkPatiente.name = this.client.name;

      } else {
        this.chkPatiente.document = "";
        this.chkPatiente.name = "";
      }
    },
    activarTipo(value) {
      if (this.pago.type != "") {
        this.bottonActiveT = value;
        this.pago.document = value;
        if (value == "BOLETA" || value == "FACTURA") {
          this.disabledChkDoc = false;

        } else {
          this.disabledChkDoc = true;
        }
        this.chkDoc = true;
        this.mtdSelectDoc();
        if (Object.keys(this.payment1).length > 0) {
          this.payment1.document = value;
        }
        if (this.isGarantia == true) {
          this.activarMedio("mixto");
        }
      } else {
        this.$refs.SweetAlert.showWarning("Escoga un tipo de Pago Por Favor");
      }
    },
    activarPago(value) {
      if (value == 'planilla') {
        this.bottonActiveP = 'planilla';
        this.pago.type = 'contado';
        this.isplanilla = true;
      } else {
        this.bottonActiveP = value;
        this.pago.type = value;
        this.garantia = false;
        this.isplanilla = false;
        if (value == 'credito') {
          let total = parseFloat(this.totalPay).toFixed(2);
          this.pago.method = 'credito';
          this.chkDoc = true;
          this.mtdSelectDoc();
          this.pago.pending = total;
        }
        if (Object.keys(this.payment1).length > 0) {
          this.payment1.type = value;
        }
      }
    },
    activarGarantia(values) {
      this.garantia = !this.garantia;
      this.bottonActiveP = values;
      if (this.garantia) {
        this.activarMedio("mixto");
        this.pago.type = 'garantia';
      }
    },
    mtdCalculate(input) {
      if (this.pago.method != "") {
        let acum = 0;
        this.procede = false;
        let copy;
        //metdodos
        if (input == "efectivo") {
          acum = parseFloat(this.pago.amount.efectivo);
        } else if (input == "tarjeta") {
          acum = this.totalPay;
          if (this.pago.method == "tarjeta") {
            this.pago.amount.tarjeta = this.totalPay;
          }
        } else if (input == "deposito") {
          acum = this.totalPay;
          if (this.pago.method =="deposito") {
            this.pago.amount.deposito = this.totalPay
          };
        } else if (input == "transferencia") {
          acum = this.totalPay;
          if (this.pago.method == "transferencia") {
            this.pago.amount.transferencia = this.totalPay;
          }
        } else if (input == "planilla") {
          acum = parseFloat(this.totalPay);
          if (this.pago.method == "planilla") {
            this.pago.amount.planilla =this.totalPay;
          }
        } else if (input == "mixto") {
          acum =
            parseFloat(this.pago.amount.efectivo) +
            parseFloat(this.pago.amount.tarjeta) +
            parseFloat(this.pago.amount.deposito) +
            parseFloat(this.pago.amount.transferencia) +
            parseFloat(this.pago.amount.planilla);
        }
        let dif = parseFloat(acum - this.totalPay);
        if (this.isGarantia) {
          let acum1 =
            parseFloat(this.pago.amount.efectivo) +
            parseFloat(this.pago.amount.tarjeta) +
            parseFloat(this.pago.amount.deposito) +
            parseFloat(this.pago.amount.transferencia) +
            parseFloat(this.pago.amount.planilla);
          if (acum1 > 0) {
            copy = { ...this.pago };
            this.payment1 = copy;
            this.procede = true;
          } else {
            this.procede = false;
          }
        }
        if (this.pago.method == 'mixto'
          && this.isGarantia == false) {
          let acum2 = 0;
          let total = 0;
          let dif1 = 0;
          acum2 =
            parseFloat(this.pago.amount.efectivo) +
            parseFloat(this.pago.amount.tarjeta) +
            parseFloat(this.pago.amount.deposito) +
            parseFloat(this.pago.amount.transferencia) +
            parseFloat(this.pago.amount.planilla);
          total = parseFloat(this.totalPay);
          dif1 = parseFloat(acum2 - total);
          if (dif1 >= 0) {
            this.procede = true;
            copy = { ...this.pago };
            this.payment1 = copy;
          } else {
            this.procede = false;
          }
        }
        //un solo metodo de  pago
        if (input == "efectivo" && this.pago.method != 'mixto'
          && this.isGarantia == false) {
          if (dif >= 0 && this.pago.type == "contado") {
            this.procede = true;
            this.pago.vuelto = parseFloat(dif).toFixed(2);
            copy = { ...this.pago };
            this.payment1 = copy;
          } else {
            this.procede = false;
          }
        }
        if (
          (input == "tarjeta" ||
            input == "deposito" ||
            input == "transferencia")
          && this.pago.method != 'mixto'
          && this.isGarantia == false
        ) {
          if (this.pago.type == "contado") {
            if (dif >= 0) {
              this.procede = true;
              copy = { ...this.pago };
              this.payment1 = copy;
            } else {
              this.procede = false;
            }
          }
        }
        if (input == "planilla"
          && this.pago.method != 'mixto'
          && this.isGarantia == false) {
          if (dif >= 0 && this.pago.type == "contado") {
            this.procede = true;
            copy = { ...this.pago };
            this.payment1 = copy;
          } else {
            this.procede = false;
          }
        }
      }
      else {
        this.$refs.SweetAlert.showWarning("Escoga un tipo Metodo de Pago");
      }
    },
    calculateImpuestos: function () {
      if (this.pago.document == "FACTURA") {
        let reten = 0;
        let detra = 0;
        let arrService = [];
        this.castCarrito.forEach((element) => {
          if (element.categorie.type == "service") {
            detra += parseFloat(element.price * element.quantity);
            arrService.push(element.categorie.id);
          } else {
            reten += parseFloat(element.price * element.quantity);
          }
        });
        if (parseFloat(detra).toFixed(2) > parseFloat(700).toFixed(2)) {
          this.impuestos.detraccion = true;
          this.impuestos.retencion = false;
          if (this.client.type == "particular") {
            this.impuestos.factor =
              arrService.length == 1
                ? arrService[0] == 16
                  ? 0.9
                  : 0.88
                : 0.88;
            this.totalPay = parseFloat(
              this.totalPay * this.impuestos.factor
            ).toFixed(2);
          } else {
            this.impuestos.factor = 0.88;
            this.totalPay = parseFloat(this.totalPay * 0.88).toFixed(2);
          }
        } else if (parseFloat(reten) > 700) {
          this.impuestos.detraccion = false;
          this.impuestos.retencion = true;
          this.impuestos.factor = 0.97;
          this.totalPay = parseFloat(this.totalPay * 0.97).toFixed(2);
        } else {
          this.totalPay = this.totalEnd;
          this.impuestos.factor = 0;
          this.impuestos.detraccion = false;
          this.impuestos.retencion = false;
        }
      } else {
        this.totalPay = this.totalEnd;
      }
    },
    mdtCommitPayment() {
      if (this.pago.type == "credito") {
        this.$emit(
          "mdtCommitPayment",
          this.pago,
          this.chkPatiente,
          this.planilla,
          this.garantia,
          this.impuestos,
          this.selectedVoucherCancel,
        );
      } else {
        if (this.bottonActiveP.length > 0 && this.bottonActiveT.length > 0 && this.mtdVerifmedio()) {
          if (this.procede == true) {
            if (this.pago.method == "planilla" || (this.isGarantia == true && this.pago.amount.planilla > 0)
              || (this.pago.method == "mixto" && this.pago.amount.planilla > 0)) {
              if (this.planilla.user != 0 && this.planilla.aplazamiento != 0 &&
                this.planilla.fracionamiento != "") {
                this.$emit(
                  "mdtCommitPayment",
                  this.payment1,
                  this.chkPatiente,
                  this.planilla,
                  this.garantia,
                  this.impuestos,
                  this.selectedVoucherCancel,
                );
              } else {
                this.$refs.SweetAlert.showWarning("Seleccione Planilla");
              }
            } else {
              this.$emit(
                "mdtCommitPayment",
                this.payment1,
                this.chkPatiente,
                this.planilla,
                this.garantia,
                this.impuestos,
                this.selectedVoucherCancel,
              );
            }
          } else {
            this.$refs.SweetAlert.showWarning("El monto ingresado no es válido");
          }
        } else if (this.bottonActiveP.length <= 0) {
          this.$refs.SweetAlert.showWarning("Escoga un tipo de Pago");
        } else if (this.bottonActiveT.length <= 0) {
          this.$refs.SweetAlert.showWarning("Escoga un tipo de Comprobante");
        } else {
          this.$refs.SweetAlert.showWarning("Escoga un medio de Pago");
        }
      }
    },
    mtdVerifmedio: function () {
      if (this.garantia) return true;
      if (this.bottonActiveM != "" && this.pago.method != "") return true;
      return false;
    },
    mtdBehindStep: function () {
      this.$emit("mtdBehindProcess", this.castCarrito);
    },
    mtdSearchDocument: function () {
      if (this.bottonActiveT == "BOLETA") {
        if (this.chkPatiente.document.length == 8 && this.chkDoc == false) {
          this.get({
            url: this.$store.getters.get__url + "/Consultas/" +
              this.chkPatiente.document,
            token: this.$store.getters.get__token,
          })
            .then((response) => {
              if (response.data.boo == 3) {
                this.client.document = "";
                this.$refs.SweetAlert.showWarning("DNI no encontrado");
              } else {
                this.chkPatiente.name =
                  response.data.name + " " + response.data.last_name;
              }
            })
        }
      } else if (this.bottonActiveT == "FACTURA") {
        if (this.chkPatiente.document.length == 11 && this.chkDoc == false) {
          this.get({
            url:
              this.$store.getters.get__url +
              "/Consultas/" +
              this.chkPatiente.document,
            token: this.$store.getters.get__token,
          })
            .then((response) => {
              if (response.data.boo == 3) {
                this.client.document = "";
                this.$refs.SweetAlert.showWarning("RUC no encontrado");
              } else {
                this.chkPatiente.name = response.data.razon_social;
                this.chkPatiente.address = response.data.direccion;
              }
            })
        }
      }
    },

    /*** heplpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    filterKey: function (e) {
      let b = false;
      b = /^[0-9]$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
#btn-close {
  width: 49%;
}

.btn-register {
  background: rgb(144, 0, 82);
}

.close {
  cursor: pointer;
}

.modal-header {
  background: rgb(144, 0, 82);
}

.input-1 {
  border: 1.5px solid #9c9a9b;
  color: #9c9a9b;
}

.btn-fin {
  border: none !important;
}

.border-custom-text-2 {
  color: #900052;
  text-align: left !important;
  width: 150px !important;
}

.input_total {
  border: 1.5px solid #900052;
  color: #900052;
}

.myButton-div {
  border: 1.5px solid #9c9a9b;
  background-color: transparent;
  color: #9c9a9b;
  border-radius: 10px;
  width: 100px;
  padding: 4px 5px 4px 5px;
}

.myButton-div:hover {
  border: 1.5px solid #900052;
  color: #900052;
}

.border-custom {
  border-bottom: 1.5px solid #900052;
}

.border-custom-text {
  color: #900052;
}

.border-right {
  border-right: 1.5px solid #900052;
}

.myButton {
  border: none;
  background-color: transparent;
  color: #9c9a9b;
  align-content: center !important;
}

.active-btn {
  border-color: #900052 !important;
  color: #900052 !important;
}

.active-div-btn {
  border: 2.5px solid #900052 !important;
  color: #900052 !important;
  background-color: #f1f1f1;
}

.active-ico {
  border-color: #900052 !important;
  color: #900052 !important;
  border: 2.5px solid #900052 !important;
  background-color: #f1f1f1;
}

.myButton-icon {
  border: 1.5px solid #9c9a9b;
  border-radius: 15px;
  height: 65px;
  margin: 0px 35px 0px 35px;
}

.myButton-icon-2 {
  border: 1.5px solid #9c9a9b;
  border-radius: 15px;
  height: 65px;
  margin: 0px 10px 0px 10px;
}

.myButton:hover .myButton-icon {
  border-color: #900052;
}

.myButton:hover div {
  color: #900052;
}

.myButton:hover .myButton-icon-2 {
  border-color: #900052;
}

.myButton:hover div {
  color: #900052;
}

.form-control {
  width: 150px !important;
}

.start-rounded {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.end-rounded {
  width: 150px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

#form-input {
  border-radius: 0px !important;
}

.grupo {
  border-radius: 0px !important;
}

@media (max-width: 1199px) {
  .myButton-icon {
    margin: 0px 20px 0px 20px;
  }

  .myButton-icon-2 {
    margin: 0px 3px 0px 3px;
  }

  #row-3 {
    align-content: center;
    margin: 0;
  }
}

@media (max-width: 991px) {
  #row-3 {
    flex-wrap: wrap;
  }

  .col {
    flex-basis: 50%;
  }

  #row {
    flex-direction: column;
  }

  .border-right {
    border-right: none;
    border-bottom: 1.5px solid #900052;
  }

  #row-3 {
    align-content: center;
    margin: 0;
  }
}

@media (max-width: 767px) {
  #row-2 {
    flex-direction: column;
    align-content: center;
  }

  #row-3 {
    align-content: center;
    margin: 0;
  }

  .myButton-icon-2 {
    margin: 0px -11px 0px -11px;
  }

  .myButton-icon {
    margin: 0px 70px 0px 70px;
  }
}

@media (max-width: 575px) {
  .myButton-icon {
    margin: 0px 78px 0px 78px;
  }
}

@media (max-width: 531px) {
  .myButton-icon {
    margin: 0px 0px 0px 0px;
  }

  .myButton-icon-2 {
    margin: 0px -40px 0px -40px;
  }
}

@media (max-width: 414px) {
  .col2 {
    padding: 0px 15px 0px 15px;
  }

  .button-1 {
    margin-bottom: 15px !important;
  }
}

#txtDoc {
  width: 1px !important;
  border-radius: 0px !important;
}

#txtDoc2 {
  border-radius: 0px !important;
}

.alert-900052 {
  background-color: #900052;
  color: #fff;
  padding: 8px 15px;
  border-radius: 5px;
}
</style>
<template>
<div class="container">
    <div class="row d-flex justify-content-center mt-3">
        <div class="col-lg-4 mb-3">
            <section class="card">
                <header class="card-header bg-main text-white">
                    <h6 class="card-title">Datos del paciente</h6>
                </header>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label label-modal">Paciente</label>
                                <input class="form-control" v-model="patient.name" placeholder="Paciente" disabled />
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label label-modal">Medico tratante</label>
                                <input class="form-control" v-model="patient.doctor_name" placeholder="Doctor" disabled />
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label class="col-form-label label-modal">Examen</label>
                                <input class="form-control" type="file" @change="handleFileUpload" name="file" id="file" ref="file" />
                            </div>
                        </div>
                        <div class="row mx-0 px-0 mt-2 col-lg-12">
                            <div class="col-xl-4" v-for="(file, index) in allFiles" :key="index">
                                <div @click="mtddelete(index, 'removeFile')">
                                    <template v-if="isImage(file.name)">
                                        <img alt="img" :src="getFilePreview(file)" style="max-width: 70px; max-height: 70px" />
                                    </template>
                                    <template v-else-if="isPDF(file.name)">
                                        <i class="far fa-file-pdf" style="color: red; font-size: 60px"></i>
                                    </template>
                                    <template v-else>
                                        <i class="far fa-file"></i>
                                    </template>
                                    <div class="text-xs">{{ file.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-lg-4">
            <section class="card">
                <header class="card-header bg-main text-white">
                    <h6 class="card-title">Informe</h6>
                </header>
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <div class="justify-contend-start">
                                    <span>Ojo derecho</span>
                                </div>
                                <div class="row d-flex justify-content-between">
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="
                          dataSend.jsonData.informe.ojo_derecho
                            .errores_falsos_positivos
                        " name="g_od" class="form-control text-xs" placeholder="Errores falsos positivos" />
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="
                          dataSend.jsonData.informe.ojo_derecho
                            .errores_falsos_negativos
                        " name="g_od" class="form-control text-xs" placeholder="Errores falsos negativos" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-2">
                                <div class="justify-contend-start">
                                    <span>Ojo izquierdo</span>
                                </div>
                                <div class="row d-inline d-flex justify-content-between">
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="
                          dataSend.jsonData.informe.ojo_izquierdo
                            .errores_falsos_positivos
                        " name="g_od" class="form-control text-xs" placeholder="Errores falsos positivos" />
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="
                          dataSend.jsonData.informe.ojo_izquierdo
                            .errores_falsos_negativos
                        " name="g_od" class="form-control text-xs" placeholder="Errores falsos negativos" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-2">
                                <div class="justify-contend-start">
                                    <span>DM</span>
                                </div>
                                <div class="row d-inline d-flex justify-content-between">
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="dataSend.jsonData.informe.dm.ojo_derecho" name="g_od" class="form-control text-xs" placeholder="Ojo derecho" />
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="dataSend.jsonData.informe.dm.ojo_izquierdo" name="g_od" class="form-control text-xs" placeholder="Ojo izquierdo" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-2">
                                <div class="justify-contend-start">
                                    <span>DSM</span>
                                </div>
                                <div class="row d-inline d-flex justify-content-between">
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="dataSend.jsonData.informe.dsm.ojo_derecho" name="g_od" class="form-control text-xs" placeholder="Ojo derecho" />
                                    </div>
                                    <div class="col-md-6 mt-2">
                                        <input type="text" v-model="dataSend.jsonData.informe.dsm.ojo_izquierdo" name="g_od" class="form-control text-xs" placeholder="Ojo izquierdo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-lg-4">
            <section class="card">
                <header class="card-header bg-main text-white d-flex justify-content-center">
                    <h6 class="card-title">Conclusiones</h6>
                </header>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-striped mb-0">
                            <caption></caption>
                            <thead>
                                <tr>
                                    <th>Numero</th>
                                    <th>Conclusion</th>
                                    <th>Ojo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colspan="3" class="text-center">
                                        No se puede registrar conclusiones
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
        <div class="col-lg-5 col-md-3 btn-home my-3">
            <button class="btn btn-block w-100" :disabled="patient.id !== '523'" @click="mtdaddData">
                Guardar
            </button>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @removeFile="removeFile" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import Multiselect from "vue-multiselect";
import "vue-select/dist/vue-select.css";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
    name: "c-enfermera-campovisual_dx",
    components: {
        SweetAlert,
        Multiselect,
    },
    data() {
        return {
            page: "campovisual_dx",
            stateHistory: null,
            viewInput: null,
            swal: null,
            allFiles: [],
            dataSend: {
                option: "visual_field",
                reservation_id: this.patient.reservation_id,
                jsonData: {
                    userRegister: this.$store.getters.get__user.name,
                    dateCreated: new Date().toLocaleDateString(),
                    patient: {
                        patient: {
                            patient_id: this.patient.patient_id,
                        },
                        doctor: {
                            doctor_id: this.patient.doctor_id,
                        },
                        indicacion: {
                            name: "indicacion",
                            value: "",
                        },
                        examen: {
                            name: "examen",
                            value: [],
                        },
                    },
                    conclusiones: {
                        name: "conclusiones",
                        value: [],
                    },
                    informe: {
                        ojo_derecho: {
                            errores_falsos_positivos: "",
                            errores_falsos_negativos: "",
                        },
                        ojo_izquierdo: {
                            errores_falsos_positivos: "",
                            errores_falsos_negativos: "",
                        },
                        dm: {
                            ojo_derecho: "",
                            ojo_izquierdo: "",
                        },
                        dsm: {
                            ojo_derecho: "",
                            ojo_izquierdo: "",
                        },
                    },
                },
            },
            optionsConclusion: [],
        };
    },
    props: {
        stateRolAdmin: Boolean,
        patient: Object,
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetStyle: function (option) {
            if (option == this.page) return "background: #900052";
            return "";
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return "color:white";
            return "";
        },
        returnHome() {
            this.$emit("returnHome");
        },
        handleFileUpload(event) {
            const files = event.target.files;
            const allowedFormats = ["pdf", "png", "jpg", "jpeg"];
            const invalidFiles = [];

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const fileExtension = file.name.split(".").pop().toLowerCase();
                const fileAlreadyExists = this.allFiles.some(
                    (existingFile) => existingFile.name === file.name
                );
                if (fileAlreadyExists) {
                    continue;
                }
                if (allowedFormats.includes(fileExtension)) {
                    this.allFiles.push(file);
                } else {
                    invalidFiles.push(file.name);
                }
            }
            if (invalidFiles.length > 0) {
                let message;
                if (invalidFiles.length === 1) {
                    message = `El archivo ${invalidFiles[0]} tiene un formato no permitido.`;
                } else {
                    message = `Los siguientes archivos tienen formatos no permitidos:\n\n${invalidFiles.join(
            "\n"
          )}`;
                }
                this.$refs.SweetAlert.showWarning(message);

                // Limpiar el input
                // event.target.value = '';
            }
        },
        isImage(fileName) {
            const ext = fileName.split(".").pop().toLowerCase();
            return ext === "png" || ext === "jpg" || ext === "jpeg";
        },
        isPDF(fileName) {
            const ext = fileName.split(".").pop().toLowerCase();
            return ext === "pdf";
        },
        getFilePreview(file) {
            return URL.createObjectURL(file);
        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);
        },
        removeFile(indexToRemove) {
            this.allFiles.splice(indexToRemove, 1);
        },
        mtd_add: function () {
            let conclusion = {
                conclusion: "",
                numero: "",
                ojo: "OD",
            };
            this.dataSend.jsonData.details.conclusiones =
                this.dataSend.jsonData.details.conclusiones.concat(conclusion);
        },
        mtd_delete: function (index) {
            this.dataSend.jsonData.details.conclusiones.splice(index, 1);
        },
        mtdaddData: function (event) {
            this.post({
                    url: this.$store.getters.get__url + "/Enfermera/store",
                    token: this.$store.getters.get__token,
                    params: this.dataSend,
                })
                .then((response) => {
                    if (!response.error) {

                        if (this.allFiles.length > 0) {
                            this.mtdStoreFile(response.data);
                        } else {
                            this.$refs.SweetAlert.showSuccess(response.message);
                            response.data == 0 ? this.returnHome() : this.returnReadingResult();
                        }
                    } else {
                        this.$refs.SweetAlert.showWarning(response.data.error_message);
                    }
                    //this.oct = response.data;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdStoreFile: function (resp) {
            let formData = new FormData();
            formData.append("length", this.allFiles.length);
            formData.append("option", this.dataSend.option);
            formData.append("reservation_id", this.dataSend.reservation_id);
            this.allFiles.forEach((element, index) => {
                let temp = [];
                temp.push(element);
                formData.append("file_" + index, this.mtdArrayToFileList(temp)[0]);
            });
            this.post({
                    url: this.$store.getters.get__url + "/Enfermera/storeFiles",
                    token: this.$store.getters.get__token,
                    params: formData,
                })
                .then((response) => {
                    if (!response.error) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.allFiles = [];
                        resp == 0 ? this.returnHome() : this.returnReadingResult();
                    } else {
                        this.$refs.SweetAlert.showWarning(response.data.error_message);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdArrayToFileList: function (arr) {
            const fileList = new DataTransfer();
            arr.forEach((file) => fileList.items.add(file));
            return fileList.files;
        },
        /** select conclusion */
        searchConclusion: function (search, loading) {
            this.optionsConclusion = [];
            if (search.length > 3) {
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Enfermera/conclusion/" + search + "/campo_visual",
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.optionsConclusion = response.data;
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        returnReadingResult: function () {
            this.$emit("returnReadingResult", this.patient);
        }
    },
};
</script>

<template>
  <div>
    <div class="w-100 h-100">
      <Nav :rolname="rolname" />
    </div>
    <div class="content">
      <Home v-if="verifBead == true && page == 
      'home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
      <Venta v-if="verifBead == true && page == 'venta'"
       @mtdChangePage="mtdChangePage" :bead="bead" 
       :client="client"
        @mtdCommitPayment="mtdCommitPayment" />
      <Close v-if="verifBead == true && page == 'reporteClose'"
       @mtdChangePage="mtdChangePage" :bead="bead"
        @mtdBackCampus="mtdBackCampus" />
      <Open :boxes="boxes" v-if="verifBead == false" 
      @mtdOpen="mtdOpen" 
      @mtdBackCampus="mtdBackCampus" />

      <Recetas v-if="verifBead == true && page == 'recetas'"
       @mtdChangePage="mtdChangePage" 
       :stateRolAdmin="stateRolAdmin"
       :bead="bead" 
       />
      <RequirementVue v-if="page == 'requirement'" 
      @mtdChangePage="mtdChangePage"
       :stateRolAdmin="stateRolAdmin" />
    </div>
    <div class="footer">
      <Footer @mtdChangePage="mtdChangePage" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Nav from "../../components/Farmacia/Nav.vue";
import Home from "../../components/Farmacia/Home.vue";
import Recetas from "../../components/FarmaciaOjos/RecetasView.vue";
import Venta from '../../components/Farmacia/Venta.vue'
import Footer from "../../components/Farmacia/Footer.vue";
import Close from '../../components/Farmacia/ReporteClose.vue'
import Open from '../../components/Farmacia/Open.vue'
import RequirementVue from "@/components/Farmacia/Requirement.vue";

export default {
  name: "CallView",
  components: {
    Nav,
    Home,
    Recetas,
    Footer,
    RequirementVue,
    Venta,
    Close,
    Open
  },
  data() {
    return {
      prefix: "Admision",
      page: "home",
      key:0,
      rol_name: null,
      stateRolAdmin: false,
      client: null,
      bead: {},
      verifBead: false,
      boxes: [],
      reserId: "",
      date: "",
      serId: "",
      patientId: "",
      hour :"",
    };
  },
  props: {
    rolname: String,
    dataRoles: Array,
  },
  created() {
    this.rol_name = this.rolname;
    this.mtdGetRolAdmin();
    this.mtd_getData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    // mtdChangePage: function (page) {
    //   this.page = page;
    // },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdGetRolAdmin: function () {
      this.dataRoles.forEach((element) => {
        if (element.role_id == 1) {
          this.stateRolAdmin = true;
        }
      });
    },
    mtd_getData: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/campu/" +
          this.$store.getters.get__campus,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.boxes = response.boxes;
          this.bead = response.bead;
          this.verifBead = response.bead != null ? true : false;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    mtdCommitBead(bead) {
      this.bead = bead;
    },
    mtdChangePage: function (page, client) {
      this.client = page == "venta" ? client : page == "home" ? this.client : null;
      this.page = page;
    },
    mtdCommitPayment(res) {
      this.bead = res;
      this.page = "home";
    },
    mtdOpen: function (data) {
      this.bead = data;
      this.verifBead = true;
      this.page = "home";
    },
  },
};
</script>
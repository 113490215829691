<template>
<div>
    <div class="container">
        <div id="btn-open-caja" class="d-flex justify-content-end mt-3">
            <div class="d-flex btn-home" role="group">
                <button type="button" class="btn btn-outline-main btn-bm-noradius" @click="mtdBackCampus">
                    <i class="fas fa-recycle"></i> <br />Cambiar de rol
                </button>
            </div>
            &nbsp;
            <div id="btn-open-caja" class="d-flex justify-content-end">
                <div class="btn-group btn-home" role="group">
                    <button type="button" class="btn btn-bm-noradius dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-cash-register"></i><br />Abrir
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="(item, index) in boxes" :key="index">
                            <a class="dropdown-item" href="javascript:void(0)" @click="mtdSelectBox(item)">{{ item.name }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-if="verif == true" id="div-modal-caja" class="d-flex justify-content-center mt-2" style="height: auto">
            <div id="div-caja" class="card w-50" style="height: auto">
                <div class="card-header text-white h4" style="background: #900052">
                    <strong>Arqueo</strong>
                    <span class="btn btn-sm float-end" style="background: white; color: #900052; font-weight: 1em">
                        <strong>
                            Caja inicial: S/
                            {{ parseFloat(process.initial).toFixed(2) }}</strong>
                    </span>
                </div>
                <div class="card-body" style="height: auto">
                    <div class="row justify-content-center">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                            <div class="mb-3">
                                <label for="observation" class="form-label"><strong>Observación</strong></label>
                                <textarea v-model="process.observation" :readonly="this.inconforme==false" class="form-control" rows="1" placeholder="Observación" style="resize: none">
                  </textarea>
                            </div>
                        </div>
                        <div class="row justify-content-center center-horizontal">
                            <div class="col-md-12 col-lg-12 col-xl-12 col-sm-12">
                                <p><strong> TOTAL EFECTIVO </strong></p>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-4 col-sm-12 mb-1">
                                <button class="btn btn-sm w-100" style="background: #900052; color: white">
                                    INGRESOS: S/. {{ parseFloat(process.totIncome).toFixed(2) }}
                                </button>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-4 col-sm-12 mb-1">
                                <button class="btn btn-sm w-100" style="background: #900052; color: white">
                                    EGRESOS: S/. {{ parseFloat(process.totExpense).toFixed(2) }}
                                </button>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-4 col-sm-12 mb-3">
                                <button class="btn btn-sm w-100" style="background: #900052; color: white">
                                    TOTAL:S/. {{ parseFloat(process.totEfectivo).toFixed(2) }}
                                </button>
                            </div>
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" v-if="process.vueltos.length > 0">
                                <table class="w-100">
                                    <tbody>
                                        <tr v-for="(item, index) in process.vueltos" :key="index">
                                            <td class="border h-30" width="60%">Concepto de vuelto</td>
                                            <td colspan="2" class="border h-30" width="40%">Monto</td>
                                        </tr>
                                        <tr class="">
                                            <td></td>
                                            <td class="border" style="background: #900052; color: white">
                                                TOTAL
                                            </td>
                                            <td class="border">00.00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row mt-1 center-horizontal">
                            <div v-for="(item, index) in process.billetes" :key="index" class="col-md-12 col-lg-12 col-xl-6 col-sm-12 col-xs-12">
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text col-4">{{ item.label }}</span>
                                    <input type="text" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                    <span class="input-group-text">{{ item.total }}</span>
                                </div>
                            </div>
                            <div v-for="(item, index) in process.monedas" :key="index + 100" class="col-md-12 col-lg-12 col-xl-6 col-sm-12 col-xs-12">
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text col-4">{{ item.label }}</span>
                                    <input type="text" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="item.cantidad" />
                                    <span class="input-group-text">{{ item.total }}</span>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-12 col-xl-6 col-sm-12 col-xs-12">
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text col-4"><strong>TOTAL</strong></span>
                                    <input align="right" type="text" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" :value="parseFloat(process.totEfectivo).toFixed(2)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-arround">
                        <div class="input-group input-group-sm mb-3 p-2">
                            <span class="input-group-text" style="background: #900052; color: white">Falta</span>
                            <input type="text" v-model="process.falta" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" :placeholder="cpSobranteFaltante < 0 ? cpSobranteFaltante : '0.00'" />
                        </div>
                        <div class="input-group input-group-sm mb-3 p-2">
                            <span class="input-group-text" style="background: #900052; color: white">Sobra</span>
                            <input type="text" v-model="process.sobra" readonly class="form-control form-control-sm" aria-label="Amount (to the nearest dollar)" :placeholder="cpSobranteFaltante >= 0 ? cpSobranteFaltante : '0.00'" />
                        </div>

                        <div id="btn-inconforme" class="input-group input-group-sm mb-3 p-2 text-center float-center" v-if="process.status != 0">
                            <button class="btn btn-sm w-100" style="background: #900052; color: white">
                                Cerrado {{ process.status == 1 ? "Conforme" : "Inconforme" }}
                            </button>
                        </div>
                    </div>
                    <div class="d-flex justify-content-arround">
                        <div class="input-group input-group-sm mb-3 p-2 text-center float-center">
                            <button @click="mtdOpenBead(1)" class="btn btn-sm w-100" style="background: #900052; color: white">
                                Abrir Inconforme
                            </button>
                        </div>
                        <div class="input-group input-group-sm mb-3 p-2 text-center float-center">
                            <button @click="mtdOpenBead(0)" class="btn btn-sm w-100" style="background: #900052; color: white">
                                Abrir Conforme
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

  
<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-admision-open",
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            inconforme: false,
            verif: false,
            process: {
                box: 0,
                bead: 0,
                falta: 0,
                sobra: 0,
                arqueo: [{
                        label: "S/ 200.00x",
                        total: 0,
                        key: "dosCientos",
                        value: 0
                    },
                    {
                        label: "S/ 100.00x",
                        total: 0,
                        key: "unCiento",
                        value: 0
                    },
                    {
                        label: "S/ 50.00x",
                        total: 0,
                        key: "cinuenta",
                        value: 0
                    },
                    {
                        label: "S/ 20.00x",
                        total: 0,
                        key: "veinte",
                        value: 0
                    },
                    {
                        label: "S/ 10.00x",
                        total: 0,
                        key: "diez",
                        value: 0
                    },
                    {
                        label: "S/ 5.00x",
                        total: 0,
                        key: "cinco",
                        value: 0
                    },
                    {
                        label: "S/ 2.00x",
                        total: 0,
                        key: "dos",
                        value: 0
                    },
                    {
                        label: "S/ 1.00x",
                        total: 0,
                        key: "uno",
                        value: 0
                    },
                    {
                        label: "S/ 0.5x",
                        total: 0,
                        key: "cincuentaCentimos",
                        value: 0
                    },
                    {
                        label: "S/ 0.2x",
                        total: 0,
                        key: "veinteCentimos",
                        value: 0
                    },
                    {
                        label: "S/ 0.1x",
                        total: 0,
                        key: "diezCentimos",
                        value: 0
                    },
                ],
                status: 0,
                incomes: [],
                initial: 0,
                vueltos: [],
                totIncome: 0,
                totExpense: 0,
                observation: "",
                totEfectivo: 0,
                totalArqueo: 0,
                totalVueltos: 0,
            },
        };
    },
    created() {
        this.mtdmovimientoalmacenes()
    },
    props: {
        boxes: [],
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdmovimientoalmacenes: function () {
            this.get({
                url: this.$store.getters.get__url + "/Logistica/storemovimientoVencido",
                token: this.$store.getters.get__token,
            }).then((response) => {
                console.log(response.data);

            }).catch((error) => {
                console.log(error);
            });
        },
        mtdSelectBox: function (item) {

            if (item.process != null) {
                this.verif = true;

                this.process = item.process.length > 0 ? item.process : this.process;
                this.process = item.process.length != 0 ? item.process : this.process;
                this.process.box = item.id;
            } else {
                this.$refs.SweetAlert.showWarning("Esta caja ya está aperturada");
            }
        },
        mtdOpenBead: function (status) {

            if (status == 1 && !this.process.observation) {
                this.$refs.SweetAlert.showWarning("Anote Una Observación");
                this.inconforme = true;
            } else {

                this.process.status = status;
                this.post({
                        url: this.$store.getters.get__url + "/bead/open",
                        token: this.$store.getters.get__token,
                        params: {
                            data: this.process,
                            campus: this.$store.getters.get__campus,
                        },
                    })
                    .then((response) => {
                        this.$refs.SweetAlert.showSuccess("Operacion Exitosa");
                        this.$emit("mtdOpen", response);
                    })
                    .catch((errors) => {});
            }
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },

        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
    },
    computed: {
        cpSobranteFaltante() {
            let totalMonedasBilletes = 0;

            // Sumar el total de monedas y billetes
            for (const billete of this.process.billetes) {
                totalMonedasBilletes += parseFloat(billete.total);
            }

            for (const moneda of this.process.monedas) {
                totalMonedasBilletes += parseFloat(moneda.total);
            }

            // Calcular la diferencia
            let dif = totalMonedasBilletes - parseFloat(this.process.totEfectivo);

            return parseFloat(dif).toFixed(2);
        },
    },
};
</script>

  
<style scoped>
@media (max-width: 800px) {
    #div-modal-caja {
        margin-top: 10px;
    }

    #btn-open-caja {
        justify-content: center !important;
    }

    #div-caja {
        width: 100% !important;
    }
}

@media (min-width: 800px) {
    #div-modal-caja {
        margin-top: -65px;
    }
}

.center-horizontal {
    margin: 0 auto;
}
</style>

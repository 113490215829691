<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'md'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 ">
          <div id="table-detalle" >
            <div class="modal-body">
              <div class="modal-body mr-2 ml-2">
                  <div class="text-center">
                      <h3>¿Desea reservar la cita para la(s) Hora libre?</h3>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <button @click="mtdClose"  class="btn btn-danger btn-sm">
          <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button data-bs-dismiss="modal" aria-label="Close" type="button" name="accion" class="btn btn-sm bg-main text-white" 
        @click="mtdReservarCita()">
              Reservar
        </button>
        &nbsp;
      </template>
    </CModal>
  </template>
  
  <script>
  import CModal from "@/components/shared/modals/CModal.vue";
  import { mapActions } from "vuex";

  export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
      CModal,
    },
    data() {
      return {
       
      };
    },
    props: {
      title: String,
      boo_modal: Boolean,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdClose() {
        this.$emit("closeModalCitas");
      },

      mtdReservarCita() {
        this.$emit("mtdReservarCitas");
      },

    },
   
  };
  </script>
  

<template>
<div>
    <div class="row mx-0 mt-3 p-3 d-flex justify-content-start border border-main border-2 h-100">
        <!---Reporte-->
        <div class="col-xl-4 col-lg-12 col-md-12 border mb-3 p-3">
            <div id="div-informe" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="row d-flex justify-content-start align-items-center">
                        <div class="col-xl-auto col-lg-3 col-md-3 col-sm-3 mt-1">
                            <div class="mt-1 mb-1 ms-auto btn-success">
                                <button type="button" class="btn btn-sm btn-outline-main w-100" @click="changeSubpage()">
                                    <i class="fas fa-arrow-left"></i> Atrás
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row px-3">
                        <div class="my-2 border-top border-bottom">
                            <span class="text-center fw-bold"> {{ cabecera.patient }} - {{cabecera.title.toUpperCase()}}</span>
                        </div>
                        <div class="ps-3">
                            <div class="text-start">
                                <dl class="row mb-0">
                                    <dt class="col-sm-4">Fecha:</dt>
                                    <dd class="col-sm-8">{{ examen.dateCreated }}</dd>
                                </dl>
                                <dl class="row mb-0">
                                    <dt class="col-sm-4">Indicacion:</dt>
                                    <dd class="col-sm-8">{{ examen.patient.indicacion.value }}</dd>
                                </dl>
                                <div class="ps-1">
                                    <div class="my-1" v-if="cabecera.title == 'Campo Visual'">
                                        <ul class="text-xs">
                                            <li class="text-start"><b>Ojo derecho</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b class="me-2">Errores Falsos Positivos: </b> {{ examen.informe.ojo_derecho.errores_falsos_positivos }}</li>
                                                    <li class="text-center d-inline-flex"><b class="me-2">Errores Falsos Negativos: </b> {{ examen.informe.ojo_derecho.errores_falsos_negativos }}</li>
                                                </ul>
                                            </li>
                                            <li class="text-start"><b>Ojo izquierdo</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b class="me-2">Errores Falsos Positivos: </b> {{ examen.informe.ojo_izquierdo.errores_falsos_positivos }}</li>
                                                    <li class="text-center d-inline-flex"><b class="me-2">Errores Falsos Negativos: </b> {{ examen.informe.ojo_izquierdo.errores_falsos_negativos }}</li>
                                                </ul>
                                            </li>
                                            <li class="text-start"><b>DM</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b class="me-2">OD: </b> {{ examen.informe.dm.ojo_derecho }}</li>
                                                    <li class="text-center d-inline-flex"><b class="me-2">OI: </b> {{ examen.informe.dm.ojo_izquierdo }}</li>
                                                </ul>
                                            </li>
                                            <li class="text-start"><b>DSM</b>
                                                <ul class="my-1">
                                                    <li class="text-start d-inline-flex me-5"><b class="me-2">OD: </b> {{ examen.informe.dsm.ojo_derecho}}</li>
                                                    <li class="text-center d-inline-flex"><b class="me-2">OI: </b> {{ examen.informe.dsm.ojo_izquierdo }}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="my-1" v-if="cabecera.title == 'OCT'">
                                        <dl class="row">
                                            <dt class="col-sm-3">{{examen.detail.fibra.name }}</dt>
                                            <dd class="col-sm-4">OD: {{examen.detail.fibra.od }} </dd>
                                            <dd class="col-sm-4">OI: {{examen.detail.fibra.oi }} </dd>

                                            <dt class="col-sm-12">EXAMEN DE LA CABEZA DEL NERVIO OPTICO</dt>

                                            <dt class="col-sm-3">{{examen.detail.copa.name }}</dt>
                                            <dd class="col-sm-9">
                                                <p>OD: {{examen.detail.copa.od }} </p>
                                                <p>OD: {{examen.detail.copa.oi }}</p>
                                            </dd>

                                            <dt class="col-sm-3">{{examen.detail.copa_horizontal.name }}</dt>
                                            <dd class="col-sm-9">
                                                <p>OD: {{examen.detail.copa_horizontal.od }} </p>
                                                <p>OD: {{examen.detail.copa_horizontal.oi }}</p>
                                            </dd>

                                            <dt class="col-sm-3">{{examen.detail.copa_vertical.name }}</dt>
                                            <dd class="col-sm-9">
                                                <p>OD: {{examen.detail.copa_vertical.od }} </p>
                                                <p>OD: {{examen.detail.copa_vertical.oi }}</p>
                                            </dd>

                                            <dt class="col-sm-12">DIAMETRO DEL DISCO</dt>

                                            <dt class="col-sm-3">{{examen.detail.diametro_vertical.name }}</dt>
                                            <dd class="col-sm-9">
                                                <p>OD: {{examen.detail.diametro_vertical.od }} </p>
                                                <p>OD: {{examen.detail.diametro_vertical.oi }}</p>
                                            </dd>

                                            <dt class="col-sm-3">{{examen.detail.diametro_horizontal.name }}</dt>
                                            <dd class="col-sm-9">
                                                <p>OD: {{examen.detail.diametro_horizontal.od }} </p>
                                                <p>OD: {{examen.detail.diametro_horizontal.oi }}</p>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div class="my-1" v-if="cabecera.title == 'OCT MACULA'">
                                        <dl class="row">
                                            <dt class="col-sm-3">{{examen.detail.grosor.name }}</dt>
                                            <dd class="col-sm-9">
                                                <p>OD: {{examen.detail.grosor.od }} </p>
                                                <p>OD: {{examen.detail.grosor.oi }}</p>
                                            </dd>
                                        </dl>
                                    </div>
                                    <div class="my-1" v-if="cabecera.title == 'MICROSCOPIA' || cabecera.title == 'TOPOGRAFIA'">
                                        <dl class="row">
                                            <dt class="col-sm-3">{{examen.detail.cornea.name }}</dt>
                                            <dd class="col-sm-9">
                                                <p>OD: {{examen.detail.cornea.od }} </p>
                                                <p>OD: {{examen.detail.cornea.oi }}</p>
                                            </dd>

                                            <dt class="col-sm-3">{{examen.detail.queratometrias.name}}</dt>
                                            <dd class="col-sm-9">
                                                <dl class="row">
                                                    <dt class="col-sm-4">K1</dt>
                                                    <dd class="col-sm-8">
                                                        <p>OD: {{examen.detail.queratometrias.k1.od }} </p>
                                                        <p>OD: {{examen.detail.queratometrias.k1.oi }}</p>
                                                    </dd>
                                                </dl>
                                            </dd>
                                            <dd class="col-sm-9">
                                                <dl class="row">
                                                    <dt class="col-sm-4">K2</dt>
                                                    <dd class="col-sm-8">
                                                        <p>OD: {{examen.detail.queratometrias.k2.od }} </p>
                                                        <p>OD: {{examen.detail.queratometrias.k2.oi }}</p>
                                                    </dd>
                                                </dl>
                                            </dd>
                                        </dl>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!---Completar las conclusiones-->
                        <div class="my-2">
                            <div class="border-main border-top border-bottom mx-2">
                                <span class="text-center text-xs fw-bold">Registrar conclusiones</span>
                            </div>
                        </div>
                        <div class="row d-flex mb-2">
                            <div class="col-md-9 d-flex">
                                <v-select class="text-dark text-xs" :options="optionsConclusion" v-model="selectConclusion" :reduce="(conclusion) => conclusion" label="name" placeholder="- Buscar -" required :clearable="false" @search="searchConclusion" style="width: 100%;">
                                    <template slot="no-options">
                                        No hay conclusiones para la búsqueda.
                                    </template>
                                </v-select>
                            </div>
                            <div class="col-md-3 d-flex">
                                <select class="form-control form-control-sm" v-model="conclusion_unit.ojo" style="width: 100%; font-size: 10px" @change="mtdaddConclusion()">
                                    <option value="" disabled selected>Seleccionar...</option>
                                    <option value="OD">OD</option>
                                    <option value="OI">OI</option>
                                    <option value="OA">AO</option>
                                </select>
                            </div>
                            <!-- <div class="col-md-1 d-flex">
                                <button type="button" :disabled="validationInput" @click="mtdaddConclusion()" class="btn btn-success btn-sm px-1 py-0">
                                    <i class="fas fa-plus text-white"></i>
                                </button>
                            </div> -->
                        </div>
                        <div class="row d-flex w-100 pe-lg-0">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-striped mb-0">
                                        <caption></caption>
                                        <thead class="text-xs div-title my-1">
                                            <tr>
                                                <th class="color-main w-25" scope="col">N°</th>
                                                <th class="color-main w-25">Conclusion</th>
                                                <th class="color-main w-25">Ojo</th>
                                                <th class="color-main w-25">Accion</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-xs">
                                            <tr v-for="(item, index1) in examen.conclusiones.value" :key="index1" style="vertical-align: middle">
                                                <td>
                                                    <span>{{ index1 + 1 }}</span>
                                                </td>
                                                <td><span>{{ item.name }}</span></td>
                                                <td><span>{{ item.ojo }}</span></td>
                                                <td class="align-middle">
                                                    <button type="button" @click="mtddelete(index1)" class=" btn btn-danger btn-sm px-1 py-0 "> <i class="fas fa-trash-alt text-white"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                            <tr v-if="examen.conclusiones.value.length == 0">
                                                <td colspan="4" class="text-center">
                                                    No ha registrado conclusiones aún
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-4 ml-auto">
                                <div class="btn-home mt-3">
                                    <button class="btn btn-block w-100" @click="mtdGuardar" :disabled="disableButton">
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <!---Imagen-->
        <div class="col-xl-4 col-lg-6 col-md-6 mb-3 ps-3">
            <div class="row rounded-5 d-flex justify-content-center border-2 h-100 p-2" id="div-img-campovisual">
                <div id="carouselExampleIndicators" class="carousel slide home-carousel mt-0" data-bs-ride="carousel" v-if="dataImg.length > 0">
                    <div class="carousel-indicators">
                        <button v-for="(imagen, index) in dataImg" :key="index" type="button" :data-bs-target="'#carouselExampleIndicators'" :data-bs-slide-to="index" :class="{ 'active': index === 0 }" aria-current="true" :aria-span="'Slide ' + (index + 1)"></button>
                    </div>
                    <div class="carousel-inner">
                        <div v-for="(imagen, index) in dataImg" :key="index" :class="{ 'carousel-item': true, 'active': index === 0 }">
                            <img :src="'https://oftalmologia.howertpi.com/assets/'+imagen.route" class="d-block w-100" @click="mtOpenCarrusel" :data-bs-slide-to="index" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <div class="h-100 p-5" v-if="dataImg.length == 0">
                    <h3>No hay Imagenes para mostrar</h3>
                </div>
            </div>
        </div>
        <!---pdf-->
        <div class="col-xl-4 col-lg-6 col-md-6 px-0">
            <div class="row rounded-5 border-2 h-100" id="div-img-campovisual">
                <div class="justify-content-center p-1 z-index-button" v-if="dataPdf[0]">
                    <iframe :src="'https://oftalmologia.howertpi.com/assets/'+dataPdf[0].route" width="100%" height="600px" title="Pdf"></iframe>
                </div>
                <div class="justify-content-center p-5 z-index-button" v-if="!dataPdf[0]">
                    <h3>No hay Archivos para mostrar</h3>
                </div>
            </div>
        </div>

    </div>
    <!-- Modal -->
    <cCarrusel :title="modal.title" :boo_modal="modal.boo_modal" :dataImg="dataImg" @closeCarrusel="closeCarrusel" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import VuePdfApp from "vue-pdf-app";
import cCarrusel from '@/components/Medico/AyudaDx/modals/cCarrusel.vue';
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
export default {
    name: 'c-medico-campovisual',
    components: {
        VuePdfApp,
        cCarrusel,
        SweetAlert,

    },
    data() {
        return {
            dataPdf: [],
            dataImg: [],
            examen: {},
            swal: null,
            optionsConclusion: [],
            conclusion_unit: {
                name: '',
                ojo: ''
            },
            selectConclusion: {
                id: '',
                name: ''
            },
            modal: {
                title: '',
                boo_modal: false
            }

        }
    },
    props: {
        dataExamen: {
            type: Array,
            default: () => ([])
        },
        cabecera: Object
    },
    created() {

    },
    watch: {
        dataExamen: {
            handler(newVal) {
                this.examen = this.dataExamen[0];
                this.mtdConversionData();
                //console.log(this.examen);
            },
            immediate: true // Para que se ejecute cuando el componente se monta por primera vez
        }
    },
    computed: {
        validationInput: function () {
            let value = true;
            if (
                this.selectConclusion.name &&
                this.conclusion_unit.ojo
            ) {
                value = false;
            }
            return value;
        },
        disableButton(){
            return this.examen.conclusiones.value.length ==0
        }
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdConversionData: function () {
            this.dataPdf = [];
            this.dataImg = [];
            //separar pdf de img
            this.examen.patient.examen.value.forEach(elem => {
                const extension = elem.route.split('.').pop().toLowerCase();
                if (extension === 'pdf') {
                    this.dataPdf.push(elem);
                } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                    this.dataImg.push(elem);
                }

            });
        },
        searchConclusion: function (search, loading) {
            this.optionsConclusion = [];
            if (search.length > 3) {
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Enfermera/conclusion/" + search + "/" + this.cabecera.title,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.optionsConclusion = response.data;
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdaddConclusion: function () {
            if(this.selectConclusion.name !== ''){
                let item = {
                    name: this.selectConclusion.name,
                    ojo: this.conclusion_unit.ojo,
                }
                this.examen.conclusiones.value.push(item);
            }
            this.mtdhidenConclusion();
        },
        mtddelete: function (index) {
            this.examen.conclusiones.value.splice(index, 1);
            /* this.examen.conclusiones.value.forEach((element) => {
                
            }); */
        },
        mtdGuardar: function () {
            let dataSend = {
                option: this.cabecera.option,
                reservation_id: this.cabecera.of_reservation_id,
                jsonData: this.examen
            };
            this.post({
                    url: this.$store.getters.get__url + "/Enfermera/store",
                    token: this.$store.getters.get__token,
                    params: dataSend,
                })
                .then((response) => {
                    if (!response.error) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.refreshSubpage();
                    } else {
                        this.$refs.SweetAlert.showWarning(response.data.error_message);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdhidenConclusion: function () {
            this.selectConclusion = {
                id: '',
                name: ''
            };
            this.conclusion_unit = {
                name: '',
                ojo: ''
            }
        },
        refreshSubpage() {
            this.$emit('refreshSubpage');
        },
        changeSubpage() {
            this.$emit('changeSubpage', 'table');
        },
        mtOpenCarrusel: function () {
            this.modal = {
                title: 'Resultados - ' + this.cabecera.title + ' ' + this.cabecera.patient,
                boo_modal: true,
            }
        },
        closeCarrusel: function () {
            this.modal.title = '';
            this.modal.boo_modal = false;
        }

    }
}
</script>

<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
  
    <div class="dropdown-header" @click="toggleDropdown(title)">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='CampoVisual'">
                <CampoVisual @changePage="changePage" :patient="patient" :data="data"> </CampoVisual>
            </div>
            <div id="myTabContentHijo" v-if="component=='Oct'">
                <Oct @changePage="changePage" :patient="patient" :data="data"> </Oct>
            </div>
            <div id="myTabContentHijo" v-if="component=='OctMacula'">
                <OctMacula @changePage="changePage" :patient="patient" :data="data"> </OctMacula>
            </div>
            <div id="myTabContentHijo" v-if="component=='Paquimetria'">
                <Paquimetria @changePage="changePage" :patient="patient" :data="data"> </Paquimetria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Topografia'">
                <Topografia @changePage="changePage" :patient="patient" :data="data"> </Topografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Microscopia'">
                <Microscopia @changePage="changePage" :patient="patient" :data="data"> </Microscopia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Ecografia'">
                <Ecografia @changePage="changePage" :patient="patient" :data="data"> </Ecografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Biometria'">
                <Biometria @changePage="changePage" :patient="patient" :data="data"> </Biometria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Angiografia'">
                <Angiografia @changePage="changePage" :patient="patient" :data="data"> </Angiografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='resonancia'">
                <Resonancia @changePage="changePage" :patient="patient" :data="data"> </Resonancia>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import CampoVisual from '@/components/Medico/AyudaDx/CampoVisual.vue';
import Oct from '@/components/Medico/AyudaDx/Oct.vue';
import OctMacula from '@/components/Medico/AyudaDx/OctMacula.vue';
import Paquimetria from '@/components/Medico/AyudaDx/Paquimetria.vue';
import Topografia from '@/components/Medico/AyudaDx/Topografia.vue';
import Microscopia from '@/components/Medico/AyudaDx/Microscopia.vue';
import Ecografia from '@/components/Medico/AyudaDx/Ecografia.vue';
import Biometria from '@/components/Medico/AyudaDx/Biometria.vue';
import Angiografia from '@/components/Medico/AyudaDx/Angiografia.vue';
import Resonancia from '@/components/Medico/AyudaDx/Resonancia.vue';

export default {
    name: 'dropdown-component-Consulta',
    props: {
        title: String,
        component: String,
        viewMedico: Number,
        dataPlan: Array,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory:{
          type: Object,
            default: () => ({})
        },
        patient :{
            type: Object,
        },
        data: {
            type: Array
        }
    },
    components: {
        CampoVisual,
        Oct,
        OctMacula,
        Paquimetria,
        Topografia,
        Microscopia,
        Ecografia,
        Biometria,
        Angiografia,
        Resonancia
    },
    data: () => ({
        dropdown: false,
        dataPlanDetailType: [],
    }),
    methods: {
        ...mapActions(['get', 'post']),
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },

        changePage(){
            this.$emit('changePage','cola')
        },
        
    }
}
</script>

<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
        <Cirugias v-if="page == 'cirugias'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Provider v-if="page=='provider'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Consultorios v-if="page == 'consultorios'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Insumos v-if="page == 'insumos'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Farmacia v-if="page == 'farmacia'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Optica v-if="page == 'optica'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Productos v-if="page == 'productos'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Laboratorio v-if="page == 'laboratorio'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Documentos v-if="page == 'documentos'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Imagenes v-if="page == 'imagen'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <User v-if="page == 'user'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Marca v-if="page == 'marca'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Horas v-if="page == 'horas'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Hours v-if="page == 'hours'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Almacen v-if="page == 'almacen'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Motivo v-if="page == 'motivo'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Medios v-if="page == 'medios'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Categorias v-if="page == 'categoria'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Conclusiones v-if="page == 'conclusiones'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Servicios v-if="page == 'servicios'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Doctores v-if="page == 'doctores'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Campus v-if="page == 'campus'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Roles v-if="page == 'roles'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />


    </div>
    <div class="footer">
    </div>
</div>
</template>

  
<script>
import {
    mapActions
} from 'vuex'
import Nav from '../../components/Maintenance/Nav.vue'
import Home from '../../components/Maintenance/Home.vue'
import Provider from '../../components/Maintenance/Provider.vue'
//import Footer from '../../components/Maintenance/Footer.vue'
import Cirugias from '../../components/Maintenance/Cirugias.vue'
import Consultorios from '../../components/Maintenance/Consultorios.vue'
import Insumos from '../../components/Maintenance/Insumos.vue'
import Farmacia from '../../components/Maintenance/Farmacia.vue'
import Optica from '../../components/Maintenance/Optica.vue'
import Productos from '../../components/Maintenance/Productos.vue'
import Laboratorio from '../../components/Maintenance/Laboratorio.vue'
import Documentos from '../../components/Maintenance/Documentos.vue'
import Imagenes from '../../components/Maintenance/Imagenes.vue'
import User from '../../components/Maintenance/User.vue'
import Marca from '@/components/Maintenance/Marca.vue'
import Hours from '../../components/Maintenance/Hours.vue'
import Almacen from '../../components/Maintenance/Almacenes.vue'
import Motivo from '../../components/Maintenance/Motivos.vue'
import Medios from '../../components/Maintenance/Medios.vue'
import Categorias from '@/components/Maintenance/Categorias.vue'
import Conclusiones from '../../components/Maintenance/Conclusiones.vue'
import Servicios from '../../components/Maintenance/Servicios.vue'
import Doctores from '../../components/Maintenance/Doctores.vue'
import Campus from '../../components/Maintenance/Campus.vue'
import Roles from '../../components/Maintenance/Roles.vue'


export default {
    name: "MaintenanceView",
    components: {
        Nav,
        Home,
        Provider,
        //Footer,
        Cirugias,
        Consultorios,
        Insumos,
        Farmacia,
        Optica,
        Productos,
        Laboratorio,
        Documentos,
        Imagenes,
        User,
        Marca,
        Hours,
        Almacen,
        Motivo,
        Medios,
        Categorias,
        Conclusiones,
        Servicios,
        Doctores,
        Campus,
        Roles
    },
    data() {
        return {
            prefix: 'maintenace',
            page: 'home',
            rol_name: null,
            stateRolAdmin: false,
        }
    },
    props: {
        rolname: String,
        dataRoles: Array
    },
    created() {
        this.rol_name = this.rolname;
        this.mtdGetRolAdmin();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function (page) {
            this.page = page;
        },
        mtdGetRolAdmin: function () {
            this.dataRoles.forEach(element => {
                if (element.role_id == 2) {
                    this.stateRolAdmin = true;
                }
            });
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
    }

}
</script>

<template>
    <div>
        <div class="px-3 py-2">
            <div id="btn-home" class="col-md-12 mt-3 mb-3 d-flex justify-content-end btn-home">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="card-header text-white h4 text-center" style="background: #900052">
                        <strong>KARDEX</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <!--Filtro-->
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-4">SELECCIÓN MES</span>
                                            <v-select
                                              class="text-dark form-control"
                                              label="name"
                                              :options="months"
                                              placeholder="- Seleccionar Mes -"
                                              v-model="filter.monthRange"
                                              :reduce="(name) => name.id"
                                              required
                                              :clearable="false"
                                            >
                                              <template slot="no-options">
                                                No hay Meses para la busqueda.
                                              </template>
                                            </v-select>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2">
                                      <div class="input-group input-group-sm">
                                          <span class="input-group-text col-md-4">SELECCIÓN AÑO</span>
                                          <v-select
                                          class="text-dark form-control"
                                          label="Year"
                                          :options="nextThreeYears"
                                          placeholder="- Seleccionar Año -"
                                          v-model="selectedYear"
                                          required
                                          :clearable="false"
                                          >
                                          <template slot="no-options">
                                            No hay Años para la busqueda.
                                          </template>
                                          </v-select>
                                      </div>
                                  </div>
                                  <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-1">
                                          <button
                                          class="btn btn-sm w-100 text-white w-100 py-2"
                                          style="background: #900052"
                                          @click="mtdGetData"
                                          :disabled="mtddisable"
                                          >
                                          <i class="fas fa-search"></i>&nbsp;Buscar
                                          </button>
                                  </div>
                                  <div class="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                          <button
                                          class="btn btn-sm w-100 text-white w-100 py-2"
                                          style="background: #900052"
                                          @click="viewpdf()"
                                          :disabled="mtddisable"
                                          >
                                          <i class="fal fa-file-pdf"  style="font-size: 18px;"></i>
                                          </button>
                                  </div>
                                </div>
                            </div>
                             <!--Fin Filtro-->
  
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-4 table-container" id=""  v-if="showTable">
                              <table  id="tb-anular" class="table tablaRe table-bordered align-middle">
                                <thead>
                                      <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                          <th scope="col" colspan="1" rowspan="2">FECHA</th>
                                          <th scope="col" colspan="1" rowspan="2">TIPO DOC.</th>
                                          <th scope="col" colspan="1" rowspan="2">SERIE Y N° DE DOC.</th>
                                          <th scope="col" colspan="1" rowspan="2">LOTE</th>
                                          <th scope="col" colspan="1" rowspan="2">TIPO OPE.</th>
                                          <th scope="col" colspan="3">INGRESOS</th>
                                          <th scope="col" colspan="3">SALIDAS</th>
                                          <th scope="col" colspan="3">SALDO</th>
                                      </tr>
                                      <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                          <th scope="col">Cantidad</th>
                                          <th scope="col">Costo Unit.</th>
                                          <th scope="col">Costo Total</th>
                                          <th scope="col">Cantidad</th>
                                          <th scope="col">Costo Unit.</th>
                                          <th scope="col">Costo Total</th>
                                          <th scope="col">Cantidad</th>
                                          <th scope="col">Costo Unit.</th>
                                          <th scope="col">Costo Total</th>
                                      </tr>
                                  </thead>
  
                                <tbody class="custom-table">
  
                                  <tr class="text-center" style="vertical-align: middle; color: #900052; background: #f3e2ec">
                                      <th scope="col" colspan="5" style="font-style: italic;">*** TOTALES</th>
                                      <th scope="col">{{ totals.totalSumaCantidadEn }}</th>
                                      <th scope="col" colspan="2">{{  totals.totalSumaTotalEn }}</th>
                                      <th scope="col">{{  totals.totalSumaCantidadSa}}</th>
                                      <th scope="col" colspan="2">{{  totals.totalSumaTotalSa}}</th>
                                      <th scope="col" colspan="3" style="font-style: italic;"></th>
                                  </tr>
  
                                  <template v-for="(product, productId) in dataCxcs">
                                    <tr class="text-center" :key="'product_' + productId">
                                        <th scope="col" colspan="14">{{  product[0].name }}</th>
                                    </tr>
  
                                    <tr v-for="(item, index) in product" :key="'item_' + productId + '_' + index">
                                          <template v-if="item.type == 16">
                                                <td>{{ item.date | formatDate }}</td>
                                                <td colspan="3"> {{'-'}}</td>
                                                <td>
                                                    <span v-if="item.type == 16" class="badge text-bg-primary">INVENTARIO</span>
                                                </td>
                                                <td>{{ parseInt(item.quantity) }}</td>
                                                <td>{{ item.price_cost }}</td>
                                                <td>{{ (item.quantity * item.price_cost).toFixed(2) }}</td>
                                                <td colspan="3">-</td>
                                                <td>{{ item.saldoCantidad }}</td>
                                                <td>{{ item.division }}</td>
                                                <td>{{ item.saldoTotal.toFixed(2) }}</td>
                                            </template>
  
                                          <template v-if="item.type == 1">
                                              <td>{{ item.date | formatDate }}</td>
                                              <td> {{item.typeBills === 1 ? 'FACTURA' : 'BOLETA'}}</td>
                                              <td>{{ item.number}}</td>
                                              <td>{{ item.lote}}</td>
                                              <td @click="openModal(item.of_dispatch_detail_id, item.of_sale_details_id, item.name, item.lote, item.id, item.of_product_detail_id)">
                                                  <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                  <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                  <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                  <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                              </td>
                                              <td>{{ parseInt(item.quantity) }}</td>
                                              <td>{{ item.price_cost }}</td>
                                              <td>{{ (item.quantity * item.price_cost).toFixed(2) }}</td>
                                              <td colspan="3">-</td>
                                              <td>{{ item.saldoCantidad }}</td>
                                              <td>{{ item.division }}</td>
                                              <td>{{ item.saldoTotal.toFixed(2) }}</td>
                                          </template>
  
                                          <template v-if="item.type == 2">
                                              <td>{{ item.date | formatDate }}</td>
                                              <td>{{ item.document !== '' ? item.document : '-' }}</td>
                                              <td>{{item.serie}}-{{item.numberVou}}</td>
                                              <td>{{ item.lote}}</td>
                                              <td @click="openModal(item.of_dispatch_detail_id, item.of_sale_details_id, item.name, item.lote, item.id, item.of_product_detail_id)">
                                                <span v-if="item.type == 1 && item.bonus == 2" class="badge text-bg-success">ENTRADA (BONUS)</span>
                                                <span v-else-if="item.type == 1" class="badge text-bg-success">ENTRADA</span>
                                                <span v-if="item.type == 2 && item.bonus == 2" class="badge text-bg-danger">SALIDA (BONUS)</span>
                                                <span v-else-if="item.type == 2" class="badge text-bg-danger">SALIDA</span>
                                              </td>
                                              <td colspan="3">-</td>
                                              <td>{{parseInt(item.quantity) }}</td>
                                              <td>{{ item.price_cost }}</td>
                                              <td>{{ (item.quantity * item.price_cost).toFixed(2) }}</td>
                                              <td>{{ item.saldoCantidad }}</td>
                                              <td>{{ item.division }}</td>
                                              <td>{{ item.saldoTotal}}</td>
                                          </template>
                                    </tr>
                                    <tr  class="text-center" style="vertical-align: middle; color: #900052; background: #fdfae3" :key="productId">
                                          <th scope="col" colspan="5" style="font-style: italic;">*** TOTAL</th>
                                          <th scope="col">{{ product.totales.sumaCantidadEn }}</th>
                                          <th scope="col" colspan="2">{{ product.totales.sumaTotalEn}}</th>
                                          <th scope="col">{{ product.totales.sumaCantidadSa }}</th>
                                          <th scope="col" colspan="2">{{ product.totales.sumaTotalSa}}</th>
                                          <th scope="col" colspan="3" style="font-style: italic;"></th>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
  
                            </div>
  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalDetKarVue :title="modalDetKar.title" :boo_modal="modalDetKar.modal_form" :dataDis=modalDetKar.dataDis
      :dataSal=modalDetKar.dataSal :dataDePhar=modalDetKar.dataDePhar 
      :namePro=modalDetKar.namePro :lotePro=modalDetKar.lotePro @closeModalDetKar="closeModalDetKar"/>
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalDetKarVue from "../modals/cModalDetKar.vue";

export default {
    name: "kardex",
    components: {
        cModalDetKarVue
    },
    data() {
        return {
            search: "",
            dataShow: [],
            dataCxcs: [],
            selectPage: 1,
            noRecordsMessage: "",
            filter: {
                init: "",
                end: "",
                monthRange: null
            },
            stepPagination: [],
            servicesProd: [],
            selectedProd: "",
            totals: [],

            selectedYear: null,
            nextThreeYears: [],
            months: [
            { id: 1, name: 'Enero' },
            { id: 2, name: 'Febrero' },
            { id: 3, name: 'Marzo' },
            { id: 4, name: 'Abril' },
            { id: 5, name: 'Mayo' },
            { id: 6, name: 'Junio' },
            { id: 7, name: 'Julio' },
            { id: 8, name: 'Agosto' },
            { id: 9, name: 'Septiembre' },
            { id: 10, name: 'Octubre' },
            { id: 11, name: 'Noviembre' },
            { id: 12, name: 'Diciembre' }
            ],
            showTable: false,
            //Inventario
            registerKar: [],

            modalDetKar: {
                title: "DETALLE DE KARDEX",
                dataDis: [],
                dataSal: [],
                dataDePhar: [],
                namePro: "",
                lotePro: "",
                modal_form: false,
            },
        };
    },
    mounted() {
         this.populateNextThreeYears();
    },
    computed: {
        mtddisable() {
            //return this.filter.monthRange == '';
            return !(this.filter.monthRange !== null && this.selectedYear !== null);
        },
        cpData() {
        }
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
        //Inventario
        this.registerKardex();
    },
    methods: {
        ...mapActions(["get", "post"]),

        //metodo obtener data

        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Logistica/kardexx",
                token: this.$store.getters.get__token,
                params: {
                    month: this.filter.monthRange,
                    anio: this.selectedYear,
                },
            })
            .then((response) => {
                this.dataCxcs = response.dataCxcs;
                this.totals = response.totals;
                if (this.dataCxcs.length === 0) {
                    this.showTable = false;
                    this.noRecordsMessage = "No se encontraron resultados.";
                    Swal.fire({
                    text: " Sin Datos",
                    icon: "warning",
                    confirmButtonText: "Aceptar",
                    confirmButtonColor: "#900052",
                    });
                } else {
                    this.showTable = true;
                    this.noRecordsMessage = "";
                }
            })
            .catch((errors) => {});
        },

        //Inventario
        registerKardex: function () {
          const currentMonth = moment().month() + 1;
          const currentYear = moment().year();
            this.post({
                url: this.$store.getters.get__url + "/Logistica/registerKardex",
                token: this.$store.getters.get__token,
                params: {
                  month: currentMonth,
                  anio: currentYear,
                },
            })
            .then((response) => {
                this.registerKar = response.data;
            })
            .catch((errors) => {});
        },

        openModal: function (idDis, idSale, name, lote, id, idPd) {
            this.post({
                url: this.$store.getters.get__url + "/Logistica/openDetKardex",
                token: this.$store.getters.get__token,
                params: {
                  idDis: idDis,
                  idSale: idSale,
                  idKa: id,
                  idPd: idPd,
                },
            })
            .then((response) => {
              this.modalDetKar = {
                  //title: "DETALLE DE KARDEX" + ': ' + name,
                  title: "DETALLE DE KARDEX",
                  modal_form: true,
                  dataDis: response.dataDis,
                  dataSal: response.dataSal,
                  dataDePhar: response.dataDePhar,
                  namePro: name,
                  lotePro: lote,
              };
            })
            .catch((errors) => {});

        },

        closeModalDetKar: function () {
            this.modalDetKar = {
                title: "DETALLE DE KARDEX",
                modal_form: false,
                dataDis: [],
                dataSal: [],
                dataDePhar: [],
                namePro: "",
                lotePro: "",
            };
        },

        changePage(page) {
            this.$emit("returnHome", page);
        },
        //Fecha
        formatDate(date) {
            const formattedDate = new Date(date);
            const day = String(formattedDate.getDate()).padStart(2, "0");
            const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
            const year = formattedDate.getFullYear();
            return `${day}/${month}/${year}`;
        },
        searchProd: function (search, loading) {
            if (search.length > 3) {
                this.servicesProd = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/logistics/searchKardex/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        this.servicesProd = response.data;
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        viewpdf: function () {
            window.open(
                this.$store.getters.get__url +
                "/Logistica/viewpdf/Kardexx/" +
                this.filter.monthRange + "/" +
                this.selectedYear,
                "_blank"
            );
        },

        populateNextThreeYears1() {
            const currentYear = new Date().getFullYear() - 2;
            for (let i = 0; i < 6; i++) {
                this.nextThreeYears.push(currentYear + i);
            }
        },

        populateNextThreeYears() {
          const currentYear = new Date().getFullYear();
          for (let i = 0; i < 3; i++) {
            this.nextThreeYears.push(currentYear - i);
          }
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>

<style scoped>
#dtb-cxc {
    max-height: 400px;
    overflow-y: auto;
}

#dtb-cxc {
    max-width: 1400px;
    overflow-x: auto;
}

#btn-edit {
    color: #39b54a;
    font-size: 25px;
    margin: 0px 2px;
}

#btn-edit:hover {
    color: #217b2d;
}

#btn-remove {
    color: #ff0000;
    font-size: 25px;
    margin: 0px 2px;
}

#btn-remove:hover {
    color: #a30404;
}

.btn-bm-noradius {
    margin-left: 10px;
    width: 150px;
    height: 38px;
}

.btn-bm-noradius:hover {
    background: rgb(170, 2, 95);
    background-color: rgb(170, 2, 95);
    color: white;
}

.btn-register {
    background: rgb(144, 0, 82);
}

.btn-register:hover {
    background: rgb(170, 2, 95);
}

.modal-header {
    background: rgb(144, 0, 82);
}

.close {
    cursor: pointer;
}

.text-start {
    text-align: left;
}

.tr-table>th>div {
    background: #900052;
    border-radius: 7px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
}

.tr-table>th {
    padding: 0px;
}

.tbody-table>td>div {
    background: #eaecee;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
}

.tbody-table>td {
    padding: 0px;
}

.tbody-table>td>div>div {
    border-radius: 10px;
}

#btn-close {
    width: 49%;
}

.table-th {
    width: 50% !important;
}

.table-th-state {
    width: 18% !important;
}

.page-link {
    color: black !important;
}

.page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
}

.page {
    color: white !important;
    background: rgb(144, 0, 82);
}

.checkbox-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
}

@media (max-width: 480px) {
    #btn-edit {
        font-size: 13px !important;
    }

    #btn-remove {
        font-size: 13px !important;
    }

    #btn-close {
        width: 100%;
    }

    .container {
        font-size: 11px !important;
    }

    .btn-register {
        width: 100% !important;
        margin-top: 0 !important;
    }

    .td-div-a {
        padding-top: 10px !important;
    }

    .td-div-state>div>div {
        margin-top: 3px !important;
    }
}

@media (max-width: 415px) {
    #state_schedule {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px !important;
    }

    #state_schedule div {
        width: 100%;
    }
}

.custom-table th,
.custom-table td {
    font-size: 11px;
}

.table-container {
overflow-x: auto;
white-space: nowrap;
scrollbar-width: thin;
scrollbar-color: darkgray lightgray;
}
</style>

<template>
  <div>
    <nav
      id="nav-menu"
      class="fixed-bottom py-0 d-flex justify-content-around mt-2"
    >
      <div
        :style="mtdGetStyle('antecedente')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('antecedente')"
      >
        <span :style="mtdGetStyleSpan('antecedente')" class="btn w-100"
          ><strong>ANTECEDENTES</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('historia')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('historia')"
      >
        <span :style="mtdGetStyleSpan('historia')" class="btn w-100"
          ><strong>HISTORIA CLÍNICA</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('ayudadx')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('ayudadx')"
      >
        <span :style="mtdGetStyleSpan('ayudadx')" class="btn w-100"
          ><strong>AYUDA AL DX</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('laboratorio')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('laboratorio')"
      >
        <span :style="mtdGetStyleSpan('laboratorio')" class="btn w-100"
          ><strong>LABORATORIO</strong></span
        >
      </div>
      <!-- <div :style="mtdGetStyle('reporte')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('reporte')"><span :style="mtdGetStyleSpan('reporte')"
          class="btn w-100"><strong>REPORTE OPERATORIO</strong></span>
      </div> -->
      <div
        :style="mtdGetStyle('conclusiones')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('conclusiones')"
      >
        <span :style="mtdGetStyleSpan('conclusiones')" class="btn w-100"
          ><strong>COMPLETAR CONCLUSIONES</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('consulta')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('consulta')"
      >
        <span :style="mtdGetStyleSpan('consulta')" class="btn w-100"
          ><strong>CONSULTA</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('tableHc')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('tableHc')"
      >
        <span :style="mtdGetStyleSpan('tableHc')" class="btn w-100"
          ><strong>CONSULTAS HC</strong></span
        >
      </div>
      <div
        :style="mtdGetStyle('codigos')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('codigos')"
      >
        <span :style="mtdGetStyleSpan('codigos')" class="btn w-100"
          ><strong>CODIGOS</strong></span
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "FooterMedicoComponent",
  props: {
    patient: Object,
    pageView: String

  },
  data() {
    return {
      page: null,
    };
  },
  created() {},
  methods: {
    mtdGetStyle: function (option) {
      if (option == this.page && this.pageView != "home") return "background: #900052";
      return "";
    },
    mtdGetStyleSpan: function (option) {
      if (option == this.page && this.pageView != "home") return "color:white";
      return "";
    },
    mtdSendOption: function (page) {
      if (
        page === "antecedente" ||
        page === "historia" ||
        page === "ayudadx" ||
        page === "laboratorio" ||
        page === "reporte"
      ) {
        if (page == "mtdChangePage") {
          this.$emit("mtdChangePage", this.page);
        } else {
          if (Object.keys(this.patient).length !== 0) {
            this.page = page;
            this.$emit("mtdChangePage", this.page);
          }
        }
      } else {
        this.page = page;
        this.$emit("mtdChangePage", this.page);
      }
    },
  },
};
</script>
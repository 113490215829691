<template>
<div class="container">
    <div id="btn-return" class="d-flex justify-content-end pt-1 mt-3">
        <div class="btn-home" role="group">
            <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                <i class="fas fa-recycle"></i> Cambiar de Sede
            </button>
        </div>
    </div>
    <div id="btn_home" class="c-app align-items-center">
        <div class="d-grid gap-2 mx-auto text-center col-md-12">

            <div id="content-timelinetable" class="mb-2 mt-4">
                <table id="table-historia" class="table table-bordered border-main mb-0">
                    <caption></caption>
                    <thead class="align">
                        <tr class="text-white border-white text-center bg-main text-xs">
                            <!-- Mostrar las primeras 3 cabeceras -->
                            <th scope="col" v-for="(columnHeader, columnIndex) in Object.keys(data).slice(0, 3)" :key="columnIndex">
                                {{ columnHeader }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="text-xs align">
                        <tr>
                            <!-- Iterar sobre las primeras 3 cabeceras y mostrar los datos correspondientes -->
                            <td v-for="(header, headerIndex) in Object.keys(data).slice(0, 3)" :key="headerIndex">
                                <draggable :list="data[header]" :group="{ name: 'cells', put: true }" class="draggable">
                                    <div v-for="(item, index) in data[header]" :key="index" :class="{'highlighted': index === 0 }" class="extra-space-td">
                                      <span class="drag-handle" @dblclick="mtdSelectPatient(item)">
                                        <b><i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                      </span>
                                    </div>
                                </draggable>
                                <div v-if="data[header].length == 0">
                                    <span class="drag-handle">
                                    <b>No hay pacientes por atender</b>
                                  </span>
                                </div>
                            </td>
                        </tr>
                        <tr class="text-white border-white text-center bg-main text-xs">
                            <!-- Mostrar las siguientes 3 cabeceras -->
                            <th scope="col" v-for="(columnHeader, columnIndex) in Object.keys(data).slice(3, 6)" :key="columnIndex + 3">
                                {{ columnHeader }}
                            </th>
                        </tr>
                        <tr>
                            <!-- Iterar sobre las siguientes 3 cabeceras y mostrar los datos correspondientes -->
                            <td v-for="(header, headerIndex) in Object.keys(data).slice(3,6)" :key="headerIndex + 3">
                                <draggable :list="data[header]" :group="{ name: 'cells', put: true }" class="draggable">
                                    <div v-for="(item, index) in data[header]" :key="index + 3" :class="{'highlighted': index === 0 }" class="extra-space-td">
                                      <span class="drag-handle" @dblclick="mtdSelectPatient(item)">
                                        <b><i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                      </span>
                                    </div>
                                </draggable>
                                <div v-if="data[header].length == 0">
                                    <span class="drag-handle">
                                    <b>No hay pacientes por atender</b>
                                  </span>
                                </div>
                            </td>
                        </tr>
                        <tr class="text-white border-white text-center bg-main text-xs">
                            <!-- Mostrar las 3 ultimas -->
                            <th scope="col" v-for="(columnHeader, columnIndex) in Object.keys(data).slice(6,9)" :key="columnIndex + 3">
                                {{ columnHeader }}
                            </th>
                        </tr>
                        <tr>
                            <!-- Iterar sobre las siguientes 3 cabeceras y mostrar los datos correspondientes -->
                            <td v-for="(header, headerIndex) in Object.keys(data).slice(6,9)" :key="headerIndex + 3">
                                <draggable :list="data[header]" :group="{ name: 'cells', put: true }" class="draggable">
                                    <div v-for="(item, index) in data[header]" :key="index + 3" :class="{'highlighted': index === 0 }" class="extra-space-td">
                                      <span class="drag-handle" @dblclick="mtdSelectPatient(item)">
                                        <b><i class="fas fa-asterisk"></i> {{ index + 1 }}. {{ item.name }}</b>
                                      </span>
                                    </div>
                                </draggable>
                                <div v-if="data[header].length == 0">
                                    <span class="drag-handle">
                                    <b>No hay pacientes por atender</b>
                                  </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import draggable from 'vuedraggable';
import {
    mapActions
} from 'vuex'

export default {
    name: 'HomeMedicoComponent',
    props: {},
    components: {
        draggable
    },
    data() {
        return {
            user_id: null,
            dataConsultingroom: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 2,
            dataShow: [],
            msg: "",
            data: {},
            campoLength:0,
            octLength:0,
            paquimetriaLength:0,
            topografiaLength:0,
            microscopiaLength:0,
            biometriaLength:0
        };
    },
    created() {
      this.mtdGetData();
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Enfermera/PatientsHome",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                    }
                })
                .then((response) => {
                    this.data = {
                        "CAMPO VISUAL": response.data.campo,
                        "OCT": response.data.oct,
                        "OCT MACULA": response.data.oct_macula,
                        "PAQUIMETRIA": response.data.paquimetria,
                        "MICROSCOPIA ESPEC.": response.data.microscopia,
                        "TOPOGRAFIA": response.data.topografia,
                        "ECOGRAFIA OCULAR": response.data.ecografia,
                        "BIOMETRIA": response.data.biometria,
                        "ANGIOGRAFIA": response.data.angiografia,
                        //"RESONANCIA": response.data.resonancia,
                    };
                    this.campoLength = response.data.campo.length;
                    this.octLength = response.data.oct.length;
                    this.paquimetriaLength = response.data.paquimetria.length;
                    this.topografiaLength = response.data.topografia.length;
                    this.microscopiaLength=response.data.microscopia.length;
                    this.biometriaLength=response.data.biometria.length;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdSelectPatient: function (patient) {
            this.$emit('mtdSelectPatient', patient);
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
    },
}
</script>

<style>
.align {
    text-align: left;
}

.fas.fa-asterisk {
    font-size: xx-small;
    margin-right: 3px;
    color: #900052;
    font-size: 0.6em;
}

.no-border {
    border: none;
}

tr:last-child {
    border-bottom: 1px solid #900052;
}

#table-historia {
    width: 80%;
    margin: 0 auto;
}

.extra-space-td {
    padding: 7px !important;
}

#table-historia th {
    font-size: 15px;
}

#table-historia td {
    font-size: 12.5px;
}

.draggable {
    cursor: grab;
}

.highlighted {
    color: green;
}
</style>

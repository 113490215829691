<template>
<div>
    <div class="container text-center">
        <div class="d-flex title-option mt-3">
            <div class="btn-home mt-1 mb-1 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="card-body w-100">
            <div class="row d-flex mt-2" v-if="subpage=='table'">
                <div class="col-md-12 col-lg-12">
                    <div class="px-0">
                        <div class="input-group input-group mb-3">
                            <span class="input-group-text text-white bg-main "><i class="fas fa-search"></i></span>
                            <input v-model="patient_name" type="text" class="form-control form-control" placeholder="Buscar por paciente" @input="mtdSearchFilter"/>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 col-lg-4">
                    <div class="px-0">
                        <div class="input-group input-group mb-3">
                            <span class="input-group-text text-white bg-main col-lg-3 col-md-4">Fecha</span>
                            <input v-model="filter.date_start" type="date" class="form-control form-control" placeholder="Fecha inicio" @input="mtdSearchFilter" />
                        </div>
                    </div>
                </div> -->
                <div v-if="data.length > 0 && cpData.length > 0" class="d-flex justify-content-end px-2">
                    <nav aria-label="Page navigation example ">
                        <ul class="pagination mb-0 ">
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                            </li>
                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                            </li>
                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>

                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                </a>
                            </li>
                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>
                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div class="col-md-12 mt-3">
                    <table class="table table-responsive table-bordered px-0">
                        <caption></caption>
                        <thead class="bg-main text-white">
                            <tr>
                                <th scope="col">N°</th>
                                <th scope="col">Enfermera</th>
                                <th scope="col">Paciente</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Examenes</th>
                            </tr>
                        </thead>
                        <tbody class="text-xs">
                            <tr v-for="(item, index) in cpData" :key="index">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.enfermera_name }}</td>
                                <td>{{ item.patient_name }}</td>
                                <td>{{ item.date }}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="btn btn-sm btn-info dropdown-toggle text-xxs" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            {{item.ayudadx.length }} Examenes
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li v-for="(exam, indexa) in item.ayudadx"><a class="dropdown-item text-xxs" @click="mtdCompleted(exam,item)">{{exam}}</a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="cpData.length === 0">
                                <td colspan="5" class="text-center">No hay Examenes para llenar</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <RegisterConclusion v-if="subpage=='register'" :cabecera="cabecera" :dataExamen="dataExamen"  @changeSubpage="changeSubpage" @refreshSubpage="refreshSubpage" />
        </div>
    </div>

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import RegisterConclusion from '@/components/Medico/Conclusion/AyudadxConclusion.vue';
export default {
    name: 'c-Medico-conclusiones',
    components: {
        RegisterConclusion,
    },
    data() {
        return {
            filter: {
                date_start: null,
                date_end: null,
            },
            patient_name: "",
            totalPagesToShow: 5,
            selectPage: 1,
            data: [],
            stepPagination: [],
            dataShow: [],
            subpage: 'table',
            cabecera: {
                title: "",
                patient: "",
                option: "",
                of_reservation_id: "",
            },
            dataExamen: [],
        }
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    created() {
        this.mtdGetData();
        //this.getFirstDayOfMonth();
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            //const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = firstDayOfMonth.toISOString().split('T')[0];
        },
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Doctor/getPendingConclusions",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.data = response.data;
                    if (this.data.length === 0) {
                    this.dataShow = [];
                } else {
                    this.calculateData(10);
                }
                })
                .catch((errors) => {});
        },
        mtdSearchFilter() {
            this.calculateData(10);
        },
        mtdCompleted: function (exam, item) {
            let option = "";
            switch (exam) {
                case "Campo Visual":
                    this.dataExamen = JSON.parse(item.campo_visual);
                    option = "visual_field";
                    break;
                case "Oct":
                    this.dataExamen = JSON.parse(item.oct);
                    option = "oct";
                    break;
                case "Oct Macula":
                    this.dataExamen = JSON.parse(item.oct_macula);
                    option = "oct_macula";
                    break;
                case "Paquimetria":
                    this.dataExamen = JSON.parse(item.paquimetria);
                    option = "pachymetry";
                    break;
                case "Topografia":
                    this.dataExamen = JSON.parse(item.topografia);
                    option = "topography";
                    break;
                case "Microscopia":
                    this.dataExamen = JSON.parse(item.microscopia);
                    option = "specular_microscopy";
                    break;
                case "Ecografia Ocular":
                    this.dataExamen = JSON.parse(item.ecografia);
                    option = "eye_ultrasound";
                    break;
                case "Biometria":
                    this.dataExamen = JSON.parse(item.biometria);
                    option = "biometrics";
                    break;
                case "Angiografia":
                    this.dataExamen = JSON.parse(item.angiografia);
                    option = "retinal_angiography";
                    break;
                default:
                    break;
            }
            this.cabecera = {
                title: exam,
                patient: item.patient_name,
                option: option,
                of_reservation_id: item.of_reservation_id,
            }
            this.changeSubpage('register');

        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        refreshSubpage(){
            this.mtdGetData();
            this.subpage = 'table';
        },
        changeSubpage: function (subpage) {
            this.subpage = subpage;
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let selectPage = this.selectPage;
            let filteredData = this.data;
            if ((this.patient_name.length >= 2 && this.patient_name != "") || this.filter.date_start !== null ) {
                let query = this.patient_name.toUpperCase();
                let dateInput  = this.filter.date_start ? this.filter.date_start.split("-").reverse().join("/"): null;
                let date1 = new Date(dateInput);
                filteredData = this.data.filter((element) => {
                    let number = element.patient_name;
                    let date2 = new Date(element.date);
                    //console.log(date1.getTime());
                    return  dateInput !== null ? number.includes(query) || date1.getTime() === date2.getTime() : number.includes(query);
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    }
}
</script>

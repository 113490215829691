<template>
    <div>
      <nav class="navbar">
        <div class="container-fluid">
          <div class="" id="img_logo">
            <img src="../../assets/logo_bm.png" alt="Logo" width="200" class="d-inline-block" />
          </div>
          <div class="row navbar-content">
            <ul style="justify-content: end;">
              <li class="h5" id="title-user">{{ user }}
                    <div class="dropdown-login">
                      <button class="dropbtn"><i class="fas fa-solid fa-caret-down"></i></button>
                      <div class="dropdown-login-content align-left">
                        <a href="#">Mi perfil</a>
                        <a class="dropdown-login-item" href="javascript:void(0)" @click="mtdLogout">Cerrar sesión</a>
                      </div>
                    </div>
              </li>
              <li class="h5" id="title-doctor">{{ rolname }}</li>
              <li class="h5" id="title-date">{{ hoy }}</li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </template>
  
  <script>
  import moment from 'moment'
  import { mapActions } from 'vuex'
  
  export default {
    name: 'NavComponent',
    props: {
      page: {
        type: String,
        default: null
      },
      rolname: String
    },
    data () {
      return {
        hoy: null,
        user: null
      }
    },
    created () {
      moment.locale('es')
      this.hoy = moment().format('LLL')
      this.user = this.$store.getters.get__user.name 
      // + ' ' + this.$store.getters.get__user.last_name
    },
    mounted: function () {
      setInterval(() => {
        moment.locale('es')
        this.hoy = moment().format('LLL')
      }, 1000)
    },
    methods: {
      ...mapActions(['get', 'post']),
      mtdLogout: function(){   
        this.get({
          url: this.$store.getters.get__url + "/Authentication/logout",
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            console.log(response);
            this.$store.commit('mt_logout_auth')
            this.$router.replace({path: '/login'});
          })
      }
    },
  }
  </script>
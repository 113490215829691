<template>
<div>
    <div class="container">
        <div id="btn-home" class="col-md-12 mt-3 mb-4 d-flex justify-content-end btn-home">
            <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="card-header text-white h4 text-center" style="background: #900052">
                    <strong>CONSIGNACIONES</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="d-flex justify-content-between btn-home  px-3 ">
                            <button type="button " class="btn btn-sm " @click="viewModaladd"><i class="fas fa-plus"></i> Nueva</button>
                            <div v-if="cpData.length > 0">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0 ">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div class="col-md-12 mt-3 mb-4" id="content-timelinetable">
                            <table class="table text-xxs table-bordered border-main text-center align-middle">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">FECHA DE LLEGADA </th>
                                        <th scope="col">USUARIO</th>
                                        <th scope="col">PROVEEDOR</th>
                                        <th scope="col">RUC</th>
                                        <th scope="col">TOTAL</th>
                                        <th scope="col">ACCIONES</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-if="cpData.length === 0">
                                        <td colspan="6" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr v-for="(item, index) in cpData" :key="index">

                                        <td>{{ item.date }}</td>
                                        <td>{{ item.user.name }}</td>
                                        <td>{{ item.provider.name }}</td>
                                        <td>{{ item.provider.ruc }}</td>
                                        <td>{{ item.total }}</td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <a href="#" class="btn btn-sm btn-info px-1 text-white " type="button" @click="viewModal(item)">
                                                    <i class="far fa-eye" style="font-size: 13px;"></i>
                                                </a>
                                                <a v-if="item.url_guide_remission" href="#" class=" ms-1 btn btn-sm btn-danger px-2 text-white " type="button" @click="openPdf(item.url_guide_remission)">
                                                    <i class="fa fa-file-pdf" style="font-size: 13px;"></i>
                                                </a>
                                            </div>

                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert ref="SweetAlert" />
        <cModalViewConsignacionesVue :productos="productos" :title="modal.title" :boo_modal="modal.modal_form" @mtdcloseModal="closeModal" />
        <cModaladdConsignacionesVue @mtdGetData="mtdGetData" :dataprovider="dataprovider" :title="modalconsignacion.title" :boo_modal="modalconsignacion.modal_form" @closeModalConsignacion="closeModalConsignacion" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import cModalViewConsignacionesVue from '../modals/cModalViewConsignaciones.vue';
import cModaladdConsignacionesVue from '../modals/cModaladdConsignaciones.vue';
export default {
    name: "c-logistica-pages-consignaciones",
    components: {
        SweetAlert,
        cModalViewConsignacionesVue,
        cModaladdConsignacionesVue
    },
    data() {
        return {
            search: "",
            data: [],
            dataShow: [],
            selectPage: 1,
            stepPagination: [],
            noRecordsMessage: "",
            modal: {
                title: "",
                modal_form: false,
            },
            modalconsignacion: {
                title: "",
                modal_form: false,
            },
            productos: [],
            dataprovider: []
        }
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        this.mtdGetData();
        this.mtdGetProvider();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Logistica/indexConsignaciones",
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.data = response.data;
                if (this.data.length === 0) {
                    this.noRecordsMessage = "No hay registros";
                    this.dataShow = [];
                } else {
                    this.calculateData(10);
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        mtdGetProvider: function () {
            this.get({
                url: this.$store.getters.get__url + "/Maintainer/indexProvider",
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.dataprovider = response.data;
            }).catch((error) => {
                console.log(error);
            });
        },
        mtdSearchFilter() {
            this.calculateData(10);
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            // if (this.search.length >= 2 && this.search != "") {
            //     let query = this.search.toString().toUpperCase();
            //     filteredData = this.data.filter((element) => {
            //         let name = element.productodetail.molecule.toString().toUpperCase();
            //         return name.includes(query);
            //     });
            //     selectPage = 1;
            // }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        changePage(page) {
            this.$emit("returnHome", page);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        viewModal: function (item) {
            this.productos = item.consignments_details;
            this.modal = {
                title: 'CONSIGNACIONES',
                modal_form: true,
            }
        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
            }
        },
        viewModaladd: function () {
            this.modalconsignacion = {
                title: 'NUEVA CONSIGNACION',
                modal_form: true,
            }
        },
        closeModalConsignacion: function () {
            this.modalconsignacion = {
                title: '',
                modal_form: false,
            }
        },
        openPdf: function (url) {
            console.log(url);
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        }, 

    }
}
</script>

<style scoped>
#input-f-factura {
    font-size: 10px;
}
</style>

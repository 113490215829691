<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Consultorio</label>
                <input type="text" class="form-control form-control-sm" placeholder=" Nombre" v-model="data_detail.name">
            </div>
            <div class="mb-2 col-xl-12 px-1">
                <label id="miSelect" for="name" class="form-label">Campu</label>
                <select  v-model="data_detail.campu_id"  class="form-control form-control-sm" aria-label=".form-select-sm example">
                    <option value="" selected disabled>Seleccione...</option>
                    <option  v-for="(campu,index) in dataCampus" :key="index" :value="campu.id">{{ campu.name }}</option>
                </select>
            </div>
 
            <div class="mb-2 col-xl-12 px-1">
                <label id="miSelect" for="name" class="form-label">Servicio</label>
                <div class="d-flex">
                    <div class="form-check">
                            <input class="form-check-input" type="radio" name="service_id" id="consultas" value="1" v-model="data_detail.service_id">
                            <label class="form-check-label" for="consultas">CONSULTAS</label>
                    </div>
                    <div class="form-check mx-3">
                        <input class="form-check-input" type="radio" name="service_id" id="imagenes" value="4" v-model="data_detail.service_id">
                        <label class="form-check-label" for="imagenes">IMÁGENES</label>
                    </div>
                </div>
            </div> 
        </div>
        <div class="row mx-0 px-0">
            <div class="col-md-12 mb-2 px-1" v-if="num == 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="data_detail.state == 1 ? true : false" @change="mtdChangeState" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">{{ data_detail.state == 1 ? "Activo" : "Inactivo" }}</label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm"  @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-consultorios",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                campu_id: '',
                name: '',
                service_id: '',
                state: 1
            }
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    if(this.num == 1){
                        this.data_detail = {
                        ...newVal
                    };
                    }
                    
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataCampus: {
            type: Array,
            default: null
        }
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name.length > 0 && this.data_detail.campu_id !== '' && this.data_detail.service_id !== '');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
                this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdClose() {
            this.data_detail = {
                campu_id: '',
                name: '',
                service_id: '',
            }
            this.$emit("closeModal");
           
        },
        DataDetail() {
            this.data_detail.campus_id='';
            document.getElementById("miSelect").selectedIndex = 0;
            this.$emit('Datadetail', this.data_detail, this.num)
            
        },
        
    },

};
</script>
<style scoped>

    input[type="radio"]:checked {
        border-color: #900052; 
        background-color: #900052; 
    }

</style>


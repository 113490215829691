import { render, staticRenderFns } from "./cModalDetalle.vue?vue&type=template&id=436899ca&scoped=true"
import script from "./cModalDetalle.vue?vue&type=script&lang=js"
export * from "./cModalDetalle.vue?vue&type=script&lang=js"
import style0 from "./cModalDetalle.vue?vue&type=style&index=0&id=436899ca&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436899ca",
  null
  
)

export default component.exports
<template>
<div>
</div>
</template>

<script>
import Swal from 'sweetalert2';
export default {
    name: 'SweetAlert-c',
    props: {
        swal: String,

    },
    methods: {
        showAlert(title, text, icon, accion) {
            Swal.fire({
                title: title + "--" + this.swal,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonText: accion,
                cancelButtonText: "Cancelar",
            });
        },
        showConfirmSimple(title, text, icon, accion,status) {
            Swal.fire({
                title: title,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonText: accion,
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit("confirmed",status);
                }
            }); 
        },
        showConfirmVenta(title, text, icon, accion) {
            Swal.fire({
                title: title,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonText: accion,
                cancelButtonText: "Cancelar",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit("confirmed","1")
                }
            });   
        },
        showConfirmSimple2(title, text, icon, accion) {
            return new Promise((resolve) => {
                Swal.fire({
                    title: title,
                    text: text,
                    icon: icon,
                    showCancelButton: true,
                    confirmButtonText: accion,
                    cancelButtonText: "Cancelar",
                }).then((result) => {
                    resolve(result);
                });
            });
        },
        showSuccess(text) {
            Swal.fire({
                icon: "success",
                title: "Éxito",
                text: text,
            });
        },
        showWarning(text) {
            Swal.fire({
                icon: "warning",
                title: "Alerta",
                text: text,
            });
        },
        showInfo(text) {
            Swal.fire({
                icon: "info",
                title: "¡Atención!",
                text: text,
            });
        },
        showError(text) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: text,
            });
        },
        showDelete( index, msg) {
            Swal.fire({
                title: "¿Esta Seguro?",
                text: "¡No podras revertir esto!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Eliminar"
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "Elimininado!",
                        text: "Se ha eliminado correctamente",
                        icon: "success",
                       
                    });
                    this.$emit(msg , index)
                }
            });
        },

        showUpdateState(id,type){
           Swal.fire({
                icon: "warning",
                title: "¿Quieres cambiar el estado a Pendiente?",
                width: "400px",
                showDenyButton: false,
                denyButtonText: `Cancelar`,
                confirmButtonText: "Cambiar a Pendiente",
                confirmButtonColor: "#d33",
                showCancelButton: true,
                cancelButtonColor: "#3085d6",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit('mtdPermissionRollback',id,result.value,type,1)
                }
            });
        },

        showPermisions(id,type,msg,state){
            Swal.fire({
                    icon: "warning",
                    title: "¿Qué accción deseas tomar para este requerimiento?",
                    input: msg,
                    inputAttributes: {
                        autocapitalize: "off",
                        placeholder: "OBSERVACION",
                    },
                    width: "400px",
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cerrar",
                }).then((result) => {
                    if (result.isConfirmed) {
                        let  local=null;
                        if(state){
                            local=result.value;
                            // this.$emit('mtdPermissionCommit',id, result.value, type, 2) 
                        }
                        this.$emit('mtdPermissionCommit',id,local, type, 2)
                    } else if (result.isDenied) {
                        let  local=null;
                        if(state){
                            local=result.value;
                            // this.$emit('mtdPermissionCommit',id, result.value, type, 3) 
                        }
                         this.$emit('mtdPermissionCommit',id,local, type, 3)
                    }
                });
        }

    },
};
</script>

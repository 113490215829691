<template>
<div>
    <div class="container text-center">

        <div class="d-flex title-option">
            <div class="btn-home mt-3 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <ul class="nav pb-0 d-none d-lg-flex" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" v-if=" patient.id == ('523')">
                <button @click="changePage('campovisual_dx')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'campovisual_dx' }" id="home-tab" type="button">
                    <strong class="text-xs">Campo Visual</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('524')">
                <button @click="changePage('oct_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'oct_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">OCT</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('525')">
                <button @click="changePage('octmacula_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'octmacula_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">OCT Macula</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('526')">
                <button @click="changePage('paquimetria_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'paquimetria_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">Paquimetría</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('527')">
                <button @click="changePage('topografia_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'topografia_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">Topografía</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('528')">
                <button @click="changePage('microscopia_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'microscopia_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">Microscopía E.</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('529')">
                <button @click="changePage('ecografia_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'ecografia_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">Ecografía Ocular</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('530')">
                <button @click="changePage('biometria_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'biometria_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">Biometría</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('531')">
                <button @click="changePage('angiografia_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'angiografia_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">Angiografía R.</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation" v-if="patient.id == ('532')">
                <button @click="changePage('resonancia_dx')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'resonancia_dx' }" id="profile-tab" type="button">
                    <strong class="text-xs">Resonancia</strong>
                </button>
            </li>
        </ul>

        <div class="tab-content mb-3 px-0 d-none d-lg-block" id="myTabContent">
            <CampoVisual @mtdviewpage="mtdviewpage" @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='campovisual_dx'" class="px-3" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Oct @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='oct_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <OctMacula @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='octmacula_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Paquimetria @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='paquimetria_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Topografia @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='topografia_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Microscopia @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='microscopia_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Ecografia @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='ecografia_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Biometria @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='biometria_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Angiografia @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='angiografia_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
            <Resonancia @returnReadingResult="returnReadingResult" @returnHome="returnHome" v-if="page=='resonancia_dx'" :stateRolAdmin="stateRolAdmin" :patient="patient" />
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none mb-3">
            <div class="wrapper-list">
                <dropdown title="Campo Visual" component="CampoVisual" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('523')" :patient="patient"></dropdown>
                <dropdown title="OCT" component="Oct" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('524')" :patient="patient"></dropdown>
                <dropdown title="OCT Macula" component="OctMacula" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('525')" :patient="patient"></dropdown>
                <dropdown title="Paquimetría" component="Paquimetria" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('526')" :patient="patient"></dropdown>
                <dropdown title="Topografía" component="Topografia" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('527')" :patient="patient"></dropdown>
                <dropdown title="Microscopía E." component="Microscopia" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('528')" :patient="patient"></dropdown>
                <dropdown title="Ecografía Ocular" component="Ecografia" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('529')" :patient="patient"></dropdown>
                <dropdown title="Biometría" component="Biometria" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('530')" :patient="patient"></dropdown>
                <dropdown title="Angiografía R." component="Angiografia" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('531')" :patient="patient"></dropdown>
                <dropdown title="Resonancia" component="Resonancia" @changePage="changePage" @returnHome="returnHome" @returnReadingResult="returnReadingResult" v-if=" patient.id == ('532')" :patient="patient"></dropdown>
            </div>
        </div>
        <!--  -->

    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'

import CampoVisual from '@/components/Enfermera/AyudaDx/CampoVisual.vue';
import Oct from '@/components/Enfermera/AyudaDx/Oct.vue';
import OctMacula from '@/components/Enfermera/AyudaDx/OctMacula.vue';
import Paquimetria from '@/components/Enfermera/AyudaDx/Paquimetria.vue';
import Topografia from '@/components/Enfermera/AyudaDx/Topografia.vue';
import Microscopia from '@/components/Enfermera/AyudaDx/Microscopia.vue';
import Ecografia from '@/components/Enfermera/AyudaDx/Ecografia.vue';
import Biometria from '@/components/Enfermera/AyudaDx/Biometria.vue';
import Angiografia from '@/components/Enfermera/AyudaDx/Angiografia.vue';
import Resonancia from '@/components/Enfermera/AyudaDx/Resonancia.vue';
import Dropdown from "@/components/Enfermera/AyudaDx/Dropdown.vue";

export default {
    name: 'c-enfermera-ayudadx',
    components: {
        CampoVisual,
        Oct,
        OctMacula,
        Paquimetria,
        Topografia,
        Microscopia,
        Ecografia,
        Biometria,
        Angiografia,
        Resonancia,
        Dropdown
    },
    data() {
        return {
            page: 'campovisual_dx',
            stateHistory: null,
            viewInput: null,
            showItem: false,
            plans: [],
            pagep: 'ayudadx'
        }
    },
    props: {
        stateRolAdmin: Boolean,
        patient: Object
    },
    created() {
        this.mtddata();
    },
    mounted() {
        this.showItem = Object.keys(this.patient).length === 0;
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.pagep) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.pagep) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
        },
        returnHome() {
            this.plans = [];
            this.$emit('mtdChangePage', 'home');
        },
        returnReadingResult: function (patient) {
            //console.log(patient);
            if(patient.doctor_name !== 'DOCTOR INVITADO'){
            this.$emit("returnReadingResult", patient);
        }else{
            this.$emit('mtdChangePage', 'home');
            }
        },
        mtdviewpage: function (payload) {
            this.page = 'ayudadx'
        },
        mtddata: function () {
            if (this.patient.id == 523) {
                this.page = 'campovisual_dx';
            }
            if (this.patient.id == 524) {
                this.page = 'oct_dx';
            }
            if (this.patient.id == 525) {
                this.page = 'octmacula_dx';
            }
            if (this.patient.id == 526) {
                this.page = 'paquimetria_dx';
            }
            if (this.patient.id == 527) {
                this.page = 'topografia_dx';
            }
            if (this.patient.id == 528) {
                this.page = 'microscopia_dx';
            }
            if (this.patient.id == 529) {
                this.page = 'ecografia_dx';
            }
            if (this.patient.id == 530) {
                this.page = 'biometria_dx';
            }
            if (this.patient.id == 531) {
                this.page = 'angiografia_dx';
            }
            if (this.patient.id == 532) {
                this.page = 'resonancia_dx';
            }

        }

    }
}
</script>

<template>
<div class="px-2">
    <div class="text-center mt-2">
        <div id="content-history" v-if="opt == 1">
            <div class="row text-cent mt-2">
                <div class="col-xl-12 mb-2">
                    <div class="row d-flex justify-content-start align-items-center px-3">
                        <div class="col-xl-auto col-md-2 col-sm-2 ps-0 mt-2">
                            <div class="mt-1 mb-1 ms-auto btn-success">
                                <button type="button" class="btn btn-sm btn-outline-main w-100" @click="mtdBackPage()">
                                    <i class="fas fa-arrow-left"></i> Atrás
                                </button>
                            </div>
                        </div>
                        <div class="col-xl-4 col-md-5 col-sm-10 rounded-3 px-0 bg-blue mt-2">
                            <div class="row mx-0">
                                <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                                    <span class="align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                                    <span class="align-middle col-sm-8 h-100 px-0 text-start" v-if="patient">{{ patient.name }}</span>
                                    <span class="align-middle col-sm-8 h-100 px-0 text-start" v-else>{{ cpData[0].te }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-5 col-sm-12 rounded-3 px-0 bg-blue mt-2">
                            <div class="row mx-0">
                                <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                                    <span class="align-middle col-sm-4 h-100 px-2 text-start">Doctor:</span>
                                    <span class="align-middle col-sm-8 h-100 px-0 text-start" v-if="cpData[0].reservation.of_doctor">{{cpData[0].reservation.of_doctor.name}} {{cpData[0].reservation.of_doctor.last_name}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-1 col-md-4 mt-2">
                            <div id="div-te" class="form-group align-left d-flex">
                                <label for="te" class="color-main fw-bold align-self-center">T.E.</label>
                                <div class="flex-grow-1 ms-2">
                                    <input ref="tiempoEnfermedad" v-if="cpData[0]" type="text" v-model="cpData[0].te" readonly class="form-control form-control-input form-control-sm" id="te" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-1 col-md-4 btn-home mt-1 px-0">
                            <button @click="optaccion('2')" type="button" class="btn btn-outline-light w-100 btn-sm">
                                <strong>OPTOMETRIA</strong>
                            </button>
                        </div>
                        <div class="col-xl-2 col-md-4 pe-0 mt-1">
                            <div class="row d-flex justify-content-end">
                                <div class="col-12 px-0">
                                    <nav aria-label="Page navigation example" v-if="cpData.length > 0" class="pagination-container">
                                        <ul class="pagination pagination-xs justify-content-end" style="
                          margin-bottom: 0;
                          margin-top: 0.1rem;
                          padding-left: 40px;
                        ">
                                            <li class="page-item">
                                                <a style="cursor: pointer" href="javascript:void(0)" @click="backPage" class="page-link">«</a>
                                            </li>
                                            <template v-if="stepPagination.length > 0">
                                                <li class="page-item">
                                                    <a class="page-link active" href="javascript:void(0)">{{ stepPagination[selectPage - 1].date }}</a>
                                                </li>
                                            </template>
                                            <!-- <li class="page-item" v-if="selectPage < stepPagination.length - 4">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li class="page-item" v-if="selectPage < stepPagination.length - 3">
                                            <a class="page-link" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">
                                                {{ stepPagination.length }}
                                            </a>
                                        </li> -->
                                            <li class="page-item">
                                                <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-cent mt-2" v-for="(item1, index) in cpData" :key="index">
                <div class="col-xl-6 col-lg-12 text-xs">
                    <div>
                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(7)">
                            <h6 class="fw-bold color-main d-inline-block">Fondo De Ojos</h6>
                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[7].state && 'active']" />
                        </div>
                        <div class="panel-border mb-0 pt-1" v-if="titleDropdown[7].state">
                            <div class="row px-3">
                                <div class="col-md-12 my-1">
                                    <textarea id="relato" v-model="item1.fo" readonly class="form-control form-control-sm" rows="3" placeholder="" style="resize: none" />
                                    </div>
                </div>
              </div>
            </div>
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(5)"
            >
              <h6 class="fw-bold color-main d-inline-block">
                Motivo de Consulta
              </h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[5].state && 'active']"
              />
            </div>
            <!-- item1.reason -->
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[5].state">
              <div class="row px-3">
                <div class="col-md-12 my-1">
                  <textarea
                    ref="motivo"
                    id="relato"
                    v-model="item1.reason"
                    readonly
                    class="form-control form-control-sm"
                    rows="3"
                    placeholder=""
                    style="resize: none"
                  />
                </div>
              </div>
            </div>
            <div class="panel-border my-1 text-xs pt-1">
              <h6 class="color-main fw-bold">Exploración Física</h6>
              <div class="row px-3">
                <div class="col-md-12 mt-1 align-left">
                  <div id="div-otro" class="form-group row">
                    <div class="col-sm-12 mb-2">
                      <div
                        v-html="item1.physical_exploration"
                        readonly
                        class="form-control form-control-sm"
                        id="otro"
                        ref="refOtro"
                        style="resize: none"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(4)"
            >
              <h6 class="fw-bold color-main d-inline-block">Diagnosticos</h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[4].state && 'active']"
              />
            </div>
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[4].state">
              <div class="row my-1 px-3">
                <div class="col-md-12">
                  <table class="w-100">
                    <caption class="div-text"></caption>
                    <thead class="div-text div-title">
                      <tr>
                        <th class="color-main">#</th>
                        <th class="color-main">DX</th>
                        <th class="color-main">CIE10</th>
                        <th class="color-main">TIPO</th>
                      </tr>
                    </thead>
                    <tbody class="div-text">
                      <tr
                        v-for="(item1, index) in diagnostics"
                        :key="index"
                        class="px-1"
                      >
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          {{ item1.name }}
                        </td>
                        {{
                          item1.codigo
                        }}
                        <td>
                          {{ item1.type }}
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-12">
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(6)"
            >
              <h6 class="fw-bold color-main d-inline-block">Otros examenes</h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[6].state && 'active']"
              />
            </div>
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[6].state">
              <div class="row my-1 px-3">
                <div class="col-lg-3 col-md-3">
                  <label>Agregar examen</label>
                </div>
                <div class="btn-home col-lg-3 col-md-3 mb-2">
                  <button
                    type="button"
                    class="btn btn-sm"
                    disabled
                  >
                    <i class="fas fa-upload"></i> Cargar PDF
                  </button>
                </div>
                <div class="col-md-12">
                  <table class="w-100 justify-content-center">
                    <caption class="div-text"></caption>
                    <thead class="div-text div-title">
                      <tr>
                        <th scope="col">N°</th>
                        <th class="color-main w-25">Fecha</th>
                        <th class="color-main w-25">Name</th>
                        <th class="color-main w-25">Usuario</th>
                        <th class="color-main w-25">Acciones</th>
                      </tr>
                    </thead>
                    <tbody v-if="other_exams.length > 0" class="div-text">
                      <tr
                        v-for="(file, index) in other_exams"
                        :key="index"
                        class="px-1"
                      >
                        <td class=" ">{{ index + 1 }}</td>
                        <td class=" ">{{ file.date }}</td>
                        <td class=" ">{{ file.name }}</td>
                        <td class=" ">{{ file.user_name }}</td>
                        <td class="text-center align-middle">
                          <div class="d-flex justify-content-center">
                            <button
                              class="btn btn-sm btn-danger text-white"
                              @click="openPdf(file)"
                            >
                              <i class="fa fa-file-pdf"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else class="div-text fw-bold">
                      <tr>
                        <td colspan="5" class="text-center">
                          No hay archivos subidos
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block"
              @click="toggleDropdown(3)"
            >
              <h6 class="fw-bold color-main d-inline-block">Plan</h6>
              <img
                src="@/assets/helpers/circle-chevron-down-solid.svg"
                alt=""
                class="dropdown-icon-content d-inline-block float-end"
                :class="[titleDropdown[3].state && 'active']"
              />
            </div>
            <div class="panel-border mb-0 pt-1" v-if="titleDropdown[3].state">
              <div class="row px-3">
                <div class="col-xl-12 col-sm-12 py-2 pb-0">
                  <div
                    class="flex-input d-sm-flex row input-group input-group mx-0 mb-2"
                  >
                    <span
                      class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"
                      ><i class="fas fa-search"></i
                    ></span>
                    <v-select
                      class="col-xl-11 col-md-11 col-sm-11 px-0"
                      :options="dataLaboratorio"
                      disabled
                      :reduce="(laboratorio) => laboratorio"
                      label="name"
                      placeholder="Laboratorio"
                      required
                      v-model="plan_unit"
                    />
                  </div>
                </div>
                <div class="col-xl-12 col-sm-12 py-2 pb-0">
                  <div
                    class="flex-input d-sm-flex row input-group input-group mx-0 mb-2"
                  >
                    <span
                      class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"
                      ><i class="fas fa-search"></i
                    ></span>
                    <v-select
                      class="col-xl-11 col-md-11 col-sm-11 px-0"
                      :options="dataImagenes"
                      :reduce="(Imagenes) => Imagenes"
                      label="name"
                      placeholder="Imagenes"
                      required
                      disabled
                      v-model="plan_unit"
                    />
                  </div>
                </div>
                <div class="col-xl-12 col-sm-12 py-2">
                  <div
                    class="flex-input d-sm-flex row input-group input-group mx-0 mb-2"
                  >
                    <span
                      class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"
                      ><i class="fas fa-search"></i
                    ></span>
                    <v-select
                      class="col-xl-11 col-md-11 col-sm-11 px-0"
                      :options="dataProcedimiento"
                      :reduce="(Procedimiento) => Procedimiento"
                      label="name"
                      placeholder="Procedimiento"
                      required
                      disabled
                      v-model="plan_unit"
                    />
                  </div>
                </div>
              </div>
              <div class="row mx-0 px-3">
                <div
                  v-for="(planunit, index) in plan"
                  :key="index"
                  class="col-md-12 py-1"
                >
                  <div
                    class="input-group input-group btn-home px-0 div-pointer text-white fw-bold border bg-main h-100 border-2 align-middle"
                  >
                    <button
                      type=" button"
                      class="w-100 text-start"
                      disabled
                    >
                      {{ planunit.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-12 text-xs pe-xl-0">
            <div class="panel-border my-1" style="height: auto">
              <h6 class="color-main fw-bold">Indicaciones / Rp</h6>

              <div class="row my-1 px-0">
                <div id="" class="px-4">
                  <table class="w-100">
                    <caption class="div-text"></caption>
                    <thead class="div-text div-title">
                      <tr>
                        <th scope="col" class="color-main">Rp.</th>
                        <th class="color-main">Medicamentos</th>
                        <th class="color-main">Presentación</th>
                        <th class="color-main">Dosis</th>
                        <th class="color-main">Vía</th>
                        <th class="color-main">Frecuencia</th>
                        <th class="color-main">Duración</th>
                        <th class="color-main">Ojo</th>
                      </tr>
                    </thead>
                    <tbody class="div-text">
                      <tr
                        v-for="(recipeunit, index) in recipe"
                        :key="index"
                        class="px-1"
                      >
                        <td>
                          <span>{{ index + 1 }}</span>
                        </td>
                        <td>
                          <span>{{ recipeunit.molecule }}</span>
                        </td>
                        <td>
                          <span>{{ recipeunit.presentation }}</span>
                        </td>
                        <template v-if="recipeunit.multidosis && recipeunit.multidosis.length > 1">
                          <td colspan="4">
                            <span
                              >Este medicamento contiene
                              {{ recipeunit.multidosis.length }} dosis
                            </span>
                          </td>
                          <td>
                            <button
                              type="button"
                              @click="mtdOpenModalDosis(recipeunit)"
                              class="btn btn-info btn-sm px-1 py-0"
                            >
                              <i class="fas fa-eye text-white"></i>
                            </button>
                          </td>
                        </template>
                        <template v-if="recipeunit.multidosis && recipeunit.multidosis.length == 1">
                          <td>
                            <span>{{ recipeunit.dosis }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.via }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.frequency }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.duration }}</span>
                          </td>
                          <td>
                            <span>{{ recipeunit.ojo }}</span>
                          </td>
                        </template>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-12 my-1 text-xs">
            <div class="panel-border">
              <h6 class="color-main fw-bold">Recomendaciones</h6>
              <div class="row px-3 mb-2">
                <div class="col-md-12 mt-1 align-left">
                  <div id="div-otro" class="form-group row">
                    <div class="col-sm-12 mb-2">
                      <textarea
                        v-model="item1.recomendations"
                        class="form-control form-control-sm"
                        readonly
                        id="otro"
                        rows="4"
                        placeholder=""
                        style="resize: none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Optometria
        v-if="opt == 2"
        @optaccion="optaccion"
        :dataOptometria="cpData[0].optometry"
        :hc="cpData[0].id"
      />
      <cModalViewDosis
        :data="modalDosis.dosis"
        :product_id="modalDosis.product_id"
        :title="modalDosis.title"
        :boo_modal="modalDosis.modal_form"
        @mtdclosemodal="mtdclosemodal"
        :edit="modalDosis.edit"
      />
    </div>
  </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import Optometria from "./Historia/Optometria.vue";
import cModalViewDosis from "./Historia/cModalViewDosis.vue";
import moment from "moment";
export default {
    name: "c-medico-cosnultahc-historia",
    components: {
        Optometria,
        cModalViewDosis,
    },
    data() {
        return {
            status: false,
            page: "historia",
            /* paginacion */
            itemsPerPage: 1,
            dataShow: [],
            patient_id: null,
            selectPage: this.history.length,
            stepPagination: [],
            stateHistory: null,
            viewInput: null,
            productdetails: [],
            opt: "1",
            other_exams: [],
            other_exams_admision: [],
            other_examsadicional: [],
            fileExam_unit: {
                name: "",
                date: "",
                user_name: "",
                user_id: "",
                pdf_id: "",
            },
            inputchecked: false,
            inputcheckedRp: false,
            clinica_history: {
                id: null,
                user_id: null,
                patient_id: null,
                reason: "",
                fo: "",
                physical_exploration: "",
                other_exams: null,
                te: "",
                diagnostics: null,
                plan: null,
                recipe: null,
                recomendations: "",
                optometria: null,
                of_reservation_id: null,
                status: null,
                option: null,
            },
            selectedProduct: {
                molecule: "",
                presentacion: "",
                dosis: "",
                id: "",
            },
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: "",
                presentation: "",
                multidosis: [],
                dosis: "",
                via: "",
                frequency: "",
                duration: "",
                ojo: "",
            },
            recipe: [],
            recipeadicional: [],
            titleDropdown: [{
                    title: "Antecedentes",
                    state: true,
                },
                {
                    title: "Signos",
                    state: true,
                },
                {
                    title: "Signos vitales",
                    state: true,
                },
                {
                    title: "Plan",
                    state: true,
                },
                {
                    title: "DX",
                    state: true,
                },
                {
                    title: "Relato",
                    state: true,
                },
                {
                    title: "Otros examenes",
                    state: true,
                },
                {
                    title: "Fondo de ojos",
                    state: true,
                },
            ],
            dataLaboratorio: [],
            dataImagenes: [],
            dataProcedimiento: [],
            dataCies: [],
            plan_unit: null,
            plan_unit_detail: {
                name: "",
                of_service_id: null,
                of_product_id: null,
            },
            plan: [],
            planadicional: [],
            modal: {
                title: "",
                modal_form: false,
            },
            modalDosis: {
                title: "",
                modal_form: false,
                dosis: [],
                product_id: 0,
                edit: false,
            },
            diagnostics: [],
            diagnosticsadicional: [],
            diagnostics_unit: {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            },
            diagnostico: "",
            fo_wait_time: null,
        };
    },
    computed: {
        estadoChecklist: function () {
            const completadas = this.checklist.filter((item) => item.checked).length;
            return `${completadas} de ${this.checklist.length} tareas completadas.`;
        },
        disabledx: function () {
            return !this.diagnostics_unit.name;
        },
        cpData() {
            const startIndex = (this.selectPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            let itemTemporal = this.history.slice(startIndex, endIndex);
            if (itemTemporal.length > 0) {
                this.procesarPlans(itemTemporal[0]);
                this.procesarRecipe(itemTemporal[0]);
                this.procesarDiagnostics(itemTemporal[0]);
                this.procesarExams(itemTemporal[0]);
            }
            //console.log(this.history.slice(startIndex, endIndex));
            return this.history.slice(startIndex, endIndex);
        },
    },
    watch: {
        history: {
            handler() {
                // Llamas a mtdfilldata cuando history cambia
                this.mtdfilldata();
            },
            deep: true, // Para observar cambios en la profundidad de la historia
            immediate: true,
        },
    },
    props: {
        patient: {
            type: Object,
            default: () => ({}),
        },
        history: {
            type: Array,
        },
    },
    created() {
        this.mtdExamenesAdmision();
    },
    methods: {
        ...mapActions(["get", "post"]),
        changePage: function (page) {
            this.page = page;
            if (page === "cola") {
                this.patients = {};
            }
            this.stateHistory = page === "historia" ? 1 : 0;
            this.viewInput = page === "historia" ? 1 : 0;
        },
        mtdOpenModalDosis: function (item) {
            this.modalDosis = {
                title: "DOSIS DE " + item.molecule,
                modal_form: true,
                dosis: item.multidosis,
                product_id: item.product_id,
                edit: false,
            };
        },
        mtdExamenesAdmision: function () {
            this.patient_id = this.patient.id;
            this.get({
                    url: this.$store.getters.get__url +
                        "/Doctor/indexexamenes/" +
                        this.patient.id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        if (response.data.length > 0) {
                            response.data.forEach((element) => {
                                this.fileExam_unit = {
                                    name: element.name,
                                    date: element.date,
                                    user_name: element.user.name,
                                    user_id: element.user.id,
                                    pdf_id: element.id,
                                };
                                this.other_exams_admision.push(this.fileExam_unit);
                            });
                        } else {
                            this.other_exams_admision = [];
                        }

                        // this.other_exams = response.data
                    }
                })
                .catch((errors) => {});
        },
        returnHome() {
            this.$emit("mtdChangePage", "home");
            this.imagenes = [];
            this.plan = [];
        },
        mtdBackPage() {
            this.$emit("changeSubpage", 'table');
        },
        mtdviewpage: function (payload) {
            this.page = "historia";
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput = 0;
        },
        mtdopenmodal: function () {
            this.modal = {
                title: "DESTINO",
                modal_form: true,
            };
        },
        optaccion(num, dataOptometria) {
            this.opt = num;
            //this.clinica_history.optometria = payload;
            this.dataOptometria = dataOptometria;
        },
        toggleDropdown(title) {
            this.titleDropdown[title].state = !this.titleDropdown[title].state;
        },
        mtdclosemodal: function () {
            this.modal = {
                title: "",
                modal_form: false,
            };
            this.modalDosis = {
                title: "",
                modal_form: false,
                dosis: [],
                product_id: 0,
            };
        },
        mtdfilldata: function () {
            this.selectPage = this.history.length;
            this.stepPagination = [];
            let cantidad = parseInt(this.history.length);

            for (let index = 0; index < cantidad; index++) {
              let fechaFormateada = moment(this.history[index].created_at).format(
                  "DD/MM/YYYY");

                        let item = {
                            index: index + 1,
                            date: fechaFormateada,
                        };
                        this.stepPagination.push(item);
            }
            this.historyItem = this.history[this.selectPage - 1];

            if (this.history.length > 0) {
                if (Object.keys(this.historyItem).length !== 0) {
                    this.clinica_history.id = this.historyItem.id;
                    this.procesarPlans(this.historyItem);
                    this.procesarRecipe(this.historyItem);
                    this.procesarDiagnostics(this.historyItem);
                    this.procesarExams(this.historyItem);
                    this.clinica_history.reason = this.historyItem.reason;
                    this.clinica_history.physical_exploration =
                        this.historyItem.physical_exploration;
                    this.clinica_history.fo = this.historyItem.fo;
                    this.clinica_history.te = this.historyItem.te;
                    this.clinica_history.recomendations = this.historyItem.recomendations;
                    this.clinica_history.optometria = this.historyItem.optometria;
                    this.clinica_history.of_reservation_id =
                        this.historyItem.of_reservation_id;
                }
            }
        },
        procesarDiagnostics: function (historyItem) {
            if (historyItem.diagnostics != null) {
                this.diagnostics =
                    historyItem.of_reservation_id == this.patient.id_reservation &&
                    this.diagnosticsadicional.length > 0 ?
                    this.diagnosticsadicional :
                    JSON.parse(historyItem.diagnostics);
            } else {
                this.diagnostics =
                    this.diagnosticsadicional.length > 0 ? this.diagnosticsadicional : [];
            }
        },
        procesarPlans: function (historyItem) {
            if (historyItem.plan != null) {
                this.plan =
                    historyItem.of_reservation_id == this.patient.id_reservation &&
                    this.planadicional.length > 0 ?
                    this.planadicional :
                    JSON.parse(historyItem.plan);
            } else {
                this.plan = this.planadicional.length > 0 ? this.planadicional : [];
            }
            if (this.plan.length > 0) {
                // Comprobar si algún elemento en el plan tiene el ID de servicio igual a 4
                this.status = this.plan.some((element) => element.of_service_id === 4);
            } else {
                this.status = false;
            }
        },
        procesarRecipe: function (historyItem) {
            if (historyItem.recipe != null) {
                this.recipe =
                    historyItem.of_reservation_id == this.patient.id_reservation &&
                    this.recipeadicional.length > 0 ?
                    this.recipeadicional :
                    JSON.parse(historyItem.recipe);
            } else {
                this.recipe =
                    this.recipeadicional.length > 0 ? this.recipeadicional : [];
            }
        },
        procesarExams: function (historyItem) {
            if (historyItem.other_exams != null) {
                this.other_exams =
                    historyItem.of_reservation_id == this.patient.id_reservation &&
                    this.other_examsadicional.length > 0 ?
                    this.other_examsadicional :
                    JSON.parse(historyItem.other_exams);
            } else {
                this.other_exams =
                    this.other_examsadicional.length > 0 ?
                    this.other_examsadicional :
                    this.other_exams_admision;
            }
        },
        /** pagination */
        selectedPage: function (page) {
            this.selectPage = page.index;
        },
        backPage: function () {
            this.selectPage = this.selectPage === 1 ? 1 : this.selectPage - 1;
        },
        nextPage: function () {
            this.selectPage =
                this.selectPage === this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;
        },
        openPdf: function (pdf) {
            this.get({
                    url: this.$store.getters.get__url + "/Doctor/getExamen/" + pdf.pdf_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        let url = response.data.url;
                        let nuevaUrl = "/assets/" + url;
                        window.open(nuevaUrl, "_blank");
                    }
                })
                .catch((errors) => {});
            // console.log(pdf);
        }
    },
    
};
</script>


<template>
  <div class="container">
    <div id="btn-return" class="d-flex justify-content-end pt-1 mt-3">
      <div class="btn-home" role="group">
          <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
              <i class="fas fa-recycle"></i> Cambiar de Sede
          </button>
      </div>
  </div>
  <div class="row " id="div_conte2">
    <div id="div_conte" class="col-md-10 mx-auto ">
        <div  class="bg-secondary text-white bg-opacity-10">
            <div class="text-center py-4 px-5 " id="scroll-div">
                <div class="row">
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('cirugias')">
                        CIRUGIAS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('consultorios')">
                        CONSULTORIOS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('documentos')">
                        DOCUMENTOS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('farmacia')">
                        FARMACIA
                    </button>
                </div>
                <div class="row">
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('imagen')">
                        IMAGENES
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('insumos')">
                        INSUMOS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('laboratorio')">
                        LABORATORIO
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('optica')">
                        OPTICA
                    </button>
                </div>
                <div class="row">
                    
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('provider')">
                        PROVEEDORES
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('user')">
                        USUARIOS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('marca')">
                        MARCAS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('hours')">
                        HORAS
                    </button>
                </div>
                <div class="row">
                  <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('almacen')">
                        ALMACENES
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('motivo')">
                        MOTIVOS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('medios')">
                        MEDIOS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('categoria')">
                        CATEGORIA
                    </button>
                </div>
                <div class="row">
                  <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('conclusiones')">
                        CONCLUSIONES
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('servicios')">
                      SERVICIOS
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('doctores')">
                      DOCTORES
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('campus')">
                      SEDE
                    </button>
                    <button class=" div-campus fw-bold col m-2 p-2 text-white" @click="mtdChangePage('roles')">
                      ROLES
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>
  </div>
</template>

<script>
export default {
  name: 'HomeComponent',
  props: {
  },
  components: {
  },
  data () {
    return {
      page: null,
      swal:'TITULO POR  PROPS',
    }
  },
  created () {
    
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page) return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page) return 'color:white';
      return '';
    },
    mtdChangePage: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    },
    mtdBackCampus: function () {
          this.$emit("mtdBackCampus");
      },
  },
}
</script>
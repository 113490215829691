<template>
<div>
    <div class="container">
        <div class="d-flex flex-row-reverse">
            <div class="mt-5 mb-4 btn-home">
                <button type="button" class="btn text-white" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="card-header text-white h4 bg-main text-center">
                    <strong>OPTICA</strong>
                </div>
                <div class="card-body w-100">
                    <cTable :columns="columns" :data="data" @openmodal="openmodal" @deleteData="deleteData" />
                </div>
            </div>
        </div>
        <cModalOpticaVue :dataBrands="dataBrands" :num="modal.num" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
        <SweetAlert :swal="swal" ref="SweetAlert" @deleteDataDetail="deleteDataDetail" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import cTable from '../shared/Table/cTable.vue'
import cModalOpticaVue from './Modals/cModalOptica.vue'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
    name: 'c-mantenimiento-Laboratorios',
    data() {
        return {
            swal: null,
            columns: [
                'N°',
                'NOMBRE',
                'MARCA',
                'MODELO',
                'SERIE',
                'COSTO S/IGV',
                'COSTO C/IGV',
                'VENTA S/IGV',
                'VENTA C/IGV',
                'ESTADO',
                'ACCIONES'

            ],
            data: [],
            dataBrands: [],
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        }
    },
    created() {
        this.getData();
    },
    components: {
        cTable,
        cModalOpticaVue,
        SweetAlert
    },
    methods: {
        ...mapActions(['get', 'post']),
        getData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/index/8",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    const dataArray = response.data;
                    dataArray.forEach(item => {
                        this.calculatePricesIGV(item);
                    });
                    this.data = dataArray;
                    this.getProvedores();
                })
                .catch(error => {
                    console.log(error);
                })
        },
        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.mantenimientodetail(payload_detail)
            } else {
                payload_detail.action = 'u';
                this.mantenimientodetail(payload_detail)
            }
        },
        mantenimientodetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/Optica",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (payload_detail.action != 'd') {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.closeModal()
                    }
                    this.getData();
                })
                .catch(error => {
                    console.log(error);
                })
        },
        deleteData: function (payload_detail) {
            payload_detail.action = 'd';
            this.$refs.SweetAlert.showDelete(payload_detail, 'deleteDataDetail');

        },
        deleteDataDetail: function (payload_detail) {
            this.mantenimientodetail(payload_detail)
        },
        getProvedores: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexBrands",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    this.dataBrands = response.data;
                })
                .catch(error => {
                    console.log(error);
                })
        },
        calculatePricesIGV: function (item) {
            const priceCost = parseFloat(item.price_cost);
            const priceSale = parseFloat(item.price_sale);
            const igvPercentage = 1.18;
            const priceCostIGV = priceCost * igvPercentage;
            const priceSaleIGV = priceSale * igvPercentage;
            item.price_cost_igv = priceCostIGV.toFixed(2);
            item.price_sale_igv = priceSaleIGV.toFixed(2);
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        openmodal: function (num, payload) {
            console.log(payload);
            if (num !== 0) {
                if (payload.brand_id == null) {
                    payload.brand_id = '';
                    payload.modelo = '';
                    payload.serie = '';
                }
            }

            this.modal.title = (num == 0) ? 'Registrar Optica' : 'Editar Optica';
            this.modal.modal_form = true;
            this.modal.num = num;
            this.modal.payload = payload;

        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }

        },

    }
}
</script>

<template>
  <CModal
   @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'lg'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12" v-if="item.state == 0">
        <div  class="mb-3 row"> 
          <div class="d-flex justify-content-evenly">
          <button type="button" class="btn w-25 mx-1" :class="{ 'bg-success text-white': item.json.enviada_a_sunat, 'bg-danger text-white': !item.json.enviada_a_sunat }">Enviado a Sunat</button>
          <button type="button" class="btn w-25 mx-1" :class="{ 'bg-success text-white': item.json.aceptada_por_la_sunat, 'bg-danger text-white': !item.json.aceptada_por_la_sunat }">{{ item.json.aceptada_por_la_sunat?"Aceptado Por Sunat":" No Aceptado Por Sunat" }}</button>
          <button type="button" class="btn w-25 btn-warning mx-1" v-if="item.json.documento_anulado">Documento Anulado</button>
        </div>

        </div>
        <div class="mb-3 row">
          <label class="col-sm-2 col-form-label">Mensaje sunat</label>
          <div class="col-sm-10">
            <input
            type="text"
            readonly
            class="form-control"
            :value="respuesta(item)"
          />

          </div>
        </div>
        <div class="mb-3 row">
          <label class="col-sm-2 col-form-label">Notas de sunat</label>
          <div class="col-sm-10">
            <input
            type="text"
            readonly
            class="form-control"
            :value="respuestaNota(item)"
          />

          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-xs-12" v-if="item.state == 0">
        <div class="d-flex justify-content-evenly">
          <button type="button" @click="mtdOpenDoc(item.json.enlace_del_pdf_ticket)" class="btn w-25 btn-outline-info"><i class="fas fa-file-pdf"></i><br>Pdf</button>
          <button type="button" @click="mtdOpenDoc(item.json.enlace_del_xml)" class="btn w-25 btn-outline-warning"><i class="fas fa-file-code"></i><br>Xml</button>
          <button type="button" class="btn w-25 btn-outline-success" v-if="garantias.length>0 && doc.sale.pending == 0"><i class="fas fa-file-code"></i><br>Canjear Documento</button>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      &nbsp;
    </template>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import { mapActions } from "vuex";

export default {
  name: "c-vigilancia-modal-control",
  components: {
    CModal,
    CButtonClose,
  },
  data() {
    return {
      filter: {
        filter_floor_id: null,
        filter_campu_id: 0,
        bed: "",
      },
      dataFloor: [],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: {},
    size: String,
    action: String,
    garantias:Array,
    doc:{}
  },
  computed: {
    btnClasses() {
      return [`btn btn-${this.color || "primary"}`];
    },
    cpButtonFormFloor: function () {
      if (this.Floor.name.length > 0 && this.Floor.campu_id != "") return false;
      return true;
    },
    cpGarantias(){
      let string = "";
      if (this.garantias.length > 0) {
        this.garantias.forEach(element => {
          string += element.serie+"-"+element.correlativo+",";  
        });  
      }
      return string.substring(0,string.length - 1);
    }
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdOpenDoc: function(link){
      window.open(link,'_blank');
    },
    filterKey: function (e) {
      let b = false;
      b = /^[0-9]+$/.test(e.key);
      if (b == false && e.key != "Backspace") {
        e.preventDefault();
      }
    },
    mtdClose() {
      this.$emit("closeModalSunat");
    },
    respuesta(item){
    return    item.json.sunat_descripcion ;
    },
    respuestaNota(item){
    return  item.json.resumen_diario?item.json.resumen_diario.sunat_descripcion:'No tiene notas';
    }
  },
};
</script>

<style scoped>
.inpt_color {
  text-align: center !important;
  background-color: cadetblue !important;
  border-radius: 20px;
  color: white;
}
.inpt_color::placeholder {
  color: white;
}
</style>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
      <div class="row d-flex">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-3">
            <div class="row rounded-5 d-flex justify-content-center border-2 h-100 p-2" id="div-img-campovisual">
                <div id="carouselExampleIndicators" class="carousel slide home-carousel mt-0" data-bs-ride="carousel" v-if="dataImg.length > 0">
                    <div class="carousel-indicators">
                        <button v-for="(imagen, index) in dataImg" :key="index" type="button" :data-bs-target="'#carouselExampleIndicators'" :data-bs-slide-to="index" :class="{ 'active': index === 0 }" aria-current="true" :aria-span="'Slide ' + (index + 1)"></button>
                    </div>
                    <div class="carousel-inner">
                        <div v-for="(imagen, index) in dataImg" :key="index" :class="{ 'carousel-item': true, 'active': index === 0 }">
                            <img :src="'https://oftalmologia.howertpi.com/assets/'+imagen.route" class="d-block w-100" :data-bs-slide-to="index" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <div class="h-100 p-5" v-if="dataImg.length == 0">
                    <h3>No hay Imagenes para mostrar</h3>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-md-6 col-lg-6 col-sm-12  px-0">
            <div class="row rounded-5 border-2 h-100" id="div-img-campovisual">
                <div class="justify-content-center p-1 z-index-button" v-if="dataPdf[0]">
                    <iframe :src="'https://oftalmologia.howertpi.com/assets/'+dataPdf[0].route" width="100%" height="600px" title="Pdf"></iframe>
                </div>
                <div class="justify-content-center p-5 z-index-button" v-if="!dataPdf[0]">
                    <h3>No hay Archivos para mostrar</h3>
                </div>
            </div>
        </div>
      </div>
      <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
          <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
      </template>
    </CModal>
  </template>
    
    <script>
  import CModal from "../../shared/modals/CModal.vue";
  import { mapActions } from "vuex";
  
  export default {
    name: "c-oftalmologia-modal-iamgen",
    components: {
      CModal,
    },
    data() {
      return {};
    },
    props: {
      title: String,
      boo_modal: Boolean,
      dataImg: Array,
      dataPdf: Array,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdClose() {
        this.$emit("closeCarrusel");
      },
    },
  };
  </script>
    
  
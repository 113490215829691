<template>
    <div>
        <div class="container">
            <div class="d-flex flex-row-reverse">
                <div class="mt-5 mb-4 btn-home">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
                </div>
            </div>
            <div id="div-modal-anular" class="w-100">
                <div id="div-anular" class="card w-100">  
                    <div  class="card-header text-white h4 text-center bg-main" >
                       <strong>ALMACEN/INVENTARIO</strong>
                    </div>
                    <div  class="cuerpo">
                      
                      <Categoria/>
                    </div>
                </div>
                
            </div>
        </div>
        
    </div>
    
</template>
<script>
// import { mapActions } from "vuex";

// import Categoria from "../../../components/Logistica/pages/Inventario/Categoria.vue"
export default{
    name:'Inventario',
    // components:{
    //   Categoria
    //   },

   
    methods:{
//metodo retornar a Home
        returnHome: function () {
            this.$emit("returnHome");
            },
     }
}

</script>

<template>
    <div>
      <div class="w-100 h-100">
        <Nav :rolname="rolname" />
      </div>
      <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" @mtdSelectPatient="mtdSelectPatient"  />
        <AyudaDx v-if="page=='ayudadx'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" :patient="patient" @returnReadingResult="returnReadingResult"/>
        <Consulta v-if="page=='consulta'" @mtdChangePage="mtdChangePage" :stateRolAdmin="stateRolAdmin" />
        <Cita v-if="page=='cita'" :patient="patient" @mtdChangePage="mtdChangePage" />
      
      </div>
      <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :patient="patient" :pageView='page' />
      </div>
    </div>
  </template>
  
  <script>
    import { mapActions } from 'vuex'
    import Nav from '../../components/Enfermera/Nav.vue'
    import Home from '../../components/Enfermera/Home.vue'
    import AyudaDx from '../../components/Enfermera/AyudaDxView.vue'
    import Consulta from '../../components/Enfermera/ConsultaView.vue'
    import Cita from '../../components/Enfermera/CitasView.vue'
    
    import Footer from '../../components/Enfermera/Footer.vue'
  
    export default {
      name: "EnfermeraView",
      components: {
        Nav,
        Home,
        AyudaDx,
        Consulta,
        Cita,
        Footer,
      },
      data () {
        return {
          prefix: 'enfermera',
          page:'home',
          rol_name: null,
          stateRolAdmin: false,
          patient:{}
        }
      },
      props: {
        rolname: String,
        dataRoles: Array
        
      },
      created () {
        this.rol_name = this.rolname;
        this.mtdGetRolAdmin();
      },
      methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function(page){
          this.page = page;
          this.patient = {};
        },
        mtdBackCampus: function () {
          this.$emit("mtdBackCampus");
      },
        mtdGetRolAdmin:function(){
          this.dataRoles.forEach(element => {
            if(element.role_id==1){
              this.stateRolAdmin = true;
            }
          });
        },
        mtdSelectPatient: function (patient) {
          this.patient= patient;
          this.page="ayudadx";
        },
        returnReadingResult: function (patient) {
          this.patient= patient;
          this.page="cita";
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
      }
      
    }
  </script>
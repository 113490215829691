<template>
  <div>    
    <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2" style="background: rgb(162, 162, 162)">
      <div :style="mtdGetStyle('document')" class="navbar-brand pl-2 text-white text-center pr-2
         nav-footer w-100 border-end border-white border" @click="mtdSendOption('document')">
        <span :style="mtdGetStyleSpan('document')" class="btn w-100"><strong>DOCUMENTOS</strong></span></div>
        <div :style="mtdGetStyle('ingreso')" class="navbar-brand pl-2 text-white text-center pr-2
         nav-footer w-100 border-end border-white border" @click="mtdSendOption('ingreso')">
        <span :style="mtdGetStyleSpan('ingreso')" class="btn w-100"><strong>INGRESOS</strong></span></div>
        <div :style="mtdGetStyle('notas')" class="navbar-brand pl-2 text-white text-center pr-2
         nav-footer w-100 border-end border-white border" @click="mtdSendOption('notas')">
        <span :style="mtdGetStyleSpan('notas')" class="btn w-100"><strong>NOTAS DE CREDITO</strong></span></div>
       <div :style="mtdGetStyle('volveremitir')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer 
       w-100 border-end border-white border"
        @click="mtdSendOption('volveremitir')">
        <span :style="mtdGetStyleSpan('volveremitir')" class="btn w-100"><strong>VOLVER EMITIR</strong></span></div>
        <div :style="mtdGetStyle('ordencompra')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer 
       w-100 border-end border-white border"
        @click="mtdSendOption('ordencompra')">
        <span :style="mtdGetStyleSpan('ordencompra')" class="btn w-100"><strong>FACTURA OC</strong></span></div>
        <div :style="mtdGetStyle('ordenservicio')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer 
       w-100 border-end border-white border"
        @click="mtdSendOption('ordenservicio')">
        <span :style="mtdGetStyleSpan('ordenservicio')" class="btn w-100"><strong>FACTURA OS</strong></span></div>
      <!-- <div :style="mtdGetStyle('altas')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('altas')"><span :style="mtdGetStyleSpan('altas')" class="btn w-100">ALTAS CHECKOUT</span></div>
      <div :style="mtdGetStyle('vueltos')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('vueltos')"><span :style="mtdGetStyleSpan('vueltos')" class="btn w-100">VUELTOS</span></div>
      <div :style="mtdGetStyle('cxc')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('cxc')"><span :style="mtdGetStyleSpan('cxc')" class="btn w-100">CXC</span></div>
      <div :style="mtdGetStyle('recibo')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('recibo')"><span :style="mtdGetStyleSpan('recibo')" class="btn w-100">RECIBO POR HONORARIOS</span></div>
      <div :style="mtdGetStyle('pasaje')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('pasaje')"><span :style="mtdGetStyleSpan('pasaje')" class="btn w-100">PASAJE</span></div>
      <div :style="mtdGetStyle('cola')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('cola')"><span :style="mtdGetStyleSpan('cola')" class="btn w-100">COLA DE PACIENTES</span></div> -->
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavBarComponent',
  props: {
    pageView:{
      type:String,
      default:""
    }
  },
  data () {
    return {
      page: null
    }
  },
  created () {
   
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != 'home' && this.pageView != 'close' && this.pageView != 'venta' ) return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != 'home' && this.pageView != 'close' && this.pageView != 'venta') return 'color:white';
      return 'text-dark';
    },
    mtdSendOption: function(page){
        this.page=page;
        this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>

<style scoped>

#contenedor-menu {
  justify-content: center;
}

#lista_menu_home {
  font-family:Arial;
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding-left: 0 !important;
}

li {
  background-color: #900052;
  float: left;
  text-align: center;
  text-decoration:none;
}

li a{
  color:#ffffff;
  font-weight:bold;
  margin: 0;
}

.nav-footer:hover {
  color:#000000;
  /* background-color:rgb(162, 162, 162); */
  background: #900052;
}

#nav{
    position: absolute;
    float:left;
    bottom:0px;
}
#img_logo{
    position: absolute;
    float:left;
    left:-5px;
    top:-5px;
}

@media(max-width: 800px){
  #title-user, #title-cash, #title, #title-date{
    text-align: right !important;
  }
  #img_logo img{
    width: 175px !important;
  }

  li {
    float: none;
  }

  #nav-menu {
    position: inherit;
  }
  /**  */
  #nav-menu{
    flex-direction: column;
  }
}
@media(min-width: 800px){
  #title-cash, #title, #title-date{
    text-align: center !important;
  }
  #title-user{
    text-align: right !important;
  }
}
</style>

<template>
    <div>
      <div class="container text-center mt-2">
  
        <div class="d-flex title-option">
          <h2 class=" h2-borde mt-3 mb-3 ms-5 text-main pt-1 px-4">
            <strong>RESONANCIA</strong>
          </h2>
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import {
      mapActions
  } from 'vuex'
  
  export default {
      name: 'c-enfermera-resonancia',
      components: {
      },
      data() {
        return {
          page: 'resonancia_dx',
        }
      },
      props:{
          stateRolAdmin: Boolean,
          patient: Object,
      },
      created() {
          
      },
      methods: {
          ...mapActions(['get', 'post']),
          mtdGetStyle: function (option) {
              if (option == this.page) return 'background: #900052';
              return '';
          },
          mtdGetStyleSpan: function (option) {
              if (option == this.page) return 'color:white';
              return '';
          },
          returnHome() {
           this.$emit('mtdChangePage','home')
          },
          
      }
  }
  </script>
<template>
  <div>
    <div class="p-5">
      <div class="row">
        <div
          id="btn-home"
          class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end"
        >
          <button
            type="button"
            class="btn btn-outline-main"
            @click="changePage('home')"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class="card w-100">
          <div
            align="center"
            class="card-header text-white h4"
            style="background: #900052"
          >
            <strong>DOCUMENTOS DE VENTA</strong>
          </div>

          <div class="card-body w-100">
            <div class="row">

              <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                <div class="input-group input-group-sm">
                  <span class="input-group-text col-md-3">Desde</span>
                  <input
                    type="date"
                    aria-label="shift_date"
                    class="form-control form-control-sm"
                    id="begindate"
                    v-model="filter.begindate"
                  />
                </div>
              </div>

              <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                <div class="input-group input-group-sm">
                  <span class="input-group-text col-md-3">Hasta</span>
                  <input
                    type="date"
                    aria-label="shift_date"
                    class="form-control form-control-sm"
                    id="enddate"
                    v-model="filter.enddate"
                  />
                </div>
              </div>

              <div class="col-md-12 col-xl-2 col-lg-2 col-sm-12 col-xs-12 mb-2">
                <button
                  type="button"
                  name="accion"
                  class="btn btn-sm w-100 btn-register text-white"
                  @click="mtdGetData"
                >
                  <i class="fas fa-search"></i> Buscar
                </button>
              </div>
      
              <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 text-center">
                <button
                  type="button"
                  name="accion"
                  class="btn btn-sm w-100 btn-register text-white"
                  @click=" exportExcel()"
                >
                <i class="fas fa-file-excel"></i> Exportar
                </button>
              </div>

              <!-- <div class="col-md-6 py-2">
                <div class="d-flex">
                  <div class="input-group input-group mb-3">
                    <span
                      class="input-group-text text-white"
                      style="background: #900052"
                      ><i class="fas fa-search"></i
                    ></span>
                    <input
                      @keyup="mtdSearch"
                      v-model="search"
                      type="text"
                      class="form-control form-control"
                      placeholder="Nombre y Apellido"
                    />
                  </div>
                </div>
              </div> -->
              <div class="col-md-12  py-2">
                <div class="d-flex">
                  <div class="input-group input-group mb-3">
                    <span
                      class="input-group-text text-white"
                      style="background: #900052"
                      ><i class="fas fa-id-card"></i></span>
                    <input
                      @keyup="mtdSearchDni"
                      v-model="searchDni"
                      type="text"
                      class="form-control form-control"
                      placeholder="
                      DNI"
                    />
                  </div>
                </div>
              </div>

              <!--tabla-->
              <div class="col-md-12 table-responsive">
                <table  class="table table-bordered border-main">
                  <thead> 
                    <tr
                      align="center"
                      class="text-white border-white"
                      style="background: #900052"
                    >
                      <th scope="col">N°</th>
                      <th scope="col">TIPO VENTA</th>
                      <th scope="col">FECHA</th>
                      <th scope="col">TIPO DOC</th>
                      <th scope="col">SERIE</th>
                      <th scope="col">CORRELATIVO</th>
                      <th scope="col">DNI</th>
                      <th scope="col">A NOMBRE</th>
                      <th scope="col">MONTO</th>
                      <th scope="col">PER. FACTURACIÓN</th>
                      <th scope="col">PER. INGRESO</th>
                      <th scope="col">SUJETO A</th>
                      <th scope="col">ESTADO</th>
                      <th scope="col">ESTADO SUNAT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="dataShow.length === 0">
                      <td class="text-center" colspan="29">{{noRecordsMessage}}</td>
                    </tr>
                    <tr class="" v-for="(item, index) in cpData" :key="index" v-if="item.of_document_type_id!=3">
                      <td align="center">{{ index + 1 }}</td>
                      <td align="center">{{ item.sale.type?item.sale.type.toUpperCase():'-' }}</td>
                      <td align="center">{{item.date | formatDate}} </td>
                      <td align="center">{{item.document.toUpperCase()}}</td>
                      <td align="center">{{item.serie}} </td>
                      <td align="center">{{ item.number }}</td>
                      <td align="center">{{item.vaucher_document}}</td>
                      <td align="center">{{item.vaucher_fullname.toUpperCase()}}</td>
                      <td align="center">S/ {{ item.amount }}</td>
                      <td align="center">{{item.billing_period}}</td>
                      <td align="center">{{item.admission_period?item.admission_period:'-'}}</td>
                      <td align="center">{{item.detraccion?'DETRACCIÓN':(item.retencion?'RETENCIÓN':'-')}}</td>
                      <td align="center">{{ item.state==1?'-':(item.state==2?(item.notecredit==null?'ANULADO':
                      'CON NOTA DE CREDITO'):'-')}}</td>
                      <td align="center">
                        <button v-if="item.sunat != null" class="btn btn-sm btn-info text-white" @click="mtdViewSunat(item.sunat,item)"><i class="fa fa-eye"></i></button>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--paginate-->
              <div class="d-flex justify-content-center" style="align-items: center">
                    <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                            </li>
                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                            </li>
                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>
                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                            </li>
                            <li v-if="
                    limitedStepPagination[limitedStepPagination.length - 1] <
                    stepPagination.length - 1
                  " class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>
                            <li v-if="
                    limitedStepPagination[limitedStepPagination.length - 1] !==
                    stepPagination.length
                  " class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}</a>
                            </li>
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <cModalSunatVue
      :title="modalSunat.title"
      :boo_modal="modalSunat.modal_form"
      :item="modalSunat.item"
      :garantias="modalSunat.garantias"
      :doc="modalSunat.doc"
      @closeModalSunat="closeModalSunat"
    >
    </cModalSunatVue>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import cModalSunatVue from '../modals/cModalSunat.vue';
import exportFromJson from "export-from-json";

export default {
  name: "c-contabilidad-pages-document",
  components:{
    cModalSunatVue
  },
  data() {
    return {
        search:"",
        dataShow: [],
        dataCxcs:[],
        totalPagesToShow: 7,
        page: 0,
        selectPage: 1,
        searchDni:"",
        stepPagination: [],
        noRecordsMessage:"",
        filter:{
          begindate:null,
          enddate:null
        },
        modalSunat: {
          action: "",
          title: "",
          modal_form: false,
          item: [],
          garantias:[],
          doc:{}
        },
        excel:[]
    };
  },
  computed: {
    cpData() {
      let filteredData = this.dataCxcs;
      if (this.search.length >= 4 && this.search != "") {
        let query = this.search.toString().toUpperCase();
        filteredData = this.dataCxcs.filter((element) => {
          let name = element.vaucher_fullname.toString().toUpperCase();
          return name.includes(query);
        });
      }
      if (this.searchDni.length >= 4 && this.searchDni != "") {
        let query = this.searchDni.toString().toUpperCase();
        filteredData = this.dataCxcs.filter((element) => {
          let name = element.vaucher_document.toString().toUpperCase();
          return name.includes(query);
        });
      }
      let totalPages = Math.ceil(filteredData.length / 10);
      
      this.stepPagination = Array.from(
        {
          length: totalPages,
        },
        (_, index) => index + 1
      );
      let startIndex = (this.selectPage - 1) * 10;
      let endIndex = startIndex + 9;
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      return this.dataShow;
    },
   
    // cpData() {
    //   let bo = false;
    //   if (this.search.length >= 5 && this.search != "") {
    //     let q = this.search.toString().toUpperCase();
    //     this.dataShow = [];
    //     this.excel=[];
    //     this.dataCxcs.forEach((element, i) => { 
    //     let name = element.vaucher_fullname.toString().toUpperCase();
    //       if (name.includes(q)) {
    //         this.dataShow.push(element);
    //           this.excel.push({
    //             "TIPO VENTA":element.sale.type?element.sale.type.toUpperCase():'-',
    //             FECHA:element.date,
    //             "TIPO DOC": element.document.toUpperCase(),
    //             "SERIE":element.serie,
    //             CORRELATIVO:element.number ,
    //             "A NOMBRE": element.vaucher_fullname,
    //             MONTO:  element.amount ,
    //             "PER. FACTURACIÓN": element.billing_period,
    //             "PER. INGRESO":element.admission_period?element.admission_period:'-',
    //             "SUJETO A": element.detraccion?'DETRACCIÓN':(element.retencion?'RETENCIÓN':'-'),
    //             ESTADO: element.state==1?'-':'ANULADO',
    //            });
    //           }
    //         });
    //     if (this.dataShow.length === 0 && this.dataCxcs.length !== 0) {
    //       this.noRecordsMessage = "No se encontraron resultados de la búsqueda";
    //     } else {
    //       this.noRecordsMessage = "";
    //     }
    //     bo = true;
    //   } else {
    //     let fin = this.selectPage * 10 - 1;
    //     let ini = fin - 9;
    //     this.dataShow = [];
    //     this.dataCxcs.forEach((element, index) => {
    //       if (ini <= index && index <= fin) {
    //         this.dataShow.push(element);
    //       }
    //     });
    //     bo = false;
    //     this.noRecordsMessage = "";
    //   }
    //   /** paginación */
    //   this.stepPagination = [];
    //   let cantidad =
    //     bo == false
    //       ? parseInt(this.dataCxcs.length / 10)
    //       : parseInt(this.dataShow.length / 10);
    //   let residuo =
    //     bo == false
    //       ? parseInt(this.dataCxcs.length % 10)
    //       : parseInt(this.dataShow.length % 10);
    //   cantidad = residuo > 0 ? cantidad + 1 : cantidad;
    //   for (let index = 0; index < cantidad; index++) {
    //     this.stepPagination.push(index + 1);
    //   }
    //   let startIndex = (this.selectPage - 1) * 10;
    //   let endIndex = startIndex + 9;
    //   this.dataShow = filteredData.slice(startIndex, endIndex + 1);
    //   return this.dataShow;
    // },



    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from(
        {
          length: endPage - startPage + 1,
        },
        (_, i) => startPage + i
      );
    },

  },
  created() {
    // this.mtdGetData();
    moment.locale("es");
      this.filter.begindate =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-01";
      this.filter.enddate =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
  },
  methods: {
    ...mapActions(["get", "post"]),
    //metodo obtener data
    mtdGetData: function () {
      this.post({
        url: this.$store.getters.get__url + "/contabilidad/document/"+this.$store.getters.get__campus,
        token: this.$store.getters.get__token,
        params:this.filter
      })
        .then((response) => {
          this.dataCxcs = response.data;
          if (this.dataCxcs.length === 0) {
            this.noRecordsMessage = "No se encontraron resultados.";
          } else {
            this.noRecordsMessage = "";
            this.selectPage = 1;
          }
          this.dataCxcs.forEach((element, index) => {
            if(element.of_document_type_id!=3){
              this.excel.push({
                "N°": index + 1,
                "TIPO VENTA":element.sale.type?element.sale.type.toUpperCase():'-',
                FECHA:element.date,
                "TIPO DOC": element.document.toUpperCase(),
                "SERIE":element.serie,
                CORRELATIVO:element.number ,
                "A NOMBRE": element.vaucher_fullname.toUpperCase(),
                MONTO:  element.amount ,
                "PER. FACTURACIÓN": element.billing_period,
                "PER. INGRESO":element.admission_period?element.admission_period:'-',
                "SUJETO A": element.detraccion?'DETRACCIÓN':(element.retencion?'RETENCIÓN':'-'),
                ESTADO: element.state==1?'-':'ANULADO',
               });
              }
            });
          /** paginación */
          let cantidad = parseInt(this.dataCxcs.length / 10);
          let residuo = parseInt(this.dataCxcs.length % 10);
          cantidad = residuo > 0 ? cantidad + 1 : cantidad;
          for (let index = 0; index < cantidad; index++) {
            this.stepPagination.push(index + 1);
          }
        })
        .catch((errors) => {});
    },
    mtdSearch: function () {
      if (this.search.length >= 4 && this.search != "") {
        let q = this.search.toString().toUpperCase();
        this.dataShow = [];
        let i = 0;
        this.excel=[];
        this.dataCxcs.forEach((element) => {
          let name = element.vaucher_fullname.toString().toUpperCase();
          if (name.includes(q) && element.of_document_type_id!=3) {
            this.dataShow.push(element);
              this.excel.push({
                "N°": index + 1,
                "TIPO VENTA":element.sale.type?element.sale.type.toUpperCase():'-',
                FECHA:element.date,
                "TIPO DOC": element.document.toUpperCase(),
                "SERIE":element.serie,
                CORRELATIVO:element.number ,
                "A NOMBRE": element.vaucher_fullname.toUpperCase(),
                MONTO:  element.amount ,
                "PER. FACTURACIÓN": element.billing_period,
                "PER. INGRESO":element.admission_period?element.admission_period:'-',
                "SUJETO A": element.detraccion?'DETRACCIÓN':(element.retencion?'RETENCIÓN':'-'),
                ESTADO: element.state==1?'-':'ANULADO',
               });
          }
        });
        this.selectPage = 1;
      } else {
        // this.data_product_filter = this.dataSubSubCategories;
      }
    },
    mtdSearchDni: function () {
      if (this.searchDni.length >= 4 && this.searchDni != "") {
        let q = this.searchDni.toString();
        this.dataShow = [];
        let i = 0;
        this.excel=[];
        this.dataCxcs.forEach((element) => {
          let name = element.vaucher_document.toString();
          if (name.includes(q) && element.of_document_type_id!=3) {
            this.dataShow.push(element);
              this.excel.push({
                "N°": index + 1,
                "TIPO VENTA":element.sale.type?element.sale.type.toUpperCase():'-',
                FECHA:element.date,
                "TIPO DOC": element.document.toUpperCase(),
                "SERIE":element.serie,
                CORRELATIVO:element.number ,
                "A NOMBRE": element.vaucher_fullname.toUpperCase(),
                MONTO:  element.amount ,
                "PER. FACTURACIÓN": element.billing_period,
                "PER. INGRESO":element.admission_period?element.admission_period:'-',
                "SUJETO A": element.detraccion?'DETRACCIÓN':(element.retencion?'RETENCIÓN':'-'),
                ESTADO: element.state==1?'-':'ANULADO',
               });
          }
        });
        this.selectPage = 1;
      } else {
        // this.data_product_filter = this.dataSubSubCategories;
      }
    },
    /** modal */
    mtdViewSunat: function(sunat,item){
      this.post({
        url: this.$store.getters.get__url + "/contabilidad/consult/"+item.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
      this.modalSunat.title="DOCUMENTOS DE SUNAT";
      this.modalSunat.modal_form=true;
      this.modalSunat.item=JSON.parse(response.data);
      this.modalSunat.doc=item;
      this.modalSunat.garantias=[];
      if (item.sale.type == 'garantia') {
        this.dataShow.forEach(element => {
          if (element.sale.id == item.sale.id) {
            this.modalSunat.garantias.push({
              "tipoDocumento" : "02",
              "serie" : element.serie,
              "correlativo" : element.number.padStart(8, '0'),
              "monto" : parseFloat(element.amount).toFixed(2)
            });
          }
        });
      }
        })
        .catch((errors) => {});
      
     
    },
    closeModalSunat: function(){
      this.modalSunat= {
        action: "",
        title: "",
        modal_form: false,
        item: [],
      };
    },
    /** pagination */
    selectedPage: function (page) {
      this.selectPage = page;
    },
    backPAge: function () {
      this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
    },
    addPAge: function () {
      this.selectPage =
        this.selectPage == this.stepPagination.length
          ? this.stepPagination.length
          : this.selectPage + 1;
    },
    /** coponent */
    changePage(page) {
      this.$emit("changePage", page);
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdBackRol: function () {
      this.$emit("mtdBackRol");
    },
    exportExcel: function () {
      const datos =this.excel;
      const fileName = 'Reporte Documentos';
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
        data: datos,
        fileName: fileName,
        exportType: typeExport,
      });
    },
    backPage() {
      this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
    },
    nextPage() {
      this.selectPage =
        this.selectPage < this.stepPagination.length
          ? this.selectPage + 1
          : this.stepPagination.length;
    },
    selectedPage(page) {
      this.selectPage = page;
    },
  },
  filters: {
    formatDate(date) {
      return date!=null?moment(date).format("DD/MM/YYYY"):'-';
    },
  },
  
};
</script>

<style scoped>
 #div-tabla-cxc {
    max-height: 400px; 
    overflow-y: auto; 
}
#div-tabla-cxc{
  max-width: 100px; 
}
#btn-autorizar-cxc {
  color: #39b54a;
  font-size: 25px;
}
#btn-autorizar-cxc:hover {
  color: #217b2d;
}
#btn-error-cxc {
  color: #ff0000;
  font-size: 25px;
}
#btn-error-cxc:hover {
  color: #a30404;
}
#btn-pediente-cxc {
  color: #ffe600;
  font-size: 25px;
}
#btn-pediente-cxc:hover {
  color: #c0ac01;
}
#btn-registro-cxc a {
  color: #900052;
}
#btn-registro-cxc a:hover {
  color: #65013a;
  align-items: center;
  transform: scale(1.5);
}
.circulo {
  vertical-align: middle;
  border-style: none;
}
#div-modal-cxc {
  margin-top: 5px;
}
@media (max-width: 800px) {
  /* #div-modal-cxc{
      margin-top: 20px;
    } */
  #btn-open-caja {
    justify-content: center !important;
  }
  /* #div-cxc{
      width: 100% !important;
    } */
  #div-tabla-cxc {
    overflow-x: auto;
  }
}
@media (min-width: 800px) {
  /* #div-modal-cxc{
      margin-top: 65px;
    } */
}

#btn-close {
  width: 49%;
}
.btn-register {
  background: rgb(144, 0, 82);
}

.close {
  cursor: pointer;
}
.modal-header {
  background: rgb(144, 0, 82);
}
.input-1 {
  border: 1.5px solid #9c9a9b;
  color: #9c9a9b;
}

.btn-fin {
  border: none !important;
}

.border-custom-text-2 {
  color: #900052;
  text-align: left !important;
  width: 150px !important;
}

.input_total {
  border: 1.5px solid #900052;
  color: #900052;
}

.myButton-div {
  border: 1.5px solid #9c9a9b;
  background-color: transparent;
  color: #9c9a9b;
  border-radius: 10px;
  width: 100px;
  padding: 4px 5px 4px 5px;
}

.myButton-div:hover {
  border: 1.5px solid #900052;
  color: #900052;
}

.border-custom {
  border-bottom: 1.5px solid #900052;
}

.border-custom-text {
  color: #900052;
}

.border-right {
  border-right: 1.5px solid #900052;
}

.myButton {
  border: none;
  background-color: transparent;
  color: #9c9a9b;
  align-content: center !important;
}

.active-btn {
  border-color: #900052 !important;
  color: #900052 !important;
}

.active-div-btn {
  border: 2.5px solid #900052 !important;
  color: #900052 !important;
  background-color: #f1f1f1;
}

.active-ico {
  border-color: #900052 !important;
  color: #900052 !important;
  border: 2.5px solid #900052 !important;
  background-color: #f1f1f1;
}

.myButton-icon {
  border: 1.5px solid #9c9a9b;
  border-radius: 15px;
  height: 65px;
  margin: 0px 0px 0px 0px;
}

.myButton-icon-2 {
  border: 1.5px solid #9c9a9b;
  border-radius: 15px;
  height: 65px;
  margin: 0px 10px 0px 10px;
}

.myButton:hover .myButton-icon {
  border-color: #900052;
}

.myButton:hover div {
  color: #900052;
}

.myButton:hover .myButton-icon-2 {
  border-color: #900052;
}

.myButton:hover div {
  color: #900052;
}

.form-control {
  width: 150px !important;
  /* border-radius: 10px !important; */
}
#form-input {
  border-radius: 0px !important;
}
.grupo {
  border-radius: 0px !important;
}

@media (max-width: 1199px) {
  .myButton-icon {
    margin: 0px 20px 0px 20px;
  }

  .myButton-icon-2 {
    margin: 0px 3px 0px 3px;
  }

  #row-3 {
    align-content: center;
    margin: 0;
  }
}

@media (max-width: 991px) {
  #row-3 {
    flex-wrap: wrap;
  }

  .col {
    flex-basis: 50%;
  }

  #row {
    flex-direction: column;
  }

  .border-right {
    border-right: none;
    border-bottom: 1.5px solid #900052;
  }

  #row-3 {
    align-content: center;
    margin: 0;
  }
}

@media (max-width: 767px) {
  #row-2 {
    flex-direction: column;
    align-content: center;
  }

  #row-3 {
    align-content: center;
    margin: 0;
  }

  .myButton-icon-2 {
    margin: 0px -11px 0px -11px;
  }

  .myButton-icon {
    margin: 0px 70px 0px 70px;
  }
}

@media (max-width: 575px) {
  .myButton-icon {
    margin: 0px 78px 0px 78px;
  }
}

@media (max-width: 531px) {
  .myButton-icon {
    margin: 0px 0px 0px 0px;
  }

  .myButton-icon-2 {
    margin: 0px -40px 0px -40px;
  }
}

@media (max-width: 414px) {
  .col2 {
    padding: 0px 15px 0px 15px;
  }

  .button-1 {
    margin-bottom: 15px !important;
  }
}
#btn-edit {
  color: #39b54a;
  font-size: 25px;
  margin: 0px 2px;
  cursor: pointer;
}
#btn-edit:hover {
  color: #217b2d;
}
.btn-bm-noradius {
  margin-left: 10px;
  width: 150px;
  height: 38px;
}
.btn-bm-noradius:hover {
  background: rgb(170, 2, 95);
  background-color: rgb(170, 2, 95);
  color: white;
}
.btn-register {
  background: rgb(144, 0, 82);
}
.btn-register:hover {
  background: rgb(170, 2, 95);
}
.modal-header {
  background: rgb(144, 0, 82);
}
.close {
  cursor: pointer;
}
.text-start {
  text-align: left;
}
.tr-table > th > div {
  background: #900052;
  border-radius: 7px;
  padding: 0px;
  margin: 4px;
  height: 45px;
  padding-top: 10px;
}
.tr-table > th {
  padding: 0px;
}
.tbody-table > td > div {
  background: #eaecee;
  border-radius: 15px;
  padding: 0px;
  margin: 4px;
  height: 45px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}
.tbody-table > td {
  padding: 0px;
}
.tbody-table > td > div > div {
  border-radius: 10px;
}
#btn-close {
  width: 49%;
}
.table-th {
  width: 65% !important;
}
.table-th-state {
  width: 13% !important;
}

.page-link {
  color: black !important;
}

.page-link.active {
  color: white !important;
  border-color: white !important;
  background: rgb(144, 0, 82);
}
.page {
  color: white !important;
  background: rgb(144, 0, 82);
}

@media (max-width: 480px) {
  #btn-edit {
    font-size: 13px !important;
  }
  #btn-remove {
    font-size: 13px !important;
  }
  #btn-close {
    width: 100%;
  }
  .container {
    font-size: 11px !important;
  }
  .btn-register {
    width: 100% !important;
    margin-top: 0 !important;
  }
  .td-div-a {
    padding-top: 10px !important;
  }
}
</style>

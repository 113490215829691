<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'md'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-2">
        <input
          type="text"
          placeholder="Digitar el Documento del Paciente"
          class="form-control btn_red"
          v-model="client.document"
          @keypress="validateNumberInput"
          @keypress.enter="mtdSearchDocument"
        />
      </div>
      <div class="col-md-12 pb-2">
        <input
          type="text"
          placeholder="Nombres y Apellidos"
          class="form-control text-center"
          v-model="client.fullName"
          :disabled="true"
        />
      </div>
    </div>

    <template #footer>
      <div class="btn-group">
        <button v-for="(motivo, index) in item" :key="index" class="btn btn-secondary btn-sm custom-btn" 
        :style="{ backgroundColor: index === selectedItemIndex ? 'yellow' : '#900052', 
                  borderColor: index === selectedItemIndex ? 'yellow' : '#900052',
                  color: index === selectedItemIndex ? 'black' : 'white' }" 
                   @click="selectMotive(index)">
            {{ motivo.motive }}
        </button>
        <!--
        <button @click="mtdClose" class="btn btn-danger btn-sm custom-btn">
          <i class="fa fa-times"></i>&nbsp;Cerrar
        </button>
        -->
        <button @click="mtdSurveillance" class="btn btn-success btn-sm custom-btn"><i class="fas fa-save"></i>&nbsp; GUARDAR</button>
      </div>
    </template>


      <SweetAlert :swal="swal" ref="SweetAlert"/>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";

export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
    CModal,
    SweetAlert,
    },
    data() {
    return {
        swal: null,
        client: {
            id: "",
            document: "",
            fullName: "",
            motiveId: "",
        },
        surveillance: [],
        selectedItemIndex: -1,
    };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: [],
    },
    created() {
    },
    computed: {
      cpData() {
        return this.item.map(element => element);
      },
    },
    methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
        this.client =  {
            id: "",
            document: "",
            fullName: "",
            motiveId: "",
        },
        this.selectedItemIndex= -1,
        this.$emit("closeModalDni");
    },

    mtdSearchDocument: function () {
      if (this.client.document.length == 8) {
        this.get({
          url: this.$store.getters.get__url + "/Consultas/" + this.client.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.boo == 3) {
              this.client.document = "";
              this.$refs.SweetAlert.showWarning(response.data.error);
              this.disabledPatient = false;
              this.client.fullName = "";
            } else {
              this.client.fullName = response.data.name + " " + response.data.last_name;
            }
          })
          .catch(() => {
          });
      } else {
        this.disabledPatient = false;
        this.client.celphone = "";
        this.client.email = "";
        this.client.fullName = "";
        this.client.yearOld = "";
      }
    },
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    mtdSurveillance: function () {
        if (!this.client.document.trim()) {
            this.$refs.SweetAlert.showWarning("Digite un Documento");
            return;
        }
        if (this.client.fullName == "") {
            this.$refs.SweetAlert.showWarning("Digite un Documento valido");
            return;
        }
        if (this.client.motiveId == "") {
            this.$refs.SweetAlert.showWarning("Seleccione un motivo");
            return;
        }

        this.$refs.SweetAlert.showConfirmSimple2("","Agregar Paciente","warning","Confirmar") 
        .then((result) => {
            if (result.value) {
                  
              this.post({
                  url: this.$store.getters.get__url + "/surveillance/store",
                  token: this.$store.getters.get__token,
                  params: {
                      Campus: this.$store.getters.get__campus,
                      document: this.client.document,
                      name: this.client.fullName,
                      motiveId: this.client.motiveId,
                  },
              })
              .then((response) => {
                  if(response.state == 1){
                    console.log("No se registro") ;
                  }else{
                      this.$refs.SweetAlert.showSuccess(response.message);
                      this.mtdChangePage();
                  }
                  this.mtdClose();
              })
              .catch((error) => {
                  this.$refs.SweetAlert.showError(error)
              });

            }
        });

    },
    selectMotive(index) {
        this.selectedItemIndex = index;
        this.client.motiveId = this.item[index].id;
    },
    mtdChangePage: function () {
      this.$emit("changePage");
    },
    },

};
</script>
<style scoped>
.custom-btn {
  font-size: 11.5px; 
  font-weight: bold;
}
</style>
  
  
<template>
<div class="container">

    <div class="row p-3" v-if="show=='table'">
        <div class="col-lg-4 col-md-12 main mb-3 p-3">
            <div class="row">
                <div class="col-sm-12 col-md-10 col-xl-11 rounded-3 mb-2">
                    <div class="form-group input-group-sm">
                        <label for="patientSelect" class="form-label"><strong>Paciente</strong></label>
                        <v-select id="patientSelect" class="text-dark" :options="patients" @input="handleSelectItemClick" :reduce="(patient) => patient" label="name" placeholder="- Buscar Paciente -" required :clearable="false" />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-12 mt-2">
            <table class="table table-responsive table-bordered px-0">
                <caption></caption>
                <thead class="bg-main text-white">
                    <tr>
                        <th scope="col">N°</th>
                        <th scope="col">Enfermera</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody v-if="ayuda_dx.length > 0 && pos== 1">
                    <tr v-for="(item, index) in ayuda_dx" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.userRegister }}</td>
                        <td class="concept">
                            <div class="left"><span>{{item.dateCreated}}</span></div>
                        </td>
                        <td>
                            <div class="d-flex justify-content-center">
                                <button class="btn btn-sm bg-main text-white me-1" @click="mtdViewExam(item)">Examen</button>
                                <button class="btn btn-sm btn-info text-white me-1" @click="mtdviewpdf(item)">Informe</button>
                                <button class="btn btn-sm btn-dark text-white me-1">Enviar</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="ayuda_dx.length == 0 && pos ==1">
                    <tr>
                        <td colspan="4" class="text-center">No se encontraron resultados</td>
                    </tr>
                </tbody>
                <tbody v-if="pos == 0">
                    <tr>
                        <td colspan="4" class="text-center">Seleccione un paciente</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
    <!-- Contenido del examen -->
    <div class="row p-3" v-if="show=='content'">
        <div class="col-xl-4 col-lg-6 col-md-12 border mb-3 p-3">
            <div id="div-informe" class="row">
                <div class="col-xl-12 col-lg-12 col-md-12">
                    <div class="row d-flex justify-content-start align-items-center">
                        <div class="col-xl-auto col-md-4 col-sm-4 mt-2">
                            <div class="mt-1 mb-1 ms-auto btn-success">
                                <button type="button" class="btn btn-sm btn-outline-main w-100" @click="mtdback()">
                                    <i class="fas fa-arrow-left"></i> Atrás
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row px-3">
                        <div class="my-2 border-top border-bottom">
                            <span class="text-center">Informe {{ itemImagen.dateCreated }}</span>
                        </div>
                        <div class="text-start">
                                <p class="ps-2 text-xs"> > Indicacion para {{ itemImagen.patient.indicacion.value }}</p>
                            </div>
                        <div class="ps-3">
                            <div class="text-start">
                                <div class="mb-1">
                                    <span class="fst-italic"><b>Conclusiones:</b></span>
                                </div>
                                <dl class="row" v-for="(item, index) in itemImagen.conclusiones.value" :key="index">
                                    <dd class="col-sm-12 text-xs">{{item.name}} - {{ item.ojo }}</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!---Imagen-->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 px-3">
            <div class="row rounded-5 d-flex justify-content-center border-2 h-100 p-2" id="div-img-campovisual">
                <div id="carouselExampleIndicators" class="carousel slide home-carousel mt-0" data-bs-ride="carousel" v-if="dataImg.length > 0">
                    <div class="carousel-indicators">
                        <button v-for="(imagen, index) in dataImg" :key="index" type="button" :data-bs-target="'#carouselExampleIndicators'" :data-bs-slide-to="index" :class="{ 'active': index === 0 }" aria-current="true" :aria-span="'Slide ' + (index + 1)"></button>
                    </div>
                    <div class="carousel-inner">
                        <div v-for="(imagen, index) in dataImg" :key="index" :class="{ 'carousel-item': true, 'active': index === 0 }">
                            <img :src="'https://oftalmologia.howertpi.com/assets/'+imagen.route" class="d-block w-100" @click="mtOpenCarrusel" :data-bs-slide-to="index" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <div class="h-100 p-5" v-if="dataImg.length == 0">
                    <h3>No hay Imagenes para mostrar</h3>
                </div>
            </div>
        </div>

        <div class="col-xl-4 col-md-6 col-lg-6 col-sm-12  px-0">
            <div class="row rounded-5 border-2 h-100" id="div-img-campovisual">
                <div class="justify-content-center p-1 z-index-button" v-if="dataPdf[0]">
                    <iframe :src="'https://oftalmologia.howertpi.com/assets/'+dataPdf[0].route" width="100%" height="600px" title="Pdf"></iframe>
                </div>
                <div class="justify-content-center p-5 z-index-button" v-if="!dataPdf[0]">
                    <h3>No hay Archivos para mostrar</h3>
                </div>
            </div>
        </div>
    </div>
    <cCarrusel :title="modal.title" :boo_modal="modal.boo_modal" :dataImg="dataImg" @closeCarrusel="closeCarrusel" />

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import cCarrusel from '@/components/Medico/AyudaDx/modals/cCarrusel.vue';

export default {
    name: 'c-doctor-consulta-ecografia',
    components: {
        cCarrusel
    },
    data() {
        return {
            id_patient: null,
            page: 'Ecografia',
            stateHistory: null,
            viewInput: null,
            ayuda_dx: [],
            pos: 0,
            patients: [],
            patient_id: "",
            select_patient: null,
            state: false,
            indicacion: "",
            conclusiones: "",
            /* data para visualizar examen */
            dataPdf: [],
            dataImg: [],
            show: 'table',
            itemImagen: {},
            patient_name: "",
            modal: {
                title: "",
                boo_modal: false,
            }
        }
    },
    props: {
        stateRolAdmin: Boolean,
    },
    created() {
        this.mtdpatients();
    },
    methods: {
        ...mapActions(['get', 'post']),
        handleSelectItemClick: function (patientSelect) {
            this.id_patient = patientSelect.id;
            this.patient_name = patientSelect.name;
            this.get({
                    url: this.$store.getters.get__url + "/Doctor/ListAyudaDxIds/" + patientSelect.id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.pos = 1;
                    if (response.data.ecografiaOcular.length > 0) {
                        this.ayuda_dx = response.data.ecografiaOcular;
                    } else {
                        this.ayuda_dx = []
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        toggleVisibilityGraph: function () {
            this.state = !this.state;
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            if (page === 'cola') {
                this.patients = {}
            }
            this.stateHistory = (page === 'ecografia') ? 1 : 0;
            this.viewInput = (page === 'ecografia') ? 1 : 0;
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        mtdviewpage: function (payload) {
            this.page = 'ecografia'
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput = 0;
        },
        mtdpatients: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Call/patients",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.patients = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });

        },
        mtdviewpdf: function (item) {
            window.open(
                this.$store.getters.get__url +
                "/Doctor/viewpdf/ayudadx/" +
                item.id + "?status=" + 7 + "&id_patient=" + this.id_patient,
                "_blank"
            );
        },
        /*metodo para visualiza contenido*/
        mtdViewExam: function (item) {
            this.dataPdf = [];
            this.dataImg = [];
            this.itemImagen = item;
            //separar pdf de img
            this.itemImagen.patient.examen.value.forEach(elem => {
                const extension = elem.route.split('.').pop().toLowerCase();
                if (extension === 'pdf') {
                    this.dataPdf.push(elem);
                } else if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                    this.dataImg.push(elem);
                }

            });
            this.show= 'content';
        },
        mtOpenCarrusel: function (){
            this.modal = {
                title: 'RESULTADOS ' +  this.page.toUpperCase() + ' - ' + this.patient_name,
                boo_modal: true, 
            }
        },
        mtdback: function(){
            this.show = "table";
        },
        closeCarrusel: function(){
            this.modal.title = '';
            this.modal.boo_modal = false;
        }
    }
}
</script>

<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12">
        <div id="table-detalle">
          <table id="table-body" class="table table-bordered table-responsive">
            <caption class="py-0 my-0"></caption>
            <thead>
              <tr
                class="text-white border-white text-center text-xs bg-main align-middle"
              >
                <th scope="col">DOCUMENTO</th>
                <th scope="col">FECHA</th>
                <th scope="col">MEDICAMENTO</th>
                <th scope="col">CANTIDAD</th>
                <th scope="col">PDF</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="cpData.length > 0">
                <tr
                  v-for="(det, index) in cpData"
                  :key="index"
                  class="align-middle"
                  style="font-size: 10.7px"
                >
                  <td>{{ det.correlative }}</td>
                  <td style="white-space: nowrap">{{ det.date | formatDate }}</td>
                  <td>{{ det.subsub_name }}</td>
                  <td>{{ det.cantidad }}</td>
                  <td>
                    <a
                      @click="mtdPdf(det.id)"
                      class="btn btn-primary btn-sm rounded-circle"
                      type="button"
                      style="
                        background-color: #900052;
                        border: 2px solid #900052;
                        margin-left: 5px;
                      "
                    >
                      <i class="fas fa-solid fa-file-pdf"></i>
                    </a>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="6" style="font-size: 14px">No hay registros</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "c-vigilancia-modal-control",
  components: {
    CModal,
  },
  data() {
    return {
      tabla: [],
    };
  },
  created() {},
  computed: {
    cpData() {
      return this.item.map((element) => element);
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    item: [],
    producto: String,
  },
  methods: {
    ...mapActions(["get", "post"]),
    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;
      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    mtdClose() {
      this.$emit("mtdCloseModalDispath");
    },

    mtdPdf: function (id) {
      window.open(
        this.$store.getters.get__url + "/pharmacy/recetasDispathPdf/" + id,
        "_blank"
      );
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY HH:mm:ss");
      } else {
        return "-";
      }
    }
  }
};
</script>
<style scoped>
#content-timeline {
  border: 1px solid #aaa;
  height: 100px;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 1rem;
}

#content-timeline::-webkit-scrollbar {
  -webkit-appearance: none;
}

#content-timeline::-webkit-scrollbar:vertical {
  width: 10px;
}

#content-timeline::-webkit-scrollbar-button:increment,
#content-timeline::-webkit-scrollbar-button {
  display: none;
}

#content-timeline::-webkit-scrollbar:horizontal {
  height: 10px;
}

#content-timeline::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

#content-timeline::-webkit-scrollbar-track {
  border-radius: 10px;
}

.header-main {
  background-color: #900052;
}

@media (max-width: 992px) {
  .flex-row {
    flex-direction: column !important;
    align-items: center;
    padding-top: 20px !important;
  }

  #div-listcola {
    width: 300px !important;
    margin-bottom: 20px !important;
  }
}

.text-doctor {
  font-weight: bold;
  font-size: 14px;
  color: #900052;
}
.selected {
  background-color: yellow;
}

.table-th-state {
  width: 20% !important;
}

#tb-lote th {
  font-size: 9.5px;
}

#tb-lote td {
  font-size: 11px;
}

.error-message {
  color: red;
  font-size: 9px;
}

/* X a la derecha*/
.header-container {
  width: 100%;
  margin-right: 10px;
}

.close-button {
  border: none;
  background-color: transparent;
}

.modal-body {
  margin-right: 20px;
}

@media (max-width: 767px) {
  #table-body {
    font-size: 10px !important;
  }
}
</style>

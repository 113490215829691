<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'lg'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
      :scrollable="true"
    >
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 ">
          <div id="table-detalle" >
            <table id="table-body" class="table table-bordered table-responsive">
                <caption class="py-0 my-0"></caption>
              <thead>
                <tr class="text-white border-white text-center text-sm bg-main">
                    <th scope="col">#</th>
                    <th scope="col">VIA</th>
                    <th scope="col">DOSIS</th>
                    <th scope="col">MOLECULA</th>
                    <th scope="col">PRESENTACIÓN</th>
                    <th scope="col">CONCENTRACIÓN</th>
                    <!--
                    <th scope="col">DURACION</th>
                    <th scope="col">FRECUENCIA</th>
                    -->
                    <th scope="col">OJO</th>
                    <th scope="col">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(det, index) in cpData" :key="index" class="text-sm align-middle">
                  <template>
                    <td>{{ index + 1 }}</td>
                    <td>{{ det.via ? det.via.toUpperCase() : '-' }}</td>
                    <td>{{ det.dosis ? det.dosis.toUpperCase() : '-' }}</td>
                    <td>{{ det.molecule ? det.molecule.toUpperCase() : '-' }}</td>
                    <td>{{ det.presentation ? det.presentation.toUpperCase() : '-' }}</td>
                    <td>{{ det.concentration ? det.concentration.toUpperCase() : '-' }}</td>
                    <!--
                    <td>{{ det.duration}}</td>
                    <td>{{ det.frequency}}</td>
                    -->
                    <td>{{ det.eyes ? det.eyes.toUpperCase() : '-'}}</td>
                    <td>
                      {{
                        (det.presentation && det.presentation.toUpperCase().includes('GOTA')) ? 1 : 
                        (det.duration && det.frequency ? Math.ceil(det.duration * det.frequency) : "-")
                      }}
                    </td>
                  </template>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </CModal>
</template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";
  import { mapActions } from "vuex";
  export default {
    name: "c-vigilancia-modal-control",
    components: {
      CModal,
    },
    data() {
      return {
        tabla: [],
      };
    },
    created() {},
    computed: {
        cpData() {
        return this.item.map(element => element);
    },
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: [],
    },
    methods: {
      ...mapActions(["get", "post"]),
      validateNumberInput(event) {
        const regex = /^[0-9]*\.?[0-9]*$/;
        if (!regex.test(event.key)) {
          event.preventDefault();
        }
      },
  
      mtdClose() {
        this.$emit("mtdCloseModalView");
      },
    },
  };
  </script>
  <style scoped>
  #content-timeline {
    border: 1px solid #aaa;
    height: 100px;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 1rem;
  }
  
  #content-timeline::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  
  #content-timeline::-webkit-scrollbar:vertical {
    width: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-button:increment,
  #content-timeline::-webkit-scrollbar-button {
    display: none;
  }
  
  #content-timeline::-webkit-scrollbar:horizontal {
    height: 10px;
  }
  
  #content-timeline::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  
  #content-timeline::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  .header-main {
    background-color: #900052;
  }
  
  @media (max-width: 992px) {
    .flex-row {
      flex-direction: column !important;
      align-items: center;
      padding-top: 20px !important;
    }
  
    #div-listcola {
      width: 300px !important;
      margin-bottom: 20px !important;
    }
  }
  
  .text-doctor {
    font-weight: bold;
    font-size: 14px;
    color: #900052;
  }
  .selected {
    background-color: yellow;
  }
  
  .table-th-state {
    width: 20% !important;
  }
  
  #tb-lote th {
    font-size: 9.5px;
  }
  
  #tb-lote td {
    font-size: 11px;
  }
  
  .error-message {
    color: red;
    font-size: 9px;
  }
  
  /* X a la derecha*/
  .header-container {
    width: 100%;
    margin-right: 10px;
  }
  
  .close-button {
    border: none;
    background-color: transparent;
  }
  
  .modal-body {
    margin-right: 20px;
  }
  
  @media (max-width: 767px) {
    #table-body {
      font-size: 10px !important;
    }
  }
  </style>
  
<template>
  <div>
    <div class="container">
      <div class="row text-end">
        <div class="col-md-12 mt-2 mb-4 float-right btn-home">
          <button
            type="button"
            class="btn btn-outline-main"
            @click="returnHome"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-anular" class="d-flex justify-content-center">
        <div id="div-anular" class="card w-100">
          <div class="text-center card-header text-white h4 bg-main">
            <strong>CIRUGÍAS</strong>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="row mx-0">
                <div class="col-xl-6 col-12 px-0">
                  <div class="input-group input-group-sm w-100">
                    <span class="input-group-text">Desde</span>
                    <input
                      v-model="filter.initial"
                      type="date"
                      class="form-control form-control-sm"
                      @change="mtdGetData"
                    />
                  </div>
                </div>
                <div class="col-xl-6 col-12 px-0 px-xl-1 my-xl-0 my-2">
                  <div class="input-group input-group-sm w-100">
                    <span class="input-group-text">Hasta</span>
                    <input
                      v-model="filter.end"
                      type="date"
                      class="form-control form-control-sm"
                      @change="mtdGetData"
                    />
                  </div>
                </div>
                <!--
                <div class="col-xl-2 px-0">
                  <div class="input-group input-group-sm text-center btn-home">
                    <button class="btn btn-sm text-white w-100" @click="mtdGetData">
                      <i class="fas fa-search"></i>&nbsp;Buscar
                    </button>
                  </div>
                </div>
                -->
              </div>
              <div class="col-md-12 py-2">
                <div class="input-group input-group-sm mb-3">
                  <span class="input-group-text text-white bg-main"
                    ><i class="fas fa-search"></i
                  ></span>
                  <input
                    v-model="search"
                    type="text"
                    class="form-control form-control"
                    placeholder="Nombre y Apellido"
                    @input="mtdSearchFilter"
                  />
                </div>
              </div>
              <div class="col-md-12 table-container" id="content-timelinetable">
                <table class="table table-bordered">
                  <caption class="py-0 my-0"></caption>
                  <thead>
                    <tr class="text-white table-th text-center text-xs">
                      <th
                        class="align-middle border border-5 bg-main border-white"
                      >
                        N°
                      </th>
                      <th
                        class="align-middle border border-5 bg-main border-white"
                      >
                        FECHA
                      </th>
                      <th
                        class="align-middle border border-5 bg-main border-white"
                      >
                        PACIENTE
                      </th>                      
                      <th
                        class="align-middle border border-5 bg-main border-white"
                      >
                        ACCIÓNES
                      </th>
                    </tr>
                  </thead>

                  <tbody class="text-xs">
                    <tr class="table-body" v-for="(item, index) in cpData" :key="index">
                      <td class="border border-5 border-white align-middle text-center">
                        {{ mtdGenerateCorrelativo(item.id) }}
                      </td>
                      <td
                        class="border border-5 border-white align-middle text-center"
                      >
                        {{item.date}}
                      </td>
                      <td class="border border-5 border-white align-middle">
                        {{item.patient}}
                      </td>
                      <td
                        class="text-center border border-5 border-white align-middle"
                      >
                        <div class="d-flex justify-content-evenly">
                          <a class="btn btn-info btn-sm text-white" @click="viewfactura(item.id)">
                            <i class="far fa-file-pdf"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--pag-->
              <div
                  v-if="cpData.length > 0"
                  class="d-flex justify-content-center px-1 mb-4"
                >
                  <nav aria-label="Page navigation example">
                    <ul class="pagination mb-1">
                      <li class="page-item">
                        <a
                          class="page-link page"
                          href="javascript:void(0)"
                          @click="backPage"
                          >Anterior</a
                        >
                      </li>
                      <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a
                          :class="
                            selectPage === 1 ? 'page-link active' : 'page-link'
                          "
                          href="javascript:void(0)"
                          @click="selectedPage(1)"
                          >1</a
                        >
                      </li>
                      <li
                        v-if="limitedStepPagination[0] > 2"
                        class="page-item disabled"
                      >
                        <span class="page-link">...</span>
                      </li>
  
                      <li
                        v-for="(item, index) in limitedStepPagination"
                        :key="index"
                        class="page-item"
                      >
                        <a
                          :class="
                            selectPage === item ? 'page-link active' : 'page-link'
                          "
                          href="javascript:void(0)"
                          @click="selectedPage(item)"
                          >{{ item }}
                        </a>
                      </li>
                      <li
                        v-if="
                          limitedStepPagination[
                            limitedStepPagination.length - 1
                          ] <
                          stepPagination.length - 1
                        "
                        class="page-item disabled"
                      >
                        <span class="page-link">...</span>
                      </li>
                      <li
                        v-if="
                          limitedStepPagination[
                            limitedStepPagination.length - 1
                          ] !== stepPagination.length
                        "
                        class="page-item"
                      >
                        <a
                          :class="
                            selectPage === limitedStepPagination.length + 2
                              ? 'page-link active'
                              : 'page-link'
                          "
                          href="javascript:void(0)"
                          @click="selectedPage(stepPagination.length)"
                          >{{ stepPagination.length }}
                        </a>
                      </li>
                      <li class="page-item">
                        <a
                          class="page-link page"
                          href="javascript:void(0)"
                          @click="nextPage"
                          >Siguiente</a
                        >
                      </li>
                    </ul>
                  </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  name: "c-sat-cirujias",
  data() {
    return {
      filter: {
        initial: "",
        end: "",
      },
      data:[],
      search: "",
      dataShow: [],
      page: 0,
      selectPage: 1,
      stepPagination: [],
      totalPagesToShow: 4,
    };
  },
  created() {
    moment.locale("es");
    this.filter.end =
    moment().format("L").substr(6, 4)+
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.filter.initial = this.filter.end;
    this.mtdGetData();
  },
  watch: {
    selectPage() {
      this.calculateData(7);
    },
  },
  computed: {
    cpData() {
      return this.dataShow;
    },

    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from(
        {
          length: endPage - startPage + 1,
        },
        (_, i) => startPage + i
      );
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
      this.$emit("mtdChangePage", "home");
    },
    mtdGetData: function(){
        this.post({
          url: this.$store.getters.get__url + "/quote/getall",
          token: this.$store.getters.get__token,
          params:this.filter
        })
          .then((response) => {            
            this.data =response.data;
            this.calculateData(7);
          })
          .catch((error) => {
            console.log(error);
            this.$refs.SweetAlert.showError(response.message);
          });
    },
    calculateData(items) {
      if (!this.data || !this.data.length) {
        return (this.dataShow = []);
      }
      let filteredData = this.data;
      let indexInitial = this.selectPage;
      if (this.search.length >= 2 && this.search != "") {
        let query = this.search.toString().toUpperCase();
        filteredData = this.data.filter((element) => {
          if (element.of_patient_name !== null) {
            let name = element.patient.toString().toUpperCase();
            return name.includes(query);
          }
        });
        indexInitial=1;
      }

      let totalPages = Math.ceil(filteredData.length / items);
      this.stepPagination = Array.from(
        {
          length: totalPages,
        },
        (_, index) => index + 1
      );
      let startIndex = (indexInitial - 1) * items;
      let endIndex = startIndex + (items - 1);
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      this.count = this.count + 1;
    },
    mtdSearchFilter() {
      this.calculateData(7);
    },
    viewfactura: function (id) {
        window.open(
            this.$store.getters.get__url + "/quote/viewpdf/" + id,
            "_blank"
        );
    },
    mtdGenerateCorrelativo: function (item){
      if (0 < item && item < 10) {
                return " PR-000" + item;
            }

            if (9 < item && item < 100) {
                return " PR-00" + item;
            }
            if (99 < item && item < 1000) {
                return " PR-0" + item;
            }
            if (999 < item && item < 10000) {
                return " PR-" + item;
            }
    },

     /** paginación */
    backPage() {
      this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
    },
    nextPage() {
      this.selectPage =
        this.selectPage < this.stepPagination.length
          ? this.selectPage + 1
          : this.stepPagination.length;
    },
    selectedPage(page) {
      this.selectPage = page;
    },
  },
};
</script>
<style scoped>
.table-th > th {
  border-radius: 15px;
}

.table-body > td {
  background: #eaecee;
  border-radius: 15px;
}
</style>
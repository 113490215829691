<template>
<div>
    <div class="container">
        <div class="d-flex flex-row-reverse">
            <div class="mt-1 mb-4 btn-home">
                <button type="button" class="btn btn-outline-main ms-1" @click="changePage('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>FACTURA DE OS</strong>
                </div>
                <div class="card-body w-100" style="background-color: #f2f4f4">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="px-0">
                                <div class="input-group input-group mb-3">
                                    <span class="input-group-text text-white bg-main "><i class="fas fa-search"></i></span>
                                    <input v-model="search" type="text" class="form-control form-control" placeholder="Buscar por N°" @input="mtdSearchFilter" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 col-xl-2 col-lg-2 mb-2">
                            <button type="button" name="accion" class="btn btn-secondary w-100 btn-register text-white" @click="mtdOpenModalReporte">
                                <i class="fas fa-flag"></i> Reporte
                            </button>
                        </div>
                        <div v-if="dataorders.length > 0 && cpData.length > 0" class="col-xl-2 d-flex justify-content-end px-1">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination mb-0 ">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <p id="mydesc"></p>
                            <table id="content-timelinetable " class="table table-bordered" aria-describedby="mydesc">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main text-xxxs">
                                    <tr class="text-white text-center">
                                        <th scope="col" class="align-middle">PROVEEDOR</th>
                                        <th scope="col" class="align-middle">RUC</th>
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">FECHA</th>
                                        <th scope="col" class="align-middle">S/ EXONERADO</th>
                                        <th scope="col" class="align-middle">S/ INAFECTO</th>
                                        <th scope="col" class="align-middle">MONEDA</th>
                                        <th scope="col" class="align-middle">DETRACCION/ NO APLICA</th>
                                        <th scope="col" class="align-middle">S/ TOTAL</th>
                                        <th scope="col" class="align-middle">S/ TOTAL NETO</th>
                                        <th scope="col" class="align-middle">S/ PAGADO DE TOTAL</th>
                                        <th scope="col" colspan="1" class="align-middle">S/ PENDIENTE DEL TOTAL</th>
                                        <th scope="col" class="align-middle">S/ PAGADO DE DETRACCION</th>
                                        <th scope="col" colspan="1" class="align-middle">S/ PENDIENTE DEL DETRACCION</th>
                                        <th scope="col" colspan="1" class="align-middle">PAGOS</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xxs">
                                    <tr class="tbody-table" v-for="(items, index) in cpData" :key="index">
                                        <td>
                                            {{ items.provider.name }}
                                        </td>
                                        <td>
                                            {{ items.provider.ruc }}
                                        </td>
                                        <td>{{ items.number }}</td>
                                        <td>{{ items.date | formatDate }}</td>
                                        <td>{{ items.exonerated }}</td>
                                        <td>{{ items.unaffected }}</td>
                                        <td>{{ items.money }}</td>
                                        <td>{{ items.detraction }}</td>
                                        <td>{{ items.total }}</td>
                                        <td>{{ items.net_total }}</td>
                                        <td>{{ items.paid == null? '0.00':items.paid}}</td>
                                        <td>{{ items.pending }}</td>
                                        <td>{{ items.detraction_paid == null? '0.00':items.detraction_paid }}</td>
                                        <td>{{ items.detraction_pending == null? '0.00':items.detraction_pending }}</td>
                                        <td class="d-flex justify-content-center">
                                            <button type="button" @click="openModalPay(items)" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-dollar-sign text-white"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!cpData.length > 0" class="text-center">
                                        <th scope="col" colspan="15">NO SE ENCONTRARON RESULTADOS</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <cModalRegisterPay :title="modalPay.title" :billpay="modalPay.bill" :boo_modal="modalPay.modal_form" @mtdcloseModalPay="mtdcloseModalPay" />
        <cModalReportPay :title="modalReport.title" :type="modalReport.type" :boo_modal="modalReport.modal_form" @mtdCloseModalReporte="mtdCloseModalReporte" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalRegisterPay from "@/components/Contabilidad/modals/cModalRegisterPay.vue";
import cModalReportPay from "@/components/Contabilidad/modals/cModalReportPay.vue";
export default {
    name: "c-contabilidad-pages-Os",
    components: {
        cModalRegisterPay,
        cModalReportPay
    },
    data() {
        return {
            data: [],
            dataShow: [],
            dataorders: [],
            totalPagesToShow: 10,
            dataDispatches: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            modalViewDispatch: {
                title: "",
                modal_form: false,
            },
            modalPay: {
                title: "",
                modal_form: false,
                bill: {
                    type: "",
                    id: "",
                    number: "",
                    total: "",
                    paid: "",
                    pending: "",
                }
            },
            modalReport: {
                title: "",
                modal_form: false,
                type: "",
            },
            search: '',

        }
    },
    watch: {
        selectPage() {
            this.calculateData(6);
        },
    },
    created() {
        this.mtdGetData();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    methods: {
        ...mapActions(["get", "post"]),
        changePage(page) {
            this.$emit("changePage", page);
        },
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/contabilidad/osPorPagar",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataorders = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {});
        },
        openModalPay: function (bill) {
            const type = bill.number.substring(0, 1) === 'F' ? "A LA FACTURA" : "AL RECIBO POR HONORARIO";
            const title = "GENERAR PAGO " + type + " " + bill.number;
            const total = bill.detraction_percentage == 0 ? bill.total : bill.net_total;
            this.modalPay = {
                title: title,
                modal_form: true,
                bill: {
                    id: bill.id,
                    number: bill.number,
                    total: total,
                    type: "os",
                    paid: bill.paid,
                    pending: bill.pending,
                    detraction: bill.detraction_percentage,
                    detraction_amount: bill.detraction_amount,
                    detraction_paid: bill.detraction_paid,
                    detraction_pending: bill.detraction_pending,
                }
            };
        },
        mtdcloseModalPay: function () {
            this.modalPay = {
                title: "",
                modal_form: false,
                bill: {
                    id: "",
                    number: "",
                    total: "",
                    paid: "",
                    pending: "",
                    type: "",
                    detraction: "",
                    detraction_amount: "",
                    detraction_paid: "",
                    detraction_pending: "",
                }
            };
            this.mtdGetData();
        },
        mtdOpenModalReporte: function () {
            this.modalReport = {
                title: "REPORTE DE FACTURAS OS",
                modal_form: true,
                type: "os",
            };
        },
        mtdCloseModalReporte: function () {
            this.modalReport = {
                title: "",
                modal_form: false,
                type: "",
            };
        },
        mtdSearchFilter() {
            this.calculateData(10);
        },
        calculateData(items) {
            if (!this.data || !this.dataorders.length) {
                return (this.dataShow = []);
            }
            let selectPage = this.selectPage;
            let filteredData = this.dataorders;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search;
                filteredData = this.dataorders.filter((element) => {
                    let number = element.number;
                    return number.includes(query);
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },

    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>

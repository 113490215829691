<!-- <template>
  <div>
    <nav class="navbar" style="background: #900052">
      <div class="container-fluid">
        <div id="img_logo">
          <img src="../../assets/logo_bm.png" alt="Logo" width="200" class="d-inline-block" />
        </div>
        <div class="col-md-12 w-100">
            <div class="row">
                <div align="right"> 
                <div class="col-md-2d-md-block d-sm-none" id="title-user"></div>
                <div class="text-white col-md-4 col-sm-12" id="title-cash"></div>
                <div class="text-white h5 col-md-1 col-sm-12" id="title"></div> -->
                <!-- <div class="text-white col-md-4 col-sm-12" id="title-user">
                  {{user}}
                  <div class="btn-group">
                    <button id="btn-sesion" type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false"></button>
                    <ul class="dropdown-menu">
                      <li><a class="dropdown-item" href="#">Mi perfil</a></li>
                      <li><hr class="dropdown-divider"></li>
                      <li><a class="dropdown-item" href="javascript:void(0)" @click="mtdLogout">Cerrar sesión</a></li>
                    </ul>
                  </div>
                </div> -->
                <!-- <div class="dropdown text-white col-md-4 col-sm-12" id="title-user">
                  <div class="btn-group">
                    <button id="btn-sesion" class="btn btn-primary dropdown-toggle dropdown-toggle-split" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {{user}}
                    </button>
                    <ul class="dropdown-menu">
                        <li><router-link class="dropdown-item" to="/profile">Mi perfil</router-link></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><a class="dropdown-item" href="javascript:void(0)" @click="mtdLogout">Cerrar sesión</a></li>
                    </ul>
                  </div>
                </div>
                
                <div class="dropdown text-white h6 col-md-3 col-sm-12" id="title-date">{{ hoy }}</div> -->
              <!-- </div> -->
            <!-- </div>
        </div> -->
        <!-- <form class="d-flex mt-2">
            <p class="text-white">&nbsp;&nbsp;&nbsp; {{user}} &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;</p>
            <p class="text-white">&nbsp;&nbsp;&nbsp; CAJA 01: Monto inicial S/ &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;</p>
            <p class="text-white">&nbsp;&nbsp;&nbsp; ADMISIÓN &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;</p>
          <p class="text-white">{{ hoy }}</p>
        </form> -->
      <!-- </div>
    </nav>
  </div>
</template> -->
<template>
<div>
    <nav class="navbar">
        <div class="container-fluid">
            <div class="" id="img_logo">
                <img src="../../assets/logo_bm.png" alt="Logo" width="200" class="d-inline-block" />
            </div>
            <div class="row navbar-content">
                <ul style="justify-content: end;">
                    <li class="h5" id="title-user">{{ user }}
                        <div class="dropdown-login">
                            <button class="dropbtn"><i class="fas fa-solid fa-caret-down"></i></button>
                            <div class="dropdown-login-content align-left">
                                <a href="#">Mi perfil</a>
                                <a class="dropdown-login-item" href="javascript:void(0)" @click="mtdLogout">Cerrar sesión</a>
                            </div>
                        </div>
                    </li>
                    <li class="h5" id="title-doctor">{{ rolname }}</li>
                    <li class="h5" id="title-date">{{ hoy }}</li>
                </ul>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { bus } from '../../main'
export default {
  name: 'NavBarComponent',
  props: {
    page: {
      type: String,
      default: null,
    },
    rolname: String
  },
  data () {
    return {
      hoy: null,
      user: null
    }
  },
  created () {
    moment.locale('es')
    this.hoy = moment().format('LLL')
    this.user = this.$store.getters.get__user.name
    //  + ' ' + this.$store.getters.get__user.last_name
  },
  mounted: function () {
    setInterval(() => {
      moment.locale('es')
      this.hoy = moment().format('LLL')
    }, 1000)
  },
  methods: {
    ...mapActions(['get', 'post']),
    mtdLogout: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Authentication/logout",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    //console.log(response);
                    this.$store.commit('mt_logout_auth')
                    this.$router.replace({
                        path: '/login'
                    });
                })
        }
  },
}
</script>

<style scoped>
#btn-sesion{
  background: #900052; 
  border: #900052;
}
#img_logo{
    position: absolute;
    float:left;
    left:-5px;
    top:-5px;
    z-index: 10;
    /* background-color: blue; */
}
/* #title-date{
  margin-bottom: 0px !important;
} */
@media(max-width: 800px){
  #title-user, #title-cash, #title, #title-date{
    text-align: right !important;
  }
  #img_logo img{
    width: 175px !important;
  }
}
@media(min-width: 800px){
  #title-cash, #title, #title-date{
    text-align: center !important;
  }
  #title-user{
    text-align: right !important;
  }
}
</style>

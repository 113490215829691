<template>
  <div>    
    <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
      <div :style="mtdGetStyle('permiso_caja')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('permiso_caja')"><span :style="mtdGetStyleSpan('permiso_caja')" class="btn w-100"><strong>PERMISOS DE CAJA</strong></span></div>
      <div :style="mtdGetStyle('anulaciones')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('anulaciones')"><span :style="mtdGetStyleSpan('anulaciones')" class="btn w-100"><strong>PERMISOS DE ANULACIONES</strong></span></div>
      <div :style="mtdGetStyle('permiso_cierre_caja')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('permiso_cierre_caja')"><span :style="mtdGetStyleSpan('permiso_cierre_caja')" class="btn w-100"><strong>PERMISOS DE CIERRE DE CAJA</strong></span></div>
      <div :style="mtdGetStyle('reporte_caja')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('reporte_caja')"><span :style="mtdGetStyleSpan('reporte_caja')" class="btn w-100"><strong>REPORTES DE CAJA</strong></span></div>
      <div :style="mtdGetStyle('ingreso_egreso')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('ingreso_egreso')"><span :style="mtdGetStyleSpan('ingreso_egreso')" class="btn w-100"><strong>REPORTES DE INGRESOS/EGRESOS</strong></span></div>
      <div :style="mtdGetStyle('call')" 
      class="navbar-brand pl-2 text-white text-center pr-2 nav-footer
       w-100 border-end border-white border" @click="mtdSendOption('call')">
       <span :style="mtdGetStyleSpan('call')" class="btn w-100">
        <strong>REPORTES DE CALL CENTER</strong></span></div>
      <div :style="mtdGetStyle('pagos')" 
       class="navbar-brand pl-2 text-white text-center pr-2 nav-footer
       w-100 border-end border-white border" @click="mtdSendOption('pagos')">
       <span :style="mtdGetStyleSpan('pagos')" class="btn w-100">
        <strong>AUTORIZAR  PAGOS</strong></span></div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'FooterGerenciaComponent',
  props: {
    pageView: String
  },
  data () {
    return {
      page: null
    }
  },
  created () {
   
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != "home") return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != "home") return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>
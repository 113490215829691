<template>
  <div>
    <div class="container text-center">

      <div class="d-flex title-option">
        <!-- <h2 class=" h2-borde mt-3 mb-3 ms-5 text-main pt-1 px-4">
          <strong>ANTECEDENTES</strong>
        </h2> -->
        <div class="btn-home mt-3 mb-2 ms-auto">
          <button type="button" class="btn btn-outline-main" @click="returnHome()">
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>

      <ul class="nav pb-0 d-none d-lg-flex" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
              <button @click="changePage('Hc_antiguas')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'Hc_antiguas' }" id="home-tab" type="button">
                  <strong>Hc antiguas</strong>
              </button>
          </li>
          <li class="nav-item" role="presentation">
              <button @click="changePage('Hc_actuales')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'Hc_actuales' }" id="profile-tab" type="button">
                  <strong>Hc actuales</strong>
              </button>
          </li>
      </ul>
      <!-- Tablet -->
      <div id="app" class="root d-md-block d-lg-none">
          <div class="wrapper-list">
              <dropdown title="H.C Antiguas" component="Hcantigua" @changePage="changePage" :patient="patient" ></dropdown>
              <dropdown title="H.C Actuales" component="Hcactual" @changePage="changePage" :patient="patient" ></dropdown>      
                   
            </div>
      </div>
      <!--  -->
      <div class="tab-content mb-3 px-0 d-none d-lg-block" id="myTabContent">
            <Hcantigua @mtdviewpage="mtdviewpage" @changePage="changePage" v-if="page=='Hc_antiguas'" :patient="patient" class="px-3"/>
            <Hcactual @changePage="changePage" v-if="page=='Hc_actuales'" :patient="patient" />
      </div>
  

    </div>
  </div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Hcantigua from '@/components/Medico/Hcs/Hcantiguas.vue';
import Hcactual from '@/components/Medico/Hcs/Hcactuales.vue';
import Dropdown from "@/components/Medico/Hcs/Dropdown.vue";


export default {
    name: 'c-medico-antecedente',
    components: {
      Hcantigua,
      Hcactual,
      Dropdown
    },
    data() {
      return {
        page: 'Hc_antiguas',
        stateHistory: null,
        viewInput:null,
        patients: {},
      }
    },
    props:{
      patient :{
            type: Object,
        }
    },
    created() {
        
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            this.stateHistory = (page === 'antecedente') ? 1 : 0;
            this.viewInput = (page === 'antecedente') ? 1 : 0;
        },
        returnHome() {
         this.$emit('mtdChangePage','home')
        },
        mtdviewpage: function (payload) {
            this.page = 'antecedente'
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput=0;
        }
        
    }
}
</script>
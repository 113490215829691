<template>
    <div>
      <div class="p-4">
        <div class="row">
          <div
            id="btn-home"
            class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end"
          >
            <button
              type="button"
              class="btn btn-outline-main"
              @click="changePage('home')"
            >
              <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
          </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
          <div id="div-cxc" class="card w-100">
            <div
              align="center"
              class="card-header text-white h4"
              style="background: #900052"
            >
              <strong>REPORTE DE INGRESOS</strong>
            </div>
  
            <div class="card-body w-100">
              <div class="row">

                <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                  <div class="input-group input-group-sm">
                    <span class="input-group-text col-md-3">Desde</span>
                    <input
                      type="date"
                      aria-label="shift_date"
                      class="form-control form-control-sm"
                      id="begindate"
                      v-model="filter.begindate"
                    />
                  </div>
                </div>
  
                <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 mb-2">
                  <div class="input-group input-group-sm">
                    <span class="input-group-text col-md-3">Hasta</span>
                    <input
                      type="date"
                      aria-label="shift_date"
                      class="form-control form-control-sm"
                      id="enddate"
                      v-model="filter.enddate"
                    />
                  </div>
                </div>

                <div class="col-md-12 col-xl-2 col-lg-2 col-sm-12 col-xs-12 mb-2">
                  <button
                    type="button"
                    name="accion"
                    class="btn btn-sm w-100 btn-register text-white"
                    @click="mtdGetData"
                  >
                    <i class="fas fa-search"></i> Buscar
                  </button>
                </div>

                <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12 col-xs-12 text-center">
                  <button
                    type="button"
                    name="accion"
                    class="btn btn-sm mb-3 w-100 btn-register text-white"
                    @click="exportExcel"
                  >
                    <i class="fas fa-file-excel"></i> Exportar
                  </button>
                </div>
                <div class="col-md-12  py-2">
                <div class="d-flex">
                  <div class="input-group input-group mb-3">
                    <span
                      class="input-group-text text-white"
                      style="background: #900052"
                      ><i class="fas fa-id-card"></i></span>
                    <input
                      @keyup="mtdSearch"
                      v-model="search"
                      type="text"
                      class="form-control form-control"
                      placeholder="
                      DNI"
                    />
                  </div>
                </div>
              </div>
                <div class="col-md-12 table-container mt-2" id="content-timelinetable">
                  <table id="dtb-cxc" class="table table-bordered border-main">
                    <thead>
                      <tr
                        class="text-white border-white bg-main text-center text-xs"
                      >
                        <th scope="col">N°</th>
                        <th scope="col">CUENTA CONTABLE</th>
                        <th scope="col">TIPO CLIENTE</th>
                        <th scope="col">COD. ANEXO</th>
                        <th scope="col">ACREEDOR</th>
                        <th scope="col">NOM. ACREEDOR</th>
                        <th scope="col">DNI PACIENTE</th>
                        <th scope="col">NOM. PACIENTE</th>
                        <th scope="col">TIPO DOCUMENTO</th>
                        <th scope="col">N° DOCUMENTO</th>
                        <th scope="col">FECH. EMISION</th>
                        <th scope="col">FECH. VENCIMIENTO</th>
                        <th scope="col">FECH. COMPUTO DE COBRO</th>
                        <th scope="col">FECH. DE INICIO DE SERVICIO</th>
                        <th scope="col">FECH. DE FIN DE SERVICIO</th>
                        <th scope="col">COD. EPS</th>
                        <th scope="col">PERIODO FACTURO</th>
                        <th scope="col">PERIODO DEVENGADO</th>
                        <th scope="col">REFERENCIA CON GARANTIA</th>
                        <th scope="col">CONTROL DE GARANTIAS</th>
                        <th scope="col">TIPO ANEXO</th>
                        <th scope="col">CLASIFICACION BM</th>
                        <th scope="col">DESCRIPCION</th>
                        <th scope="col">CANTIDAD</th>
                        <th scope="col">BASE IMPONIBLE GRAVADA</th>
                        <th scope="col">BASE IMPONIBLE NO GRAVADA</th>
                        <th scope="col">IGV</th>
                        <th scope="col">TOTAL FACTURADO</th>
                        <th scope="col">IMPORTE AFECTO A RENTA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="dataShow.length === 0">
                        <td class="text-center" colspan="14">{{noRecordsMessage}}</td>
                      </tr>
                      <tr class="text-xs" v-for="(item, index) in cpData" :key="index">
                        <td align="center">{{ index + 1 }}</td>
                        <td align="center">{{ '-' }}</td>
                        <td align="center">{{item.of_sale.type_patient ||'-' }}</td>
                        <td align="center">C</td>
                        <td align="center">{{ item.of_sale.voucher[item.of_sale.voucher.length- 1].vaucher_document ||'-'}}</td>
                        <td align="center">{{  item.of_sale.voucher[item.of_sale.voucher.length-1].vaucher_fullname.toUpperCase()||'-' }}</td>
                        <td align="center">{{ item.patient.document || '-' }}</td>
                        <td align="center">{{ item.patient.name || '-' }}</td>
                        <td align="center">{{ item.of_sale.voucher[item.of_sale.voucher.length-1].document|| '-' }}</td>
                        <td align="center">{{ item.of_sale.voucher[item.of_sale.voucher.length-1].serie +'-'+ item.of_sale.voucher[item.of_sale.voucher.length-1].number || '-' }}</td>
                        <td align="center">{{ item.of_sale.voucher[item.of_sale.voucher.length-1].date || '-' }}</td>
                        <td align="center">{{  item.of_sale.voucher[item.of_sale.voucher.length-1].date || '-' }}</td>
                        <td align="center">{{  item.of_sale.voucher[item.of_sale.voucher.length-1].date || '-' }}</td>
                        <td align="center">{{ item.of_sale.date_ind|| '-' }}</td>
                        <td align="center">{{ item.of_sale.date_out|| '-' }}</td>
                        <td align="center">{{  '-' }}</td>
                        <td align="center">{{  item.of_sale.voucher[item.of_sale.voucher.length-1].billing_period || '-' }}</td>
                        <td align="center">{{  '-' }}</td>
                        <td align="center">{{ item.of_sale.type=='garantia'? 'SI' : 'NO' }}</td>
                        <td align="center">
                            {{ item.of_sale.voucher[item.of_sale.voucher.length-1].canje === 0 && item.of_sale.type === 'garantia' ?'GARANTIA' : (item.of_sale.type !== 'garantia' ? '-' : 'APLICACION') }}
                          </td>
                        <td align="center">{{ item.of_sale.type=='garantia'? '001' : '002' }}</td>
                        <td align="center">{{ item.product.service.name}}</td>
                        <td align="center">{{ item.product.name}}</td>
                        <td align="center">{{ item.quantity}}</td>
                        <td align="center">{{ ((Number(item.quantity) * Number(item.price))/1.18)
                        .toFixed(2)  }}</td>
                        <td align="center">0</td>
                        <td align="center">{{ ((Number(item.quantity) * Number(item.price))-((Number(item.quantity) * Number(item.price))/1.18)).toFixed(2) }}</td>
                       <td align="center">{{ (Number(item.quantity) * Number(item.price)).toFixed(2) }}</td>
                        <td align="center">{{ ((Number(item.quantity) * Number(item.price))/1.18).toFixed(2)  }}</td>
                     </tr>
                     </tbody>
                  </table>
                </div>
                 <div class="d-flex justify-content-center" style="align-items: center">
                  <nav v-if="cpData.length > 0" aria-label="Page navigation example">
                    <ul class="pagination">
                      <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                      </li>
                      <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                      </li>
                      <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                      <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)"
                          @click="selectedPage(item)">{{ item }}</a>
                      </li>
                      <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                        class="page-item disabled">
                        <span class="page-link">...</span>
                      </li>
                      <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                        class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{
                          stepPagination.length }}</a>
                      </li>
                      <li class="page-item">
                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import moment from "moment";

  import exportFromJson from "export-from-json";
  export default {
    name: "c-contabilidad-pages-ingreso",
    components:{
    },
    data() {
      return {
          search:"",
          dataShow: [],
          dataCxcs:[],
          selectPage: 1,
          noRecordsMessage:"",
          filter:{
            begindate:null,
            enddate:null
          },
          modalSunat: {
            action: "",
            title: "",
            modal_form: false,
            item: [],
            garantias:[],
            doc:{}
          },
          stepPagination: [],
          totalPagesToShow: 10,
          page: 0,
          excelCxcs: [],
      };
    },
    computed: {
      cpData() {
        let filteredData = this.dataCxcs;
        if (this.search.length >= 2 && this.search != "") {
          let query = this.search.toString().toUpperCase();
          filteredData = this.dataCxcs.filter((element) => {
            let name = element.patient.document.toString().toUpperCase();
            return name.includes(query);
          });
        }
        /** paginación */
        let totalPages = Math.ceil(filteredData.length / 10);
        this.stepPagination = Array.from({ length: totalPages }, (_, index) => index + 1);
        let startIndex = (this.selectPage - 1) * 10;
        let endIndex = startIndex + 9;
        this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        return this.dataShow;
      },

      limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    },
    },
    created() {
      // this.mtdGetData();
      moment.locale("es");
        this.filter.begindate =
          moment().format("L").substr(6, 4) +
          "-" +
          moment().format("L").substr(3, 2) +
          "-01";
        this.filter.enddate =
          moment().format("L").substr(6, 4) +
          "-" +
          moment().format("L").substr(3, 2) +
          "-" +
          moment().format("L").substr(0, 2);
    },
    methods: {
      ...mapActions(["get", "post"]),
      //metodo obtener data
      mtdGetData: function () {
        this.post({
          url: this.$store.getters.get__url + "/contabilidad/ingreso/"
          +this.$store.getters.get__campus,
          token: this.$store.getters.get__token,
          params:this.filter
        })
          .then((response) => {
            //console.log(response);
            this.dataCxcs = response.data;
            if (this.dataCxcs.length === 0) {
              this.noRecordsMessage = "No se encontraron resultados.";
            } else {
              this.noRecordsMessage = "";
              this.selectPage = 1;
            }
            this.dataCxcs.forEach((element, index) => {
              this.excelCxcs.push({
                "N°": index + 1,
                "CUENTA CONTABLE":
                  '<div style="text-align: center;">' +
                    '-' +
                  "</div>",
                "TIPO CLIENTE":
                  '<div style="text-align: center;">' + element.of_sale.type_patient ||'-'  + "</div>",
                "COD. ANEXO":
                  '<div style="text-align: center;">' +
                    'C' +
                  "</div>",
                "ACREEDOR":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].vaucher_document ||'-' +
                  "</div>",
                "NOM. ACREEDOR":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].vaucher_fullname.toUpperCase()||'-' +
                  "</div>",
                "DNI PACIENTE":
                  '<div style="text-align: center;">' +
                    element.patient.document || '-' +
                  "</div>",
                "NOM. PACIENTE":
                  '<div style="text-align: center;">' +
                    element.patient.name || '-'+
                  "</div>",
                "TIPO DOCUMENTO":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].document || '-' +
                  "</div>",
                "N° DOCUMENTO":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].serie +'-'+ element.of_sale.voucher[element.of_sale.voucher.length-1].number || '-'  +
                  "</div>",
                "FECH. EMISION":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].date || '-'  +
                  "</div>",
                "FECH. VENCIMIENTO":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].date || '-'  +
                  "</div>",
                "FECH. COMPUTO DE COBRO":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].date || '-'  +
                  "</div>",
                "FECH. DE INICIO DE SERVICIO":
                  '<div style="text-align: center;">' +
                    (element.of_sale.date_ind !== null ? element.of_sale.date_ind : '-') +
                  "</div>",
                "FECH. DE FIN DE SERVICIO":
                  '<div style="text-align: center;">' +
                    (element.of_sale.date_out !== null ? element.of_sale.date_out : '-') +
                  "</div>",
                "COD. EPS":
                  '<div style="text-align: center;">' +
                    '-' +
                  "</div>",
                "PERIODO FACTURO":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].billing_period || '-' +
                  "</div>",
                "PERIODO DEVENGADO":
                  '<div style="text-align: center;">' +
                    '-' +
                  "</div>",
                "REFERENCIA CON GARANTIA":
                  '<div style="text-align: center;">' +
                    element.of_sale.type=='garantia'? 'SI' : 'NO'  +
                  "</div>",
                "CONTROL DE GARANTIAS":
                  '<div style="text-align: center;">' +
                    element.of_sale.voucher[element.of_sale.voucher.length-1].canje === 0 && element.of_sale.type === 'garantia' ?'GARANTIA' : (element.of_sale.type !== 'garantia' ? '-' : 'APLICACION')   +
                  "</div>",
                "TIPO ANEXO":
                  '<div style="text-align: center;">' +
                    (element.of_sale.type === 'garantia' ? `'001'` : `'002'`)   +
                  "</div>",
                "CLASIFICACION BM":
                  '<div style="text-align: center;">' +
                    element.product.service.name  +
                  "</div>",
                "DESCRIPCION":
                  '<div style="text-align: center;">' +
                    element.product.name   +
                  "</div>",
                "CANTIDAD":
                  '<div style="text-align: center;">' +
                    element.quantity   +
                  "</div>",
                "BASE IMPONIBLE GRAVADA":
                  '<div style="text-align: center;">' +
                    ((Number(element.quantity) * Number(element.price))/1.18).toFixed(2)  +
                  "</div>",
                "BASE IMPONIBLE NO GRAVADA":
                  '<div style="text-align: center;">' +
                    0  +
                  "</div>",
                "IGV":
                  '<div style="text-align: center;">' +
                    ((Number(element.quantity) * Number(element.price))-((Number(element.quantity) * Number(element.price))/1.18)).toFixed(2)   +
                  "</div>",
                "TOTAL FACTURADO":
                  '<div style="text-align: center;">' +
                    (Number(element.quantity) * Number(element.price)).toFixed(2)    +
                  "</div>",
                "IMPORTE AFECTO A RENTA":
                  '<div style="text-align: center;">' +
                    ((Number(element.quantity) * Number(element.price))/1.18).toFixed(2)    +
                  "</div>",
              });
            });

            /** paginación */
            let cantidad = parseInt(this.dataCxcs.length / 10);
            let residuo = parseInt(this.dataCxcs.length % 10);
            cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            for (let index = 0; index < cantidad; index++) {
              this.stepPagination.push(index + 1);
            }
          })
          .catch((errors) => {});
      },
      mtdSearch: function () {
        if (this.search.length >= 4 && this.search != "") {
          let q = this.search.toString().toUpperCase();
          this.dataShow = [];
          let i = 0;
          this.dataCxcs.forEach((element) => {
            let name = element.patient.document.toString().toUpperCase();
            if (name.includes(q)) {
              this.dataShow.push(element);
            }
          });
          this.selectPage = 1;
        } else {
          // this.data_product_filter = this.dataSubSubCategories;
        }
      },
    exportExcel: function () {
      const datos =this.excelCxcs;
      const fileName = 'INGRESOS';
      const typeExport = exportFromJson.types.xls;
      exportFromJson({
        data: datos,
        fileName: fileName,
        exportType: typeExport,
      });
    },
    
      //Paginación
      backPage() {
        this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
      },
      nextPage() {
        this.selectPage = this.selectPage < this.stepPagination.length ? this.selectPage + 1 : this.stepPagination.length;
      },
      selectedPage(page) {
        this.selectPage = page;
      },
      addPAge: function () {
        this.selectPage =
          this.selectPage == this.stepPagination.length
            ? this.stepPagination.length
            : this.selectPage + 1;
      },
      /** coponent */
      changePage(page) {
        this.$emit("changePage", page);
      },
      mtdBackCampus: function () {
        this.$emit("mtdBackCampus");
      },
      mtdBackRol: function () {
        this.$emit("mtdBackRol");
      },
    },
    filters: {
      formatDate(date) {
        return date!=null?moment(date).format("DD/MM/YYYY"):'-';
      },
    },
  };
  </script>
  
  <style scoped>
  #dtb-cxc {
    max-height: 400px; 
    overflow-y: auto; 
}
#dtb-cxc{
  max-width: 1400px; 
    overflow-x: auto; 
}
  #btn-edit {
    color: #39b54a;
    font-size: 25px;
    margin: 0px 2px;
  }
  
  #btn-edit:hover {
    color: #217b2d;
  }
  
  #btn-remove {
    color: #ff0000;
    font-size: 25px;
    margin: 0px 2px;
  }
  
  #btn-remove:hover {
    color: #a30404;
  }
  
  .btn-bm-noradius {
    margin-left: 10px;
    width: 150px;
    height: 38px;
  }
  
  .btn-bm-noradius:hover {
    background: rgb(170, 2, 95);
    background-color: rgb(170, 2, 95);
    color: white;
  }
  
  .btn-register {
    background: rgb(144, 0, 82);
  }
  
  .btn-register:hover {
    background: rgb(170, 2, 95);
  }
  
  .modal-header {
    background: rgb(144, 0, 82);
  }
  
  .close {
    cursor: pointer;
  }
  
  .text-start {
    text-align: left;
  }
  
  .tr-table>th>div {
    background: #900052;
    border-radius: 7px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
  }
  
  .tr-table>th {
    padding: 0px;
  }
  
  .tbody-table>td>div {
    background: #eaecee;
    border-radius: 15px;
    padding: 0px;
    margin: 4px;
    height: 45px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  
  .tbody-table>td {
    padding: 0px;
  }
  
  .tbody-table>td>div>div {
    border-radius: 10px;
  }
  
  #btn-close {
    width: 49%;
  }
  
  .table-th {
    width: 50% !important;
  }
  
  .table-th-state {
    width: 18% !important;
  }
  
  .page-link {
    color: black !important;
  }
  
  .page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
  }
  
  .page {
    color: white !important;
    background: rgb(144, 0, 82);
  }
  
  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }
  
  @media (max-width: 480px) {
    #btn-edit {
      font-size: 13px !important;
    }
  
    #btn-remove {
      font-size: 13px !important;
    }
  
    #btn-close {
      width: 100%;
    }
  
    .container {
      font-size: 11px !important;
    }
  
    .btn-register {
      width: 100% !important;
      margin-top: 0 !important;
    }
  
    .td-div-a {
      padding-top: 10px !important;
    }
  
    .td-div-state>div>div {
      margin-top: 3px !important;
    }
  }
  
  @media (max-width: 415px) {
    #state_schedule {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px !important;
    }
  
    #state_schedule div {
      width: 100%;
    }
  }
  </style>
<template>
<div>
    <div class="p-3">
        <div class="row text-end">
            <div class="col-md-12 mt-3 mb-4 float-right btn-home">
                <button type="button" class="btn btn-outline-main me-2" @click="exportExcel">
                    <i class="fas fa-file-excel"></i> Exportar
                </button>
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="d-flex justify-content-center">
            <div id="div-anular" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>REPORTE EGRESOS</strong>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="row mx-0 d-flex justify-content-center">
                            <div class="col-xl-3 col-md-6 col-lg-3 mb-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text ">Desde</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" v-model="filterModal.begindate" id="init" @change="mtdDataReport" />
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6 col-lg-3 mb-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text ">Hasta</span>
                                    <input type="date" aria-label="shift_date" class="form-control form-control-sm" v-model="filterModal.enddate" id="end" @change="mtdDataReport" />
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-3 col-xl-3 mb-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text">Caja</span>
                                    <select id="bead_id" class="form-control form-control" v-model="filterModal.bead_id" @change="mtdDataReport">
                                        <option selected value="0" disabled>Seleccione ...</option>
                                        <option v-for="(item, index) in dataBeadsList" :key="index" :value="item.id">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6 col-lg-3 mb-2">
                                <div class="input-group input-group-sm">
                                    <span class="input-group-text">Tipo Egreso</span>
                                    <select class="form-control  form-control-xs" v-model="filterModal.typeExpense" name="tipo_egreso" @change="mtdDataReport">
                                        <option selected value="0" disabled>Seleccione ...</option>
                                        <option value="todos">TODOS</option>
                                        <option value="expense">EGRESOS</option>
                                        <option value="passage">PASAJES</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="card-body w-100">
                            <div class="row">
                                <div v-if="cpData.length > 0" class="d-flex justify-content-end px-3 mt-3">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>

                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div class="col-md-12 table-container" id="content-timelinetable">
                                    <table class="table table-bordered ">
                                        <caption class="py-0 my-0"></caption>
                                        <thead>
                                            <tr class="text-white table-th text-center text-xs">
                                                <th class="align-middle border border-5 bg-main border-white">N°</th>
                                                <th class="align-middle border border-5 bg-main border-white">TIPO EGRESO</th>
                                                <th class="align-middle border border-5 bg-main border-white">TIPO DOC</th>
                                                <th class="align-middle border border-5 bg-main border-white">DNI / RUC</th>
                                                <th class="align-middle border border-5 bg-main border-white">NOMBRES / RAZÓN SOCIAL</th>
                                                <th class="align-middle border border-5 bg-main border-white">SERIE - NÚMERO</th>
                                                <th class="align-middle border border-5 bg-main border-white">FECHA DOC.</th>
                                                <th class="align-middle border border-5 bg-main border-white">MÉTODO PAGO</th>
                                                <th class="align-middle border border-5 bg-main border-white">TOTAL</th>
                                                <th class="align-middle border border-5 bg-main border-white">PAGADO</th>
                                                <th class="align-middle border border-5 bg-main border-white">PENDIENTE</th>
                                                <th class="align-middle border border-5 bg-main border-white">MOTIVO</th>
                                                <th class="align-middle border border-5 bg-main border-white">ESTADO</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-xs">
                                            <tr class="table-body" v-for="(item, index) in cpData" :key="index">
                                                <td class="border border-5 border-white align-middle"> {{ index + 1 }}</td>
                                                <td class="border border-5 border-white align-middle">{{ item.modulo == 'passage' ? 'Pasajes' : (item.modulo == 'retiro' ? 'Retiro' : 'Egresos')}}</td>
                                                <td class="border border-5 border-white align-middle">
                                                    {{
                    item.of_document_type_id != null
                    ? item.of_document_type.name
                              : item.modulo == 'receipt'?"RECIBO POR HONORARIOS":"DOC. INTERNO",
                  }}
                                                </td>
                                                <td class="border border-5 border-white align-middle">{{ item.modulo == 'passage' ? item.passage.document : (item.modulo == 'retiro' ? item.of_bead.user.dni : (
                  item.modulo == 'receipt' ? item.receipt.document : item.document)) }}</td>
                                                <td class="border border-5 border-white align-middle">{{ item.modulo == 'passage' ? item.passage.name : (item.modulo == 'retiro' ? item.of_bead.user.last_name + 
                " "+item.of_bead.user.name:(item.modulo =='receipt'? item.receipt.name:item.company )) }}</td>
                                                <td class="border border-5 border-white align-middle">{{item.serie}}-{{ item.number }}</td>
                                                <td class="border border-5 border-white align-middle">{{ item.date_doc | formatDate}}</td>
                                                <td class="border border-5 border-white align-middle">{{item.method}}</td>
                                                <td class="border border-5 border-white align-middle">{{item.total}}</td>
                                                <td class="border border-5 border-white align-middle">{{ item.amount }}</td>
                                                <td class="border border-5 border-white align-middle">{{item.pending}}</td>
                                                <td class="border border-5 border-white align-middle">{{ item.description == null ? "-" : item.description }}</td>
                                                <td class="border border-5 border-white align-middle">
                                                    <a v-if="item.state == 2" href="javascript:void(0)" id="btn-autorizar-anular" class="a-success">
                                                        <i class="fas fa-check-circle "></i>
                                                    </a>
                                                    <a v-if="item.state == 1" href="javascript:void(0)" id="btn-pediente-anular" class="a-warning">
                                                        <i class="far fa-circle"></i>
                                                    </a>
                                                    <a v-if="item.state == 3" href="javascript:void(0)" id="btn-error-anular" class="a-danger ">
                                                        <i class="fas fa-times-circle"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr class="table-body" v-if="cpData.length == 0">
                                                <td class="border border-5 border-white text-center" colspan="13"> Escoga un tipo de egreso</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import exportFromJson from "export-from-json";

export default {
    name: 'c-oftalmologia-reporteegresos',
    components: {
        SweetAlert,
    },
    data() {
        return {
            filterModal: {
                campu_id: 0,
                bead_id: 0,
                begindate: null,
                enddate: null,
                typeExpense: 0,
            },
            dataShow: [],
            data: [],
            totalPagesToShow: 10,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            swal: null,
            dataBeadsList: [],
            dataExpense: [],
            excelExpense: [],
            methods: [{
                    id: 'efectivo',
                    name: 'EFECTIVO'
                },
                {
                    id: 'tarjeta',
                    name: 'TARJETA'
                },
                {
                    id: 'deposito',
                    name: 'DEPOSITO'
                },
                {
                    id: 'mixto',
                    name: 'MIXTO'
                }
            ]
        }
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        cpData() {
            /*let filter = false;
            this.filterModal.typeExpense == "todos" ? this.dataShow = this.dataExpense : filter = true;
            if (filter) {
                this.dataShow = [];
                this.dataExpense.forEach(item => {
                    if (item.modulo == this.filterModal.typeExpense) {
                        this.dataShow.push(item);
                    }
                });
            }*/

            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdgetDataBeads();
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            this.$emit("mtdChangePage", "home");
        },
        mtdgetDataBeads: function () {
            this.filterModal.campu_id = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Admision/beads/" + this.filterModal.campu_id,
                    token: this.$store.getters.get__token,
                })
                .then(response => {
                    this.dataBeadsList = response.data;
                    this.filterModal.bead_id = this.dataBeadsList[0].id;
                    this.mtdCreatedDates();
                })
                .catch(() => {});
        },

        mtdCreatedDates() {
            moment.locale("es");
            this.filterModal.begindate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-01";
            this.filterModal.enddate =
                moment().format("L").substr(6, 4) +
                "-" +
                moment().format("L").substr(3, 2) +
                "-" +
                moment().format("L").substr(0, 2);
        },

        mtdDataReport: function () {
            if (this.filterModal.bead_id == 0) {
                this.$refs.SweetAlert.showWarning("¡Escoja una caja!");
                return;
            }
            this.post({
                    url: this.$store.getters.get__url + "/Admision/ReportExpenses",
                    token: this.$store.getters.get__token,
                    params: {
                        campu_id: this.filterModal.campu_id,
                        bead_id: this.filterModal.bead_id,
                        begindate: this.filterModal.begindate,
                        enddate: this.filterModal.enddate,
                        typeExpense: this.filterModal.typeExpense,
                    }
                })
                .then((response) => {
                    this.dataExpense = response.data;
                    this.dataExpense.forEach((element, index) => {
                        this.excelExpense.push({
                            "N°": index + 1,
                            "TIPO EGRESO" : element.modulo == 'passage' ? 'Pasajes' : (element.modulo == 'retiro' ? 'Retiro' : 'Egresos'),
                            "ADMISIONISTA": element.of_bead.user.name,
                            "SEDE": element.campu.name,
                            "TIPO DE COMPROBANTE": element.of_document_type_id != null ?
                                element.of_document_type.name :
                                element.modulo == "receipt" ?
                                "RECIBO POR HONORARIOS" :
                                "DOC. INTERNO",
                            "NÚMERO DE COMPROBANTE": element.modulo == "passage" ?
                                "PLANILLA-PASAJES" :
                                element.number ?
                                element.serie + "-" + element.number :
                                element.serie ?
                                element.serie :
                                "-",
                            "RAZON SOCIAL/NOMBRE": element.modulo == "passage" ?
                                element.passage.name :
                                element.modulo == "retiro" ?
                                element.of_bead.user.name :
                                element.modulo == "receipt" ?
                                element.receipt.name :
                                element.company,
                            "RUC/DNI": element.modulo == "passage" ?
                                element.passage.document :
                                element.modulo == "retiro" ?
                                element.of_bead.user.dni :
                                element.modulo == "receipt" ?
                                element.receipt.document :
                                element.document,
                            "FECHA DE EGRESO": element.date,
                            "FECHA DE COMPROBANTE": element.date_doc == null ? element.date : element.date_doc,
                            "METODO DE PAGO": element.method,
                            "TOTAL": element.total,
                            "MONTO PAGADO": element.amount,
                            "PENDIENTE": element.pending,
                            "MOTIVO, DESCRIPCION O DETALLE DE COMPRA": element.description == null ? "-" : element.description,
                            "ESTADO": element.state == 1 ?
                                "PENDIENTE DE APROBACIÓN" :
                                element.state == 2 ?
                                "APROBADO" :
                                "RECHAZADO",
                            
                        });
                    });
                    this.calculateData(10);
                })
                .catch(() => {});
        },
        exportExcel: function () {
            if (this.filterModal.typeExpense !== 0) {
                const datos = this.excelExpense;
                const fileName = 'Reporte Egresos';
                const typeExport = exportFromJson.types.xls;
                exportFromJson({
                    data: datos,
                    fileName: fileName,
                    exportType: typeExport,
                });
            }
        },
        calculateData(items) {
            let filteredData = this.dataExpense;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>

<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}

#cmbEgreso {
    height: 30px !important
}

#cmbEgreso div .vs__search {
    height: 30px !important
}
</style>

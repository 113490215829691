<template>
  <div class="row mx-0">
    <div
      v-if="data.length > 0 && cpData.length > 0"
      class="d-flex justify-content-end px-1"
    >
      <nav aria-label="Page navigation example">
        <ul class="pagination mb-1">
          <li class="page-item">
            <a
              class="page-link page"
              href="javascript:void(0)"
              @click="backPage"
              >Anterior</a
            >
          </li>
          <li v-if="limitedStepPagination[0] !== 1" class="page-item">
            <a
              :class="selectPage === 1 ? 'page-link active' : 'page-link'"
              href="javascript:void(0)"
              @click="selectedPage(1)"
              >1</a
            >
          </li>
          <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
            <span class="page-link">...</span>
          </li>

          <li
            v-for="(item, index) in limitedStepPagination"
            :key="index"
            class="page-item"
          >
            <a
              :class="selectPage === item ? 'page-link active' : 'page-link'"
              href="javascript:void(0)"
              @click="selectedPage(item)"
              >{{ item }}
            </a>
          </li>
          <li
            v-if="
              limitedStepPagination[limitedStepPagination.length - 1] <
              stepPagination.length - 1
            "
            class="page-item disabled"
          >
            <span class="page-link">...</span>
          </li>
          <li
            v-if="
              limitedStepPagination[limitedStepPagination.length - 1] !==
              stepPagination.length
            "
            class="page-item"
          >
            <a
              :class="
                selectPage === limitedStepPagination.length + 2
                  ? 'page-link active'
                  : 'page-link'
              "
              href="javascript:void(0)"
              @click="selectedPage(stepPagination.length)"
              >{{ stepPagination.length }}
            </a>
          </li>
          <li class="page-item">
            <a
              class="page-link page"
              href="javascript:void(0)"
              @click="nextPage"
              >Siguiente</a
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-xl-12 px-0 py-2">
      <div class="row d-flex mx-0">
        <div class="col-xl-8 col-lg-8 col-md-12 px-1 mb-2">
          <div class="input-group input-group">
            <span class="input-group-text text-white bg-main"
              ><i class="fas fa-search"></i
            ></span>
            <input
              type="text"
              class="form-control form-control"
              placeholder="Buscar"
              @input="mtdGetData()"
              v-model="search"
            />
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 btn-home px-1 py-xl-0 my-xl-0 mb-2">
          <button
            type="button"
            class="btn btn-bm-noradius w-100"
            @click="openmodal(0)"
          >
            <i class="fas fa-plus"></i> Nueva
          </button>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-6 col-sm-12 btn-home px-1">
          <button type="button" class="btn btn-bm-noradius w-100">
            <i class="fas fa-file-excel"></i> Cargar
          </button>
        </div>
      </div>
    </div>
    <div class="col-xl-12 px-0">
      <div class="px-1 text-xs" id="content-timelinetable">
        <table id="tb-anular" class="table table-borderless text-center">
          <caption class="py-0 my-0"></caption>
          <thead>
            <tr class="text-white table-th text-center">
              <th
                v-for="(column, index) in columns"
                :key="index"
                scope="col"
                colspan="1"
                class="align-middle border border-5 bg-main border-white"
              >
                {{ column }}
              </th>
            </tr>
          </thead>

          <tbody v-if="cpData.length > 0">
            <tr class="table-body" v-for="(row, index) in cpData" :key="index">
              <td class="border border-5 border-white text-center align-middle">
                {{ mtdNumber(index) }}
              </td>
              <template v-for="(item, key) in row">
                <template v-if="shouldRenderColumn(key)">
                  <td
                    :key="key"
                    class="border border-5 border-white text-center align-middle"
                  >
                    {{ item }}
                  </td>
                </template>
              </template>
              <!-- <template v-for="(item, key) in row">
                            <template
                             v-if="
                             key !== 'id' && key !== 'state' && key !== 'price_cost' 
                             && key !== 'price_sale' && key !== 'price_cost_igv' &&  key !== 'price_sale_igv' 
                             " >
                                <td :key="key" class="border border-5 border-white text-center align-middle">
                                    {{ key}}
                                </td>
                            </template>
                        </template> -->
              <td
                v-if="row.hasOwnProperty('price_cost')"
                class="text-center border border-5 border-white align-middle"
              >
                {{ row.price_cost }}
              </td>
              <td
                v-if="row.hasOwnProperty('price_cost_igv')"
                class="text-center border border-5 border-white align-middle"
              >
                {{ row.price_cost_igv }}
              </td>
              <td
                v-if="row.hasOwnProperty('price_sale')"
                class="text-center border border-5 border-white align-middle"
              >
                {{ row.price_sale }}
              </td>
              <td
                v-if="row.hasOwnProperty('price_sale_igv')"
                class="text-center border border-5 border-white align-middle"
              >
                {{ row.price_sale_igv }}
              </td>
              <td
                v-if="button_num == 1 || button_num == 2"
                class="text-center border border-5 border-white align-middle"
              >
                <button
                  class="btn btn-sm btn-info text-white me-1"
                  @click="openmodal(3, row)"
                >
                  <i class="fas fa-eye"></i>
                </button>
              </td>
              <td class="text-center border border-5 border-white align-middle">
                <span :class="row.state == 1 ? 'text-white rounded rounded-2 px-3 bg-success' : 'text-white rounded rounded-2 px-3 bg-secondary'">
                  {{ row.state == 1 ? 'ACTIVO' : 'INACTIVO' }}
                </span>
              </td>
              <td class="text-center border border-5 border-white align-middle">
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-sm btn-primary text-white me-1"
                    @click="openmodal(1, row)"
                  >
                    <i class="fa fa-edit"></i>
                  </button>
                  <button
                    class="btn btn-sm btn-danger text-white"
                    @click="deleteData(row)"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button
                    v-if="button_num == 1"
                    class="btn btn-sm btn-info text-white ms-1"
                    @click="openmodal(2, row)"
                  >
                    <i class="fas fa-tag"></i>
                  </button>
                  <button
                    v-if="button_num == 2"
                    class="btn btn-sm btn-info text-white ms-1"
                    @click="openmodal(2, row)"
                  >
                    <i class="fas fa-user"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else class="text-center">
            <tr class="border-0">
              <td :colspan="columns.length">No se encontraron resultados</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CModal",
  data() {
    return {
      search: "",
      dataShow: [],
      totalPagesToShow: 4,
      page: 0,
      selectPage: 1,
      stepPagination: [],
      count: 0,
    };
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    button_num: {
      type: Number,
      default: null,
    },
  },
  watch: {
    selectPage() {
      this.calculateData(6);
    },
    data: {
      handler: "mtdGetData",
      deep: true,
      immediate: true,
    },
  },
  computed: {
    cpData() {
      return this.dataShow;
    },
    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from(
        {
          length: endPage - startPage + 1,
        },
        (_, i) => startPage + i
      );
    },
  },
  methods: {
    mtdNumber: function (index) {
            return (this.selectPage - 1) * 6 + (index + 1)
        },
    mtdGetData: function () {
      if (this.dataShow.length > 0) {
        if (this.dataShow[0].action == "d") {
          if (this.dataShow.length - 1 > 1) {
            this.selectPage = this.selectPage;
          } else {
            this.selectPage =
              this.selectPage == 1 ? this.selectPage : this.selectPage - 1;
          }

          // if(this.selectPage == 1){
          //     this.selectPage = this.selectPage
          // }else{
          //     this.selectPage = this.selectPage - 1
          // }
        }
      }
      this.calculateData(6);
    },
    calculateData(items) {
      let filteredData = this.data;
      let indexInitial = this.selectPage;
      if (this.search.length >= 2 && this.search != "") {
        let query = this.search.toString().toUpperCase();
        filteredData = this.data.filter((element) => {
          let name = element.name.toString().toUpperCase();
          return name.includes(query);
        });
        indexInitial = 1;
      }
      let totalPages = Math.ceil(filteredData.length / items);
      this.stepPagination = Array.from(
        {
          length: totalPages,
        },
        (_, index) => index + 1
      );
      let startIndex = (indexInitial - 1) * items;
      let endIndex = startIndex + (items - 1);
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      this.count = this.count + 1;
      /*if (this.count != 1) {
                if (this.dataShow.length == 0) {
                    this.selectPage = this.selectPage - 1
                }
            }*/
    },
    backPage() {
      this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
    },
    nextPage() {
      this.selectPage =
        this.selectPage < this.stepPagination.length
          ? this.selectPage + 1
          : this.stepPagination.length;
    },
    selectedPage(page) {
      this.selectPage = page;
    },
    openmodal: function (num, payload) {
      this.$emit("openmodal", num, payload);
    },
    deleteData: function (payload) {
      this.$emit("deleteData", payload);
    },
    shouldRenderColumn(columnKey) {
      const columnsToExclude = [
        "id",
        "state",
        "price_cost",
        "price_cost_igv",
        "price_sale",
        "price_sale_igv",
        "campus_id",
        "category_id",
        "product_id",
        "product_detail_id",
        "provider_id",
        "brand_id",
        "brands",
        "campu_id",
        "service_id",
        "roles",
        "categoria_id",
        "services",
        "created_at",
        "updated_at",
        "deleted_at",
        "of_doctor_id",
        "description",
        "user_id"
      ];
      return !columnsToExclude.includes(columnKey);
    },
  },
};
</script>

<style scoped>
.table-th > th {
  border-radius: 15px;
}

.table-body > td {
  background: #eaecee;
  border-radius: 15px;
}
</style>

<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="modaltitle"
        :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row">
            <div class="row mx-0  container border-custom">
                <div class="col-md-12 col-lg-12 col-xs-12 text-center" v-if="canje==false">
                    <div class="border-custom-text fw-bold">MEDIO DE PAGO</div>
                    <div id="row-3" class="row mt-2 mb-3">
                        <div class="col-xl-1 "></div>
                        <div class="col-xl-2">
                            <button id="efectivo" :class="bottonActiveM == 'efectivo'
                                        ? 'myButton active-btn w-100'
                                        : 'myButton w-100'
                                        " @click="activarMedio('efectivo')">
                                                                <div :class="bottonActiveM == 'efectivo'
                                        ? 'myButton-icon active-ico'
                                        : 'myButton-icon'
                                        ">
                                    <i class="far fa-money-bill-alt fa-2x mt-3"></i>
                                </div>
                                <div>Efectivo</div>
                            </button>
                        </div>
                        <div class="col-xl-2">
                            <button id="tarjeta" :class="bottonActiveM == 'tarjeta'
                                    ? 'myButton active-btn w-100'
                                    : 'myButton w-100'
                                    " @click="activarMedio('tarjeta')">
                                                            <div :class="bottonActiveM == 'tarjeta'
                                    ? 'myButton-icon active-ico'
                                    : 'myButton-icon'
                                    ">
                                    <i class="fas fa-credit-card fa-2x mt-3"></i>
                                </div>
                                <div>Tarjeta</div>
                            </button>
                        </div>
                        <div class="col-xl-2">
                            <button id="deposito" :class="bottonActiveM == 'deposito'
                                        ? 'myButton active-btn w-100'
                                        : 'myButton w-100'
                                        " @click="activarMedio('deposito')">
                                                                <div :class="bottonActiveM == 'deposito'
                                        ? 'myButton-icon active-ico'
                                        : 'myButton-icon'
                                        ">
                                    <i class="fas fa-money-check-alt fa-2x mt-3"></i>
                                </div>
                                <div>Deposito</div>
                            </button>
                        </div>
                        <div class="col-xl-2">
                            <button id="transferencia" :class="bottonActiveM == 'transferencia'
                                    ? 'myButton active-btn w-100'
                                    : 'myButton w-100'
                                    " @click="activarMedio('transferencia')">
                                                            <div :class="bottonActiveM == 'transferencia'
                                    ? 'myButton-icon active-ico'
                                    : 'myButton-icon'
                                    ">
                                    <i class="fas fa-exchange-alt fa-2x mt-3"></i>
                                </div>
                                <div>Transferencia</div>
                            </button>
                        </div>
                        <div class="col-xl-2">
                            <button id="mixto" :class="bottonActiveM == 'mixto'
                                    ? 'myButton active-btn w-100'
                                    : 'myButton w-100'
                                    " @click="activarMedio('mixto')">
                                                            <div :class="bottonActiveM == 'mixto'
                                    ? 'myButton-icon active-ico'
                                    : 'myButton-icon'
                                    ">
                                    <i class="fas fa-wallet fa-2x mt-3"></i>
                                </div>
                                <div>Mixto</div>
                            </button>
                        </div>
                        <div class="col-xl-1"></div>
                    </div>
                </div>
                <hr />
                <div class="col-md-12 col-lg-12 col-xs-12" v-if="canje==false">
                    <div class="row mt-2 mx-0">
                        <div class="col-md-12">
                            <div class="d-xl-flex justify-content-around">
                                <div class="mb-3 div-margin"
                                    v-if="bottonActiveM == 'efectivo' || bottonActiveM == 'mixto'">
                                    <label for="efectivo"
                                        class="form-label border-custom-text-2 fw-bold">EFECTIVO</label>
                                    <input class="form-control text-center input-1 fw-bold" type="text"
                                        v-on:keypress="isNumber($event)" @blur="mtdCalculate('efectivo')"
                                        v-model="payment.amount.efectivo" placeholder="S/0.00"
                                        aria-label="default input example" />
                                </div>
                                <div class="mb-3 div-margin" v-if="vuelto && bottonActiveM == 'efectivo'">
                                    <label for="vuelto" class="form-label border-custom-text-2 fw-bold">VUELTO</label>
                                    <input class="form-control text-center input-1 fw-bold" type="text" disabled
                                        v-model="payment.vuelto" placeholder="S/0.00"
                                        aria-label="default input example" />
                                </div>

                                <div class="mb-3 div-margin"
                                    v-if="bottonActiveM == 'tarjeta' || bottonActiveM == 'mixto'">
                                    <label for="tarjeta" class="form-label border-custom-text-2 fw-bold">TARJETA</label>
                                    <input class="form-control text-center input-1 fw-bold" type="text"
                                        @blur="mtdCalculate('tarjeta')" v-on:keypress="isNumber($event)"
                                        v-model="payment.amount.tarjeta" aria-label="default input example"
                                        placeholder="S/0.00" />
                                </div>

                                <div class="mb-3 div-margin"
                                    v-if="bottonActiveM == 'deposito' || bottonActiveM == 'mixto'">
                                    <label for="deposito"
                                        class="form-label border-custom-text-2 fw-bold">DEPÓSITO</label>
                                    <input class="form-control text-center input-1 fw-bold" type="text"
                                        @blur="mtdCalculate('depostio')" v-on:keypress="isNumber($event)"
                                        v-model="payment.amount.deposito" aria-label="default input example"
                                        placeholder="S/0.00" />
                                </div>
                                <div class="mb-3 div-margin" v-if="bottonActiveM == 'transferencia' || bottonActiveM == 'mixto'
        ">
                                    <label for="exampleFormControlInput1"
                                        class="form-label border-custom-text-2 fw-bold">TRANSFERENCIA</label>
                                    <input class="form-control text-center input-1 fw-bold" type="text"
                                        @blur="mtdCalculate('transferencia')" v-model="payment.amount.transferencia"
                                        v-on:keypress="isNumber($event)" aria-label="default input example"
                                        placeholder="S/0.00" />
                                </div>
                                <div class="mb-3 div-margin" v-if="pending">
                                    <label for="pendiente"
                                        class="form-label border-custom-text-2 fw-bold">PENDIENTE</label>
                                    <input class="form-control text-center input-1 fw-bold" type="text" disabled
                                        v-model="payment.pending" placeholder="S/0.00"
                                        aria-label="default input example" readonly />
                                </div>
                                <div class="mb-3 div-margin">
                                    <label for="input_total"
                                        class="form-label border-custom-text-2 fw-bold">TOTAL PAGADO</label>
                                    <input class="form-control text-center fw-bold input_total" readonly type="text"
                                        v-model="totalPay" aria-label="default input example" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class=" col-12 mb-3 d-flex justify-content-center"
                     v-if="totalPay>0 || canje==true">
                    <div class="">
                        <div class="row mx-0 mb-2">
                            <div v-for="(item, index) in dataDocument" :key="index"
                             class="col-xl-4 text-center "
                              v-if="pendingpay!='' && totalPay<pay &&  item.name=='TICKET' && canje==false">
                                <button class="myButton" v-bind:class="computedClass(item.name)"
                                    v-on:click="activarTipo(item.name)">
                                    <div v-bind:class="activeClass(item.name)">
                                        <i class="far fa-file-invoice-dollar fa-2x my-3"></i>
                                    </div>
                                    <div>{{ item.name }}</div>
                                </button>
                            </div>
                            <div v-for="(item, index) in dataDocument" :key="index"
                             class="col-xl-4 text-center "
                              v-if="productpay!=2 && pendingpay=='' && item.name !='TICKET'">
                                <button class="myButton" v-bind:class="computedClass(item.name)"
                                    v-on:click="activarTipo(item.name)">
                                    <div v-bind:class="activeClass(item.name)">
                                        <i class="far fa-file-invoice-dollar fa-2x my-3"></i>
                                    </div>
                                    <div>{{ item.name }}</div>
                                </button>
                            </div>
                            <div v-for="(item, index) in dataDocument" :key="index"
                             class="col-xl-4 text-center "
                              v-if="productpay!=2 && pendingpay!='' && item.name !='TICKET'">
                                <button class="myButton" v-bind:class="computedClass(item.name)"
                                    v-on:click="activarTipo(item.name)">
                                    <div v-bind:class="activeClass(item.name)">
                                        <i class="far fa-file-invoice-dollar fa-2x my-3"></i>
                                    </div>
                                    <div>{{ item.name }}</div>
                                </button>
                            </div>
                            <div v-for="(item, index) in dataDocument" :key="index"
                             class="col-xl-4 text-center "
                              v-if="productpay==2 && pay==totalPay && item.name !='TICKET'">
                                <button class="myButton" v-bind:class="computedClass(item.name)"
                                    v-on:click="activarTipo(item.name)">
                                    <div v-bind:class="activeClass(item.name)">
                                        <i class="far fa-file-invoice-dollar fa-2x my-3"></i>
                                    </div>
                                    <div>{{ item.name }}</div>
                                </button>
                            </div>
                            <div v-for="(item, index) in dataDocument" :key="index"
                             class="col-xl-4 text-center "
                              v-if="canje==true && item.name !='TICKET'">
                                <button class="myButton" v-bind:class="computedClass(item.name)"
                                    v-on:click="activarTipo(item.name)">
                                    <div v-bind:class="activeClass(item.name)">
                                        <i class="far fa-file-invoice-dollar fa-2x my-3"></i>
                                    </div>
                                    <div>{{ item.name }}</div>
                                </button>
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 ">
                            <div class="input-group input-group-sm mb-3">
                                <div class="input-group-text">
                                    <input :disabled="disabledChkDoc" class="form-check-input mt-0"
                                        @change="mtdSelectDoc" type="checkbox" v-model="chkDoc"
                                        aria-label="Checkbox for following text input" />&nbsp;{{
                                        chkDoc == true ? "A nombre del paciente " : "A nombre de: "
                                    }}
                                </div>
                                <input type="text" class="form-control" v-on:keypress="isNumber($event)"
                                    @keyup="mtdSearchDocument" :maxlength="bottonActiveT == 'BOLETA' ? 8 : 11"
                                    id="txtDoc" v-model="chkPatiente.document" :disabled="chkDoc"
                                    placeholder="Documento de paciente" />
                                <input type="text" aria-label="Last name" id="txtDoc2" placeholder="Nombre de paciente"
                                    disabled class="form-control" v-model="chkPatiente.fullName" />

                            </div>
                            <div class="input-group input-group-sm" v-if="bottonActiveT == 'FACTURA'">
                                <div class="input-group-text">Dirección</div>
                                <input type="text" class="form-control" id="txtDoc" v-model="chkPatiente.address"
                                    :disabled="true" placeholder="Dirección" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <button   color="danger" class="btn btn-danger btn-sm"
               @click="mtdHideModal()">
                <i class="fa fa-times"/>&nbsp;Cerrar
            </button>
            &nbsp;
            <button class="btn btn-success btn-sm"
            @click="mtdInsertData()" >
                <i class='fas fa-save' />&nbsp; Registrar
            </button>
            &nbsp;
        </template>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-oftalmologia-modal-registrarpago",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            modaltitle:"",
            productpay:"",
            pay:"",
            pendingpay:"",
            salepay:"",
            procede:false,
            swal:null,
            bottonActiveM: "",
            bottonActiveT: "",
            vuelto: false,
             pending: false,
            totalPay: 0.0,
            disabledChkDoc: true,
            chkDoc: false,
            chkPatiente: {
                document: "",
                fullName: "",
                address: "",
            },
            payment: {
                document: "",
                method: "",
                type: "",
                amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                },
                pending: 0,
                vuelto: 0,
                payment_patient: null,
                payment_eps: 0,
            },
            dataDocument: [
                { name: 'BOLETA' },
                { name: 'FACTURA' },
                { name: 'TICKET' },
            ],

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: Object,
        bead:Object,
        canje: Boolean,

    },
    computed: {},
    created(){
   //console.log("item", this.item);
    this.pay=this.item.total==''?parseFloat(parseFloat(this.item.price_sale)+(parseFloat(this.item.price_sale)*0.18)).toFixed(2)
    :parseFloat(this.item.total).toFixed(2); 
   //console.log("pay", this.pay);
    this.salepay=this.item.sale_id;
   //console.log("sale", this.salepay);
    this.pendingpay=this.item.sale_pendidng==""?parseFloat(parseFloat(this.item.price_sale)+
    (parseFloat(this.item.price_sale)*0.18)).toFixed(2):this.item.sale_pendidng;
   //console.log("pending", this.pendingpay);
    this.productpay=this.item.of_service_id;
   //console.log("product", this.productpay);
   //pago
   this.modaltitle=this.title+" "+" PENDIENTE DE : "+this.pendingpay;
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.$emit("closeModalPagos");
        },
        computedClass: function (comprobante) {
            return this.bottonActiveT == comprobante ?
                "myButton active-btn w-75" :
                "myButton w-75";
        },
        activeClass: function (comprobante) {
            return this.bottonActiveT == comprobante ?
                "myButton-icon-2 active-ico" :
                "myButton-icon-2";
        },
        activarMedio: function (value) {
            this.payment.method = value;
            this.bottonActiveM = value;
            this.payment.amount = {
                efectivo: 0,
                tarjeta: 0,
                deposito: 0,
                transferencia: 0,
            };
            this.mtdCalculate(this.payment.method);
        },
        activarTipo: function (value) {
            this.bottonActiveT = value;
            this.payment.document = value;
            if (value == "BOLETA" || value == "FACTURA" ||value == "TICKET") {
                this.disabledChkDoc = false;
            } else {
                this.disabledChkDoc = true;
            }
            this.chkDoc = true;
            this.mtdSelectDoc();
        },
        mtdSelectDoc: function () {
            if (this.chkDoc) {
                this.chkPatiente.document = this.item.of_patient_document;
                this.chkPatiente.fullName = this.item.of_patient_name;
            } else {
                this.chkPatiente.document = "";
                this.chkPatiente.fullName = "";
            }
        },
        mtdCalculate: function (input) {
            let acum = this.mtdGetAcum();
            if (this.payment.type == "credito") {
                let dif = parseFloat(acum - this.totalPay);
                if (dif < 0) {
                    this.payment.pending = parseFloat(dif).toFixed(2) * -1;
                    this.vuelto = false;
                    this.pending = true;
                } else {
                    this.payment.vuelto = parseFloat(dif).toFixed(2);
                    this.vuelto = true;
                    this.pending = false;
                }
                if (input == "efectivo" || input == "mixto") {
                    this.procede = true;
                } else {
                    this.procede = false;
                }
            } 
            //CAMBIOS
            else if(input == "efectivo"){
                let dif = parseFloat(acum - this.pendingpay);
                    if (dif <= 0) {
                        this.procede = true;
                        this.vuelto = false;
                        this.totalPay = acum;
                    } 
                    else {
                        this.procede = true;
                        this.vuelto = true;
                        this.payment.vuelto = parseFloat((acum - this.pendingpay).toFixed(2));
                        this.totalPay = this.pendingpay;
                    }
            }
            // FIN CAMBIOS
                
            else {
                this.procede = true;
                this.totalPay = acum;
            }
        },
        mtdGetAcum() {
            let acum = 0;
                if (this.payment.method === 'efectivo' && !this.payment.amount.efectivo) {
                    this.payment.amount.efectivo = 0;
                }
                if (this.payment.method === 'tarjeta' && !this.payment.amount.tarjeta) {
                    this.payment.amount.tarjeta = 0;
                }
                if (this.payment.method === 'deposito' && !this.payment.amount.deposito) {
                    this.payment.amount.deposito = 0;
                }
                if (this.payment.method === 'transferencia' && !this.payment.amount.transferencia) {
                    this.payment.amount.transferencia = 0;
                }
            if (this.payment.method == "efectivo") {
                acum = parseFloat(this.payment.amount.efectivo);
            } else if (this.payment.method == "tarjeta") {
                acum = parseFloat(this.payment.amount.tarjeta);
            } else if (this.payment.method == "deposito") {
                acum = parseFloat(this.payment.amount.deposito);
            } else if (this.payment.method == "transferencia") {
                acum = parseFloat(this.payment.amount.transferencia);
                // }else if (this.payment.method == "planilla"){
                //   acum = parseFloat(this.payment.amount.planilla);
            } else if (this.payment.method == "mixto") {
                acum =
                    parseFloat(this.payment.amount.efectivo) +
                    parseFloat(this.payment.amount.tarjeta) +
                    parseFloat(this.payment.amount.deposito) +
                    parseFloat(this.payment.amount.transferencia);
                // + parseFloat(this.payment.amount.planilla);
            }
            return acum;
        },
        mtdSearchDocument: function () {
            if (this.bottonActiveT == "BOLETA" ||this.bottonActiveT == "TICKET" ) {
                if (this.chkPatiente.document.length == 8 && this.chkDoc == false) {
                    this.get({
                        url: this.$store.getters.get__url + "/Consultas/" +
                            this.chkPatiente.document,
                        token: this.$store.getters.get__token,
                    })
                        .then((response) => {
                            if (response.data.boo == 3) {
                                this.chkPatiente.document = "";
                            } else {
                                this.chkPatiente.fullName = response.data.name + " " + response.data.last_name;
                                this.chkPatiente.address = "";
                            }
                        });
                }
            } else if (this.bottonActiveT == "FACTURA") {
                if (this.chkPatiente.document.length == 11 && this.chkDoc == false) {
                    this.get({
                        url:
                            this.$store.getters.get__url +
                            "/Consultas/" +
                            this.chkPatiente.document,
                        token: this.$store.getters.get__token,
                    })
                        .then((response) => {
                            if (response.data.boo == 3) {
                                this.chkPatiente.document = "";
                            } else {
                                this.chkPatiente.fullName = response.data.razon_social;
                                this.chkPatiente.address = response.data.direccion;
                            }
                        });
                }
            }
        },
        mtdHideModal(){
            this.chkPatiente = {
                document: "",
                fullName: "",
                address: "",
            };
            this.payment = {
                document: "",
                type: "",
                amount: {
                    efectivo: 0,
                    tarjeta: 0,
                    deposito: 0,
                    transferencia: 0,
                },
                pending: 0,
                vuelto: 0,
                payment_patient: null,
                payment_eps: 0,
            };
            // this.item={};
        this.$emit("closeModalPagos");
        },
        mtdInsertData() {
                let total=0;
                if(this.item.sale_id==""){
                 total= parseFloat(parseFloat(this.item.price_sale) +
                                                (parseFloat(this.item.price_sale)*0.18)).toFixed(2);
                }else{
                    total= parseFloat(this.item.pending).toFixed(2);
                }
                if (this.canje==false){
                if(this.totalPay==0){
                    Swal.fire({
                    text: "Por favor  Ingresemonto a  pagar.",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;  
                }
                if (!this.chkPatiente.document ||!this.chkPatiente.fullName) {
                Swal.fire({
                    text: "Por favor, complete el documento del paciente y marque la casilla correspondiente.",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;
                 }
                if(this.totalPay > total || this.totalPay<=0){
                Swal.fire({
                    text: "El monto invalido.",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;
               }
                if(this.item.of_service_id!=2 && this.totalPay <total){
                Swal.fire({
                    text: "El servicio  debe ser  pagado en su totalidad",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;
             }    
            this.post({
                url: this.$store.getters.get__url + "/Admision/cxcstore",
                token: this.$store.getters.get__token,
                params: {
                    bead_id: this.bead.id,
                    total_pay: this.totalPay,
                    item: this.item,
                    payment: this.payment,
                    chkPatiente: this.chkPatiente,
                    totalSer:total,
                },
            })
                .then((response) => {
                    if (response.state == 0) {
                        if (response.voucher != null) {
                            if(response.voucher.document=='TICKET'){ 
                              window.open(this.$store.getters.get__url +"/Admision/ticket/"+response.voucher.id, "_blank");
                            }
                            else{
                            let res = response.voucher.sunat;
                            window.open(res.json.enlace_del_pdf_ticket, "_blank");
                            }
                        }
                        /** todo correcto **/
                        Swal.fire({
                            title: "Registro Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        // this.mtdGetData();
                        this.mtdHideModal();
                    }
                })
                .catch((errors) => { });
        }else{
            if (!this.chkPatiente.document ||!this.chkPatiente.fullName) {
                Swal.fire({
                    text: "Por favor, complete el documento del paciente y marque la casilla correspondiente.",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });}
            this.post({
                url: this.$store.getters.get__url + "/Admision/cxccanje",
                token: this.$store.getters.get__token,
                params: {
                    item: this.item,
                    chkPatiente: this.chkPatiente,
                    bead_id: this.bead.id,
                    payment: this.payment
                },
            })
                .then((response) => {
                    if (response.state == 0) {
                        if (response.voucher != null) {
                            
                            let res = response.voucher.sunat;
                            window.open(res.json.enlace_del_pdf_ticket, "_blank");
                            }
                        }
                        /** todo correcto **/
                        Swal.fire({
                            title: "Canje Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        // this.mtdGetData();
                        this.mtdHideModal();
                    })
                .catch((errors) => { });         
         
        }},
        /*** heplpers */
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
};
</script>

<style scoped>
.input_total {
    border: 1.5px solid #900052;
    color: #900052;
}

.input-1 {
    border: 1.5px solid #9c9a9b;
    color: #9c9a9b;
}

.div-margin {
    margin: 0px 2px 0px 2px;
}

.active-btn {
    /* border-color: #900052 !important; */
    color: #900052 !important;
}

.myButton {
    border: none;
    background-color: transparent;
    color: #9c9a9b;
    align-content: center !important;
}

/* .myButton-div {
        border: 1.5px solid #9c9a9b;
        background-color: transparent;
        color: #9c9a9b;
        border-radius: 10px;
        width: 100px;
        padding: 4px 5px 4px 5px;
    } */

.myButton-div:hover {
    border: 1.5px solid #900052;
    color: #900052;
}

/* .myButton-icon {
        border: 1.5px solid #9c9a9b;
        border-radius: 15px;
        height: 65px;
        margin: 0px 0px 0px 0px;
    } */

.myButton-icon-2 {
    border: 1.5px solid #9c9a9b;
    border-radius: 15px;
    height: 65px;
    margin: 0px 10px 0px 10px;
}

.myButton:hover .myButton-icon {
    border-color: #900052;
}

.myButton:hover div {
    color: #900052;
}

.myButton:hover .myButton-icon-2 {
    border-color: #900052;
}

.border-custom-text-2 {
    color: #900052;
    text-align: left !important;
    width: 150px !important;
}
</style>
<template>
  <div>
    <div class="container" v-if="view=='table'">
      <div id="div-modal-pasaje" class="w-100">
        <div id="div-pasaje" class="card-body w-100">
            <div class="row">
              <div class="col-md-12 h2" id="btn-registro-pasaje">
                <a href="javascript:void(0)" @click="mtdStore">
                  <i class="fas fa-plus-circle"></i>
                </a>
              </div>
              <div class="col-md-12 table-container" id="content-timelinetable">
                <table class="table table-bordered ">
                  <caption class="py-0 my-0"></caption>
                  <thead>
                    <tr class="text-white table-th text-center text-xs">
                      <th class="align-middle border border-5 bg-main border-white">N°</th>
                      <th class="align-middle border border-5 bg-main border-white">RESPONSABLE</th>
                      <th class="align-middle border border-5 bg-main border-white">PAGO</th>
                      <th class="align-middle border border-5 bg-main border-white">COSTO</th>
                      <th class="align-middle border border-5 bg-main border-white">VUELTO</th>
                      <th class="align-middle border border-5 bg-main border-white">ESTADO</th>
                    </tr>
                  </thead>
                  <tbody class="text-xs">
                    <tr v-for="(item, index) in Passages" :key="index" class="table-body">
                      <td class="border border-5 border-white align-middle">{{index + 1}}</td>
                      <td class="border border-5 border-white align-middle">{{item.document}}-{{item.name}}</td>
                      <td class="border border-5 border-white align-middle">{{item.payment}}</td>
                      <td class="border border-5 border-white align-middle">{{item.cost}}</td>
                      <td class="border border-5 border-white align-middle">{{item.turned}}</td>
                      <td class="border border-5 border-white align-middle">
                        <a href="javascript:void(0)" v-if="item.state == 1"  id="btn-pediente-pasaje">
                          <i class="far fa-circle"></i>
                        </a>
                        <a href="javascript:void(0)" v-else-if="item.state == 2" id="btn-autorizar-pasaje">
                          <i class="fas fa-check-circle"></i>
                        </a>
                        <a href="javascript:void(0)" v-else id="btn-error-pasaje">
                          <i class="fas fa-times-circle"></i>
                        </a>
                      </td>
                    </tr>
                    <tr v-if="Passages.length == 0" class="text-center">
                        <td class="text-center"  colspan="7">No hay registros</td>
                      </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div><br><br><br>
    </div>
    <div class="row" v-if="view=='store'">
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm">
          <span class="input-group-text">Fecha y hora</span>
          <input
            type="date"
            aria-label="date"
            class="form-control form-control-sm"
            v-model="passage.date"
          />
          <input
            type="time"
            aria-label="time"
            class="form-control form-control-sm"
            v-model="passage.time"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text">Destinos</span>
          <input
            type="text"
            aria-label="initial"
            placeholder="Destino inicio"
            class="form-control form-control-sm"
            v-model="passage.initial"
          />
          <input
            type="text"
            aria-label="end"
            placeholder="Destino final"
            class="form-control form-control-sm"
            v-model="passage.end"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm">
          <span class="input-group-text">Montos</span>
          <input
            type="text"
            aria-label="payment"
            placeholder="Entregado"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            v-model="passage.payment"
          />
          <input
            type="text"
            aria-label="cost"
            placeholder="Costo"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            v-on:input="verifyCost"
            v-model="passage.cost"
          />
          <input
            type="text"
            aria-label="turned"
            placeholder="Vuelto"
            class="form-control form-control-sm"
            v-model="cpTurned"
            disabled
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text" id="motive">Motivo</span>
          <select class="form-control" v-model="passage.motive">
            <option disabled selected>Seleccione Motivo....</option>
            <option v-for="(item, index) in dataPassage" :key="index" :value="item.id">
              {{ item.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm">
          <span class="input-group-text" id="document">Responsable</span>
          <!--  -->
          <input
            type="text"
            class="form-control form-control-sm"
            aria-label="Sizing example input"
            placeholder="DNI"
            maxlength="8"
            aria-describedby="document"
            v-model="passage.document"
            @keyup="mtdSearchDocument"
          />
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            placeholder="Nombre y Apellido"
            disabled
            aria-describedby="name"
            v-model="passage.fullname"
          />
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            v-model="passage.type"
            type="radio"
            name="inlineRadioOptions"
            value="1"
          />
          <label class="form-check-label">Solo ida</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            class="form-check-input"
            v-model="passage.type"
            type="radio"
            name="inlineRadioOptions"
            value="2"
          />
          <label class="form-check-label">Ida y vuelta</label>
        </div>
      </div>
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center mt-3">
        <div class="btn-group" role="group" aria-label="Basic mixed styles example">
          <button type="button" class="btn btn-sm btn-danger" @click="mtdCancelar">
            Cancelar
          </button>
          &nbsp;
          <button type="button" class="btn btn-sm btn-success ml-1" @click="mtdCommit">
            Guardar
          </button>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
//import { bus } from "../../main";
import moment from "moment";

export default {
  name: "c-admsion-storepasaje",
  components: {
        SweetAlert
    },
  props: {
    bead: {},
  },
  data() {
    return {
      view: "table",
      swal:null,
      dataPassage: [],
      Passages: [],
      passage: {
        bead_id: null,
        date: null,
        time: null,
        campu_id: "",
        document: "",
        initial: "",
        end: "",
        motive: "",
        payment: "",
        cost: "",
        turned: "",
        type: 1,
        fullname: "",
      },
      fullname: "",
    };
  },
  computed: {
    cpTurned() {
      if (this.passage.payment > 0 && this.passage.cost > 0) {
        if (parseFloat(this.passage.payment) >= parseFloat(this.passage.cost)) {
          this.passage.turned =
            parseFloat(this.passage.payment) - parseFloat(this.passage.cost);
        } else {
          this.passage.cost="";
          this.passage.turned = 0;
          this.$refs.SweetAlert.showWarning("El costo debe ser menor o igual a lo Entregado ");
        }
      } else {
        this.passage.turned = 0;
      } 
      return this.passage.turned;
    },
  },
  created() {
    moment.locale("es");
    this.passage.date =
      moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.passage.time = moment().format("LT");
    this.passage.bead_id = this.bead.id;
    this.passage.campu_id = this.$store.getters.get__campus;
    this.mtdGetReasons();
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackTable: function () {
      this.$emit("mtdBackTable");
    },
    mtdGetData: function(){
      this.post({
        url: this.$store.getters.get__url + "/Admision/passage",
        token: this.$store.getters.get__token,
        params:{
          bead:this.bead.id
        }
      })
        .then((response) => {
          this.Passages=response.data;          
        })
        .catch((errors) => {});
    },
    mtdGetReasons: function () {
      this.get({
        url: this.$store.getters.get__url + "/passagereasons",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.dataPassage = response.data;
        })
        .catch((errors) => { console.log(errors); });
    },
    verifyCost() {
     if (
        this.passage.cost !== null &&
        this.passage.cost > 41 
      ) {
        this.$refs.SweetAlert.showWarning("El costo debe ser menor o igual a 41");
        this.passage.cost = "";
      }
    },
    mtdStore: function () {
      this.view = "store";
    },
    mtdCancelar: function () {
      this.view = "table";
      this.passage = {
              bead_id: null,
              date: null,
              time: null,
              campu_id: "",
              document: "",
              initial: "",
              end: "",
              motive: "",
              payment: "",
              cost: "",
              turned: "",
              type: 1,
            };
    },
    mtdCommit: function () {
      if (!this.passage.initial ||
      !this.passage.end ||
      !this.passage.payment ||
      !this.passage.cost ||
      !this.passage.motive ||
      !this.passage.document) {
      this.$refs.SweetAlert.showWarning("¡Complete todos los campos antes de guardar!");
    return;
  }
      this.post({
        url: this.$store.getters.get__url + "/Admision/passage/store",
        token: this.$store.getters.get__token,
        params: this.passage,
      })
        .then((response) => {
          if (response.error==false) {
            this.passage = {
              bead_id: null,
              date: null,
              time: null,
              campu_id: "",
              document: "",
              initial: "",
              end: "",
              motive: "",
              payment: "",
              cost: "",
              turned: "",
              type: 1,
            };
            this.Passages.push(response.data);
            this.view = "table";
          } else if (response.state == 41) {
            this.$refs.SweetAlert.showWarning(response.message);
          }else {
            alert(response.message);
          }
        })
        .catch((errors) =>{console.log(errors);s});
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdSearchDocument: function () {
      if (this.passage.document.length === 8) { 
        this.get({
          url: this.$store.getters.get__url + "/Consultas/" +this.passage.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.boo== 3) {
               this.passage.document="";
               this.$refs.SweetAlert.showWarning(response.data.error);  
               this.passage.fullname="";
            } else {
              this.passage.fullname = response.data.name + " " + response.data.last_name;
             
            }
          })
          .catch(() => {
          });
      }
    },
  },
};
</script>

<style scoped>
.btn-home {
  z-index: 1;
}
#btn-autorizar-pasaje {
  color: #39b54a;
  font-size: 25px;
}
#btn-autorizar-pasaje:hover {
  color: #217b2d;
}
#btn-error-pasaje {
  color: #ff0000;
  font-size: 25px;
}
#btn-error-pasaje:hover {
  color: #a30404;
}
#btn-pediente-pasaje {
  color: #ffe600;
  font-size: 25px;
}
#btn-pediente-pasaje:hover {
  color: #c0ac01;
}
#btn-registro-pasaje a {
  color: #900052;
}
#btn-registro-pasaje a:hover {
  color: #65013a;
  align-items: center;
  transform: scale(1.5);
}
.circulo {
  vertical-align: middle;
  border-style: none;
}
#div-modal-pasaje {
  margin-top: 10px;
}
@media (max-width: 800px) {
  /* #div-modal-pasaje{
      margin-top: 10px;
    } */
  #btn-open-caja {
    justify-content: center !important;
  }
  #btn-registro-pasaje {
    text-align: center;
  }
  #div-tabla-pasaje {
    overflow-x: auto;
  }
}
@media (min-width: 800px) {
  /* #div-modal-pasaje{
      margin-top: 10px;
    } */
  #btn-registro-pasaje {
    text-align: left;
  }
} 
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}
</style>

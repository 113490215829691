<template>
    <div>
        <div class="container">
            <div class="d-flex flex-row-reverse">
                <div class="mt-5 mb-4 btn-home">
                    <button type="button" class="btn text-white" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-anular" class="w-100">
                <div id="div-anular" class="card w-100">
                    <div class="card-header text-white h4 bg-main text-center">
                        <strong>SERVICIOS</strong>
                    </div>
                    <div class="card-body w-100">
                        <cTable :columns="columns" :data="data" @openmodal="openmodal" @deleteData="deleteData" />
                    </div>
                </div>
            </div>
            <cModalCirugiaVue :input="modal.input" :num="modal.num" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
            <SweetAlert :swal="swal" ref="SweetAlert"  @deleteDataDetail="deleteDataDetail" />
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import cTable from '../shared/Table/cTable.vue'
    import cModalCirugiaVue from './Modals/cModalCirugia.vue'
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    export default {
        name: 'c-mantenimiento-surgeries',
        data() {
            return {
                swal: null,
                columns: [
                    'N°',
                    'NOMBRE',
                    'COSTO S/IGV',
                    'VENTA S/IGV',
                    'COSTO C/IGV',
                    'VENTA C/IGV',
                    'ESTADO',
                    'ACCIONES'
    
                ],
                data: [],
                modal: {
                    title: '',
                    modal_form: false,
                    num :null,
                    payload: {},
                    input:null
                }
    
            }
        },
        components: {
            cTable,
            cModalCirugiaVue,
            SweetAlert
        },
        created() {
            this.getData();
        },
        methods: {
            ...mapActions(['get', 'post']),
            getData: function () {
                this.get({
                        url: this.$store.getters.get__url + "/Maintainer/index/9" ,
                        token: this.$store.getters.get__token,
                    }).then(response => {
                        const dataArray = response.data;
                        dataArray.forEach(item => {
                            this.calculatePricesIGV(item);
                        });
                        this.data = dataArray
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            Datadetail:function (payload_detail, num_detail){
              if(num_detail == 0){
                payload_detail.action ='c';
                this.addDatadetail(payload_detail)
              }else{
                payload_detail.action ='u';
                this.addDatadetail(payload_detail)
              }
            },
            addDatadetail:function(payload_detail){
                this.post({
                        url: this.$store.getters.get__url + "/Maintainer/Servicio",
                        token: this.$store.getters.get__token,
                        params: payload_detail, 
                    })
                    .then(response => {
                       this.$refs.SweetAlert.showSuccess(response.message);
                       this.closeModal()
                       this.getData();  
                    })
                    .catch(error => {
                        console.log(error);
                    }) 
            },
        
            deleteData:function(payload_detail){
                payload_detail.action ='d';
                this.$refs.SweetAlert.showDelete( payload_detail , 'deleteDataDetail');
            
            },
            deleteDataDetail:function( payload_detail){
                this.post({
                        url: this.$store.getters.get__url + "/Maintainer/Servicio",
                        token: this.$store.getters.get__token,
                        params: payload_detail,
                    })
                    .then(response => {
                        if(response.statusCode ==200){
                            this.getData();
                        }   
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            calculatePricesIGV: function (item) {
                const priceCost = parseFloat(item.price_cost);
                const priceSale = parseFloat(item.price_sale);
                const igvPercentage = 1.18;
                const priceCostIGV = priceCost * igvPercentage;
                const priceSaleIGV = priceSale * igvPercentage;
                item.price_cost_igv = priceCostIGV.toFixed(2);
                item.price_sale_igv = priceSaleIGV.toFixed(2);
            },
    
            returnHome() {
                this.$emit('mtdChangePage', 'home')
            },
            openmodal: function (num, payload) {
                this.modal.title = (num == 0) ? 'Registrar Servicio' : 'Editar Servicio';
                this.modal.modal_form = true;
                this.modal.num = num;
                this.modal.payload=payload;
                this.modal.input= 2
            },
            closeModal: function () {
                this.modal = {
                    title: '',
                    modal_form: false,
                    num :null,
                    payload: {},
                    input:null
                }
            }
        }
    }
    </script>
    
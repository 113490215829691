<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0  px-2">
  
        <div v-if="status"  class=" col-xl-4 text-center  btn-home my-2 ">
            <button @click="mtdstoredata(7)" type="button" class="btn w-100 fw-bold">
                    IMAGENES 
            </button>
        </div>
        <div v-else  class=" col-xl-4 text-center  btn-home my-2 ">
            <button @click="mtdstoredata(4)" type="button" class="btn w-100 fw-bold">
                CONTROL
            </button>
        </div>
        <div class=" col-xl-4 text-center  btn-home my-2 ">
            <button @click="mtdstoredata(5)" type="button" class="btn w-100 fw-bold" :disabled="status">
                LECTURA DE RESULTADO
            </button>
        </div>
        <div class=" col-xl-4 text-center  btn-home my-2 ">
            <button @click="mtdstoredata(6)" type="button" class="btn w-100 fw-bold" :disabled="status">
                FINALIZADO
            </button>
        </div>
    </div>
    <hr />

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";

import {
    mapActions
} from "vuex";

export default {
    name: "c-vigilancia-modal-control",
    components: {
        CModal,
    },
    data() {
        return {

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        status:{
            type:Boolean,
            default:false
        }

    },
    computed:{
       mtddisabled:function(){
        return this.imagenes > 0;
       }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodal')
        },
        mtdstoredata(status) {
            this.$emit('confirmed', status)
        }
    },
};
</script>

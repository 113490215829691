<template>
<div>
    <div class="container">
        <div class="row">
            <div id="btn-home" class="col-md-12 mt-3 mb-4 d-flex justify-content-end btn-home">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="card-header text-white h4 text-center" style="background: #900052">
                    <strong>STOCK MÍNIMO</strong>
                </div>

                <div class="card-body w-100">
                    <div class="row">

                        <div v-if="cpData.length > 0" class="d-flex justify-content-end px-1 ">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination mb-0 ">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="col-md-12 py-2">
                            <div class="row d-flex">
                                <div class="col-lg-12 col-md-12">
                                    <div class="input-group input-group mb-2">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input v-model="search" type="text" class="form-control form-control" placeholder="Buscar Producto" @input="mtdSearchFilter" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 mb-4" id="dtb-cxc">
                            <table id="dtb-cxc" class="table table-bordered border-main text-center align-middle">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">PRODUCTO</th>
                                        <th scope="col">STOCK MIN.</th>
                                        <th scope="col">STOCK MAX.</th>
                                        <th scope="col">ACCIONES</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-if="cpData.length === 0">
                                        <td colspan="16" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr v-for="(item, index) in cpData" :key="index">

                                        <td class="align-left">{{ item.name }}</td>
                                        <td>{{ parseInt(item.stock_min) }}</td>
                                        <td>{{ parseInt(item.stock_max) }}</td>
                                        <td>
                                            <a @click="viewStock(item)" class="btn btn-sm btn-info" type="button">
                                                <i class="fas fa-edit"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalViewStockVue :title="modalStock.title" :boo_modal="modalStock.modal_form" @closeModalStock="closeModalStock" @mtdRegisterStock="mtdRegisterStock" :item="item" />
    <SweetAlert ref="SweetAlert" />

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModalViewStockVue from "../modals/cModalViewStock.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";

import moment from "moment";

export default {
    name: "c-logistica-pages-stockminimo",
    components: {
        cModalViewStockVue,
        SweetAlert
    },
    data() {
        return {
            search: "",
            data: [],
            dataShow: [],
            selectPage: 1,
            noRecordsMessage: "",
            modalStock: {
                title: "Registro de Stock",
                item: {},
                modal_from: false,
                pos: null,
            },
            item: {
                id: null,
                name: '',
                min_stock: '',
                max_stock: ''
            },
            filter: {
                begindate: null,
                enddate: null,
                id: "",
            },
            stepPagination: [],
            kardex: [],
            totales: {},
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        //cpData() {}
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Logistica/stock/filter",
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.data = response.data;
                if (this.data.length === 0) {
                    this.noRecordsMessage = "No hay registros";
                    this.dataShow = [];
                } else {
                    this.calculateData(10);
                }
            }).catch((error) => {
                console.log(error);
            });
        },

        mtdSearchFilter() {
            this.calculateData(10);
        },

        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            //console.log(this.dataShow);
        },

        //Fecha
        /* formatDate(date) {
          const formattedDate = new Date(date);
          const day = String(formattedDate.getDate()).padStart(2, "0");
          const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
          const year = formattedDate.getFullYear();
          return `${day}/${month}/${year}`;
        }, */

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },

        viewStock: function (item) {
            this.modalStock = {
                title: "Registro de Stock de " + item.name,
                modal_form: true,
                idProductDetail: item.id
            };
            this.item = item
        },
        closeModalStock: function () {
            this.modalStock = {
                title: "Registro de Stock",
                modal_form: false,
                idProductDetail: 0
            };
            this.item = {}
        },
        mtdRegisterStock: function (stock_min, stock_max) {
            this.post({
                    url: this.$store.getters.get__url + "/Logistica/stock/store",
                    token: this.$store.getters.get__token,
                    params: {
                        of_product_detail_id: this.modalStock.idProductDetail,
                        stock_min: stock_min,
                        stock_max: stock_max,
                    },
                })
                .then((response) => {
                    if (response.error == false) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.closeModalStock();
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showWarning(response.message);
                    }
                })
                .catch((errors) => {});
        },
        changePage(page) {
            this.$emit("returnHome", page);
        },
    },

    /* filters: {
      formatDate(date) {
        return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
    }, */
};
</script>

<template>
    <div>
        <div class="container text-center mt-2 mb-3">
            <div id="div-modal-anular" class="w-100">
                <div id="div-anular" class="card w-100">
                    <div class="card-header text-white h5 bg-main text-center">
                        <span class="col btn border border-white text-white" style="float: left;" @click="mtdBehindStep()"><strong>ATRAS</strong></span>
                        <strong style="display: inline-block;">RESERVA DE {{elementProcess.name}}</strong>
                        <span class="btn border border-white text-white" style="float: right;" @click="mtdNextStep()"><strong>SIGUIENTE</strong></span>
                    </div>
                    <div class="card-body w-100">
                        <div class="row  mt-2 mb-2">
                            <div class="col-sm-12 col-md-12 col-xl-8 mt-4">
                                <div id="div-modal-anular" class="w-100">
                                    <div id="div-anular" class="card w-100">
                                        <div class="card-body w-100">
                                            <header class="card-header bg-main text-white align">
                                                <h6 class="card-title ">DR(A). {{elementProcess.doctor.name}}</h6>
                                            </header>
                                            <div class="row">
                                                <div class="col-md-12 py-2">
                                                    <div class="card-body">
                                                        <FullCalendar :options="calendarOptions" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-xl-4 mt-4">
                                <div id="div-anular" class="card w-100">
                                    <div class="card-body w-100">
                                        <section class="card">
                                            <header class="card-header bg-main text-white align">
                                                <h6 class="card-title ">CREAR CITA</h6>
                                            </header>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12 legend d-flex justify-content-center legend mb-2">
                                                        <div class="legend-item">
                                                            <div class="circle" style="background-color: blue;"></div>
                                                            <span>CONSULTAS</span>
                                                        </div>
                                                        <div class="legend-item">
                                                            <div class="circle" style="background-color: green;"></div>
                                                            <span>CIRUGIAS</span>
                                                        </div>
                                                       
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="col-form-label label-modal">Fecha</label>
                                                            <input type="text" class="form-control" autocomplete="off"
                                                              name="date" v-model="quote.date" disabled  placeholder="Seleccione  una fecha" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="col-form-label label-modal">Salas</label>
                                                            <div>
                                                                <v-select id="patientSelect"
                                                                    class="text-dark form-control" :options="conRoom"
                                                                    v-model="Reservation.selectedConRoom"
                                                                    :reduce="(name) => name.id" label="name"
                                                                    placeholder="Seleccione Una Sala" required
                                                                    :clearable="false"
                                                                    @input="mtdGetTurn()"
                                                                    />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label class="col-form-label label-modal"> Seleccione Turno</label>
                                                            <div class="row d-flex justify-content-center text-center">
                                                                <div v-for="turno in turns" :key="turno.id"
                                                                    class="col-md-12 col-sm-12 col-xl-4 col-lg-4 mt-2">
                                                                    <button type="button"
                                                                        class="btn input-group-text form-control"
                                                                        :class="{ 'btn-outline-custom': selectedTurno !== turno.name, 'text-white bg-main': selectedTurno === turno.name }"
                                                                        @click="selectTurno(turno.name)"
                                                                        :disabled="!quote.dateStr|| Reservation.selectedConRoom == ''">
                                                                        {{ turno.name }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 mt-2" v-if="selectedTurno!==''">
                                                        <div class="table-responsive">
                                                            <table aria-describedby="mydesc" class="table table-striped table-scroll">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Horas</th>
                                                                        <th scope="col"><i class="fas fa-calendar-check"></i> Estado</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <template v-if="selectedTurno!==''&& selectedTurno!='Colados'">
                                                                        <tr v-for="hour in ofHours" :key="hour.id" class="cursor text-lg table-body">
                                                                            <td class="wide-cell">
                                                                                <button type="button" v-if="hour.status==1"
                                                                                        class="btn input-group-text form-control"
                                                                                        >
                                                                                    {{ hour.name }}
                                                                                </button>
                                                                                <button  type="button"  v-else 
                                                                                        class="btn input-group-text form-control"
                                                                                        :class="{ 'btn-warning text-white': selectedHour === hour.id }"
                                                                                        @click="selectHour(hour.id)"
                                                                                        :disabled="!quote.dateStr||Reservation.selectedConRoom ==''"
                                                                                        >
                                                                                    {{ hour.name }}
                                                                                </button>
                                                                            </td>
                                                                            <td class="wide-cell">
                                                                                <span v-if="hour.status==1">
                                                                                    <i class="fas fa-circle text-danger"></i> Reservado
                                                                                </span>
                                                                                <span v-else @click="selectHour(hour.id)">
                                                                                    <i class="fas fa-circle text-success"></i>
                                                                                        {{ selectedHour === hour.id ? 'Seleccionado':'Libre'}}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr v-for="hour in ofHours" :key="hour.id" class="cursor text-lg table-body">
                                                                            <td class="wide-cell">
                                                                                <button  type="button" 
                                                                                        class="btn input-group-text form-control"
                                                                                        :class="{ 'btn-warning text-white': selectedHour === hour.id }"
                                                                                        @click="selectHour(hour.id)"
                                                                                        :disabled="!quote.dateStr||Reservation.selectedConRoom ==''"
                                                                                        >
                                                                                    {{ hour.name }}
                                                                                </button>
                                                                            </td>
                                                                            <td class="wide-cell">
                                                                                <span @click="selectHour(hour.id)">
                                                                                    <i class="fas fa-circle text-success"></i>
                                                                                        {{ selectedHour === hour.id ? 'Seleccionado':'Libre'}}
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="card-footer text-white" style="background: #900052; font-size: 20px">
                        <div class="row">
                            <div align="left" class="col">
                                <span class="btn border border-white text-white"  @click="mtdBehindStep()"><strong>ATRAS</strong></span>
                            </div>
                            <div align="right" class="col">
                                <span class="btn border border-white text-white"  @click="mtdNextStep()"><strong>SIGUIENTE</strong></span>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import cModalCitasVue from '@/components/Oftalmologia/Venta/Modals/cModalCitas.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import FullCalendar from '@fullcalendar/vue';
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';

export default {
    name: 'c-call-citas',
    components: {
        FullCalendar,
        cModalCitasVue,
        SweetAlert,
    },
    props:{
     elementProcess:Object,
    },
    data() {
        return {
            swal: null,
            selectedHour: '',
            viewInput: null,
            pos: 0,
            showCalendar: false,
            Reservation: {
                selectedConRoom: "",
            },
            serviceOptions: [],
            medics: [],
            patients: [],
            turns: [],
            ofHours: [],
            conRoom: [],
            opeRoom: [],
            imgRoom: [],
            calendarOptions: {
                locale: "es",
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                events: [],
                initialView: "dayGridMonth",
                buttonText: {
                    today: "Hoy",
                    month: "Meses",
                    week: "Semanas",
                    day: "Días",
                },
                headerToolbar: {
                    left: "title",
                    center: "",
                    right: "prev today next",
                },
                weekends: false,
                selectable: true,
                editable: false,
                validRange: {
                    start: Date.now(),
                },
                dateClick: this.dateClick,
                dayMaxEventRows: true,
                views: {
                    timeGrid: {
                        dayMaxEventRows: 6
                    }
                }
            },
            events: [],
            quote: {
                
                dateStr: '',
                campu_id: null,
                turn: 1,
                date:''
            },
            selectedTurno: '',
            ultimaFechaSeleccionada: null,
            lastSelectedDay: null,
            hourbycita: '',
            ofCall: []
        }
    },
    created() {
            this.events = [];
            this.mtdGetHour();
            this.mtdGetConRoom();
            if(this.elementProcess.data.date){
                this.events = [];
                this.mtdGetHour();
                this.mtdGetConRoom();
                this.quote.dateStr=this.elementProcess.data.date;
                this.quote.date = moment(this.quote.dateStr, 'YYYY-MM-DD').format('DD/MM/YYYY');
                this.selectedHour=this.elementProcess.data.hour;
                this.Reservation.selectedConRoom=this.elementProcess.data.room;
                this.mtdGetTurn();
            }
    },
    watch: {
        events: {
            handler(newEvents) {
                // Actualiza calendarOptions con los nuevos eventos
                this.calendarOptions.events = newEvents;
            },
            deep: true
        }
    },
    computed: {
        isFilterDisabled() {
            return !this.Reservation.selectedService || !this.Reservation.selectedMedic;
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        selectHour(hour) {
            this.selectedHour = hour; // Seleccionar la hora
        },
           mtdGetTurn(){
           if(this.quote.dateStr!=""&& this.Reservation.selectedConRoom!=''){
            this.ofHours = [];
            this.selectedTurno='';
            this.post({
                url: this.$store.getters.get__url + "/Admision/turnsoperations",
                token: this.$store.getters.get__token,
                params:{
                    room:this.Reservation.selectedConRoom,
                    doctor:this.elementProcess.doctor.id,
                    fecha:this.quote.dateStr,
                }
                })
                .then((response) => {
                    this.turns = response.data;

                    this.turns.forEach(turn => {
                        turn.of_hour.forEach(hour => {
                            this.ofHours.push({
                                id: hour.id,
                                name: hour.name
                            });
                        });
                    });
                })
                .catch(error => {
                    console.log(error);
          })
        }
        //else{
        // this.$refs.SweetAlert.showWarning("Escoga una Fecha"); 
        // }
        },
        mtdGetConRoom: function () {
            this.post({
                url: this.$store.getters.get__url + "/Admision/roomoperation",
                token: this.$store.getters.get__token,
                params: {
                    campu: this.$store.getters.get__campus,
                    service:this.elementProcess.servicio.id
                },
            })
                .then((response) => {
                    this.conRoom = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                })
        },

        mtdGetHour: function () {
            this.post({
                url: this.$store.getters.get__url + "/Admision/bringDate",
                token: this.$store.getters.get__token,
                params: {
                    Campus: this.$store.getters.get__campus,
                    doctor: this.elementProcess.doctor.id,
                },
            })
            .then((response) => {
                    this.ofCall = response.data;
                    this.bringDate();
                })
                .catch(error => {
                    console.log(error);
                })
                
        },
        bringDate: function () {
            this.events = [];
            const eventCount = {};
            if (this.ofCall.CONSULTAS && Array.isArray(this.ofCall.CONSULTAS)) {
                this.ofCall.CONSULTAS.forEach(date => {
                    const day = date.split('T')[0];
                    const eventType = "CONSULTAS";
                    if (!eventCount[day]) {
                        eventCount[day] = {};
                    }
                    if (!eventCount[day][eventType]) {
                        eventCount[day][eventType] = 0;
                    }
                    eventCount[day][eventType]++;
                    eventCount[day][eventType + '_event'] = {
                        title: `${eventType}-${eventCount[day][eventType]}`,
                        start: date,
                        backgroundColor: "blue",
                        borderColor: "blue",
                        textColor: "white"
                    };
                });
            }

            if (this.ofCall.CIRUJIAS && Array.isArray(this.ofCall.CIRUJIAS)) {
                this.ofCall.CIRUJIAS.forEach(date => {
                    const day = date.split('T')[0];
                    const eventType = "CIRUGIAS";
                    if (!eventCount[day]) {
                        eventCount[day] = {};
                    }
                    if (!eventCount[day][eventType]) {
                        eventCount[day][eventType] = 0;
                    }
                    eventCount[day][eventType]++;
                    eventCount[day][eventType + '_event'] = {
                        title: `${eventType}-${eventCount[day][eventType]}`,
                        start: date,
                        backgroundColor: "green",
                        borderColor: "green",
                        textColor: "white"
                    };
                });
            }
            Object.values(eventCount).forEach(dayEvents => {
                Object.values(dayEvents).forEach(event => {
                    if (typeof event === 'object') {
                        this.events.push(event);
                    }
                });
            });
        },
        dateClick: function (info) {
            const date2 = new Date(info.date);
            const date = info.dateStr
            const olddate = new Date();
            var ano = olddate.getFullYear();
            var mes = ('0' + (olddate.getMonth() + 1)).slice(-2);
            var dia = ('0' + olddate.getDate()).slice(-2);
            var fechaFormateada = ano + '-' + mes + '-' + dia;
            if (date < fechaFormateada) {
                this.$refs.SweetAlert.showWarning("Elija una Fecha Válida");
            } else {
                const auxiliar = this.fixDate(date2, "date-en");
                this.quote.date = moment(auxiliar, 'YYYY-MM-DD').format('DD/MM/YYYY');
                
                this.quote.dateStr=moment(auxiliar, 'YYYY-MM-DD').format('YYYY-MM-DD');
                this.ultimaFechaSeleccionada = date;  
                this.mtdGetTurn();
            }
        },
        
        selectTurno(turno) {
            this.ofHours=[];
            if (this.selectedTurno === turno) {
                this.selectedTurno = '';
                this.ofHours = [];
            } else {
                this.selectedTurno = turno;
                this.ofHours=[];
                const selectedTurn = this.turns.find(turn => turn.name === turno);
                if (selectedTurn) {
                    this.ofHours=[];
                    this.ofHours = selectedTurn.of_hour.map(hour => ({
                        id: hour.id,
                        name: hour.name,
                        status:hour.status
                    }));
                }
            }
        },
        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        fixDate(date, type) {
            let dateFixed = "";
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hours = "";
            switch (type) {
                case "date-es":
                    if (date.getDate() < 10) day = "0" + day;
                    dateFixed = `${day}/${month}/${year}`;
                    break;
                case "date-en":
                    if (date.getDate() < 10) day = "0" + day;
                    dateFixed = `${year}-${month}-${day}`;
                    break;
                case "time":
                    date.getHours() < 10 ?
                        (hours += "0" + date.getHours()) :
                        (hours = date.getHours());
                    dateFixed = `${hours}:${date
                        .getMinutes()
                        .toString()
                        .padStart(2, "0")}`;
                    break;

                default:
                    break;
            }
            return dateFixed;
        },
     mtdNextStep: function () {
      if (
        this.quote.dateStr!=''&&
        this.selectedHour!=''&&
        this.Reservation.selectedConRoom!=''
      ) {
        this.elementProcess.data = {
        turno:this.selectedTurno,
        date:this.quote.dateStr,
        hour:this.selectedHour,
        room: this.Reservation.selectedConRoom,
         };
       this.elementProcess.programado = true;
       this.quote.dateStr=="";
       this.selectedHour=="";
       this.Reservation.selectedConRoom=="";
        this.$emit("mtdNextProcess", this.elementProcess);
      } else {
        this.$refs.SweetAlert.showWarning("Seleccione una sala Y Fecha"); 
    }},
    mtdBehindStep: function () {
      this.$emit("mtdBehindProcess", this.elementProcess);
    },

    }
}
</script>

<style scoped>
#newPatientLink {
    text-decoration: none;
    color: #900052;
}

.btn-outline-custom {
    color: #900052;
    border-color: #900052;
}

.cursor {
    cursor: pointer;
}

.wide-cell {
    padding: 4px;
    font-size: 14px;
}

.align {
    display: block;
    text-align: left;
}

.fc-highlight {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #000000;
}

/*leyenda*/
.legend-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.circle {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
}
.btn-warning {
    background-color: #ffc107 !important; /* Color amarillo */
    border-color: #ffc107 !important;
}

.text-white {
    color: #ffffff !important; /* Texto blanco */
}
.table-responsive {
    max-height: 250px; 
    overflow-y: auto;
    display: block;
  }
</style>
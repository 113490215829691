<template>
<div>
    <div class="container text-center mt-2">
        <div class="row">
            <div class="col-md-12 py-2">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-11 col-sm-11 rounded-3 px-0 bg-blue my-2">
                        <div class="row mx-0">
                            <div class="h-100 py-1 mx-0 col-12 px-0 text-x border rounded-start d-inline-flex">
                                <span class=" align-middle col-md-2 col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                                <span class=" align-middle col-md-10 col-sm-8 h-100 px-0 text-start" v-if="patient">{{ patient.name }}</span>
                                <span class=" align-middle col-md-10 col-sm-8 h-100 px-0 text-start" v-else></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 my-2 px-4">
                <div class="row d-flex">
                    <div class="col-md-6 col-sm-12">
                        <div class="input-group input-group-sm mb-2">
                            <span class="input-group-text col-md-3">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init" @change="mtdGetData" />
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end" @change="mtdGetData"/>
                        </div>
                    </div>
                    <!--
                    <div class="btn-home col-lg-4 col-md-6">
                        <button type="button" class="btn w-100">
                            <i class="fas fa-upload"></i> Cargar PDF
                        </button>
                    </div>
                    -->

                    <!--PDF-->
                    <div class="col-lg-4 col-md-6">
                        <div class="input-group input-group-sm mb-3">
                        <input
                            @change="getFile"
                            type="file"
                            class="form-control"
                            id="file"
                            lang="es"
                            name="file"
                            ref="fileInput"
                        />
                        </div>
                    </div>
                     <!--Fin PDF-->

                    <div class="btn-home col-lg-4 col-md-6">
                        <button type="button" class="btn btn-sm  w-100" @click="mtdCommitAnti()">
                            <i class="far fa-save"></i>&nbsp; Guardar PDF
                        </button>
                    </div>

                </div>
            </div>
            <!--tabla-->
            <div class="col-md-12" id="content-timelinetable" >
                <div>
                    <table id="tb-anular" class="table table-borderless">
                        <caption class="py-0 my-0"></caption>
                        <thead>
                            <tr class="text-white table-th text-center text-xs">
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">N°</th>
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">Fecha</th>
                                <th scope="col" colspan="1" class="align-middle border border-5 bg-main border-white">ACCIONES</th>
                            </tr>
                        </thead>
                        <tbody class="">
                            <template v-if="cpData.length > 0">
                                <tr class="text-xs table-body" v-for="(item, index) in cpData" :key="index">
                                    <td class="border border-5 border-white align-middle">{{ index + 1 }}</td>
                                    <td class="border border-5 border-white align-left">{{ item.created_at | formatDate}}</td>
                                    <td class="text-center border border-5 border-white align-middle">
                                        <div class="d-flex justify-content-center">
                                            <button v-if="item.file" class="btn btn-sm btn-danger text-white" @click="openPDF(`/assets/${item.file}`)">
                                                <i class="fa fa-file-pdf"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td colspan="3">Sin Registros</td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
             <!--pag-->
             <div v-if="cpData.length > 0" class="d-flex justify-content-center px-1">
                <nav aria-label="Page navigation example">
                <ul class="pagination mb-1">
                    <li class="page-item">
                    <a
                        class="page-link page"
                        href="javascript:void(0)"
                        @click="backPage"
                        >Anterior</a
                    >
                    </li>
                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                    <a
                        :class="
                        selectPage === 1 ? 'page-link active' : 'page-link'
                        "
                        href="javascript:void(0)"
                        @click="selectedPage(1)"
                        >1</a
                    >
                    </li>
                    <li
                    v-if="limitedStepPagination[0] > 2"
                    class="page-item disabled"
                    >
                    <span class="page-link">...</span>
                    </li>

                    <li
                    v-for="(item, index) in limitedStepPagination"
                    :key="index"
                    class="page-item"
                    >
                    <a
                        :class="
                        selectPage === item ? 'page-link active' : 'page-link'
                        "
                        href="javascript:void(0)"
                        @click="selectedPage(item)"
                        >{{ item }}
                    </a>
                    </li>
                    <li
                    v-if="
                        limitedStepPagination[
                        limitedStepPagination.length - 1
                        ] <
                        stepPagination.length - 1
                    "
                    class="page-item disabled"
                    >
                    <span class="page-link">...</span>
                    </li>
                    <li
                    v-if="
                        limitedStepPagination[
                        limitedStepPagination.length - 1
                        ] !== stepPagination.length
                    "
                    class="page-item"
                    >
                    <a
                        :class="
                        selectPage === limitedStepPagination.length + 2
                            ? 'page-link active'
                            : 'page-link'
                        "
                        href="javascript:void(0)"
                        @click="selectedPage(stepPagination.length)"
                        >{{ stepPagination.length }}
                    </a>
                    </li>
                    <li class="page-item">
                    <a
                        class="page-link page"
                        href="javascript:void(0)"
                        @click="nextPage"
                        >Siguiente</a
                    >
                    </li>
                </ul>
                </nav>
            </div>
        </div>

    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import moment from "moment";

export default {
    name: 'c-medico-antecedente',
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            search: "",
            filter: {
                init: null,
                end: null,
            },
            dataShow: [],
            antiHc: [],
            antiguas: {
                type: "",
                file: "",
                of_doctor_id: "",
                of_patient_id: "",
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        }
    },
    props: {
        patient: {
            type: Object,
        }
    },
    created() {
        moment.locale("es");
        this.filter.end =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;

        this.mtdGetData();
    },
    watch: {
      selectPage() {
        this.calculateData(5);
      },
    },
    computed: {
      cpData() {
        return this.dataShow;
      },
  
      limitedStepPagination() {
        const totalPages = this.stepPagination.length;
        const currentPage = this.selectPage;
  
        let startPage = 1;
        let endPage = totalPages;
        if (totalPages > this.totalPagesToShow) {
          const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);
  
          if (currentPage > halfPagesToShow) {
            startPage = currentPage - halfPagesToShow;
            endPage = currentPage + halfPagesToShow - 1;
  
            if (endPage > totalPages) {
              endPage = totalPages;
              startPage = totalPages - this.totalPagesToShow + 1;
            }
          } else {
            endPage = this.totalPagesToShow;
          }
        }
  
        return Array.from(
          {
            length: endPage - startPage + 1,
          },
          (_, i) => startPage + i
        );
      },
    },
    methods: {
        ...mapActions(['get', 'post']),

        openPDF(url) {
            window.open(url, '_blank');
        },

        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Doctor/getAntigua",
                token: this.$store.getters.get__token,
                params: {
                    init: this.filter.init,
                    end: this.filter.end,
                    of_patient_id: this.patient.id,
                    of_doctor_id: this.$store.state.user.id
                },
            })
                .then((response) => {
                this.antiHc = response.data;
                this.calculateData(5);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        calculateData(items) {
            if (!this.antiHc || !this.antiHc.length) {
            return (this.dataShow = []);
            }
            let filteredData = this.antiHc;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from(
            {
                length: totalPages,
            },
            (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        getFile(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(pdf)$/i.test(file.name)) {
            this.$refs.SweetAlert.showWarning("Seleccione un archivo formato PDF");
            fileInput.value = '';
            } else {
                this.antiguas.file = file;
            }
	    },

        mtdCommitAnti: function () {

            if (!this.patient.id) {
                this.$refs.SweetAlert.showWarning("No hay Paciente en Hc. Antigua");
                return; 
            }

            if (!this.antiguas.file || !this.patient.id) {
                this.$refs.SweetAlert.showWarning("Asegúrate de seleccionar un Archivo");
                return; 
            }

            let formData = new FormData();
            formData.append("type",1);
            formData.append("file", this.antiguas.file);
            formData.append("of_patient_id", this.patient.id);
            formData.append("of_doctor_id", this.$store.state.user.id);
            this.post({
                url: this.$store.getters.get__url + "/Doctor/antAntigua",
                token: this.$store.getters.get__token,
                params: formData,
            })
                .then((response) => {
                this.$refs.SweetAlert.showSuccess(response.message);
                this.mtdGetData();
                    this.antiguas = {
                        type: "",
                        file: "",
                    };
                    this.$refs.fileInput.value = "";
                
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

        mtdGetStyle: function (option) {
            if (option == this.page) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return 'color:white';
            return '';
        },
        changePage: function (page) {
            this.page = page;
            this.stateHistory = (page === 'antecedente') ? 1 : 0;
            this.viewInput = (page === 'antecedente') ? 1 : 0;
        },
        returnHome() {
            this.$emit('mtdChangePage', 'home')
        },
        mtdviewpage: function (payload) {
            this.page = 'antecedente'
            this.stateHistory = 0;
            this.patients = payload;
            this.viewInput = 0;
        },
        
        /** paginación */
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
            this.selectPage < this.stepPagination.length
                ? this.selectPage + 1
                : this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>

<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}
</style>

<template>
    <div>
        <div class="container">
            <div class="row text-end">
                <div class="col-md-12 mt-3 mb-4 float-right btn-home">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-anular" class="d-flex justify-content-center">
                <div id="div-anular" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>ANULACIONES</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="row mx-0">
                                <div class="col-xl-3 col-12 px-0">
                                    <div class="input-group input-group-sm w-100  ">
                                        <span class="input-group-text ">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" @change="mtdGetData" id="init" />
                                    </div>
                                </div>
                                <div class="col-xl-3 col-12 px-0 px-xl-1 my-xl-0 my-2">
                                    <div class="input-group input-group-sm  w-100 ">
                                        <span class="input-group-text ">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" @change="mtdGetData" id="end" />
                                    </div>

                                </div>
                                <!-- <div class="col-xl-2 px-0 px-xl-1 my-xl-0 my-2">
                                    <div class="input-group input-group-sm text-center btn-home">
                                        <button class="btn btn-sm text-white w-100">
                                            <i class="fas fa-search"></i>&nbsp;Buscar
                                        </button>
                                    </div>
                                </div> -->
                                <div class="col-xl-2 px-0">
                                    <div class="input-group input-group-sm text-center btn-home">
                                        <button  @click="mtdRegistrar" class="btn btn-sm w-100 text-white w-100">
                                            <i class="fas fa-save"></i>&nbsp;Registrar anulación
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body w-100">
                             <div class="row">
                                <div class="col-md-12 table-container" id="content-timelinetable">

                                    <table class="table table-bordered ">
                                        <caption class="py-0 my-0"></caption>
                                        <thead>
                                            <tr class="text-white table-th text-center text-xs">
                                                    <th class="align-middle border border-5 bg-main border-white">
                                                        N°
                                                    </th>
                                                    <th class="align-middle border border-5 bg-main border-white">
                                                        FECHA
                                                    </th>
                                                    <th class="align-middle border border-5 bg-main border-white">
                                                        HORA
                                                    </th>
                                                    <th class="align-middle border border-5 bg-main border-white">
                                                        COMPROBANTE
                                                    </th>
                                                    <th class="align-middle border border-5 bg-main border-white">
                                                        SERIE Y N°
                                                    </th>
                                                    <th class="align-middle border border-5 bg-main border-white">
                                                        MONTO
                                                    </th>
                                                    <th class="align-middle border border-5 bg-main border-white">
                                                        MOTIVO
                                                    </th>
                                                    <th class="align-middle border border-5 bg-main border-white" colspan="1">
                                                        ESTADO
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody class="text-xs">
                                                <tr v-for="(item, index) in cpData" :key="index" class="table-body">
                                                    <td class="border border-5 border-white align-middle">{{ index + 1 }}</td>
                                                    <td class="border border-5 border-white align-middle">{{ item.date | formatDate }}</td>
                                                    <td class="border border-5 border-white align-middle">{{ item.hour | formatTime }}</td>
                                                    <td class="border border-5 border-white align-middle">{{ item.of_voucher.document }}</td>
                                                    <td class="border border-5 border-white align-middle">{{ item.of_voucher.serie }}-{{ item.of_voucher.number }}</td>
                                                    <td class="border border-5 border-white align-middle text-end">S/ {{ parseFloat(item.of_voucher.amount).toFixed(2) }}</td>
                                                    <td class="border border-5 border-white align-middle">{{ item.motive_cancel }}</td>
                                                    <td class="border border-5 border-white align-middle text-center">
                                                        <a v-if="item.state == 2" href="javascript:void(0)" id="btn-autorizar-anular" class="a-success">
                                                            <i class="fas fa-check-circle"></i>
                                                        </a>
                                                        <a v-if="item.state == 3" href="javascript:void(0)" id="btn-error-anular" class="a-danger">
                                                            <i class="fas fa-times-circle"></i>
                                                        </a>
                                                        <a v-if="item.state == 1" href="javascript:void(0)" id="btn-pediente-anular" class="a-warning">
                                                            <i class="far fa-circle"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalRegisterCancelVoucherVue 
        :title="modalRegCancel.title" 
        :boo_modal="modalRegCancel.modal_form"
        :item="modalRegCancel.item" 
        @closeModalCancel="closeModalCancel" 
        @mtdSendPermission="mtdSendPermission" />
        <SweetAlert />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import cModalRegisterCancelVoucherVue from "./Modals/cModalRegisterCancelVoucher.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: 'c-oftalmologia-anulaciones',
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            data: [],
            dataShow: [],
            search: "",
            page: 0,
            selectPage: 1,
            stepPagination: [],
            modalRegCancel: {
                title: "",
                modal_form: false,
                data: {
                    serie: null,
                    number: null,
                    motive: null,
                    type: null,
                },
            },
        }
    },
    components: {
        cModalRegisterCancelVoucherVue,
        SweetAlert
    },
    props: {
        boxes: [],
    },
    created() {
        moment.locale("es");
        this.filter.begindate =
        moment().format("L").substr(6, 4) + "-" + moment().format("L").substr(3, 2) + "-01";
        this.filter.enddate =
        moment().format("L").substr(6, 4) +
        "-" +
        moment().format("L").substr(3, 2) +
        "-" +
        moment().format("L").substr(0, 2);
    },
    computed: {
        cpData() {
            let bo = false;
            if (this.search.length >= 2 && this.search != "") {
                let q = this.search.toString().toUpperCase();
                this.dataShow = [];
                this.data.forEach((element) => {
                let doc = element.sale.patient.document.toString().toUpperCase();
                let name = element.sale.patient.name.toString().toUpperCase();
                let serie = element.serie.toString().toUpperCase();
                let number = element.number.toString().toUpperCase();
                if (
                    doc.includes(q) ||
                    name.includes(q) ||
                    serie.includes(q) ||
                    number.includes(q)
                ) {
                    this.dataShow.push(element);
                }
                });
                if (this.dataShow.length === 0 && this.data.length !== 0) {
                this.noRecordsMessage = "No se encontraron resultados de la búsqueda";
                } else {
                this.noRecordsMessage = "";
                }
                bo = true;
            } else {
                let fin = this.selectPage * 5 - 1;
                let ini = fin - 4;
                this.dataShow = [];
                this.data.forEach((element, index) => {
                if (ini <= index && index <= fin) {
                    this.dataShow.push(element);
                }
                });
                bo = false;
                this.noRecordsMessage = "";
            }
            /** paginación */
            this.stepPagination = [];
            let cantidad =
                bo == false ? parseInt(this.data.length / 5) : parseInt(this.dataShow.length / 5);
            let residuo =
                bo == false ? parseInt(this.data.length % 5) : parseInt(this.dataShow.length % 5);
            cantidad = residuo > 0 ? cantidad + 1 : cantidad;
            for (let index = 0; index < cantidad; index++) {
                this.stepPagination.push(index + 1);
            }
            return this.dataShow;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            this.$emit("mtdChangePage", "home");
        },
        mtdGetData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Admision/anular",
                token: this.$store.getters.get__token,
                params: this.filter,
            })
            .then((response) => {
                this.data = response.data;
                /** paginación */
                let cantidad = parseInt(this.data.length / 5);
                let residuo = parseInt(this.data.length % 5);
                cantidad = residuo > 0 ? cantidad + 1 : cantidad;
                for (let index = 0; index < cantidad; index++) {
                    this.stepPagination.push(index + 1);
                }
            })
            .catch((errors) => {});
        },
        mtdSendPermission: function (serie, number, motive, type) {
            this.post({
                url: this.$store.getters.get__url + "/Admision/anular/store",
                token: this.$store.getters.get__token,
                params: {
                serie: serie,
                number: number,
                motive: motive,
                type: type,
                },
            })
                .then((response) => {
                if (response.state == 0) {
                    this.closeModalCancel();
                    this.data.push(response.cancel);
                    this.$refs.SweetAlert.showSuccess("Registrado con Exito!");
                } else {
                    this.$refs.SweetAlert.showWarning(response.message);
                }
                })
                .catch((errors) => {});
        },
        /** pagination */
        selectedPage: function (page) {
            this.selectPage = page;
        },
        backPAge: function () {
            this.selectPage = this.selectPage == 1 ? 1 : this.selectPage - 1;
        },
        addPAge: function () {
            this.selectPage =
            this.selectPage == this.stepPagination.length
            ? this.stepPagination.length
            : this.selectPage + 1;
        },
        mtdRegistrar: function () {
            this.modalRegCancel.title = "Registrar permiso de anulación";
            this.modalRegCancel.modal_form = true;
            this.modalRegCancel.data = {
                serie: null,
                number: null,
                motive: null,
            };
        },
        closeModalCancel: function () {
            this.modalRegCancel = {
                title: "",
                modal_form: false,
                data: {
                    serie: null,
                    number: null,
                    motive: null,
                    type: null,
                },
            };
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        }
    }
}
</script>
<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}
#cmbEgreso {
    height: 30px !important
}
#cmbEgreso div .vs__search {
    height: 30px !important
}
</style>
<template>
  <div class="container">
    <div id="btn-return" class="d-flex justify-content-end pt-1 mt-3">
      <div class="btn-home" role="group">
          <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
              <i class="fas fa-recycle"></i> Cambiar de Sede
          </button>
      </div>
  </div>
  <div>
    <div id="btn_home" class="c-appp align-items-center">
      <!--
      <div class="d-grid gap-2 mx-auto text-center col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <h2>HOME VIGILANCIA</h2>  
      </div>
      -->
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 ">
        <div class="d-flex justify-content-center">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center" id="column_center">
              <div class="colum_form">
                  <i class="fas fa-solid fa-id-card fa-9x" style="color: #900052; cursor: pointer;" @click="mtdGetMotive()"></i>
              </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <cModalDniVue :title="modalDni.title" :boo_modal="modalDni.modal_form"  :item="modalDni.item" @closeModalDni="closeModalDni" @changePage="changePage"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cModalDniVue from "./Modals/cModalDni.vue";
export default {
  components: {
        cModalDniVue,
    },
  data() {
    return {
      page: null,
      modalDni: {
          title: "AGREGAR DOCUMENTO",
          item: [],
          modal_form: false,
          pos: null,
      },
    };
  },
  created() {
      //this.mtdGetMotive();
  },
  methods: {
    ...mapActions(["get", "post"]),

    mtdGetMotive: function () {
      this.modalDni.title = "AGREGAR DOCUMENTO";
      this.modalDni.modal_form = true;
      this.get({
              url: this.$store.getters.get__url + "/surveillance/motive",
              token: this.$store.getters.get__token,
          })
          .then((response) => {
              this.modalDni.item = response.data;
          })
          .catch((error) => {
              console.log(error);
          });
    },

    changePage: function () {
      this.$emit("mtdChangePage", "seguimiento");
    },

    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdOpenDni: function () {
      this.modalDni = {
          title: "AGREGAR DOCUMENTO",
          modal_form: true,
      };
    },
    closeModalDni: function () {
        this.modalDni = {
            title: "AGREGAR DOCUMENTO",
            modal_form: false,
            item: [],
        };
    },
  },
};
</script>

<style lang="scss" scoped>
.c-appp {
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: 80vh;
  justify-content: center; 
  align-items: center
}

</style>

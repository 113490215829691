<template>
    <div>
        <div class="w-100 h-100">
            <Nav :rolname="rolname" :bead="bead"/>
        </div> 
        <div class="content">
            <Home  v-if="verifBead == true && page == 'home'" 
            :bead="bead"
            @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
            <Venta v-if="verifBead == true && page == 'venta'" @mtdChangePage="mtdChangePage" 
            :bead="bead"
            :client="client"
              @mtdCommitPayment="mtdCommitPayment"/>
            <Egreso 
             v-if="verifBead == true && page == 'egreso' &&
              this.bead.of_box.name=='CAJA CHICA'" 
              @mtdChangePage="mtdChangePage" 
               :bead="bead"
                @mtdCommitPayment="mtdCommitPayment
            "/>
            <Close  v-if="verifBead == true && page == 'reporteClose'" @mtdChangePage="mtdChangePage" :bead="bead"  @mtdBackCampus="mtdBackCampus"/>
            <Cxc v-if="verifBead == true && page == 'cxc'"  
            @mtdChangePage="mtdChangePage"  
            @mtdReprogramar="mtdReprogramar" 
            :bead="bead" 
            @mtdCommitBead="mtdCommitBead"/>
            <Egresos
            :bead="bead"
            :client="client"
             v-if="verifBead == true && page == 'egresos'
             &&this.bead.of_box.name=='CAJA CHICA'"
              @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus"/>
            <Open :boxes="boxes" v-if="verifBead == false"  @mtdOpen="mtdOpen"  @mtdBackCampus="mtdBackCampus"/>
            <Vueltos v-if="verifBead == true && page == 'vueltos'" 
                @mtdChangePage="mtdChangePage" 
                :bead="bead"   
                @mtdCommitBead="mtdCommitBead"
                :boxes="boxes"/>
            <Anulaciones v-if=" verifBead == true && page == 'anulaciones'" @mtdChangePage="mtdChangePage" />
            <Cita v-if=" verifBead == true && page == 'cita'" 
             @mtdChangePage="mtdChangePage" 
               :key="key"
              :reserId="reserId" 
              :date="date" 
              :serId="serId" 
              :patientId="patientId" 
             :hour="hour"
             :doctorId="doctorId"
             :state="state" />
            <Seguimiento v-if="  verifBead == true && page == 'seguimiento'"  @mtdChangePage="mtdChangePage" />
            <Resultados v-if="  verifBead == true && page == 'resultadosImagenes'"  @mtdChangePage="mtdChangePage" />
        </div>
        <div class="footer">
            <Footer  :bead="bead"
             @mtdChangePage="mtdChangePage"
             :pageView="page"
             v-if="verifBead==true" />
        </div>
    </div>
</template>
    
    <script>
    import {
        mapActions
    } from 'vuex'
    import Nav from '../../components/Oftalmologia/Nav.vue'
    import Home from '../../components/Oftalmologia/Home.vue'
    import Venta from '../../components/Oftalmologia/Venta.vue'
    import Egreso from "../../components/Oftalmologia/EgresoView.vue"
    import Close from '../../components/Oftalmologia/ReporteClose.vue'
    import Footer from '../../components/Oftalmologia/Footer.vue'
    import Cxc from '../../components/Oftalmologia/Cxc.vue'
    import Egresos from '../../components/Oftalmologia/Egresos.vue'
    import Open from '../../components/Oftalmologia/Open.vue'
    import Vueltos from '../../components/Oftalmologia/Vueltos.vue'
    import Anulaciones from '../../components/Oftalmologia/Anulaciones.vue'
    import Cita from '../../components/Oftalmologia/Citas.vue'
    import Seguimiento from '../../components/Oftalmologia/SeguiView.vue'
    import Resultados from '@/components/Oftalmologia/ResultImages.vue'
    
    export default {
        name: "OftalmologiaView",
        components: {
            Nav,
            Home,
            Venta,
            Egreso,
            Close,
            Footer,
            Cxc,
            Egresos,
            Open,
            Vueltos,
            Anulaciones,
            Cita,
            Seguimiento,
            Resultados
        },
        data() {
            return {
                prefix: 'Admision',
                page: 'home',
                key:0,
                rol_name: null,
                stateRolAdmin: false,
                client:null,
                bead: {},
                verifBead: false,
                boxes: [],
                reserId:"",
                date :"",
                serId :"",
                patientId :"",
                doctorId :"",
                hour :"",

            }
        },
        props: {
            rolname: String,
            dataRoles: Array
        },
        created() {
            this.rol_name = this.rolname;
            this.mtdGetRolAdmin();
            this.mtd_getData();
        },
        methods: {
            ...mapActions(['get', 'post']),

            mtd_getData: function () {
                this.get({
                    url:
                    this.$store.getters.get__url +
                    "/" +
                    this.prefix +
                    "/campu/" +
                    this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.boxes = response.boxes;
                    this.bead = response.bead;
                    this.verifBead = response.bead != null ? true : false;
                    })
                .catch((errors) => {
                    console.log(errors);
                });
            },
            mtdCommitBead(bead){
                this.bead=bead;
            },
            mtdChangePage: function (page,client) {
                this.client = page =="venta" ? client : page == "home" ? this.client : null;
                this.page = page;
            },
            mtdReprogramar(page, reserId, date, serId, patientId,hour, doctorId, state){
                this.key++;
              this.reserId = reserId;
              this.date = date;
              this.serId = serId;
              this.patientId = patientId;
              this.page = page;
              this.hour = hour;
              this.doctorId=doctorId;
              this.state=state;
        },
            mtdGetRolAdmin: function () {
                this.dataRoles.forEach(element => {
                    if (element.role_id == 1) {
                        this.stateRolAdmin = true;
                    }
                });
            },mtdCommitPayment(res){
                this.bead = res;
                this.page = "home";
            },
            mtdBackCampus: function () {
              this.$emit("mtdBackCampus");
            },

            mtdOpen: function (data) {
                this.bead = data;
                this.verifBead = true;
                this.page = "home";
            },
        }
    
    }
    </script>
    
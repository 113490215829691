<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">

        <div v-if="num == 2" class=" row col-xl-12 mt-3 mx-0 px-0 ">
            <div class="col-xl-4" v-for="(brand,index) in dataBrand" :key="index">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" :value="brand.id" v-model="localIdMarca" :id="index">
                    <label class="form-check-label" :for="index">
                        {{ brand.name }}
                    </label>
                </div>
            </div>
        </div>
        <div v-else class=" row col-xl-12 mt-3 mx-0 px-0 ">
            <div class="row max-0 px-0" v-if="productopormarcas.length > 0">
                <div class="col-xl-3  px-1 " v-for="(marca,index) in productopormarcas" :key="index">
                    <div class="form-check text-center  text-white rounded rounded-2 btn-home text-xxs" @click="mtdproduct(marca)">
                        <button type="button" class="btn w-100 text-xs">
                            {{ marca.marca_name }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="text-center fw-bold" v-else>
                <span>No hay registros</span>
            </div>
            <div v-if="marca.name != ''" class="mt-4">
                <h5 class="color-main px-1"> {{ marca.name }}</h5>

                <div class="row mx-0 px-0 mt-2">
                    <div class="col-xl-6 px-1">
                        <h6>Productos no Selecionados</h6>
                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered">
                                <caption class="mx-0 px-0"></caption>
                                <thead class="bg-main text-xxs">
                                    <tr class="text-white">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">PRODUCTOS</th>
                                        <th scope="col" class="align-middle">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xxs">
                                    <tr class="tbody-table" v-for="(item, index) in productosnoselecionados" :key="index">
                                        <td class="align-middle">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="align-middle">
                                            {{item.of_product.name }}  {{ item.molecule }} {{ item.comercial }} {{ item.presentacion }} {{ item.concentration }} {{ item.modelo }} {{ item.serie }}
                                        </td>
                                        <td class="align-middle text-center" @click="mtdnoselecionado(item)">
                                            <button type="button" class=" btn btn-success btn-sm px-1 py-0 ">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!productosnoselecionados.length > 0" class="text-center">
                                        <th scope="col" colspan="4">No hay registros</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-xl-6 px-0">
                        <h6>Productos Seleccionados</h6>
                        <div class="col-md-12 table-responsive" id="div-tabla-anular">
                            <table id="tb-anular" class="table table-bordered">
                                <caption class="mx-0 px-0"></caption>
                                <thead class="bg-main text-xxs">
                                    <tr class="text-white">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">PRODUCTOS</th>
                                        <th scope="col" class="align-middle">PRECIO</th>
                                        <th scope="col" class="align-middle">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main text-xxs">
                                    <tr class="tbody-table" v-for="(item, index) in productosselecionandos" :key="index">
                                        <td class="align-middle">
                                            {{ index + 1 }}
                                        </td>
                                        <td class="align-middle">
                                            {{item.of_product.name }} {{ item.molecule }} {{ item.comercial }} {{ item.presentacion }} {{ item.concentration }} {{ item.modelo }} {{ item.serie }}
                                        </td>
                                        <td class="align-middle" @dblclick="mtdSelectInput(index)">
                                            <span v-if="posEdit != index">{{ item.price_purchase}} </span>
                                            <input v-else type="text" class="form-control text-center form-control-sm w-50" v-model="newPrice" @keyup.enter="mtdOutFocusEditPrice" @blur="mtdOutFocusEditPrice" @input="validateAmount" placeholder="0.00" />
                                        </td>
                                        <td class="align-middle text-center">
                                            <button type="button" class=" btn btn-danger btn-sm px-1 py-0" @click="mtdselecionado(item)">
                                                <i class="fas fa-minus"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr v-if="!productosselecionandos.length > 0" class="text-center">
                                        <th scope="col" colspan="4">No hay registros</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button v-if="num ==2" class="btn btn-success btn-sm" @click="mtdaddbrand">
            Registrar
        </button>
        <button v-if="num !=2" class="btn btn-success btn-sm" @click="mtdaddproduct">
            Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-marca",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            filteredData: [],
            brandprovider: {
                brands_id: [],
                provider_id: null
            },
            marca: {
                id: '',
                name: ''
            },
            product_provider: {
                id: null,
                productosproveedor: []
            },
            productosselecionandos: [],
            productosnoselecionados: [],
            localIdMarca: [],
            product_brand: {
                id: null,
                id_provider: null,
                bran_id: null
            },
            productosproveedor: [],
            posEdit: null,
            oldPrice: null,
            newPrice: null
        };
    },
    watch: {
        // Actualizar la copia local cuando cambie la prop idMarca
        idMarca(newValue) {
            this.localIdMarca = [...newValue];
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        dataBrand: {
            type: Array,
            default: null
        },
        num: {
            type: Number,
            default: null
        },
        provider_id: {
            type: Number,
            default: null
        },
        dataBrandProvider: {
            type: Array,
            default: null
        },
        dataProvider: {
            type: Object,
            default: null
        },
        idMarca: {
            type: Array,
            default: null
        },
        productopormarcas: {
            type: Array,
            default: null
        }
    },

    computed: {},

    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.productosselecionandos = []
            this.productosnoselecionados = []
            this.marca = {
                id: '',
                name: ''
            }
            this.$emit("closeModalMarca");
        },
        mtdaddbrand: function () {
            this.brandprovider = {
                brands_id: this.localIdMarca,
                provider_id: this.dataProvider.id
            }
            this.$emit('mtdaddbrand', this.brandprovider);

        },
        mtdproduct: function ($item) {
            this.marca.id = $item.marca_id;
            this.marca.name = $item.marca_name;
            this.productosselecionandos = $item.productos_seleccionados;
            this.productosnoselecionados = $item.productos_no_seleccionados;

        },
        mtdnoselecionado: function (item) {
            const index = this.productosnoselecionados.findIndex(producto => producto.id === item.id);
            if (index !== -1) {
                const productoQuitado = this.productosnoselecionados.splice(index, 1)[0];
                this.productosselecionandos.push(productoQuitado);
            }

        },
        mtdselecionado: function (item) {
            const index = this.productosselecionandos.findIndex(producto => producto.id === item.id);
            if (index !== -1) {
                const productoQuitado = this.productosselecionandos.splice(index, 1)[0];
                this.productosnoselecionados.push(productoQuitado);
            }
        },
        mtdaddproduct: function () {
            this.productopormarcas.forEach(element => {
                let productomarca = {
                    brand_id: element.marca_id,
                    products: element.productos_seleccionados
                }
                this.productosproveedor.push(productomarca)
            });
            this.product_provider = {
                id: this.provider_id,
                productosproveedor: this.productosproveedor,
            }

            this.$emit('mtdaddproduct', this.product_provider);

            this.product_provider = {
                id: null,
                productosproveedor: []
            }
            this.marca.name = ''
        },
        mtdSelectInput: function (pos) {
            this.posEdit = pos;
            this.oldPrice = this.productosselecionandos[pos].price_purchase;
            this.newPrice = this.productosselecionandos[pos].price_purchase;

        },
        mtdOutFocusEditPrice: function () {

            if (this.oldPrice != this.newPrice && this.newPrice > 0) {
                Swal.fire({
                    icon: "warning",
                    title: "¿Desea cambiar este precio?",
                    width: "400px",
                    /** buttons */
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.mtdCommitEditPrice();
                    } else if (result.isDenied) {
                        this.posEdit = null;
                        this.oldPrice = null;
                        this.newPrice = null;
                    }
                });
            } else {
                this.posEdit = null;
                this.oldPrice = null;
                this.newPrice = null;
            }
        },
        validateAmount(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.newPrice = sanitizedValue;
        },
        mtdCommitEditPrice: function () {

            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/update/priceproduct",
                    token: this.$store.getters.get__token,
                    params: {
                        producto: this.productosselecionandos[this.posEdit].id,
                        provider: this.provider_id,
                        price: this.newPrice,
                    },
                })
                .then((response) => {
                    if (response.state == 0) {
                        this.productosselecionandos[this.posEdit].price_purchase = parseFloat(this.newPrice).toFixed(2);
                        this.posEdit = null;
                        this.oldPrice = null;
                        this.newPrice = null;
                        Swal.fire({
                            icon: "success",
                            title: "Editado correctamente",
                            showConfirmButton: false,
                            timer: 1500,
                        });

                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "",
                            icon: "error",
                            // width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {
                    Swal.fire({
                        title: "Error",
                        text: errors,
                        icon: "error",
                        // width: "400px",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                });
        },

    },

};
</script>

<template>
  <div>
    <div class="w-100 h-100">
      <Nav :rolname="rolname" />
    </div>
    <div class="content">
      <Home
        v-if="page == 'home'"
        @mtdChangePage="mtdChangePage"
        @mtdBackCampus="mtdBackCampus"
        @mtdSelectPatient="mtdSelectPatient"
        ref="home"
        @mtdShowOffCanva="mtdShowOffCanva"
      />
      <Antecedente
        v-if="page == 'antecedente'"
        @mtdChangePage="mtdChangePage"
        :stateRolAdmin="stateRolAdmin"
        :patient="patient"
      />
      <Historia
        v-if="page == 'historia'"
        ref="historiaRef"
        @mtdChangePage="mtdChangePage"
        @mtdCitas="mtdCitas"
        :stateRolAdmin="stateRolAdmin"
        :history="history"
        :patient="patient"
        :codesIa="codesIa"
        @mtdBackPage="mtdBackPage"
        @mtdShowOffCanva="mtdShowOffCanva"
        @mtdenviarstatus="mtdenviarstatus"
      />
      <AyudaDx
        v-if="page == 'ayudadx'"
        @mtdChangePage="mtdChangePage"
        :stateRolAdmin="stateRolAdmin"
        :patient="patient"
      />
      <Laboratorio
        v-if="page == 'laboratorio'"
        @mtdChangePage="mtdChangePage"
        :stateRolAdmin="stateRolAdmin"
        :patient="patient"
      />
      <Imagenes
        v-if="page == 'imagen'"
        @mtdChangePage="mtdChangePage"
        :stateRolAdmin="stateRolAdmin"
        :patient="patient"
      />
      <Reporte
        v-if="page == 'reporte'"
        @mtdChangePage="mtdChangePage"
        :stateRolAdmin="stateRolAdmin"
        :patient="patient"
      />
      <Consulta
        v-if="page == 'consulta'"
        @mtdChangePage="mtdChangePage"
        :stateRolAdmin="stateRolAdmin"
      />
      <Citas
        v-if="page == 'citas'"
        :calendario="calendario"
        @mtdChangePage="mtdChangePage"
        :stateRolAdmin="stateRolAdmin"
        @mtdShowOffCanva="mtdShowOffCanva"
      />
      <Conclusiones
        v-if="page == 'conclusiones'"
        @mtdChangePage="mtdChangePage"
      />
      <Codes v-if="page == 'codigos'" @mtdChangePage="mtdChangePage" />
      <TableHc v-if="page == 'tableHc'" @mtdChangePage="mtdChangePage" />
    </div>
    <div class="footer">
      <Footer @mtdChangePage="mtdChangePage" :patient="patient" :pageView="page" />
    </div>

    <div
      class="load-patient"
      data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"
    >
      <i class="far fa-user-circle"></i>
    </div>

    <div
      class="offcanvas offcanvas-end"
      data-bs-backdrop="static"
      tabindex="-1"
      id="offcanvasRight"
      aria-labelledby="offcanvasRightLabel"
    >
      <div class="offcanvas-header border">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">Cola Pacientes</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body align-center">
        <div class="d-flex title-option justify-content-center">
          <div class="btn-home mt-1 mb-1">
            <button
              type="button"
              class="btn btn-outline-main"
              @click="selectCola('cola')"
              :disabled="Object.keys(patient).length !== 0"
            >
              <i class="fas fa-home-lg-alt"></i> Lista Pacientes
            </button>
          </div>
          <div class="btn-home mt-1 mb-1 px-3">
            <button
              type="button"
              class="btn btn-outline-main"
              @click="selectCola('atendiendo')"
            >
              <i class="fas fa-home-lg-alt"></i> Paciente
            </button>
          </div>
        </div>

        <div class="home__wait">
          <Atendiendo
            v-if="cpAntendido"
            :status="status"
            :patient="patient"
            ref="atendiendoRef"
            @mtdShowCola="mtdShowCola"
            @confirmed="handleConfirmed"
            @finalizar="mtdregisterFO"
          />
          <ColaPaciente
            v-if="cpCola"
            @mtdSelectPatient="mtdViewHistory"
            ref="colaPaciente"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions   } from "vuex";
import Nav from "../../components/Medico/Nav.vue";
import Home from "../../components/Medico/Home.vue";
import Antecedente from "../../components/Medico/AntecedenteView.vue";
import Historia from "../../components/Medico/HistoriaView.vue";
import AyudaDx from "../../components/Medico/AyudaDxView.vue";
import Laboratorio from "../../components/Medico/LaboratorioView.vue";
import Imagenes from "../../components/Medico/ImagenView.vue";
import Reporte from "../../components/Medico/ReporteView.vue";
import Consulta from "../../components/Medico/ConsultaView.vue";
import Footer from "../../components/Medico/Footer.vue";
import Atendiendo from "./AtendiendoView.vue";
import ColaPaciente from "./ColaPacienteView.vue";
import Citas from "@/components/Medico/Citas.vue";
import Conclusiones from "@/components/Medico/ConclusionView.vue";
import Codes from "@/components/Medico/Codes.vue";
import TableHc from "@/components/Medico/TableHcView.vue"
export default {
  name: "MedicoView",
  components: {
    Nav,
    Home,
    Antecedente,
    Historia,
    AyudaDx,
    Laboratorio,
    Imagenes,
    Reporte,
    Consulta,
    Footer,
    Atendiendo,
    ColaPaciente,
    Citas,
    Conclusiones,
    Codes,
    TableHc,
  },
  data() {
    return {
      status: false,
      prefix: "medico",
      page: "home",
      rol_name: null,
      stateRolAdmin: false,
      patient: {},
      stateCola: "atendiendo",
      history: [],
      ayudadax: [],
      calendario: {},
      codesIa: [],
    };
  },
  props: {
    rolname: String,
    dataRoles: Array,
  },
  created() {
    this.rol_name = this.rolname;
    this.mtdGetRolAdmin();
  },
  computed: {
    cpAntendido() {
      return (
        this.stateCola == "atendiendo" || Object.keys(this.patient).length !== 0
      );
    },
    cpCola() {
      return this.stateCola == "cola" && Object.keys(this.patient).length === 0;
    },
    /* cpd_call_state() {
          return this.$store.getters["call__state"];
        },
        cpd_call_state_menu() {
          return this.$store.getters["call__state_menu"];
        },
        cpd_call_state_end() {
          return this.$store.getters["call__state_end"];
        },
        cpd_call_view_patient() {
          return this.$store.getters["call__state_view_patient"];
        }, */
  },
  methods: {
    //...mapActions(['get', 'post']),
    ...mapActions(["post", "get", "execute_commit"]),
    mtd_endAppointment: function () {
      this.execute_commit({
        mutation: "CALL__END_APPOINTMENT",
        payload: {
          state_menu: 0,
          state_end: 1,
        },
      });
    },
    mtdChangePage: function (page) {
      this.page = page;
      this.status = false;
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdBackPage: function () {
      this.patient = {};
      this.page = "home";
      this.history = [];
    },
    mtdGetRolAdmin: function () {
      this.dataRoles.forEach((element) => {
        if (element.role_id == 1) {
          this.stateRolAdmin = true;
        }
      });
    },
    mtdSelectPatient: function (patient,codesIa) {
      this.patient = patient;
      this.codesIa = codesIa;
      this.page = patient.of_product_name.includes("LECTURA")
        ? "ayudadx"
        : "historia";
      //cargar el historial clinico
      this.get({
        url:
          this.$store.getters.get__url +
          "/Doctor/ListClinicalHistory/" +
          patient.id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.history = response.data;
          let clinica_history = {
            id: null,
            user_id: null,
            patient_id: null,
            reason: "",
            fo: "",
            physical_exploration: "",
            other_exams: null,
            te: "",
            diagnostics: null,
            plan: null,
            recipe: null,
            recomendations: "",
            optometria: null,
            of_reservation_id: null,
            status: null,
            option: null,
            created_at: "HC Actual",
          };
          if (patient.status === 1) this.history.push(clinica_history);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    mtdViewHistory: function (patient, codesIa) {
      //cuando viene de FO
      this.mtdSelectPatient(patient,codesIa);
      this.mtdHidden();
    },
    mtdHidden: function () {
      let myOffcanvas = document.getElementById("offcanvasRight");
      let offcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
      offcanvas.hide();
    },
    mtdShowOffCanva: function () {
      this.status = false;
      this.patient = {};
      this.page = "home";
      this.stateCola = "cola";

      // Espera al siguiente ciclo de eventos antes de mostrar el offcanvas
      this.$nextTick(() => {
        let myOffcanvas = document.getElementById("offcanvasRight");
        let offcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas);
        if (offcanvas) {
          offcanvas.show();
        } else {
          offcanvas = new bootstrap.Offcanvas(myOffcanvas);
          offcanvas.show();
        }
        myOffcanvas.addEventListener("show.bs.offcanvas", handleOffcanvasShow);
      });

      const handleOffcanvasShow = () => {
        if (this.$refs.colaPaciente) {
          this.$refs.colaPaciente.refreshPatients();
        }
      };
    },
    refreshPatientsFO: function () {
      this.patient = {};
      this.page = "home";
      this.$nextTick(() => {
        this.$refs.colaPaciente.refreshPatients();
        this.$refs.home.refreshDataHome();
      });
    },
    mtdShowCola: function () {
      this.stateCola = "cola";
      this.page = "home";
      this.$nextTick(() => {
        this.$refs.colaPaciente.showCola();
      });
      this.patient = {};
      this.history = [];
    },
    mtd_volver: function () {
      this.execute_commit({
        mutation: "CALL__END_APPOINTMENT",
        payload: {
          state_menu: 1,
          state_end: 0,
        },
      });
    },

    /* mtd_stateViewPatient: function () {
          this.execute_commit({
            mutation: "CALL__STATE_VIEW_PATIENT",
            payload: {
              state_view_patient: 0,
            },
          });
        }, */
    selectCola(value) {
      this.stateCola = value;
    },

    handleConfirmed() {
      if (this.$refs.home) {
        this.$refs.home.mtdGetData();
      }
    },
    mtdCitas: function (calendario) {
      this.page = "citas";
      this.calendario = calendario;
    },
    mtdenviarstatus: function (status) {
      this.status = status;
    },
    mtdregisterFO: function (state) {
      this.$nextTick(() => {
        this.$refs.historiaRef.Confirmed(state);
      });
    },
  },
};
</script>

<style>
.load-patient {
  position: absolute;
  right: 0;
  top: 42%;
  background: #900052;
  padding: 0.5rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  cursor: pointer;
}

.load-patient i {
  font-size: 2.3rem;
  color: #fff;
}

.home__wait {
  height: 94%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 45%;
  flex: 1 0 45%;
  color: #777;
  background: #fff;
  border: 2px solid #900052;
}
</style>

<template>
  <div>
    <div class="container" v-if="view=='table'">
      <div id="div-modal-pasaje" class="w-100">
        <div id="div-pasaje" class="card-body w-100">
            <div class="row">
              <div class="col-md-12 h2" id="btn-registro-pasaje">
                <a href="javascript:void(0)" @click="mtdStore">
                  <i class="fas fa-plus-circle"></i>
                </a>
              </div>
              <div class="col-md-12 table-container" id="content-timelinetable">
                <table class="table table-bordered ">
                  <caption class="py-0 my-0"></caption>
                  <thead>
                    <tr class="text-white table-th text-center text-xs">
                    <th class="align-middle border border-5 bg-main border-white">N°</th>
                    <th class="align-middle border border-5 bg-main border-white">DNI / RUC</th>
                    <th class="align-middle border border-5 bg-main border-white">NOMBRES / RAZÓN SOCIAL</th>
                    <th class="align-middle border border-5 bg-main border-white">SERIE - NÚMERO</th>
                    <th class="align-middle border border-5 bg-main border-white">FECHA</th>
                    <th class="align-middle border border-5 bg-main border-white">MÉTODO PAGO</th>
                    <th class="align-middle border border-5 bg-main border-white">TOTAL</th>
                    <th class="align-middle border border-5 bg-main border-white">PAGADO</th>
                    <th class="align-middle border border-5 bg-main border-white">PENDIENTE</th>
                    <th class="align-middle border border-5 bg-main border-white">MOTIVO</th>
                    <th class="align-middle border border-5 bg-main border-white">ESTADO</th>
                  </tr>
                </thead>
                <tbody class="text-xs">
                  <tr v-for="(item, index) in dataEgreso" :key="index" class="table-body">
                    <td class="border border-5 border-white align-middle">{{ index + 1 }}</td>
                    <td class="border border-5 border-white align-middle">{{ item.document }}</td>
                    <td class="border border-5 border-white align-middle">{{ item.company }}</td>
                    <td class="border border-5 border-white align-middle">{{ item.serie }} - {{ item.number }}</td>
                    <td class="border border-5 border-white align-middle">{{ item.date}}</td>
                    <td class="border border-5 border-white align-middle">{{ item.method }}</td>
                    <td class="border border-5 border-white align-middle">S/ {{ item.total }}</td>
                    <td class="border border-5 border-white align-middle">S/ {{ item.amount }}</td>
                    <td class="border border-5 border-white align-middle">S/ {{ item.pending }}</td>
                    <td class="border border-5 border-white align-middle">{{ item.description }}</td>
                    <td class="border border-5 border-white align-middle">
                      <a
                        href="javascript:void(0)"
                        v-if="item.state == 2"
                        @click="mtdViewPdfEgreso(item.id)"
                        id="btn-autorizar-pasaje"
                      >
                        <i class="fas fa-check-circle"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        v-if="item.state == 3"
                        id="btn-error-pasaje"
                      >
                        <i class="fas fa-times-circle"></i>
                      </a>
                      <a
                        href="javascript:void(0)"
                        v-if="item.state == 1"
                        id="btn-pediente-pasaje"
                      >
                        <i class="far fa-circle"></i>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="dataEgreso.length == 0" class="text-center">
                    <td colspan="11">No hay registros</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="view == 'store'">

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="document_type">Tipo Documento</span>
          <v-select 
            :options="documents_type" 
            class="form-control" 
            :reduce="(name) => name.id" 
            label="name"  
            placeholder="-- Seleccione uno --"
            required
            v-model="egreso.document_type_id" 
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm">
          <span class="input-group-text col-md-3">Fecha Egreso</span>
          <input
            type="date"
            aria-label="date"
            class="form-control form-control-sm"
            required
            v-model="egreso.date"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm">
          <span class="input-group-text col-md-3">Fecha Comprobante</span>
          <input
            type="date"
            aria-label="date_doc"
            class="form-control form-control-sm"
            required
            v-model="egreso.date_doc"
          />
        </div>
      </div>

      <div class="col-md-2 col-lg-2 col-sm-6 col-xs-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="serie">Serie</span>
          <input 
            style="text-align: right"
            type="text" 
            class="form-control" 
            placeholder="N° Serie" 
            aria-describedby="serie" 
            v-model="egreso.serie" 
          />
        </div>
      </div>

      <div class="col-md-4 col-lg-4 col-sm-6 col-xs-6">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="number">Número</span>
          <input 
            style="text-align: right"
            type="text" 
            class="form-control" 
            placeholder="Número" 
            aria-describedby="number" 
            v-on:keypress="isNumber($event)"
            v-model="egreso.number" 
          />
        </div>
      </div>
      
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
      <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input 
              type="radio" 
              aria-label="Checkbox for selecting RUC" 
              v-model="egreso.type_document"
              value="RUC"
            />
            <label for="rucCheckbox">RUC</label>
          </div>
        </div>
        <div class="input-group-prepend">
          <div class="input-group-text">
            <input 
              type="radio" 
              aria-label="Checkbox for selecting DNI" 
              v-model="egreso.type_document"
              value="DNI"
            />
            <label for="dniCheckbox">DNI</label>
          </div>
        </div>
        <input type="text" 
          class="form-control" 
          placeholder="RUC/DNI" 
          aria-describedby="document" 
          required
          v-model="egreso.document" 
          @keyup="mtdSearchDocument"
        />
      </div>
   </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="company">Razón Social / Nombre</span>
          <input 
            disabled
            type="text" 
            class="form-control" 
            placeholder="Razón Social / Nombre" 
            aria-describedby="company"  
            required
            v-model="egreso.company" 
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3">Monto</span>
          <input
            style="text-align: right"
            type="text"
            aria-label="total"
            placeholder="0.00"
            class="form-control form-control-sm"
            required
            v-on:keypress="isNumber($event)"
            v-model="egreso.total"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3">Monto Pagado</span>
          <input
            style="text-align: right"
            type="text"
            aria-label="amount"
            placeholder="0.00"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            required
            @blur="mtdCalculate"
            v-model="egreso.amount"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3">Monto Pendiente</span>
          <input
            readonly
            style="text-align: right"
            type="text"
            aria-label="amount_pay"
            placeholder="0.00"
            class="form-control form-control-sm"
            v-on:keypress="isNumber($event)"
            v-model="egreso.pending"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="method">Método Pago</span>
          <v-select 
            :options="methods" 
            class="form-control" 
            :reduce="(name) => name.id" 
            label="name"  
            placeholder="-- Seleccione uno --"
            required
            v-model="egreso.method" 
          />
        </div>
      </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <input
          @change="getFile"
            type="file"
            class="form-control"
            id="file"
            lang="es"
            name="file"
            ref="fileInput"
          />
        </div>
      </div>

      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div class="input-group input-group-sm mb-3">
          <span class="input-group-text col-md-3" id="description">Motivo</span>
          <textarea
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            placeholder="Motivo, descripción o detalle"
            aria-describedby="description"
            v-model="egreso.description"
          ></textarea>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
        <div
          class="btn-group"
          role="group"
          aria-label="Basic mixed styles example"
        >
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="mtdCancelar"
          >
            Cancelar
          </button>
          &nbsp;
          <button
            type="button"
            class="btn btn-sm btn-success ml-1"
            @click="mtdVerifEgreso"
          >
            Guardar
          </button>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/>
  </div>
</template>

<script>
import { mapActions } from "vuex";
//import { bus } from "../../main";
import moment from 'moment';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
export default {
  name: "c-admsion-storerecibo",
  components: {
        SweetAlert
    },
  props:{
    bead:{}
  },
  data() {
    return {
      view: "table",
      dataEgreso:[],
      swal:null,
      egreso: {
        swal:null,
        campu_id:null,
        bead_id:null,
        type_document:"",
        document_type_id:null,
        date: "",
        date_doc: "",
        serie: "",
        number: "",
        document: "",
        company: "",
        total: 0.00,
        amount: 0.00,
        pending: 0.00,
        method: "",
        description: "",
        state: "",
        file: "",
      },
      documents_type: [],
      methods: [
        { id: 'efectivo', name: 'EFECTIVO'},
        { id: 'tarjeta', name: 'TARJETA'},
        { id: 'deposito', name: 'DEPOSITO'},
        { id: 'mixto', name: 'MIXTO'}
      ]
    };
  },
  created() {
    moment.locale('es');
   this.egreso.date = moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.egreso.date_doc =  moment().format("L").substr(6, 4) +
      "-" +
      moment().format("L").substr(3, 2) +
      "-" +
      moment().format("L").substr(0, 2);
    this.egreso.bead_id = this.bead.id;
    this.egreso.campu_id = this.bead.campu_id;
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdBackTable: function () {
      this.$emit("mtdBackTable");
    },
    getFile(e) {
      let fileInput = this.$refs.fileInput;
		  let file = e.target.files[0];
		  if (!/\.(pdf)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) &&  !/\.(jpeg)$/i.test(file.name)) {
		    Swal.fire({
          text:'Seleccione un archivo formato PDF, PNG O JPG',
          icon:'info', 
          confirmButtonColor: '#900052',
        });
        //$("#file").val('');
        fileInput.value = '';
		  } else {
    		this.egreso.file = file;
		  }
	  },
    mtdGetData: function(){
      this.post({
        url: this.$store.getters.get__url + "/Admision/expenses",
        token: this.$store.getters.get__token,
        params:{
          Campus:this.$store.getters.get__campus,
          bead:this.egreso.bead_id}
      })
        .then((response) => {
          this.dataEgreso=response.data.data;
          this.documents_type=response.data.documents_type;
        }) 
        .catch((errors) => { console.log(errors); });
    },
    mtdStore: function () {
      this.view = "store";
    },
    mtdCancelar: function () {
      this.view = "table";
      this.egreso = {
              campu_id:null,
              bead_id:null,
              document_type_id:null,
              date: "",
              date_doc: "",
              serie: "",
              number: "",
              document: "",
              company: "",
              total: 0.00,
              amount: 0.00,
              pending: 0.00,
              method: "",
              description: "",
              state: "",
              file: "",
      };
    },
    mtdVerifEgreso: function (){
      if(this.egreso.document_type_id==null || this.egreso.document_type_id==''){
        this.$refs.SweetAlert.showWarning("Seleccione tipo documento.");
      }else{
        if(parseFloat(this.egreso.total).toFixed(2)<=0){
          this.$refs.SweetAlert.showWarning("Ingrese un monto total.");
        }else{
          if(this.egreso.document==null || this.egreso.document==''){
            this.$refs.SweetAlert.showWarning("Ingrese un RUC o DNI.");
          }else{
            if(this.egreso.company==null || this.egreso.company==''){
              this.$refs.SweetAlert.showWarning("Ingrese una Persona o Compañia.");
            }else{
              if(this.egreso.method==null || this.egreso.method==''){
                this.$refs.SweetAlert.showWarning("Seleccione un método de pago.");
              }else{
                // Aquí se agrega la nueva validación
                if((this.egreso.serie !== '' || this.egreso.number !== '') && !this.egreso.file){
                  this.$refs.SweetAlert.showWarning("Debe adjuntar un archivo si ingresa una serie y número.");
                }else{
                  this.mtdCommitEgreso();
                }
              }
            }
          }
        }
      }
    },
    mtdCommitEgreso: function () {
      let formData = new FormData();
      formData.append("campu_id",this.$store.getters.get__campus);
      //formData.append("campu_id", this.egreso.campu_id);
      formData.append("bead_id", this.egreso.bead_id);
      formData.append("document_type_id", this.egreso.document_type_id);
      formData.append("date", this.egreso.date);
      formData.append("date_doc", this.egreso.date_doc);
      formData.append("serie", this.egreso.serie);
      formData.append("number", this.egreso.number);
      formData.append("document",this.egreso.document);
      formData.append("company",this.egreso.company);
      formData.append("total",this.egreso.total);
      formData.append("amount",this.egreso.amount);
      formData.append("pending",this.egreso.pending);
      formData.append("method",this.egreso.method);
      formData.append("description",this.egreso.description);
      formData.append("state",1);
      formData.append("file", this.egreso.file);
      this.post({
        url: this.$store.getters.get__url + "/Admision/egreso/store",
        token: this.$store.getters.get__token,
        params: formData,
      })
        .then((response) => {
          if (response.error == false) {
            this.egreso = {
              campu_id:null,
              bead_id:null,
              document_type_id:null,
              date: "",
              date_doc: "",
              serie: "",
              number: "",
              document: "",
              company: "",
              total: 0.00,
              amount: 0.00,
              pending: 0.00,
              method: "",
              description: "",
              state: "",
            };
            this.egreso.bead_id=response.data.of_bead_id;
            this.view="table";
            this.mtdGetData();
          } else {
            alert(response.message);
          }
        })
        .catch((errors) => { console.log(errors); });
    },
    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdSearchDocument: function(){
      if(!this.egreso.type_document){
        this.$refs.SweetAlert.showWarning("Seleccione RUC  o DNI");
      }
      else
      {
      if (this.egreso.document.length === 8  && this.egreso.type_document=='DNI') {
        this.get({
          url: this.$store.getters.get__url + "/Consultas/" +this.egreso.document,
          token: this.$store.getters.get__token,
        })
          .then((response) => {
            if (response.data.boo== 3) {
              this.egreso.document="";
              this.$refs.SweetAlert.showWarning(response.data.error);  
              this.egreso.company ="";
            } else {
              this.egreso.company = response.data.name + " " + response.data.last_name;
            }
          })
          .catch(() => {
          });
      }else{
        if (this.egreso.document.length === 11 && this.egreso.type_document=='RUC') {
          this.get({
            url: this.$store.getters.get__url + "/Consultas/" +this.egreso.document,
            token: this.$store.getters.get__token,
            
          })
            .then((response) => {
              if (response.data.boo== 3) {
                this.egreso.document="";
              this.$refs.SweetAlert.showWarning(response.data.error);  
              this.egreso.company ="";
              } else {
                this.egreso.company = response.data.razon_social;
              }
            })
            .catch((errors) => { console.log(errors); });
          }
      }
    }
    },
    mtdCalculate: function () {
      this.egreso.pending = parseFloat(this.egreso.total).toFixed(2) - parseFloat(this.egreso.amount).toFixed(2);
    }
  },
};
</script>
<style scoped>
.btn-home {
  z-index: 1;
}
#btn-autorizar-pasaje {
  color: #39b54a;
  font-size: 25px;
}
#btn-autorizar-pasaje:hover {
  color: #217b2d;
}
#btn-error-pasaje {
  color: #ff0000;
  font-size: 25px;
}
#btn-error-pasaje:hover {
  color: #a30404;
}
#btn-pediente-pasaje {
  color: #ffe600;
  font-size: 25px;
}
#btn-pediente-pasaje:hover {
  color: #c0ac01;
}
#btn-registro-pasaje a {
  color: #900052;
}
#btn-registro-pasaje a:hover {
  color: #65013a;
  align-items: center;
  transform: scale(1.5);
}
.circulo {
  vertical-align: middle;
  border-style: none;
}
#div-modal-pasaje {
  margin-top: 10px;
}
@media (max-width: 800px) {
  /* #div-modal-pasaje{
      margin-top: 10px;
    } */
  #btn-open-caja {
    justify-content: center !important;
  }
  #btn-registro-pasaje {
    text-align: center;
  }
  #div-tabla-pasaje {
    overflow-x: auto;
  }
}
@media (min-width: 800px) {
  /* #div-modal-pasaje{
      margin-top: 10px;
    } */
  #btn-registro-pasaje {
    text-align: left;
  }
} 
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}
</style>
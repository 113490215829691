<template>
<div class="row pt-2 d-flex justify-content-start">
    <div class="col-lg-12 col-md-12 col-sm-12 fw-bold color-main mb-2">
        <div class="mt-1 mb-2 ms-auto btn-success text-start">
            <button type="button" class="btn btn-sm btn-outline-main" @click="mtdBack()">
                <i class="fas fa-arrow-left"></i> Atrás
            </button>
        </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 fw-bold color-main mb-2">
        <section class="card">
            <header class="card-header">
                <h5 class="card-title">Autorefractometro</h5>
            </header>
            <div class="card-body">
                <table class="table table-responsive-md table-bordered my-0 pb-1">
                    <caption class="py-0"></caption>
                    <thead>
                        <tr class="text-center text-xs">
                            <th></th>
                            <th class="col-sm-1 color-main fw-bold">ESF</th>
                            <th class="col-sm-1 color-main fw-bold">CIL</th>
                            <th class="col-sm-1 color-main fw-bold">EJE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center">OD</td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.autorefractometro.od.esf" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.autorefractometro.od.cil" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.autorefractometro.od.eje" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center">OI</td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.autorefractometro.oi.esf" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.autorefractometro.oi.cil" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.autorefractometro.oi.eje" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center">Comentario</td>
                            <td colspan="3">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="lente premiun" v-model="optometria.autorefractometro.comentario.lentepremium" :disabled="stateBotton" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 fw-bold color-main">
        <section class="card">
            <header class="card-header">
                <h5 class="card-title">Agudeza visual</h5>
            </header>
            <div class="card-body">
                <table class="table table-responsive-md table-bordered mb-0">
                    <caption></caption>
                    <thead>
                        <tr class="text-center text-xs">
                            <th class="col-sm-1 color-main fw-bold"></th>
                            <th class="col-sm-1 color-main fw-bold text-center" colspan="4">AVSC</th>
                            <th class="col-sm-1 color-main fw-bold">AVCC</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OD</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="4">
                                <input class="form-control form-control-input form-control-sm" type="text" v-model="optometria.agudeza.od.avsc" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input class="form-control form-control-input form-control-sm" type="text" v-model="optometria.agudeza.od.avcc" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OI</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="4">
                                <input class="form-control form-control-input form-control-sm" type="text" v-model="optometria.agudeza.oi.avsc" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input class="form-control form-control-input form-control-sm" type="text" v-model="optometria.agudeza.oi.avcc " :disabled="stateBotton" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12 fw-bold color-main mb-2">
        <section class="card">
            <header class="card-header">
                <h5 class="card-title">Medida que usa</h5>
            </header>
            <div class="card-body">
                <table class="table table-responsive-md table-bordered mb-0">
                    <caption></caption>
                    <thead>
                        <tr class="text-center text-xs">
                            <th class="col-sm-1 color-main fw-bold"></th>
                            <th class="col-sm-1 color-main fw-bold">ESF</th>
                            <th class="col-sm-1 color-main fw-bold">CIL</th>
                            <th class="col-sm-1 color-main fw-bold">EJE</th>
                            <th class="col-sm-1 color-main fw-bold">AV</th>
                            <th class="col-sm-1 color-main fw-bold">PRISMA</th>
                            <th class="col-sm-1 color-main fw-bold">CAE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center">OD</td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.medida_uso.od.esf" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.medida_uso.od.cil" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.medida_uso.od.eje" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medida_uso.od.av" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medida_uso.od.prisma" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medida_uso.od.cae" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center">OI</td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.medida_uso.oi.esf" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.medida_uso.oi.cil" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.medida_uso.oi.eje" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medida_uso.oi.av" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medida_uso.oi.prisma" :disabled="stateBotton" />
                            </td>
                            <td>
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medida_uso.oi.cae" :disabled="stateBotton" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 fw-bold color-main mb-1">
        <section class="card">
            <header class="card-header d-flex justify-content-between">
                <h5 class="card-title">Medida de lejos</h5>

            </header>
            <div class="card-body pb-0">
                <table class="table table-responsive-md table-bordered mb-0">
                    <caption></caption>
                    <thead>
                        <tr class="text-center text-xs">
                            <th class="col-sm-1 color-main fw-bold"></th>
                            <th class="col-sm-1 color-main fw-bold">ESF</th>
                            <th class="col-sm-1 color-main fw-bold">CIL</th>
                            <th class="col-sm-1 color-main fw-bold">EJE</th>
                            <th class="col-sm-1 color-main fw-bold">AV</th>
                            <th class="col-sm-1 color-main fw-bold">PRISMA</th>
                            <th class="col-sm-1 color-main fw-bold">CAE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OD</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.medidad_lejos.od.esf" @input="changeValue" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.medidad_lejos.od.cil" @input="changeValue" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.medidad_lejos.od.eje" :disabled="stateBotton" @input="changeValue" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.od.av" @input="changeValue" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.od.prisma" @input="changeValue" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.od.cae" @input="changeValue" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OI</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.medidad_lejos.oi.esf" @input="changeValue" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.medidad_lejos.oi.cil" @input="changeValue" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.medidad_lejos.oi.eje" :disabled="stateBotton" @input="changeValue" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.oi.av" @input="changeValue" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.oi.prisma" @input="changeValue" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.oi.cae" @input="changeValue" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Adición OD</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <div class="d-flex align-items-center">

                                    <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+0.5" @input="mtdCalcularMedidaCercaOD" v-model="optometria.medidad_lejos.adicion.od" :disabled="stateBotton" />
                                </div>
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Adición OI</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <div class="d-flex align-items-center">

                                    <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+0.5" @input="mtdCalcularMedidaCercaOI" v-model="optometria.medidad_lejos.adicion.oi" :disabled="stateBotton" />
                                </div>
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Tipo de luna</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.tipo_de_luna.value" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">DIP</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_lejos.dip.value" :disabled="stateBotton" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
        <div class="d-flex justify-content-start mt-5">
            <span class=" btn-home">
                <button v-if="!stateBotton" type="button" class="btn btn-outline-light btn-sm" @click="mtdBack()" :disabled="stateBotton">
                    <strong><i class="fas fa-save"></i> Guardar</strong>
                </button>
            </span>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 fw-bold color-main mb-1">
        <section class="card">
            <header class="card-header d-flex justify-content-between">
                <h5 class="card-title">Medida de cerca</h5>

            </header>
            <div class="card-body pb-0">
                <table class="table table-responsive-md table-bordered mb-0">
                    <caption></caption>
                    <thead>
                        <tr class="text-center text-xs">
                            <th class="col-sm-1 color-main fw-bold"></th>
                            <th class="col-sm-1 color-main fw-bold">ESF</th>
                            <th class="col-sm-1 color-main fw-bold">CIL</th>
                            <th class="col-sm-1 color-main fw-bold">EJE</th>
                            <th class="col-sm-1 color-main fw-bold">AV</th>
                            <th class="col-sm-1 color-main fw-bold">PRISMA</th>
                            <th class="col-sm-1 color-main fw-bold">CAE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OD</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.medidad_cerca.od.esf" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.medidad_cerca.od.cil" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.medidad_cerca.od.eje" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.od.av" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.od.prisma" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.od.cae" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OI</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.medidad_cerca.oi.esf" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.medidad_cerca.oi.cil" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.medidad_cerca.oi.eje" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.oi.av" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.oi.prisma" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.oi.cae" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <!-- <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Adición</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.adicion.value" />
                            </td>
                        </tr> -->
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Tipo de luna</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.tipo_de_luna.value" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">DIP</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.medidad_cerca.dip.value" :disabled="stateBotton" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
        <!--
        <section class="card mt-2">
            <header class="card-header d-flex justify-content-between">
                <h5 class="card-title">Cicloplegia</h5>

            </header>
            <div class="card-body pb-0">
                <table class="table table-responsive-md table-bordered mb-0">
                    <caption></caption>
                    <thead>
                        <tr class="text-center text-xs">
                            <th class="col-sm-1 color-main fw-bold"></th>
                            <th class="col-sm-1 color-main fw-bold">Medidad de lejos </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OD</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.cicloplegia.od" :disabled="stateBotton" />
                            </td>

                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OI</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.cicloplegia.oi" :disabled="stateBotton" />
                            </td>

                        </tr>

                    </tbody>
                </table>
            </div>
        </section>
        -->
        <section class="card">
            <header class="card-header d-flex justify-content-between">
                <h5 class="card-title">Cicloplegia</h5>

            </header>
            <div class="card-body pb-0">
                <table class="table table-responsive-md table-bordered mb-0">
                    <caption></caption>
                    <thead>
                        <tr class="text-center text-xs">
                            <th class="col-sm-1 color-main fw-bold"></th>
                            <th class="col-sm-1 color-main fw-bold">ESF</th>
                            <th class="col-sm-1 color-main fw-bold">CIL</th>
                            <th class="col-sm-1 color-main fw-bold">EJE</th>
                            <th class="col-sm-1 color-main fw-bold">AV</th>
                            <th class="col-sm-1 color-main fw-bold">PRISMA</th>
                            <th class="col-sm-1 color-main fw-bold">CAE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OD</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.cicloplegia.od.esf" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.cicloplegia.od.cil" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.cicloplegia.od.eje" :disabled="stateBotton"/>
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.od.av"  @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.od.prisma"  @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.od.cae" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-center text-xs">
                            <td class="col-sm-1 color-main fw-bold">OI</td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+" v-model="optometria.cicloplegia.oi.esf" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="-" v-model="optometria.cicloplegia.oi.cil" @keypress="filterDecimal" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-degree class="form-control form-control-input form-control-sm" type="text" placeholder="°" v-model="optometria.cicloplegia.oi.eje" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.oi.av" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.oi.prisma" :disabled="stateBotton" />
                            </td>
                            <td class="col-sm-1 color-main fw-bold">
                                <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.oi.cae" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Adición OD</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <div class="d-flex align-items-center">

                                    <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+0.5" v-model="optometria.cicloplegia.adicion.od" :disabled="stateBotton" />
                                </div>
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Adición OI</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <div class="d-flex align-items-center">

                                    <input v-validate-number class="form-control form-control-input form-control-sm" type="text" placeholder="+0.5" v-model="optometria.cicloplegia.adicion.oi" :disabled="stateBotton" />
                                </div>
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">Tipo de luna</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.tipo_de_luna.value" :disabled="stateBotton" />
                            </td>
                        </tr>
                        <tr class="text-xs">
                            <td class="col-sm-1 color-main fw-bold text-center" colspan="2">DIP</td>
                            <td class="col-sm-1 color-main fw-bold" colspan="5">
                                <input class="form-control form-control-input form-control-sm" type="text" placeholder="" v-model="optometria.cicloplegia.dip.value" :disabled="stateBotton" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    </div>

</div>
</template>

<script>
import OftalmologiaView from '@/views/Oftalmologia/OftalmologiaView.vue';
import {
    mapActions
} from 'vuex'
export default {
    name: 'c-medico-historia-optometria',
    data() {
        return {
            stateRefraccionCerca: true,
            stateRefraccionLejos: false,
            optometria: {
                autorefractometro: {
                    od: {
                        esf: '',
                        cil: '',
                        eje: ''
                    },
                    oi: {
                        esf: '',
                        cil: '',
                        eje: ''
                    },
                    comentario: {
                        lentepremium: ''
                    }
                },

                medida_uso: {
                    od: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    },
                    oi: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    }
                },

                agudeza: {
                    od: {
                        avsc: '',
                        avcc: ''
                    },
                    oi: {
                        avsc: '',
                        avcc: ''
                    }
                },
                medidad_cerca: {
                    od: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    },
                    oi: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    },
                    // adicion: {
                    //     value: '',

                    // },
                    tipo_de_luna: {
                        value: ''
                    },
                    dip: {
                        value: ''
                    }
                },
                medidad_lejos: {
                    od: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    },
                    oi: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    },
                    adicion: {
                        od: '',
                        oi: '',

                    },
                    tipo_de_luna: {
                        value: ''
                    },
                    dip: {
                        value: ''
                    }
                },
                cicloplegia: {
                    od: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    },
                    oi: {
                        esf: '',
                        cil: '',
                        eje: '',
                        av: '',
                        prisma: '',
                        cae: ''
                    },
                    adicion: {
                        od: '',
                        oi: '',

                    },
                    tipo_de_luna: {
                        value: ''
                    },
                    dip: {
                        value: ''
                    }
                },
                /*
                cicloplegia: {
                    od: '',
                    oi: '',
                }
                */
            },
            stateBotton: true,
        }
    },
    directives: {

        validateNumber: {
            bind(el) {
                el.addEventListener('input', function (event) {
                    let value = event.target.value;
                    const regex = /^[+-]?\d*\.?\d*$/;
                    if (!regex.test(value)) {
                        value = value.slice(0, -1);
                    }
                    const plusMinusCount = (value.match(/[+-]/g) || []).length;
                    const hasMultipleSigns = plusMinusCount > 1 && (value.indexOf('+') > 0 || value.indexOf('-') > 0);
                    if (hasMultipleSigns) {
                        value = value.slice(0, -1);
                    }
                    if (value.length > 1 && (value[0] === '+' || value[0] === '-') && value[1] === '.') {
                        value = value.slice(0, 1);
                    }
                    event.target.value = value;
                    const eventInput = new Event('input', {
                        bubbles: true
                    });
                    // el.dispatchEvent(eventInput);
                });
            }
        },
        validateDegree: {
            bind(el) {
                el.addEventListener('input', function (event) {
                    let value = event.target.value;
                    const regex = /^\d*\.?\d*$/;
                    if (!regex.test(value)) {
                        value = value.replace(/[^\d.]/g, '');
                    }
                    const parts = value.split('.');
                    if (parts.length > 2) {
                        value = parts[0] + '.' + parts.slice(1).join('');
                    }
                    event.target.value = value;
                    const eventInput = new Event('input', {
                        bubbles: true
                    });
                    // el.dispatchEvent(eventInput);
                });
            }
        }
    },
    props: {
        dataOptometria: {
            type: String,
            default: null
        },
        hc: {
            type: Number,
            default: null
        }
    },
    watch: {
        /*
        dataOptometriaa: {
            handler(newValue) {
                this.optometria = (this.hc !== null) ? JSON.parse(newValue) : this.optometria;
                this.stateBotton = this.hc == null ? false : true;
            },
            immediate: true // Para que se ejecute cuando el componente se monta por primera vez
        },
        */
        dataOptometria: {
            handler(newValue) {
                if (this.dataOptometria !== null) {
                    this.optometria = (this.hc !== null) ? JSON.parse(newValue) :  JSON.parse(this.dataOptometria);
                    //console.log("uno");
                } else {
                    this.optometria = (this.hc !== null) ? JSON.parse(newValue) : this.optometria;
                    //console.log("dos");
                }
                this.stateBotton = this.hc == null ? false : true;
            },
            immediate: true // Para que se ejecute cuando el componente se monta por primera vez
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdBack() {
            this.$emit('optaccion', 1, this.optometria);
        },
        changeValue: function () {
            const adicionOd = this.optometria.medidad_lejos.adicion.od;
            const adicionOi = this.optometria.medidad_lejos.adicion.oi;
            if (adicionOd > 0) {
                this.mtdCalcularMedidaCercaOD();
            }
            if (adicionOi > 0) {
                this.mtdCalcularMedidaCercaOI();
            }
        },
        selectRefraccion() {
            this.stateRefraccionLejos = !this.stateRefraccionLejos;
            this.stateRefraccionCerca = !this.stateRefraccionCerca;
        },
        filterNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        filterDecimal: function (e) {
            // Expresión regular para validar números decimales con signo opcional
            const regex = /^[-+]?[0-9]*\.?[0-9]+$/;

            // Verificar si el valor coincide con la expresión regular
            if (!regex.test(e)) {
                return false;
            }

            // Verificar si hay más de un signo
            const signoCount = (e.match(/[+-]/g) || []).length;
            if (signoCount > 1) {
                return false;
            }

            // Verificar si hay más de un punto decimal
            const puntoCount = (e.match(/\./g) || []).length;
            if (puntoCount > 1) {
                return false;
            }

            // Si pasa todas las validaciones, devuelve true
            return true;
        },
        mtdCalcularMedidaCercaOD() {
           
            // const adicionOD = this.optometria.medidad_lejos.adicion.od;
            // const regex = /^\+(\d.*)$/; 
            // if (regex.test(adicionOD)) {
            //     const match = regex.exec(adicionOD);
            //     const numeroDespuesDelMas = match[1]; 
            //     if (numeroDespuesDelMas.trim() !== '') {
                    
            //         this.optometria.medidad_cerca.od = {
            //             esf: parseFloat(parseFloat(this.optometria.medidad_lejos.od.esf) + parseFloat(numeroDespuesDelMas)).toFixed(2),
            //             cil: parseFloat(parseFloat(this.optometria.medidad_lejos.od.cil) + parseFloat(numeroDespuesDelMas)).toFixed(2),
            //             eje: parseInt(this.optometria.medidad_lejos.od.eje),
            //             av: parseFloat(parseFloat(this.optometria.medidad_lejos.od.av) + parseFloat(numeroDespuesDelMas)).toFixed(2),
            //             prisma: parseFloat(parseFloat(this.optometria.medidad_lejos.od.prisma) + parseFloat(numeroDespuesDelMas)).toFixed(2),
            //             cae: parseFloat(parseFloat(this.optometria.medidad_lejos.od.cae) + parseFloat(numeroDespuesDelMas)).toFixed(2)
            //         };
            //     }
            // }

                if (this.optometria.medidad_lejos.od.esf != '' && this.optometria.medidad_lejos.od.cil != '' &&
                    this.optometria.medidad_lejos.od.eje != '' && this.optometria.medidad_lejos.od.av != '' &&
                    this.optometria.medidad_lejos.od.prisma != '' && this.optometria.medidad_lejos.od.cae != ''
                ) {

                    this.optometria.medidad_cerca.od = {
                        esf: parseFloat(parseFloat(this.optometria.medidad_lejos.od.esf) + parseFloat(this.optometria.medidad_lejos.adicion.od)).toFixed(2),
                        cil: parseFloat(parseFloat(this.optometria.medidad_lejos.od.cil) + parseFloat(this.optometria.medidad_lejos.adicion.od)).toFixed(2),
                        eje: parseInt(this.optometria.medidad_lejos.od.eje),
                        av: parseFloat(parseFloat(this.optometria.medidad_lejos.od.av) + parseFloat(this.optometria.medidad_lejos.adicion.od)).toFixed(2),
                        prisma: parseFloat(parseFloat(this.optometria.medidad_lejos.od.prisma) + parseFloat(this.optometria.medidad_lejos.adicion.od)).toFixed(2),
                        cae: parseFloat(parseFloat(this.optometria.medidad_lejos.od.cae) + parseFloat(this.optometria.medidad_lejos.adicion.od)).toFixed(2)
                    };

                }

            },
            mtdCalcularMedidaCercaOI() {
                //realizar suma automatica
                if (this.optometria.medidad_lejos.oi.esf != '' && this.optometria.medidad_lejos.oi.cil != '' &&
                    this.optometria.medidad_lejos.oi.eje != '' && this.optometria.medidad_lejos.oi.av != '' &&
                    this.optometria.medidad_lejos.oi.prisma != '' && this.optometria.medidad_lejos.oi.cae != ''
                ) {

                    this.optometria.medidad_cerca.oi = {
                        esf: parseFloat(parseFloat(this.optometria.medidad_lejos.oi.esf) + parseFloat(this.optometria.medidad_lejos.adicion.oi)).toFixed(2),
                        cil: parseFloat(parseFloat(this.optometria.medidad_lejos.oi.cil) + parseFloat(this.optometria.medidad_lejos.adicion.oi)).toFixed(2),
                        eje: parseInt(this.optometria.medidad_lejos.oi.eje),
                        av: parseFloat(parseFloat(this.optometria.medidad_lejos.oi.av) + parseFloat(this.optometria.medidad_lejos.adicion.oi)).toFixed(2),
                        prisma: parseFloat(parseFloat(this.optometria.medidad_lejos.oi.prisma) + parseFloat(this.optometria.medidad_lejos.adicion.oi)).toFixed(2),
                        cae: parseFloat(parseFloat(this.optometria.medidad_lejos.oi.cae) + parseFloat(this.optometria.medidad_lejos.adicion.oi)).toFixed(2)
                    };
                }

            }
        },

    }
</script>

<template>
  <div>
    <div class="container">
      <div class="row">
        <div
          id="btn-home"
          class="col-md-12 mb-4 mt-1 d-flex justify-content-end btn-home"
        >
          <button
            type="button"
            class="btn btn-outline-main"
            @click="changePage('home')"
          >
            <i class="fas fa-home-lg-alt"></i> Inicio
          </button>
        </div>
      </div>
      <div id="div-modal-anular" class="w-100">
        <ul class="nav pb-0" id="myTab" role="tablist">
          <li
            v-for="(item, index) in dataArea"
            :key="index"
            class="nav-item"
            style="width: auto;"
            :role="index"
          >
            <button
            v-if="item.id == 5"
              @click="mtdSelectTab(item.id)"
              :class="{
                'item-button': true,
                'nav-link': true,
                active: page === item.id,
              }"
              id="profile-tab"
              type="button"
            >
            {{ item.name }}
            </button>
          </li>
        </ul>
        <div v-if="page != null" class="tab-content border border-main" id="myTabContent">
          <ReqFarmaciaVue v-if="page == 5" :area="5"></ReqFarmaciaVue>
        </div>
      </div>
    </div>
  
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ReqFarmaciaVue from './Requeriment/ReqFarmacia.vue';


export default {
  components: {ReqFarmaciaVue},
  data() {
    return {
      page: 5,
      dataArea: [],
    };
  },
  computed: {
    validardate() {
      if (this.filter.begindate && this.filter.enddate) {
        return false;
      }
      return true;
    },
    cpData() {
      let filteredData = this.dataRequerimientosID;

      let totalPages = Math.ceil(filteredData.length / 10);

      this.stepPagination = Array.from(
        {
          length: totalPages,
        },
        (_, index) => index + 1
      );
      let startIndex = (this.selectPage - 1) * 10;
      let endIndex = startIndex + 9;
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
      return this.dataShow;
    },
    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from(
        {
          length: endPage - startPage + 1,
        },
        (_, i) => startPage + i
      );
    },
  },
  created() {
    this.mtdGetarea();
  },

  methods: {
    ...mapActions(["get", "post"]),
    mtdGetarea: function () {
      this.get({
        url: this.$store.getters.get__url + "/area/getall",
        token: this.$store.getters.get__token,
      }).then((response) => {
        this.dataArea = response.data;
      });
    },
    mtdSelectTab: function(id){
      this.page = id;
    },
    changePage(page) {
      this.$emit("returnHome", page);
    },
   
  },
};
</script>


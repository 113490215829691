<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        
        <div class="row d-flex mx-0 px-0 ">
            <div class="mb-2 col-lg-4  px-1">
                <label for="name" class="form-label">Nombre Generico</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.name" placeholder=" Nombre Generico">
            </div>
            
            <div class="mb-2 col-xl-4 col-md-12 px-1">
                <label for="marca" class="form-label">Marcas</label>
                <select class="form-control form-control-sm" aria-label="form-select-sm example" v-model="data_detail.brand_id">
                    <option selected disabled value="">Selecione </option>
                    <option v-for="(brand,index) in dataBrands" :key="index" :value="brand.id">{{ brand.name }}</option>
                </select>
            </div>

            <div class="mb-2 col-xl-4  col-md-12 px-1">
                <label for="name" class="form-label">Lote</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.lote" placeholder="Lote">
            </div>
        </div>

        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-3 col-md-12 px-1">
                <label for="name" class="form-label">Piezas</label>
                <input type="text" class="form-control form-control-sm" @keydown="filterKey" v-model="data_detail.parts" placeholder="Piezas">
            </div>
            <div class=" mb-2 col-xl-3   col-12 px-1">
                <label for="fechav" class="form-label">Fecha de Vencimiento</label>
                <input type="date" class="form-control form-control-sm" v-model="data_detail.fecha_vencimiento">
            </div>
            <div class=" mb-2 col-xl-3  col-12 px-1">
                <label for="price_cost" class="form-label">S/. Costo</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_cost" @input="validatePriceCost" placeholder="0.00">
            </div>
            <div class=" mb-2 col-xl-3   col-12 px-1">
                <label for="price_sale" class="form-label">S/. Venta</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.price_sale" @input="validatePriceSale" placeholder="0.00">
            </div>
        </div>

        <div class="row mx-0 px-0">
            <div class="col-md-12 mb-2 px-1" v-if="num == 1">
                <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" :checked="data_detail.state == 1 ? true : false" @change="mtdChangeState" />
                    <label class="form-check-label" for="flexSwitchCheckChecked">{{ data_detail.state == 1 ? "Activo" : "Inactivo" }}</label>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-insumos",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                lote: '',
                name: '',
                parts: '',
                price_cost: '',
                price_sale: '',
                fecha_vencimiento: '',
                brand_id: '',
                state: 1
            },
           
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataBrands: {
            type: Array,
            default: function () {
                return [];
            }
        },

        input: Number
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    if (this.num == 1) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name !== '' && this.data_detail.parts !== ''  
            && this.data_detail.brand_id !== '' && this.data_detail.price_cost !== ''  && this.data_detail.price_sale !== '' );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdChangeState: function () {
                this.data_detail.state = this.data_detail.state == 1 ? 0 : 1;
        },
        mtdClose() {
            this.data_detail =  {
                lote: '',
                name: '',
                parts: '',
                price_cost: '',
                price_sale: '',
                fecha_vencimiento: '',
                brand_id: '',
            },
            this.$emit("closeModal");
        },
        DataDetail() {
            
            this.$emit('Datadetail', this.data_detail, this.num)
            this.data_detail= {
                lote: '',
                name: '',
                parts: '',
                price_cost: '',
                price_sale: '',
                fecha_vencimiento: '',
                brand_id: '',
            }
        },
        
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        validatePriceCost: function (event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }
            this.data_detail.price_cost = sanitizedValue;
        },
        validatePriceSale(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');

            const decimalCount = sanitizedValue.split('.').length - 1;
            if (decimalCount > 1) {
                sanitizedValue = sanitizedValue.slice(0, -1);
            }
            const decimalIndex = sanitizedValue.indexOf('.'); // Limitar a dos decimales
            if (decimalIndex !== -1) {
                const decimalPart = sanitizedValue.substring(decimalIndex + 1);
                sanitizedValue = `${sanitizedValue.substring(0, decimalIndex)}.${decimalPart.substring(0, 2)}`;
            }

            this.data_detail.price_sale = sanitizedValue;
        },
    },

};
</script>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'md'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
    <div class="row">
        <div class="col-md-12 py-2">
            <div class="row d-flex">
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 mb-2">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-4">Desde</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="init" v-model="filter.init"/>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-4">Hasta</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end"/>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <template #footer>
      <div class="btn-group">
        <button @click="mtdClose" class="btn btn-danger btn-sm custom-btn">
          <i class="fa fa-times"></i>&nbsp;Cerrar
        </button>
        <button @click="mtdExportarPdf" class="btn btn-success btn-sm custom-btn"><i class="fas fa-solid fa-file-pdf"></i>&nbsp;Exportar PDF</button>
      </div>
    </template>

      <SweetAlert :swal="swal" ref="SweetAlert"/>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
import moment from "moment";

export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
    CModal,
    SweetAlert,
    },
    data() {
    return {
        swal: null,
        filter: {
            init: "",
            end: "",
        },
    };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: [],
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
    },
    computed: {
    },
    methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
        this.$emit("closeModalDate");
    },


    mtdExportarPdf() {
        const initDate = this.filter.init;
        const endDate = this.filter.end;
        const campus = this.$store.getters.get__campus; 
        const url = `${this.$store.getters.get__url}/surveillance/viewPdf/${initDate}/${endDate}?campus=${campus}`;
        window.open(url, "_blank");
        this.mtdClose();
    },
    },

};
</script>
<style scoped>
.custom-btn {
  font-size: 11.8px; 
  font-weight: bold;
}
</style>
  
  
<template>
<div class="home__wait-div">
    <div class="home__wait-user">
        <i class="far fa-user-circle"></i>
        <ul class="list-patient">
            <li>
                <b>Paciente</b>
            </li>
            <li v-if="Object.keys(patient).length !== 0">{{patient.name}}</li>
            <li v-else>No ha seleccionado un paciente aun</li>
            <li>
                <b>Provincia</b>
            </li>
            <li>Chiclayo</li>
            <li>
                <b>Ultima cita</b>
            </li>
            <li> - </li>
        </ul>
    </div>
    <div class="home__wait-buttons">
        <!-- <button
        @click="mtd_stateViewPatient"
        class="btn btn-outline-primary btn-block btn-medico"
      >
        Minimizar(Ocultar)
      </button> -->
        <!-- <button
        @click="mtd_mandarEspera"
        class="btn btn-outline-primary btn-block btn-medico"
        v-if="cpd_call_state_end == 0"
      >
        Mandar a espera
      </button> -->
        <!-- <button @click="mtd_mandarEspera" class="btn btn-outline-atendiendo w-100">
            <strong>Mandar a espera</strong>
        </button><br> -->
        <button @click="mtd_volver" v-if="cpd_call_state_end == 1" class="btn btn-outline-atendiendo w-100">
            <strong>Volver</strong>
        </button><br>
        <button @click="mtdFinalizarCita" :disabled="Object.keys(patient).length === 0" class="btn btn-outline-atendiendo w-100" :class="cpd_call_state_end == 1 ? 'btn-active' : ''">
            <strong>Finalizar cita</strong>
        </button>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @confirmed="Confirmed" />
    <cModaldestino :status="status" :title="modal.title" :boo_modal="modal.modal_form" @mtdclosemodal="mtdclosemodal" @confirmed="mtdUpdateHc" />
</div>
</template>

<script>
/** Vuex */
import {
    mapActions
} from "vuex";
import cModaldestino from '@/components/Medico/AyudaDx/modals/cModaldestino.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "AtendiendoView",
    components: {
        cModaldestino,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            modal: {
                title: "",
                modal_form: false,
            },
        };
    },
    props: {
        patient: {
            type: Object,
        },
        status:{
            type:Boolean,
            default:false
        }

    },
    computed: {
        cpd_patient() {
            return this.$store.getters["call__patient"];
        },
        cpd_call_state_end() {
            return this.$store.getters["call__state_end"];
        },
    },
    methods: {
        ...mapActions(["post", "execute_commit"]),
        mtd_endAppointment: function () {
            this.execute_commit({
                mutation: "CALL__END_APPOINTMENT",
                payload: {
                    state_menu: 0,
                    state_end: 1,
                },
            });
        },
        mtdFinalizarCita: function () {
            this.modal = {
                title: "DESTINO",
                modal_form: true,
            }
        },
        Confirmed: function (status) {
            this.post({
                    url: this.$store.getters.get__url + "/Doctor/changeStatus",
                    token: this.$store.getters.get__token,
                    params: {
                        id_reservation: this.patient.id_reservation,
                        status: status,
                    }
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdclosemodal();
                    this.$emit("mtdShowCola");
                    this.$emit("confirmed");
                })
                .catch((errors) => {

                    console.log(errors);
                });

        },
        mtdUpdateHc: function(status){
            //regresar al componente padre de acuerdo al servicio
            if(this.patient.of_product_name !== 'LECTURA DE RESULTADOS'){
                this.$emit("finalizar",status);
            }else{
                this.Confirmed(status);
            }
        },
        mtdclosemodal: function () {
            this.modal = {
                title: "",
                modal_form: false,
            };
            

        },
        mtd_mandarEspera: function () {
            //GUARDAR CITA
            this.post({
                    url: `/api/v1/attention/end_appointment`,
                    params: {
                        quote_id: this.$store.getters["call__patient"].id,
                        patient_id: this.$store.getters["call__patient"].patient_id,
                        antecedentes: this.$store.getters["patient__sistema_actual"],
                        historia_clinica: this.$store.getters["patient__historia_clinica"],
                        autorefractometro: this.$store.getters["patient__autorefractometro"],
                        av_con_sus_lentes: this.$store.getters["patient__av_con_sus_lentes"],
                        av_sin_coreccion: this.$store.getters["patient__av_sin_coreccion"],
                        av_con_coreccion: this.$store.getters["patient__av_con_coreccion"],
                        indicaciones: this.$store.getters["patient__indicaciones"],
                        solicitudes: this.$store.getters["patient__solicitudes"],
                        campu_id: localStorage.getItem("campu_id"),
                        type: "ESPERA",
                    },
                })
                .then((response) => {
                    console.log(response);
                    //CLEAR PACIENTE
                    this.execute_commit({
                        mutation: "PATIENT__CLEAR",
                        payload: {},
                    });
                    this.execute_commit({
                        mutation: "RECORD__CLEAR",
                        payload: {},
                    });
                    //CLEAR ATENCION
                    this.execute_commit({
                        mutation: "CALL__CLEAR",
                        payload: {},
                    });
                })
                .catch(() => {
                    //
                });
        },
        mtd_volver: function () {
            this.execute_commit({
                mutation: "CALL__END_APPOINTMENT",
                payload: {
                    state_menu: 1,
                    state_end: 0,
                },
            });
        },
        mtd_stateViewPatient: function () {
            this.execute_commit({
                mutation: "CALL__STATE_VIEW_PATIENT",
                payload: {
                    state_view_patient: 1,
                },
            });
        },
    },
};
</script>

<style>
.home__wait-div {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0rem 1rem;
    overflow-y: auto;
}

.home__wait-user {
    margin-top: 1rem;
    text-align: center;
    flex: 1 0 auto;
}

.home__wait-user i {
    font-size: 5rem;
}

.home__wait-user .list-patient {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 1.5rem;
}

.home__wait-user .list-patient li {
    font-size: 1.1rem;

}

.home__wait-user .list-patient li b {
    font-weight: 600 !important;
}

.home__wait-user .list-patient li:nth-child(even) {
    margin-bottom: 0.5rem;
}

.home__wait-buttons {
    padding-bottom: 5%;
}

.btn-outline-atendiendo {
    color: #900052 !important;
    border-color: #900052 #900052 #5d0035 !important;
}

.btn-outline-atendiendo:hover {
    background-color: #900052 !important;
    border-color: #900052 !important;
    color: #fff !important;
}
</style>

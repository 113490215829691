<template>
<div class="dropdown mb-3" :class="[dropdown && 'activeItem']">
  
    <div class="dropdown-header" @click="toggleDropdown(title)">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='CampoVisual'">
                <CampoVisual @changePage="changePage"></CampoVisual>
            </div>
            <div id="myTabContentHijo" v-if="component=='Oct'">
                <Oct @changePage="changePage"></Oct>
            </div>
            <div id="myTabContentHijo" v-if="component=='OctMacula'">
                <OctMacula @changePage="changePage"></OctMacula>
            </div>
            <div id="myTabContentHijo" v-if="component=='Paquimetria'">
                <Paquimetria @changePage="changePage"></Paquimetria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Topografia'">
                <Topografia @changePage="changePage"></Topografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Microscopia'">
                <Microscopia @changePage="changePage"></Microscopia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Ecografia'">
                <Ecografia @changePage="changePage"></Ecografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Biometria'">
                <Biometria @changePage="changePage"></Biometria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Angiografia'">
                <Angiografia @changePage="changePage"></Angiografia>
            </div>
            <div id="myTabContentHijo" v-if="component=='Resonancia'">
                <Resonancia @changePage="changePage"></Resonancia>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import CampoVisual from '@/components/Medico/Consulta/CampoVisual.vue';
import Oct from '@/components/Medico/Consulta/Oct.vue';
import OctMacula from '@/components/Medico/Consulta/OctMacula.vue';
import Paquimetria from '@/components/Medico/Consulta/Paquimetria.vue';
import Topografia from '@/components/Medico/Consulta/Topografia.vue';
import Microscopia from '@/components/Medico/Consulta/Microscopia.vue';
import Ecografia from '@/components/Medico/Consulta/Ecografia.vue';
import Biometria from '@/components/Medico/Consulta/Biometria.vue';
import Angiografia from '@/components/Medico/Consulta/Angiografia.vue';
import Resonancia from '@/components/Medico/Consulta/Resonancia.vue';

export default {
    name: 'dropdown-component-consulta',
    props: {
        title: String,
        component: String,
        viewMedico: Number,
        dataPlan: Array,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory:{
          type: Object,
            default: () => ({})
        }
    },
    components: {
        CampoVisual,
        Oct,
        OctMacula,
        Paquimetria,
        Topografia,
        Microscopia,
        Ecografia,
        Biometria,
        Angiografia,
        Resonancia
    },
    data: () => ({
        dropdown: false,
        dataPlanDetailType: [],
    }),
    methods: {
        ...mapActions(['get', 'post']),
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },

        changePage(){
            this.$emit('changePage','cola')
        },
        
    }
}
</script>

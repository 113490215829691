<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xs-12">
        <div id="table-detalle">
          <table id="table-body" class="table table-bordered table-responsive">
            <caption class="py-0 my-0"></caption>
            <thead>
              <tr class="text-white border-white text-center text-xs bg-main">
                <th scope="col">N°</th>
                <th scope="col">FECHA DE REGISTRO</th>
                <th scope="col">OBSERVACIÓN</th>
                <th scope="col">USUARIO DE CALL</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index" class="text-xs">
                <td>{{ index + 1 }}</td>
                <td>{{ fixDate(item.created_at) }}</td>
                <td>{{ item.comment }}</td>
                <td>{{ item.user.name }} {{ item.user.last_name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <template #footer>
      <button @click="mtdClose" class="btn btn-danger btn-sm">
        <i class="fa fa-times" />&nbsp;Cerrar
      </button>
      &nbsp;
    </template>
  </CModal>
</template>
  
  <script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";

export default {
  name: "c-oftalmologia-modal-detalle",
  components: {
    CModal,
  },
  data() {
    return {};
  },
  props: {
    title: String,
    boo_modal: Boolean,
    data: Array,
  },
  computed: {},
  methods: {
    ...mapActions(["get", "post"]),

    mtdClose() {
      this.$emit("closeModalSeeCall");
    },
    fixDate(date) {
      date = new Date(date)
      let dateFixed = "";
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let hours = "";
      date.getHours() < 10
        ? (hours += "0" + date.getHours())
        : (hours = date.getHours());
      dateFixed =
        `${day}/${month}/${year}` +" " +
        `${hours}:${date.getMinutes().toString().padStart(2, "0")}`;
      return dateFixed;
    },
  },
};
</script>
  

<template>
  <div class="container">
    <div v-if="table">
      <div class="row pt-2 d-flex justify-content-start">
        <div class="col-lg-12 col-md-12 col-sm-12 fw-bold color-main mb-2">
          <div class="mt-1 mb-2 ms-auto btn-success text-start">
            <button
              type="button"
              class="btn btn-sm btn-outline-main text-white"
              @click="returnHome()"
            >
              <i class="fas fa-arrow-left"></i> Atrás
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 fw-bold color-main mb-2">
          <section class="card">
            <header class="card-header text-center">
              <h6 class="card-title" style="font-weight: bold">
                LISTA DE CODIGOS
              </h6>
            </header>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <cTable
                    :columns="columns"
                    :data="data"
                    @openmodal="openmodal"
                    @deleteData="deleteData"
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row pt-2 d-flex justify-content-start">
        <div class="col-lg-12 col-md-12 col-sm-12 fw-bold color-main mb-2">
          <div class="mt-1 mb-2 ms-auto btn-success text-start">
            <button
              type="button"
              class="btn btn-sm btn-outline-main text-white"
              @click="returnTable()"
            >
              <i class="fas fa-arrow-left"></i> Atrás
            </button>
          </div>
        </div>
      </div>
      <div class="row px-3">
        <section class="card">
          <header class="card-header text-center">
            <h6 class="card-title" style="font-weight: bold">
              {{ code.id == 0 ? "REGISTRAR" : "EDITAR" }} C&Oacute;DIGOS
            </h6>
          </header>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label"
                    >Cógido</label
                  >
                  <input
                    class="form-control form-control-sm"
                    placeholder="Codigo"
                    v-model="code.code"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <div class="mb-3">
                  <label for="exampleFormControlInput1" class="form-label"
                    >Título de codigo</label
                  >
                  <input
                    v-model="code.title"
                    class="form-control form-control-sm"
                    placeholder="Título"
                  />
                </div>
              </div>
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ckeditor :editor="editor" v-model="code.editorData"></ckeditor>
              </div>
              <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center d-flex justify-content-evenly mt-2"
              >
                <button @click="returnTable" class="btn btn-danger btn-sm">
                  <i class="fa fa-times" />&nbsp;Cancelar
                </button>
                <button class="btn btn-success btn-sm" @click="mtdAction">
                  <i class="fas fa-save"></i>
                  {{ code.id == 0 ? "REGISTRAR" : "EDITAR" }}
                </button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <br /><br />
    <SweetAlert
      :swal="swal"
      ref="SweetAlert"
      @deleteDataDetail="deleteDataDetail"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import cTable from "../shared/Table/cTable.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "c-medico-codes",
  data() {
    return {
      table: true,
      swal: null,
      columns: ["N°", "CÓDIGO", "TÍTULO", "ESTADO", "ACCIONES"],
      data: [],
      editor: ClassicEditor,
      code: {
        id: "",
        code: "",
        title: "",
        editorData: "",
      },
    };
  },
  created() {
    this.mtdGetData();
  },
  components: {
    cTable,
    SweetAlert,
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/Doctor/getCode",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.code;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    returnHome() {
      this.$emit("mtdChangePage", "home");
    },
    returnTable: function () {
      this.table = true;
    },
    openmodal: function (type, payloadEdit) {
      this.table = false;
      this.code = {
        id: type == 0 ? 0 : payloadEdit.id,
        code: type == 0 ? "" : payloadEdit.code,
        title: type == 0 ? "" : payloadEdit.title,
        editorData: type == 0 ? "" : payloadEdit.description,
      };
    },
    mtdAction: function () {
      let url = this.code.id == 0 ? "/Doctor/Code/store" : "/Doctor/Code/update";
      this.post({
        url: this.$store.getters.get__url + url,
        token: this.$store.getters.get__token,
        params: this.code
      })
        .then((response) => {
          if(!response.error && response.statusCode == 200){
            this.$refs.SweetAlert.showSuccess(response.message);
            this.code= {
              id: "",
              code: "",
              title: "",
              editorData: "",
            };
            this.returnTable();
            this.mtdGetData();
          }else{
             this.$refs.SweetAlert.showError(response.message);
          }
        })
        .catch((errors) => {});
    },

    deleteData: function () {},
    deleteDataDetail: function () {},
  },
};
</script>

<style lang="scss" scoped>
</style>
<template>
  <div>
    <nav
      id="nav-menu"
      class="fixed-bottom py-0 d-flex justify-content-around mt-2"
      style="background: rgb(162, 162, 162)"
    >
      <div
        :style="mtdGetStyle('kardex')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('kardex')"
      >
        <span :style="mtdGetStyleSpan('kardex')" class="btn w-100">KARDEX</span>
      </div>
      <div
        :style="mtdGetStyle('kardesInterno')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('kardesInterno')"
      >
        <span :style="mtdGetStyleSpan('kardesInterno')" class="btn w-100">KARDEX INTERNO</span>
      </div>
      <div
        :style="mtdGetStyle('stockminimo')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('stockminimo')"
      >
        <span :style="mtdGetStyleSpan('stockminimo')" class="btn w-100">STOCK MIN</span>
      </div>
      <!-- <div
        :style="mtdGetStyle('altas')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
      >
        <span :style="mtdGetStyleSpan('altas')" class="btn w-100">REPORTES</span>
      </div> -->
      <div
        :style="mtdGetStyle('vueltos')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('requerimiento')"
      >
        <span :style="mtdGetStyleSpan('vueltos')" class="btn w-100">REQUERIMIENTOS</span>
      </div>
      <!-- <div
        :style="mtdGetStyle('cxc')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
      >
        <span :style="mtdGetStyleSpan('cxc')" class="btn w-100">RUTA ESPERADA</span>
      </div> -->
      <!-- <div
        :style="mtdGetStyle('consultas')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
      >
        <span :style="mtdGetStyleSpan('consultas')" class="btn w-100"
          >DEVOLUCIONES / CANJES</span
        >
      </div> -->
      <div
        :style="mtdGetStyle('consignaciones')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('consignaciones')"
      >
        <span :style="mtdGetStyleSpan('consignaciones')" class="btn w-100">CONSIGNACIONES</span>
      </div>
      <div
        :style="mtdGetStyle('movimiento')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('movimiento')"
      >
        <span :style="mtdGetStyleSpan('movimiento')" class="btn w-100">MOVIMIENTO</span>
      </div>
      <div
        :style="mtdGetStyle('hisMovimiento')"
        class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border"
        @click="mtdSendOption('hisMovimiento')"
      >
        <span :style="mtdGetStyleSpan('hisMovimiento')" class="btn w-100">HISTORIAL DE MOVIMIENTO</span>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "NavBarComponent",
  props: {
    verifBead: {
      type: Boolean,
      default: false,
    },
    pageView: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: null,
    };
  },
  created() {},
  methods: {
    mtdGetStyle: function (option) {
      if (
        option == this.page && this.mtdNoPages()
      )
        return "background: #900052";
      return "";
    },
    mtdGetStyleSpan: function (option) {
      if (
        option == this.page && this.mtdNoPages()
      )
        return "color:white";
      return "";
    },
    mtdSendOption: function (page) {
      this.page = page;
      this.$emit("mtdChangePage", this.page);
    },
    mtdNoPages(){
      return this.pageView != "home" &&
        this.pageView != "os" &&
        this.pageView != "factura" &&
        this.pageView != "mantenimiento" &&
        this.pageView != "oc";
    }
  },
};
</script>



<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Nombre</label>
                <input type="text" class="form-control form-control-sm" v-model="data_detail.name" placeholder=" Nombre">
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

    
<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: '',

            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if(this.num == 1){
                    if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpButton() {
                return !(this.data_detail.name !='' );
            
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdClose() {
            this.data_detail.name ='';
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.data_detail.name ='';
        },
    },

};
</script>

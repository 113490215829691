<template lang="es">
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0  px-2">
        <div class="mb-3 text-start">
            <label for="exampleFormControlInput1" class="form-label ">Archivo:</label>
            <input type="file" @change="getFile" class="form-control" ref="fileInput" id="file" lang="es" name="file">
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" :disabled="!fileLoaded" @click="mtdaddpdf">
            Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            exam_unit: {
                name: '',
                date: '',
                user_id: '',
                user_name: ''
            },

            swal: null,
            file: "",
            fileLoaded: false,

        }
    },
    components: {
        CModal,
        SweetAlert
    },

    computed: {

    },
    props: {
        title: String,
        boo_modal: Boolean,
        patient_id: Number
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            const fileInput = this.$refs.fileInput;
            if (fileInput.files.length > 0) {
                fileInput.value = null;
            }
            this.fileLoaded = false;
            this.$emit('mtdclosemodalexam')

        },
        getFile(e) {
            let file = e.target.files[0];
            if (!/\.pdf$/i.test(file.name)) {
                Swal.fire({
                    text: "Seleccione un archivo formato PDF",
                    icon: "info",
                    confirmButtonColor: "#900052",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const input = this.$refs.fileInput;
                        input.type = "text";
                        input.type = "file";
                        this.fileLoaded = false;
                    }
                });
            } else {
                this.file = file;
                this.fileLoaded = true;
            }
        },
        mtdaddpdf: function () {
            
            let formData = new FormData();
            formData.append("name", this.file.name);
            formData.append("file", this.file);
            formData.append('status', 2);
            formData.append('view',2);
            formData.append("patient_id", this.patient_id);
            this.post({
                    url: this.$store.getters.get__url + "/Doctor/exam/store",
                    token: this.$store.getters.get__token,
                    params: formData
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        console.log(response.data);
                        this.$emit('addpdf', response.data);
                        Swal.fire({
                            icon: "success",
                            title: "Datos Guardados",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                })
                .catch((errors) => {});

            
            this.mtdclosemodal()
        }

    },
}
</script>

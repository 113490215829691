<template>
<div>

    <div class="container text-center mt-2 mb-3 mt-5">
        <!-- <div class="d-flex title-option">
            <div class="btn-home mt-3 mb-2 ms-auto">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-arrow-left"></i> Atrás
                </button>
            </div>
        </div> -->

        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="card-header text-white h5 bg-main text-center">
                    <strong>
                        CITA  DE {{calendario.status == 4 ? ' CONTROL' :'LECTURA DE RESULTADOS' }}
                    </strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-xl-8 mt-2">
                            <div id="div-modal-anular" class="w-100">
                                <div id="div-anular" class="card w-100">
                                    <div class="card-body w-100">

                                        <div class="row">
                                            <div class="col-md-12 py-2">
                                                <div class="card-body">
                                                    <FullCalendar :options="calendarOptions" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-xl-4 mt-2">
                            <div id="div-anular" class="card w-100">
                                <div class="card-body w-100">
                                    <section class="">

                                        <div class="">
                                            <div class="row">
                                                <div class="col-md-12 legend d-flex justify-content-center legend mb-2">
                                                    <div class="legend-item">
                                                        <div class="circle" style="background-color: blue"></div>
                                                        <span>CONSULTAS</span>
                                                    </div>
                                                    <div class="legend-item">
                                                        <div class="circle" style="background-color: green"></div>
                                                        <span>CIRUGIAS</span>
                                                    </div>
                                                    <div class="legend-item">
                                                        <div class="circle" style="background-color: orange"></div>
                                                        <span>IMAGENES</span>
                                                    </div>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="col-form-label label-modal">Fecha</label>
                                                        <input type="text" class="form-control" autocomplete="off" name="date" v-model="quote.dateStr" disabled />
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="col-form-label label-modal">Sala</label>
                                                        <div>
                                                            <v-select id="patientSelect" class="text-dark form-control" :options="conRoom" v-model="Reservation.selectedConRoom" :reduce="(room) => room.id" label="name" placeholder="- Buscar Sala-" required :clearable="false" @input="mtdGetTurn()" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label class="col-form-label label-modal" v-if="
                                  quote.dateStr &&
                                  Reservation.selectedConRoom !== ''
                                ">Turno</label>
                                                        <div class="row d-flex justify-content-center text-center">
                                                            <div v-for="turno in turns" :key="turno.id" class="col-md-12 col-sm-12 col-xl-4 col-lg-4 mt-2">
                                                                <button type="button" class="btn input-group-text form-control" :class="{
                                      'btn-outline-custom':
                                        selectedTurno !== turno.name,
                                      'text-white bg-main':
                                        selectedTurno === turno.name,
                                    }" @click="selectTurno(turno.name)" :disabled="
                                      !quote.dateStr ||
                                      Reservation.selectedConRoom == ''
                                    ">
                                                                    {{ turno.name }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-12 mt-2" v-if="selectedTurno !== ''">
                                                    <div class="table-responsive">
                                                        <table aria-describedby="mydesc" class="table table-striped table-scroll">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Horas</th>
                                                                    <th scope="col">
                                                                        <i class="fas fa-calendar-check"></i>
                                                                        Estado
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-if="selectedTurno !== ''">
                                                                    <tr v-for="hour in ofHours" :key="hour.id" class="cursor text-lg table-body">
                                                                        <td class="wide-cell">{{ hour.name }}</td>
                                                                        <td class="wide-cell">
                                                                            <span v-if="hour.status == 1">
                                                                                <i class="fas fa-circle text-danger"></i>
                                                                                Ocupado
                                                                            </span>
                                                                            <span v-else-if="hour.status == 0" @click="mtdCitas(hour.id)">
                                                                                <i class="fas fa-circle text-success"></i>
                                                                                Libre
                                                                            </span>
                                                                            <span v-else-if="hour.status == 2">
                                                                                <i class="fas fa-circle text-warning"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalCitasVue
      :title="modalCitas.title"
      :boo_modal="modalCitas.modal_form"
      @closeModalCitas="closeModalCitas"
      @mtdReservarCitas="reservarCita"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModalCitasVue from "@/components/Medico/AyudaDx/modals/cModalCitas.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

export default {
    name: "c-call-citas",
    components: {
        FullCalendar,
        //   cModalAddPatientVue,
        cModalCitasVue,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            viewInput: null,
            pos: 0,
            showCalendar: false,
            Reservation: {
                selectedMedic: "",
                selectedPatient: "",
                selectedService: "",
                selectedSerEs: "",
                selectedConRoom: "",
            },
            serviceOptions: [],
            medics: [],
            patients: [],
            turns: [],
            ofHours: [],
            conRoom: [],
            opeRoom: [],
            imgRoom: [],
            calendarOptions: {
                locale: "es",
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                events: [],
                initialView: "dayGridMonth",
                buttonText: {
                    today: "Hoy",
                    month: "Meses",
                    week: "Semanas",
                    day: "Días",
                },
                headerToolbar: {
                    left: "title",
                    center: "",
                    right: "prev today next",
                },
                weekends:true,
                selectable: true,
                editable: false,
                validRange: {
                    start: Date.now(),
                },
                dateClick: this.dateClick,
            },
            events: [],
            quote: {
                medic_id: null,
                dateStr: "",
                campu_id: null,
                turn: 1,
            },
            selectedTurno: "",
            ultimaFechaSeleccionada: null,
            lastSelectedDay: null,
            modalAddPatient: {
                title: "AGREGAR NUEVO PACIENTE",
                item: [],
                modal_from: false,
                pos: null,
            },
            modalCitas: {
                title: "CONFIRMACIÓN",
                item: [],
                modal_from: false,
                pos: null,
            },
            hourbycita: "",
            ofCall: [],
        };
    },
    props: {
        reserId: Number,
        patientId: Number,
        date: String,
        serId: Number,
        hour: String,
        doctorId: Number,
        calendario: {
            type: Object,
            default: () => ({})
        }
    },
    created() {
        this.mtdGetService();
        this.mtdGetTurn();
        this.mtdGetRoom();
        this.mtdGetHour();
    },
    watch: {
        events: {
            handler(newEvents) {
                this.calendarOptions.events = newEvents;
            },
            deep: true,
        },
    },
    computed: {
        isFilterDisabled() {
            return (
                !this.Reservation.selectedService || !this.Reservation.selectedMedic
            );
        },
        filteredProducts() {
            if (this.Reservation.selectedService) {
                const selectedService = this.serviceOptions.find(
                    (service) => service.id === this.Reservation.selectedService
                );
                if (selectedService) {
                    return selectedService.of_product;
                }
            }
            return [];
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        formatDate(date) {
            return moment(date).format("DD/MM/YYYY");
        },

        getServiceLabel(serId) {
            switch (serId) {
                case 1:
                    return 'CONSULTAS';
                case 2:
                    return 'CIRUGIAS';
                case 4:
                    return 'IMAGENES';
                default:
                    return '-';
            }
        },

        mtdGetService: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Call/services",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.serviceOptions = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        mtdGetDoctor: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Call/doctors",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.medics = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        mtdGetPatient: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Call/patients",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.patients = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        mtdGetTurn: function () {
            if (this.quote.dateStr != "" && this.Reservation.selectedConRoom != "") {
                const formattedDate = this.quote.dateStr.split("/").reverse().join("-");
                this.ofHours = [];
                this.selectedTurno = "";
                this.post({
                        url: this.$store.getters.get__url + "/Call/turns",
                        token: this.$store.getters.get__token,
                        params: {
                            room: this.Reservation.selectedConRoom,
                            date: formattedDate,
                            option: 1,
                        },
                    })
                    .then((response) => {
                        this.turns = response.data;

                        this.ofHours = [];
                        this.turns.forEach((turn) => {
                            turn.of_hour.forEach((hour) => {
                                this.ofHours.push({
                                    id: hour.id,
                                    name: hour.name,
                                });
                            });
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },

        mtdGetRoom: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Call/consultingRoom",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        service: 1,
                    },
                })
                .then((response) => {
                    this.conRoom = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        mtdGetHour: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Call/countReserva",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        // patientId: this.calendario.patient_id,
                    },
                })
                .then((response) => {
                    this.ofCall = response.data;
                    this.bringDate();
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        bringDate: function () {
            this.ofCall.forEach((item) => {
                let backgroundColor, title;
                switch (item.of_service_id) {
                    case 1:
                        backgroundColor = "blue";
                        title = "CONSULTAS";
                        break;
                    case 2:
                        backgroundColor = "green";
                        title = "CIRUGIAS";
                        break;
                    case 4:
                        backgroundColor = "orange";
                        title = "IMAGENES";
                        break;
                    default:
                        backgroundColor = "gray";
                        title = "Servicio Desconocido";
                        break;
                }
                const newEvent = {
                    title: title + " " + item.total,
                    start: item.date,
                    backgroundColor: backgroundColor,
                    borderColor: backgroundColor,
                    textColor: "white",
                };
                this.events.push(newEvent);
            });
        },

        dateClick: function (info) {
            const date2 = new Date(info.date);
            const date = info.dateStr;
            const olddate = new Date();
            var ano = olddate.getFullYear();
            var mes = ("0" + (olddate.getMonth() + 1)).slice(-2);
            var dia = ("0" + olddate.getDate()).slice(-2);
            var fechaFormateada = ano + "-" + mes + "-" + dia;
            if (date < fechaFormateada) {
                this.$refs.SweetAlert.showWarning("Elija una Fecha Válida");
            } else {
                const auxiliar = this.fixDate(date2, "date-en");
                this.quote.dateStr = moment(auxiliar).format("DD/MM/YYYY");
                this.ultimaFechaSeleccionada = date;
                this.mtdGetTurn();
            }
        },

        handleSelectItemClick: function (medicSelect) {
            const position = this.medics.findIndex(
                (medics) => medics.id === medicSelect.id
            );
            this.pos = position + 1;
            //this.showCalendar = false;
            this.clearService();
            //this.events = [];
            //this.mtdGetHour();
        },

        handleSelectItemClickPa: function (patientSelect) {
            const position = this.patients.findIndex(
                (patient) => patient.id === patientSelect.id
            );
            this.pos = position + 1;
        },

        handleSelectItemClickPaBu: function (patientSelect) {
            const position = this.patientsBu.findIndex(
                (patientBu) => patientBu.id === patientSelect.id
            );
            this.pos = position + 1;
        },

        handleSelectItemClickSe: function (selectedProduct) {
            const position = this.filteredProducts.findIndex(
                (product) => product.id === selectedProduct.id
            );
            this.pos = position + 1;
        },

        handleSelectItemClickRo: function (selectedRoom) {
            const position = this.filteredRoom.findIndex(
                (room) => room.id === selectedRoom.id
            );
            this.pos = position + 1;
        },

        mtdGetStyle: function (option) {
            if (option == this.page) return "background: #900052";
            return "";
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page) return "color:white";
            return "";
        },
        returnHome() {
            this.$emit("mtdChangePage", "cxc");
        },
        filterData() {
            this.showCalendar = true;
        },

        fixDate(date, type) {
            let dateFixed = "";
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hours = "";
            switch (type) {
                case "date-es":
                    if (date.getDate() < 10) day = "0" + day;
                    dateFixed = `${day}/${month}/${year}`;
                    break;
                case "date-en":
                    if (date.getDate() < 10) day = "0" + day;
                    dateFixed = `${year}-${month}-${day}`;
                    break;
                case "time":
                    date.getHours() < 10 ?
                        (hours += "0" + date.getHours()) :
                        (hours = date.getHours());
                    dateFixed = `${hours}:${date
            .getMinutes()
            .toString()
            .padStart(2, "0")}`;
                    break;

                default:
                    break;
            }
            return dateFixed;
        },

        /*
                selectTurno(turno) {
                    if (this.selectedTurno === turno) {
                        this.selectedTurno = '';
                    } else {
                        this.selectedTurno = turno;
                    }
                },
                */

        selectTurno(turno) {
            if (this.selectedTurno === turno) {
                this.selectedTurno = "";
                this.ofHours = [];
            } else {
                this.selectedTurno = turno;

                const selectedTurn = this.turns.find((turn) => turn.name === turno);
                if (selectedTurn) {
                    this.ofHours = selectedTurn.of_hour.map((hour) => ({
                        id: hour.id,
                        name: hour.name,
                        status: hour.status,
                    }));
                }
            }
        },

        mtdAddPatient: function () {
            this.modalAddPatient = {
                title: "AGREGAR NUEVO PACIENTE",
                modal_form: true,
            };
        },
        closeModalAddPatient: function () {
            this.modalAddPatient = {
                title: "AGREGAR NUEVO PACIENTE",
                item: [],
                modal_form: false,
                pos: null,
            };
        },

        mtdCitas: function (hour) {
            this.hourbycita = hour;
            this.modalCitas = {
                title: "CONFIRMACIÓN",
                modal_form: true,
            };
        },
        closeModalCitas: function () {
            this.modalCitas = {
                title: "CONFIRMACIÓN",
                item: [],
                modal_form: false,
                pos: null,
            };
            this.hourbycita = "";
        },

        reservarCita() {
            this.mtdReservation();
        },

        mtdReservation: function () {
            const formattedDate = this.quote.dateStr.split("/").reverse().join("-")  
            this.Reservation = {
                user_id: this.calendario.doctor_id,
                selectedMedic:'',
                selectedPatient: this.calendario.patient_id,
                selectedService: 1,
                selectedSerEs : this.calendario.status == 4 ? 2 : 6,
                selectedConRoom:this.Reservation.selectedConRoom,
                selectedSerEsImg:[]
            }
            
            
            this.post({
                    url: this.$store.getters.get__url + "/Call/reservationAsis",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        Reservation: this.Reservation,
                        hour: this.hourbycita,
                        date: formattedDate,
                        option: this.Reservation.selectedService,
                    },
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);

                    this.events.splice(0, this.events.length);
                    this.mtdGetHour();
                    this.mtdGetTurn();
                    this.clearService();
                    this.closeModalCitas();
                    this.$emit('mtdShowOffCanva');
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        mtdEditReservation: function () {
            const formattedDate = this.quote.dateStr.split("/").reverse().join("-");
            this.Reservation.selectedMedic = this.doctorId;
            this.Reservation.selectedPatient = this.patientId;
            this.Reservation.selectedService = this.serId;
            this.post({
                    url: this.$store.getters.get__url + "/Call/editReservationCxc",
                    token: this.$store.getters.get__token,
                    params: {
                        Reservation: this.Reservation,
                        hour: this.hourbycita,
                        date: formattedDate,
                        reserId: this.reserId,
                    },
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.events.splice(0, this.events.length);
                    this.mtdGetHour();
                    this.mtdGetTurn();
                    this.clearService();
                    this.closeModalCitas();
                    this.$emit("mtdChangePage", "cxc");
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        clearService: function () {
            this.Reservation.selectedSerEs = "";
            this.Reservation.selectedPatient = "";
            this.Reservation.selectedSerEs = "";
            this.quote.dateStr = "";
            this.Reservation.selectedConRoom = "";

            this.selectedTurno = "";
            this.ofHours = [];
        },
    },
};
</script>

<style scoped>
#newPatientLink {
    text-decoration: none;
    color: #900052;
}

.btn-outline-custom {
    color: #900052;
    border-color: #900052;
}

.cursor {
    cursor: pointer;
}

.wide-cell {
    padding: 4px;
    font-size: 14px;
}

.align {
    display: block;
    text-align: left;
}

.fc-highlight {
    background-color: #ffc107;
    border-color: #ffc107;
    color: #000000;
}

/*leyenda*/
.legend-item {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.circle {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
}

.table-responsive {
    max-height: 250px;
    overflow-y: auto;
    display: block;
}
</style>

<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
    >
      <div class="row">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
          <div id="table-detalle" style="max-height: 500px; overflow-y: auto">
            <table id="table-body" class="table table-bordered table-responsive">
              <thead>
                <tr
                  align="center"
                  class="text-white border-white"
                  style="background: #900052"
                >
                  <th scope="col">N°</th>
                  <th scope="col">FECHA</th>
                  <!-- <th scope="col">COMPROBANTE</th> -->
                  <th scope="col" v-if="title != 'Egresos'">A NOMBRE DE</th>
                  <th scope="col">SERIE</th>
                  <th scope="col">NÚMERO</th>
                  <th scope="col">MONTO</th>
                </tr>
              </thead>
              <tbody style="background: #f1f2f3">
                <tr v-for="(it, index) in item" :key="index">
                  <th>{{ index + 1 }}</th>
                  <td>{{ it.date | formatDate }}</td>
                  <!-- <td>{{ it.document }}</td> -->
                  <td v-if="title != 'Egresos'">{{ it.vaucher_fullname }}</td>
                  <td>{{ it.serie }}</td>
                  <td>{{ it.number }}</td>
                  <td>
                    {{ title !== "Egresos" ? it.chargeAmount || "-" : it.amount || "-" }}
                  </td>
                </tr>
                <tr v-if="item.length === 0">
                  <td class="text-center" colspan="7">No hay datos registrados</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <template #footer>
        <button @click="mtdClose"  class="btn btn-danger btn-sm">
          <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
      </template>
    </CModal>
</template>
  
  <script>
  import CModal from "../../shared/modals/CModal.vue";
  import moment from "moment";
  import { mapActions } from "vuex";

  export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
      CModal,
    },
    data() {
      return {
        transaction: {
        type: 1,
        amount: "",
      },
      message: false,
      };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      item: {},
      size: String,
    },
    computed: {},
    methods: {
      ...mapActions(["get", "post"]),
  
      mtdClose() {
        this.$emit("closeModalDetalle");
      },
    },
    filters: {
      formatDate(date) {
        return date != null ? moment(date).format("DD/MM/YYYY") : "-";
      },
      formatTime(time) {
        let arTime = time.split(":");
        if (arTime[0] <= 12) {
          return time + " am";
        } else {
          return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
        }
      },
    },
   
  };
  </script>
  

<template>
<div>
    <div id="div-anular" class=" w-100 ">

        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class=" w-100">
                <div class="card-body w-100">
                    <div class="row mt-4 px-2">

                        <div class="col-xl-12 px-0 py-2">
                            <div class="row mx-0">
                                <div class="col-xl-10 col-lg-12 px-1 ">
                                    <div class="input-group input-group ">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input type="text" class="form-control form-control" placeholder="Buscar" @input="calculateData(6)" v-model="search" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-12 btn-home px-1 py-xl-0 my-xl-0 my-2">
                                    <button type="button" class=" btn btn-bm-noradius w-100" @click="openmodal(0)">
                                        <i class="fas fa-plus"></i> Nueva
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between px-1 mb-2">
                            <div class="d-sm-flex">
                                <div class="form-check me-sm-2">
                                    <input v-model="seleccione" @click="mtdselecione" value="1" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked>
                                    <label class="form-check-label" for="flexRadioDefault1">
                                        Productos
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input v-model="seleccione" @click="mtdselecione" value="2" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2">
                                    <label class="form-check-label" for="flexRadioDefault2">
                                        Servicios
                                    </label>
                                </div>
                            </div>
                            <div v-if="data.length > 0 && cpData.length > 0">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0 ">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <div class="col-md-12 px-0" id="content-timelinetable">
                            <table id="tb-anular" class="table table-borderless mb-0 text-xs">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white table-th text-center">
                                        <th scope="col" class="align-middle border border-5 bg-main border-white">
                                            #
                                        </th>
                                        <th scope="col" class="align-middle border border-5 bg-main border-white">
                                            NOMBRE
                                        </th>
                                        <th scope="col" class="align-middle border border-5 bg-main border-white">
                                            RUC
                                        </th>
                                        <th scope="col" class="align-middle border border-5 bg-main border-white">
                                            EMAIL
                                        </th>
                                        <th scope="col" class="align-middle border border-5 bg-main border-white">
                                            PERSONA ENCARGADA
                                        </th>
                                        <th v-if="seleccione == 1" scope="col" class="align-middle border border-5 bg-main border-white">
                                            MARCAS - PRODUCTOS
                                        </th>
                                        <th v-else scope="col" class="align-middle border border-5 bg-main border-white">
                                            SERVICIOS
                                        </th>

                                        <th scope="col" class="align-middle border border-5 bg-main border-white">
                                            ESTADO
                                        </th>

                                        <th scope="col" class="align-middle border border-5 bg-main border-white">
                                            ACCIONES
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr class="table-body" v-for="(item, index) in cpData" :key="index">
                                        <td class="text-center border border-5 border-white align-middle">
                                            {{ index+1 }}
                                        </td>
                                        <td class="text-center border border-5 border-white align-middle">
                                            {{ item.name }}
                                        </td>
                                        <td class="text-center border border-5 border-white align-middle">
                                            {{ item.ruc }}
                                        </td>
                                        <td class="text-center border border-5 border-white align-middle">
                                            {{ item.email }}
                                        </td>
                                        <td class="text-center border border-5 border-white align-middle">
                                            {{ item.persona_encargada }}
                                        </td>
                                        <td v-if="seleccione==1" class="text-center border border-5 border-white align-middle">
                                            <button class="btn btn-sm btn-info text-white me-1 " @click="openmodal(3,item)">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </td>
                                        <td v-else class="text-center border border-5 border-white align-middle">
                                            <button class="btn btn-sm btn-info text-white me-1 " @click="openmodalservice(3,item)">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </td>
                                        <td v-if="item.state==1" class="text-center border border-5 border-white align-middle">
                                            <span class=" text-white rounded rounded-2 px-3  bg-success">Activo</span>
                                        </td>
                                        <td v-else class="text-center border border-5 border-white align-middle">
                                            <span class=" text-white rounded rounded-2 px-3  bg-secondary">Inactivo</span>
                                        </td>
                                        <td class="text-center border border-5 border-white align-middle">
                                            <div class="d-flex  justify-content-center">
                                                <button class="btn btn-sm btn-primary text-white me-1 " @click="openmodal(1,item)">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button class="btn btn-sm btn-danger text-white" @click="deleteData(item)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                                <button v-if="seleccione == 1" class="btn btn-sm  btn-info text-white ms-1" @click="openmodal(2,item)">
                                                    <i class="fas fa-tag"></i>
                                                </button>
                                                <button v-else class="btn btn-sm  btn-info text-white ms-1" @click="openmodalservice(2,item)">
                                                    <i class="fas fa-tag"></i>
                                                </button>

                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <cModalProviderVue :dataBrand="dataBrand" :num="modal.num" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
        <SweetAlert :swal="swal" ref="SweetAlert" @deleteDataDetail="deleteDataDetail" />
        <cModalMarcaVue :productopormarcas="productopormarcas" :provider_id="provider_id" @mtdaddproduct="mtdaddproduct" @mtdaddbrand="mtdaddbrand" :idMarca="idMarca" :dataProvider="dataProvider" :num="modalMarca.num_data" :dataBrandProvider="dataBrandProvider" :dataBrand="dataBrand" :title="modalMarca.title" :boo_modal="modalMarca.modal_form" @closeModalMarca="closeModalMarca" />
        <cModalServiceVue @mtdaddservicios="mtdaddservicios" :proveedor_id="proveedor_id" :services="services" :num="modalService.num_data" :title="modalService.title" :boo_modal="modalService.modal_form" @closeModalService="closeModalService" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import cModalProviderVue from '../../modals/cModalProvider.vue'
import cModalMarcaVue from '../../modals/cModalMarca.vue';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalServiceVue from '../../modals/cModalService.vue';
export default {
    name: "c-logistica-pages-mantenimiento-proveedores",
    data() {
        return {
            swal: null,
            provider_id: null,
            dataShow: [],
            totalPagesToShow: 4,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            search: "",
            modalMarca: {
                title: '',
                modal_form: false,
                num_data: null,
            },
            modalService: {
                title: '',
                modal_form: false,
                num_data: null,
            },
            data: [],
            dataBrand: [],
            idMarca: [],
            dataBrandProvider: [],
            dataProvider: {},
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },

            productopormarcas: [],
            services: [],
            seleccione: 1,
            proveedor_id: null

        };
    },
    components: {
        cModalMarcaVue,
        SweetAlert,
        cModalProviderVue,
        cModalServiceVue
    },
    created() {
        this.getData();
        this.getDataBrand()
    },
    computed: {
        cpButtonForm: function () {
            return this.provider.name.length <= 1;
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },

    methods: {
        ...mapActions(["get", "post"]),

        getData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexProvider",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    this.data = response.data
                    this.button_num = 1
                    this.calculateData(10)
                })
                .catch(error => {
                    console.log(error);
                })
        },

        getDataBrand: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Maintainer/indexBrands",
                    token: this.$store.getters.get__token,
                }).then(response => {
                    this.dataBrand = response.data
                })
                .catch(error => {
                    console.log(error);
                })

        },
        openmodal: function (num, payload) {
            if (num == 2 || num == 3) {
                if (num == 3) {
                    this.dataBrandProvider = payload.brands
                    this.provider_id = payload.id;
                    this.post({
                            url: this.$store.getters.get__url + "/Maintainer/indexProductBrand",
                            token: this.$store.getters.get__token,
                            params: {
                                id: payload.id
                            }
                        })
                        .then(response => {
                            this.productopormarcas = response.data.productos_por_marca;

                        })
                        .catch(error => {
                            console.log(error);
                        })

                } else {
                    this.dataProvider = payload;
                    this.idMarca = [];
                    payload.brands.forEach(element => {
                        this.idMarca.push(element.brand_id)
                    });
                }
                this.modalMarca = {
                    title: (num == 2) ? 'Listado de Marcas' : 'Marcas del Proveedor',
                    modal_form: true,
                    num_data: num
                }
            } else {
                this.modal.title = (num == 0) ? 'Registrar Proveedor' : 'Editar Proveedor';
                this.modal.modal_form = true;
                this.modal.num = num;
                this.modal.payload = payload;
            }

        },
        closeModalMarca: function () {
            this.modalMarca = {
                title: '',
                modal_form: false,
            }
            this.idMarca = [];
            this.dataProvider = {}
        },
        mtdaddbrand: function (brands) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/addBrandProvider",
                    token: this.$store.getters.get__token,
                    params: brands,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.getData();
                    this.closeModalMarca()
                })
                .catch(error => {
                    console.log(error);
                })
        },
        mtdaddproduct: function (products) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/addProductProvider",
                    token: this.$store.getters.get__token,
                    params: products,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.getData();
                    this.closeModalMarca()
                })
                .catch(error => {
                    console.log(error);
                })

        },
        deleteData: function (payload_detail) {
            payload_detail.action = 'd';
            this.$refs.SweetAlert.showDelete(payload_detail, 'deleteDataDetail');
        },

        deleteDataDetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/Provider",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (response.statusCode == 200) {
                        this.getData();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail)
            } else {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail)
            }
        },
        addDatadetail: function (payload_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/Provider",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.closeModal()
                    this.getData();
                })
                .catch(error => {
                    console.log(error);
                })
        },

        calculateData(items) {
            let filteredData = this.data;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdselecione: function () {
            this.seleccione = (this.seleccione == 1) ? 2 : 1;
        },
        openmodalservice: function (num, payload) {
            if (num == 2) {
                this.proveedor_id = payload.id
            } else {
                this.services = payload.services
            }
            this.modalService = {
                title: (num == 2) ? 'Listado de Servicios' : 'Servicios del Proveedor',
                modal_form: true,
                num_data: num,
            }
        },

        closeModalService: function () {
            this.modalService = {
                title: '',
                modal_form: false,
                num_data: null,
            }
            this.proveedor_id = null
        },


        mtdaddservicios: function (services) {
            this.post({
                    url: this.$store.getters.get__url + "/Maintainer/addServiceProvider",
                    token: this.$store.getters.get__token,
                    params: services,
                })
                .then(response => {

                    Swal.fire({
                        title: "Registro Exitoso",
                        text: "Perfecto!",
                        icon: "success",
                        width: "400px",
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                    this.getData();
                    this.closeModalService()

                })
                .catch(error => {
                    console.log(error);
                })

        },
    },
};
</script>

<style scoped>
.table-th>th {
    border-radius: 15px;
}

.table-body>td {
    background: #eaecee;
    border-radius: 15px;
}
</style>

<template>
    <div>
      
      <div class="row mx-0 mt-3 p-3 d-flex justify-content-start">
        <div class="col-xl-4 col-lg-6 col-md-12 border mb-3 p-3">
            <cPatient :patient="patient" :dataTableImages="data" @toggleVisibilityGraph="toggleVisibilityGraph"></cPatient>
        </div>
        <!---Imagen-->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 px-3" v-if="state">
            <div class="row rounded-5 d-flex justify-content-center border-2 h-100 p-2" id="div-img-angiografia">
                <div id="carouselExampleIndicators" class="carousel slide home-carousel mt-0" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active imagen-carousel">
                            <img src="../../../assets/analisis/default.png" class="d-block w-100" data-bs-toggle="modal" data-bs-target="#lightbox" data-bs-slide-to="0" alt="...">
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 col-lg-6 col-sm-12  px-0" v-if="state">
            <div class="row rounded-5 border-2 h-100" id="div-img-angiografia">
                <div class="justify-content-center p-1">
                    <!-- <h2 class="">{{ pos }}</h2> -->
                    <vue-pdf-app :pdf="pdfUrl" file-name="file name" />
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal" id="lightbox" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header bg-main">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
                <div class="modal-body p-0">
                    <div id="carouselLightbox" class="carousel slide" data-bs-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <img class="d-block w-100" src="../../../assets/analisis/default.png" alt="First slide">
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselLightbox" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselLightbox" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    </div>
  </template>
  
  <script>
  import {
      mapActions
  } from 'vuex'
  import VuePdfApp from "vue-pdf-app";
  // import this to use default icons for buttons
  import "vue-pdf-app/dist/icons/main.css";
import cPatient from '@/components/Medico/AyudaDx/cPatient.vue'

  export default {
      name: 'c-medico-resonancia',
      components: {
        VuePdfApp,
        cPatient
      },
      data() {
        return {
          page: 'resonancia',
          stateHistory: null,
            viewInput: null,
            pos: 0,
            patient_id: "",
            select_patient: null,
            state: false,
            optionShow: 0,
            jsonData: [],
            itemExamen: {},
            dataModal1: {
                slot: {
                    component: null,
                    data: {
                        row: null,
                        buttonSubmit: null,
                        method: null,
                    },
                },
                config: {
                    title: null,
                    id: "c-modal-1__payment",
                    idHash: "#c-modal-1__payment",
                    size: null,
                    scroll: true,
                },
            },
            pdfUrl: '/assets/pdf/prueba.pdf',
        }
      },
      props:{
          stateRolAdmin: Boolean,
          patient :{
            type: Object,
        },
        data :{
            type: Array,
            default: () => ([])
        }
      },
      created() {
          
      },
      mounted() {
        this.mtdConversionData();

    },
      methods: {
          ...mapActions(['get', 'post']),
          handleSelectItemClick: function (patientSelect) {
            // Encuentra la posición del paciente seleccionado en el array `patients`
            const position = this.patients.findIndex(patient => patient.id === patientSelect.id);

            this.pos = position + 1;

            // Realiza cualquier otra acción necesaria con la posición del paciente seleccionado
        },
        mtdConversionData: function () {
            this.data.forEach(element => {
                const jsonDataFromBackend = JSON.parse(element);
                this.jsonData = this.jsonData.concat(jsonDataFromBackend);
            });
        },
        toggleVisibilityGraph: function () {
            this.state = !this.state;
        },
          mtdGetStyle: function (option) {
              if (option == this.page) return 'background: #900052';
              return '';
          },
          mtdGetStyleSpan: function (option) {
              if (option == this.page) return 'color:white';
              return '';
          },
          changePage: function (page) {
              this.page = page;
              if(page==='cola'){
                  this.patients ={}
              }
              this.stateHistory = (page === 'resonancia') ? 1 : 0;
              this.viewInput = (page === 'resonancia') ? 1 : 0;
          },
          returnHome() {
           this.$emit('mtdChangePage','home')
          },
          mtdviewpage: function (payload) {
              this.page = 'resonancia'
              this.stateHistory = 0;
              this.patients = payload;
              this.viewInput=0;
          }
          
      }
  }
  </script>
<template>
  <div>
    <div class="container text-center mt-2">
      <div class="d-flex">
        <div class="btn-home mt-3 mb-2 ms-auto">
            <button type="button" class="btn btn-outline-main" @click="returnHome()">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
      </div>
      <ul class="nav pb-0" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            @click="changePage('egreso')"
            :class="{
              'item-button': true,
              'nav-link': true,
              active: page === 'egreso',
            }"
            id="home-tab"
            type="button"
          >
            <strong>Egreso</strong>
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            @click="changePage('pasaje')"
            :class="{
              'item-button': true,
              'nav-link': true,
              active: page === 'pasaje',
            }"
            id="profile-tab"
            type="button"
          >
            <strong>Pasajes</strong>
          </button>
        </li>
      </ul>
      <div class="tab-content mb-4" id="myTabContent">
        <br />
        <Egreso v-if="page == 'egreso'" :bead="bead" />
        <Pasaje v-if="page == 'pasaje'" :bead="bead"/>
      </div>
    </div>
  </div>
</template>

<script>
import Egreso from "@/components/Oftalmologia/Egresos/EgresoCreate.vue";
import Pasaje from "@/components/Oftalmologia/Egresos/PasajeCreate.vue";
export default {
  name: "EgresoView",
  components: {
    Egreso,
    Pasaje
  },
  props:{
    bead:Object
  },

  data() {
    return {
      page: "egreso",
    };
  },

  methods: {
    returnHome: function () {
      this.$emit("mtdChangePage", "home");
    },
    changePage: function (page) {
      this.page = page;
    },
  },
};
</script>

<style scoped>
.h4-borde {
  border-radius: 50px;
}

#myTabContent {
  border: #900052 1.9px solid;
}

.item-button {
  background: #a9a9a9 !important;
  border-radius: 10px 10px 0px 0px;
  /* margin-right: 5px !important;*/
  border: none !important;
  color: white !important;
  width: 180px;
}

.item-button1 {
  background: #a9a9a9 !important;
  border-radius: 10px 10px 0px 0px;
  /* margin-right: 5px !important;*/
  border: none !important;
  color: white !important;
  width: 160px !important;
}

.nav-link:hover {
  background: #900052 !important;
}

.active {
  background-color: #900052 !important;
}

.nav-item {
  width: 185px;
}
</style>

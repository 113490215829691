<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :fullScreen="true">
    <div class="row mx-0 px-2 w-100">
        <div class="col-md-12 col-lg-12 col-xs-12 mb-3 px-0">
            <div class="row mt-2">
                <div class="col-xl-2 col-12 ">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-3">Sede</span>
                        <select id="campu_id" class="form-control form-control">
                            <option selected value="0" disabled>Seleccione ...</option>
                            <option v-for="(index) in 2" :key="index">
                                Campu {{ index }} 
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-xl-2 col-12 my-xl-0 my-2 ">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-3">Caja</span>
                        <select id="bead_id" class="form-control form-control">
                            <option selected value="0" disabled>Seleccione ...</option>
                            <option v-for="(index) in 2" :key="index">
                                Caja {{ index}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-xl-2 col-12">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-3">Desde</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" />
                    </div>
                </div>

                <div class="col-xl-2 col-12 my-xl-0 my-2 ">
                    <div class="input-group input-group-sm">
                        <span class="input-group-text col-md-3">Hasta</span>
                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" />
                    </div>
                </div>

                <div class="col-xl-2 col-12 btn-home">
                    <button type="button" name="accion" class="btn btn-sm  w-100 btn-register text-white">
                        <i class="fas fa-search"></i> Buscar
                    </button>
                </div>
                <div class="col-xl-2 col-12 btn-home my-xl-0 my-2">
                    <button data-bs-dismiss="modal" aria-label="Close" type="button" name="accion" class="btn btn-sm w-100 btn-register text-white">
                        <i class="fas fa-file-excel"></i> Exportar
                    </button>
                </div>
            </div>
            <div class="row mt-2" v-if="type == 'INGRESO'">
                <div class="col-xl-4 col-12">
                    <div class="">
                        <label for="categoria" class="form-label">Categoría</label>
                        <select class="form-control form-control-sm">
                            <option value="0" selected>[Todas las categorias..]</option>
                            <option v-for="(index) in 3" :key="index">
                                Categoria {{ index }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-xl-4 col-12 my-xl-0 my-2">
                    <label for="tipo ">Tipo de paciente</label>
                    <br />
                    <div class="form-check form-check-inline mt-2">
                        <input class="form-check-input" type="checkbox" />
                        <label for="eps" class="form-check-label">EPS</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" />
                        <label for="particular" class="form-check-label">PARTICULAR</label>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.type == 'INGRESO'" class="col-md-12 px-0" id="content-timelinetable">
            <table class="table table-bordered" aria-describedby="mydesc">
                <thead>
                    <tr class="text-white border-white text-center bg-main text-xs">
                        <th scope="col" class=" align-middle">N°</th>
                        <th scope="col" class=" align-middle">ESTADO</th>
                        <th scope="col" class=" align-middle">ADM</th>
                        <th scope="col" class=" align-middle">SEDE</th>
                        <th scope="col" class=" align-middle">FECHA</th>
                        <th scope="col" class=" align-middle">COMPROBANTE</th>
                        <th scope="col" class=" align-middle">A NOMBRE DE</th>
                        <th scope="col" class=" align-middle">SERIE</th>
                        <th scope="col" class=" align-middle">NÚMERO</th>
                        <th scope="col" class=" align-middle">PACIENTE TIPO</th>
                        <th scope="col" class=" align-middle">CONVENIO</th>
                        <th scope="col" class=" align-middle">%</th>
                        <th scope="col" class=" align-middle">INFORMACIÓN DEL PACIENTE</th>
                        <th scope="col" class=" align-middle">CATEGORÍA</th>
                        <th scope="col" class=" align-middle">SERVICIO ESPECÍFICO</th>
                        <th scope="col" class=" align-middle">MONTO</th>
                        <th scope="col" class=" align-middle">MEDIO DE PAGO</th>
                    </tr>
                </thead>
                <tbody class="text-xs border-main">
                    <tr v-for="(index) in 5" :key="index" >
                        <td class=" align-middle">{{ index }}</td>
                        <td class=" align-middle">ACTIVO</td>
                        <td class=" align-middle">MONJA SANCHEZ MILAGROS PATRICIA</td>
                        <td class=" align-middle"> SANTA VICTORIA </td>
                        <td class=" align-middle">2024-03-04</td>
                        <td class=" align-middle">BOLETA</td>
                        <td class=" align-middle">2774678-WALTER ROMERO PEREZ</td>
                        <td class=" align-middle">BE01</td>
                        <td class=" text-end align-middle">888</td>
                        <td class=" align-middle">PARTICUALR</td>
                        <td class=" align-middle">-</td>
                        <td class="text-end align-middle ">100.00</td>
                        <td class=" align-middle">WALTER ROMERO PEREZ</td>
                        <td class=" align-middle">DOCUMENTACIÓN</td>
                        <td class=" align-middle">COPIA DE HISTORIA CLINICA </td>
                        <td class="text-end align-middle">60.00</td>
                        <td class=" align-middle" >EFECTIVO</td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div v-if="this.type == 'EGRESO'" class="col-md-12 px-0" id="content-timelinetable">
            <table  class="table table-bordered border-main" aria-describedby="tb-egreso">
                <thead>
                    <tr class="text-white border-white text-center bg-main text-xs" >
                        <th class=" align-middle" scope="col">N°</th>
                        <th class=" align-middle" scope="col">ESTADO</th>
                        <th class=" align-middle" scope="col">ADM</th>
                        <th class=" align-middle" scope="col">SEDE</th>
                        <th class=" align-middle" scope="col">FECHA EGRESO</th>
                        <th class=" align-middle" scope="col">FECHA DE COMPROBANTE</th>
                        <th class=" align-middle" scope="col"> TIPO COMPROBANTE</th>
                        <th class=" align-middle" scope="col">SERIE</th>
                        <th class=" align-middle" scope="col">NÚMERO</th>
                        <th class=" align-middle" scope="col">RAZÓN SOCIAL</th>
                        <th class=" align-middle" scope="col">RUC / DNI</th>
                        <th class=" align-middle" scope="col">TOTAL</th>
                        <th class=" align-middle" scope="col">MONTO PAGADO</th>
                        <th class=" align-middle" scope="col">PENDIENTE</th>
                        <th class=" align-middle" scope="col">MEDIO DE PAGO</th>
                        <th class=" align-middle" scope="col"> MOTIVO, DESCRIPCIÓN O DETALLE DE COMPRA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="( index) in 5" :key="index" class="text-xs">
                        <td class=" align-middle">{{ index }}</td>
                        <td class=" align-middle">APROBADO</td>
                        <td class=" align-middle">ARMAS LLONTOP FABIOLA</td>
                        <td class=" align-middle">SANTA VICTORIA</td>
                        <td class=" align-middle">01/01/2024</td>
                        <td class=" align-middle"> 01/01/2024</td>
                        <td class=" align-middle">FACTURA</td>
                        <td class=" align-middle">FBF5</td>
                        <td class=" align-middle text-end">8882</td>
                        <td class=" align-middle"> BOTICAS IP S.A.C</td>
                        <td class=" align-middle">20608430301</td>
                        <td class=" align-middle text-end">21.80</td>
                        <td class=" align-middle text-end">21.80</td>
                        <td class=" align-middle text-end">0.00</td>
                        <td class=" align-middle">EFECTIVO</td>
                        <td class=" align-middle">COMPRA DE MEDICAMENTOS</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm">
            <i class='fas fa-save' />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-oftalmologia-modal-reporte",
    components: {
        CModal,
    },
    data() {
        return {

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        type:String
    },
    computed: {},
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.$emit("closeModalReporte");
        },
    },
};
</script>

<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content h-100 " style="background:white">
        <HomeVue v-if="page=='home'" @changePage="changePage" @mtdBackCampus="mtdBackCampus" />
        <DocumentsVue v-if="page=='document'" @changePage="changePage" />
        <IngresosVue v-if="page=='ingreso'" @changePage="changePage" />
        <NotesCreditsVue v-if="page=='notas'" @changePage="changePage" />
        <VolverEmitir v-if="page=='volveremitir'" @changePage="changePage" />
        <OrdenCompra v-if="page=='ordencompra'" @changePage="changePage" />
        <OrdenServicio v-if="page=='ordenservicio'" @changePage="changePage" />
    </div>
    <div class="footer">
        <FooterVue @mtdChangePage="mtdChangePage" :pageView='page' />
    </div>
</div>
</template>

  
<script>
import HomeVue from '@/components/Contabilidad/Home.vue';
import Nav from "../../components/Contabilidad/Nav.vue";
import FooterVue from '@/components/Contabilidad/Footer.vue';
import DocumentsVue from '@/components/Contabilidad/pages/Documents.vue';
import IngresosVue from '@/components/Contabilidad/pages/Ingresos.vue';
import NotesCreditsVue from '@/components/Contabilidad/pages/NoteCredits.vue';
import VolverEmitir from "@/components/Contabilidad/pages/VolverEmitir.vue";
import OrdenCompra from "@/components/Contabilidad/pages/Oc.vue";
import OrdenServicio from "@/components/Contabilidad/pages/Os.vue";
export default {
    name: "v-contabilidad",
    components: {
        Nav,
        HomeVue,
        FooterVue,
        DocumentsVue,
        NotesCreditsVue,
        IngresosVue,
        OrdenCompra,
        OrdenServicio,
        VolverEmitir,
    },
    data() {
        return {
            page: "home",
        };
    },
    props: {
        rolname: String,
        dataRoles: Array
    },
    created() {
        this.rol_name = this.rolname;
        this.mtdGetRolAdmin();
    },
    methods: {
        changePage: function (page) {
            this.page = page;
        },
        returnHome: function () {
            this.page = "home";
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdChangePage: function (page) {
            this.page = page;
        },
        mtdGetRolAdmin: function () {
            this.dataRoles.forEach(element => {
                if (element.role_id == 13) {
                    this.stateRolAdmin = true;
                }
            });
        },
    },
};
</script>

  
<style lang="scss" scoped></style>

<template>
  <div id="div_cola">
    <div class="logo-flotante">
      <img
        src="../assets/bm_sin_fondo.png"
        alt="Logo"
        class="d-none d-sm-block"
      />
    </div>

    <div class="c-app flex-row align-items-center">
      <div class="container" id="contenedor">
        <div id="btn_home" class="c-app align-items-center">
          <div class="d-grid gap-2 mx-auto text-center col-md-12">
            <div class="container principal">
              <div class="row">
                <div class="col-lg-12 text-center">
                  <div class="row">
                    <div
                      class="col-lg-3 col-md-12 mb-5"
                      v-for="(columnHeader, columnIndex) in Object.keys(data)"
                      :key="columnIndex"
                    >
                      <div class="card-section border rounded p-3">
                        <div class="card-header-first rounded pb-2">
                          <h3
                            v-if="columnHeader == 'consulta'"
                            class="card-header-title text-white py-2"
                          >
                            CONSULTA NUEVA O REINGRESO
                          </h3>
                          <h3
                            v-if="columnHeader == 'fondo'"
                            class="card-header-title text-white py-2"
                          >
                            FONDO DE OJOS
                          </h3>
                          <h3
                            v-if="columnHeader == 'lectura'"
                            class="card-header-title text-white py-2"
                          >
                            LECT. DE RESULTADOS
                          </h3>
                          <h3
                            v-if="columnHeader == 'control'"
                            class="card-header-title text-white py-2"
                          >
                            CONTROL POR INFECCIÓN
                          </h3>
                        </div>
                        <div
                          v-for="(header, headerIndex) in Object.keys(data)"
                          :key="headerIndex"
                        >
                          <draggable
                            class="draggable pt-3"
                            v-if="columnHeader == header"
                            :list="data[header]"
                            :group="{ name: 'cells', put: true }"
                            @change="onItemMove"
                          >
                            <div
                              v-for="(item, index) in data[header]"
                              :key="index"
                              :class="{ highlighted: index === 0 }"
                              class="card-body pt-2 text-center"
                            >
                              <span
                                v-if="columnHeader == 'consulta'"
                                class="drag-handle card-text"
                              >
                                <b v-if="item.status !== 3">
                                  <i class="fas fa-asterisk"></i>
                                  {{ index + 1 }}. {{ item.name }}</b
                                >
                                <b v-else>
                                  <i class="fas fa-asterisk"></i>
                                  {{ index + 1 }}. {{ item.name }} (FO)</b
                                >
                              </span>
                              <span v-else class="drag-handle">
                                <b>
                                  <i class="fas fa-asterisk"></i>
                                  {{ index + 1 }}. {{ item.name }}</b
                                >
                              </span>
                            </div>
                          </draggable>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapActions } from "vuex";

export default {
  name: "Cola1View",
  props: {},
  components: {
    draggable,
  },
  data() {
    return {
      data: {},
      consultasLength: 0,
      fondoOjosLength: 0,
      resultadosLength: 0,
      controlLength: 0,
      statusNew: null,
    };
  },
  created() {
    this.url = this.$store.getters.get__url;
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post", "post_sin_bus"]),
    mtdGetData: function () {
      this.post_sin_bus({
        url: this.url + "/patientScreen",
        params: {
          campu_id: 1, // Fijo campus
          type: "consulta", // Primer piso consulta
        },
      })
        .then((response) => {
          this.data = {
            consulta: response.data.consultas,
            fondo: response.data.fondoOjos,
            lectura: response.data.lectura,
            control: response.data.control,
          };
          this.consultasLength = response.data.consultas.length;
          this.fondoOjosLength = response.data.fondoOjos.length;
          this.resultadosLength = response.data.lectura.length;
          this.controlLength = response.data.control.length;
        })
        .catch((errors) => {
          console.log(errors);
        });

      // setTimeout(() => {
      //   this.refreshDataHome();
      // }, 30000); // 30000 milisegundos = 30 segundos
    },
    refreshDataHome: function () {
      this.data = {};
      this.mtdGetData();
    },
    onItemMove(evt) {
      let cuadrante1Length = 0;
      let cuadrante2Length = 0;
      let cuadrante3Length = 0;
      let cuadrante4Length = 0;
      if (evt.added) {
        let id_reservation = 0;
        let statusActual = evt.added.element.status;
        id_reservation = evt.added.element.id_reservation;
        cuadrante1Length = this.data["CONSULTA NUEVA O REINGRESO"].length;
        cuadrante2Length = this.data["FONDO DE OJOS"].length;
        cuadrante3Length = this.data["LECT. DE RESULTADOS"].length;
        cuadrante4Length = this.data["CONTROL POR INFECCIÓN"].length;

        if (this.controlLength < cuadrante4Length) {
          this.statusNew = 4;
        } else {
          if (this.consultasLength < cuadrante1Length) {
            //verificar si es reingreso de fondo de ojos
            console.log(statusActual);
            this.statusNew = statusActual == 2 ? 3 : 1;
          } else {
            if (this.fondoOjosLength < cuadrante2Length) {
              this.statusNew = 2;
            } else {
              if (this.resultadosLength < cuadrante3Length) {
                this.statusNew = 5;
              } else {
                this.statusNew = 0;
              }
            }
          }
        }
        this.mtdChangeStatus(id_reservation);
      }
    },
  },
};
</script>

<style>
.logo-flotante {
  position: fixed;
  top: 20px; /* Ajusta según la distancia desde la parte superior que deseas */
  left: 20px; /* Ajusta según la distancia desde la derecha que deseas */
  z-index: 999; /* Ajusta según sea necesario */
}

.logo-flotante img {
  width: 200px; /* Ajusta el ancho del logo según sea necesario */
  height: auto; /* Para mantener la proporción */
}

.principal {
  margin-top: 50px;
  font-family: "Roboto";
  /* background: linear-gradient(-20deg, #6b003d, #900052); */
}

.card-header-first {
  margin-top: -40px;
  height: 90px;
  background: linear-gradient(-40deg, #6b003d, #900052);
  box-shadow: 1px 5px 15px #a2a2a2;
}

/*efecto shadows para todas las tarjetas*/
.card-section {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
/*Para el efecto sombra al pasar por encima el mouse*/
.card-section:hover {
  box-shadow: 1px 1px 20px #900052;
}
</style>
<template>
<div class="container">
    <div class="row">
        <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
    </div>

    <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class="card w-100">
            <div class="card-header text-white h4 text-center" style="background: #900052">
                <strong>SOLICITUDES DE AUTORIZACION DE CIERRE DE CAJA</strong>
            </div>
            <div class="card-body w-100">
                <div class="row p-1">

                    <div class="col-md-12 px-0">
                        <nav aria-label="Page navigation example" v-if="cpData.length > 0" class="pagination-container">
                            <ul class="pagination">
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                </li>
                                <li class="page-item" v-if="selectPage > 3">
                                    <a class="page-link" href="javascript:void(0)" @click="selectedPage(1)">
                                        {{ 1 }}
                                    </a>
                                </li>
                                <li class="page-item" v-if="selectPage > 3">
                                    <span class="page-link">...</span>
                                </li>
                                <template v-for="(item, index) in stepPagination">
                                    <li :key="index" class="page-item" v-if="
                        (index >= Math.max(selectPage - 2, 0) &&
                          index < selectPage + 3 &&
                          index < stepPagination.length) ||
                        (stepPagination.length <= 4 &&
                          index === stepPagination.length - 1) ||
                        (index === 0 && selectPage <= 3)
                      ">
                                        <a :class="
                          selectPage == item ? 'page-link active' : 'page-link'
                        " href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                    </li>
                                </template>
                                <li class="page-item" v-if="selectPage < stepPagination.length - 4">
                                    <span class="page-link">...</span>
                                </li>

                                <li class="page-item" v-if="selectPage < stepPagination.length - 3">
                                    <a class="page-link" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">
                                        {{ stepPagination.length }}
                                    </a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-md-12 px-0" id="">
                        <div id="div-modal-caja" class="d-flex justify-content-center" v-for="(item, index) in cpData" :key="index">
                            <div id="div-caja" class="card w-100" style="height: auto">
                                <div class="card-header text-white h5 bg-main" style="
                      display: flex;
                      justify-content: space-between;
                    ">
                                    <strong style="margin: 0">Solicitud de Autorización del
                                        {{ item.date_end | formatDate("%d/%m/%y") }}
                                        {{ item.date_end.split(" ")[1] }} -
                                        {{ item.user.name }}
                                        {{ item.user.last_name }}
                                    </strong>
                                </div>
                                <div class="card-body" style="max-height: 485px; overflow-y: auto">
                                    <div id="row div-dataMoney" class="container">
                                        <div class="row d-flex justify-content-end">
                                            <div class="col-md-12 mb-3 text-end">
                                                <div class="">
                                                    <button @click="permision(item.id)" class="btn btn-sm btn-info text-white">
                                                        Ver Accion <i class="fa fa-edit"></i>
                                                    </button>
                                                    &nbsp;
                                                    <button @click="mtdViewPdfCaja(item.id)" class="btn btn-sm btn-primary text-white">
                                                        Ver PDF <i class="fa fa-file-pdf"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row d-flex justify-content-between">
                                            <div class="col-md-6 col-lg-3 col-sm-12 col-xs-12 mt-0 text-center">
                                                <label class="form-label text-center"><strong>Billetes</strong></label>
                                                <div v-for="(billete, index) in billetes(item)" :key="index" class="">
                                                    <div class="input-group input-group-sm mb-2">
                                                        <span class="input-group-text col-4">{{
                                billete.label
                              }}</span>
                                                        <input style="align-items: center; text-align: center" type="text" class="form-control form-control-sm" disabled aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="billete.cantidad" width="10px" />
                                                        <span v-if="billete.total == 0" class="input-group-text col-4">= S/. 0.00</span>
                                                        <span v-else class="input-group-text col-4">= S/{{ billete.total }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-3 col-sm-12 col-xs-12 text-center">
                                                <label class="form-label text-center"><strong>Monedas</strong></label>
                                                <div v-for="(moneda, index) in monedas(item)" :key="index" class="">
                                                    <div class="input-group input-group-sm mb-2">
                                                        <span class="input-group-text col-4">{{
                                moneda.label
                              }}</span>
                                                        <input type="text" style="align-items: center; text-align: center" class="form-control form-control-sm" disabled aria-label="Amount (to the nearest dollar)" placeholder="0" v-model="moneda.cantidad" />
                                                        <span class="input-group-text col-4" v-if="moneda.total == 0">= S/. 0.00</span>
                                                        <span v-else class="input-group-text col-4">= S/ {{ moneda.total }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-2 col col-sm-12" style="text-align: center">
                                                <strong> TOTAL EFECTIVO </strong>
                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>APERTURA: S/.{{ item.initial }}</strong>
                                                </button>
                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>INGRESOS: S/.{{ item.income }}</strong>
                                                </button>

                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>EGRESOS: S/.{{ item.expense }} </strong>
                                                </button>
                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>ANULACION: S/.{{ item.cancellations }}
                                                    </strong>
                                                </button>

                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>TOTAL:S/.{{ item.final }} </strong>
                                                </button>
                                            </div>
                                            <div class="col-md-4 col-lg-2 col col-sm-12" style="text-align: center">
                                                <strong> OTROS INGRESOS </strong>
                                                <div>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>
                                                            Trans. : S/.
                                                            {{ ttransferencia(item) }}
                                                        </strong>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>
                                                            Tarjeta: : S/.
                                                            {{ ttarjeta(item) }}
                                                        </strong>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>
                                                            Deposito : S/.
                                                            {{ tdeposito(item) }}
                                                        </strong>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>
                                                            Planilla : S/.
                                                            {{ tplanilla(item) }}
                                                        </strong>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="col-md-4 col-lg-2 col col-sm-12" style="text-align: center">
                                                <strong> SALDOS </strong>
                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>Saldo efec. S/{{ item.final }}</strong>
                                                </button>

                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>Saldo General S/{{ general(item) }}</strong>
                                                </button>
                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong>Vueltos S/{{ vueltos(item) }}</strong>
                                                </button>
                                                <button class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                    <strong v-if="sobrafalta(item) < 0">
                                                        Faltante :S/{{
                                parseFloat(sobrafalta(item)) * -1
                              }}</strong>
                                                    <strong v-else>
                                                        Sobrante: S/{{ sobrafalta(item) }}</strong>
                                                </button>
                                            </div>
                                            <div class="row d-flex">
                                                <div class="col-md-4"></div>
                                                <div class="col-md-4">
                                                    <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Depósitos:S/.{{ depositos(item) }}</strong>
                                                    </label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="btn btn-sm w-100 mt-1" style="background: #900052; color: white">
                                                        <strong>Retiros:S/.{{ retiros(item) }}</strong>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="dataCaja.length == 0">
                    <div class="p-4 text-center">
                        <h3> No hay solicitudes pendientes de Autorización </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-gerencia-permisosCierreCaja",
    components: {
        SweetAlert,
    },
    data() {
        return {
            view: "data",
            itemsPerPage: 1,
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            dataCaja: [],
            posEdit: null,
            noRecordsMessage: "",
            datePos: 0,
        };
    },
    props: {
        bead: {},
    },
    components: {},
    created() {
        this.mtdGetData();
    },
    computed: {
        cpData() {
            const startIndex = (this.selectPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.dataCaja.slice(startIndex, endIndex);
        },
    },
    methods: {
        ...mapActions(['get', 'post']),
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Gerencia/permiso/cierre",
                    token: this.$store.getters.get__token,
                    params: {
                        campus: this.$store.getters.get__campus,
                    },
                })
                .then((response) => {
                    this.stepPagination = [];
                    this.dataCaja = response.data;
                    this.selectPage = 1;
                    let cantidad = parseInt(this.dataCaja.length);
                    for (let index = 0; index < cantidad; index++) {
                        this.stepPagination.push(index + 1);
                    }
                })
                .catch((errors) => {});
        },
        permision(id) {
            let state = "";
            Swal.fire({
                icon: "warning",
                title: "¿Qué accción deseas tomar para este requerimiento?",
                width: "400px",
                /** buttons */
                showDenyButton: true,
                denyButtonText: `Rechazar`,
                denyButtonColor: "#bb8a17",
                confirmButtonText: "Autorizar",
                confirmButtonColor: "#3085d6",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                cancelButtonText: "Cerrar",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.post({
                            url: this.$store.getters.get__url + "/Gerencia/permiso/respuesta",
                            token: this.$store.getters.get__token,
                            params: {
                                bead: id,
                                state: 2,
                            },
                        })
                        .then((response) => {
                            if (response.state == 0) {
                                Swal.fire({
                                    text: "Accion Correcta",
                                    icon: "success",
                                    confirmButtonColor: "#900052",
                                });
                                this.mtdGetData();
                            }
                        })
                        .catch((errors) => {});
                } else if (result.isDenied) {
                    this.post({
                            url: this.$store.getters.get__url + "/Gerencia/permiso/respuesta",
                            token: this.$store.getters.get__token,
                            params: {
                                bead: id,
                                state: 3,
                            },
                        })
                        .then((response) => {
                            if (response.state == 0) {
                                Swal.fire({
                                    text: "Accion Correcta",
                                    icon: "success",
                                    confirmButtonColor: "#900052",
                                });
                                this.mtdGetData();
                            }
                        })
                        .catch((errors) => {});
                }
            });
        },
        /** pagination */
        selectedPage: function (page) {
            this.selectPage = page;
        },
        backPage: function () {
            this.selectPage = this.selectPage === 1 ? 1 : this.selectPage - 1;
        },
        nextPage: function () {
            this.selectPage =
                this.selectPage === this.stepPagination.length ?
                this.stepPagination.length :
                this.selectPage + 1;

        },
        billetes: function (item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.billetes;
        },
        monedas: function (item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.monedas;
        },
        ttarjeta(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.totTarjeta;
        },
        tdeposito(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.totDeposito;
        },
        ttransferencia(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.totTransferencia;
        },
        tplanilla(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.totPlanilla;
        },
        vueltos(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.totTurned !== null && cierre.totTurned !== undefined ?
                cierre.totTurned :
                "0.00";
        },
        depositos(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.deposit;
        },
        retiros(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            return cierre.retirement;
        },
        general(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);

            let tot =
                parseFloat(cierre.totTarjeta) +
                parseFloat(cierre.totDeposito) +
                parseFloat(cierre.totTransferencia) +
                parseFloat(cierre.totPlanilla);
            return parseFloat(tot).toFixed(2);
        },
        sobrafalta(item) {
            let cierre = JSON.parse(item.transaction[0].json_cierre);
            let tot = 0;
            cierre.billetes.forEach((element) => {
                tot += parseFloat(element.total);
            });
            cierre.monedas.forEach((element) => {
                tot += parseFloat(element.total);
            });
            let tot1 =
                parseFloat(tot) -
                parseFloat(cierre.totEfectivo) -
                parseFloat(
                    cierre.totTurned !== null && cierre.totTurned !== undefined ?
                    cierre.totTurned :
                    "0.00"
                );
            return parseFloat(tot1).toFixed(2);
        },
        mtdViewPdfCaja: function (beadId) {
            window.open(
                this.$store.getters.get__url + "/Admision/viewpdf/cierre/" + beadId,
                "_blank"
            );
        },
    },
    filters: {
        // formato de fecha
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
};
</script>

<style scoped>
.pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    /* Ajusta el margen superior según sea necesario */
}

#btn-error-anular {
    color: #ff0000;
    font-size: 25px;
}

#btn-registro-anular a {
    color: #900052;
}

#btn-registro-anular a:hover {
    color: #65013a;
    align-items: center;
    transform: scale(1.5);
}

.circulo {
    vertical-align: middle;
    border-style: none;
}

@media (max-width: 800px) {

    /* #div-modal-anular{
        margin-top: 20px;
      } */
    #btn-open-caja {
        justify-content: center !important;
    }

    /* #div-anular{
        width: 100% !important;
      } */
    #div-tabla-anular {
        overflow-x: auto;
    }
}

@media (min-width: 800px) {
    /* #div-modal-anular{
        margin-top: 65px;
      } */
}

/*Paginacion*/
.page-link {
    color: black !important;
}

.mt-4 {
    margin-top: 4rem;
}

.mb-4 {
    margin-bottom: 4rem;
}

.page-link.active {
    color: white !important;
    border-color: white !important;
    background: rgb(144, 0, 82);
}

.page {
    color: white !important;
    background: rgb(144, 0, 82);
}
</style>

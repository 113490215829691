<template>
  <div id="div_login">
    <NavBar :page="'Login'" />
    <div class="c-app flex-row align-items-center">
      <div class="container" id="contenedor">
        <div class="row justify-content-center d-flex m-1 text-center">
          <div class="col-xl-3 col-lg-3 col-md-6 col-xs-12">
            <div class="align-center" id="log">
              <img src="../assets/bm_sin_fondo.png" alt="Logo" class="d-block d-sm-none" />
            </div>
            <h3 class="label-bm">&nbsp;</h3>
            <input v-model="login.login" class="form-control form-control-lg input-rounded justify-content-center" placeholder="Usuario" type="text" aria-label=".form-control-lg example">
            <h3 class="label-bm mt-2">&nbsp;</h3>
            <input v-model="login.password" @keyup.enter="loginSend" class="form-control form-control-lg input-rounded justify-content-center" type="password" placeholder="Clave" aria-label=".form-control-lg example">
          </div>
        </div>
      </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert"/> 
  </div>
</template>

<script>
import SweetAlert from '../components/shared/SweetAlert/SweetAlert.vue';
import NavBar from '../components/shared/NavBar.vue'
import { mapActions } from 'vuex'

export default {
  name: 'LoginView',
  components: { 
    NavBar,
    SweetAlert
   },
  data () {
    return {
      login: {
        login: null,
        password: null
      },
      swal: null
    }
  },
  created () {
    this.url = this.$store.getters.get__url
  },
  methods: {
    ...mapActions(['get', 'post']),
    loginSend: function () {
      if (this.login.login != null) {
        if (this.login.password != null) {
          this.post({
            url: this.url + '/Authentication/login',
            params: this.login
          })
            .then((response) => {
              if (response.statusCode == 200) {
                this.$store.commit('mt_set_auth', response.data.user)
                this.$store.commit('mt_set_datacampus', response.data.campus)
                this.$router.push({ path: 'home' })
              } else if (response.statusCode == 201){
                this.$refs.SweetAlert.showError(response.message);
              } else {
                console.log('error');
              }
            })
        } else {
          this.$refs.SweetAlert.showError("Ingrese contraseña válida.");
        }
      } else {
        this.$refs.SweetAlert.showError("Ingrese usuario válido.");
      }
      if (this.login.login == null && this.login.password == null) {
        this.$refs.SweetAlert.showError("Ingrese usuario y contraseña válida.");
      }
    },
    
  }
}
</script>
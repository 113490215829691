<template>
<div>
    <div class="container">

        <div class="row mt-4">
            <div class="col-12">
                <div class="row d-flex justify-content-center">
                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 py-2 mt-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData()" />
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 py-2 mt-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text col-md-3">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.enddate" @change="mtdGetData()" />
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4 col-xs-12 py-2 mt-2">
                        <!--Paginado-->
                        <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                            <nav aria-label="Page navigation example">
                                <ul class="pagination mb-1">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <!--tabla-->
            <div class="col-md-12 table-container mt-2" id="content-timelinetable">
                <table class="table table-borderless">
                    <caption class="py-0 my-0"></caption>
                    <thead class="bg-main ">
                        <tr class="text-white text-center tr-table text-xs">
                            <th scope="col" class="border border-white">
                                <div>N°</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>ORDEN DE COMPRA</div>
                            </th>
                            <th scope="col " class="border border-white">
                                <div>FECHA DE OC</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>S/TOTAL SIN IGV-OC</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>S/CON IGV-OC</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>PROVEEDOR</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>RUC</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>N°FACTURA</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>TIPO</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>FECHA DE FACTURA</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>S/TOTAL</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>S/EXONERADO</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>S/INAFECTO</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>MONEDA</div>
                            </th>
                            <th scope="col" class="border border-white">
                                <div>DETRACCION</div>
                            </th>
                            <th scope="col" class="border border-white" v-if="type == 'oc'">
                                <div>DESPACHOS</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-0 text-xs" v-for="(item, index) in cpData" :key="index">
                            <td class="border">
                                <div>{{ mtdNumber(index) }}</div>
                            </td>
                            <td class="border">
                                <div>{{ mtdGenerateCode(item.purcharseorder.id) }}</div>
                            </td>
                            <td class="border">
                                <div>{{ item.purcharseorder.date | formatDate }}</div>
                            </td>
                            <td class="border">
                                <div>{{item.purcharseorder.total_sin_IGV}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.purcharseorder.total_IGV}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.provider.name}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.provider.ruc}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.number}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.type == 2? 'BOLETA' : 'FACTURA'}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.date | formatDate}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.total}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.exonerated}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.unaffected}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.money}}</div>
                            </td>
                            <td class="border">
                                <div>{{item.detraction}}</div>
                            </td>
                            <td class="border  ">
                                <div class="text-center  my-2">
                                    <a class=" bg-info rounded rounded-3  px-2 py-2" href="#" @click="mtdGetDispatches(item)">
                                        <i class="fas fa-dolly text-white"></i>
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="cpData.length == 0">
                            <td colspan="16" class="fw-bold text-center">No hay Facturas registradas en estas fechas</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <cModalViewDispatch :title="modalViewDispatch.title" :dataDispatches="dataDispatches" :boo_modal="modalViewDispatch.modal_form" @mtdCloseModalFactura="mtdCloseModalViewDispatches" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalViewDispatch from "../../modals/cModalViewDispatch.vue";
export default {
    name: 'logistica-reporteFactura-reportbilloc',
    data() {
        return {
            dataShow: [],
            filter: {
                begindate: null,
                enddate: null,
            },
            totalPagesToShow: 10,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            data: [],
            modalViewDispatch: {
                title: "",
                modal_form: false,
            },
            dataDispatches: []
        }
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
        type: {
            handler(newVal) {
                this.getFirstDayOfMonth();
                this.mtdGetData();
            },
            immediate: true
        },

    },
    props: {
        type: String,
    },
    components: {
        cModalViewDispatch,
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    // created() {
    //     this.getFirstDayOfMonth();
    //     this.mtdGetData();

    // },
    methods: {
        ...mapActions(['get', 'post']),
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdGetData: function () {
            //Cargar data de acuerdo a lo buscado
            this.post({
                url: this.$store.getters.get__url + "/Logistica/oc/report/bills",
                token: this.$store.getters.get__token,
                params: this.filter,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.data = response.data;
                    this.calculateData(10);
                }
            });
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = lastDayOfMonth.toISOString().split('T')[0];
        },
        calculateData(items) {
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        mtdGenerateCode(item) {
            if (0 < item && item < 10) {
                return " OC-000" + item;
            }

            if (9 < item && item < 100) {
                return " OC-00" + item;
            }
            if (99 < item && item < 1000) {
                return " OC-0" + item;
            }
            if (999 < item && item < 10000) {
                return " OC-" + item;
            }
        },
        mtdGetDispatches: function (item) {
            const type = item.number.substring(0, 1) === 'B' ? "BOLETA" : "FACTURA";
            const title = "DESPACHOS DE LA " + type + " " + item.number;
            this.get({
                    url: this.$store.getters.get__url +
                        "/Logistica/oc/getDispatches/" +
                        item.id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataDispatches = response.dispatch;
                    if (this.dataDispatches.length > 0) {
                        this.modalViewDispatch = {
                            title: title,
                            modal_form: true,
                        };
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "No hay despachos para mostrar",
                            showConfirmButton: false,
                            timer: 1500,
                        });
                    }
                })
                .catch((errors) => {});
        },
        mtdCloseModalViewDispatches: function () {
            this.modalViewDispatch = {
                title: '',
                modal_form: false,
            };
            this.dataDispatches = [];
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },
}
</script>
